import api from './api';
import { ApiPagination, PaginationResult } from '../../types/ApiPagination';
import { Identifier } from '../../types/ApiSchema';
import {
  OtherCharges,
  OtherChargesFields,
  OtherChargesParams,
} from '../../types/OtherCharges';

const OtherChargesApi = api
  .enhanceEndpoints({ addTagTypes: ['OtherCharges'] })
  .injectEndpoints({
    endpoints: builder => ({
      getOtherCharges: builder.query<
        PaginationResult<OtherCharges>,
        OtherChargesParams & ApiPagination
      >({
        query: params => ({
          url: `/database/function/getOtherCharges`,
          method: 'GET',
          params: params,
        }),
        providesTags: result =>
          result?.documents
            ? [
                ...result.documents.map(
                  ({ _id }) => ({ type: 'OtherCharges', _id } as const),
                ),
                { type: 'OtherCharges', id: 'LIST' },
              ]
            : [{ type: 'OtherCharges', id: 'LIST' }],
      }),
      addOtherCharges: builder.mutation<OtherCharges, OtherChargesFields>({
        query: params => ({
          url: `database/OtherCharges`,
          method: 'POST',
          body: params,
        }),
        invalidatesTags: [{ type: 'OtherCharges', id: 'LIST' }],
      }),
      editOtherCharges: builder.mutation<OtherCharges, Identifier & OtherChargesFields>({
        query: ({ _id, ...params }) => ({
          url: `database/OtherCharges/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'OtherCharges', _id }],
      }),
      deleteOtherCharges: builder.mutation<void, Identifier>({
        query: ({ _id }) => ({
          url: `raeService/other-charges/${_id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'OtherCharges', _id }],
      }),
    }),
  });

export const {
  useGetOtherChargesQuery,
  useAddOtherChargesMutation,
  useEditOtherChargesMutation,
  useDeleteOtherChargesMutation,
} = OtherChargesApi;

export default OtherChargesApi;
