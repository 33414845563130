import api from './api';
import { ApiPagination, PaginationResult } from '../../types/ApiPagination';
import {
  ClauseCoefficient,
  ClauseCoefficientParams,
  ClauseCoefficientsRequest,
} from '../../types/ClauseCoefficient';
import { Identifier } from '../../types/ApiSchema';

const clauseCoefficientApi = api
  .enhanceEndpoints({ addTagTypes: ['ClauseCoefficient'] })
  .injectEndpoints({
    endpoints: builder => ({
      getClauseCoefficient: builder.query<
        PaginationResult<ClauseCoefficient>,
        ClauseCoefficientParams & ApiPagination
      >({
        query: params => ({
          url: `raeService/clause-coefficient`,
          method: 'GET',
          params: { ...params, populate: 'modUser' },
        }),
        providesTags: result =>
          result?.documents
            ? [
                ...result.documents.map(
                  ({ _id }) => ({ type: 'ClauseCoefficient', _id } as const),
                ),
                { type: 'ClauseCoefficient', id: 'LIST' },
              ]
            : [{ type: 'ClauseCoefficient', id: 'LIST' }],
      }),
      addClauseCoefficient: builder.mutation<
        ClauseCoefficient,
        ClauseCoefficientsRequest
      >({
        query: params => ({
          url: `database/function/createClauseCoefficient`,
          method: 'POST',
          body: params,
        }),
        invalidatesTags: [{ type: 'ClauseCoefficient', id: 'LIST' }],
      }),
      editClauseCoefficient: builder.mutation<
        ClauseCoefficient,
        Identifier & Partial<ClauseCoefficientsRequest>
      >({
        query: ({ _id, ...params }) => ({
          url: `database/function/updateClauseCoefficient/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'ClauseCoefficient', _id }],
      }),
      deleteClauseCoefficient: builder.mutation<void, Identifier>({
        query: ({ _id }) => ({
          url: `raeService/clause-coefficient/${_id}/archive`,
          method: 'PATCH',
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'ClauseCoefficient', _id }],
      }),
    }),
  });

export const {
  useGetClauseCoefficientQuery,
  useAddClauseCoefficientMutation,
  useEditClauseCoefficientMutation,
  useDeleteClauseCoefficientMutation,
} = clauseCoefficientApi;

export default clauseCoefficientApi;
