import { CustomTable } from '@rae/ui-library';
import { DropDownNewProps } from '@rae/ui-library/src/components/DropdownNew';
import { HeadCell, OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import { TableMainProviderProps } from '@rae/ui-library/src/components/table/TableRowMainProvider';
import React, { useState } from 'react';
import {
  ReviewStatus,
  ReviewStatusEnum,
  reviewStatusLabel,
} from '../../types/ReviewStatus';
import { ProductType, productTypeLabel } from '../../types/Product';

type Props = {
  data: TableMainProviderProps[];
  openInvoicesVersions: (id: string) => void;
  handleView: (id?: string, type?: string) => void;
  rowsPerPage: number;
  page: number;
  setPage: (newPage: number) => void;
  order: OrderProps;
  setOrder: (newOrder: OrderProps) => void;
  orderBy: HeadCell['id'];
  setOrderBy: (newOrderBy: HeadCell['id']) => void;
  count?: number;
  type: ProductType[];
  setType: (newType: ProductType[]) => void;
  reviewStatus?: ReviewStatus;
  setReviewStatus: (newReviewStatus?: ReviewStatus) => void;
};

export const ProviderTable = ({
  data,
  handleView,
  openInvoicesVersions,
  rowsPerPage,
  page,
  setPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  count = 0,
  type,
  setType,
  reviewStatus,
  setReviewStatus,
}: Props) => {
  const [openType, setOpenType] = useState<boolean>(false);
  const [openStatus, setOpenStatus] = useState<boolean>(false);

  const dropDowns: DropDownNewProps[] = [
    {
      choices: [
        { label: productTypeLabel('electric'), value: 'electric' },
        { label: productTypeLabel('gas'), value: 'gas' },
        { label: productTypeLabel('linked'), value: 'linked' },
      ],
      open: openType,
      selected: type,
      setOpen: setOpenType,
      setSelected: type => setType(type as ('gas' | 'electric' | 'linked')[]),
      label: 'Τύπος',
      multiple: true,
      labelBtn: 'Εφαρμογή',
    },
    {
      choices: [
        {
          label: reviewStatusLabel(ReviewStatusEnum.Pending),
          value: ReviewStatusEnum.Pending,
        },
        {
          label: reviewStatusLabel(ReviewStatusEnum.Revision),
          value: ReviewStatusEnum.Revision,
        },
        {
          label: reviewStatusLabel(ReviewStatusEnum.Accepted),
          value: ReviewStatusEnum.Accepted,
        },
        {
          label: reviewStatusLabel(ReviewStatusEnum.Rejected),
          value: ReviewStatusEnum.Rejected,
        },
      ],
      open: openStatus,
      selected: reviewStatus ? [reviewStatus] : [],
      setOpen: setOpenStatus,
      setSelected: reviewStatus => setReviewStatus(reviewStatus?.[0] as ReviewStatus),
      label: 'Κατάσταση τιμολ',
      multiple: false,
      labelBtn: 'Εφαρμογή',
    },
  ];

  return (
    <CustomTable
      headCells={[
        { disable: false, id: 'productInfo.nameGr', label: 'Όνομα Τιμολογίου' },
        { disable: true, id: 'versions', label: 'Διαθέσιμα versions' },
        { disable: true, id: 'invoice_status', label: 'Κατάσταση Τιμολογίου' },
        { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
        { disable: true, id: 'actions', label: 'Ενέργειες' },
      ]}
      dropDownBtn={{
        children: 'Καταχώρηση',
        variant: 'contained',
      }}
      dropDownLinks={[
        { label: 'Τιμολογίου Ηλεκτρικής Ενέργειας', link: '/product-electric' },
        { label: 'Τιμολογίου Φυσικού Αερίου', link: '/product-gas' },
        { label: 'Συνδυαστικού Τιμολογίου', link: '/product-linked' },
      ]}
      type="main-provider"
      handleNewEntry={(obj: TableMainProviderProps) =>
        openInvoicesVersions(obj._id ? obj._id : '')
      }
      handleView={(obj: TableMainProviderProps) => handleView(obj._id, obj.type)}
      count={count}
      rows={data}
      dropDownsNew={dropDowns}
      order={order}
      orderBy={orderBy}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
      page={page}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
    />
  );
};
