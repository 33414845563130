import { Box, Typography } from '@mui/material';
import { ButtonRae, ProductEntryCard } from '@rae/ui-library';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { ProductElectric } from '../../../types/ProductElectric';
import { ProductGas } from '../../../types/ProductGas';
import { ProductLinked } from '../../../types/ProductLinked';
import {
  availableReviewActions,
  ReviewActionsEnum,
  ReviewModeEnum,
  showReviewComments,
} from '../../../types/ReviewMode';
import {
  ReviewStatus,
  reviewStatusColor,
  reviewStatusLabel,
} from '../../../types/ReviewStatus';
import { Team } from '../../../types/Team';
import { isRaeTeam } from '../../../utils/PickTeam';
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
import { useMemo } from 'react';

type Props = {
  team?: Team;
  product?: ProductElectric | ProductGas | ProductLinked;
  versions?: (ProductElectric | ProductGas | ProductLinked)[];
  handleNavigate: (id?: string) => void;
  reviewMode: ReviewModeEnum;
  setReviewMode: (reviewMode: ReviewModeEnum) => void;
  handleDelete: () => void;
  handleReject: () => void;
  handleQR: () => void;
  comments?: number;
};

const ActionsComponent = ({
  team,
  product,
  versions,
  handleNavigate,
  reviewMode,
  setReviewMode,
  handleDelete,
  handleReject,
  handleQR,
  comments = 0,
}: Props) => {
  const navigateLatestVersion = useMemo(
    () =>
      versions?.[0] && versions[0]._id !== product?._id
        ? () => handleNavigate(versions[0]._id)
        : undefined,
    [versions, product, handleNavigate],
  );

  if (!team || !product || !versions) return null;
  if (!isRaeTeam(team) && team._id !== product.productInfo.team._id) return null;
  if (reviewMode === ReviewModeEnum.Creation) return null;

  return (
    <Box sx={{ mt: 3 }}>
      <ProductEntryCard
        productName={product?.productInfo.nameGr}
        productStatus={reviewStatusLabel(
          product.archived ? 'deleted' : product?.reviewStatus,
        )}
        productStatusColor={reviewStatusColor(
          product.archived ? 'deleted' : product?.reviewStatus,
        )}
        providerName={product?.productInfo.team.nameGr}
        productComments={comments}
        showComments={showReviewComments(reviewMode)}
      />
      <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
        <Actions
          isRae={isRaeTeam(team)}
          reviewStatus={product.archived ? 'deleted' : product?.reviewStatus}
          navigateLatestVersion={navigateLatestVersion}
          reviewMode={reviewMode}
          setReviewMode={setReviewMode}
          handleReject={handleReject}
          handleDelete={handleDelete}
          handleQR={handleQR}
        />
      </Box>
    </Box>
  );
};

const Actions = ({
  isRae,
  reviewStatus,
  setReviewMode,
  reviewMode,
  handleDelete,
  handleReject,
  handleQR,
  navigateLatestVersion,
}: {
  isRae?: boolean;
  reviewStatus?: ReviewStatus;
  navigateLatestVersion?: () => void;
  reviewMode: ReviewModeEnum;
  setReviewMode: (mode: ReviewModeEnum) => void;
  handleDelete: () => void;
  handleReject: () => void;
  handleQR: () => void;
}) => {
  if (reviewMode === ReviewModeEnum.Viewing) {
    return (
      <>
        {availableReviewActions(
          reviewStatus,
          isRae,
          navigateLatestVersion !== undefined,
        ).map(mode => {
          switch (mode) {
            case ReviewActionsEnum.Revise:
              return (
                <ButtonRae
                  onClick={() => setReviewMode(ReviewModeEnum.Revising)}
                  variant="outlined"
                  startIcon={<DriveFileRenameOutlineIcon />}
                >
                  Διόρθωση Τιμολογίου
                </ButtonRae>
              );
            case ReviewActionsEnum.Edit:
              return (
                <ButtonRae
                  onClick={() => setReviewMode(ReviewModeEnum.Editing)}
                  variant="outlined"
                  startIcon={<DriveFileRenameOutlineIcon />}
                >
                  Επεξεργασία Τιμολογίου
                </ButtonRae>
              );
            case ReviewActionsEnum.Review:
              return (
                <ButtonRae
                  onClick={() => setReviewMode(ReviewModeEnum.Reviewing)}
                  variant="outlined"
                  startIcon={<DriveFileRenameOutlineIcon />}
                >
                  Διαδικασία έγκρισης
                </ButtonRae>
              );
            case ReviewActionsEnum.QR:
              return (
                <ButtonRae
                  onClick={handleQR}
                  variant="outlined"
                  startIcon={<FileDownloadOutlinedIcon />}
                >
                  Κατέβασμα QR
                </ButtonRae>
              );
            case ReviewActionsEnum.Version:
              return (
                <ButtonRae
                  onClick={() => setReviewMode(ReviewModeEnum.Versioning)}
                  variant="outlined"
                  startIcon={<NoteAddOutlinedIcon />}
                >
                  Δημιουργία νέου version
                </ButtonRae>
              );
            case ReviewActionsEnum.Delete:
              return (
                <ButtonRae
                  onClick={handleDelete}
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteIcon />}
                >
                  Διαγραφή Τιμολογίου
                </ButtonRae>
              );
            case ReviewActionsEnum.Reject:
              return (
                <ButtonRae
                  onClick={handleReject}
                  variant="outlined"
                  color="error"
                  startIcon={<CancelIcon />}
                >
                  Απόρριψη Τιμολογίου
                </ButtonRae>
              );
            case ReviewActionsEnum.LatestVersion:
              return (
                <ButtonRae
                  onClick={navigateLatestVersion}
                  variant="outlined"
                  startIcon={<RedoOutlinedIcon />}
                >
                  Τελευταίο Version
                </ButtonRae>
              );
            default:
              return null;
          }
        })}
      </>
    );
  } else {
    const reviewModeText = () => {
      switch (reviewMode) {
        case ReviewModeEnum.Editing:
          return 'επεξεργασίας';
        case ReviewModeEnum.Revising:
          return 'διόρθωσης';
        case ReviewModeEnum.Reviewing:
          return 'έγκρισης';
        case ReviewModeEnum.Versioning:
          return 'δημιουργίας νέου version';
        default:
          return '';
      }
    };

    return (
      <Box
        sx={{
          flex: 1,
          py: 0.1,
          px: 1,
          bgcolor: '#1E71CF',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ ml: 1, color: '#ffffff' }}>
          {`Βρίσκεστε σε διαδικασία ${reviewModeText()} τιμολογίου`}
        </Typography>
        <ButtonRae
          onClick={() => setReviewMode(ReviewModeEnum.Viewing)}
          sx={{ color: '#ffffff' }}
          startIcon={<CancelIcon />}
        >
          Αναίρεση
        </ButtonRae>
      </Box>
    );
  }
};

export default ActionsComponent;
