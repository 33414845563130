import { TableDiscountProps } from '@rae/ui-library/src/components/table/TableRowDiscounts';
import { TableProviderProps } from '@rae/ui-library/src/components/table/TableRowProvider';
import { ReviewStatus } from '../../types/ReviewStatus';
import { ReviewComments } from '../../types/ReviewComments';
import { DiscountsTypeDiscounts } from '../ProductElectricPage/ProductElectricTypes';
import { ProductLinkedFields } from '../../types/ProductLinked';
import { chargeRowsAsString } from '../../types/ChargeRows';

export type LinkedInvoicesTypes = {
  reviewStatus?: ReviewStatus;
  reviewComments?: ReviewComments;
  electricProd: string[];
  gasProd: string[];
  productImg?: File | undefined;
  productImgId?: string;
  productImgUrl?: string;
  productName?: string;
  productNameEn?: string;
  productLink?: string;
  productLinkEn?: string;
  provider?: TableProviderProps;
  from?: Date | null;
  discountsElectric?: DiscountsTypeDiscountsElectric;
  benefitsElectric?: DiscountsTypeBenefitsElectric;
  discountsGas?: DiscountsTypeDiscountsGas;
  benefitsGas?: DiscountsTypeBenefitsGas;
};

export type ProductInvoiceDiscountData = {
  percentage?: string;
  value?: string;
  description?: string;
  customers?: string;
  indefinite?: boolean;
  startDate?: Date | null | undefined;
  endDate?: Date | null | undefined;
};

export type AvailibleDiscountsElectric = Record<string, TableDiscountProps>;

export type DiscountsTypeDiscountsElectric = Record<string, AvailibleDiscountsElectric>;

export type ProductInvoiceElBenefitsData = {
  percentage?: string;
  value?: string;
  description?: string;
  customers?: string;
  indefinite?: boolean;
  startDate?: Date | null | undefined;
  endDate?: Date | null | undefined;
};

export type AvailableBenefitsElectric = Record<string, TableDiscountProps>;

export type DiscountsTypeBenefitsElectric = Record<string, AvailableBenefitsElectric>;

export type ProductInvoiceGasDiscountsData = {
  percentage?: string;
  value?: string;
  description?: string;
  customers?: string;
  indefinite?: boolean;
  startDate?: Date | null | undefined;
  endDate?: Date | null | undefined;
};

export type AvailibleDiscountsGas = Record<string, TableDiscountProps>;

export type DiscountsTypeDiscountsGas = Record<string, AvailibleDiscountsGas>;

export type ProductInvoiceGasBenefitsData = {
  percentage?: string;
  value?: string;
  description?: string;
  customers?: string;
  indefinite?: boolean;
  startDate?: Date | null | undefined;
  endDate?: Date | null | undefined;
};

export type AvailableBenefitsGas = Record<string, TableDiscountProps>;

export type DiscountsTypeBenefitsGas = Record<string, AvailableBenefitsGas>;

export const discountElectricCast = (
  obj?: ProductLinkedFields,
): LinkedInvoicesTypes['discountsElectric'] | undefined => {
  if (!obj?.discountsElectric?.discounts) {
    return undefined;
  }

  const discounts: DiscountsTypeDiscounts = {};

  obj.discountsElectric.discounts.forEach(discount => {
    const tableDiscount: TableDiscountProps = {
      _id: discount._id,
      type: discount.discBenefType,
      name: discount.name,
      provider: '',
      connected: 0,
      modDate: '',
      modEmail: '',
      editable: true, // if RAE ? false : true
      link: discount.link,
      radioDisc: discount.radioDisc,
      descr: discount.descr,
      clients: discount.clients,
      linkInvoices: [],
      category: '',
      energy: discount.energy,
      tieredBilling: discount.tieredBilling,
      chargeRows: discount?.chargeScale
        ? chargeRowsAsString(discount?.chargeScale)
        : undefined,
      status: 0,
    };

    if (!discounts[discount.discBenefType._id]) {
      discounts[discount.discBenefType._id] = {};
    }

    discounts[discount.discBenefType._id][discount._id] = tableDiscount;
  });

  return discounts;
};

export const discountGasCast = (
  obj?: ProductLinkedFields,
): LinkedInvoicesTypes['discountsGas'] | undefined => {
  if (!obj?.discountsGas?.discounts) {
    return undefined;
  }

  const discounts: DiscountsTypeDiscounts = {};

  obj.discountsGas.discounts.forEach(discount => {
    const tableDiscount: TableDiscountProps = {
      _id: discount._id,
      type: discount.discBenefType,
      name: discount.name,
      provider: '',
      connected: 0,
      modDate: '',
      modEmail: '',
      editable: true, // if RAE ? false : true
      link: discount.link,
      radioDisc: discount.radioDisc,
      descr: discount.descr,
      clients: discount.clients,
      linkInvoices: [],
      category: '',
      energy: discount.energy,
      tieredBilling: discount.tieredBilling,
      chargeRows: discount?.chargeScale
        ? chargeRowsAsString(discount?.chargeScale)
        : undefined,
      status: 0,
    };

    if (!discounts[discount.discBenefType._id]) {
      discounts[discount.discBenefType._id] = {};
    }

    discounts[discount.discBenefType._id][discount._id] = tableDiscount;
  });

  return discounts;
};

export const benefitElectricCast = (
  obj?: ProductLinkedFields,
): LinkedInvoicesTypes['benefitsElectric'] | undefined => {
  if (!obj?.benefitsElectric?.benefits) {
    return undefined;
  }

  const benefits: DiscountsTypeDiscounts = {};

  obj.benefitsElectric.benefits.forEach(benef => {
    const tableBenefits: TableDiscountProps = {
      _id: benef._id,
      type: benef.discBenefType,
      name: benef.name,
      nameEn: benef.nameEn,
      provider: '',
      connected: 0,
      modDate: '',
      modEmail: '',
      editable: true, // if RAE ? false : true
      link: benef.link,
      linkEn: benef.linkEn,
      descr: benef.descr,
      descrEn: benef.descrEn,
      // explanation: benef.explanation,
      clients: benef.clients,
      linkInvoices: [],
      category: '',
      energy: benef.energy,
      status: 0,
    };

    if (!benefits[benef.discBenefType._id]) {
      benefits[benef.discBenefType._id] = {};
    }

    benefits[benef.discBenefType._id][benef._id] = tableBenefits;
  });

  return benefits;
};

export const benefitGasCast = (
  obj?: ProductLinkedFields,
): LinkedInvoicesTypes['benefitsGas'] | undefined => {
  if (!obj?.benefitsGas?.benefits) {
    return undefined;
  }

  const benefits: DiscountsTypeDiscounts = {};

  obj.benefitsGas.benefits.forEach(benef => {
    const tableBenefits: TableDiscountProps = {
      _id: benef._id,
      type: benef.discBenefType,
      name: benef.name,
      nameEn: benef.nameEn,
      provider: '',
      connected: 0,
      modDate: '',
      modEmail: '',
      editable: true, // if RAE ? false : true
      link: benef.link,
      linkEn: benef.linkEn,
      descr: benef.descr,
      descrEn: benef.descrEn,
      // explanation: benef.explanation,
      clients: benef.clients,
      linkInvoices: [],
      category: '',
      energy: benef.energy,
      status: 0,
    };

    if (!benefits[benef.discBenefType._id]) {
      benefits[benef.discBenefType._id] = {};
    }

    benefits[benef.discBenefType._id][benef._id] = tableBenefits;
  });

  return benefits;
};
