import { TableCompFeesProps } from '@rae/ui-library/src/components/table/TableRowCompFees';
import moment from 'moment';
import { ApiSchema, Identifier } from './ApiSchema';
import { AuthUser } from './Auth';
import { EnergyType } from './EnergyType';
import { ProductElectric } from './ProductElectric';
import { TableCompeFeesHistoryProps } from '@rae/ui-library/src/components/table/TableRowCompeFeesHistory';
import { TableCompFeesHistoryGasProps } from '@rae/ui-library/src/components/table/TableRowCompFeesHistoryGas';
import { categoryOfSupplyLabel } from './CategoryOfSupply';
import { ProductGas } from './ProductGas';
import {
  ClauseCoefficient,
  clauseCoefficientMethodologyLabel,
} from './ClauseCoefficient';
import { tieredBillingLabel } from './TieredBilling';
import { Product } from './Product';
import { factorXExpressionToString } from './FactorX';
import { TransparencyType } from './TransparencyType';

export enum CompetitiveFeeIdsElectricEnum {
  Stable = '645b6592640b27b1d09c3259',
  Established = '657065b59b425d4d757f323f',

  NoLimitExPost = '645b65a5640b27b1d09c3262',
  NoLimitExAnte = '65718eaf9b425d4d757f4a25',

  WithLimitExPost = '645b65b4640b27b1d09c326b',
  WithLimitExAnte = '65718fdb9b425d4d757f4a71',

  NoClauseExPost = '645b65c6640b27b1d09c3274',
  NoClauseExAnte = '6571910a9b425d4d757f4af3',

  // Monthly = '645b65d4640b27b1d09c327d',

  MixedConsumption = '65f0275cfdb081061ff5c49e',
  MixedTime = '65f0270bfdb081061ff5c495',
}
export enum CompetitiveFeeIdsGasEnum {
  Stable = '645b65e2640b27b1d09c3286',
  NoLimit = '645b65f2640b27b1d09c328f',
  NoClause = '645b6601640b27b1d09c3298',
}

export type CompetitiveFeesFields = {
  name?: string;
  nameEn?: string;
  info?: string;
  infoEn?: string;
  energyType?: EnergyType;
  transparencyType?: TransparencyType;
  modUser?: AuthUser;
};

export type CompetitiveFees = ApiSchema<CompetitiveFeesFields>;

export type CompFeesParams = {
  search?: string;
  energyType?: EnergyType;
};

export const gasCompetitiveFeesData = (fee?: CompetitiveFees[]): TableCompFeesProps[] => {
  return (
    fee?.map(f => ({
      _id: f._id,
      name: f.name,
      nameEn: f.nameEn,
      transparencyType: f.transparencyType,
      info: f.info,
      infoEn: f.infoEn,
      energyType: f.energyType,
      modDate: moment(f.updatedAt).format('DD/MM/YYYY'),
      modEmail: f.modUser?.email,
    })) ?? []
  );
};

export const editCompetitiveFeesFields = (
  obj: TableCompFeesProps,
): Identifier & CompetitiveFeesFields => {
  return {
    _id: obj._id ? obj._id : '',
    info: obj?.info ? obj?.info : '',
    infoEn: obj?.infoEn ? obj?.infoEn : '',
  };
};

export const competitiveFeeHistoryElectric = (
  products?: Product[],
): TableCompeFeesHistoryProps[] => {
  const productsArray = products as ProductElectric[];
  return (
    productsArray?.map(p => ({
      id: p._id,
      type: p.competitiveFees.competitiveFees.name ?? '',
      program: p.productInfo.nameGr ?? '',
      category: categoryOfSupplyLabel(p.productInfo.providerId.categoryOfSupply).gr,
      dayChargeScale:
        p.competitiveFees.dayChargeScale && p.competitiveFees.dayChargeScale.length > 0
          ? p.competitiveFees.dayChargeScale.map(
              d => `${d.from ?? '0'}-${d.to ?? 'INF'}: ${d.amount}€/kWh`,
            )
          : ['-'],
      nightChargeScale:
        p.competitiveFees.nightChargeScale &&
        p.competitiveFees.nightChargeScale.length > 0
          ? p.competitiveFees.nightChargeScale.map(
              d => `${d.from ?? '0'}-${d.to ?? 'INF'}: ${d.amount}€/kWh`,
            )
          : ['-'],
      provider: p.productInfo.team.nameGr,
    })) ?? []
  );
};

export const competitiveFeeHistoryElectricDetails = (
  product?: Product,
  clauses?: ClauseCoefficient[],
): TableCompeFeesHistoryProps[] => {
  const productsArray = [product, ...(product?.versions ?? [])] as ProductElectric[];
  return (
    productsArray?.map(p => ({
      id: p._id,
      type: p.competitiveFees.competitiveFees.name ?? '',
      program: p.productInfo.nameGr ?? '',
      category: categoryOfSupplyLabel(p.productInfo.providerId.categoryOfSupply).gr,
      dayTieredBilling: tieredBillingLabel(p.competitiveFees.dayTieredBilling),
      dayChargeScale:
        p.competitiveFees.dayChargeScale?.map(
          d => `${d.from ?? '0'}-${d.to ?? 'INF'}: ${d.amount}€/kWh`,
        ) ?? [],
      nightTieredBilling: tieredBillingLabel(p.competitiveFees.nightTieredBilling),
      nightChargeScale:
        p.competitiveFees.nightChargeScale?.map(
          d => `${d.from ?? '0'}-${d.to ?? 'INF'}: ${d.amount}€/kWh`,
        ) ?? [],
      provider: p.productInfo.team.nameGr,
      version: moment(p.productInfo.availabilityDate).format('DD/MM/YYYY'),
      methodology: clauseCoefficientMethodologyLabel(p.competitiveFees.clauseMethodology)
        .gr,
      coefficientsA: p.competitiveFees.factorA?.toString() ?? '',
      coefficientsB: p.competitiveFees.factorB?.toString() ?? '',
      coefficientsX:
        factorXExpressionToString(p.competitiveFees.factorX?.expression, clauses) ?? '',
      upProtection: p.competitiveFees.protectionUpper?.toString() ?? '',
      downProtection: p.competitiveFees.protectionLower?.toString() ?? '',
      maxReduction: p.competitiveFees.maxReduction?.toString() ?? '',
      maxIncrease: p.competitiveFees.maxRaise?.toString() ?? '',
    })) ?? []
  );
};

export const competitiveFeeHistoryGas = (
  products?: Product[],
): TableCompFeesHistoryGasProps[] => {
  const productsArray = products as ProductGas[];
  return (
    productsArray?.map(p => ({
      id: p._id,
      type: p.competitiveFees.competitiveFees.name ?? '',
      program: p.productInfo.nameGr ?? '',
      category: categoryOfSupplyLabel(p.productInfo.providerId.categoryOfSupply).gr,
      chargeScale:
        p.competitiveFees.dayChargeScale && p.competitiveFees.dayChargeScale.length > 0
          ? p.competitiveFees.dayChargeScale?.map(
              d => `${d.from ?? '0'}-${d.to ?? 'INF'}: ${d.amount}€/kWh`,
            )
          : ['-'],
      provider: p.productInfo.team.nameGr,
    })) ?? []
  );
};

export const competitiveFeeHistoryGasDetails = (
  product?: Product,
  clauses?: ClauseCoefficient[],
): TableCompFeesHistoryGasProps[] => {
  const productsArray = [product, ...(product?.versions ?? [])] as ProductGas[];
  return (
    productsArray?.map(p => ({
      id: p._id,
      type: p.competitiveFees.competitiveFees.name ?? '',
      program: p.productInfo.nameGr ?? '',
      category: categoryOfSupplyLabel(p.productInfo.providerId.categoryOfSupply).gr,
      tieredBilling: tieredBillingLabel(p.competitiveFees.dayTieredBilling),
      chargeScale:
        p.competitiveFees.dayChargeScale?.map(
          d => `${d.from ?? '0'}-${d.to ?? 'INF'}: ${d.amount}€/kWh`,
        ) ?? [],
      provider: p.productInfo.team.nameGr,
      version: moment(p.productInfo.availabilityDate).format('DD/MM/YYYY'),
      coefficientsA: p.competitiveFees.factorA?.toString() ?? '',
      coefficientsB: p.competitiveFees.factorB?.toString() ?? '',
      coefficientsX:
        factorXExpressionToString(p.competitiveFees.factorX?.expression, clauses) ?? '',
      upProtection: p.competitiveFees.protectionUpper?.toString() ?? '',
      downProtection: p.competitiveFees.protectionLower?.toString() ?? '',
      dynamicChargeNorth: p.competitiveFees.dynamicChargeNorth?.toString() ?? '',
      energyChargeNorth: p.competitiveFees.energyChargeNorth?.toString() ?? '',
      dynamicChargeSouth: p.competitiveFees.dynamicChargeSouth?.toString() ?? '',
      energyChargeSouth: p.competitiveFees.energyChargeSouth?.toString() ?? '',
    })) ?? []
  );
};
