import { toNumber } from 'lodash';

export type ChargeRows = {
  from?: number;
  to?: number;
  amount: number;
};

export const chargeRowsAsString = (
  chargeRows: ChargeRows[],
): { from: string; to: string; amount: string }[] => {
  return chargeRows.map(chargeRow => ({
    from: chargeRow.from !== undefined ? chargeRow.from.toString() : '',
    to: chargeRow.to !== undefined ? chargeRow.to.toString() : '',
    amount: chargeRow.amount.toString(),
  }));
};

export const chargeRowsAsNumber = (
  chargeRows: { from: string; to: string; amount: string }[],
): ChargeRows[] => {
  return chargeRows.map((chargeRow, index, chargeRows) => ({
    from: index === 0 ? 0 : toNumber(chargeRow.from),
    to: index === chargeRows.length - 1 ? undefined : toNumber(chargeRow.to),
    amount: toNumber(chargeRow.amount),
  }));
};

export type ChargeRowsNoAmount = {
  from?: number;
  to?: number;
};

export const chargeRowsNoAmountAsString = (
  chargeRows: ChargeRowsNoAmount[],
): { from: string; to: string }[] => {
  return chargeRows.map(chargeRow => ({
    from: chargeRow.from !== undefined ? chargeRow.from.toString() : '',
    to: chargeRow.to !== undefined ? chargeRow.to.toString() : '',
  }));
};

export const chargeRowsNoAmountAsNumber = (
  chargeRows: { from: string; to: string }[],
): ChargeRowsNoAmount[] => {
  return chargeRows.map((chargeRow, index, chargeRows) => ({
    from: index === 0 ? 0 : toNumber(chargeRow.from),
    to: index === chargeRows.length - 1 ? undefined : toNumber(chargeRow.to),
  }));
};
