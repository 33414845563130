import { toGreeklish } from 'greek-utils';
import { v4 as uuidv4 } from 'uuid';

export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      try {
        const data = reader.result!.toString().split(',')[1];
        return resolve(data);
      } catch (error) {
        return reject(error);
      }
    };
    reader.onerror = error => reject(error);
  });

export const toFileName = (name: string, uuid?: boolean): string => {
  return `${toGreeklish(name)}${uuid ? `-${uuidv4()}` : ''}`;
};
