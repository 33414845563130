import React, { FC, useCallback } from 'react';
import { Box, BoxProps, IconButton, useTheme } from '@mui/material';
import { ButtonRae, Input, RadioButtonNew } from '@rae/ui-library';
import CachedIcon from '@mui/icons-material/Cached';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  TieredBilling,
  TieredBillingEnum,
  tieredBillingLabel,
} from '../../types/TieredBilling';
import { toNumber } from 'lodash';

type ChargeScalesProps = Omit<BoxProps, 'onChange'> & {
  tieredBilling?: TieredBilling;
  setTieredBilling: (tieredBilling: TieredBilling) => void;
  chargeRows?: { amount: string; from: string; to: string }[];
  setChargeRows: (chargeRows?: { amount: string; from: string; to: string }[]) => void;
  onReset: () => void;
  disabled?: boolean;
  amountLabel?: string;
};

const ChargeScales: FC<ChargeScalesProps> = ({
  tieredBilling,
  setTieredBilling,
  chargeRows,
  setChargeRows,
  onReset,
  disabled,
  amountLabel,
  ...props
}) => {
  const theme = useTheme();

  const handleAddRow = useCallback(() => {
    const stateRows = chargeRows ? structuredClone(chargeRows) : [];
    stateRows.push({ amount: '0', from: '', to: '' });
    setChargeRows(stateRows);
  }, [chargeRows, setChargeRows]);

  const handleDeleteRow = useCallback(
    (i: any) => {
      const stateRows = chargeRows ? structuredClone(chargeRows) : [];
      if (stateRows[i + 1]) {
        stateRows[i + 1].from = stateRows[i]?.from;
      } else {
        stateRows[i - 1].to = '';
      }
      stateRows.splice(i, 1);
      setChargeRows(stateRows);
    },
    [chargeRows, setChargeRows],
  );

  const handleChangeRow = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      i: number,
      name: 'from' | 'to' | 'amount',
    ) => {
      const stateRows = chargeRows ? structuredClone(chargeRows) : [];
      switch (name) {
        case 'from':
          stateRows[i].from = event.target.value
            ? toNumber(event.target.value).toFixed(0)
            : '';
          if (stateRows?.[i - 1]) {
            stateRows[i - 1].to = event.target.value
              ? (toNumber(event.target.value) - 1).toFixed(0)
              : '';
          }
          break;
        case 'to':
          stateRows[i].to = event.target.value
            ? toNumber(event.target.value).toFixed(0)
            : '';
          if (stateRows?.[i + 1]) {
            stateRows[i + 1].from = event.target.value
              ? (toNumber(event.target.value) + 1).toFixed(0)
              : '';
          }
          break;
        case 'amount':
          stateRows[i].amount = event.target.value ?? '0';
          break;
        default:
          break;
      }
      setChargeRows(stateRows);
    },
    [chargeRows, setChargeRows],
  );

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <RadioButtonNew
          disableRadio={disabled}
          radio={[
            {
              label: tieredBillingLabel(TieredBillingEnum.Single),
              value: TieredBillingEnum.Single,
            },
            {
              label: tieredBillingLabel(TieredBillingEnum.Scalar),
              value: TieredBillingEnum.Scalar,
            },
            {
              label: tieredBillingLabel(TieredBillingEnum.Cumulative),
              value: TieredBillingEnum.Cumulative,
            },
          ]}
          value={tieredBilling ? tieredBilling : ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setTieredBilling(event.target.value as TieredBilling);
            // setChargeRows([{ amount: '0', from: '', to: '' }]);
          }}
        />
        {chargeRows && chargeRows?.length > 0 ? (
          <ButtonRae
            variant="contained"
            sx={{ bgcolor: '#2357AE' }}
            onClick={onReset}
            startIcon={<CachedIcon />}
            disabled={disabled}
          >
            Επαναφορά
          </ButtonRae>
        ) : null}
      </Box>
      {chargeRows && tieredBilling
        ? chargeRows?.map((row, i) => {
            return (
              <Box
                key={i}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  mt: 3,
                }}
              >
                <Input
                  sx={{
                    mt: 2,
                    width: '300px',
                    mr: { xs: 0, md: 4 },
                  }}
                  inputType={'number'}
                  disabled={i === 0 || disabled}
                  color="primary"
                  type={i === 0 ? 'lock' : 'default'}
                  label="Από"
                  value={i === 0 ? '0' : row.from?.toString() ?? ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeRow(event, i, 'from')
                  }
                />
                <Input
                  sx={{
                    mt: 2,
                    width: '300px',
                    mr: { xs: 0, md: 4 },
                  }}
                  inputType={i + 1 === chargeRows?.length ? 'text' : 'number'}
                  disabled={i + 1 === chargeRows?.length || disabled}
                  color="primary"
                  type={i + 1 === chargeRows?.length ? 'lock' : 'default'}
                  label="Εώς"
                  value={i + 1 === chargeRows?.length ? 'INF' : row.to?.toString() ?? ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeRow(event, i, 'to')
                  }
                />
                <Input
                  sx={{
                    mt: 2,
                    width: '350px',
                    mr: { xs: 0, md: 4 },
                  }}
                  disabled={disabled}
                  inputType="number"
                  color="primary"
                  type="default"
                  label={amountLabel ?? 'Ποσό'}
                  value={row.amount?.toString()}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeRow(event, i, 'amount')
                  }
                />
                {i === 0 &&
                tieredBilling &&
                tieredBilling !== TieredBillingEnum.Single ? (
                  <IconButton
                    sx={{ mt: 2, color: theme.palette.primary.main }}
                    onClick={handleAddRow}
                    disabled={disabled}
                  >
                    <AddCircleOutlineIcon fontSize="large" />
                  </IconButton>
                ) : null}
                {i !== 0 ? (
                  <IconButton
                    sx={{ mt: 2, color: '#87007B' }}
                    onClick={() => handleDeleteRow(i)}
                    disabled={disabled}
                  >
                    <RemoveCircleOutlineIcon fontSize="large" />
                  </IconButton>
                ) : null}
              </Box>
            );
          })
        : null}
    </Box>
  );
};

export default ChargeScales;
