import { createSlice } from '@reduxjs/toolkit';
import { AuthUser } from '../../types/Auth';
import authApi from '../apis/authApi';
import {
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from '../../utils/TokenStorage';
import { Team } from '../../types/Team';
import { currentTeam } from '../../utils/PickTeam';

interface AuthState {
  user?: AuthUser;
  accessToken?: string;
  refreshToken?: string;
  team?: Team;
}

const initialState: AuthState = {
  user: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  team: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTokens(state, { payload }) {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      setAccessToken(payload.accessToken);
      setRefreshToken(payload.refreshToken);
    },
    clear(state) {
      state.accessToken = undefined;
      state.refreshToken = undefined;
      state.user = undefined;
      removeAccessToken();
      removeRefreshToken();
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
        state.accessToken = payload.accessToken;
        state.refreshToken = payload.refreshToken;
        setAccessToken(payload.accessToken);
        setRefreshToken(payload.refreshToken);
        return state;
      })
      .addMatcher(authApi.endpoints.renew.matchFulfilled, (state, { payload }) => {
        state.accessToken = payload.accessToken;
        state.refreshToken = payload.refreshToken;
        setAccessToken(payload.accessToken);
        setRefreshToken(payload.refreshToken);
      })
      .addMatcher(authApi.endpoints.logout.matchFulfilled, state => {
        state.accessToken = undefined;
        state.refreshToken = undefined;
        state.user = undefined;
        state.team = undefined;
        removeAccessToken();
        removeRefreshToken();
      })
      .addMatcher(authApi.endpoints.logout.matchRejected, state => {
        state.accessToken = undefined;
        state.refreshToken = undefined;
        state.user = undefined;
        state.team = undefined;
        removeAccessToken();
        removeRefreshToken();
      })
      .addMatcher(authApi.endpoints.getUser.matchFulfilled, (state, { payload }) => {
        state.user = payload;
      })
      .addMatcher(authApi.endpoints.getTeam.matchFulfilled, (state, { payload }) => {
        state.team = currentTeam(payload.teams);
      });
  },
});

export const { clear, setTokens } = authSlice.actions;

export default authSlice;
