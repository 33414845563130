import { Box } from '@mui/material';
import { DropdownNew } from '@rae/ui-library';
import React, { useCallback, useMemo, useState } from 'react';
import { ProductElectricTypes } from './ProductElectricTypes';
import { FloatingChargeLimit } from './ProductFeesSections/FloatingChargeLimitSection';
import { FloatingChargeNoClause } from './ProductFeesSections/FloatingChargeNoClause';
import { FloatingChargeNoLimit } from './ProductFeesSections/FloatingChargeNoLimitSection';
import { StableSection } from './ProductFeesSections/StableSection';
import { OptionsProps } from '@rae/ui-library/src/components/Calculator';
import { TableCompFeesProps } from '@rae/ui-library/src/components/table/TableRowCompFees';
import { Choice } from '@rae/ui-library/src/components/DropdownNew';
import { ReviewTool } from './actions/ReviewTool';
import {
  disableFields,
  ReviewModeEnum,
  showReviewComments,
  viewOnlyReviewComments,
} from '../../types/ReviewMode';
import { CompetitiveFeeIdsElectricEnum } from '../../types/CompetitiveFees';
import { transparencyTypeColor } from '../../types/TransparencyType';
import { MixedSection } from './ProductFeesSections/MixedSection';

type Obj = {
  data: ProductElectricTypes;
  setData: (newState: any) => void;
  count?: string[];
  compFeesOptions: OptionsProps[];
  calculatorData: OptionsProps[][];
  setCalculatorData: (newState: any) => void;
  compFees: TableCompFeesProps[];
  reviewMode: ReviewModeEnum;
  addComment: (id: string, comment: string) => void;
};

export const CompetitiveFeesTab = ({
  count,
  compFeesOptions,
  calculatorData,
  setCalculatorData,
  data,
  setData,
  compFees,
  reviewMode,
  addComment,
}: Obj) => {
  const [openType, setopenType] = useState<boolean>(false);
  const [openScaleConsumption, setopenScaleConsumption] = useState<boolean>(false);

  const compFeesChoices = useMemo((): Choice[] => {
    return (
      compFees?.map(comp => ({
        value: comp._id ? comp._id : '',
        label: comp.name ? comp.name : '',
        color: transparencyTypeColor(comp.transparencyType),
      })) ?? []
    );
  }, [compFees]);

  const renderSection = useMemo(() => {
    switch (data.competitiveFeeType) {
      case CompetitiveFeeIdsElectricEnum.Stable:
      case CompetitiveFeeIdsElectricEnum.Established:
        return (
          <StableSection
            count={count}
            obj={data}
            setObj={setData}
            addComment={addComment}
            reviewMode={reviewMode}
          />
        );
      case CompetitiveFeeIdsElectricEnum.NoLimitExPost:
      case CompetitiveFeeIdsElectricEnum.NoLimitExAnte:
        return (
          <FloatingChargeNoLimit
            noLimitData={calculatorData}
            setNoLimitData={setCalculatorData}
            count={count}
            obj={data}
            setObj={setData}
            compFeesOptions={compFeesOptions}
            addComment={addComment}
            reviewMode={reviewMode}
          />
        );
      case CompetitiveFeeIdsElectricEnum.WithLimitExPost:
      case CompetitiveFeeIdsElectricEnum.WithLimitExAnte:
        return (
          <FloatingChargeLimit
            count={count}
            obj={data}
            setObj={setData}
            compFeesOptions={compFeesOptions}
            limitData={calculatorData}
            setLimitData={setCalculatorData}
            addComment={addComment}
            reviewMode={reviewMode}
          />
        );
      case CompetitiveFeeIdsElectricEnum.NoClauseExPost:
      case CompetitiveFeeIdsElectricEnum.NoClauseExAnte:
        return (
          <FloatingChargeNoClause
            noClauseData={calculatorData}
            setNoClauseData={setCalculatorData}
            obj={data}
            setObj={setData}
            compFeesOptions={compFeesOptions}
            addComment={addComment}
            reviewMode={reviewMode}
          />
        );
      // case CompetitiveFeeIdsElectricEnum.Monthly:
      //   return (
      //     <MonthlyVariable
      //       count={count}
      //       obj={data}
      //       setObj={setData}
      //       addComment={addComment}
      //       reviewMode={reviewMode}
      //     />
      //   );
      case CompetitiveFeeIdsElectricEnum.MixedConsumption:
      case CompetitiveFeeIdsElectricEnum.MixedTime:
        return (
          <MixedSection
            count={count}
            obj={data}
            setObj={setData}
            addComment={addComment}
            reviewMode={reviewMode}
            calculatorData={calculatorData}
            setCalculatorData={setCalculatorData}
            compFeesOptions={compFeesOptions}
            compFees={compFees}
          />
        );
      default:
        return <></>;
    }
  }, [
    data,
    count,
    setData,
    addComment,
    reviewMode,
    calculatorData,
    setCalculatorData,
    compFeesOptions,
    compFees,
  ]);

  const handleSetSelected = useCallback(
    (type: any) => {
      setData((values: ProductElectricTypes) => ({
        ...values,
        competitiveFeeType: type[0],
        competitiveChargeScale:
          type[0] === CompetitiveFeeIdsElectricEnum.MixedConsumption ||
          type[0] === CompetitiveFeeIdsElectricEnum.MixedTime
            ? [{ from: '', to: '' }]
            : undefined,
        competitiveChargeScaleConsumption: undefined,
        competitiveFee:
          type[0] === CompetitiveFeeIdsElectricEnum.MixedConsumption ||
          type[0] === CompetitiveFeeIdsElectricEnum.MixedTime
            ? [{}]
            : [{ type: type[0] }],
        otherCharges: [{}],
        discounts: [{}],
        benefits: [{}],
        clauses: [{}],
      }));
      setCalculatorData([[]]);
    },
    [setCalculatorData, setData],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        mt: 3,
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <DropdownNew
          sx={{ width: { xs: '300px', md: '450px' }, mt: 2 }}
          multiline={false}
          disabled={disableFields(reviewMode)}
          label="Τύπος Ανταγωνιστικής Χρέωσης"
          labelBtn="Εφαρμογή"
          choices={compFeesChoices}
          open={openType}
          setOpen={setopenType}
          selected={data?.competitiveFeeType ? [data?.competitiveFeeType] : []}
          setSelected={handleSetSelected}
          color={transparencyTypeColor(
            compFees?.find(f => f._id === data.competitiveFeeType)?.transparencyType,
          )}
        />
        {showReviewComments(reviewMode) ? (
          <ReviewTool
            view={viewOnlyReviewComments(reviewMode)}
            msg={data.reviewComments?.competitiveFees?.competitiveFees}
            id="competitiveFees.competitiveFees"
            title="Ανταγωνιστική Χρέωση"
            addComment={addComment}
          />
        ) : null}
      </Box>

      {data?.competitiveFeeType === CompetitiveFeeIdsElectricEnum.MixedConsumption && (
        <DropdownNew
          sx={{ width: { xs: '300px', md: '450px' }, mt: 2 }}
          multiline={false}
          disabled={disableFields(reviewMode)}
          label="Κλίμακα Χρέωσης με βάση kWh"
          labelBtn="Εφαρμογή"
          choices={[
            {
              value: 'day',
              label: 'Ημέρας',
            },
            {
              value: 'night',
              label: 'Νύχτας',
            },
            {
              value: 'both',
              label: 'Άθροισμα Ημέρας και Νύχτας',
            },
            {
              value: 'highest',
              label: 'Υψηλότερη Ημέρας και Νύχτας',
            },
          ]}
          open={openScaleConsumption}
          setOpen={setopenScaleConsumption}
          selected={
            data?.competitiveChargeScaleConsumption
              ? [data?.competitiveChargeScaleConsumption]
              : []
          }
          setSelected={(type: any) => {
            setData((values: ProductElectricTypes) => ({
              ...values,
              competitiveChargeScaleConsumption: type[0],
            }));
          }}
        />
      )}

      <Box sx={{ mt: 4, p: 1 }}>{renderSection}</Box>
    </Box>
  );
};
