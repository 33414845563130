import React, { useCallback, useRef } from 'react';
import { ProductElectricTypes } from '../ProductElectricTypes';
import { disableFields, ReviewModeEnum } from '../../../types/ReviewMode';
import { OptionsProps } from '@rae/ui-library/src/components/Calculator';
import { CompetitiveFeeIdsElectricEnum } from '../../../types/CompetitiveFees';
import { StableSection } from './StableSection';
import { FloatingChargeNoLimit } from './FloatingChargeNoLimitSection';
import { FloatingChargeLimit } from './FloatingChargeLimitSection';
import { FloatingChargeNoClause } from './FloatingChargeNoClause';
import ChargeScalesMixed from '../../ChargeScales/ChargeScalesMixed';
import { TableCompFeesProps } from '@rae/ui-library/dist/cjs/types/src/components/table/TableRowCompFees';

type Obj = {
  obj: ProductElectricTypes;
  setObj: (newState: any) => void;
  count?: string[];
  reviewMode: ReviewModeEnum;
  addComment: (id: string, comment: string) => void;
  calculatorData: OptionsProps[][];
  setCalculatorData: (newState: any) => void;
  compFeesOptions: OptionsProps[];
  compFees: TableCompFeesProps[];
};

export const MixedSection = ({
  obj,
  setObj,
  count,
  addComment,
  reviewMode,
  calculatorData,
  setCalculatorData,
  compFeesOptions,
  compFees,
}: Obj) => {
  const initialStateChargeRows = useRef(structuredClone(obj?.competitiveChargeScale));
  const initialStateCompetitiveFees = useRef(structuredClone(obj?.competitiveFee));

  const renderSection = useCallback(
    (index: number, type?: string) => {
      switch (type) {
        case CompetitiveFeeIdsElectricEnum.Stable:
        case CompetitiveFeeIdsElectricEnum.Established:
          return (
            <StableSection
              key={index}
              index={index}
              count={count}
              obj={obj}
              setObj={setObj}
              addComment={addComment}
              reviewMode={reviewMode}
            />
          );
        case CompetitiveFeeIdsElectricEnum.NoLimitExPost:
        case CompetitiveFeeIdsElectricEnum.NoLimitExAnte:
          return (
            <FloatingChargeNoLimit
              key={index}
              index={index}
              noLimitData={calculatorData}
              setNoLimitData={setCalculatorData}
              count={count}
              obj={obj}
              setObj={setObj}
              compFeesOptions={compFeesOptions}
              addComment={addComment}
              reviewMode={reviewMode}
            />
          );
        case CompetitiveFeeIdsElectricEnum.WithLimitExPost:
        case CompetitiveFeeIdsElectricEnum.WithLimitExAnte:
          return (
            <FloatingChargeLimit
              key={index}
              index={index}
              count={count}
              obj={obj}
              setObj={setObj}
              compFeesOptions={compFeesOptions}
              limitData={calculatorData}
              setLimitData={setCalculatorData}
              addComment={addComment}
              reviewMode={reviewMode}
            />
          );
        case CompetitiveFeeIdsElectricEnum.NoClauseExPost:
        case CompetitiveFeeIdsElectricEnum.NoClauseExAnte:
          return (
            <FloatingChargeNoClause
              key={index}
              index={index}
              noClauseData={calculatorData}
              setNoClauseData={setCalculatorData}
              obj={obj}
              setObj={setObj}
              compFeesOptions={compFeesOptions}
              addComment={addComment}
              reviewMode={reviewMode}
            />
          );
        default:
          return <></>;
      }
    },
    [
      addComment,
      calculatorData,
      compFeesOptions,
      count,
      obj,
      reviewMode,
      setCalculatorData,
      setObj,
    ],
  );

  return (
    <ChargeScalesMixed
      type={obj.competitiveFeeType}
      chargeRows={obj.competitiveChargeScale}
      setChargeRows={chargeRows =>
        setObj((values: ProductElectricTypes) => ({
          ...values,
          competitiveChargeScale: chargeRows,
          otherCharges: Array.from({ length: chargeRows?.length ?? 0 }, () => []),
          discounts: Array.from({ length: chargeRows?.length ?? 0 }, () => []),
          benefits: Array.from({ length: chargeRows?.length ?? 0 }, () => []),
          clauses: Array.from({ length: chargeRows?.length ?? 0 }, () => []),
        }))
      }
      competitiveFees={obj.competitiveFee}
      setCompetitiveFees={comp => {
        setObj((values: ProductElectricTypes) => ({
          ...values,
          competitiveFee: comp,
        }));
      }}
      compFees={compFees}
      components={obj.competitiveFee?.map((comp, index) =>
        renderSection(index, comp.type),
      )}
      duration={obj.info?.duration}
      onReset={() => {
        setObj((values: ProductElectricTypes) => ({
          ...values,
          competitiveChargeScale: initialStateChargeRows.current,
          competitiveFee: initialStateCompetitiveFees.current,
        }));
      }}
      setCalcData={setCalculatorData}
      disabled={disableFields(reviewMode)}
    />
  );
};
