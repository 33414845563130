import { Box, Divider, Paper, Typography, useTheme } from '@mui/material';
import { ButtonRae, Input } from '@rae/ui-library';
import Success from '../../assets/success_reset.png';
import Error from '../../assets/error_reset.png';
import { ForgotPasswordRequest } from '../../types/Auth';
import Loader from '../../assets/loading.gif';

export type ShowResetErrorProps = {
  email?: boolean;
  submit?: boolean;
};

export type ResetErrorMsg = {
  email?: string;
};

type Props = {
  email: ForgotPasswordRequest;
  setEmail: (newState: any) => void;
  handleReset: () => void;
  showError: ShowResetErrorProps;
  errorMsg: ResetErrorMsg;
  isLoading?: boolean;
};

export const ResetPassCard = ({
  email,
  errorMsg,
  handleReset,
  setEmail,
  showError,
  isLoading,
}: Props) => {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        width: 492,
        height: showError.submit === true || showError.submit === false ? 520 : 381,
        borderRadius: '16px',
        p: 4,
        position: 'relative',
      }}
      elevation={3}
    >
      {!isLoading ? (
        <>
          {showError.submit === true || showError.submit === false ? (
            <Box>
              <img
                src={showError.submit && showError.submit ? Error : Success}
                alt="status"
              />
            </Box>
          ) : null}
          <Box sx={{ mt: 2 }}>
            <Box>
              <Typography
                sx={{ minWidth: '200px' }}
                color={
                  showError.submit && showError.submit
                    ? theme.palette.error.main
                    : theme.palette.primary.main
                }
                variant="h5"
              >
                {showError.submit && showError.submit
                  ? 'Ανεπιτυχής Αποστολή'
                  : showError && showError.submit === false
                  ? 'Επιτυχής Αποστολή'
                  : 'Επαναφορά Κωδικού πρόσβασης'}
              </Typography>
              <Divider
                sx={{
                  mt: 1,
                  width:
                    showError.submit === true || showError.submit === false
                      ? '50%'
                      : '80%',
                  borderBottomWidth: 4,
                  borderColor:
                    showError.submit && showError.submit
                      ? theme.palette.error.main
                      : theme.palette.primary.main,
                }}
                textAlign="left"
              />
            </Box>
            <Box sx={{ mt: 4 }}>
              <Typography>
                Εισάγετε τη διεύθυνση email που χρησιμοποιήθηκε για τη δημιουργία του
                λογαριασμού σας και θα σας στείλουμε οδηγίες για να επανεκδόσετε τον
                κωδικό σας
              </Typography>
            </Box>
            <Box sx={{ mt: 6 }}>
              <Input
                sx={{ mt: 2, width: '100%' }}
                error={showError.email}
                helperText={showError.email ? errorMsg.email : ''}
                type="default"
                label="Email"
                placeholder="mail@abc.com"
                value={email.email}
                onChange={e =>
                  setEmail({
                    email: e.target.value,
                  })
                }
              />
            </Box>
            <Box sx={{ position: 'absolute', bottom: 20, width: `calc(100% - 64px)` }}>
              <ButtonRae
                color="primary"
                variant="contained"
                sx={{ width: '100%' }}
                onClick={handleReset}
              >
                Επαναφορά
              </ButtonRae>
            </Box>
          </Box>{' '}
        </>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img style={{ width: '140px' }} src={Loader} alt="loading..." />
        </Box>
      )}
    </Paper>
  );
};
