import { Box, Divider, IconButton, Typography, useTheme } from '@mui/material';
import { CheckBoxes } from '@rae/ui-library';
import { CheckBoxesProps } from '@rae/ui-library/src/components/CheckBox';
import React, { useCallback, useMemo, useRef } from 'react';
import { ProductGasOtherCharges, ProductGasTypes } from '../ProductGasTypes';
import { OthersSection } from './OthersSection';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { TableOtherChargesProps } from '@rae/ui-library/src/components/table/TableRowOtherCharges';
import {
  disableFields,
  ReviewModeEnum,
  showReviewComments,
  viewOnlyReviewComments,
} from '../../../types/ReviewMode';
import { ReviewTool } from '../../ProductElectricPage/actions/ReviewTool';
import { TieredBilling } from '../../../types/TieredBilling';
import ChargeScales from '../../ChargeScales/ChargeScales';
import { TableCompFeesProps } from '@rae/ui-library/dist/cjs/types/src/components/table/TableRowCompFees';
import ChargeScalesMixed from '../../ChargeScales/ChargeScalesMixed';

type Obj = {
  obj: ProductGasTypes;
  setObj: (newState: any) => void;
  count: string[];
  otherCharges: TableOtherChargesProps[];
  reviewMode: ReviewModeEnum;
  addComment: (id: string, comment: string) => void;
  compFees: TableCompFeesProps[];
};

export const OtherChargesTab = ({
  obj,
  setObj,
  count,
  otherCharges,
  reviewMode,
  addComment,
  compFees,
}: Obj) => {
  const theme = useTheme();

  const setObjOtherCharge = useCallback(
    (val: ProductGasOtherCharges, index: number) =>
      setObj((values: ProductGasTypes) => {
        const newOtherCharges = values.otherCharges ? [...values.otherCharges] : [];
        newOtherCharges[index] = {
          ...newOtherCharges[index],
          ...val,
        };
        return {
          ...values,
          otherCharges: newOtherCharges,
        };
      }),
    [setObj],
  );

  const initialState = useRef(structuredClone(obj?.otherCharges));

  const checkboxesPagiou: CheckBoxesProps['checkboxes'] =
    otherCharges?.map(charge => ({
      label: charge?.chargeName ? charge?.chargeName : '',
      value: charge?._id ? charge?._id : '',
    })) ?? [];

  const checkboxesOtherCharges: CheckBoxesProps['checkboxes'] = [
    {
      label: 'Άλλο',
      value: 'other',
    },
  ];

  const section = useCallback(
    (item: ProductGasOtherCharges, index: number) => {
      switch (count ? count[0] : '') {
        case 'Ημερήσια':
          return (
            <>
              <Typography variant="h6" color={theme.palette.primary.main} sx={{ mb: 2 }}>
                Ημέρας
              </Typography>
              <Box sx={{ mb: 4 }}>
                <Typography variant="caption" color={theme.palette.primary.main}>
                  Ποσό μηνιαίου παγίου σε ευρώ
                </Typography>
                {showReviewComments(reviewMode) ? (
                  <ReviewTool
                    view={viewOnlyReviewComments(reviewMode)}
                    msg={obj.reviewComments?.otherCharges?.otherDay}
                    id="otherCharges.otherDay"
                    title="Ημέρας"
                    addComment={addComment}
                  />
                ) : null}
                <ChargeScales
                  disabled={disableFields(reviewMode)}
                  chargeRows={item?.daySinglePhase?.chargeRows}
                  setChargeRows={value =>
                    setObjOtherCharge(
                      {
                        ...item,
                        daySinglePhase: {
                          ...item?.daySinglePhase,
                          chargeRows: value,
                        },
                      },
                      index,
                    )
                  }
                  tieredBilling={item?.daySinglePhase?.tieredBilling as TieredBilling}
                  setTieredBilling={value =>
                    setObjOtherCharge(
                      {
                        ...item,
                        daySinglePhase: {
                          ...item?.daySinglePhase,
                          tieredBilling: value,
                          chargeRows: [{ amount: '0', from: '', to: '' }],
                        },
                      },
                      index,
                    )
                  }
                  onReset={() =>
                    setObjOtherCharge(
                      {
                        ...item,
                        daySinglePhase: {
                          ...(initialState.current?.[index].daySinglePhase ?? {}),
                        },
                      },
                      index,
                    )
                  }
                  amountLabel={'Χρέωση μήνα (€)'}
                />
              </Box>
            </>
          );
        case 'Ημερήσια + Νυχτερινή':
          return (
            <>
              <Typography variant="h6" color={theme.palette.primary.main} sx={{ mb: 2 }}>
                Ημέρας
              </Typography>
              <Box sx={{ mb: 4 }}>
                <Typography variant="caption" color={theme.palette.primary.main}>
                  Ποσό μηνιαίου παγίου σε ευρώ
                </Typography>
                {showReviewComments(reviewMode) ? (
                  <ReviewTool
                    view={viewOnlyReviewComments(reviewMode)}
                    msg={obj.reviewComments?.otherCharges?.otherDay}
                    id="otherCharges.otherDay"
                    title="Ημέρας"
                    addComment={addComment}
                  />
                ) : null}
                <ChargeScales
                  disabled={disableFields(reviewMode)}
                  chargeRows={item?.daySinglePhase?.chargeRows}
                  setChargeRows={value =>
                    setObjOtherCharge(
                      {
                        ...item,
                        daySinglePhase: {
                          ...item?.daySinglePhase,
                          chargeRows: value,
                        },
                      },
                      index,
                    )
                  }
                  tieredBilling={item?.daySinglePhase?.tieredBilling as TieredBilling}
                  setTieredBilling={value =>
                    setObjOtherCharge(
                      {
                        ...item,
                        daySinglePhase: {
                          ...item?.daySinglePhase,
                          tieredBilling: value,
                          chargeRows: [{ amount: '0', from: '', to: '' }],
                        },
                      },
                      index,
                    )
                  }
                  onReset={() =>
                    setObjOtherCharge(
                      {
                        ...item,
                        daySinglePhase: {
                          ...(initialState.current?.[index].daySinglePhase ?? {}),
                        },
                      },
                      index,
                    )
                  }
                  amountLabel={'Χρέωση μήνα (€)'}
                />
              </Box>
              <Typography
                variant="h6"
                color={theme.palette.primary.main}
                sx={{ mb: 2, mt: 8 }}
              >
                Νύχτας
              </Typography>
              <Box sx={{ mb: 4 }}>
                <Typography variant="caption" color={theme.palette.primary.main}>
                  Ποσό μηνιαίου παγίου σε ευρώ
                </Typography>
                {showReviewComments(reviewMode) ? (
                  <ReviewTool
                    view={viewOnlyReviewComments(reviewMode)}
                    msg={obj.reviewComments?.otherCharges?.otherNight}
                    id="otherCharges.otherNight"
                    title="Νύχτας"
                    addComment={addComment}
                  />
                ) : null}
                <ChargeScales
                  disabled={disableFields(reviewMode)}
                  chargeRows={item?.nightSinglePhase?.chargeRows}
                  setChargeRows={value =>
                    setObjOtherCharge(
                      {
                        ...item,
                        nightSinglePhase: {
                          ...item?.nightSinglePhase,
                          chargeRows: value,
                        },
                      },
                      index,
                    )
                  }
                  tieredBilling={item?.nightSinglePhase?.tieredBilling as TieredBilling}
                  setTieredBilling={value =>
                    setObjOtherCharge(
                      {
                        ...item,
                        nightSinglePhase: {
                          ...item?.nightSinglePhase,
                          tieredBilling: value,
                          chargeRows: [{ amount: '0', from: '', to: '' }],
                        },
                      },
                      index,
                    )
                  }
                  onReset={() =>
                    setObjOtherCharge(
                      {
                        ...item,
                        nightSinglePhase: {
                          ...(initialState.current?.[index].nightSinglePhase ?? {}),
                        },
                      },
                      index,
                    )
                  }
                  amountLabel={'Χρέωση μήνα (€)'}
                />
              </Box>
            </>
          );
        default:
          break;
      }
    },
    [
      addComment,
      count,
      obj.reviewComments?.otherCharges?.otherDay,
      obj.reviewComments?.otherCharges?.otherNight,
      reviewMode,
      setObjOtherCharge,
      theme.palette.primary.main,
    ],
  );

  const renderSections = useMemo(
    () =>
      obj?.otherCharges?.map((item, i) => (
        <Box
          sx={[
            {
              display: 'flex',
              flexDirection: 'column',
            },
          ]}
          key={i}
        >
          <Box sx={{ mt: 2, mb: 1 }}>
            <Typography
              sx={{ minWidth: '200px', color: theme.palette.primary.main }}
              fontSize={'13px'}
              fontWeight={700}
            >
              Προσθήκη Παγίου
            </Typography>
            <Divider
              sx={{
                mt: 1,
                width: '5%',
                borderBottomWidth: 4,
                borderColor: theme.palette.primary.main,
              }}
              textAlign="left"
            />
          </Box>
          <CheckBoxes
            checked={item?.pagio ? item.pagio : []}
            disabled={
              disableFields(reviewMode) ? checkboxesPagiou.map(item => item.value) : []
            }
            checkboxes={checkboxesPagiou}
            setChecked={type =>
              setObjOtherCharge(
                {
                  ...item,
                  pagio: type[type.length - 1] ? [type[type.length - 1]] : [],
                },
                i,
              )
            }
          />
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.otherCharges?.otherCharges}
              id="otherCharges.otherCharges"
              title="Λοιπές Χρεώσεις"
              addComment={addComment}
            />
          ) : null}
          {item?.pagio && item?.pagio.length > 0 ? <Box>{section(item, i)} </Box> : null}
          <Box sx={{ mt: 4, mb: 1 }}>
            <Typography
              sx={{ minWidth: '200px', color: theme.palette.primary.main }}
              fontSize={'13px'}
              fontWeight={700}
            >
              Προσθήκη Λοιπών Χρεώσεων
            </Typography>
            <Divider
              sx={{
                mt: 1,
                width: '5%',
                borderBottomWidth: 4,
                borderColor: theme.palette.primary.main,
              }}
              textAlign="left"
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckBoxes
                checked={item?.otherCharges ? item?.otherCharges : []}
                checkboxes={checkboxesOtherCharges}
                disabled={
                  disableFields(reviewMode)
                    ? checkboxesOtherCharges.map(item => item.value)
                    : []
                }
                setChecked={type =>
                  setObjOtherCharge(
                    {
                      ...item,
                      otherCharges: type,
                      other:
                        item?.other && item?.other?.length > 0
                          ? item?.other
                          : [
                              {
                                otherName: '',
                                otherDescr: '',
                                otherAmount: '',
                                otherChargeStep: '',
                                otherApply: '',
                              },
                            ],
                    },
                    i,
                  )
                }
              />
              {item?.otherCharges && item?.otherCharges?.length > 0 ? (
                <IconButton
                  sx={{ color: theme.palette.primary.main }}
                  onClick={() => {
                    const rows = item.other ? [...item.other] : [];
                    rows.push({
                      otherAmount: '',
                      otherApply: '',
                      otherChargeStep: '',
                      otherDescr: '',
                      otherName: '',
                    });
                    setObjOtherCharge(
                      {
                        ...item,
                        other: rows,
                      },
                      i,
                    );
                  }}
                  disabled={disableFields(reviewMode)}
                >
                  <AddCircleOutlineIcon fontSize="large" />
                  <Typography sx={{ ml: 1 }}> Δημιουργία νέας χρέωσης</Typography>
                </IconButton>
              ) : null}
            </Box>
            {item?.other?.map((otherItem, otherIndex) => {
              return (
                <OthersSection
                  setItem={val => {
                    const newOther = item.other ? [...item.other] : [];
                    newOther[otherIndex] = val;
                    setObjOtherCharge(
                      {
                        ...item,
                        other: newOther,
                      },
                      i,
                    );
                  }}
                  i={otherIndex}
                  item={otherItem}
                  key={otherIndex}
                  handleDeleteRow={() => {
                    const rows = item.other ? [...item.other] : [];
                    rows.splice(otherIndex, 1);
                    setObjOtherCharge(
                      {
                        ...item,
                        other: rows,
                      },
                      i,
                    );
                  }}
                  disable={disableFields(reviewMode)}
                />
              );
            })}
          </Box>
        </Box>
      )) ?? [],
    [
      addComment,
      checkboxesOtherCharges,
      checkboxesPagiou,
      obj,
      reviewMode,
      section,
      setObjOtherCharge,
      theme.palette.primary.main,
    ],
  );

  return (
    <ChargeScalesMixed
      type={obj.competitiveFeeType}
      competitiveFees={obj.competitiveFee}
      compFees={compFees}
      components={renderSections}
      duration={obj.info?.duration}
      disabled
    />
  );
};
