import { Box, Typography, useTheme } from '@mui/material';
import React, { useCallback, useMemo, useRef } from 'react';
import { ReviewTool } from '../actions/ReviewTool';
import { ProductElectricTypes } from '../ProductElectricTypes';
import {
  disableFields,
  ReviewModeEnum,
  showReviewComments,
  viewOnlyReviewComments,
} from '../../../types/ReviewMode';
import { TieredBilling } from '../../../types/TieredBilling';
import ChargeScales from '../../ChargeScales/ChargeScales';

type Obj = {
  index?: number;
  obj: ProductElectricTypes;
  setObj: (newState: any) => void;
  count?: string[];
  reviewMode: ReviewModeEnum;
  addComment: (id: string, comment: string) => void;
};

export const StableSection = ({
  index = 0,
  obj,
  setObj,
  count,
  addComment,
  reviewMode,
}: Obj) => {
  const theme = useTheme();

  let initialStateDay = useRef(
    structuredClone(obj?.competitiveFee?.[index]?.stableSection?.day),
  );
  let initialStateNight = useRef(
    structuredClone(obj?.competitiveFee?.[index]?.stableSection?.night),
  );

  const handleReset = useCallback(
    (reset: 'day' | 'night') => {
      switch (count ? count[0] : '') {
        case 'Ημερήσια':
          setObj((values: ProductElectricTypes) => {
            const newValues = { ...values };
            if (newValues.competitiveFee?.[index]) {
              newValues.competitiveFee[index] = {
                ...newValues.competitiveFee[index],
                stableSection: {
                  ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                  day: initialStateDay.current,
                },
              };
            }
            return newValues;
          });
          break;
        case 'Ημερήσια & Νυχτερινή':
          switch (reset) {
            case 'day':
              setObj((values: ProductElectricTypes) => {
                const newValues = { ...values };
                if (newValues.competitiveFee?.[index]) {
                  newValues.competitiveFee[index] = {
                    ...newValues.competitiveFee[index],
                    stableSection: {
                      ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                      day: initialStateDay.current,
                    },
                  };
                }
                return newValues;
              });
              break;
            case 'night':
              setObj((values: ProductElectricTypes) => {
                const newValues = { ...values };
                if (newValues.competitiveFee?.[index]) {
                  newValues.competitiveFee[index] = {
                    ...newValues.competitiveFee[index],
                    stableSection: {
                      ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                      night: initialStateNight.current,
                    },
                  };
                }
                return newValues;
              });
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    },
    [index, count, setObj],
  );

  const section = useMemo(() => {
    switch (count ? count[0] : '') {
      case 'Ημερήσια':
        return (
          <>
            <Box>
              <Typography variant="h6" color={theme.palette.primary.main} sx={{ mb: 2 }}>
                Ημέρας
              </Typography>
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.competitiveFees?.day?.[index]}
                  id={`competitiveFees.day.${index}`}
                  title="Ημέρας"
                  addComment={addComment}
                />
              ) : null}
            </Box>
            <ChargeScales
              disabled={disableFields(reviewMode)}
              chargeRows={obj.competitiveFee?.[index]?.stableSection?.day?.chargeRows}
              setChargeRows={value =>
                setObj((obj: ProductElectricTypes) => {
                  const newValues = { ...obj };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      stableSection: {
                        ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                        day: {
                          ...(newValues.competitiveFee[index]?.stableSection?.day ?? {}),
                          chargeRows: value,
                        },
                      },
                    };
                  }
                  return newValues;
                })
              }
              tieredBilling={
                obj.competitiveFee?.[index]?.stableSection?.day
                  ?.tieredBilling as TieredBilling
              }
              setTieredBilling={value =>
                setObj((obj: ProductElectricTypes) => {
                  const newValues = { ...obj };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      stableSection: {
                        ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                        day: {
                          ...(newValues.competitiveFee[index]?.stableSection?.day ?? {}),
                          tieredBilling: value,
                          chargeRows: [{ amount: '0', from: '', to: '' }],
                        },
                      },
                    };
                  }
                  return newValues;
                })
              }
              onReset={() => handleReset('day')}
              amountLabel={'Σταθερή Χρέωση Προμήθειας (€/kWh)'}
            />
          </>
        );
      case 'Ημερήσια & Νυχτερινή':
        return (
          <>
            <Box>
              <Typography variant="h6" color={theme.palette.primary.main} sx={{ mb: 2 }}>
                Ημέρας
              </Typography>
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.competitiveFees?.day?.[index]}
                  id={`competitiveFees.day.${index}`}
                  title="Ημέρας"
                  addComment={addComment}
                />
              ) : null}
            </Box>
            <ChargeScales
              disabled={disableFields(reviewMode)}
              chargeRows={obj.competitiveFee?.[index]?.stableSection?.day?.chargeRows}
              setChargeRows={value =>
                setObj((obj: ProductElectricTypes) => {
                  const newValues = { ...obj };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      stableSection: {
                        ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                        day: {
                          ...(newValues.competitiveFee[index]?.stableSection?.day ?? {}),
                          chargeRows: value,
                        },
                      },
                    };
                  }
                  return newValues;
                })
              }
              tieredBilling={
                obj.competitiveFee?.[index]?.stableSection?.day
                  ?.tieredBilling as TieredBilling
              }
              setTieredBilling={value =>
                setObj((obj: ProductElectricTypes) => {
                  const newValues = { ...obj };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      stableSection: {
                        ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                        day: {
                          ...(newValues.competitiveFee[index]?.stableSection?.day ?? {}),
                          tieredBilling: value,
                          chargeRows: [{ amount: '0', from: '', to: '' }],
                        },
                      },
                    };
                  }
                  return newValues;
                })
              }
              onReset={() => handleReset('day')}
              amountLabel={'Σταθερή Χρέωση Προμήθειας (€/kWh)'}
            />
            <Box>
              <Typography
                variant="h6"
                color={theme.palette.primary.main}
                sx={{ mb: 2, mt: 8 }}
              >
                Νύχτας
              </Typography>
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.competitiveFees?.night?.[index]}
                  id={`competitiveFees.night.${index}`}
                  title="Νύχτας"
                  addComment={addComment}
                />
              ) : null}
            </Box>
            <ChargeScales
              disabled={disableFields(reviewMode)}
              chargeRows={obj.competitiveFee?.[index]?.stableSection?.night?.chargeRows}
              setChargeRows={value =>
                setObj((obj: ProductElectricTypes) => {
                  const newValues = { ...obj };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      stableSection: {
                        ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                        night: {
                          ...(newValues.competitiveFee[index]?.stableSection?.night ??
                            {}),
                          chargeRows: value,
                        },
                      },
                    };
                  }
                  return newValues;
                })
              }
              tieredBilling={
                obj.competitiveFee?.[index]?.stableSection?.night
                  ?.tieredBilling as TieredBilling
              }
              setTieredBilling={value =>
                setObj((obj: ProductElectricTypes) => {
                  const newValues = { ...obj };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      stableSection: {
                        ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                        night: {
                          ...(newValues.competitiveFee[index]?.stableSection?.night ??
                            {}),
                          tieredBilling: value,
                          chargeRows: [{ amount: '0', from: '', to: '' }],
                        },
                      },
                    };
                  }
                  return newValues;
                })
              }
              onReset={() => handleReset('night')}
              amountLabel={'Σταθερή Χρέωση Προμήθειας (€/kWh)'}
            />
          </>
        );
      default:
        break;
    }
  }, [index, addComment, count, handleReset, obj, reviewMode, setObj, theme]);

  return <Box>{section}</Box>;
};
