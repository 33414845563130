import { Box, IconButton, TextField, Typography, useTheme } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { CustomTabs, DropdownNew, DropZone, Input } from '@rae/ui-library';
import React, { useMemo, useState } from 'react';
import { ProductGasTypes } from './ProductGasTypes';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TableProviderIdProps } from '@rae/ui-library/src/components/table/TableRowProviderId';
import { Choice } from '@rae/ui-library/src/components/DropdownNew';
import {
  disableFields,
  ReviewModeEnum,
  showReviewComments,
  viewOnlyReviewComments,
} from '../../types/ReviewMode';
import { ReviewTool } from '../ProductElectricPage/actions/ReviewTool';
import { ProductElectricTypes } from '../ProductElectricPage/ProductElectricTypes';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

type Obj = {
  obj: ProductGasTypes;
  setObj: (newState: any) => void;
  providerIds?: TableProviderIdProps[];
  reviewMode: ReviewModeEnum;
  addComment: (id: string, comment: string) => void;
  minDate?: Date;
};
export const ProductInfoTab = ({
  obj,
  setObj,
  providerIds,
  reviewMode,
  addComment,
  minDate,
}: Obj) => {
  const theme = useTheme();
  const [openCountGr, setOpenCountGr] = useState<boolean>(false);
  const [openDuration, setOpenDuration] = useState<boolean>(false);

  const providersIdOptions = useMemo((): Choice[] => {
    return (
      providerIds?.map(prov => ({
        value: prov._id ? prov._id : '',
        label: `${prov.measurement} - ${prov.categoryOfSupply}`,
      })) ?? []
    );
  }, [providerIds]);

  const providersIdOptionsEn = useMemo((): Choice[] => {
    return (
      providerIds?.map(prov => ({
        value: prov._id ? prov._id : '',
        label: `${prov.measurementEn} - ${prov.categoryOfSupplyEn}`,
      })) ?? []
    );
  }, [providerIds]);

  const GrTab = useMemo(() => {
    const terms = obj?.info?.terms && obj.info.terms.length > 0 ? obj.info.terms : [''];
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            mt: 3,
          }}
        >
          <Input
            sx={{ mt: 2, minWidth: '300px' }}
            type="lock"
            disabled
            label="Όνομα Παρόχου"
            value={obj?.info?.provider?.providerName}
            onChange={() => {}}
          />
          <Box>
            <Input
              sx={{ mt: 2, minWidth: '300px' }}
              type="default"
              label="Όνομα Προϊόντος"
              placeholder="Εισαγετε το όνομα του προϊόντος"
              disabled={
                disableFields(reviewMode) || reviewMode === ReviewModeEnum.Versioning
              }
              value={obj.info?.productName}
              onChange={e =>
                setObj((values: ProductGasTypes) => ({
                  ...values,
                  info: {
                    ...values.info,
                    productName: e.target.value,
                  },
                }))
              }
            />
          </Box>
          <Box>
            <Input
              sx={{ mt: 2, minWidth: '300px' }}
              type="default"
              label="Σύνδεσμος Προϊόντος"
              placeholder="Προσθέστε σύνδεσμο"
              disabled={disableFields(reviewMode, true)}
              value={obj.info?.productLink}
              onChange={e =>
                setObj((values: ProductGasTypes) => ({
                  ...values,
                  info: {
                    ...values.info,
                    productLink: e.target.value,
                  },
                }))
              }
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.productInfo?.linkGr}
                id="productInfo.linkGr"
                title="Σύνδεσμος Προϊόντος"
                addComment={addComment}
              />
            ) : null}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            mt: 6,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: { xs: '0rem', md: '5rem' },
            }}
          >
            <Box>
              <DropdownNew
                sx={{ width: { xs: '300px', md: '450px' }, mt: 2 }}
                multiline={false}
                label="Ταυτότητα Παροχής"
                labelBtn="Εφαρμογή"
                choices={providersIdOptions}
                open={openCountGr}
                disabled={disableFields(reviewMode)}
                setOpen={setOpenCountGr}
                selected={obj?.info?.providerId?._id ? [obj?.info?.providerId?._id] : []}
                setSelected={(type: any) =>
                  setObj((values: ProductGasTypes) => ({
                    ...values,
                    info: {
                      ...values.info,
                      providerId: {
                        ...values.info?.providerId,
                        _id: type[0],
                      },
                    },
                  }))
                }
              />
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.productInfo?.providerId}
                  id="productInfo.providerId"
                  title="Ταυτότητα Παροχής"
                  addComment={addComment}
                />
              ) : null}
            </Box>
            <Box>
              <DropdownNew
                sx={{ width: { xs: '300px', md: '450px' }, mt: 2 }}
                disabled={disableFields(reviewMode)}
                label="Διάρκεια Συμβολαίου"
                labelBtn="Εφαρμογή"
                choices={[
                  {
                    label: '1 μήνας',
                    value: '1',
                  },
                  {
                    label: '3 μήνες',
                    value: '3',
                  },
                  {
                    label: '6 μήνες',
                    value: '6',
                  },
                  {
                    label: '12 μήνες',
                    value: '12',
                  },
                  {
                    label: '18 μήνες',
                    value: '18',
                  },
                  {
                    label: '24 μήνες',
                    value: '24',
                  },
                  {
                    label: 'Αορίστου',
                    value: 'undefined',
                  },
                ]}
                open={openDuration}
                setOpen={setOpenDuration}
                selected={obj?.info?.duration ? [obj?.info?.duration] : []}
                setSelected={(type: any) =>
                  setObj((values: ProductElectricTypes) => ({
                    ...values,
                    info: {
                      ...values.info,
                      duration: type[0],
                    },
                  }))
                }
              />
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.productInfo?.duration}
                  id="productInfo.duration"
                  title="Διάρκεια Συμβολαίου"
                  addComment={addComment}
                />
              ) : null}
            </Box>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Box sx={{ width: '350px' }}>
                <DatePicker
                  disabled={disableFields(reviewMode)}
                  label="Σε ισχύ από"
                  inputFormat="DD/MM/YYYY"
                  minDate={minDate}
                  maxDate={undefined}
                  value={obj?.info?.from ? obj?.info?.from : null}
                  onChange={newValue =>
                    setObj((values: ProductGasTypes) => ({
                      ...values,
                      info: {
                        ...values.info,
                        from: newValue,
                      },
                    }))
                  }
                  renderInput={params => (
                    <TextField sx={{ width: { xs: '300px', md: '450px' } }} {...params} />
                  )}
                />
                {showReviewComments(reviewMode) ? (
                  <ReviewTool
                    view={viewOnlyReviewComments(reviewMode)}
                    msg={obj.reviewComments?.productInfo?.availabilityDate}
                    id="productInfo.availabilityDate"
                    title="Σε ισχύ από"
                    addComment={addComment}
                  />
                ) : null}
              </Box>
            </LocalizationProvider>
          </Box>
          <Box>
            <Box>
              <Input
                sx={{ mt: 2, width: { xs: '300px', md: '400px' } }}
                disabled={true}
                value={obj.info?.productImg ? obj.info?.productImg.name : ''}
                type="upload"
                subText="file must be in .png format, up to 2mb"
                label="Λογότυπο Προϊόντος"
              />
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.productInfo?.image}
                  id="productInfo.image"
                  title="Λογότυπο Προϊόντος"
                  addComment={addComment}
                />
              ) : null}
            </Box>
            <Box
              sx={{
                mt: 2,
                height: { xs: '200px', md: '400px' },
                width: { xs: '200px', md: '400px' },
              }}
            >
              <DropZone
                type="img"
                disable={disableFields(reviewMode, true)}
                file={obj.info?.productImg}
                setFile={(file: any) =>
                  setObj((values: ProductGasTypes) => ({
                    ...values,
                    info: {
                      ...values.info,
                      productImg: file,
                    },
                  }))
                }
              />
              <Typography
                sx={{
                  mt: 1,
                  marginLeft: 1,
                  fontSize: 14,
                  color: theme.palette.grey[700],
                  fontWeight: 400,
                  textAlign: 'center',
                }}
                variant="subtitle2"
                gutterBottom
              >
                Σε περίπτωση μη συμπλήρωσης του πεδίου θα εμφανίζεται το λογότυπο του
                προμηθευτή.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 4,
          }}
        >
          {terms?.map((item, i) => (
            <Box key={i}>
              <Input
                sx={{ mt: 1, minWidth: { xs: '300px', md: '400px' } }}
                placeholder="Προσθέστε σύνδεσμο με τους Όρους Χρήσης"
                type="default"
                disabled={disableFields(reviewMode, true)}
                label="Όροι Χρήσης"
                value={item}
                onChange={e =>
                  setObj((values: ProductGasTypes) => ({
                    ...values,
                    info: {
                      ...values.info,
                      terms: terms?.map((term, index) =>
                        index === i ? e.target.value : term,
                      ),
                    },
                  }))
                }
              />
              {i + 1 === terms.length && terms.length < 5 ? (
                <IconButton
                  sx={{ mt: 1.25, color: theme.palette.primary.main }}
                  disabled={disableFields(reviewMode, true)}
                  onClick={() =>
                    setObj((values: ProductGasTypes) => ({
                      ...values,
                      info: {
                        ...values.info,
                        terms: [...terms, ''],
                      },
                    }))
                  }
                >
                  <AddCircleOutlineIcon fontSize="large" />
                </IconButton>
              ) : (
                <IconButton
                  sx={{ mt: 1.25, color: '#87007B' }}
                  disabled={disableFields(reviewMode, true)}
                  onClick={() =>
                    setObj((values: ProductGasTypes) => ({
                      ...values,
                      info: {
                        ...values.info,
                        terms: terms.filter((term, index) => index !== i),
                      },
                    }))
                  }
                >
                  <RemoveCircleOutlineIcon fontSize="large" />
                </IconButton>
              )}
            </Box>
          ))}
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.productInfo?.termsLinkGr}
              id="productInfo.termLinkGr"
              title="Όροι Χρήσης"
              addComment={addComment}
            />
          ) : null}
        </Box>
        <Box
          sx={{
            mt: 3,
          }}
        >
          <Input
            sx={{ mt: 2, width: '100%' }}
            minRows={4}
            multiline
            type="default"
            placeholder="Εισαγετε πληροφορίες του προϊόντος"
            label="Πληροφορίες σχετικά με το Προϊόν"
            disabled={disableFields(reviewMode, true)}
            value={obj.info?.info}
            onChange={e =>
              setObj((values: ProductGasTypes) => ({
                ...values,
                info: {
                  ...values.info,
                  info: e.target.value,
                },
              }))
            }
          />
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.productInfo?.infoGr}
              id="productInfo.infoGr"
              title="Πληροφορίες σχετικά με το Προϊόν"
              addComment={addComment}
            />
          ) : null}
        </Box>
      </Box>
    );
  }, [
    minDate,
    addComment,
    obj.info,
    obj.reviewComments,
    openCountGr,
    openDuration,
    providersIdOptions,
    reviewMode,
    setObj,
    theme.palette,
  ]);

  const EnTab = useMemo(() => {
    const terms =
      obj?.info?.termsEn && obj.info.termsEn.length > 0 ? obj.info.termsEn : [''];
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            mt: 3,
          }}
        >
          <Input
            sx={{ mt: 2, minWidth: '300px' }}
            type="lock"
            disabled
            label="Provider"
            value={obj?.info?.provider?.providerNameEn}
            onChange={() => {}}
          />
          <Box>
            <Input
              sx={{ mt: 2, minWidth: '300px' }}
              type="default"
              label="Product Commercial Name"
              placeholder="Enter product name"
              disabled={disableFields(reviewMode)}
              value={obj.info?.productNameEn}
              onChange={e =>
                setObj((values: ProductGasTypes) => ({
                  ...values,
                  info: {
                    ...values.info,
                    productNameEn: e.target.value,
                  },
                }))
              }
            />
          </Box>
          <Box>
            <Input
              sx={{ mt: 2, minWidth: '300px' }}
              type="default"
              label="Product link"
              placeholder="Add link"
              disabled={disableFields(reviewMode, true)}
              value={obj.info?.productLinkEn}
              onChange={e =>
                setObj((values: ProductGasTypes) => ({
                  ...values,
                  info: {
                    ...values.info,
                    productLinkEn: e.target.value,
                  },
                }))
              }
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.productInfo?.linkEn}
                id="productInfo.linkEn"
                title="Product link"
                addComment={addComment}
              />
            ) : null}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            mt: 6,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: { xs: '0rem', md: '5rem' },
            }}
          >
            <Box>
              <DropdownNew
                sx={{ width: { xs: '300px', md: '450px' }, mt: 2 }}
                multiline={false}
                label="Supply Identity"
                labelBtn="Εφαρμογή"
                choices={providersIdOptionsEn}
                disabled={disableFields(reviewMode)}
                open={openCountGr}
                setOpen={setOpenCountGr}
                selected={obj?.info?.providerId?._id ? [obj?.info?.providerId?._id] : []}
                setSelected={(type: any) =>
                  setObj((values: ProductGasTypes) => ({
                    ...values,
                    info: {
                      ...values.info,
                      providerId: {
                        ...values.info?.providerId,
                        _id: type[0],
                      },
                    },
                  }))
                }
              />
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.productInfo?.providerId}
                  id="productInfo.providerId"
                  title="Measurement"
                  addComment={addComment}
                />
              ) : null}
            </Box>
            <Box>
              <DropdownNew
                sx={{ width: { xs: '300px', md: '450px' }, mt: 2 }}
                disabled={disableFields(reviewMode)}
                label="Contract Duration"
                labelBtn="Εφαρμογή"
                choices={[
                  {
                    label: '1 month',
                    value: '1',
                  },
                  {
                    label: '3 months',
                    value: '3',
                  },
                  {
                    label: '6 months',
                    value: '6',
                  },
                  {
                    label: '12 months',
                    value: '12',
                  },
                  {
                    label: '18 months',
                    value: '18',
                  },
                  {
                    label: '24 months',
                    value: '24',
                  },
                  {
                    label: 'Indefinite',
                    value: 'undefined',
                  },
                ]}
                open={openDuration}
                setOpen={setOpenDuration}
                selected={obj?.info?.duration ? [obj?.info?.duration] : []}
                setSelected={(type: any) =>
                  setObj((values: ProductElectricTypes) => ({
                    ...values,
                    info: {
                      ...values.info,
                      duration: type[0],
                    },
                  }))
                }
              />
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.productInfo?.duration}
                  id="productInfo.duration"
                  title="Contract Duration"
                  addComment={addComment}
                />
              ) : null}
            </Box>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Box sx={{ width: '350px' }}>
                <DatePicker
                  label="Activation date"
                  inputFormat="DD/MM/YYYY"
                  disabled={disableFields(reviewMode)}
                  value={obj?.info?.from ? obj?.info.from : null}
                  minDate={minDate}
                  maxDate={undefined}
                  onChange={newValue =>
                    setObj((values: ProductGasTypes) => ({
                      ...values,
                      info: {
                        ...values.info,
                        from: newValue,
                      },
                    }))
                  }
                  renderInput={params => (
                    <TextField sx={{ width: { xs: '300px', md: '450px' } }} {...params} />
                  )}
                />
                {showReviewComments(reviewMode) ? (
                  <ReviewTool
                    view={viewOnlyReviewComments(reviewMode)}
                    msg={obj.reviewComments?.productInfo?.availabilityDate}
                    id="productInfo.availabilityDate"
                    title="Activation date"
                    addComment={addComment}
                  />
                ) : null}
              </Box>
            </LocalizationProvider>
          </Box>
          <Box>
            <Input
              sx={{ mt: 2, width: { xs: '300px', md: '400px' } }}
              disabled={true}
              value={obj.info?.productImg ? obj.info?.productImg?.name : ''}
              type="upload"
              subText="file must be in .png format, up to 2mb"
              label="Logo"
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.productInfo?.image}
                id="productInfo.image"
                title="Logo"
                addComment={addComment}
              />
            ) : null}
            <Box
              sx={{
                mt: 2,
                height: { xs: '200px', md: '400px' },
                width: { xs: '200px', md: '400px' },
              }}
            >
              <DropZone
                type="img"
                disable={disableFields(reviewMode, true)}
                file={obj.info?.productImg}
                setFile={(file: any) =>
                  setObj((values: ProductGasTypes) => ({
                    ...values,
                    info: {
                      ...values.info,
                      productImg: file,
                    },
                  }))
                }
              />
              <Typography
                sx={{
                  mt: 1,
                  marginLeft: 1,
                  fontSize: 14,
                  color: theme.palette.grey[700],
                  fontWeight: 400,
                  textAlign: 'center',
                }}
                variant="subtitle2"
                gutterBottom
              >
                Σε περίπτωση μη συμπλήρωσης του πεδίου θα εμφανίζεται το λογότυπο του
                προμηθευτή.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 4,
          }}
        >
          {terms?.map((item, i) => (
            <Box key={i}>
              <Input
                sx={{ mt: 1, minWidth: { xs: '300px', md: '400px' } }}
                placeholder="Add link to Terms of Use"
                type="default"
                disabled={disableFields(reviewMode, true)}
                label="Terms"
                value={item}
                onChange={e =>
                  setObj((values: ProductElectricTypes) => ({
                    ...values,
                    info: {
                      ...values.info,
                      termsEn: terms?.map((term, index) =>
                        index === i ? e.target.value : term,
                      ),
                    },
                  }))
                }
              />
              {i + 1 === terms.length && terms.length < 5 ? (
                <IconButton
                  sx={{ mt: 1.25, color: theme.palette.primary.main }}
                  disabled={disableFields(reviewMode, true)}
                  onClick={() =>
                    setObj((values: ProductElectricTypes) => ({
                      ...values,
                      info: {
                        ...values.info,
                        termsEn: [...terms, ''],
                      },
                    }))
                  }
                >
                  <AddCircleOutlineIcon fontSize="large" />
                </IconButton>
              ) : (
                <IconButton
                  sx={{ mt: 1.25, color: '#87007B' }}
                  disabled={disableFields(reviewMode, true)}
                  onClick={() =>
                    setObj((values: ProductElectricTypes) => ({
                      ...values,
                      info: {
                        ...values.info,
                        termsEn: terms.filter((term, index) => index !== i),
                      },
                    }))
                  }
                >
                  <RemoveCircleOutlineIcon fontSize="large" />
                </IconButton>
              )}
            </Box>
          ))}
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.productInfo?.termsEn}
              id="productInfo.termEn"
              title="Terms"
              addComment={addComment}
            />
          ) : null}
        </Box>
        <Box
          sx={{
            mt: 3,
          }}
        >
          <Input
            sx={{ mt: 2, width: '100%' }}
            minRows={4}
            multiline
            type="default"
            placeholder="Εισαγετε πληροφορίες του προϊόντος"
            label="Product Info"
            disabled={disableFields(reviewMode, true)}
            value={obj.info?.infoEn}
            onChange={e =>
              setObj((values: ProductGasTypes) => ({
                ...values,
                info: {
                  ...values.info,
                  infoEn: e.target.value,
                },
              }))
            }
          />
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.productInfo?.infoEn}
              id="productInfo.infoEn"
              title="Product Info"
              addComment={addComment}
            />
          ) : null}
        </Box>
      </Box>
    );
  }, [
    minDate,
    obj.info,
    obj.reviewComments,
    addComment,
    reviewMode,
    openCountGr,
    openDuration,
    providersIdOptionsEn,
    setObj,
    theme.palette,
  ]);

  const tabs = useMemo(() => {
    return [
      {
        tabName: 'GR',
        tabContent: GrTab,
        badge:
          showReviewComments(reviewMode) &&
          Boolean(
            obj.reviewComments?.productInfo?.termsLinkGr ||
              obj.reviewComments?.productInfo?.nameGr ||
              obj.reviewComments?.productInfo?.linkGr ||
              obj.reviewComments?.productInfo?.infoGr,
          ),
      },
      {
        tabName: 'EN',
        tabContent: EnTab,
        badge:
          showReviewComments(reviewMode) &&
          Boolean(
            obj.reviewComments?.productInfo?.termsLinkEn ||
              obj.reviewComments?.productInfo?.nameEn ||
              obj.reviewComments?.productInfo?.linkEn ||
              obj.reviewComments?.productInfo?.infoEn,
          ),
      },
    ];
  }, [EnTab, GrTab, obj.reviewComments?.productInfo, reviewMode]);

  return <CustomTabs tabs={tabs} newTab={0} />;
};
