import { Box, Divider, IconButton, Typography, useTheme } from '@mui/material';
import { ButtonRae, CheckBoxes, DropdownNew, PopupForm } from '@rae/ui-library';
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { DiscountsTypeBenefits, ProductElectricTypes } from './ProductElectricTypes';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { TableDiscountProps } from '@rae/ui-library/src/components/table/TableRowDiscounts';
import { DiscountBenefitsSection } from './DiscountBenefitsSection';
import { DiscBenefTypeProps } from '@rae/ui-library/src/components/table/TableRowDiscBenefType';
import { Choice } from '@rae/ui-library/src/components/DropdownNew';
import { useAddBenefitMutation } from '../../redux/apis/benefitApi';
import { addBenefitData } from '../../types/Benefit';
import { ReviewTool } from './actions/ReviewTool';
import {
  disableFields,
  ReviewModeEnum,
  showReviewComments,
  viewOnlyReviewComments,
} from '../../types/ReviewMode';
import { Team } from '../../types/Team';
import ChargeScalesMixed from '../ChargeScales/ChargeScalesMixed';
import { TableCompFeesProps } from '@rae/ui-library/dist/cjs/types/src/components/table/TableRowCompFees';

type Obj = {
  data: ProductElectricTypes;
  setData: Dispatch<SetStateAction<ProductElectricTypes>>;
  team?: Team;
  benefitsTypes: DiscBenefTypeProps[];
  benefitsData: TableDiscountProps[];
  addComment: (id: string, comment: string) => void;
  reviewMode: ReviewModeEnum;
  compFees: TableCompFeesProps[];
};

export const BenefitsTab = ({
  data,
  setData,
  team,
  benefitsTypes,
  benefitsData,
  addComment,
  reviewMode,
  compFees,
}: Obj) => {
  const theme = useTheme();
  const [addBenefitRequest] = useAddBenefitMutation();

  const [openKind, setOpenKind] = useState<number>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [newDisc, setNewDisc] = useState<TableDiscountProps>({
    category: 'benefit',
  });
  const [activeType, setActiveType] = useState<string>('');
  const [prevName, setPrevName] = useState<string>();

  const setObjDiscounts = useCallback(
    (val: DiscountsTypeBenefits, index: number) =>
      setData((values: ProductElectricTypes) => {
        const newDiscounts = values.benefits ? [...values.benefits] : [];
        newDiscounts[index] = {
          ...newDiscounts[index],
          ...val,
        };
        return {
          ...values,
          benefits: newDiscounts,
        };
      }),
    [setData],
  );

  const addBenefitService = useCallback(async () => {
    if (
      !team ||
      !newDisc?.type?._id ||
      !newDisc.name ||
      !newDisc.nameEn ||
      !newDisc.energy ||
      !newDisc.clients
    )
      return;
    const addFields = addBenefitData(newDisc, team);
    try {
      return await addBenefitRequest(addFields).unwrap();
    } catch (error: any) {
      console.log(error);
    }
  }, [addBenefitRequest, newDisc, team]);

  const handleInvoiceCheckbox = useCallback(
    (type: string, discount: string[], i: number) => {
      const currentInvoices = Object.keys(
        data.benefits?.[i] ? data.benefits[i][type] : {},
      );
      const added = discount.filter(x => !currentInvoices.includes(x));
      const removed = currentInvoices.filter(x => !discount.includes(x));
      const newData = { ...(data.benefits?.[i] ? data.benefits[i] : {}) };
      removed.forEach(value => delete newData[type][value]);
      added.forEach(
        value =>
          (newData[type][value] = benefitsData
            ?.filter(item => item && item._id === value && item.type?._id === type)
            .map(item => item)[0] ?? { ...newDisc, status: 0, _id: value }),
      );

      setObjDiscounts(newData, i);
    },
    [data.benefits, benefitsData, newDisc, setObjDiscounts],
  );

  const onFocusName = useCallback((prevName: string) => {
    setPrevName(prevName);
  }, []);

  const onLoseFocusName = useCallback(
    (type: string, availDisc: string, name: string, i: number) => {
      const newData = { ...(data.benefits?.[i] ?? {}) };
      const currentInvoices = Object.keys(
        data.benefits?.[i] ? data.benefits[i][type] : {},
      );
      let discName = newData[type][availDisc].name;
      currentInvoices.push(discName ? discName : '');
      const filteredArray = currentInvoices.filter(obj => obj !== prevName);
      handleInvoiceCheckbox(type, filteredArray, i);
    },
    [data, handleInvoiceCheckbox, prevName],
  );

  const editNewData = useCallback(
    (type: string, availDisc: string, name: string, value: any) => {
      switch (name) {
        case 'type':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'electric',
            type: value,
          }));
          break;
        case 'name':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'electric',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            name: value,
          }));
          break;
        case 'nameEn':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'electric',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            nameEn: value,
          }));
          break;
        case 'link':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'electric',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            link: value,
          }));
          break;
        case 'linkEn':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'electric',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            linkEn: value,
          }));
          break;
        case 'descr':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'electric',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            descr: value,
          }));
          break;
        case 'descrEn':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'electric',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            descrEn: value,
          }));
          break;
        case 'reset':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'electric',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            chargeRows: value,
            tieredBilling: undefined,
          }));
          break;
        case 'clients':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'electric',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            clients: value,
          }));
          break;
        case 'tieredBilling':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'electric',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            tieredBilling: value,
            chargeRows: [{ amount: '0', from: '', to: '' }],
          }));
          break;
        case 'chargeRows':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'electric',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            chargeRows: value,
          }));
          break;
        default:
          break;
      }
    },
    [],
  );

  const addDiscount = useCallback(
    async (i: number) => {
      const benef = await addBenefitService();
      if (!benef) return;
      const currentInvoices = Object.keys(
        data?.benefits?.[i]
          ? data.benefits[i][newDisc.type?._id ? newDisc.type._id : '']
          : [],
      );
      currentInvoices.push(benef._id ? benef._id : '');
      handleInvoiceCheckbox(
        newDisc.type?._id ? newDisc.type._id : '',
        currentInvoices,
        i,
      );

      setOpenDialog(false);
      setNewDisc({ category: 'benefit' });
    },
    [addBenefitService, data.benefits, handleInvoiceCheckbox, newDisc],
  );

  const cancelAddDiscount = useCallback(() => {
    setOpenDialog(false);
    setNewDisc({ category: 'benefit' });
  }, []);

  const handleOpenDialog = useCallback((type: DiscBenefTypeProps) => {
    setNewDisc((values: any) => ({
      ...values,
      type: type,
    }));
    setOpenDialog(true);
    setActiveType(type?._id ? type._id : '');
  }, []);

  const disableAddBenefObj = useMemo(() => {
    return (
      !newDisc?.type?._id ||
      !newDisc.name ||
      !newDisc.nameEn ||
      !newDisc.energy ||
      !newDisc.clients ||
      !newDisc.descr ||
      !newDisc.descrEn
    );
  }, [newDisc]);

  const typesOptions = useMemo((): Choice[] => {
    return (
      benefitsTypes?.map(type => ({
        value: type._id ? type._id : '',
        label: type.typeName ? type.typeName : '',
      })) ?? []
    );
  }, [benefitsTypes]);

  const renderSections = useMemo(
    () =>
      data?.benefits?.map((item, i) => (
        <Box
          key={i}
          sx={[
            {
              display: 'flex',
              flexDirection: 'column',
              rowGap: { xs: '0rem', md: '5rem' },
            },
          ]}
        >
          <Box>
            <DropdownNew
              sx={{ width: { xs: '300px', md: '470px' }, mt: 2 }}
              disabled={disableFields(reviewMode)}
              label="Επιλέξτε ένα ή περισσοτερα είδη Πρόσθετης Παροχής"
              multiple
              labelBtn="Εφαρμογή"
              choices={typesOptions}
              open={openKind === i}
              setOpen={open => setOpenKind(open ? i : undefined)}
              selected={Object.keys(item ? item : {})}
              setSelected={types => {
                const currentTypes = Object.keys(item ? item : {});
                const added = types.filter(x => !currentTypes.includes(x));
                const removed = currentTypes.filter(x => !types.includes(x));
                const newData = { ...item };
                removed.forEach(value => delete newData[value]);
                added.forEach(value => (newData[value] = {}));
                setObjDiscounts(newData, i);
              }}
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={data.reviewComments?.benefits?.benefits?.[i]}
                id={`benefits.benefits.${i}`}
                title="Πρόσθετες Παροχές"
                addComment={addComment}
              />
            ) : null}
          </Box>
          {Object.entries(item ? item : {}).map(([type, availDiscounts]) => (
            <Box sx={{ mt: 2 }} key={type}>
              <Typography
                sx={{ minWidth: '200px' }}
                fontSize={'14px'}
                fontWeight={600}
                color={theme.palette.primary.main}
              >
                {`Διαθέσιμες Πρόσθετες Παροχές ${
                  benefitsTypes?.find(disc => disc._id === type)?.typeName
                }`}
                <Divider
                  sx={{
                    mt: 1,
                    width: '10%',
                    borderBottomWidth: 4,
                    borderColor: theme.palette.primary.main,
                  }}
                  textAlign="left"
                />
              </Typography>
              <Box sx={{ mt: 2, display: 'flex' }}>
                {benefitsData?.find(
                  benef => benef.type?._id === type && benef.energy === 'electric',
                ) ? (
                  <CheckBoxes
                    sx={{ mt: 1 }}
                    checked={Object.keys(availDiscounts)}
                    setChecked={disc => handleInvoiceCheckbox(type, disc, i)}
                    disabled={
                      disableFields(reviewMode)
                        ? benefitsData.map(disc => disc._id ?? '') ?? []
                        : []
                    }
                    checkboxes={
                      benefitsData
                        ?.filter(
                          disc =>
                            disc && disc.energy === 'electric' && disc.type?._id === type,
                        )
                        .map(disc => ({
                          label: disc.name ? disc.name : '',
                          value: disc._id ? disc._id : '',
                        })) ?? []
                    }
                  />
                ) : null}
                <IconButton
                  onClick={() =>
                    handleOpenDialog(
                      benefitsTypes.find(disc => disc?._id === type) || {
                        category: 'benefit',
                        typeName: '',
                      },
                    )
                  }
                  aria-label="add"
                  sx={{
                    '&:hover': { backgroundColor: 'transparent' },
                    '&:active': { backgroundColor: 'transparent' },
                    textAlign: 'center',
                  }}
                  disabled={disableFields(reviewMode)}
                >
                  <AddCircleOutlineOutlinedIcon sx={{ fontSize: 40 }} color="primary" />
                  <Typography>
                    Προσθήκη νέας Πρόσθετης Παροχής{' '}
                    {benefitsTypes?.find(disc => disc._id === type)?.typeName}
                  </Typography>
                </IconButton>
              </Box>
              {Object.entries(availDiscounts).map(([availDisc, discountData], i) => {
                return (
                  <Box sx={{ mt: 2 }} key={availDisc}>
                    <Divider sx={{ mb: 2 }} />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="body2">
                        {benefitsData?.find(disc => disc._id === availDisc)?.name}
                      </Typography>
                      <ButtonRae
                        size="small"
                        sx={{ color: '#87007B' }}
                        onClick={() => {
                          const newData = { ...item };
                          delete newData[type][availDisc];
                          setObjDiscounts(newData, i);
                        }}
                        disabled={disableFields(reviewMode)}
                      >
                        Αφαίρεση Πρόσθετης Παροχής
                      </ButtonRae>
                    </Box>
                    <DiscountBenefitsSection
                      obj={discountData}
                      typeDisc={'edit'}
                      type={type}
                      discName={availDisc}
                      section="benefit"
                      onFocusName={onFocusName}
                      onLoseFocusName={(type, availDisc, name) =>
                        onLoseFocusName(type, availDisc, name, i)
                      }
                      key={i}
                      disable={disableFields(reviewMode)}
                    />
                  </Box>
                );
              })}
              {activeType === type ? (
                <PopupForm
                  InfoTootipProps={undefined}
                  title={'Προσθήκη Πρόσθετης Παροχής'}
                  helpText="Insert helping text"
                  leftBtnTitle="Ακύρωση"
                  rightBtnTitle={'Προσθήκη'}
                  open={openDialog}
                  setOpen={() => setOpenDialog(true)}
                  onClose={cancelAddDiscount}
                  onClickLeftBtn={cancelAddDiscount}
                  onClickRightBtn={() => addDiscount(i)}
                  rightBtnProps={{ disabled: disableAddBenefObj }}
                >
                  <DiscountBenefitsSection
                    obj={newDisc}
                    setObj={editNewData}
                    typeDisc={'new'}
                    type={type}
                    discName={''}
                    onFocusName={onFocusName}
                    onLoseFocusName={(type, availDisc, name) =>
                      onLoseFocusName(type, availDisc, name, i)
                    }
                    section="benefit"
                    disable={disableFields(reviewMode)}
                  />
                </PopupForm>
              ) : null}
            </Box>
          ))}
        </Box>
      )),
    [
      data?.benefits,
      data.reviewComments?.benefits?.benefits,
      theme.palette.primary.main,
      reviewMode,
      typesOptions,
      openKind,
      addComment,
      setObjDiscounts,
      benefitsTypes,
      benefitsData,
      activeType,
      openDialog,
      cancelAddDiscount,
      disableAddBenefObj,
      newDisc,
      editNewData,
      onFocusName,
      handleInvoiceCheckbox,
      handleOpenDialog,
      onLoseFocusName,
      addDiscount,
    ],
  );

  return (
    <ChargeScalesMixed
      type={data.competitiveFeeType}
      chargeRows={data.competitiveChargeScale}
      competitiveFees={data.competitiveFee}
      compFees={compFees}
      components={renderSections}
      duration={data.info?.duration}
      disabled
    />
  );
};
