import { TableRegulatedChargesProps } from '@rae/ui-library/src/components/table/TableRowRegulatedCharges';
import { ApiSchema, Identifier } from './ApiSchema';
import { TieredBilling } from './TieredBilling';
import {
  ProviderIdElectric,
  providerIdElectricTableSingleData,
} from './ProviderIdElectric';
import { ChargeRows, chargeRowsAsNumber, chargeRowsAsString } from './ChargeRows';

export type RegulatedChargesElectricFields = {
  providerId: ProviderIdElectric;
  tieredBilling?: TieredBilling;
  etmeap?: number;
  pricePowerMove?: number;
  priceEnergyMove?: number;
  pricePowerDistr?: number;
  priceEnergyDistr?: number;
  chargeScale?: ChargeRows[];
};

export type RegulatedChargesElectricFieldsRequest = Omit<
  RegulatedChargesElectricFields,
  'providerId'
> & {
  providerId?: string;
};

export type RegulatedChargesElectric = ApiSchema<RegulatedChargesElectricFields>;

export type filterParams = {
  registeredOnly?: boolean;
};

export const regulatedChargesElectricTableData = (
  charges?: RegulatedChargesElectric[],
): TableRegulatedChargesProps[] =>
  charges?.map(c => ({
    _id: c._id ?? undefined,
    complete: !!c?.tieredBilling,
    providerId: providerIdElectricTableSingleData(c.providerId),
    etmeap: c?.etmeap,
    pricePowerMove: c?.pricePowerMove,
    priceEnergyMove: c?.priceEnergyMove,
    pricePowerDistr: c?.pricePowerDistr,
    priceEnergyDistr: c?.priceEnergyDistr,
    tieredBilling: c?.tieredBilling,
    chargeRows: c?.chargeScale ? chargeRowsAsString(c?.chargeScale) : undefined,
    modDate: c?.updatedAt ? new Date(c?.updatedAt).toLocaleDateString() : undefined,
    modEmail: undefined,
  })) ?? [];

export const addRegulatedChargesElectricData = (
  regulatedCharge: TableRegulatedChargesProps,
): RegulatedChargesElectricFieldsRequest => ({
  providerId: regulatedCharge.providerId?._id ? regulatedCharge.providerId?._id : '',
  chargeScale: regulatedCharge.chargeRows
    ? chargeRowsAsNumber(regulatedCharge.chargeRows)
    : undefined,
  etmeap: regulatedCharge.etmeap ? regulatedCharge.etmeap : undefined,
  priceEnergyDistr: regulatedCharge.priceEnergyDistr,
  priceEnergyMove: regulatedCharge.priceEnergyMove,
  pricePowerDistr: regulatedCharge.pricePowerDistr,
  pricePowerMove: regulatedCharge.pricePowerMove,
  tieredBilling: regulatedCharge.tieredBilling as TieredBilling,
});

export const editRegulatedChargesElectricData = (
  regulatedCharge: TableRegulatedChargesProps,
): Identifier & RegulatedChargesElectricFieldsRequest => ({
  _id: regulatedCharge._id ? (regulatedCharge._id as Identifier['_id']) : '',
  providerId: regulatedCharge.providerId?._id ? regulatedCharge.providerId?._id : '',
  chargeScale: regulatedCharge.chargeRows
    ? chargeRowsAsNumber(regulatedCharge.chargeRows)
    : undefined,
  etmeap: regulatedCharge.etmeap,
  priceEnergyDistr: regulatedCharge.priceEnergyDistr,
  priceEnergyMove: regulatedCharge.priceEnergyMove,
  pricePowerDistr: regulatedCharge.pricePowerDistr,
  pricePowerMove: regulatedCharge.pricePowerMove,
  tieredBilling: regulatedCharge.tieredBilling as TieredBilling,
});
