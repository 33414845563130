import { TableDiscountProps } from '@rae/ui-library/src/components/table/TableRowDiscounts';
import moment from 'moment';
import { ApiSchema, Identifier } from './ApiSchema';
import { DiscBenefType, discBenefTypeDataSingle } from './DiscBenefType';
import { TieredBilling } from './TieredBilling';
import { BenefitFieldsRequest } from './Benefit';
import { ChargeRows, chargeRowsAsNumber, chargeRowsAsString } from './ChargeRows';
import { Team } from './Team';

export type DiscountFields = {
  team: Team;
  discBenefType: DiscBenefType;
  name: string;
  nameEn: string;
  link?: string;
  linkEn?: string;
  radioDisc: string;
  clients: string;
  descr?: string;
  descrEn?: string;
  energy: string;
  tieredBilling: TieredBilling;
  chargeScale?: ChargeRows[];
};

export type Discount = ApiSchema<DiscountFields>;

export type DiscountParams = {
  search?: string;
  typeId?: string[];
  energyType?: string; //'electric' | 'gas'
  team?: string;
};

export type DiscountFieldsRequest = Omit<DiscountFields, 'discBenefType' | 'team'> & {
  discBenefType: string;
  team: string;
};

export const discountData = (isRae: boolean, disc?: Discount[]): TableDiscountProps[] => {
  return (
    disc?.map(d => ({
      _id: d._id,
      type: discBenefTypeDataSingle(d.discBenefType),
      name: d.name,
      nameEn: d.nameEn,
      link: d.link,
      linkEn: d.linkEn,
      radioDisc: d.radioDisc,
      clients: d.clients,
      descr: d.descr,
      descrEn: d.descrEn,
      energy: d.energy,
      tieredBilling: d.tieredBilling,
      chargeRows: d?.chargeScale ? chargeRowsAsString(d?.chargeScale) : undefined,
      category: 'discount',
      provider: d?.team.nameGr,
      linkInvoices: [],
      editable: !isRae, //add check if RAE
      connected: 0,
      status: 0,
      modDate: moment(d.updatedAt).format('DD/MM/YYYY'),
      modEmail: '',
    })) ?? []
  );
};

export const addDiscountData = (
  obj: TableDiscountProps,
  team: Team,
): DiscountFieldsRequest => {
  return {
    team: team._id,
    discBenefType: obj.type?._id ? obj.type?._id : '',
    name: obj.name ? obj.name : '',
    nameEn: obj.nameEn ? obj.nameEn : '',
    link: obj.link,
    linkEn: obj.linkEn,
    radioDisc: obj.radioDisc ? obj.radioDisc : '',
    clients: obj.clients ? obj.clients : '',
    descr: obj.descr,
    descrEn: obj.descrEn,
    energy: obj.energy ? obj.energy : '',
    tieredBilling: obj.tieredBilling as BenefitFieldsRequest['tieredBilling'],
    chargeScale: obj.chargeRows
      ? (chargeRowsAsNumber(obj.chargeRows) as DiscountFields['chargeScale'])
      : undefined,
  };
};

export const editDiscountData = (
  obj: TableDiscountProps,
  team: Team,
): Identifier & DiscountFieldsRequest => {
  return {
    _id: obj._id ? obj._id : '',
    team: team._id,
    discBenefType: obj.type?._id ? obj.type?._id : '',
    name: obj.name ? obj.name : '',
    nameEn: obj.nameEn ? obj.nameEn : '',
    link: obj.link,
    linkEn: obj.linkEn,
    radioDisc: obj.radioDisc ? obj.radioDisc : '',
    clients: obj.clients ? obj.clients : '',
    descr: obj.descr,
    descrEn: obj.descrEn,
    energy: obj.energy ? obj.energy : '',
    tieredBilling: obj.tieredBilling as BenefitFieldsRequest['tieredBilling'],
    chargeScale: obj.chargeRows
      ? (chargeRowsAsNumber(obj.chargeRows) as DiscountFields['chargeScale'])
      : undefined,
  };
};
