import { OptionsProps } from '@rae/ui-library/src/components/Calculator';
import { ClauseCoefficient } from './ClauseCoefficient';

export type FactorXExpression = (string | number | FactorXExpression)[];

export type FactorX = {
  expression: FactorXExpression;
};

export const optionsPropsToFactorXExpression = (
  props: OptionsProps[],
): FactorXExpression => {
  let skipIndex = -1;

  return props
    .map((prop, index) => {
      if (index <= skipIndex) {
        return '';
      }

      if (prop.type === 'number' && prop.value === '(') {
        skipIndex = props.findIndex((p, i) => i > index && p.value === ')');
        const expression = props.slice(index + 1, skipIndex);
        return optionsPropsToFactorXExpression(expression);
      }

      return prop.value ?? '';
    })
    .filter(value => value !== ''); // Filter out empty values
};

export const factorXExpressionToOptionsProps = (
  expression?: FactorXExpression,
  clauses?: ClauseCoefficient[],
): OptionsProps[] => {
  const result: OptionsProps[] = [];
  if (!expression) return result;

  expression.forEach(item => {
    if (typeof item === 'number') {
      // Add a number
      result.push({
        label: `${item}`,
        value: item,
        type: 'number',
      });
    } else if (Array.isArray(item)) {
      // Recursively add nested expression
      const nestedProps = factorXExpressionToOptionsProps(item);
      result.push(
        {
          label: '(',
          value: '(',
          type: 'number',
        },
        ...nestedProps,
        {
          label: ')',
          value: ')',
          type: 'number',
        },
      );
    } else {
      if (/[*/()+-]/.test(item)) {
        // Add an operator
        result.push({
          label: item,
          value: item,
          type: 'number',
        });
      } else {
        // Add a clause
        result.push({
          label:
            clauses?.find(clause => clause._id === item)?.clauseCoefficientName ?? item,
          value: item,
          type: 'text',
        });
      }
    }
  });

  return result;
};

export const factorXExpressionToString = (
  expression?: FactorXExpression,
  clauses?: ClauseCoefficient[],
): string => {
  return factorXExpressionToOptionsProps(expression, clauses)
    ?.map(obj => obj.label)
    ?.join('');
};
