import { IconButton, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { CustomTabs, Input } from '@rae/ui-library';
import React, { useMemo } from 'react';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { TableRegulatedChargesProps } from '@rae/ui-library/src/components/table/TableRowRegulatedCharges';
import { TieredBilling } from '../../types/TieredBilling';
import ChargeScales from '../ChargeScales/ChargeScales';

export type AddChargesProps = {
  tabsContent: TableRegulatedChargesProps;
  setTabs: (newState: any) => void;
  view: boolean;
  edit: boolean;
  setEdit: (newState: boolean) => void;
  handleReset: () => void;
};

export const NewEntryTabsEl = ({
  setTabs,
  tabsContent,
  view,
  edit,
  setEdit,
  handleReset,
}: AddChargesProps) => {
  const theme = useTheme();

  const Charges = useMemo(() => {
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'flex-start',
            mt: 3,
          }}
        >
          <Input
            sx={{
              mt: 2,
              width: '300px',
              mr: { xs: 0, md: 4 },
              bgcolor: theme.palette.grey[100],
            }}
            color="primary"
            type="lock"
            disabled
            value={tabsContent.providerId?.measurement}
            label="Μέτρηση"
          />

          <Input
            sx={{
              mt: 2,
              width: '300px',
              mr: { xs: 0, md: 4 },
              bgcolor: theme.palette.grey[100],
            }}
            type="lock"
            disabled
            value={tabsContent.providerId?.categoryOfSupply}
            label="Κατηγορία παροχής"
          />

          <Input
            sx={{ mt: 2, width: '300px' }}
            type="default"
            label="ΕΤΜΕΑΡ"
            disabled={!edit}
            inputType={'number'}
            value={tabsContent.etmeap}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setTabs((values: TableRegulatedChargesProps) => ({
                ...values,
                etmeap: (event.target as HTMLInputElement).value,
              }))
            }
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'flex-start',
            mt: 3,
          }}
        >
          <Input
            sx={{ mt: 2, width: '300px', mr: { xs: 0, md: 4 } }}
            type="default"
            label="Τιμή ισχύος μεταφοράς (€/KWh)"
            disabled={!edit}
            inputType={'number'}
            value={tabsContent.pricePowerMove}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setTabs((values: TableRegulatedChargesProps) => ({
                ...values,
                pricePowerMove: (event.target as HTMLInputElement).value,
              }))
            }
          />

          <Input
            sx={{ mt: 2, width: '300px', mr: { xs: 0, md: 4 } }}
            type="default"
            label="Τιμή ενέργειας μεταφοράς (€/KWh)"
            disabled={!edit}
            inputType={'number'}
            value={tabsContent.priceEnergyMove}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setTabs((values: TableRegulatedChargesProps) => ({
                ...values,
                priceEnergyMove: (event.target as HTMLInputElement).value,
              }))
            }
          />

          <Input
            sx={{ mt: 2, width: '300px' }}
            type="default"
            label="Τιμή ισχύος διανομής (€/KWh)"
            disabled={!edit}
            inputType={'number'}
            value={tabsContent.pricePowerDistr}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setTabs((values: TableRegulatedChargesProps) => ({
                ...values,
                pricePowerDistr: (event.target as HTMLInputElement).value,
              }))
            }
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'flex-start',
            mt: 3,
          }}
        >
          <Input
            sx={{ mt: 2, width: '300px' }}
            type="default"
            label="Τιμή ενέργειας διανομής (€/KWh)"
            disabled={!edit}
            inputType={'number'}
            value={tabsContent.priceEnergyDistr}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setTabs((values: TableRegulatedChargesProps) => ({
                ...values,
                priceEnergyDistr: (event.target as HTMLInputElement).value,
              }))
            }
          />
        </Box>
      </Box>
    );
  }, [setTabs, tabsContent, theme.palette.grey, edit]);

  const ykw = useMemo(() => {
    return (
      <ChargeScales
        disabled={!edit}
        chargeRows={tabsContent.chargeRows}
        setChargeRows={value =>
          setTabs((values: TableRegulatedChargesProps) => ({
            ...values,
            chargeRows: value,
          }))
        }
        tieredBilling={tabsContent.tieredBilling as TieredBilling}
        setTieredBilling={value =>
          setTabs((values: TableRegulatedChargesProps) => ({
            ...values,
            tieredBilling: value,
            chargeRows: [{ amount: '0', from: '', to: '' }],
          }))
        }
        onReset={handleReset}
        amountLabel={'Ποσό ΥΚΩ (€/kWh)'}
      />
    );
  }, [edit, tabsContent.chargeRows, tabsContent.tieredBilling, handleReset, setTabs]);

  const tabs = useMemo(
    () => [
      { tabName: 'Χρεώσεις', tabContent: Charges },
      { tabName: 'ΥΚΩ', tabContent: ykw },
    ],
    [Charges, ykw],
  );

  return (
    <CustomTabs
      newTab={0}
      tabs={tabs}
      chipStyle={true}
      btnIcon={
        view ? (
          !edit ? (
            <IconButton onClick={() => setEdit(true)} aria-label="edit">
              <ModeEditOutlineOutlinedIcon sx={{ color: '#87007B' }} />
              <Typography sx={{ color: '#87007B' }}>Επεξεργασία</Typography>
            </IconButton>
          ) : (
            <IconButton onClick={() => setEdit(false)} aria-label="edit">
              <CloseIcon sx={{ color: '#87007B' }} />
              <Typography sx={{ color: '#87007B' }}>Ακύρωση</Typography>
            </IconButton>
          )
        ) : undefined
      }
    />
  );
};
