import { Box, Container, Drawer, useMediaQuery, useTheme } from '@mui/material';
import {
  BreadCrumbsCustom,
  CustomChip,
  CustomChipNew,
  CustomTable,
  CustomTabs,
  PopupConfirm,
  PopupForm,
  Tooltip,
} from '@rae/ui-library';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AddTypeDialog } from '../components/DiscountsAndBenefitsPage/DialogAddType';
import { AddDiscDialog } from '../components/DiscountsAndBenefitsPage/DialogAddDisc';
import { AddBenefDialog } from '../components/DiscountsAndBenefitsPage/DialogAddBenef';
import { TableDiscountProps } from '@rae/ui-library/src/components/table/TableRowDiscounts';
import { EditDiscDialog } from '../components/DiscountsAndBenefitsPage/EditDiscDialog';
import { EditBenefDialog } from '../components/DiscountsAndBenefitsPage/editBenefDialog';
import { DiscBenefTypeProps } from '@rae/ui-library/src/components/table/TableRowDiscBenefType';
import { pageToSkipLimit } from '../types/ApiPagination';
import { HeadCell, OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import {
  useAddDiscBenefTypeMutation,
  useDeleteDiscBenefTypeMutation,
  useEditDiscBenefTypeMutation,
  useGetDiscBenefTypeQuery,
} from '../redux/apis/DiscBenefTypeApi';
import {
  addDiscBenefTypeData,
  discBenefTypeData,
  editDiscBenefTypeData,
} from '../types/DiscBenefType';
import Loader from '../assets/loading.gif';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store';
import { setLog } from '../redux/slices/appSlice';
import {
  useAddDiscountMutation,
  useDeleteDiscountMutation,
  useEditDiscountMutation,
  useGetDiscountsQuery,
} from '../redux/apis/discountApi';
import { addDiscountData, discountData, editDiscountData } from '../types/Discount';
import {
  useAddBenefitMutation,
  useDeleteBenefitMutation,
  useEditBenefitMutation,
  useGetBenefitsQuery,
} from '../redux/apis/benefitApi';
import { addBenefitData, benefitData, editBenefitData } from '../types/Benefit';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isRaeTeam } from '../utils/PickTeam';
import { energyTypeLabel } from '../types/EnergyType';
import useDebounce from '../utils/useDebounce';

export const DiscountAndBnfits = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();

  const { team } = useSelector((state: RootState) => state.auth);

  const [orderType, setOrderType] = useState<OrderProps>('asc');
  const [orderByType, setOrderByType] = useState<HeadCell['id']>('updatedAt');
  const [pageType, setPageType] = useState<number>(0);

  const [orderDisc, setOrderDisc] = useState<OrderProps>('asc');
  const [orderByDisc, setOrderByDisc] = useState<HeadCell['id']>('updatedAt');
  const [pageDisc, setPageDisc] = useState<number>(0);

  const [orderBenef, setOrderBenef] = useState<OrderProps>('asc');
  const [orderByBenef, setOrderByBenef] = useState<HeadCell['id']>('updatedAt');
  const [pageBenef, setPageBenef] = useState<number>(0);

  const [openKindDisc, setOpenKindDisc] = useState<boolean>(false);
  const [selectedKindDisc, setSelectedKindDisc] = useState<string[]>([]);
  const [openTypeDisc, setOpenTypeDisc] = useState<boolean>(false);
  const [selectedTypeDisc, setSelectedTypeDisc] = useState<string[]>([]);
  const [searchDisc, setSearchDisc] = useState<string>('');
  const debouncedSearchDisc = useDebounce<string>(searchDisc, 300);

  const [openKindBenef, setOpenKindBenef] = useState<boolean>(false);
  const [selectedKindBenef, setSelectedKindBenef] = useState<string[]>([]);
  const [openTypeBenef, setOpenTypeBenef] = useState<boolean>(false);
  const [selectedTypeBenef, setSelectedTypeBenef] = useState<string[]>([]);
  const [searchBenef, setSearchBenef] = useState<string>('');
  const debouncedSearchBenef = useDebounce<string>(searchBenef, 300);

  const [searchType, setSearchType] = useState<string>('');

  const [activeTab, setActiveTab] = useState<number>(0);

  const [openAddType, setOpenAddType] = useState<boolean>(false);
  const [openAddDiscount, setOpenAddDiscount] = useState<boolean>(false);
  const [openAddBenef, setOpenAddBenef] = useState<boolean>(false);

  const [discObj, setDiscObj] = useState<TableDiscountProps>({});
  const [typeDisc, setTypeDisc] = useState<'new' | 'edit' | 'viewLinkInv' | 'view'>(
    'new',
  );
  const [openDeleteDisc, setOpenDeleteDisc] = useState<boolean>(false);

  const [benefObj, setBenefObj] = useState<TableDiscountProps>({});
  const [typeBenef, setTypeBenef] = useState<'new' | 'edit' | 'viewLinkInv' | 'view'>(
    'new',
  );
  const [openDeleteBenef, setOpenDeleteBenef] = useState<boolean>(false);

  const [typeObj, setTypeObj] = useState<DiscBenefTypeProps>({
    typeName: '',
    category: undefined,
    applicationField: undefined,
  });
  const [typeType, setTypeType] = useState<'new' | 'edit' | 'viewLinkInv'>('new');
  const [openDeleteType, setOpenDeleteType] = useState<boolean>(false);

  const links = useMemo(() => {
    return [{ name: 'Διαχείριση', href: '/' }, { name: 'Εκπτώσεις & Πρόσθετες Παροχές' }];
  }, []);

  useEffect(() => {
    setActiveTab(Number(searchParams.get('t') ?? 0));
  }, [searchParams]);

  const { data: discountBenefits, isLoading: discountBenefitsIsLoading } =
    useGetDiscBenefTypeQuery({
      ...pageToSkipLimit(pageType, 5, [
        `${orderType === 'asc' ? '-' : ''}${orderByType}`,
      ]),
      team: isRaeTeam(team) ? undefined : team?._id,
    });
  const { data: discountBenefitsALL } = useGetDiscBenefTypeQuery({
    ...pageToSkipLimit(0, 100),
  });
  const [addDiscountBenefits, { isLoading: addDiscountBenefitsLoading }] =
    useAddDiscBenefTypeMutation();

  const [editDiscountBenefits, { isLoading: editDiscountBenefitsLoading }] =
    useEditDiscBenefTypeMutation();

  const [deleteDiscountBenefits, { isLoading: deleteDiscountBenefitsLoading }] =
    useDeleteDiscBenefTypeMutation();

  const { data: discounts, isLoading: discountsIsLoading } = useGetDiscountsQuery({
    ...pageToSkipLimit(pageDisc, 5, [`${orderDisc === 'asc' ? '-' : ''}${orderByDisc}`]),
    search: debouncedSearchDisc,
    energyType: selectedTypeDisc?.[0] ?? undefined,
    typeId: selectedKindDisc?.length ? selectedKindDisc : undefined,
    team: isRaeTeam(team) ? undefined : team?._id,
  });

  const [addDiscountRequest, { isLoading: addDiscountLoading }] =
    useAddDiscountMutation();

  const [editDiscountRequest, { isLoading: editDiscountLoading }] =
    useEditDiscountMutation();

  const [deleteDiscountRequest, { isLoading: deleteDiscountLoading }] =
    useDeleteDiscountMutation();

  const { data: benefits, isLoading: benefitsIsLoading } = useGetBenefitsQuery({
    ...pageToSkipLimit(pageBenef, 5, [
      `${orderBenef === 'asc' ? '-' : ''}${orderByBenef}`,
    ]),
    search: debouncedSearchBenef,
    energyType: selectedTypeBenef?.[0] ?? undefined,
    typeId: selectedKindBenef?.length ? selectedKindBenef : undefined,
    team: isRaeTeam(team) ? undefined : team?._id,
  });

  const [addBenefitRequest, { isLoading: addBenefitLoading }] = useAddBenefitMutation();

  const [editBenefitRequest, { isLoading: editBenefitLoading }] =
    useEditBenefitMutation();

  const [deleteBenefits, { isLoading: deleteBenefitLoading }] =
    useDeleteBenefitMutation();

  const discountFilters = useMemo(
    () =>
      selectedKindDisc
        .map(kind => ({
          label:
            discBenefTypeData(discountBenefitsALL?.discBenefType)?.find(
              i => i._id === kind,
            )?.typeName ?? '',
          value: kind,
        }))
        .concat(
          selectedTypeDisc?.[0]
            ? [
                {
                  label: energyTypeLabel(selectedTypeDisc?.[0] as 'electric' | 'gas').gr,
                  value: selectedTypeDisc?.[0],
                },
              ]
            : [],
          searchDisc !== '' ? [{ label: searchDisc, value: 'search' }] : [],
        ),
    [discountBenefitsALL?.discBenefType, searchDisc, selectedKindDisc, selectedTypeDisc],
  );

  const benefitFilters = useMemo(
    () =>
      selectedKindBenef
        .map(kind => ({
          label:
            discBenefTypeData(discountBenefitsALL?.discBenefType)?.find(
              i => i._id === kind,
            )?.typeName ?? '',
          value: kind,
        }))
        .concat(
          selectedTypeBenef?.[0]
            ? [
                {
                  label: energyTypeLabel(selectedTypeBenef?.[0] as 'electric' | 'gas').gr,
                  value: selectedTypeBenef?.[0],
                },
              ]
            : [],
          searchBenef !== '' ? [{ label: searchBenef, value: 'search' }] : [],
        ),
    [
      discountBenefitsALL?.discBenefType,
      searchBenef,
      selectedKindBenef,
      selectedTypeBenef,
    ],
  );

  const clearFilters = useCallback(() => {
    setSelectedKindDisc([]);
    setSelectedTypeDisc([]);
    setSearchDisc('');
  }, []);

  const clearFiltersBenef = useCallback(() => {
    setSelectedKindBenef([]);
    setSelectedTypeBenef([]);
    setSearchBenef('');
  }, []);

  const clearFiltersDiscBenefType = useCallback(() => {
    setSearchType('');
  }, []);

  const deleteChip = useCallback(
    (chipLabel: string) => {
      setSelectedKindDisc(selectedKindDisc.filter(label => label !== chipLabel));
      setSelectedTypeDisc(selectedTypeDisc.filter(label => label !== chipLabel));
      if (chipLabel === 'search') {
        setSearchDisc('');
      }
    },
    [selectedKindDisc, selectedTypeDisc],
  );

  const deleteChipBenef = useCallback(
    (chipLabel: string) => {
      setSelectedKindBenef(selectedKindBenef.filter(label => label !== chipLabel));
      setSelectedTypeBenef(selectedTypeBenef.filter(label => label !== chipLabel));
      if (chipLabel === 'search') {
        setSearchBenef('');
      }
    },
    [selectedKindBenef, selectedTypeBenef],
  );

  const filters = useCallback(() => {
    const tab = isRaeTeam(team) ? activeTab : activeTab + 1;
    switch (tab) {
      case 0:
        return searchType !== '' && searchType !== null ? (
          <>
            <Box sx={{ mt: 4 }}>
              <Tooltip
                text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
                type="info"
                btnAction={clearFiltersDiscBenefType}
                btnLabel="Καθαρισμός Φίλτρων"
                dismiss={true}
                icon={<InfoOutlinedIcon fontSize="small" />}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <CustomChip label={searchType} onDelete={clearFiltersDiscBenefType} />
            </Box>
          </>
        ) : null;
      case 1:
        return (
          <>
            {' '}
            <Box sx={{ mt: 4 }}>
              {discountFilters.length > 0 ? (
                <Tooltip
                  text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
                  type="info"
                  btnAction={clearFilters}
                  btnLabel="Καθαρισμός Φίλτρων"
                  dismiss={true}
                  icon={<InfoOutlinedIcon fontSize="small" />}
                />
              ) : null}
            </Box>
            <Box sx={{ mt: 2 }}>
              {discountFilters.map(({ label, value }, i) => {
                return (
                  <CustomChipNew
                    key={i}
                    label={label}
                    value={value}
                    onDelete={deleteChip}
                  />
                );
              })}
            </Box>
          </>
        );
      case 2:
        return (
          <>
            {' '}
            <Box sx={{ mt: 4 }}>
              {benefitFilters.length > 0 ? (
                <Tooltip
                  text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
                  type="info"
                  btnAction={clearFiltersBenef}
                  btnLabel="Καθαρισμός Φίλτρων"
                  dismiss={true}
                  icon={<InfoOutlinedIcon fontSize="small" />}
                />
              ) : null}
            </Box>
            <Box sx={{ mt: 2 }}>
              {benefitFilters.map(({ label, value }, i) => {
                return (
                  <CustomChipNew
                    key={i}
                    label={label}
                    value={value}
                    onDelete={deleteChipBenef}
                  />
                );
              })}
            </Box>
          </>
        );
      default:
        break;
    }
  }, [
    team,
    activeTab,
    benefitFilters,
    clearFilters,
    clearFiltersBenef,
    clearFiltersDiscBenefType,
    deleteChip,
    deleteChipBenef,
    discountFilters,
    searchType,
  ]);

  const addDiscount = useCallback(() => {
    setOpenAddDiscount(true);
    setTypeDisc('new');
    setDiscObj({
      chargeRows: [{ amount: '0', from: '0', to: '0' }],
      tieredBilling: 'single',
    });
  }, []);

  const editDiscount = useCallback((obj: TableDiscountProps) => {
    setOpenAddDiscount(true);
    setTypeDisc('edit');
    setDiscObj(obj);
  }, []);

  const openViewLinkInv = useCallback((obj: TableDiscountProps) => {
    setTypeDisc('viewLinkInv');
    setOpenAddDiscount(true);
    setDiscObj(obj);
  }, []);

  const handleDeleteDisc = useCallback((obj: TableDiscountProps) => {
    setOpenDeleteDisc(true);
    setDiscObj(obj);
  }, []);

  const deleteRowDisc = useCallback(async () => {
    setOpenDeleteDisc(false);
    if (!discObj._id) return;
    try {
      await deleteDiscountRequest({ _id: discObj._id }).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: `Η Έκπτωση διαγράφθηκε επιτυχώς`,
        }),
      );
    } catch (error: any) {
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setDiscObj({});
  }, [deleteDiscountRequest, discObj._id, dispatch]);

  const addBenefit = useCallback(() => {
    setOpenAddBenef(true);
    setTypeBenef('new');
    setBenefObj({
      chargeRows: [{ amount: '0', from: '0', to: '0' }],
      tieredBilling: 'single',
    });
  }, []);

  const editBenefit = useCallback((obj: TableDiscountProps) => {
    setOpenAddBenef(true);
    setTypeBenef('edit');
    setBenefObj(obj);
  }, []);

  const openViewLinkInvBenef = useCallback((obj: TableDiscountProps) => {
    setTypeBenef('viewLinkInv');
    setOpenAddBenef(true);
    setBenefObj(obj);
  }, []);

  const handleDeleteBenef = useCallback((obj: TableDiscountProps) => {
    setOpenDeleteBenef(true);
    setBenefObj(obj);
  }, []);

  const deleteRowBenef = useCallback(async () => {
    setOpenDeleteBenef(false);
    if (!benefObj._id) return;
    try {
      await deleteBenefits({ _id: benefObj._id }).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: `Η Πρόσθετη Παροχή διαγράφθηκε επιτυχώς`,
        }),
      );
    } catch (error: any) {
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setBenefObj({});
  }, [benefObj._id, deleteBenefits, dispatch]);

  const addType = useCallback(() => {
    setOpenAddType(true);
    setTypeType('new');
    setTypeObj({ typeName: '', category: undefined, applicationField: undefined });
  }, []);

  const editType = useCallback((obj: DiscBenefTypeProps) => {
    setOpenAddType(true);
    setTypeType('edit');
    setTypeObj(obj);
  }, []);

  const handleDeleteType = useCallback((obj: DiscBenefTypeProps) => {
    setOpenDeleteType(true);
    setTypeObj(obj);
  }, []);

  const deleteRowType = useCallback(async () => {
    setOpenDeleteType(false);
    if (!typeObj._id) return;
    try {
      await deleteDiscountBenefits({ _id: typeObj._id }).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: `Το είδος διαγράφθηκε επιτυχώς`,
        }),
      );
    } catch (error: any) {
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setTypeObj({ typeName: '', category: undefined, applicationField: undefined });
  }, [deleteDiscountBenefits, dispatch, typeObj._id]);

  const handleViewDiscount = useCallback((obj: TableDiscountProps) => {
    setOpenAddDiscount(true);
    setTypeDisc('view');
    setDiscObj(obj);
  }, []);

  const handleViewBenefit = useCallback((obj: TableDiscountProps) => {
    setOpenAddBenef(true);
    setTypeBenef('view');
    setBenefObj(obj);
  }, []);

  const tabsRae = useMemo(() => {
    return [
      {
        tabName: 'Είδος Έκπτωσης / Παροχής',
        tabContent: (
          <CustomTable
            buttonProps={{
              variant: 'contained',
              onClick: addType,
              children: 'Προσθήκη Είδους',
            }}
            searchInput={{
              onChange: e => setSearchType(e.target.value),
              value: searchType,
              type: 'search',
              label: 'Αναζήτηση ',
            }}
            type="disc-benef-type"
            handleEdit={(obj: DiscBenefTypeProps) => editType(obj)}
            handleDelete={(obj: DiscBenefTypeProps) => handleDeleteType(obj)}
            headCells={[
              { disable: false, id: 'typeName', label: 'Είδος Έκπτωσης / Παροχής' },
              { disable: false, id: 'category', label: 'Κατηγορία' },
              { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
              { disable: true, id: 'actions', label: 'Ενέργειες' },
            ]}
            rows={discBenefTypeData(discountBenefits?.discBenefType)}
            count={discountBenefits?.count ?? 0}
            order={orderType}
            orderBy={orderByType}
            page={pageType}
            setOrder={setOrderType}
            setOrderBy={setOrderByType}
            setPage={setPageType}
            rowsPerPage={5}
          />
        ),
      },
      {
        tabName: 'Εκπτώσεις',
        tabContent: (
          <CustomTable
            dropDownsNew={[
              {
                choices: [
                  { label: energyTypeLabel('electric').gr, value: 'electric' },
                  { label: energyTypeLabel('gas').gr, value: 'gas' },
                ],
                open: openTypeDisc,
                selected: selectedTypeDisc,
                setOpen: setOpenTypeDisc,
                setSelected: setSelectedTypeDisc,
                label: 'Τύπος',
                multiple: false,
                labelBtn: 'Εφαρμογή',
              },
              {
                choices: discBenefTypeData(discountBenefitsALL?.discBenefType)
                  .filter(option => option.typeName && option.category === 'discount')
                  .map(i => ({
                    label: i.typeName,
                    value: i._id ?? '',
                  })),
                open: openKindDisc,
                selected: selectedKindDisc,
                setOpen: setOpenKindDisc,
                setSelected: setSelectedKindDisc,
                label: 'Είδος Έκπτωσης',
                multiple: true,
                labelBtn: 'Εφαρμογή',
              },
            ]}
            buttonProps={
              isRaeTeam(team)
                ? undefined
                : {
                    variant: 'contained',
                    onClick: addDiscount,
                    children: 'Προσθήκη Έκπτωσης',
                  }
            }
            searchInput={{
              onChange: e => setSearchDisc(e.target.value),
              value: searchDisc,
              type: 'search',
              label: 'Αναζήτηση ',
            }}
            rows={discountData(isRaeTeam(team), discounts?.documents)}
            type="discount"
            handleEdit={(obj: TableDiscountProps) => editDiscount(obj)}
            handleDelete={(obj: TableDiscountProps) => handleDeleteDisc(obj)}
            handleView={(obj: TableDiscountProps) => handleViewDiscount(obj)}
            handleNewEntry={(obj: TableDiscountProps) => openViewLinkInv(obj)}
            count={discounts?.count ?? 0}
            headCells={[
              { disable: true, id: 'type', label: 'Είδος Ἐκπτωσης' },
              { disable: false, id: 'name', label: 'Όνομα' },
              { disable: true, id: 'provider', label: 'Πάροχος' },
              // { disable: true, id: 'linkedInvoice', label: 'Συνδεδεμένα Τιμολόγια' },
              { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
              { disable: true, id: 'actions', label: 'Ενέργειες' },
            ]}
            order={orderDisc}
            orderBy={orderByDisc}
            setOrder={setOrderDisc}
            setOrderBy={setOrderByDisc}
            page={pageDisc}
            setPage={setPageDisc}
            rowsPerPage={5}
          />
        ),
      },
      {
        tabName: 'Πρόσθετες Παροχές',
        tabContent: (
          <CustomTable
            dropDownsNew={[
              {
                choices: [
                  { label: energyTypeLabel('electric').gr, value: 'electric' },
                  { label: energyTypeLabel('gas').gr, value: 'gas' },
                ],
                open: openTypeBenef,
                selected: selectedTypeBenef,
                setOpen: setOpenTypeBenef,
                setSelected: setSelectedTypeBenef,
                label: 'Τύπος',
                multiple: false,
                labelBtn: 'Εφαρμογή',
              },
              {
                choices: discBenefTypeData(discountBenefitsALL?.discBenefType)
                  .filter(option => option.typeName && option.category === 'benefit')
                  .map(i => ({ label: i.typeName, value: i._id ?? '' })),
                open: openKindBenef,
                selected: selectedKindBenef,
                setOpen: setOpenKindBenef,
                setSelected: setSelectedKindBenef,
                label: 'Είδος παροχής',
                multiple: true,
                labelBtn: 'Εφαρμογή',
              },
            ]}
            buttonProps={
              isRaeTeam(team)
                ? undefined
                : {
                    variant: 'contained',
                    onClick: addBenefit,
                    children: 'Προσθήκη Παροχής',
                  }
            }
            searchInput={{
              onChange: e => setSearchBenef(e.target.value),
              value: searchBenef,
              type: 'search',
              label: 'Αναζήτηση ',
            }}
            rows={benefitData(isRaeTeam(team), benefits?.documents)}
            type="discount"
            handleEdit={(obj: TableDiscountProps) => editBenefit(obj)}
            handleDelete={(obj: TableDiscountProps) => handleDeleteBenef(obj)}
            handleView={(obj: TableDiscountProps) => handleViewBenefit(obj)}
            handleNewEntry={(obj: TableDiscountProps) => openViewLinkInv(obj)}
            count={benefits?.count ?? 0}
            headCells={[
              { disable: true, id: 'type', label: 'Είδος Παροχής' },
              { disable: false, id: 'name', label: 'Όνομα' },
              { disable: true, id: 'provider', label: 'Πάροχος' },
              // { disable: true, id: 'link-invoices', label: 'Συνδεδεμένα Τιμολόγια' },
              { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
              { disable: true, id: 'actions', label: 'Ενέργειες' },
            ]}
            order={orderBenef}
            orderBy={orderByBenef}
            setOrder={setOrderBenef}
            setOrderBy={setOrderByBenef}
            page={pageBenef}
            setPage={setPageBenef}
            rowsPerPage={5}
          />
        ),
      },
    ];
  }, [
    openKindBenef,
    selectedKindBenef,
    openKindDisc,
    selectedKindDisc,
    addBenefit,
    addDiscount,
    addType,
    benefits?.documents,
    discountBenefits?.discBenefType,
    discountBenefitsALL?.discBenefType,
    discounts?.documents,
    editBenefit,
    editDiscount,
    editType,
    handleDeleteBenef,
    handleDeleteDisc,
    handleDeleteType,
    handleViewBenefit,
    handleViewDiscount,
    team,
    openTypeBenef,
    openTypeDisc,
    openViewLinkInv,
    orderBenef,
    orderByBenef,
    orderByDisc,
    orderByType,
    orderDisc,
    orderType,
    pageBenef,
    pageDisc,
    pageType,
    searchBenef,
    searchDisc,
    searchType,
    selectedTypeBenef,
    selectedTypeDisc,
    discountBenefits?.count,
    discounts?.count,
    benefits?.count,
  ]);

  const tabsProvider = useMemo(() => {
    return [
      {
        tabName: 'Εκπτώσεις',
        tabContent: (
          <CustomTable
            dropDownsNew={[
              {
                choices: [
                  { label: energyTypeLabel('electric').gr, value: 'electric' },
                  { label: energyTypeLabel('gas').gr, value: 'gas' },
                ],
                open: openTypeDisc,
                selected: selectedTypeDisc,
                setOpen: setOpenTypeDisc,
                setSelected: setSelectedTypeDisc,
                label: 'Τύπος',
                multiple: false,
                labelBtn: 'Εφαρμογή',
              },
              {
                choices: discBenefTypeData(discountBenefitsALL?.discBenefType)
                  .filter(option => option.typeName && option.category === 'discount')
                  .map(i => ({ label: i.typeName, value: i._id ?? '' })),
                open: openKindDisc,
                selected: selectedKindDisc,
                setOpen: setOpenKindDisc,
                setSelected: setSelectedKindDisc,
                label: 'Είδος Έκπτωσης',
                multiple: true,
                labelBtn: 'Εφαρμογή',
              },
            ]}
            buttonProps={
              isRaeTeam(team)
                ? undefined
                : {
                    variant: 'contained',
                    onClick: addDiscount,
                    children: 'Προσθήκη Έκπτωσης',
                  }
            }
            searchInput={{
              onChange: e => setSearchDisc(e.target.value),
              value: searchDisc,
              type: 'search',
              label: 'Αναζήτηση ',
            }}
            rows={discountData(isRaeTeam(team), discounts?.documents)}
            type="discount"
            handleEdit={(obj: TableDiscountProps) => editDiscount(obj)}
            handleDelete={(obj: TableDiscountProps) => handleDeleteDisc(obj)}
            handleView={(obj: TableDiscountProps) => handleViewDiscount(obj)}
            handleNewEntry={(obj: TableDiscountProps) => openViewLinkInv(obj)}
            count={discounts?.count ?? 0}
            headCells={[
              { disable: true, id: 'type', label: 'Είδος Έκπτωσης' },
              { disable: false, id: 'name', label: 'Όνομα' },
              { disable: true, id: 'provider', label: 'Πάροχος' },
              // { disable: true, id: 'linkedInvoice', label: 'Συνδεδεμένα Τιμολόγια' },
              { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
              { disable: true, id: 'actions', label: 'Ενέργειες' },
            ]}
            order={orderDisc}
            orderBy={orderByDisc}
            setOrder={setOrderDisc}
            setOrderBy={setOrderByDisc}
            page={pageDisc}
            setPage={setPageDisc}
            rowsPerPage={5}
          />
        ),
      },
      {
        tabName: 'Πρόσθετες Παροχές',
        tabContent: (
          <CustomTable
            dropDownsNew={[
              {
                choices: [
                  { label: energyTypeLabel('electric').gr, value: 'electric' },
                  { label: energyTypeLabel('gas').gr, value: 'gas' },
                ],
                open: openTypeBenef,
                selected: selectedTypeBenef,
                setOpen: setOpenTypeBenef,
                setSelected: setSelectedTypeBenef,
                label: 'Τύπος',
                multiple: false,
                labelBtn: 'Εφαρμογή',
              },
              {
                choices: discBenefTypeData(discountBenefitsALL?.discBenefType)
                  .filter(option => option.typeName && option.category === 'benefit')
                  .map(i => ({ label: i.typeName, value: i._id ?? '' })),
                open: openKindBenef,
                selected: selectedKindBenef,
                setOpen: setOpenKindBenef,
                setSelected: setSelectedKindBenef,
                label: 'Είδος παροχής',
                multiple: true,
                labelBtn: 'Εφαρμογή',
              },
            ]}
            buttonProps={
              isRaeTeam(team)
                ? undefined
                : {
                    variant: 'contained',
                    onClick: addBenefit,
                    children: 'Προσθήκη Παροχής',
                  }
            }
            searchInput={{
              onChange: e => setSearchBenef(e.target.value),
              value: searchBenef,
              type: 'search',
              label: 'Αναζήτηση ',
            }}
            rows={benefitData(isRaeTeam(team), benefits?.documents)}
            type="discount"
            handleEdit={(obj: TableDiscountProps) => editBenefit(obj)}
            handleDelete={(obj: TableDiscountProps) => handleDeleteBenef(obj)}
            handleView={(obj: TableDiscountProps) => handleViewBenefit(obj)}
            handleNewEntry={(obj: TableDiscountProps) => openViewLinkInv(obj)}
            count={benefits?.count ?? 0}
            headCells={[
              { disable: true, id: 'type', label: 'Είδος Παροχής' },
              { disable: false, id: 'name', label: 'Όνομα' },
              { disable: true, id: 'provider', label: 'Πάροχος' },
              // { disable: true, id: 'link-invoices', label: 'Συνδεδεμένα Τιμολόγια' },
              { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
              { disable: true, id: 'actions', label: 'Ενέργειες' },
            ]}
            order={orderBenef}
            orderBy={orderByBenef}
            setOrder={setOrderBenef}
            setOrderBy={setOrderByBenef}
            page={pageBenef}
            setPage={setPageBenef}
            rowsPerPage={5}
          />
        ),
      },
    ];
  }, [
    openKindBenef,
    openKindDisc,
    selectedKindDisc,
    selectedKindBenef,
    openTypeDisc,
    selectedTypeDisc,
    discountBenefitsALL?.discBenefType,
    team,
    addDiscount,
    searchDisc,
    discounts?.documents,
    discounts?.count,
    orderDisc,
    orderByDisc,
    pageDisc,
    openTypeBenef,
    selectedTypeBenef,
    addBenefit,
    searchBenef,
    benefits?.documents,
    benefits?.count,
    orderBenef,
    orderByBenef,
    pageBenef,
    editDiscount,
    handleDeleteDisc,
    handleViewDiscount,
    openViewLinkInv,
    editBenefit,
    handleDeleteBenef,
    handleViewBenefit,
  ]);

  const cancelAddTypeDialog = useCallback(() => {
    setOpenAddType(false);
    setTypeObj({
      typeName: '',
      category: undefined,
      applicationField: undefined,
    });
  }, []);

  const submitlAddTypeDialog = useCallback(async () => {
    setOpenAddType(false);
    switch (typeType) {
      case 'new':
        if (
          !typeObj?.category ||
          !typeObj.typeName ||
          (typeObj?.category === 'discount' && !typeObj.applicationField)
        )
          return;
        const addFields = addDiscBenefTypeData(typeObj);
        try {
          await addDiscountBenefits(addFields).unwrap();
          dispatch(
            setLog({
              severity: 'success',
              message: `Το είδος προστέθηκε επιτυχώς`,
            }),
          );
        } catch (error: any) {
          console.log(error);
          dispatch(
            setLog({
              severity: 'error',
              message: error.data.message,
            }),
          );
        }
        setTypeObj({
          typeName: '',
          category: undefined,
          applicationField: undefined,
        });
        break;
      case 'edit':
        if (
          !typeObj?._id ||
          !typeObj?.category ||
          !typeObj.typeName ||
          (typeObj?.category === 'discount' && !typeObj.applicationField)
        )
          return;
        const editFields = editDiscBenefTypeData(typeObj);
        try {
          await editDiscountBenefits(editFields).unwrap();
          dispatch(
            setLog({
              severity: 'success',
              message: `Το είδος αποθηκεύτηκε επιτυχώς`,
            }),
          );
        } catch (error: any) {
          console.log(error);
          dispatch(
            setLog({
              severity: 'error',
              message: error.data.message,
            }),
          );
        }
        setTypeObj({
          typeName: '',
          category: undefined,
          applicationField: undefined,
        });
        break;
      default:
        break;
    }
  }, [addDiscountBenefits, dispatch, editDiscountBenefits, typeObj, typeType]);

  const cancelAddDiscDialog = useCallback(() => {
    setOpenAddDiscount(false);
    setDiscObj({});
  }, []);

  const submitlAddDiscDialog = useCallback(async () => {
    setOpenAddDiscount(false);
    switch (typeDisc) {
      case 'new':
        if (
          !team ||
          !discObj?.type?._id ||
          !discObj.name ||
          !discObj.nameEn ||
          !discObj.energy ||
          !discObj.radioDisc ||
          !discObj.tieredBilling ||
          !discObj.clients
        )
          return;
        const addFields = addDiscountData(discObj, team);
        try {
          await addDiscountRequest(addFields).unwrap();
          dispatch(
            setLog({
              severity: 'success',
              message: `Η Έκπτωση προστέθηκε επιτυχώς`,
            }),
          );
        } catch (error: any) {
          console.log(error);
          dispatch(
            setLog({
              severity: 'error',
              message: error.data.message,
            }),
          );
        }
        setDiscObj({});
        break;
      case 'edit':
        if (
          !team ||
          !discObj?.type?._id ||
          !discObj._id ||
          !discObj.name ||
          !discObj.nameEn ||
          !discObj.energy ||
          !discObj.radioDisc ||
          !discObj.tieredBilling ||
          !discObj.clients
        )
          return;
        const editFields = editDiscountData(discObj, team);
        try {
          await editDiscountRequest(editFields).unwrap();
          dispatch(
            setLog({
              severity: 'success',
              message: `Η Έκπτωση αποθηκεύτηκε επιτυχώς`,
            }),
          );
        } catch (error: any) {
          console.log(error);
          dispatch(
            setLog({
              severity: 'error',
              message: error.data.message,
            }),
          );
        }
        setDiscObj({});
        break;
      default:
        break;
    }
  }, [addDiscountRequest, discObj, dispatch, editDiscountRequest, typeDisc, team]);

  const cancelAddBenefDialog = useCallback(() => {
    setOpenAddBenef(false);
    setBenefObj({});
  }, []);

  const submitlAddBenefDialog = useCallback(async () => {
    setOpenAddBenef(false);
    switch (typeBenef) {
      case 'new':
        if (
          !team ||
          !benefObj?.type?._id ||
          !benefObj.name ||
          !benefObj.nameEn ||
          !benefObj.energy ||
          !benefObj.clients
        )
          return;
        const addFields = addBenefitData(benefObj, team);
        try {
          await addBenefitRequest(addFields).unwrap();
          dispatch(
            setLog({
              severity: 'success',
              message: 'Η Πρόσθετη Παροχή προστέθηκε επιτυχώς',
            }),
          );
        } catch (error: any) {
          console.log(error);
          dispatch(
            setLog({
              severity: 'error',
              message: error.data.message,
            }),
          );
        }
        setBenefObj({});
        break;
      case 'edit':
        if (
          !team ||
          !benefObj?._id ||
          !benefObj?.type?._id ||
          !benefObj.name ||
          !benefObj.nameEn ||
          !benefObj.energy ||
          !benefObj.clients
        )
          return;
        const editFields = editBenefitData(benefObj, team);
        try {
          await editBenefitRequest(editFields).unwrap();
          dispatch(
            setLog({
              severity: 'success',
              message: 'Η Πρόσθετη Παροχή αποθηκεύτηκε επιτυχώς',
            }),
          );
        } catch (error: any) {
          console.log(error);
          dispatch(
            setLog({
              severity: 'error',
              message: error.data.message,
            }),
          );
        }
        setBenefObj({});
        break;
      default:
        break;
    }
  }, [addBenefitRequest, benefObj, dispatch, editBenefitRequest, typeBenef, team]);

  const isLoading =
    discountBenefitsIsLoading ||
    addDiscountBenefitsLoading ||
    editDiscountBenefitsLoading ||
    deleteDiscountBenefitsLoading ||
    discountsIsLoading ||
    addDiscountLoading ||
    editDiscountLoading ||
    deleteDiscountLoading ||
    benefitsIsLoading ||
    addBenefitLoading ||
    editBenefitLoading ||
    deleteBenefitLoading;

  return (
    <Container maxWidth={'xl'} sx={{ p: 2 }}>
      <Box sx={{ mt: 4 }}>
        <BreadCrumbsCustom maxItems={matches ? 3 : 1} navigate={navigate} links={links} />
      </Box>

      {filters()}

      <Box sx={{ mt: 4 }}>
        {!discountBenefits?.discBenefType && !discounts?.documents && isLoading ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img style={{ width: '140px' }} src={Loader} alt="loading..." />
          </Box>
        ) : (
          <CustomTabs
            tabs={isRaeTeam(team) ? tabsRae : tabsProvider}
            newTab={Number(searchParams.get('t') ?? 0)}
            setActive={i => setSearchParams({ ...searchParams, t: i.toString() })}
          />
        )}
      </Box>

      <PopupForm
        InfoTootipProps={undefined}
        title={typeType === 'new' ? 'Προσθήκη Είδους' : 'Επεξεργασία Είδους'}
        helpText={`${
          typeType === 'new' ? 'Προσθήκη' : 'Διαχείριση'
        } νέου είδους έκπτωσης/πρόσθετης παροχής.`}
        leftBtnTitle="Ακύρωση"
        rightBtnTitle={typeType === 'new' ? 'Προσθήκη' : 'Αποθήκευση'}
        open={openAddType}
        setOpen={() => setOpenAddType(true)}
        onClose={cancelAddTypeDialog}
        onClickLeftBtn={cancelAddTypeDialog}
        onClickRightBtn={submitlAddTypeDialog}
        rightBtnProps={{
          disabled:
            !typeObj.category ||
            !typeObj.typeName ||
            !typeObj.typeNameEn ||
            (typeObj?.category === 'discount' && !typeObj.applicationField),
        }}
        maxWidth="md"
      >
        <AddTypeDialog obj={typeObj} setObj={setTypeObj} type={typeType} />
      </PopupForm>

      <Drawer
        anchor="right"
        open={openAddDiscount}
        onClose={() => setOpenAddDiscount(false)}
        sx={{ position: 'relative' }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            minWidth: { xs: '100%', md: 1400 },
          }}
        >
          {typeDisc === 'new' ? (
            <AddDiscDialog
              optionsType={discBenefTypeData(discountBenefitsALL?.discBenefType)}
              obj={discObj}
              setObj={setDiscObj}
              closeDrawer={setOpenAddDiscount}
              cancel={cancelAddDiscDialog}
              submit={submitlAddDiscDialog}
              view={false}
            />
          ) : typeDisc === 'edit' || typeDisc === 'viewLinkInv' ? (
            <EditDiscDialog
              optionsType={discBenefTypeData(discountBenefitsALL?.discBenefType)}
              obj={discObj}
              setObj={setDiscObj}
              closeDrawer={setOpenAddDiscount}
              cancel={cancelAddDiscDialog}
              submit={submitlAddDiscDialog}
              typeDisc={typeDisc}
              view={false}
            />
          ) : (
            <AddDiscDialog
              optionsType={discBenefTypeData(discountBenefitsALL?.discBenefType)}
              obj={discObj}
              setObj={setDiscObj}
              closeDrawer={setOpenAddDiscount}
              cancel={cancelAddDiscDialog}
              submit={submitlAddDiscDialog}
              view={typeDisc === 'view'}
            />
          )}
        </Box>
      </Drawer>

      <Drawer
        anchor="right"
        open={openAddBenef}
        onClose={() => setOpenAddBenef(false)}
        sx={{ position: 'relative' }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            minWidth: { xs: '100%', md: 1400 },
          }}
        >
          {typeBenef === 'new' ? (
            <AddBenefDialog
              optionsType={discBenefTypeData(discountBenefitsALL?.discBenefType)}
              obj={benefObj}
              setObj={setBenefObj}
              cancel={cancelAddBenefDialog}
              closeDrawer={cancelAddBenefDialog}
              submit={submitlAddBenefDialog}
              view={false}
            />
          ) : typeBenef === 'edit' || typeBenef === 'viewLinkInv' ? (
            <EditBenefDialog
              optionsType={discBenefTypeData(discountBenefitsALL?.discBenefType)}
              obj={benefObj}
              setObj={setBenefObj}
              cancel={cancelAddBenefDialog}
              typeBenef={typeBenef}
              closeDrawer={cancelAddBenefDialog}
              submit={submitlAddBenefDialog}
              view={false}
            />
          ) : (
            <AddBenefDialog
              optionsType={discBenefTypeData(discountBenefitsALL?.discBenefType)}
              obj={benefObj}
              setObj={setBenefObj}
              cancel={cancelAddBenefDialog}
              closeDrawer={cancelAddBenefDialog}
              submit={submitlAddBenefDialog}
              view={typeBenef === 'view'}
            />
          )}
        </Box>
      </Drawer>

      <PopupConfirm
        title="Διαγραφή Έκπτωσης"
        subTile="Είστε σίγουρος ότι θέλετε να προχωρήσετε στη διαγραφή της Έκπτωσης"
        leftBtnTitle="Οχι"
        rightBtnTitle="Ναι"
        open={openDeleteDisc}
        setOpen={setOpenDeleteDisc}
        onClose={setOpenDeleteDisc}
        onClickLeftBtn={() => setOpenDeleteDisc(false)}
        onClickRightBtn={deleteRowDisc}
      />

      <PopupConfirm
        title="Διαγραφή Πρόσθετης Παροχής"
        subTile="Είστε σίγουρος ότι θέλετε να προχωρήσετε στη διαγραφή της Πρόσθετης Παροχής"
        leftBtnTitle="Οχι"
        rightBtnTitle="Ναι"
        open={openDeleteBenef}
        setOpen={setOpenDeleteBenef}
        onClose={setOpenDeleteBenef}
        onClickLeftBtn={() => setOpenDeleteBenef(false)}
        onClickRightBtn={deleteRowBenef}
      />

      <PopupConfirm
        title="Διαγραφή Είδους"
        subTile="Είστε σίγουρος ότι θέλετε να προχωρήσετε στη διαγραφή Είδους"
        leftBtnTitle="Οχι"
        rightBtnTitle="Ναι"
        open={openDeleteType}
        setOpen={setOpenDeleteType}
        onClose={setOpenDeleteType}
        onClickLeftBtn={() => setOpenDeleteType(false)}
        onClickRightBtn={deleteRowType}
      />
    </Container>
  );
};
