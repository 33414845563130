import { Box } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { SettingMenuProps } from '@rae/ui-library/src/components/Menu';
import { CustomMenu } from '@rae/ui-library';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Logo from '../assets/rae-logo.png';
import { Outlet } from 'react-router-dom';
import { Cardprops } from '@rae/ui-library/src/components/NotificationCard';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../redux/apis/authApi';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import {
  useGetNotificationsQuery,
  useReadNotificationMutation,
} from '../redux/apis/notificationApi';
import { pageToSkipLimit } from '../types/ApiPagination';
import { notificationData } from '../types/Notification';
import { isRaeTeam } from '../utils/PickTeam';

const menuBtnLabel = 'Διαχείριση';

const raeMenuItems = [
  { label: 'Χρήστες', link: '/users' },
  { label: 'Πάροχοι', link: '/providers' },
  { label: 'Ταυτοτητα Παροχής', link: '/providerid' },
  { label: 'Έγγραφα', link: '/documents' },
  { label: 'Δείκτες Μέσης Κατανάλωσης', link: '/avrg-consumption' },

  { label: 'Ρυθμιζόμενες Χρεώσεις Η.Ε.', link: '/regular-electric-charges' },
  { label: 'Ρυθμιζόμενες Χρεώσεις Φ.Α.', link: '/regular-gas-charges' },
  { label: 'Ρήτρες Αναπροσαρμογής', link: '/clauses' },
  { label: 'Ανταγωνιστικές Χρεώσεις', link: '/competitive-charges' },
  { label: 'Άλλες Χρεώσεις', link: '/charges' },

  { label: 'Εκπτώσεις & Πρόσθετες Παροχές', link: '/discounts-benefits' },
  { label: 'Εκπτώσεις Κοινωνικού Τιμολογίου', link: '/social-invoice' },
  { label: 'Έκτακτες Εκπτώσεις', link: '/special-discounts' },
];

const providerMenuItems = [
  { label: 'Χρήστες', link: '/users' },
  { label: 'Εκπτώσεις & Πρόσθετες Παροχές', link: '/discounts-benefits' },
];

const img = <img src={Logo} alt="logo" style={{ height: 38, borderRadius: 8 }} />;

export const MenuLayout = () => {
  let navigate = useNavigate();

  const [logout] = useLogoutMutation();
  const [readNotification] = useReadNotificationMutation();

  const { user, team } = useSelector((state: RootState) => state.auth);

  const { data: notifications } = useGetNotificationsQuery({
    read: false,
    ...pageToSkipLimit(0, 5, [`-createdAt`]),
  });

  const name = useMemo(() => {
    if (!user) return '';
    let nameToDisplay = '';
    if (user.firstName) nameToDisplay = user.firstName;
    if (user.lastName)
      nameToDisplay = nameToDisplay
        ? nameToDisplay.concat(' ', user.lastName)
        : user.lastName;
    if (!nameToDisplay) return user.email;
    return nameToDisplay;
  }, [user]);

  const handleLogout = useCallback(async () => {
    try {
      await logout().unwrap();
      navigate('/login');
    } catch (e) {
      console.log(e);
    }
  }, [logout, navigate]);

  const userSetting: SettingMenuProps[] = [
    {
      label: 'Ρυθμίσεις',
      icon: <SettingsOutlinedIcon fontSize="small" sx={{ color: '#000' }} />,
      link: '/settings',
    },
    {
      label: 'Χρήσιμα Έγγραφα',
      icon: <DescriptionOutlinedIcon fontSize="small" sx={{ color: '#000' }} />,
      link: '/settings/docs',
    },
    {
      label: 'Αποσύνδεση',
      icon: <LogoutOutlinedIcon fontSize="small" sx={{ color: '#000' }} />,
      link: '/logout',
    },
  ];

  const indicator = useMemo(() => {
    if (notifications) {
      return notifications.unreadCount > 0;
    } else {
      return false;
    }
  }, [notifications]);

  const redirectNotifications = useCallback(() => {
    return navigate('/notifications');
  }, [navigate]);

  const redirect = useCallback(
    (url?: string) => {
      if (url === '/logout') {
        handleLogout();
      } else {
        navigate(url ? url : '/login');
      }
    },
    [handleLogout, navigate],
  );

  const handleNotification = useCallback(
    async (obj: Cardprops) => {
      if (obj._id) {
        try {
          await readNotification({ _id: obj._id }).unwrap();
          navigate(obj.url ? obj.url : '#');
        } catch (e) {
          console.log(e);
        }
      }
    },
    [navigate, readNotification],
  );

  const handleRedirectHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <Box>
      <CustomMenu
        img={img}
        name={name}
        indicator={indicator}
        userSetting={userSetting}
        mainMenuItems={isRaeTeam(team) ? raeMenuItems : providerMenuItems}
        menuBtnLabel={menuBtnLabel}
        notifications={notificationData(notifications?.notifications)}
        redirectNotifications={redirectNotifications}
        redirect={redirect}
        handleRedirectNotification={handleNotification}
        handleRedirectHome={handleRedirectHome}
        team={team?.nameGr}
        teamImageUrl={team?.image?.url}
        comparisonUrl={process.env.REACT_APP_COMPARISON_BASE_URL}
      />
      <Outlet />
    </Box>
  );
};
