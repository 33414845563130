import { Box } from '@mui/material';
import React, { useState } from 'react';
import { ReviewBadges, CommentCard } from '@rae/ui-library';

type Props = {
  msg?: string;
  view?: boolean;
  title?: string;
  id?: string;
  addComment: (id: string, comment: string) => void;
};

export const ReviewTool = ({ msg, view, title, id, addComment }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <ReviewBadges setOpenDialog={setOpen} comments={msg} disabled={view && !msg} />
      <CommentCard
        _id={id ? id : ''}
        disable={view}
        comment={msg}
        isOpen={open}
        setIsOpen={setOpen}
        submit={(id: string, comment: string) => addComment(id, comment)}
        title={title ? title : ''}
      />
    </Box>
  );
};
