import { Box, Container } from '@mui/material';
import {
  BreadCrumbsCustom,
  CustomChip,
  CustomTabs,
  PopupConfirm,
  PopupForm,
  Tooltip,
} from '@rae/ui-library';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { RegulatedChargesGasTable } from '../components/RegulatedChargesGas/RegulatedChargesTab/RegulatedChargesTable';
import { RegulatedChargesDialog } from '../components/RegulatedChargesGas/RegulatedChargesTab/RegulatedChargesDialog';
import { TableRegulatedChargesGasProps } from '@rae/ui-library/src/components/table/TableRowRegulatedChargesGas';
import { GasProvidersTable } from '../components/RegulatedChargesGas/GasProviders/GasProvidersTable';
import { TableGasProvidersProps } from '@rae/ui-library/src/components/table/TableRowGasProviders';
import { GasProvidersDialog } from '../components/RegulatedChargesGas/GasProviders/GasProvidersDIalog';
import { GasDistributionMngmentTable } from '../components/RegulatedChargesGas/GasDistributionMngment/GasDistributionMngmentTable';
import { TableDistributionGasMngmentProps } from '@rae/ui-library/src/components/table/TableRowDistributionGasMngment';
import { DistributionGasMngmentDialod } from '../components/RegulatedChargesGas/GasDistributionMngment/DistributionGasMngmentDialog';
import { DistributionGasChargesTable } from '../components/RegulatedChargesGas/DistributionGasCharges/ DistributionGasChargesGTable';
import { TransmissionGasChargesDialog } from '../components/RegulatedChargesGas/TransmissionGasCharges.tsx/TransmissionGasChargesDialog';
import {
  useAddGasProviderMutation,
  useDeleteGasProciderMutation,
  useEditGasProviderMutation,
  useGetGasProvidersQuery,
} from '../redux/apis/gasProviderApi';
import { pageToSkipLimit } from '../types/ApiPagination';
import { HeadCell, OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import { addGasProviderFields, editGasProviderFields } from '../types/GasProvider';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { setLog } from '../redux/slices/appSlice';
import Loader from '../assets/loading.gif';
import {
  useAddManagmentGasNetworkMutation,
  useDeleteManagmentGasNetworkMutation,
  useEditManagmentGasNetworkMutation,
  useGetManagmentGasNetworksQuery,
} from '../redux/apis/managmentGasNetworkApi';
import {
  addNetworkGasManagmentFields,
  editNetworkGasManagmentFields,
  managmentGasNetworksData,
} from '../types/ManagmentGasNetwork';
import {
  useAddRegulatedChargesMutation,
  useDeleteRegulatedChargesMutation,
  useEditRegulatedChargesMutation,
  useGetRegulatedChargesQuery,
} from '../redux/apis/regulatedChargesGasApi';
import {
  addRegulatedChargesGasFields,
  editRegulatedChargesGasFields,
  regulatedChargesGasData,
} from '../types/RegulatedChargesGas';
import useDebounce from '../utils/useDebounce';
import { useNavigate, useSearchParams } from 'react-router-dom';

export type GasChargesSearchProps = {
  regulatedCharges: {
    dfa: string[];
    zone: string[];
    year: string[];
    category: string[];
    searchValue: string;
  };
  gasProviders: {
    searchValue: string;
  };
  distributionGas: {
    dfa: string[];
    zone: string[];
    city: string[];
    searchValue: string;
  };
  distributionGasCharges: {
    dfa: string[];
    zone: string[];
    year: string[];
    category: string[];
    searchValue: string;
  };
  transmissionGasCharges: {
    gasAdministrator: string[];
    month: string[];
    zone: string[];
    year: string[];
    category: string[];
    searchValue: string;
  };
};

export type ActionsProps = {
  tab1: {
    type: 'edit' | 'view' | 'new' | 'delete';
  };
  tab2: {
    type: 'edit' | 'view' | 'new' | 'delete';
  };
  tab3: {
    type: 'edit' | 'view' | 'new' | 'delete';
  };
  tab4: {
    type: 'edit' | 'view' | 'new' | 'delete';
  };
  tab5: {
    type: 'edit' | 'view' | 'new' | 'delete';
  };
};

export const RegularCHargesGas = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [tabValue, setValueTab] = useState<number>(0);

  const [orderTabs2, setOrderTabs2] = useState<OrderProps>('asc');
  const [orderByTabs2, setOrderByTabs2] = useState<HeadCell['id']>('updatedAt');
  const [pageTabs2, setPageTabs2] = useState<number>(0);

  const [orderTabs3, setOrderTabs3] = useState<OrderProps>('asc');
  const [orderByTabs3, setOrderByTabs3] = useState<HeadCell['id']>('updatedAt');
  const [pageTabs3, setPageTabs3] = useState<number>(0);

  const [orderTabs1, setOrderTabs1] = useState<OrderProps>('asc');
  const [orderByTabs1, setOrderByTabs1] = useState<HeadCell['id']>('updatedAt');
  const [pageTabs1, setPageTabs1] = useState<number>(0);

  const [openRegulatedChargesDialog, setOpenRegulatedChargesDialog] =
    useState<boolean>(false);
  const [openDeleteRegulatedChargesDialog, setOpenDeleteRegulatedChargesDialog] =
    useState<boolean>(false);
  const [openGasProvidersDialog, setOpenGasProvidersDialog] = useState<boolean>(false);
  const [openDistributionGasMngmentDialog, setOpenDistributionGasMngmentDialog] =
    useState<boolean>(false);
  const [openDistributionGasChargesDialog, setOpenDistributionGasChargesDialog] =
    useState<boolean>(false);
  const [openTransmissionGasChargesDialog, setOpenTransmissionGasChargesDialog] =
    useState<boolean>(false);
  const [openDeleteGasProviders, setOpenDeleteGasProviders] = useState<boolean>(false);
  const [openDeleteDistributionGasMngment, setOpenDeleteDistributionGasMngment] =
    useState<boolean>(false);
  const [search, setSearch] = useState<GasChargesSearchProps>({
    regulatedCharges: {
      category: [],
      dfa: [],
      year: [],
      zone: [],
      searchValue: '',
    },
    gasProviders: {
      searchValue: '',
    },
    distributionGas: {
      city: [],
      dfa: [],
      searchValue: '',
      zone: [],
    },
    distributionGasCharges: {
      category: [],
      dfa: [],
      year: [],
      zone: [],
      searchValue: '',
    },
    transmissionGasCharges: {
      category: [],
      gasAdministrator: [],
      month: [],
      searchValue: '',
      year: [],
      zone: [],
    },
  });

  const debouncedSearchGssMngmentNet = useDebounce<string>(
    search.distributionGas.searchValue,
    300,
  );

  const { data: gasProviders, isLoading: getProviderIsLoading } = useGetGasProvidersQuery(
    pageToSkipLimit(pageTabs2, 5, [`${orderTabs2 === 'asc' ? '-' : ''}${orderByTabs2}`]),
  );

  const [addGasProvider, { isLoading: addProviderIsLoading }] =
    useAddGasProviderMutation();
  const [editGasProvider, { isLoading: editProviderIsLoading }] =
    useEditGasProviderMutation();
  const [deleteGasProvider, { isLoading: deleteProviderIsLoading }] =
    useDeleteGasProciderMutation();

  const { data: gasNetworksManagment, isLoading: networksManagmentIsLoading } =
    useGetManagmentGasNetworksQuery({
      ...pageToSkipLimit(pageTabs3, 5, [
        `${orderTabs3 === 'asc' ? '-' : ''}${orderByTabs3}`,
      ]),
      search: debouncedSearchGssMngmentNet,
    });

  const [addNetworkManagment, { isLoading: addNetworkManagmentIsLoading }] =
    useAddManagmentGasNetworkMutation();
  const [editNetworkManagment, { isLoading: editNetworkManagmentIsLoading }] =
    useEditManagmentGasNetworkMutation();
  const [deleteNetworkManagment, { isLoading: deleteNetworkManagmentIsLoading }] =
    useDeleteManagmentGasNetworkMutation();

  const { data: regulatedCharges, isLoading: regulatedChargesIsLoading } =
    useGetRegulatedChargesQuery(
      pageToSkipLimit(pageTabs1, 1000, [
        `${orderTabs1 === 'asc' ? '-' : ''}${orderByTabs1}`,
      ]),
    );
  const [addRegulatedCharges, { isLoading: addRegulatedChargesIsLoading }] =
    useAddRegulatedChargesMutation();
  const [editRegulatedCharges, { isLoading: editRegulatedChargesIsLoading }] =
    useEditRegulatedChargesMutation();
  const [deleteRegulatedCharges, { isLoading: deleteRegulatedChargesIsLoading }] =
    useDeleteRegulatedChargesMutation();

  const [tab1Obj, setTab1Obj] = useState<TableRegulatedChargesGasProps>({});
  const [tab2Obj, setTab2Obj] = useState<TableGasProvidersProps>({});
  const [tab3Obj, setTab3Obj] = useState<TableDistributionGasMngmentProps>({});
  const [tab4Obj, setTab4Obj] = useState<TableRegulatedChargesGasProps>({});
  const [tab5Obj, setTab5Obj] = useState<TableRegulatedChargesGasProps>({});
  const [tabActions, setTabActions] = useState<ActionsProps>({
    tab1: {
      type: 'new',
    },
    tab2: {
      type: 'new',
    },
    tab3: {
      type: 'new',
    },
    tab4: {
      type: 'new',
    },
    tab5: {
      type: 'new',
    },
  });

  const isLoading =
    getProviderIsLoading ||
    addProviderIsLoading ||
    editProviderIsLoading ||
    deleteProviderIsLoading ||
    networksManagmentIsLoading ||
    addNetworkManagmentIsLoading ||
    editNetworkManagmentIsLoading ||
    deleteNetworkManagmentIsLoading ||
    regulatedChargesIsLoading ||
    addRegulatedChargesIsLoading ||
    deleteRegulatedChargesIsLoading ||
    editRegulatedChargesIsLoading;

  const links = [{ name: 'Προϊόντα', href: '/' }, { name: 'Ρυθμιζόμενες Χρεώσεις Φ.Α ' }];

  useEffect(() => {
    setValueTab(Number(searchParams.get('t') ?? 0));
  }, [searchParams]);

  const handleActionsRegulatedCharges = useCallback(
    (obj: TableRegulatedChargesGasProps, action: ActionsProps['tab1']['type']) => {
      setTab1Obj(obj);
      if (action === 'delete') {
        setOpenDeleteRegulatedChargesDialog(true);
      } else if (action === 'new') {
        setOpenRegulatedChargesDialog(true);
      } else {
        setOpenRegulatedChargesDialog(true);
      }
      setTabActions((values: ActionsProps) => ({
        ...values,
        tab1: {
          type: action,
        },
      }));
    },
    [],
  );

  const handleActionsGasProviders = useCallback(
    (obj: TableGasProvidersProps, action: ActionsProps['tab2']['type']) => {
      setTab2Obj(obj);
      if (action === 'delete') {
        setOpenDeleteGasProviders(true);
      } else {
        setOpenGasProvidersDialog(true);
      }
      setTabActions((values: ActionsProps) => ({
        ...values,
        tab2: {
          type: action,
        },
      }));
    },
    [],
  );

  const handleActionsGasDistributionMngment = useCallback(
    (obj: TableDistributionGasMngmentProps, action: ActionsProps['tab3']['type']) => {
      setTab3Obj(obj);
      if (action === 'delete') {
        setOpenDeleteDistributionGasMngment(true);
      } else {
        setOpenDistributionGasMngmentDialog(true);
      }
      setTabActions((values: ActionsProps) => ({
        ...values,
        tab3: {
          type: action,
        },
      }));
    },
    [],
  );

  const handleActionsGasDistributionCharges = useCallback(
    (obj: TableRegulatedChargesGasProps, action: ActionsProps['tab4']['type']) => {
      setTab4Obj(obj);
      setOpenDistributionGasChargesDialog(true);
      setTabActions((values: ActionsProps) => ({
        ...values,
        tab4: {
          type: action,
        },
      }));
    },
    [],
  );

  const handleSaveRegulatedChargess = useCallback(async () => {
    setOpenRegulatedChargesDialog(false);
    if (tabActions.tab1.type === 'new') {
      if (!tab1Obj || !tab1Obj.network || !tab1Obj.category) return;
      const addFields = addRegulatedChargesGasFields(tab1Obj);
      try {
        await addRegulatedCharges(addFields).unwrap();
        dispatch(
          setLog({
            severity: 'success',
            message: `Η Ρυθμιζόμενη χρέωση προστεθήκε επιτυχώς`,
          }),
        );
      } catch (e: any) {
        dispatch(
          setLog({
            severity: 'error',
            message: `${e.data.message}`,
          }),
        );
      }
      setTab1Obj({});
    } else if (tabActions.tab1.type === 'delete') {
      setOpenDeleteRegulatedChargesDialog(false);
      if (!tab1Obj || !tab1Obj._id) return;
      try {
        await deleteRegulatedCharges({ _id: tab1Obj._id }).unwrap();
        dispatch(
          setLog({
            severity: 'success',
            message: `Η Ρυθμιζόμενη χρέωση διαγράφθηκε
      επιτυχώς`,
          }),
        );
      } catch (e: any) {
        dispatch(
          setLog({
            severity: 'error',
            message: `${e.data.message}`,
          }),
        );
      }
      setTab1Obj({});
    } else {
      if (!tab1Obj || !tab1Obj._id || !tab1Obj.network || !tab1Obj.category) return;
      const editFields = editRegulatedChargesGasFields(tab1Obj);
      try {
        await editRegulatedCharges(editFields).unwrap();
        dispatch(
          setLog({
            severity: 'success',
            message: `Η Ρυθμιζόμενη χρέωση επεξεργάστηκε επιτυχώς`,
          }),
        );
      } catch (e: any) {
        dispatch(
          setLog({
            severity: 'error',
            message: `${e.data.message}`,
          }),
        );
      }
      setTab1Obj({});
    }
  }, [
    addRegulatedCharges,
    deleteRegulatedCharges,
    dispatch,
    editRegulatedCharges,
    tab1Obj,
    tabActions.tab1.type,
  ]);

  //actions - tab2
  const handleSaveGasProviders = useCallback(() => {
    if (tabActions.tab2.type === 'new') {
      if (!tab2Obj || !tab2Obj.providerName) return;
      const addFields = addGasProviderFields(tab2Obj);
      try {
        addGasProvider(addFields).unwrap();
        dispatch(
          setLog({
            severity: 'success',
            message: `Ο διαχειριστής Φυσικού Αερίου αποθηκεύτηκε επιτυχώς`,
          }),
        );
      } catch (e: any) {
        dispatch(
          setLog({
            severity: 'error',
            message: `${e.data.message}`,
          }),
        );
      }
      setTab2Obj({});
      setOpenGasProvidersDialog(false);
    } else if (tabActions.tab2.type === 'delete') {
      if (!tab2Obj || !tab2Obj._id) return;
      try {
        deleteGasProvider({ _id: tab2Obj._id }).unwrap();
        dispatch(
          setLog({
            severity: 'success',
            message: `Ο διαχειριστής Φυσικού Αερίου διαγράφθηκε
    επιτυχώς`,
          }),
        );
      } catch (e: any) {
        dispatch(
          setLog({
            severity: 'error',
            message: `${e.data.message}`,
          }),
        );
      }
      setOpenDeleteGasProviders(false);
    } else {
      if (!tab2Obj || !tab2Obj._id || !tab2Obj.providerName || !tab2Obj.providerInfo)
        return;
      const editFields = editGasProviderFields(tab2Obj);
      try {
        editGasProvider(editFields).unwrap();
        dispatch(
          setLog({
            severity: 'success',
            message: `Ο διαχειριστής Φυσικού Αερίου επεξεργάστηκε
            επιτυχώς`,
          }),
        );
      } catch (e: any) {
        dispatch(
          setLog({
            severity: 'error',
            message: `${e.data.message}`,
          }),
        );
      }
      setTab2Obj({});
      setOpenGasProvidersDialog(false);
    }
  }, [
    addGasProvider,
    deleteGasProvider,
    dispatch,
    editGasProvider,
    tab2Obj,
    tabActions.tab2.type,
  ]);

  //actions - tab3
  const handleSaveGasDistributionMngment = useCallback(() => {
    if (tabActions.tab3.type === 'new') {
      if (!tab3Obj || !tab3Obj.gasProvider?._id) return;
      const addFields = addNetworkGasManagmentFields(tab3Obj);
      try {
        addNetworkManagment(addFields).unwrap();
        dispatch(
          setLog({
            severity: 'success',
            message: `Το δίκτυο προστεθήκε επιτυχώς`,
          }),
        );
      } catch (e: any) {
        dispatch(
          setLog({
            severity: 'error',
            message: `${e.data.message}`,
          }),
        );
      }
      setOpenDistributionGasMngmentDialog(false);
      setTab3Obj({});
    } else if (tabActions.tab3.type === 'delete') {
      if (!tab3Obj || !tab3Obj._id) return;
      try {
        deleteNetworkManagment({ _id: tab3Obj._id }).unwrap();
        dispatch(
          setLog({
            severity: 'success',
            message: `Το δίκτυο διαγράφθηκε
      επιτυχώς`,
          }),
        );
      } catch (e: any) {
        dispatch(
          setLog({
            severity: 'error',
            message: `${e.data.message}`,
          }),
        );
      }
      setOpenDeleteDistributionGasMngment(false);
      setTab3Obj({});
    } else {
      if (!tab3Obj || !tab3Obj._id || !tab3Obj.gasProvider?._id) return;
      const editFields = editNetworkGasManagmentFields(tab3Obj);
      try {
        editNetworkManagment(editFields).unwrap();
        dispatch(
          setLog({
            severity: 'success',
            message: `Το δίκτυο επεξεργάστηκε επιτυχώς`,
          }),
        );
      } catch (e: any) {
        dispatch(
          setLog({
            severity: 'error',
            message: `${e.data.message}`,
          }),
        );
      }
      setOpenDistributionGasMngmentDialog(false);
      setTab3Obj({});
    }
  }, [
    addNetworkManagment,
    deleteNetworkManagment,
    dispatch,
    editNetworkManagment,
    tab3Obj,
    tabActions.tab3.type,
  ]);

  const tabs = [
    {
      tabName: 'Ρυθμιζόμενες Χρεώσεις Φ.Α.',
      tabContent: (
        <RegulatedChargesGasTable
          search={search}
          setSearch={setSearch}
          handleActionsRegulatedCharges={handleActionsRegulatedCharges}
          data={regulatedChargesGasData(regulatedCharges?.documents)}
          order={orderTabs1}
          orderBy={orderByTabs1}
          page={pageTabs1}
          setOrder={setOrderTabs1}
          setOrderBy={setOrderByTabs1}
          setPage={setPageTabs1}
        />
      ),
      disabled: false,
    },
    {
      tabName: 'Διαχειριστές Φ.Α.',
      tabContent: (
        <GasProvidersTable
          handleActionsGasProviders={handleActionsGasProviders}
          search={search}
          setSearch={setSearch}
          order={orderTabs2}
          orderBy={orderByTabs2}
          page={pageTabs2}
          setOrder={setOrderTabs2}
          setOrderBy={setOrderByTabs2}
          setPage={setPageTabs2}
          data={gasProviders}
        />
      ),
      disabled: false,
    },
    {
      tabName: 'Διαχείριση Δικτύων Φ.Α.',
      tabContent: (
        <GasDistributionMngmentTable
          search={search}
          setSearch={setSearch}
          handleActionsGasDistributionMngment={handleActionsGasDistributionMngment}
          optionsProvider={gasProviders?.documents.map(provider => provider)}
          order={orderTabs3}
          orderBy={orderByTabs3}
          page={pageTabs3}
          setOrder={setOrderTabs3}
          setOrderBy={setOrderByTabs3}
          setPage={setPageTabs3}
          data={gasNetworksManagment}
        />
      ),
      disabled: false,
    },
    {
      tabName: 'Χ.Χ. Δικτύου Διανομής',
      tabContent: (
        <DistributionGasChargesTable
          search={search}
          setSearch={setSearch}
          data={regulatedChargesGasData(regulatedCharges?.documents)}
          handleActionsGasDistributionCharges={handleActionsGasDistributionCharges}
          optionsProvider={gasProviders?.documents.map(provider => provider)}
        />
      ),
      disabled: false,
    },
    // {
    //   tabName: 'Χ.Χ. Συστήματος Μεταφοράς',
    //   tabContent: <TransmissionGasChargesTable search={search} setSearch={setSearch} />,
    //   disabled: false,
    // },
  ];

  const filtersTab0 = useMemo(
    () =>
      search.regulatedCharges.dfa.concat(
        search.regulatedCharges.category,
        search.regulatedCharges.year,
        search.regulatedCharges.zone,
        search.regulatedCharges.searchValue !== ''
          ? search.regulatedCharges.searchValue
          : [],
      ),
    [search],
  );

  const filtersTab2 = useMemo(
    () =>
      search.distributionGas.dfa.concat(
        search.distributionGas.city,
        search.distributionGas.zone,
        search.distributionGas.searchValue !== ''
          ? search.distributionGas.searchValue
          : [],
      ),
    [search],
  );

  const filtersTab3 = useMemo(
    () =>
      search.distributionGasCharges.dfa.concat(
        search.distributionGasCharges.category,
        search.distributionGasCharges.year,
        search.distributionGasCharges.zone,
        search.distributionGasCharges.searchValue !== ''
          ? search.distributionGasCharges.searchValue
          : [],
      ),
    [search],
  );

  const filtersTab4 = useMemo(
    () =>
      search.transmissionGasCharges.gasAdministrator.concat(
        search.transmissionGasCharges.category,
        search.transmissionGasCharges.year,
        search.transmissionGasCharges.zone,
        search.transmissionGasCharges.month,
        search.transmissionGasCharges.searchValue !== ''
          ? search.transmissionGasCharges.searchValue
          : [],
      ),
    [search],
  );

  const clearFilters = useCallback(() => {
    switch (tabValue) {
      case 0:
        setSearch((values: GasChargesSearchProps) => ({
          ...values,
          regulatedCharges: {
            category: [],
            dfa: [],
            year: [],
            zone: [],
            searchValue: '',
          },
        }));
        break;
      case 1:
        setSearch((values: GasChargesSearchProps) => ({
          ...values,
          gasProviders: {
            searchValue: '',
          },
        }));
        break;
      case 2:
        setSearch((values: GasChargesSearchProps) => ({
          ...values,
          distributionGas: {
            dfa: [],
            city: [],
            zone: [],
            searchValue: '',
          },
        }));
        break;
      case 3:
        setSearch((values: GasChargesSearchProps) => ({
          ...values,
          distributionGasCharges: {
            category: [],
            dfa: [],
            year: [],
            zone: [],
            searchValue: '',
          },
        }));
        break;
      case 4:
        setSearch((values: GasChargesSearchProps) => ({
          ...values,
          transmissionGasCharges: {
            category: [],
            year: [],
            zone: [],
            gasAdministrator: [],
            month: [],
            searchValue: '',
          },
        }));
        break;
      default:
        break;
    }
  }, [tabValue]);

  const deleteChip = useCallback(
    (chipLabel: string) => {
      switch (tabValue) {
        case 0:
          setSearch((values: GasChargesSearchProps) => ({
            ...values,
            regulatedCharges: {
              category: search.regulatedCharges.category.filter(
                label => label !== chipLabel,
              ),
              dfa: search.regulatedCharges.dfa.filter(label => label !== chipLabel),
              year: search.regulatedCharges.year.filter(label => label !== chipLabel),
              zone: search.regulatedCharges.zone.filter(label => label !== chipLabel),
              searchValue:
                search.regulatedCharges.searchValue === chipLabel
                  ? ''
                  : search.regulatedCharges.searchValue,
            },
          }));
          break;
        case 1:
          setSearch((values: GasChargesSearchProps) => ({
            ...values,
            gasProviders: {
              searchValue: '',
            },
          }));
          break;
        case 2:
          setSearch((values: GasChargesSearchProps) => ({
            ...values,
            distributionGas: {
              dfa: search.distributionGas.dfa.filter(label => label !== chipLabel),
              city: search.distributionGas.city.filter(label => label !== chipLabel),
              zone: search.distributionGas.zone.filter(label => label !== chipLabel),
              searchValue:
                search.distributionGas.searchValue === chipLabel
                  ? ''
                  : search.distributionGas.searchValue,
            },
          }));
          break;
        case 3:
          setSearch((values: GasChargesSearchProps) => ({
            ...values,
            distributionGasCharges: {
              category: search.distributionGasCharges.category.filter(
                label => label !== chipLabel,
              ),
              dfa: search.distributionGasCharges.dfa.filter(label => label !== chipLabel),
              year: search.distributionGasCharges.year.filter(
                label => label !== chipLabel,
              ),
              zone: search.distributionGasCharges.zone.filter(
                label => label !== chipLabel,
              ),
              searchValue:
                search.distributionGasCharges.searchValue === chipLabel
                  ? ''
                  : search.distributionGasCharges.searchValue,
            },
          }));
          break;
        case 4:
          setSearch((values: GasChargesSearchProps) => ({
            ...values,
            transmissionGasCharges: {
              category: search.transmissionGasCharges.category.filter(
                label => label !== chipLabel,
              ),
              gasAdministrator: search.transmissionGasCharges.gasAdministrator.filter(
                label => label !== chipLabel,
              ),
              year: search.transmissionGasCharges.year.filter(
                label => label !== chipLabel,
              ),
              month: search.transmissionGasCharges.month.filter(
                label => label !== chipLabel,
              ),
              zone: search.transmissionGasCharges.zone.filter(
                label => label !== chipLabel,
              ),
              searchValue:
                search.transmissionGasCharges.searchValue === chipLabel
                  ? ''
                  : search.transmissionGasCharges.searchValue,
            },
          }));
          break;
        default:
          break;
      }
    },
    [search, tabValue],
  );

  const renderTooltip = useMemo(() => {
    switch (tabValue) {
      case 0:
        return filtersTab0.length > 0 ? (
          <Tooltip
            text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
            type="info"
            btnAction={clearFilters}
            btnLabel="Καθαρισμός Φίλτρων"
            dismiss={true}
            icon={<InfoOutlinedIcon fontSize="small" />}
          />
        ) : null;
      case 1:
        return search.gasProviders.searchValue &&
          search.gasProviders.searchValue !== '' ? (
          <Tooltip
            text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
            type="info"
            btnAction={clearFilters}
            btnLabel="Καθαρισμός Φίλτρων"
            dismiss={true}
            icon={<InfoOutlinedIcon fontSize="small" />}
          />
        ) : null;
      case 2:
        return filtersTab2.length > 0 ? (
          <Tooltip
            text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
            type="info"
            btnAction={clearFilters}
            btnLabel="Καθαρισμός Φίλτρων"
            dismiss={true}
            icon={<InfoOutlinedIcon fontSize="small" />}
          />
        ) : null;
      case 3:
        return filtersTab3.length > 0 ? (
          <Tooltip
            text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
            type="info"
            btnAction={clearFilters}
            btnLabel="Καθαρισμός Φίλτρων"
            dismiss={true}
            icon={<InfoOutlinedIcon fontSize="small" />}
          />
        ) : null;
      case 4:
        return filtersTab4.length > 0 ? (
          <Tooltip
            text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
            type="info"
            btnAction={clearFilters}
            btnLabel="Καθαρισμός Φίλτρων"
            dismiss={true}
            icon={<InfoOutlinedIcon fontSize="small" />}
          />
        ) : null;
      default:
        break;
    }
  }, [
    clearFilters,
    filtersTab0.length,
    filtersTab2.length,
    filtersTab3.length,
    filtersTab4.length,
    search.gasProviders.searchValue,
    tabValue,
  ]);

  const renderChips = useMemo(() => {
    switch (tabValue) {
      case 0:
        return filtersTab0.map((chiplabel, i) => {
          return (
            <CustomChip
              key={i}
              label={chiplabel}
              onDelete={() => deleteChip(chiplabel)}
            />
          );
        });
      case 1:
        return search.gasProviders.searchValue &&
          search.gasProviders.searchValue !== '' ? (
          <CustomChip
            label={search.gasProviders.searchValue}
            onDelete={() => deleteChip('')}
          />
        ) : null;
      case 2:
        return filtersTab2.map((chiplabel, i) => {
          return (
            <CustomChip
              key={i}
              label={chiplabel}
              onDelete={() => deleteChip(chiplabel)}
            />
          );
        });
      case 3:
        return filtersTab3.map((chiplabel, i) => {
          return (
            <CustomChip
              key={i}
              label={chiplabel}
              onDelete={() => deleteChip(chiplabel)}
            />
          );
        });
      case 4:
        return filtersTab4.map((chiplabel, i) => {
          return (
            <CustomChip
              key={i}
              label={chiplabel}
              onDelete={() => deleteChip(chiplabel)}
            />
          );
        });
      default:
        break;
    }
  }, [
    deleteChip,
    filtersTab0,
    filtersTab2,
    filtersTab3,
    filtersTab4,
    search.gasProviders.searchValue,
    tabValue,
  ]);

  return (
    <Container maxWidth={'xl'} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mt: 4 }}>
        <BreadCrumbsCustom navigate={navigate} links={links} />
      </Box>

      <Box sx={{ mt: 4 }}>{renderTooltip}</Box>

      <Box sx={{ mt: 2 }}>{renderChips}</Box>

      <Box sx={{ mt: 4, mb: 4 }}>
        {!gasProviders?.documents && isLoading ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img style={{ width: '140px' }} src={Loader} alt="loading..." />
          </Box>
        ) : (
          <CustomTabs
            tabs={tabs}
            newTab={Number(searchParams.get('t') ?? 0)}
            setActive={i => setSearchParams({ ...searchParams, t: i.toString() })}
          />
        )}
      </Box>

      <PopupForm
        maxWidth="xl"
        InfoTootipProps={undefined}
        title={
          tabActions.tab1.type === 'new'
            ? 'Προσθήκη Ρυθμιζόμενης Χρέωσης Φυσικόυ Αερίου'
            : tabActions.tab1.type === 'edit'
            ? 'Επεξεργασία Ρυθμιζόμενης Χρέωσης Φυσικόυ Αερίου'
            : 'Προβολή Ρυθμιζόμενης Χρέωσης Φυσικόυ Αερίου'
        }
        helpText={
          tabActions.tab1.type === 'new'
            ? ' Προσθήκη νέας Ρυθμζόμενης Χρέωσης.'
            : tabActions.tab1.type === 'edit'
            ? 'Επεξεργασία Ρυθμιζόμενων Χρεώσεων.'
            : 'Προβολή Ρυθμιζόμενων Χρεώσεων.'
        }
        leftBtnTitle={tabActions.tab1.type !== 'view' ? 'Ακύρωση' : 'Κλείσιμο'}
        rightBtnTitle={
          tabActions.tab1.type === 'new'
            ? 'Προσθήκη'
            : tabActions.tab1.type === 'edit'
            ? 'Αποθήκευση'
            : undefined
        }
        open={openRegulatedChargesDialog}
        setOpen={() => setOpenRegulatedChargesDialog(true)}
        onClose={() => setOpenRegulatedChargesDialog(false)}
        onClickLeftBtn={() => setOpenRegulatedChargesDialog(false)}
        onClickRightBtn={handleSaveRegulatedChargess}
      >
        <RegulatedChargesDialog
          obj={tab1Obj}
          setObj={setTab1Obj}
          action={tabActions.tab1.type}
          networkOptions={managmentGasNetworksData(gasNetworksManagment?.documents)}
        />
      </PopupForm>

      <PopupConfirm
        title="Διαγραφή Ρυθμζόμενης Χρέωσης"
        subTile="Είστε σίγουρος ότι θέλετε να προχωρήσετε στη διαγραφή της Ρυθμζόμενης Χρέωσης;"
        leftBtnTitle="Οχι"
        rightBtnTitle="Ναι"
        open={openDeleteRegulatedChargesDialog}
        setOpen={setOpenDeleteRegulatedChargesDialog}
        onClose={() => setOpenDeleteRegulatedChargesDialog(false)}
        onClickLeftBtn={() => setOpenDeleteRegulatedChargesDialog(false)}
        onClickRightBtn={handleSaveRegulatedChargess}
      />

      <PopupForm
        maxWidth="md"
        InfoTootipProps={undefined}
        title={
          tabActions.tab2.type === 'new'
            ? 'Προσθήκη Διαχειριστή Φυσικού Αερίου'
            : 'Επεξεργασία Διαχειριστή Φυσικού Αερίου'
        }
        helpText={
          tabActions.tab2.type === 'new'
            ? 'Προσθήκη Διαχειριστών Δικτύων Φυσικού Αερίου'
            : 'Επεξεργασία Διαχειριστών Δικτύων Φυσικού Αερίου'
        }
        leftBtnTitle="Ακύρωση"
        rightBtnTitle={tabActions.tab2.type === 'new' ? 'Προσθήκη' : 'Αποθήκευση'}
        open={openGasProvidersDialog}
        setOpen={() => setOpenGasProvidersDialog(true)}
        onClose={() => setOpenGasProvidersDialog(false)}
        onClickLeftBtn={() => setOpenGasProvidersDialog(false)}
        onClickRightBtn={handleSaveGasProviders}
        rightBtnProps={{ disabled: !tab2Obj.providerName }}
      >
        <GasProvidersDialog obj={tab2Obj} setObj={setTab2Obj} />
      </PopupForm>

      <PopupConfirm
        title="Διαγραφή Διαχειριστή Φυσικού Αερίου"
        subTile="Είστε σίγουρος ότι θέλετε να προχωρήσετε στη διαγραφή του Διαχειριστή Φυσικού Αερίου;"
        leftBtnTitle="Οχι"
        rightBtnTitle="Ναι"
        open={openDeleteGasProviders}
        setOpen={setOpenDeleteGasProviders}
        onClose={() => setOpenDeleteGasProviders(false)}
        onClickLeftBtn={() => setOpenDeleteGasProviders(false)}
        onClickRightBtn={handleSaveGasProviders}
      />

      <PopupForm
        maxWidth="lg"
        InfoTootipProps={undefined}
        title={
          tabActions.tab3.type === 'new' ? 'Προσθήκη Δικτύου' : 'Επεξεργασία Δικτύου'
        }
        helpText={
          tabActions.tab3.type === 'new'
            ? 'Διαχείρηση/ Προσθήκη Δικτύων Διανομής Φυσικού Αερίου'
            : 'Διαχείρηση/ Προσθήκη Δικτύων Διανομής Φυσικού Αερίου'
        }
        leftBtnTitle="Ακύρωση"
        rightBtnTitle={tabActions.tab3.type === 'new' ? 'Προσθήκη' : 'Αποθήκευση'}
        open={openDistributionGasMngmentDialog}
        setOpen={() => setOpenDistributionGasMngmentDialog(true)}
        onClose={() => setOpenDistributionGasMngmentDialog(false)}
        onClickLeftBtn={() => setOpenDistributionGasMngmentDialog(false)}
        onClickRightBtn={handleSaveGasDistributionMngment}
        rightBtnProps={{
          disabled:
            tab3Obj.cityGr === undefined ||
            tab3Obj.gasProvider === undefined ||
            tab3Obj?.remoteNetwork === undefined ||
            tab3Obj.zone === undefined,
        }}
      >
        <DistributionGasMngmentDialod
          obj={tab3Obj}
          setObj={setTab3Obj}
          optionsProvider={gasProviders?.documents.map(provider => provider)}
        />
      </PopupForm>

      <PopupConfirm
        title="Διαγραφή Δικτύου Φυσικού Αερίου"
        subTile="Είστε σίγουρος ότι θέλετε να προχωρήσετε στη διαγραφή του Δικτύου Φυσικού Αερίου;"
        leftBtnTitle="Οχι"
        rightBtnTitle="Ναι"
        open={openDeleteDistributionGasMngment}
        setOpen={setOpenDeleteDistributionGasMngment}
        onClose={() => setOpenDeleteDistributionGasMngment(false)}
        onClickLeftBtn={() => setOpenDeleteDistributionGasMngment(false)}
        onClickRightBtn={handleSaveGasDistributionMngment}
      />

      <PopupForm
        maxWidth="xl"
        InfoTootipProps={undefined}
        title={'Προβολή Ρυθμιζόμενης Χρέωσης Φυσικόυ Αερίου'}
        helpText={
          'Επικαιροποίηση Χρεώσεων Χρήσης Δικτύου Διανομής /Προσθήκη νέας Χρέωσης Χρήσης Δικτύου Διανομής'
        }
        leftBtnTitle="Ακύρωση"
        open={openDistributionGasChargesDialog}
        setOpen={() => setOpenDistributionGasChargesDialog(true)}
        onClose={() => setOpenDistributionGasChargesDialog(false)}
        onClickLeftBtn={() => setOpenDistributionGasChargesDialog(false)}
      >
        <RegulatedChargesDialog
          obj={tab4Obj}
          setObj={setTab4Obj}
          activeTab={2}
          action={tabActions.tab4.type}
          networkOptions={managmentGasNetworksData(gasNetworksManagment?.documents)}
        />
      </PopupForm>

      <PopupForm
        maxWidth="md"
        InfoTootipProps={undefined}
        title={'Χρέωση Χρήσης Συστήματος Μεταφοράς'}
        helpText={'Στοίχεια που αφορούν την Χρεώση Χρήσης Συστήματος Μεταφοράς'}
        leftBtnTitle="Ακύρωση"
        open={openTransmissionGasChargesDialog}
        setOpen={() => setOpenTransmissionGasChargesDialog(true)}
        onClose={() => setOpenTransmissionGasChargesDialog(false)}
        onClickLeftBtn={() => setOpenTransmissionGasChargesDialog(false)}
      >
        <TransmissionGasChargesDialog obj={tab5Obj} setObj={setTab5Obj} />
      </PopupForm>
    </Container>
  );
};
