import { Box, Typography, useTheme } from '@mui/material';
import { Calculator, Input } from '@rae/ui-library';
import React from 'react';
import { ReviewTool } from '../actions/ReviewTool';
import { ProductElectricTypes } from '../ProductElectricTypes';
import {
  disableFields,
  ReviewModeEnum,
  showReviewComments,
  viewOnlyReviewComments,
} from '../../../types/ReviewMode';
import { OptionsProps } from '@rae/ui-library/src/components/Calculator';

type Obj = {
  index?: number;
  obj: ProductElectricTypes;
  setObj: (newState: any) => void;
  reviewMode: ReviewModeEnum;
  addComment: (id: string, comment: string) => void;
  noClauseData: OptionsProps[][];
  setNoClauseData: (newState: any) => void;
  compFeesOptions: OptionsProps[];
};

export const FloatingChargeNoClause = ({
  index = 0,
  obj,
  setObj,
  addComment,
  reviewMode,
  noClauseData,
  setNoClauseData,
  compFeesOptions,
}: Obj) => {
  const theme = useTheme();

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box>
          <Input
            sx={{ mt: 3, width: '300px' }}
            disabled={disableFields(reviewMode)}
            type="default"
            inputType={'number'}
            label="Συντελεστής Α"
            value={obj?.competitiveFee?.[index]?.floatingChargeNoClause?.factorA}
            onChange={e =>
              setObj((values: ProductElectricTypes) => {
                const newValues = { ...values };
                if (newValues.competitiveFee?.[index]) {
                  newValues.competitiveFee[index] = {
                    ...newValues.competitiveFee[index],
                    floatingChargeNoClause: {
                      ...(newValues.competitiveFee[index]?.floatingChargeNoClause ?? {}),
                      factorA: e.target.value,
                    },
                  };
                }
                return newValues;
              })
            }
          />
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.competitiveFees?.factorA?.[index]}
              id={`competitiveFees.factorA.${index}`}
              title="Συντελεστής Α"
              addComment={addComment}
            />
          ) : null}
        </Box>

        <Box>
          <Input
            sx={{ mt: 3, width: '300px' }}
            disabled={disableFields(reviewMode)}
            type="default"
            inputType={'number'}
            label="Συντελεστής Β"
            value={obj?.competitiveFee?.[index]?.floatingChargeNoClause?.factorB}
            onChange={e =>
              setObj((values: ProductElectricTypes) => {
                const newValues = { ...values };
                if (newValues.competitiveFee?.[index]) {
                  newValues.competitiveFee[index] = {
                    ...newValues.competitiveFee[index],
                    floatingChargeNoClause: {
                      ...(newValues.competitiveFee[index]?.floatingChargeNoClause ?? {}),
                      factorB: e.target.value,
                    },
                  };
                }
                return newValues;
              })
            }
          />
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.competitiveFees?.factorB?.[index]}
              id={`competitiveFees.factorB.${index}`}
              title="Συντελεστής B"
              addComment={addComment}
            />
          ) : null}
        </Box>
        <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body1" color={theme.palette.primary.main}>
            Τιμή Ρήτρας = A x + B
          </Typography>
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.competitiveFees?.factorX?.[index]}
              id={`competitiveFees.factorX.${index}`}
              title="Τιμή Ρήτρας"
              addComment={addComment}
            />
          ) : null}
        </Box>
        <Box
          sx={{
            mt: 4,
            width: 1000,
            border: '1px solid #012C6D',
            p: 2,
            borderRadius: '16px',
          }}
        >
          <Calculator
            disabled={disableFields(reviewMode)}
            data={noClauseData?.[index]}
            setData={(newData: any) => {
              setNoClauseData((values: any) => {
                const newValues = [...values];
                newValues[index] = newData;
                return newValues;
              });
            }}
            multi={false}
            options={compFeesOptions}
          />
        </Box>
      </Box>
    </>
  );
};
