import {
  NewPasswordProps,
  ShowNewPassErrorProps,
} from '../components/ResetPassPage/NewPassCard';

type NewPassValidationsProps = {
  newPass: NewPasswordProps;
  setShowErrorNewPass: (newState: any) => void;
  setErrorMsgNewPass: (newState: any) => void;
};

export const newPassValidations = ({
  newPass,
  setErrorMsgNewPass,
  setShowErrorNewPass,
}: NewPassValidationsProps) => {
  let errorFlag = false;

  setShowErrorNewPass({});
  setErrorMsgNewPass({});

  if (!newPass.password) {
    setShowErrorNewPass((prev: ShowNewPassErrorProps) => ({
      ...prev,
      password: true,
    }));
    setErrorMsgNewPass((prev: ShowNewPassErrorProps) => ({
      ...prev,
      password: 'Must be filled',
    }));
    errorFlag = true;
  }

  if (!newPass.confirmation_password) {
    setShowErrorNewPass((prev: ShowNewPassErrorProps) => ({
      ...prev,
      confirmation_password: true,
    }));
    setErrorMsgNewPass((prev: ShowNewPassErrorProps) => ({
      ...prev,
      confirmation_password: 'Must be filled',
    }));
    errorFlag = true;
  }

  if (newPass.confirmation_password !== newPass.password) {
    setShowErrorNewPass((prev: ShowNewPassErrorProps) => ({
      ...prev,
      confirmation_password: true,
    }));
    setErrorMsgNewPass((prev: ShowNewPassErrorProps) => ({
      ...prev,
      confirmation_password: "Password doesn't match with password confirmation",
    }));
    errorFlag = true;
  }

  return errorFlag;
};
