import { Container, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { PopupForm, Toggle } from '@rae/ui-library';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Box } from '@mui/system';
import {
  ProfileCard,
  PasswordCard,
  DocumentsCards,
  ProfileData,
  ChangeEmailData,
  ShowProfileErrorProps,
  ProfileErrorMsg,
  PasswordData,
} from '../components/settingPage/SettingCards';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store';
import {
  useChangeEmailMutation,
  useChangePasswordMutation,
  useEditUserDetailsMutation,
  useGetUserQuery,
  useLoginMutation,
} from '../redux/apis/authApi';
import {
  changePassValidations,
  emailValidations,
  profileValidations,
} from '../validations/SeetingsProfileValidations';
import {
  useGetUserManualsQuery,
  useReplaceUserManualMutation,
} from '../redux/apis/documentsApi';
import { pageToSkipLimit } from '../types/ApiPagination';
import { setLog } from '../redux/slices/appSlice';
import { useLocation } from 'react-router-dom';
import { toBase64 } from '../utils/FileData';
import { SudoDialog } from '../components/Sudo/SudoDialog';
import { LoginUserProps } from './LoginPage';
import { tokenIsSudo } from '../utils/TokenDecode';
import { getAccessToken } from '../utils/TokenStorage';
import { isRaeTeam } from '../utils/PickTeam';

const toggleItem = [
  { label: 'Το προφίλ μου', value: 'profile', icon: <PersonOutlineIcon /> },
  {
    label: 'Αλλαγή κωδικού πρόσβασης',
    value: 'change_pass',
    icon: <LockOutlinedIcon />,
  },
  { label: 'Χρήσιμα Έγγραφα', value: 'docs', icon: <DescriptionOutlinedIcon /> },
];

export const Settings = () => {
  const location = useLocation();
  const [showError, setShowError] = useState<ShowProfileErrorProps>({});
  const [errorMsg, setErrorMsg] = useState<ProfileErrorMsg>({});
  const [userSudo, setUserSudo] = useState<LoginUserProps>({});
  const [methodName, setMethodName] = useState<string>('');
  const [active, setActive] = useState<string>('profile');
  const [profile, setProfile] = useState<ProfileData>({
    contactPhone: '',
    firstName: '',
    lastName: '',
  });
  const [pass, setPass] = useState<PasswordData>({
    currentPass: '',
    newPass: '',
    confirmPass: '',
  });
  const [email, setEmail] = useState<ChangeEmailData>({
    email: '',
  });

  const dispatch = useDispatch<AppDispatch>();
  const { team } = useSelector((state: RootState) => state.auth);

  const [updateUserManuals, { isLoading: updateUserManualsLoading }] =
    useReplaceUserManualMutation();
  const [changeUserDetails, { isLoading: userDetailsLoading }] =
    useEditUserDetailsMutation();
  const { data: user, isLoading: userLoading } = useGetUserQuery();
  const [changePass, { isLoading: changePassLoading }] = useChangePasswordMutation();
  const [changeEmail, { isLoading: changeEmailLoading }] = useChangeEmailMutation();
  const [login, { isLoading: loginLoading }] = useLoginMutation();
  const { data: userManuals, isLoading: userManualsLoading } = useGetUserManualsQuery(
    pageToSkipLimit(0, 10, [`-updatedAt`]),
  );

  const detailsCardLoading =
    userDetailsLoading || userLoading || changeEmailLoading || loginLoading;
  const passwordCardLoading = userLoading || changePassLoading || loginLoading;
  const documentsCardsLoading = updateUserManualsLoading || userManualsLoading;

  const handleFileSelect = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>, id?: string) => {
      const file = event.target.files?.[0];
      if (!file || !id) {
        return;
      }
      try {
        const data = await toBase64(file);
        await updateUserManuals({
          _id: id,
          name: file.name,
          mimeType: file.type,
          data: data,
        }).unwrap();
        dispatch(
          setLog({
            severity: 'success',
            message: `Το αρχείο ανέβηκε επιτυχώς`,
          }),
        );
      } catch (e: any) {
        console.log(e);
        dispatch(
          setLog({
            severity: 'error',
            message: `${e.data.message}`,
          }),
        );
      }
    },
    [dispatch, updateUserManuals],
  );

  useEffect(() => {
    if (location.pathname?.includes('/docs')) {
      setActive('docs');
    } else {
      setActive('profile');
    }
  }, [location.pathname]);

  useEffect(() => {
    setProfile({
      contactPhone: user?.contactPhone,
      firstName: user?.firstName,
      lastName: user?.lastName,
    });
    setEmail({ email: user?.email });
  }, [user]);

  const handleResetPass = useCallback(async () => {
    if (changePassValidations({ pass, setErrorMsg, setShowError })) return;
    try {
      if (!tokenIsSudo(getAccessToken())) {
        setMethodName('handleResetPass');
        return;
      }
      await changePass({
        newPassword: pass.newPass ? pass.newPass : '',
        oldPassword: pass.currentPass ? pass.currentPass : '',
      }).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: `Επιτυχής αλλαγή κωδικού`,
        }),
      );
    } catch (e: any) {
      console.log(e);
      dispatch(
        setLog({
          severity: 'error',
          message: `${e.data.message}`,
        }),
      );
    }
    setPass({
      currentPass: '',
      newPass: '',
      confirmPass: '',
    });
  }, [changePass, dispatch, pass]);

  const handleEmailChange = useCallback(async () => {
    if (emailValidations({ email, setErrorMsg, setShowError })) return;
    try {
      if (!tokenIsSudo(getAccessToken())) {
        setMethodName('handleEmailChange');
        return;
      }
      await changeEmail({ newEmail: email.email ? email.email : '' }).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: `Επιτυχής αλλαγή email.`,
        }),
      );
    } catch (e: any) {
      console.log(e);
      dispatch(
        setLog({
          severity: 'error',
          message: `${e.data.message}`,
        }),
      );
    }
  }, [changeEmail, dispatch, email]);

  const handleChangeDetails = useCallback(async () => {
    if (profileValidations({ profile, setErrorMsg, setShowError })) return;
    try {
      await changeUserDetails(profile).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: `Επιτυχής αλλαγή στοιχείων`,
        }),
      );
    } catch (e: any) {
      console.log(e);
      dispatch(
        setLog({
          severity: 'error',
          message: `${e.data.message}`,
        }),
      );
    }
  }, [changeUserDetails, dispatch, profile]);

  const handleSudoLogin = useCallback(async () => {
    try {
      const method = `${methodName}`;
      await login(userSudo).unwrap();
      setMethodName('');
      setUserSudo({});
      switch (method) {
        case 'handleResetPass':
          await handleResetPass();
          break;
        case 'handleEmailChange':
          await handleEmailChange();
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e);
    }
  }, [handleEmailChange, handleResetPass, login, methodName, userSudo]);

  const renderSection = (active: string) => {
    switch (active) {
      case 'profile':
        return (
          <ProfileCard
            profile={profile}
            setProfile={setProfile}
            email={email}
            setEmail={setEmail}
            errorMsg={errorMsg}
            showError={showError}
            isLoading={detailsCardLoading}
            handleEmailChange={handleEmailChange}
            handleChangeDetails={handleChangeDetails}
          />
        );
      case 'change_pass':
        return (
          <PasswordCard
            pass={pass}
            setPass={setPass}
            errorMsg={errorMsg}
            showError={showError}
            handleResetPass={handleResetPass}
            isLoading={passwordCardLoading}
          />
        );
      case 'docs':
        return (
          <DocumentsCards
            userManuals={userManuals?.documents}
            isLoading={documentsCardsLoading}
            handleFileSelect={handleFileSelect}
            isRae={isRaeTeam(team)}
          />
        );
      default:
        <></>;
    }
  };

  return (
    <>
      <Container maxWidth={'xl'} sx={{ display: 'flex' }}>
        <Grid
          container
          sx={{
            p: 1,
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexDirection: { sm: 'column', md: 'row' },
            pt: { xs: 5, sm: 20 },
          }}
        >
          <Grid
            item
            md={4}
            xs={12}
            sx={{
              height: { sm: 'auto', md: 600 },
              display: 'flex',
              flexDirection: { xs: 'column' },
              alignItems: { xs: 'center' },
              marginTop: { xs: 2, md: 0 },
              marginBottom: { xs: 2, md: 0 },
              textAlign: 'start',
            }}
          >
            <Box>
              <Typography
                variant="h4"
                fontWeight={700}
                sx={{ mb: 2, textAlign: { xs: 'center', md: 'start' } }}
              >
                Ρυθμίσεις
              </Typography>
              <Toggle active={active} setActive={setActive} toggleItem={toggleItem} />
            </Box>
          </Grid>
          <Grid
            item
            md={7}
            xs={12}
            sx={{
              height: { sm: 'auto', md: 600 },
              display: 'flex',
              flexDirection: { xs: 'column' },
              marginTop: { xs: 2, md: 0 },
            }}
          >
            {renderSection(active)}
          </Grid>
        </Grid>
      </Container>
      <PopupForm
        maxWidth="sm"
        InfoTootipProps={undefined}
        title="Σύνδεση"
        helpText="Πρέπει να συνδεθείτε ξανά για να ολοκληρώσετε το αίτημά σας."
        leftBtnTitle="Ακύρωση"
        rightBtnTitle="Σύνδεση"
        open={methodName !== ''}
        setOpen={() => {}}
        onClose={() => {
          setMethodName('');
          setUserSudo({});
        }}
        onClickLeftBtn={() => {
          setMethodName('');
          setUserSudo({});
        }}
        onClickRightBtn={handleSudoLogin}
        rightBtnProps={{ disabled: !userSudo.email || !userSudo.password }}
      >
        <SudoDialog user={userSudo} setUser={setUserSudo} />
      </PopupForm>
    </>
  );
};
