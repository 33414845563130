import { OptionsProps } from '@rae/ui-library/src/components/Calculator';
import { TableDiscountProps } from '@rae/ui-library/src/components/table/TableRowDiscounts';
import { TableProviderProps } from '@rae/ui-library/src/components/table/TableRowProvider';
import { TableProviderIdProps } from '@rae/ui-library/src/components/table/TableRowProviderId';
import {
  ChargeRows,
  chargeRowsAsNumber,
  chargeRowsAsString,
} from '../../types/ChargeRows';
import { ClauseCoefficientMethodology } from '../../types/ClauseCoefficient';
import { FactorXExpression, optionsPropsToFactorXExpression } from '../../types/FactorX';
import { Phase } from '../../types/Phase';
import {
  ProductElectricCompetitiveFeesFieldsRequest,
  ProductElectricFields,
  ProductElectricOtherChargesFields,
} from '../../types/ProductElectric';
import { TieredBilling } from '../../types/TieredBilling';
import { ReviewStatus } from '../../types/ReviewStatus';
import { ReviewComments } from '../../types/ReviewComments';
import { CompetitiveFeeIdsElectricEnum } from '../../types/CompetitiveFees';
import { toNumber } from 'lodash';

export type ProductElectricTypes = {
  reviewStatus?: ReviewStatus;
  reviewComments?: ReviewComments;
  info?: {
    provider?: TableProviderProps;
    productName?: string;
    productNameEn?: string;
    productLink?: string;
    productLinkEn?: string;
    providerId?: TableProviderIdProps;
    receivesDisc?: string[];
    phase?: string;
    info?: string;
    infoEn?: string;
    duration?: string;
    from?: Date | null;
    productImg?: File | undefined;
    productImgId?: string;
    productImgUrl?: string;
    checkBox?: string[];
    terms?: string[];
    termsEn?: string[];
  };
  competitiveFeeType?: string;
  competitiveChargeScale?: { from: string; to: string }[];
  competitiveChargeScaleConsumption?: 'day' | 'night' | 'both' | 'highest';
  competitiveFee?: ProductElectricTypesCompetitiveFees[];
  otherCharges?: ProductElectricTypesOtherCharges[];
  discounts?: DiscountsTypeDiscounts[];
  benefits?: DiscountsTypeBenefits[];
  clauses?: ProductElectricTypesClauses[];
};

export type ProductElectricTypesCompetitiveFees = {
  type?: string;
  transparencyType?: string;
  stableSection?: {
    day?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    night?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
  };
  floatingChargeNoLimit?: {
    day?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    night?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    clauseMethodology?: string;
    factorA?: string;
    factorB?: string;
    underProtection?: string;
    topProtection?: string;
    clausePrice?: string;
  };
  floatingChargeWithLimit?: {
    day?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    night?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    clauseMethodology?: string;
    factorA?: string;
    factorB?: string;
    underProtection?: string;
    topProtection?: string;
    maxReduction?: string;
    maxRaise?: string;
    clausePrice?: string;
  };
  floatingChargeNoClause?: {
    factorA?: string;
    factorB?: string;
  };
  monthlyVariable?: {
    dayA?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    dayB?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    nightA?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    nightB?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
  };
};

export type ProductElectricTypesOtherCharges = {
  pagio?: string[];
  otherCharges?: string[];
  daySinglePhase?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  dayMultiPhase?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  nightSinglePhase?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  nightMultiPhase?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  other?: {
    otherName?: string;
    otherDescr?: string;
    otherAmount?: string;
    otherChargeStep?: string;
    otherApply?: string;
  }[];
};

export type ProductElectricTypesClauses = {
  implClause?: string;
  kindClause?: string;
  durationClause?: string;
  descrClause?: string;
  months?: { id: number; value: number | undefined }[];
};

export type ProductInfoTabProps = {
  providerName?: string;
  productName?: string;
  productLink?: string;
  count?: string[];
  category?: string;
  receivesDisc?: string[];
  phase?: string;
  info?: string;
  duration?: string;
  from?: Date | null;
  productImg?: File | undefined;
  checkBox?: string[];
  terms?: string;
};

export type StableSectionProps = {
  day?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  night?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
};

export type FloatingChargeNoLimitSectionProps = {
  day?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  night?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  clauseMethodology?: string;
  factorA?: string;
  factorB?: string;
  underProtection?: string;
  topProtection?: string;
  clausePrice?: string;
};

export type FloatingChargeWithLimitSectionProps = {
  day?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  night?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  clauseMethodology?: string;
  factorA?: string;
  factorB?: string;
  underProtection?: string;
  topProtection?: string;
  maxReduction?: string;
  maxRaise?: string;
  clausePrice?: string;
};

export type FloatingChargeNoClauseProps = {
  floatingSupplyCharge?: string;
  period?: string;
  factorA?: string;
  factorB?: string;
};

export type MonthlyVariableProps = {
  dayA?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  dayB?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  nightA?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  nightB?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
};

export type CompetitiveFeesTabProps = {
  type: string;
};

export type OtherChargesTabProps = {
  pagio?: string[];
  otherCharges?: string[];
  daySinglePhase?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  dayMultiPhase?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  nightSinglePhase?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  nightMultiPhase?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  other?: {
    otherName?: string;
    otherDescr?: string;
    otherAmount?: string;
    otherChargeStep?: string;
    otherApply?: string;
  }[];
};

export type DiscountData = {
  percentage?: string;
  value?: string;
  description?: string;
  customers?: string;
  indefinite?: boolean;
  startDate?: Date | null | undefined;
  endDate?: Date | null | undefined;
};

export type AvailibleDiscounts = Record<string, TableDiscountProps>;

export type DiscountsTypeDiscounts = Record<string, AvailibleDiscounts>;

export type DiscountTabDialog = {
  type?: string;
  discountName?: string;
  discountLink?: string;
  radio?: string;
  supplyAmount?: string;
  discDescr?: string;
  client?: string;
};

export type BenefitData = {
  percentage?: string;
  value?: string;
  description?: string;
  link?: string;
  indefinite?: boolean;
  startDate?: Date | null | undefined;
  endDate?: Date | null | undefined;
};

export type AvailableBenefits = Record<string, TableDiscountProps>;

export type DiscountsTypeBenefits = Record<string, AvailableBenefits>;

export type BenefitTabDialog = {
  type?: string;
  benefitName?: string;
  benefitLink?: string;
  radio?: string;
  ratioBenefit?: string;
  descrBenefit?: string;
  client?: string;
  indefinite?: boolean;
  startDate?: Date | null | undefined;
  endDate?: Date | null | undefined;
};

export type ClauseMonths = Record<string, string>;

export type ClauseTabProps = {
  implClause?: string;
  kindClause?: string;
  durationClause?: string;
  descrClause?: string;
  months?: { id: number; value: number | undefined }[];
};

export const durationCast = (duration?: string): number | undefined => {
  if (!duration) return undefined;
  try {
    return parseInt(duration);
  } catch (error) {
    return undefined;
  }
};

export const durationCastRevert = (duration?: number): string => {
  if (!duration) return 'undefined';
  return `${duration}`;
};

export const phaseCast = (phase?: string): Phase[] => {
  if (!phase) return ['single'];
  switch (phase) {
    case 'Μονοφασική':
      return ['single'];
    case 'Τριφασική':
      return ['three'];
    default:
      return ['single', 'three'];
  }
};

export const phaseCastRevert = (phase?: Phase[]): string => {
  if (!phase || phase.length === 0) return '';
  if (phase.length === 1) {
    switch (phase[0]) {
      case 'single':
        return 'Μονοφασική';
      case 'three':
        return 'Τριφασική';
      default:
        return '';
    }
  } else {
    return 'Και τα δύο';
  }
};

export const compFeesData = (
  obj?: ProductElectricFields,
): ProductElectricTypes['competitiveFee'] =>
  obj?.competitiveFees?.map(fee => {
    if (!fee?.competitiveFees?._id) {
      return {};
    }
    switch (fee?.competitiveFees?._id) {
      case CompetitiveFeeIdsElectricEnum.Stable: //stable
      case CompetitiveFeeIdsElectricEnum.Established:
        return {
          type: fee?.competitiveFees?._id,
          transparencyType: fee?.competitiveFees?.transparencyType,
          stableSection: {
            day: {
              chargeRows: fee?.dayChargeScale
                ? chargeRowsAsString(fee?.dayChargeScale)
                : undefined,
              tieredBilling: fee?.dayTieredBilling,
            },
            night: {
              chargeRows: fee?.nightChargeScale
                ? chargeRowsAsString(fee?.nightChargeScale)
                : undefined,
              tieredBilling: fee?.nightTieredBilling,
            },
          },
        };
      case CompetitiveFeeIdsElectricEnum.NoLimitExPost: //no-limit
      case CompetitiveFeeIdsElectricEnum.NoLimitExAnte: //no-limit
        return {
          type: fee?.competitiveFees?._id,
          transparencyType: fee?.competitiveFees?.transparencyType,
          floatingChargeNoLimit: {
            day: {
              chargeRows: fee?.dayChargeScale
                ? chargeRowsAsString(fee?.dayChargeScale)
                : undefined,
              tieredBilling: fee?.dayTieredBilling,
            },
            night: {
              chargeRows: fee?.nightChargeScale
                ? chargeRowsAsString(fee?.nightChargeScale)
                : undefined,
              tieredBilling: fee?.nightTieredBilling,
            },
            clauseMethodology: fee?.clauseMethodology,
            //clausePrice:obj.competitiveFees.factorX
            factorA: fee?.factorA?.toString(),
            factorB: fee?.factorB?.toString(),
            topProtection: fee?.protectionUpper?.toString(),
            underProtection: fee?.protectionLower?.toString(),
          },
        };
      case CompetitiveFeeIdsElectricEnum.WithLimitExPost: //with-limit
      case CompetitiveFeeIdsElectricEnum.WithLimitExAnte: //with-limit
        return {
          type: fee?.competitiveFees?._id,
          transparencyType: fee?.competitiveFees?.transparencyType,
          floatingChargeWithLimit: {
            day: {
              chargeRows: fee?.dayChargeScale
                ? chargeRowsAsString(fee?.dayChargeScale)
                : undefined,
              tieredBilling: fee?.dayTieredBilling,
            },
            night: {
              chargeRows: fee?.nightChargeScale
                ? chargeRowsAsString(fee?.nightChargeScale)
                : undefined,
              tieredBilling: fee?.nightTieredBilling,
            },
            clauseMethodology: fee?.clauseMethodology,
            //clausePrice:obj.competitiveFees.factorX
            factorA: fee?.factorA?.toString(),
            factorB: fee?.factorB?.toString(),
            maxRaise: fee?.maxRaise?.toString(),
            maxReduction: fee?.maxReduction?.toString(),
            topProtection: fee?.protectionUpper?.toString(),
            underProtection: fee?.protectionLower?.toString(),
          },
        };
      case CompetitiveFeeIdsElectricEnum.NoClauseExPost: //no-clause
      case CompetitiveFeeIdsElectricEnum.NoClauseExAnte: //no-clause
        return {
          type: fee?.competitiveFees?._id,
          transparencyType: fee?.competitiveFees?.transparencyType,
          floatingChargeNoClause: {
            factorA: fee?.factorA?.toString(),
            factorB: fee?.factorB?.toString(),
          },
        };
      // case CompetitiveFeeIdsElectricEnum.Monthly: //monthly
      //   return {
      //     type: fee?.competitiveFees?._id,
      //     transparencyType: fee?.competitiveFees?.transparencyType,
      //     monthlyVariable: {
      //       dayA: {
      //         chargeRows: fee?.dayChargeScale
      //           ? chargeRowsAsString(fee?.dayChargeScale)
      //           : undefined,
      //         tieredBilling: fee?.dayTieredBilling,
      //       },
      //       nightA: {
      //         chargeRows: fee?.nightChargeScale
      //           ? chargeRowsAsString(fee?.nightChargeScale)
      //           : undefined,
      //         tieredBilling: fee?.nightTieredBilling,
      //       },
      //       dayB: {
      //         chargeRows: fee?.dayNextChargeScale
      //           ? chargeRowsAsString(fee?.dayNextChargeScale)
      //           : undefined,
      //         tieredBilling: fee?.dayNextTieredBilling,
      //       },
      //       nightB: {
      //         chargeRows: fee?.nightNextChargeScale
      //           ? chargeRowsAsString(fee?.nightNextChargeScale)
      //           : undefined,
      //         tieredBilling: fee?.nightNextTieredBilling,
      //       },
      //     },
      //   };
      default:
        return {};
    }
  });

export const otherChargesData = (
  obj?: ProductElectricFields,
): ProductElectricTypes['otherCharges'] => {
  if (!obj?.otherCharges || obj.otherCharges.length === 0) return [];

  return obj.otherCharges.map(charge => {
    return {
      daySinglePhase: {
        chargeRows: charge.daySinglePhaseChargeScale
          ? chargeRowsAsString(charge.daySinglePhaseChargeScale)
          : undefined,
        tieredBilling: charge.daySinglePhaseTieredBilling,
      },
      dayMultiPhase: {
        chargeRows: charge.dayThreePhaseChargeScale
          ? chargeRowsAsString(charge.dayThreePhaseChargeScale)
          : undefined,
        tieredBilling: charge.dayThreePhaseTieredBilling,
      },
      nightSinglePhase: {
        chargeRows: charge.nightSinglePhaseChargeScale
          ? chargeRowsAsString(charge.nightSinglePhaseChargeScale)
          : undefined,
        tieredBilling: charge.nightSinglePhaseTieredBilling,
      },
      nightMultiPhase: {
        chargeRows: charge.nightThreePhaseChargeScale
          ? chargeRowsAsString(charge.nightThreePhaseChargeScale)
          : undefined,
        tieredBilling: charge.nightThreePhaseTieredBilling,
      },
      other: otherChargesRevert(charge),
      otherCharges: charge.other && charge.other.length > 0 ? ['other'] : [''],
      pagio: charge.otherCharges?._id ? [charge.otherCharges._id] : [],
    };
  });
};

export const exitClauses = (
  obj?: ProductElectricFields,
): ProductElectricTypes['clauses'] => {
  return obj?.exitClause?.map(clause => ({
    descrClause: clause.info,
    months: clause.amount?.map((item, i) => ({ id: i + 1, value: item })),
    implClause:
      clause.info || (clause.amount && clause.amount.length > 0) ? 'Ναι' : 'Όχι',
    kindClause: clause.info || clause.amount ? 'Πρόωρης Αποχώρησης' : undefined,
  }));
};

export const dayChargeScale = (
  obj?: ProductElectricTypesCompetitiveFees,
): ChargeRows[] | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsElectricEnum.Stable: //stable
    case CompetitiveFeeIdsElectricEnum.Established:
      return obj?.stableSection?.day?.chargeRows
        ? chargeRowsAsNumber(obj?.stableSection?.day?.chargeRows)
        : undefined;
    case CompetitiveFeeIdsElectricEnum.NoLimitExPost: //no-limit
    case CompetitiveFeeIdsElectricEnum.NoLimitExAnte: //no-limit
      return obj?.floatingChargeNoLimit?.day?.chargeRows
        ? chargeRowsAsNumber(obj?.floatingChargeNoLimit?.day?.chargeRows)
        : undefined;
    case CompetitiveFeeIdsElectricEnum.WithLimitExPost: //with-limit
    case CompetitiveFeeIdsElectricEnum.WithLimitExAnte: //with-limit
      return obj?.floatingChargeWithLimit?.day?.chargeRows
        ? chargeRowsAsNumber(obj?.floatingChargeWithLimit?.day?.chargeRows)
        : undefined;
    case CompetitiveFeeIdsElectricEnum.NoClauseExPost: //no-clause
    case CompetitiveFeeIdsElectricEnum.NoClauseExAnte: //no-clause
      return undefined;
    // case CompetitiveFeeIdsElectricEnum.Monthly: //monthly
    //   return obj.competitiveFee.monthlyVariable?.dayA?.chargeRows
    //     ? chargeRowsAsNumber(obj.competitiveFee.monthlyVariable.dayA.chargeRows)
    //     : undefined;
    default:
      return undefined;
  }
};

export const dayTieredBillling = (
  obj?: ProductElectricTypesCompetitiveFees,
): TieredBilling | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsElectricEnum.Stable: //stable
    case CompetitiveFeeIdsElectricEnum.Established:
      return obj?.stableSection?.day
        ?.tieredBilling as ProductElectricCompetitiveFeesFieldsRequest['dayTieredBilling'];
    case CompetitiveFeeIdsElectricEnum.NoLimitExPost: //no-limit
    case CompetitiveFeeIdsElectricEnum.NoLimitExAnte: //no-limit
      return obj?.floatingChargeNoLimit?.day
        ?.tieredBilling as ProductElectricCompetitiveFeesFieldsRequest['dayTieredBilling'];
    case CompetitiveFeeIdsElectricEnum.WithLimitExPost: //with-limit
    case CompetitiveFeeIdsElectricEnum.WithLimitExAnte: //with-limit
      return obj?.floatingChargeWithLimit?.day
        ?.tieredBilling as ProductElectricCompetitiveFeesFieldsRequest['dayTieredBilling'];
    case CompetitiveFeeIdsElectricEnum.NoClauseExPost: //no-clause
    case CompetitiveFeeIdsElectricEnum.NoClauseExAnte: //no-clause
      return undefined;
    // case CompetitiveFeeIdsElectricEnum.Monthly: //monthly
    //   return obj?.monthlyVariable?.dayA
    //     ?.tieredBilling as ProductElectricCompetitiveFeesFieldsRequest['dayTieredBilling'];
    default:
      return undefined;
  }
};

export const dayNextChargeScale = (
  obj?: ProductElectricTypesCompetitiveFees,
): ChargeRows[] | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsElectricEnum.Stable: //stable
    case CompetitiveFeeIdsElectricEnum.Established:
      return undefined;
    case CompetitiveFeeIdsElectricEnum.NoLimitExPost: //no-limit
    case CompetitiveFeeIdsElectricEnum.NoLimitExAnte: //no-limit
      return undefined;
    case CompetitiveFeeIdsElectricEnum.WithLimitExPost: //with-limit
    case CompetitiveFeeIdsElectricEnum.WithLimitExAnte: //with-limit
      return undefined;
    case CompetitiveFeeIdsElectricEnum.NoClauseExPost: //no-clause
    case CompetitiveFeeIdsElectricEnum.NoClauseExAnte: //no-clause
      return undefined;
    // case CompetitiveFeeIdsElectricEnum.Monthly: //monthly
    //   return obj?.monthlyVariable?.dayB?.chargeRows
    //     ? chargeRowsAsNumber(obj?.monthlyVariable.dayB.chargeRows)
    //     : undefined;
    default:
      return undefined;
  }
};

export const dayNextTieredBillling = (
  obj?: ProductElectricTypesCompetitiveFees,
): TieredBilling | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsElectricEnum.Stable: //stable
    case CompetitiveFeeIdsElectricEnum.Established:
      return undefined;
    case CompetitiveFeeIdsElectricEnum.NoLimitExPost: //no-limit
    case CompetitiveFeeIdsElectricEnum.NoLimitExAnte: //no-limit
      return undefined;
    case CompetitiveFeeIdsElectricEnum.WithLimitExPost: //with-limit
    case CompetitiveFeeIdsElectricEnum.WithLimitExAnte: //with-limit
      return undefined;
    case CompetitiveFeeIdsElectricEnum.NoClauseExPost: //no-clause
    case CompetitiveFeeIdsElectricEnum.NoClauseExAnte: //no-clause
      return undefined;
    // case CompetitiveFeeIdsElectricEnum.Monthly: //monthly
    //   return obj?.monthlyVariable?.dayB
    //     ?.tieredBilling as ProductElectricCompetitiveFeesFieldsRequest['dayTieredBilling'];
    default:
      return undefined;
  }
};

export const nightChargeScale = (
  obj?: ProductElectricTypesCompetitiveFees,
): ChargeRows[] | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsElectricEnum.Stable: //stable
    case CompetitiveFeeIdsElectricEnum.Established:
      return obj?.stableSection?.night?.chargeRows
        ? chargeRowsAsNumber(obj?.stableSection.night.chargeRows)
        : undefined;
    case CompetitiveFeeIdsElectricEnum.NoLimitExPost: //no-limit
    case CompetitiveFeeIdsElectricEnum.NoLimitExAnte: //no-limit
      return obj?.floatingChargeNoLimit?.night?.chargeRows
        ? chargeRowsAsNumber(obj?.floatingChargeNoLimit.night.chargeRows)
        : undefined;
    case CompetitiveFeeIdsElectricEnum.WithLimitExPost: //with-limit
    case CompetitiveFeeIdsElectricEnum.WithLimitExAnte: //with-limit
      return obj?.floatingChargeWithLimit?.night?.chargeRows
        ? chargeRowsAsNumber(obj?.floatingChargeWithLimit.night.chargeRows)
        : undefined;
    case CompetitiveFeeIdsElectricEnum.NoClauseExPost: //no-clause
    case CompetitiveFeeIdsElectricEnum.NoClauseExAnte: //no-clause
      return undefined;
    // case CompetitiveFeeIdsElectricEnum.Monthly: //monthly
    //   return obj?.monthlyVariable?.nightA?.chargeRows
    //     ? chargeRowsAsNumber(obj?.monthlyVariable.nightA.chargeRows)
    //     : undefined;
    default:
      return undefined;
  }
};

export const nightTieredBillling = (
  obj?: ProductElectricTypesCompetitiveFees,
): TieredBilling | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsElectricEnum.Stable: //stable
    case CompetitiveFeeIdsElectricEnum.Established:
      return obj?.stableSection?.night
        ?.tieredBilling as ProductElectricCompetitiveFeesFieldsRequest['nightTieredBilling'];
    case CompetitiveFeeIdsElectricEnum.NoLimitExPost: //no-limit
    case CompetitiveFeeIdsElectricEnum.NoLimitExAnte: //no-limit
      return obj?.floatingChargeNoLimit?.night
        ?.tieredBilling as ProductElectricCompetitiveFeesFieldsRequest['nightTieredBilling'];
    case CompetitiveFeeIdsElectricEnum.WithLimitExPost: //with-limit
    case CompetitiveFeeIdsElectricEnum.WithLimitExAnte: //with-limit
      return obj?.floatingChargeWithLimit?.night
        ?.tieredBilling as ProductElectricCompetitiveFeesFieldsRequest['nightTieredBilling'];
    case CompetitiveFeeIdsElectricEnum.NoClauseExPost: //no-clause
    case CompetitiveFeeIdsElectricEnum.NoClauseExAnte: //no-clause
      return;
    // case CompetitiveFeeIdsElectricEnum.Monthly: //monthly
    //   return obj?.monthlyVariable?.nightA
    //     ?.tieredBilling as ProductElectricCompetitiveFeesFieldsRequest['nightTieredBilling'];
    default:
      return undefined;
  }
};

export const nightNextChargeScale = (
  obj?: ProductElectricTypesCompetitiveFees,
): ChargeRows[] | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsElectricEnum.Stable: //stable
    case CompetitiveFeeIdsElectricEnum.Established:
      return undefined;
    case CompetitiveFeeIdsElectricEnum.NoLimitExPost: //no-limit
    case CompetitiveFeeIdsElectricEnum.NoLimitExAnte: //no-limit
      return undefined;
    case CompetitiveFeeIdsElectricEnum.WithLimitExPost: //with-limit
    case CompetitiveFeeIdsElectricEnum.WithLimitExAnte: //with-limit
      return undefined;
    case CompetitiveFeeIdsElectricEnum.NoClauseExPost: //no-clause
    case CompetitiveFeeIdsElectricEnum.NoClauseExAnte: //no-clause
      return undefined;
    // case CompetitiveFeeIdsElectricEnum.Monthly: //monthly
    //   return obj?.monthlyVariable?.nightB?.chargeRows
    //     ? chargeRowsAsNumber(obj?.monthlyVariable.nightB.chargeRows)
    //     : undefined;
    default:
      return undefined;
  }
};

export const nightNextTieredBillling = (
  obj?: ProductElectricTypesCompetitiveFees,
): TieredBilling | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsElectricEnum.Stable: //stable
    case CompetitiveFeeIdsElectricEnum.Established:
      return undefined;
    case CompetitiveFeeIdsElectricEnum.NoLimitExPost: //no-limit
    case CompetitiveFeeIdsElectricEnum.NoLimitExAnte: //no-limit
      return undefined;
    case CompetitiveFeeIdsElectricEnum.WithLimitExPost: //with-limit
    case CompetitiveFeeIdsElectricEnum.WithLimitExAnte: //with-limit
      return undefined;
    case CompetitiveFeeIdsElectricEnum.NoClauseExPost: //no-clause
    case CompetitiveFeeIdsElectricEnum.NoClauseExAnte: //no-clause
      return undefined;
    // case CompetitiveFeeIdsElectricEnum.Monthly: //monthly
    //   return obj?.monthlyVariable?.nightB
    //     ?.tieredBilling as ProductElectricCompetitiveFeesFieldsRequest['nightNextTieredBilling'];
    default:
      return undefined;
  }
};

export const clauseMethodology = (
  obj?: ProductElectricTypesCompetitiveFees,
): ClauseCoefficientMethodology | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsElectricEnum.Stable: //stable
    case CompetitiveFeeIdsElectricEnum.Established:
      return undefined;
    case CompetitiveFeeIdsElectricEnum.NoLimitExPost: //no-limit
    case CompetitiveFeeIdsElectricEnum.NoLimitExAnte: //no-limit
      return obj?.floatingChargeNoLimit
        ?.clauseMethodology as ProductElectricCompetitiveFeesFieldsRequest['clauseMethodology'];
    case CompetitiveFeeIdsElectricEnum.WithLimitExPost: //with-limit
    case CompetitiveFeeIdsElectricEnum.WithLimitExAnte: //with-limit
      return obj?.floatingChargeWithLimit
        ?.clauseMethodology as ProductElectricCompetitiveFeesFieldsRequest['clauseMethodology'];
    case CompetitiveFeeIdsElectricEnum.NoClauseExPost: //no-clause
    case CompetitiveFeeIdsElectricEnum.NoClauseExAnte: //no-clause
      return undefined;
    // case CompetitiveFeeIdsElectricEnum.Monthly: //monthly
    //   return undefined;
    default:
      return undefined;
  }
};

export const factorA = (
  obj?: ProductElectricTypesCompetitiveFees,
): number | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsElectricEnum.Stable: //stable
    case CompetitiveFeeIdsElectricEnum.Established:
      return undefined;
    case CompetitiveFeeIdsElectricEnum.NoLimitExPost: //no-limit
    case CompetitiveFeeIdsElectricEnum.NoLimitExAnte: //no-limit
      return obj?.floatingChargeNoLimit?.factorA
        ? toNumber(obj?.floatingChargeNoLimit?.factorA)
        : undefined;
    case CompetitiveFeeIdsElectricEnum.WithLimitExPost: //with-limit
    case CompetitiveFeeIdsElectricEnum.WithLimitExAnte: //with-limit
      return obj?.floatingChargeWithLimit?.factorA
        ? toNumber(obj?.floatingChargeWithLimit?.factorA)
        : undefined;
    case CompetitiveFeeIdsElectricEnum.NoClauseExPost: //no-clause
    case CompetitiveFeeIdsElectricEnum.NoClauseExAnte: //no-clause
      return obj?.floatingChargeNoClause?.factorA
        ? toNumber(obj?.floatingChargeNoClause?.factorA)
        : undefined;
    // case CompetitiveFeeIdsElectricEnum.Monthly: //monthly
    //   return undefined
    default:
      return undefined;
  }
};

export const factorB = (
  obj?: ProductElectricTypesCompetitiveFees,
): number | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsElectricEnum.Stable: //stable
    case CompetitiveFeeIdsElectricEnum.Established:
      return undefined;
    case CompetitiveFeeIdsElectricEnum.NoLimitExPost: //no-limit
    case CompetitiveFeeIdsElectricEnum.NoLimitExAnte: //no-limit
      return obj?.floatingChargeNoLimit?.factorB
        ? toNumber(obj?.floatingChargeNoLimit?.factorB)
        : undefined;
    case CompetitiveFeeIdsElectricEnum.WithLimitExPost: //with-limit
    case CompetitiveFeeIdsElectricEnum.WithLimitExAnte: //with-limit
      return obj?.floatingChargeWithLimit?.factorB
        ? toNumber(obj?.floatingChargeWithLimit?.factorB)
        : undefined;
    case CompetitiveFeeIdsElectricEnum.NoClauseExPost: //no-clause
    case CompetitiveFeeIdsElectricEnum.NoClauseExAnte: //no-clause
      return obj?.floatingChargeNoClause?.factorB
        ? toNumber(obj?.floatingChargeNoClause?.factorB)
        : undefined;
    // case CompetitiveFeeIdsElectricEnum.Monthly: //monthly
    //   return undefined;
    default:
      return undefined;
  }
};

export const factorX = (
  obj?: ProductElectricTypesCompetitiveFees,
  calculatorData?: OptionsProps[],
): FactorXExpression => {
  switch (obj?.type) {
    case CompetitiveFeeIdsElectricEnum.Stable: //stable
    case CompetitiveFeeIdsElectricEnum.Established:
      return optionsPropsToFactorXExpression([]);
    case CompetitiveFeeIdsElectricEnum.NoLimitExPost: //no-limit
    case CompetitiveFeeIdsElectricEnum.NoLimitExAnte: //no-limit
      return calculatorData
        ? optionsPropsToFactorXExpression(calculatorData)
        : optionsPropsToFactorXExpression([]);
    case CompetitiveFeeIdsElectricEnum.WithLimitExPost: //with-limit
    case CompetitiveFeeIdsElectricEnum.WithLimitExAnte: //with-limit
      return calculatorData
        ? optionsPropsToFactorXExpression(calculatorData)
        : optionsPropsToFactorXExpression([]);
    case CompetitiveFeeIdsElectricEnum.NoClauseExPost: //no-clause
    case CompetitiveFeeIdsElectricEnum.NoClauseExAnte: //no-clause
      return calculatorData
        ? optionsPropsToFactorXExpression(calculatorData)
        : optionsPropsToFactorXExpression([]);
    // case CompetitiveFeeIdsElectricEnum.Monthly: //monthly
    //   return optionsPropsToFactorXExpression([]);
    default:
      return optionsPropsToFactorXExpression([]);
  }
};

export const protectionLower = (
  obj?: ProductElectricTypesCompetitiveFees,
): number | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsElectricEnum.Stable: //stable
    case CompetitiveFeeIdsElectricEnum.Established:
      return undefined;
    case CompetitiveFeeIdsElectricEnum.NoLimitExPost: //no-limit
    case CompetitiveFeeIdsElectricEnum.NoLimitExAnte: //no-limit
      return obj?.floatingChargeNoLimit?.underProtection
        ? toNumber(obj?.floatingChargeNoLimit?.underProtection)
        : undefined;
    case CompetitiveFeeIdsElectricEnum.WithLimitExPost: //with-limit
    case CompetitiveFeeIdsElectricEnum.WithLimitExAnte: //with-limit
      return obj?.floatingChargeWithLimit?.underProtection
        ? toNumber(obj?.floatingChargeWithLimit?.underProtection)
        : undefined;
    case CompetitiveFeeIdsElectricEnum.NoClauseExPost: //no-clause
    case CompetitiveFeeIdsElectricEnum.NoClauseExAnte: //no-clause
      return undefined;
    // case CompetitiveFeeIdsElectricEnum.Monthly: //monthly
    //   return undefined;
    default:
      return undefined;
  }
};

export const protectionUpper = (
  obj?: ProductElectricTypesCompetitiveFees,
): number | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsElectricEnum.Stable: //stable
    case CompetitiveFeeIdsElectricEnum.Established:
      return undefined;
    case CompetitiveFeeIdsElectricEnum.NoLimitExPost: //no-limit
    case CompetitiveFeeIdsElectricEnum.NoLimitExAnte: //no-limit
      return obj?.floatingChargeNoLimit?.topProtection
        ? toNumber(obj?.floatingChargeNoLimit?.topProtection)
        : undefined;
    case CompetitiveFeeIdsElectricEnum.WithLimitExPost: //with-limit
    case CompetitiveFeeIdsElectricEnum.WithLimitExAnte: //with-limit
      return obj?.floatingChargeWithLimit?.topProtection
        ? toNumber(obj?.floatingChargeWithLimit?.topProtection)
        : undefined;
    case CompetitiveFeeIdsElectricEnum.NoClauseExPost: //no-clause
    case CompetitiveFeeIdsElectricEnum.NoClauseExAnte: //no-clause
      return undefined;
    // case CompetitiveFeeIdsElectricEnum.Monthly: //monthly
    //   return undefined;
    default:
      return undefined;
  }
};

export const exitClauseAmount = (
  obj?: ProductElectricTypesClauses,
): number[] | undefined => {
  if (obj?.months?.length === 0) return;
  return obj?.months ? obj?.months?.map(month => (month?.value ? month?.value : 0)) : [];
};

export const daySinglePhaseChargeScale = (
  obj?: ProductElectricTypesOtherCharges,
): ChargeRows[] | undefined => {
  if (!obj?.daySinglePhase?.chargeRows) return;

  return obj?.daySinglePhase?.chargeRows
    ? chargeRowsAsNumber(obj?.daySinglePhase.chargeRows)
    : undefined;
};

export const daySinglePhaseTieredBillling = (
  obj?: ProductElectricTypesOtherCharges,
): TieredBilling | undefined => {
  if (!obj?.daySinglePhase?.tieredBilling) return;
  return obj?.daySinglePhase
    ?.tieredBilling as ProductElectricOtherChargesFields['daySinglePhaseTieredBilling'];
};

export const dayThreePhaseChargeScale = (
  obj?: ProductElectricTypesOtherCharges,
): ChargeRows[] | undefined => {
  if (!obj?.dayMultiPhase?.chargeRows) return;

  return obj?.dayMultiPhase?.chargeRows
    ? chargeRowsAsNumber(obj?.dayMultiPhase.chargeRows)
    : undefined;
};

export const dayThreePhaseTieredBillling = (
  obj?: ProductElectricTypesOtherCharges,
): TieredBilling | undefined => {
  if (!obj?.dayMultiPhase?.tieredBilling) return;
  return obj?.dayMultiPhase
    ?.tieredBilling as ProductElectricOtherChargesFields['dayThreePhaseTieredBilling'];
};

export const nightSinglePhaseChargeScale = (
  obj?: ProductElectricTypesOtherCharges,
): ChargeRows[] | undefined => {
  if (!obj?.nightSinglePhase?.chargeRows) return;

  return obj?.nightSinglePhase?.chargeRows
    ? chargeRowsAsNumber(obj?.nightSinglePhase.chargeRows)
    : undefined;
};

export const nightSinglePhaseTieredBillling = (
  obj?: ProductElectricTypesOtherCharges,
): TieredBilling | undefined => {
  if (!obj?.nightSinglePhase?.tieredBilling) return;
  return obj?.nightSinglePhase
    ?.tieredBilling as ProductElectricOtherChargesFields['nightSinglePhaseTieredBilling'];
};

export const nightThreePhaseChargeScale = (
  obj?: ProductElectricTypesOtherCharges,
): ChargeRows[] | undefined => {
  if (!obj?.nightMultiPhase?.chargeRows) return;

  return obj?.nightMultiPhase?.chargeRows
    ? chargeRowsAsNumber(obj?.nightMultiPhase.chargeRows)
    : undefined;
};

export const nightThreePhaseTieredBillling = (
  obj?: ProductElectricTypesOtherCharges,
): TieredBilling | undefined => {
  if (!obj?.nightMultiPhase?.tieredBilling) return;
  return obj?.nightMultiPhase
    ?.tieredBilling as ProductElectricOtherChargesFields['nightThreePhaseTieredBilling'];
};

export const otherCharges = (
  obj?: ProductElectricTypesOtherCharges,
): ProductElectricOtherChargesFields['other'] | undefined => {
  if (!obj?.other) return undefined;
  if (obj?.other?.length === 0) return undefined;

  const result: ProductElectricOtherChargesFields['other'] = [];

  for (const charge of obj.other) {
    if (
      charge.otherAmount &&
      charge.otherName &&
      charge.otherApply &&
      charge.otherChargeStep
    ) {
      result.push({
        amount: charge.otherAmount ? toNumber(charge.otherAmount) : 0,
        chargeName: charge.otherName ? charge.otherName : '',
        phase: charge.otherApply ? phaseCast(charge.otherApply) : ['single'],
        step: charge.otherChargeStep ? toNumber(charge.otherChargeStep) : 0,
        chargeInfo: charge.otherDescr,
      });
    }
  }

  return result.length > 0 ? result : undefined;
};

export const otherChargesRevert = (other?: ProductElectricOtherChargesFields) => {
  if (!other?.other || other.other.length === 0) return undefined;

  return other.other.map(
    charge =>
      ({
        otherAmount: charge.amount?.toString(),
        otherName: charge.chargeName,
        otherApply: phaseCastRevert(charge.phase),
        otherChargeStep: charge.step?.toString(),
        otherDescr: charge.chargeInfo,
      } ?? []),
  );
};

export const disableCompFees = (obj?: ProductElectricTypes): boolean => {
  switch (obj?.competitiveFeeType) {
    case CompetitiveFeeIdsElectricEnum.Stable: //stable
    case CompetitiveFeeIdsElectricEnum.Established:
      return (
        !obj.competitiveFee?.[0]?.stableSection?.day?.tieredBilling &&
        !obj.competitiveFee?.[0]?.stableSection?.night?.tieredBilling
      );
    case CompetitiveFeeIdsElectricEnum.NoLimitExPost: //no-limit
    case CompetitiveFeeIdsElectricEnum.NoLimitExAnte: //no-limit
      return (
        !obj.competitiveFee?.[0]?.floatingChargeNoLimit?.clauseMethodology ||
        !obj.competitiveFee?.[0]?.floatingChargeNoLimit?.clausePrice ||
        !obj.competitiveFee?.[0]?.floatingChargeNoLimit?.factorA ||
        !obj.competitiveFee?.[0]?.floatingChargeNoLimit?.factorB ||
        !obj.competitiveFee?.[0]?.floatingChargeNoLimit?.topProtection ||
        !obj.competitiveFee?.[0]?.floatingChargeNoLimit?.underProtection
      );
    case CompetitiveFeeIdsElectricEnum.WithLimitExPost: //with-limit
    case CompetitiveFeeIdsElectricEnum.WithLimitExAnte: //with-limit
      return (
        !obj.competitiveFee?.[0]?.floatingChargeWithLimit?.clauseMethodology ||
        !obj.competitiveFee?.[0]?.floatingChargeWithLimit?.clausePrice ||
        !obj.competitiveFee?.[0]?.floatingChargeWithLimit?.factorA ||
        !obj.competitiveFee?.[0]?.floatingChargeWithLimit?.factorB ||
        !obj.competitiveFee?.[0]?.floatingChargeWithLimit?.maxRaise ||
        !obj.competitiveFee?.[0]?.floatingChargeWithLimit?.maxReduction ||
        !obj.competitiveFee?.[0]?.floatingChargeWithLimit?.topProtection ||
        !obj.competitiveFee?.[0]?.floatingChargeWithLimit?.underProtection
      );
    case CompetitiveFeeIdsElectricEnum.NoClauseExPost: //no-clause
    case CompetitiveFeeIdsElectricEnum.NoClauseExAnte: //no-clause
      return (
        !obj.competitiveFee?.[0]?.floatingChargeNoClause?.factorA ||
        !obj.competitiveFee?.[0]?.floatingChargeNoClause?.factorB
      );
    // case CompetitiveFeeIdsElectricEnum.Monthly: //monthly
    //   return (
    //     !obj.competitiveFee?.[0]?.monthlyVariable?.dayA?.tieredBilling &&
    //     !obj.competitiveFee?.[0]?.monthlyVariable?.dayB?.tieredBilling &&
    //     !obj.competitiveFee?.[0]?.monthlyVariable?.nightA?.tieredBilling &&
    //     !obj.competitiveFee?.[0]?.monthlyVariable?.nightB?.tieredBilling
    //   );
    case CompetitiveFeeIdsElectricEnum.MixedTime:
    case CompetitiveFeeIdsElectricEnum.MixedConsumption:
      return (
        (obj?.competitiveFeeType === CompetitiveFeeIdsElectricEnum.MixedConsumption &&
          !obj?.competitiveChargeScaleConsumption) ||
        !obj?.competitiveFee ||
        obj.competitiveFee.length === 0 ||
        !obj?.competitiveChargeScale ||
        obj.competitiveChargeScale.length === 0 ||
        obj.competitiveChargeScale.length !== obj.competitiveFee.length ||
        obj.competitiveFee.some(fee => {
          switch (fee.type) {
            case CompetitiveFeeIdsElectricEnum.Stable: //stable
            case CompetitiveFeeIdsElectricEnum.Established:
              return (
                !fee?.stableSection?.day?.tieredBilling &&
                !fee?.stableSection?.night?.tieredBilling
              );
            case CompetitiveFeeIdsElectricEnum.NoLimitExPost: //no-limit
            case CompetitiveFeeIdsElectricEnum.NoLimitExAnte: //no-limit
              return (
                !fee?.floatingChargeNoLimit?.clauseMethodology ||
                !fee?.floatingChargeNoLimit?.clausePrice ||
                !fee?.floatingChargeNoLimit?.factorA ||
                !fee?.floatingChargeNoLimit?.factorB ||
                !fee?.floatingChargeNoLimit?.topProtection ||
                !fee?.floatingChargeNoLimit?.underProtection
              );
            case CompetitiveFeeIdsElectricEnum.WithLimitExPost: //with-limit
            case CompetitiveFeeIdsElectricEnum.WithLimitExAnte: //with-limit
              return (
                !fee?.floatingChargeWithLimit?.clauseMethodology ||
                !fee?.floatingChargeWithLimit?.clausePrice ||
                !fee?.floatingChargeWithLimit?.factorA ||
                !fee?.floatingChargeWithLimit?.factorB ||
                !fee?.floatingChargeWithLimit?.maxRaise ||
                !fee?.floatingChargeWithLimit?.maxReduction ||
                !fee?.floatingChargeWithLimit?.topProtection ||
                !fee?.floatingChargeWithLimit?.underProtection
              );
            case CompetitiveFeeIdsElectricEnum.NoClauseExPost: //no-clause
            case CompetitiveFeeIdsElectricEnum.NoClauseExAnte: //no-clause
              return (
                !fee?.floatingChargeNoClause?.factorA ||
                !fee?.floatingChargeNoClause?.factorB
              );
            default:
              return true;
          }
        }, false)
      );
    default:
      return true;
  }
};

export const disableOtherCharges = (obj?: ProductElectricTypes): boolean => {
  return false;
  // return (
  //   !obj?.otherCharges?.pagio ||
  //   (!obj.otherCharges.dayMultiPhase?.tieredBilling &&
  //     !obj.otherCharges.daySinglePhase?.tieredBilling &&
  //     !obj.otherCharges.nightMultiPhase?.tieredBilling &&
  //     !obj.otherCharges.nightSinglePhase?.tieredBilling)
  // );
};

export const disableDiscounts = (obj?: ProductElectricTypes): boolean => {
  return false;
  // return obj?.discounts
  //   ? Object.entries(obj?.discounts)
  //       .map(([type, availDiscounts]) => Object.keys(availDiscounts)[0])
  //       .filter(val => val !== undefined).length === 0
  //   : true;
};

export const disableBenefits = (obj?: ProductElectricTypes): boolean => {
  return false;
  // return obj?.benefits
  //   ? Object.entries(obj?.benefits)
  //       .map(([type, availDiscounts]) => Object.keys(availDiscounts)[0])
  //       .filter(val => val !== undefined).length === 0
  //   : true;
};

export const disableExitClause = (obj?: ProductElectricTypes): boolean => {
  return (
    !obj?.clauses ||
    obj.clauses.length === 0 ||
    obj.clauses.reduce((acc, clause) => {
      if (!clause.implClause) return true;
      return (
        acc ||
        (clause.implClause === 'Ναι'
          ? obj?.info?.duration && obj?.info?.duration === 'undefined'
            ? !clause?.descrClause
            : clause?.months?.some(month => month.value === undefined) ?? false
          : clause?.implClause !== 'Όχι')
      );
    }, false)
  );
};

export const discountCast = (
  obj?: ProductElectricFields,
): ProductElectricTypes['discounts'] =>
  obj?.discounts && obj.discounts.length > 0
    ? obj?.discounts?.map(disc => {
        if (!disc?.discounts) {
          return {};
        }

        const discounts: DiscountsTypeDiscounts = {};
        disc?.discounts.forEach(discount => {
          const tableDiscount: TableDiscountProps = {
            _id: discount._id,
            type: discount.discBenefType,
            name: discount.name,
            nameEn: discount.nameEn,
            provider: '',
            connected: 0,
            modDate: '',
            modEmail: '',
            editable: true, // if RAE ? false : true
            link: discount.link,
            linkEn: discount.linkEn,
            radioDisc: discount.radioDisc,
            descr: discount.descr,
            descrEn: discount.descrEn,
            clients: discount.clients,
            linkInvoices: [],
            category: '',
            energy: discount.energy,
            tieredBilling: discount.tieredBilling,
            chargeRows: discount?.chargeScale
              ? chargeRowsAsString(discount?.chargeScale)
              : undefined,
            status: 0,
          };

          if (!discounts[discount.discBenefType._id]) {
            discounts[discount.discBenefType._id] = {};
          }

          discounts[discount.discBenefType._id][discount._id] = tableDiscount;
        });

        return discounts;
      })
    : [{}];

export const benefitCast = (
  obj?: ProductElectricFields,
): ProductElectricTypes['benefits'] =>
  obj?.benefits && obj.benefits.length > 0
    ? obj?.benefits?.map(benef => {
        if (!benef?.benefits) {
          return {};
        }

        const benefits: DiscountsTypeDiscounts = {};
        benef.benefits.forEach(benef => {
          const tableBenefits: TableDiscountProps = {
            _id: benef._id,
            type: benef.discBenefType,
            name: benef.name,
            nameEn: benef.nameEn,
            provider: '',
            connected: 0,
            modDate: '',
            modEmail: '',
            editable: true, // if RAE ? false : true
            link: benef.link,
            linkEn: benef.linkEn,
            descr: benef.descr,
            descrEn: benef.descrEn,
            clients: benef.clients,
            linkInvoices: [],
            category: '',
            energy: benef.energy,
            status: 0,
          };

          if (!benefits[benef.discBenefType._id]) {
            benefits[benef.discBenefType._id] = {};
          }

          benefits[benef.discBenefType._id][benef._id] = tableBenefits;
        });

        return benefits;
      })
    : [{}];
