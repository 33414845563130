import { Box, IconButton, Typography, useTheme } from '@mui/material';
import {
  ButtonRae,
  CustomTable,
  CustomTabs,
  DropdownNew,
  Input,
  RadioButtonNew,
} from '@rae/ui-library';
import { HeadCell, OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import {
  LinkInvoices,
  TableDiscountProps,
} from '@rae/ui-library/src/components/table/TableRowDiscounts';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DiscountBenefitsSection } from './DiscountBenefitsSection';
import { DiscBenefTypeProps } from '@rae/ui-library/src/components/table/TableRowDiscBenefType';
import { Choice } from '@rae/ui-library/src/components/DropdownNew';

type ObjProps = {
  obj: TableDiscountProps;
  setObj: (newState: any) => void;
  closeDrawer: (newState: boolean) => void;
  cancel: () => void;
  submit: () => void;
  typeBenef: 'new' | 'edit' | 'viewLinkInv' | 'view';
  optionsType: DiscBenefTypeProps[];
  view: boolean;
};

export const EditBenefDialog = ({
  obj,
  setObj,
  typeBenef,
  cancel,
  closeDrawer,
  submit,
  optionsType,
  view,
}: ObjProps) => {
  const [openKindBenef, setOpenKindBenef] = useState<boolean>(false);
  const [order, setOrder] = useState<OrderProps>('asc');
  const [orderBy, setOrderBy] = useState<HeadCell['id']>('test');
  const [page, setPage] = useState<number>(0);

  const typeOptions = useMemo((): Choice[] => {
    return (
      optionsType
        ?.filter(type => type.category === 'benefit') // Only include items with category "discount"
        .map(type => ({
          value: type._id ? type._id : '',
          label: type.typeName ? type.typeName : '',
        }))
        .filter(Boolean) ?? []
    );
  }, [optionsType]);

  const typeOptionsEn = useMemo((): Choice[] => {
    return (
      optionsType
        ?.filter(type => type.category === 'benefit') // Only include items with category "discount"
        .map(type => ({
          value: type._id ? type._id : '',
          label: type.typeNameEn ? type.typeNameEn : '',
        }))
        .filter(Boolean) ?? []
    );
  }, [optionsType]);

  const theme = useTheme();
  let initialState = useRef(structuredClone(obj));

  const handleReset = useCallback(() => {
    setObj((values: TableDiscountProps) => ({
      ...values,
      chargeRows: initialState.current.chargeRows,
      tieredBilling: initialState.current.tieredBilling,
    }));
  }, [setObj]);

  const infoTabGr = useMemo(() => {
    return (
      <Box>
        <Box>
          <RadioButtonNew
            disableRadio={view}
            label="Που εφαρμόζεται"
            radio={[
              { label: 'Ηλεκτρική Ενέργεια', value: 'electric' },
              { label: 'Φυσικό Αέριο', value: 'gas' },
              { label: 'Η.Ε. (Συνδυαστικό)', value: 'electric_comb' },
              { label: 'Φ.Α. (Συνδυαστικό)', value: 'gas_comb' },
            ]}
            value={obj.energy}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setObj((values: TableDiscountProps) => ({
                ...values,
                energy: (event.target as HTMLInputElement).value,
              }))
            }
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            mt: 3,
          }}
        >
          <DropdownNew
            sx={{ width: '300px', mt: 2 }}
            disabled={view}
            multiline={false}
            label="Είδος Πρόσθετης Παροχής"
            labelBtn="Εφαρμογή"
            choices={typeOptions}
            open={openKindBenef}
            setOpen={setOpenKindBenef}
            selected={obj.type?._id ? [obj.type._id] : []}
            setSelected={(type: any) =>
              setObj((values: TableDiscountProps) => ({
                ...values,
                type: {
                  ...values.type,
                  _id: type[0],
                },
              }))
            }
          />
          <Input
            sx={{ mt: 2, width: '300px' }}
            disabled={view}
            type="default"
            label="Όνομα Πρόσθετης Παροχής"
            placeholder="Εισάγετε όνομα"
            value={obj.name}
            onChange={e =>
              setObj((values: TableDiscountProps) => ({
                ...values,
                name: e.target.value,
              }))
            }
          />
          <Input
            sx={{ mt: 2, width: '300px' }}
            disabled={view}
            type="default"
            label="Link Πρόσθετης Παροχής"
            placeholder="Eισάγετε link"
            value={obj.link}
            onChange={e =>
              setObj((values: TableDiscountProps) => ({
                ...values,
                link: e.target.value,
              }))
            }
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'flex-start',
            mt: 3,
          }}
        >
          <Input
            sx={{ mt: 2, width: '100%' }}
            disabled={view}
            multiline
            minRows={4}
            type="default"
            label="Επεξήγηση Πρόσθετης Παροχής"
            placeholder="Εισάγετε επεξήγηση"
            value={obj.descr}
            onChange={e =>
              setObj((values: TableDiscountProps) => ({
                ...values,
                descr: e.target.value,
              }))
            }
          />
        </Box>
      </Box>
    );
  }, [
    obj.energy,
    obj.descr,
    obj.link,
    obj.name,
    obj?.type?._id,
    openKindBenef,
    setObj,
    typeOptions,
    view,
  ]);

  const infoTabEn = useMemo(() => {
    return (
      <Box>
        <Box>
          <RadioButtonNew
            label="Applies in"
            disableRadio={view}
            radio={[
              { label: 'Electricity', value: 'electric' },
              { label: 'Gas', value: 'gas' },
              { label: 'Electricity (Combined)', value: 'electric_comb' },
              { label: 'Gas (Combined)', value: 'gas_comb' },
            ]}
            value={obj.energy}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setObj((values: TableDiscountProps) => ({
                ...values,
                energy: (event.target as HTMLInputElement).value,
              }))
            }
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            mt: 3,
          }}
        >
          <DropdownNew
            sx={{ width: '300px', mt: 2 }}
            disabled={view}
            multiline={false}
            label="Additional Offer Type"
            labelBtn="Εφαρμογή"
            choices={typeOptionsEn}
            open={openKindBenef}
            setOpen={setOpenKindBenef}
            selected={obj.type?._id ? [obj.type._id] : []}
            setSelected={(type: any) =>
              setObj((values: TableDiscountProps) => ({
                ...values,
                type: {
                  ...values.type,
                  _id: type[0],
                },
              }))
            }
          />
          <Input
            sx={{ mt: 2, width: '300px' }}
            disabled={view}
            type="default"
            label="Additional Offer Name"
            placeholder="Enter name"
            value={obj.nameEn}
            onChange={e =>
              setObj((values: TableDiscountProps) => ({
                ...values,
                nameEn: e.target.value,
              }))
            }
          />
          <Input
            sx={{ mt: 2, width: '300px' }}
            disabled={view}
            type="default"
            label="Additional Offer Link"
            placeholder="Enter link"
            value={obj.linkEn}
            onChange={e =>
              setObj((values: TableDiscountProps) => ({
                ...values,
                linkEn: e.target.value,
              }))
            }
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'flex-start',
            mt: 3,
          }}
        >
          <Input
            sx={{ mt: 2, width: '100%' }}
            disabled={view}
            multiline
            minRows={4}
            type="default"
            label="Additional Offer Explanation"
            placeholder="Enter an explanation"
            value={obj.descrEn}
            onChange={e =>
              setObj((values: TableDiscountProps) => ({
                ...values,
                descrEn: e.target.value,
              }))
            }
          />
        </Box>
      </Box>
    );
  }, [
    obj.energy,
    obj.descrEn,
    obj.linkEn,
    obj.nameEn,
    obj?.type?._id,
    openKindBenef,
    setObj,
    typeOptionsEn,
    view,
  ]);

  const infoTab = useMemo(() => {
    const tabsInner = [
      { tabName: 'GR', tabContent: infoTabGr },
      { tabName: 'EN', tabContent: infoTabEn },
    ];
    return <CustomTabs tabs={tabsInner} newTab={0} />;
  }, [infoTabEn, infoTabGr]);

  const benefitTab = useMemo(() => {
    return (
      <DiscountBenefitsSection
        data={obj}
        handleReset={handleReset}
        setData={setObj}
        type="benefit"
        view={view}
      />
    );
  }, [handleReset, obj, setObj, view]);

  const LinkInvoices = useMemo(() => {
    return (
      <Box>
        <CustomTable
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          page={page}
          rowsPerPage={5}
          setPage={setPage}
          count={obj.linkInvoices ? obj.linkInvoices?.length : 0}
          headCells={[
            { disable: false, id: 'invoice_name', label: 'Όνομα Τιμολογίου' },
            { disable: false, id: 'type', label: 'Τύπος' },
            { disable: false, id: 'mod', label: 'Τροποποίηση' },
          ]}
          rows={obj.linkInvoices ? obj.linkInvoices : []}
          handleRedirect={(obj: LinkInvoices) => alert(obj.name)}
          type="link-invoice"
        />
      </Box>
    );
  }, [obj.linkInvoices, order, orderBy, page]);

  const tabs = [
    {
      tabName: 'Πληροφορίες',
      tabContent: infoTab,
      disabled: typeBenef === 'viewLinkInv',
    },
    {
      tabName: 'Πρόσθετη Παροχή',
      tabContent: benefitTab,
      disabled: typeBenef === 'viewLinkInv',
    },
    // { tabName: 'Συνδεδεμένα Τιμολόγια', tabContent: LinkInvoices },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          bgcolor: theme.palette.primary.main,
          p: 2,
        }}
      >
        <IconButton
          onClick={() => closeDrawer(false)}
          sx={{ mr: 5, color: '#ffffff' }}
          component="label"
        >
          <ChevronLeftIcon fontSize="large" />
        </IconButton>
        <Typography color={'#ffffff'} variant="h4">
          Επεξεργασία Πρόσθετης Παροχής
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mt: 3,
          mb: 3,
          width: '100%',
          minHeight: { md: '75vh', xl: '85vh' },
        }}
      >
        <CustomTabs tabs={tabs} newTab={typeBenef === 'viewLinkInv' ? 2 : 0} />{' '}
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          borderTop: '1px solid #B8B8B8',
          pt: 2,
          pb: 2,
        }}
      >
        <ButtonRae
          variant="contained"
          color="secondary"
          sx={{ mr: 2, ml: 2, minWidth: 150 }}
          onClick={cancel}
        >
          {typeBenef !== 'viewLinkInv' ? 'Ακύρωση' : 'Κλείσιμο'}
        </ButtonRae>
        {typeBenef !== 'viewLinkInv' && typeBenef !== 'view' ? (
          <ButtonRae
            variant="contained"
            color="primary"
            sx={{ mr: 2, ml: 2, minWidth: 150 }}
            onClick={submit}
          >
            Αποθήκευση
          </ButtonRae>
        ) : null}
      </Box>
    </Box>
  );
};
