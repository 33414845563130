import { Box, Divider } from '@mui/material';
import { RadioButtonNew } from '@rae/ui-library';
import { TableDiscountProps } from '@rae/ui-library/src/components/table/TableRowDiscounts';
import React, { useMemo } from 'react';
import ChargeScales from '../ChargeScales/ChargeScales';
import { TieredBilling } from '../../types/TieredBilling';

type DiscountBenefitsProps = {
  data?: TableDiscountProps;
  setData: (newState: any) => void;
  handleReset: () => void;
  type?: 'discount' | 'benefit';
  view: boolean;
};

export const DiscountBenefitsSection = ({
  handleReset,
  setData,
  data,
  type,
  view,
}: DiscountBenefitsProps) => {
  const types: {
    value: string;
    label: string;
  }[] = useMemo(() => {
    switch (type) {
      case 'discount':
        return [
          { label: 'Ποσό Έκπτωσης', value: 'disc_amount' },
          { label: 'Ποσοστό έκπτωσης', value: 'disc_rate' },
        ];
      // case 'benefit':
      //   return [
      //     { label: 'Ποσό Πρόσθετης Παροχής', value: 'benef_amount' },
      //     { label: 'Ποσοστό Πρόσθετης Παροχής', value: 'benef_ratio' },
      //     { label: 'Άλλη πρόσθετη Παροχή', value: 'benef_other' },
      //   ];
      default:
        return [];
    }
  }, [type]);

  const amountLabel = useMemo(() => {
    switch (data?.radioDisc) {
      case 'disc_amount':
        return 'Ποσό Έκπτωσης';
      case 'disc_rate':
        return 'Ποσοστό έκπτωσης';
      // case 'benef_amount':
      //   return 'Ποσό Πρόσθετης Παροχής';
      // case 'benef_ratio':
      //   return 'Ποσοστό Πρόσθετης Παροχής';
      // case 'benef_other':
      //   return 'Άλλη πρόσθετη Παροχή';

      default:
        return '';
    }
  }, [data?.radioDisc]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          mt: 3,
          p: 2,
          bgcolor: '#F8F8F8',
          borderRadius: '12px',
        }}
      >
        {type === 'discount' ? (
          <>
            <RadioButtonNew
              disableRadio={view}
              label="Τύπος"
              radio={types ? types : []}
              value={data?.radioDisc}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setData((values: TableDiscountProps) => ({
                  ...values,
                  radioDisc: (event.target as HTMLInputElement).value,
                }))
              }
            />
            <Divider orientation="vertical" variant="middle" flexItem sx={{ mr: 2 }} />
          </>
        ) : null}
        <RadioButtonNew
          disableRadio={view}
          label="Αφορά πελάτες"
          radio={[
            { label: 'Όλους', value: 'all' },
            { label: 'Υφιστάμενους', value: 'exists' },
            { label: 'Νέους', value: 'new' },
          ]}
          value={data?.clients}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setData((values: TableDiscountProps) => ({
              ...values,
              clients: (event.target as HTMLInputElement).value,
            }))
          }
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'flex-start', md: 'space-between' },
          mt: 6,
        }}
      >
        {data && data.radioDisc && data.clients ? (
          type === 'benefit' ? null : (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <ChargeScales
                chargeRows={data?.chargeRows}
                setChargeRows={value =>
                  setData((values: TableDiscountProps) => ({
                    ...values,
                    chargeRows: value,
                  }))
                }
                tieredBilling={data?.tieredBilling as TieredBilling}
                setTieredBilling={value =>
                  setData((values: TableDiscountProps) => ({
                    ...values,
                    tieredBilling: value,
                    chargeRows: [{ amount: '0', from: '', to: '' }],
                  }))
                }
                onReset={handleReset}
                amountLabel={amountLabel}
              />
            </Box>
          )
        ) : null}
      </Box>
    </Box>
  );
};
