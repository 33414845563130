import { Box, IconButton } from '@mui/material';
import { DropdownNew, Input } from '@rae/ui-library';
import React, { useState } from 'react';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

type OthersSectionProps = {
  item: any;
  setItem: (item: any) => void;
  i: number;
  handleDeleteRow: () => void;
  disable?: boolean;
};

export const OthersSection = ({
  item,
  setItem,
  i,
  handleDeleteRow,
  disable,
}: OthersSectionProps) => {
  const [openStep, setOpenStep] = useState<boolean>(false);
  // const [openApply, setOpenApply] = useState<boolean>(false);
  return (
    <Box sx={{ mt: 4 }}>
      <Box>
        <Input
          sx={{
            mt: 2,
            width: '300px',
            mr: { xs: 0, md: 4 },
          }}
          disabled={disable}
          color="primary"
          type="default"
          label="Όνομα Άλλης Χρέωσης"
          placeholder="Εισάγετε όνομα"
          value={item.otherName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setItem({ ...item, otherName: event.target.value })
          }
        />
        <Input
          sx={{
            mt: 2,
            width: '300px',
            mr: { xs: 0, md: 4 },
          }}
          disabled={disable}
          color="primary"
          type="default"
          label="Επεξήγηση Χρεώσης"
          placeholder="Εισάγετε επεξήγηση χρέωσης"
          value={item.otherDescr}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setItem({ ...item, otherDescr: event.target.value })
          }
        />
        {i !== 0 ? (
          <IconButton
            disabled={disable}
            sx={{ mt: 2, color: '#87007B' }}
            onClick={handleDeleteRow}
          >
            <RemoveCircleOutlineIcon fontSize="large" />
          </IconButton>
        ) : null}
      </Box>
      <Box>
        <Input
          sx={{
            mt: 2,
            width: '300px',
            mr: { xs: 0, md: 4 },
          }}
          disabled={disable}
          inputType="number"
          color="primary"
          type="default"
          label="Ποσό"
          placeholder="€"
          value={item.otherAmount}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setItem({ ...item, otherAmount: event.target.value })
          }
        />
        <DropdownNew
          disabled={disable}
          sx={{ width: '300px', mt: 2 }}
          multiline={false}
          label="Βήμα Χρέωσης"
          labelBtn="Εφαρμογή"
          choices={[
            {
              label: '1 μήνα',
              value: '1',
            },
            {
              label: '2 μήνες',
              value: '2',
            },
            {
              label: '3 μήνες',
              value: '3',
            },
            {
              label: '4 μήνες',
              value: '4',
            },
            {
              label: '6 μήνες',
              value: '6',
            },
            {
              label: '1 έτος',
              value: '12',
            },
          ]}
          open={openStep}
          setOpen={setOpenStep}
          selected={item.otherChargeStep ? [item.otherChargeStep] : []}
          setSelected={(type: any) => setItem({ ...item, otherChargeStep: type[0] })}
        />
      </Box>
      {/* <Box>
        <Dropdown
          sx={{ width: '300px', mt: 2 }}
          label="Εφαρμογή σε"
          labelBtn="Εφαρμογή"
          choices={['Μονοφασική', 'Τριφασική', 'Και τα δύο']}
          open={openApply}
          setOpen={setOpenApply}
          selected={item.otherApply ? [item.otherApply] : []}
          setSelected={(type: any) => handleChangeOther(type, i, 'otherApply')}
        />
      </Box> */}
    </Box>
  );
};
