import { TableClauseCoefficientProps } from '@rae/ui-library/src/components/table/TableRowClauseCoefficient';
import moment from 'moment';
import { ApiSchema, Identifier } from './ApiSchema';
import { AuthUser } from './Auth';
import { EnergyType } from './EnergyType';

export enum ClauseCoefficientMethodologyEnum {
  Alternative = 'alternative',
  M409 = '409',
}

export type ClauseCoefficientMethodology = `${ClauseCoefficientMethodologyEnum}`;

export const clauseCoefficientMethodologyLabel = (methodology?: string) => {
  switch (methodology) {
    case 'alternative':
      return { gr: 'Εναλλακτική', en: 'Alternative' };
    case '409':
      return { gr: '409', en: '409' };
    default:
      return { gr: '', en: '' };
  }
};

export const clauseCoefficientMethodologyReverseLabel = (
  methodology: string,
): ClauseCoefficientMethodology | undefined => {
  switch (methodology) {
    case clauseCoefficientMethodologyLabel(ClauseCoefficientMethodologyEnum.Alternative)
      .gr:
    case clauseCoefficientMethodologyLabel(ClauseCoefficientMethodologyEnum.Alternative)
      .en:
      return ClauseCoefficientMethodologyEnum.Alternative;
    case clauseCoefficientMethodologyLabel(ClauseCoefficientMethodologyEnum.M409).gr:
    case clauseCoefficientMethodologyLabel(ClauseCoefficientMethodologyEnum.M409).en:
      return ClauseCoefficientMethodologyEnum.M409;
    default:
      return undefined;
  }
};

export type ClauseCoefficientFields = {
  clauseCoefficientName: string;
  clauseCoefficientNameEn?: string;
  clauseInformation?: string;
  price: number;
  methodology?: ClauseCoefficientMethodology[];
  energyType: EnergyType;
  modUser: AuthUser;
  archived?: boolean;
};

export type ClauseCoefficient = ApiSchema<ClauseCoefficientFields>;

export type ClauseCoefficientsRequest = Omit<ClauseCoefficientFields, 'modUser'>;

export type ClauseCoefficientParams = {
  search?: string;
  energyType?: string;
  methodologyFilter?: string[];
};

export const gasClausesData = (
  clause?: ClauseCoefficient[],
): TableClauseCoefficientProps[] => {
  return (
    clause?.map(c => ({
      _id: c._id,
      clauseCoefficientName: c.clauseCoefficientName,
      clauseCoefficientNameEn: c.clauseCoefficientNameEn,
      clauseInformation: c.clauseInformation,
      energyType: c.energyType,
      methodology: c.methodology,
      price: c.price,
      modDate: moment(c.createdAt).format('DD/MM/YYYY'),
      modEmail: c.modUser?.email,
    })) ?? []
  );
};

export const addClauseFields = (
  obj: TableClauseCoefficientProps,
): ClauseCoefficientsRequest => {
  return {
    clauseCoefficientName: obj?.clauseCoefficientName ? obj?.clauseCoefficientName : '',
    clauseCoefficientNameEn: obj.clauseCoefficientNameEn,
    clauseInformation: obj?.clauseInformation,
    energyType: obj.energyType ? obj.energyType : 'electric',
    methodology: obj.methodology as ClauseCoefficientFields['methodology'],
    price: obj.price ? obj.price : 0,
  };
};

export const editClauseFields = (
  obj: TableClauseCoefficientProps,
): Identifier & Partial<ClauseCoefficientsRequest> => {
  return {
    _id: obj._id ? obj._id : '',
    clauseCoefficientName: obj?.clauseCoefficientName ? obj?.clauseCoefficientName : '',
    clauseCoefficientNameEn: obj.clauseCoefficientNameEn,
    clauseInformation: obj?.clauseInformation,
    methodology: obj.methodology as ClauseCoefficientFields['methodology'],
    price: obj.price ? obj.price : 0,
  };
};
