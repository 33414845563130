import { DrawerCardCharges } from '@rae/ui-library';
import React, { useState } from 'react';
import { Box, Divider, IconButton, Typography, useTheme } from '@mui/material';
import { TableHistChargesGasProps } from '@rae/ui-library/src/components/table/TableRowHistChargesGas';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import image from '../../assets/drawer-charges.png';

export type DrawerGasProps = {
  data?: TableHistChargesGasProps[];
  active?: string;
  closeDrawer: (newState: boolean) => void;
};

export const DrawerGas = ({ data, active, closeDrawer }: DrawerGasProps) => {
  const theme = useTheme();
  const [activeChoice, setActiveChoice] = useState<string>(active ? active : '');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <IconButton
          onClick={() => closeDrawer(false)}
          sx={{ mr: 5 }}
          color="primary"
          component="label"
        >
          <ChevronLeftIcon fontSize="large" />
        </IconButton>
        <Typography color={theme.palette.primary.main} variant="h4">
          Εξέλιξη τιμής
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', mt: 3, mb: 3 }}>
        <Box
          sx={{
            flexGrow: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" sx={{ p: 1, color: '#6E6E6E' }}>
            Όνομα Χρέωσης: {data?.[0]?.type}
          </Typography>
          <Typography variant="caption" sx={{ p: 1, color: '#6E6E6E' }}>
            Πρόγραμμα: {data?.[0]?.program}
          </Typography>
          <Typography variant="caption" sx={{ p: 1, color: '#6E6E6E' }}>
            Κατηγορία: {data?.[0]?.category}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          <img
            src={image}
            alt={''}
            loading="lazy"
            style={{
              width: '-webkit-fill-available',
              height: '-webkit-fill-available',
              objectFit: 'cover',
            }}
          />
        </Box>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      {data?.map((item, i) => {
        return (
          <Box sx={{ mt: 3, mb: 3 }}>
            <DrawerCardCharges
              key={i}
              active={activeChoice}
              setActive={setActiveChoice}
              dataGas={item}
            />
          </Box>
        );
      })}
    </Box>
  );
};
