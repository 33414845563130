export enum EnergyTypeEnum {
  Electric = 'electric',
  Gas = 'gas',
}

export type EnergyType = `${EnergyTypeEnum}`;

export const energyTypeLabel = (methodology: EnergyType) => {
  switch (methodology) {
    case 'electric':
      return { gr: 'Ηλεκτρική Ενέργεια', en: 'Electricity' };
    case 'gas':
      return { gr: 'Φυσικό Αέριο', en: 'Gas' };
    default:
      return { gr: '', en: '' };
  }
};

export const energyTypeReverseLabel = (energyType: string): EnergyType | undefined => {
  switch (energyType) {
    case energyTypeLabel(EnergyTypeEnum.Electric).gr:
    case energyTypeLabel(EnergyTypeEnum.Electric).en:
      return EnergyTypeEnum.Electric;
    case energyTypeLabel(EnergyTypeEnum.Gas).gr:
    case energyTypeLabel(EnergyTypeEnum.Gas).en:
      return EnergyTypeEnum.Gas;
    default:
      return undefined;
  }
};
