import { Box, Divider, Grid, Paper, Typography, useTheme } from '@mui/material';
import { CheckBoxes } from '@rae/ui-library';
import { CheckBoxesProps } from '@rae/ui-library/src/components/CheckBox';
import React, { useMemo } from 'react';
import { ProductElectric } from '../../types/ProductElectric';
import { ProductGas } from '../../types/ProductGas';
import moment from 'moment/moment';

type Obj = {
  productEl: string[];
  productGas: string[];
  productsElectric?: ProductElectric[];
  productsGas?: ProductGas[];
};
export const ProductsComponent = ({
  productEl,
  productGas,
  productsGas,
  productsElectric,
}: Obj) => {
  const theme = useTheme();

  const checkboxesProductElectric: CheckBoxesProps['checkboxes'] = productsElectric
    ?.filter(item => productEl.includes(item._id))
    .map(item => ({
      label: `${item.productInfo.nameGr} (${moment(
        item.productInfo.availabilityDate,
      ).format('DD/MM/YYYY')})`,
      value: item._id,
    })) as CheckBoxesProps['checkboxes'];

  const checkboxesProductGas: CheckBoxesProps['checkboxes'] = productsGas
    ?.filter(item => productGas.includes(item._id))
    .map(item => ({
      label: `${item.productInfo.nameGr} (${moment(
        item.productInfo.availabilityDate,
      ).format('DD/MM/YYYY')})`,
      value: item._id,
    })) as CheckBoxesProps['checkboxes'];

  const elProduct: CheckBoxesProps['checkboxes'] = useMemo(() => {
    return productEl.map((p: string) => {
      return { label: p, value: p };
    });
  }, [productEl]);

  const gasProduct: CheckBoxesProps['checkboxes'] = useMemo(() => {
    return productGas.map((p: string) => {
      return { label: p, value: p };
    });
  }, [productGas]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Paper elevation={2} sx={{ p: 3, minHeight: '50px' }}>
          <Box>
            <Typography
              sx={{ minWidth: '200px' }}
              fontSize={'14px'}
              fontWeight={600}
              color={theme.palette.primary.main}
            >
              Επιλογή Προϊόντος Ηλεκτρικής Ενέργειας
            </Typography>
            <Divider
              sx={{
                mt: 1,
                width: '20%',
                borderBottomWidth: 4,
                borderColor: theme.palette.primary.main,
              }}
              textAlign="left"
            />
          </Box>
          <CheckBoxes
            checked={elProduct.map(p => {
              return p.value;
            })}
            disabled={elProduct.map(p => {
              return p.value;
            })}
            checkboxes={checkboxesProductElectric}
            row={false}
            boxProps={{
              sx: { flexDirection: 'column', alignItems: 'flex-start', mt: 3, mb: 3 },
            }}
          />
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper elevation={2} sx={{ p: 3, minHeight: '50px' }}>
          <Box>
            <Typography
              sx={{ minWidth: '200px' }}
              fontSize={'14px'}
              fontWeight={600}
              color={theme.palette.primary.main}
            >
              Επιλογή Προϊόντος Φυσικού Αερίου
            </Typography>
            <Divider
              sx={{
                mt: 1,
                width: '20%',
                borderBottomWidth: 4,
                borderColor: theme.palette.primary.main,
              }}
              textAlign="left"
            />
          </Box>
          <CheckBoxes
            checked={gasProduct.map(p => {
              return p.value;
            })}
            disabled={gasProduct.map(p => {
              return p.value;
            })}
            checkboxes={checkboxesProductGas}
            row={false}
            boxProps={{
              sx: { flexDirection: 'column', alignItems: 'flex-start', mt: 3, mb: 3 },
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
