import { TableRegulatedChargesGasProps } from '@rae/ui-library/src/components/table/TableRowRegulatedChargesGas';
import moment from 'moment';
import { ApiSchema, Identifier } from './ApiSchema';
import { AuthUser } from './Auth';
import { CategoryOfSupply } from './CategoryOfSupply';
import { ManagmentGasNetworks } from './ManagmentGasNetwork';

export type RegulatedChargesGasFields = {
  _id?: string;
  category: CategoryOfSupply;
  network: ManagmentGasNetworks;
  energyCharge: number;
  capacityCharge: number;
  surchargedEnergyCharge: number;
  additionalEnergyCapacityCharge: number;
  penetrationSurcharge: number;
  remoteNetworksCharge: number;
  transportationEnergyCharge: number;
  transportationCapacityCharge: number;
  modUser?: AuthUser;
};

export type RegulatedChargesGas = ApiSchema<RegulatedChargesGasFields>;

export type RegulatedChargesGasFieldsRequest = Omit<
  RegulatedChargesGasFields,
  'network'
> & {
  network: string;
};

export const regulatedChargesGasData = (
  charge?: RegulatedChargesGas[],
): TableRegulatedChargesGasProps[] => {
  return (
    charge?.map(c => ({
      _id: c._id,
      network: c.network,
      category: c.category,
      additionalEnergyCapacityCharge: c.additionalEnergyCapacityCharge,
      capacityCharge: c.capacityCharge,
      energyCharge: c.energyCharge,
      penetrationSurcharge: c.penetrationSurcharge,
      remoteNetworksCharge: c.remoteNetworksCharge,
      surchargedEnergyCharge: c.surchargedEnergyCharge,
      transportationCapacityCharge: c.transportationCapacityCharge,
      transportationEnergyCharge: c.transportationEnergyCharge,
      modDate: moment(c.createdAt).format('DD/MM/YYYY'),
      modEmail: c.modUser?.email,
    })) ?? []
  );
};

export const addRegulatedChargesGasFields = (
  obj: TableRegulatedChargesGasProps,
): RegulatedChargesGasFieldsRequest => {
  return {
    network: obj.network?._id ? obj.network?._id : '',
    category: obj.category
      ? (obj.category as RegulatedChargesGasFields['category'])
      : 'home',
    energyCharge: obj.energyCharge ? obj.energyCharge : 0,
    capacityCharge: obj.capacityCharge ? obj.capacityCharge : 0,
    penetrationSurcharge: obj.penetrationSurcharge ? obj.penetrationSurcharge : 0,
    remoteNetworksCharge: obj.remoteNetworksCharge ? obj.remoteNetworksCharge : 0,
    surchargedEnergyCharge: obj.surchargedEnergyCharge ? obj.surchargedEnergyCharge : 0,
    transportationEnergyCharge: obj.transportationEnergyCharge
      ? obj.transportationEnergyCharge
      : 0,
    transportationCapacityCharge: obj.transportationCapacityCharge
      ? obj.transportationCapacityCharge
      : 0,
    additionalEnergyCapacityCharge: obj.additionalEnergyCapacityCharge
      ? obj.additionalEnergyCapacityCharge
      : 0,
  };
};

export const editRegulatedChargesGasFields = (
  obj: TableRegulatedChargesGasProps,
): Identifier & RegulatedChargesGasFieldsRequest => {
  return {
    _id: obj._id ? obj._id : '',
    network: obj.network?._id ? obj.network?._id : '',
    category: obj.category
      ? (obj.category as RegulatedChargesGasFields['category'])
      : 'home',
    energyCharge: obj.energyCharge ? obj.energyCharge : 0,
    capacityCharge: obj.capacityCharge ? obj.capacityCharge : 0,
    penetrationSurcharge: obj.penetrationSurcharge ? obj.penetrationSurcharge : 0,
    remoteNetworksCharge: obj.remoteNetworksCharge ? obj.remoteNetworksCharge : 0,
    surchargedEnergyCharge: obj.surchargedEnergyCharge ? obj.surchargedEnergyCharge : 0,
    transportationEnergyCharge: obj.transportationEnergyCharge
      ? obj.transportationEnergyCharge
      : 0,
    transportationCapacityCharge: obj.transportationCapacityCharge
      ? obj.transportationCapacityCharge
      : 0,
    additionalEnergyCapacityCharge: obj.additionalEnergyCapacityCharge
      ? obj.additionalEnergyCapacityCharge
      : 0,
  };
};
