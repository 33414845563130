import { Box } from '@mui/material';
import { CustomTabs, DropdownNew, Input } from '@rae/ui-library';
import { TableOtherChargesProps } from '@rae/ui-library/src/components/table/TableRowOtherCharges';
import React, { useMemo, useState } from 'react';
import { ActionProps } from '../../pages/AssetsMngment';

export type OtherChargesDialogprops = {
  data: TableOtherChargesProps;
  setData: (newState: any) => void;
  action: ActionProps;
};

export const OtherChargesDialog = ({
  data,
  setData,
  action,
}: OtherChargesDialogprops) => {
  const [openType, setOpenType] = useState<boolean>(false);
  const GrTab = useMemo(() => {
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            mt: 3,
          }}
        >
          <Input
            sx={{ mt: 2, width: { sm: '300px', md: '350px' } }}
            type="default"
            label="Όνομα Χρεώσης"
            value={data.chargeName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setData((values: TableOtherChargesProps) => ({
                ...values,
                chargeName: (event.target as HTMLInputElement).value,
              }))
            }
          />
          <DropdownNew
            sx={{ width: { sm: '300px', md: '350px' }, mt: 6 }}
            multiline={false}
            disabled={action === 'edit'}
            label="Τύπος"
            labelBtn="Εφαρμογή"
            choices={[
              {
                label: 'Ηλεκτρική Ενέργεια',
                value: 'electric',
              },
              {
                label: 'Φυσικό Αέριο',
                value: 'gas',
              },
            ]}
            open={openType}
            setOpen={setOpenType}
            selected={data.energyType ? [data.energyType] : []}
            setSelected={(type: any) =>
              setData((values: TableOtherChargesProps) => ({
                ...values,
                energyType: type[0],
              }))
            }
          />
          <Input
            sx={{ mt: 6 }}
            type="default"
            label="Πληροφορίες Χρεώσης"
            value={data.chargeInfo}
            multiline
            minRows={3}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setData((values: TableOtherChargesProps) => ({
                ...values,
                chargeInfo: (event.target as HTMLInputElement).value,
              }))
            }
          />
        </Box>
      </Box>
    );
  }, [action, data.chargeInfo, data.chargeName, data.energyType, openType, setData]);

  const EnTab = useMemo(() => {
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            mt: 3,
          }}
        >
          <Input
            sx={{ mt: 2, width: { sm: '300px', md: '350px' } }}
            type="default"
            label="Other Charge"
            value={data.chargeNameEn}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setData((values: TableOtherChargesProps) => ({
                ...values,
                chargeNameEn: (event.target as HTMLInputElement).value,
              }))
            }
          />
          <DropdownNew
            sx={{ width: { sm: '300px', md: '350px' }, mt: 6 }}
            multiline={false}
            disabled={action === 'edit'}
            label="Energy Source Type"
            labelBtn="Εφαρμογή"
            choices={[
              {
                label: 'Electricity',
                value: 'electric',
              },
              {
                label: 'Natural Gas',
                value: 'gas',
              },
            ]}
            open={openType}
            setOpen={setOpenType}
            selected={data.energyType ? [data.energyType] : []}
            setSelected={(type: any) =>
              setData((values: TableOtherChargesProps) => ({
                ...values,
                energyType: type[0],
              }))
            }
          />
          <Input
            sx={{ mt: 6 }}
            type="default"
            label="Charge's info"
            value={data.chargeInfo}
            multiline
            minRows={3}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setData((values: TableOtherChargesProps) => ({
                ...values,
                chargeInfo: (event.target as HTMLInputElement).value,
              }))
            }
          />
        </Box>
      </Box>
    );
  }, [action, data.chargeInfo, data.chargeNameEn, data.energyType, openType, setData]);

  const tabs = [
    {
      tabName: 'GR',
      tabContent: GrTab,
    },
    {
      tabName: 'EN',
      tabContent: EnTab,
    },
  ];

  return <CustomTabs tabs={tabs} newTab={0} />;
};
