import { StorageFile, storageFileToFile } from './StorageFile';
import { ApiSchema, Identifier } from './ApiSchema';
import moment from 'moment';
import {
  service,
  TableProviderProps,
} from '@rae/ui-library/src/components/table/TableRowProvider';
import { toGreeklish } from 'greek-utils';

export type TeamFields = {
  parentTeam?: string;
  name: string;
  nameGr?: string;
  nameEn?: string;
  phone?: string;
  url?: string;
  suspended?: boolean;
  services?: service[];
  infoGr?: string;
  infoEn?: string;
  image?: StorageFile;
  imageId?: string;
  role?: 'member' | 'owner';
};

export type Team = ApiSchema<TeamFields>;

export type TeamFieldsRequest = Omit<TeamFields, 'image'> & {
  image?: string;
};

export type TeamParams = {
  search?: string;
  isSuspended?: boolean;
};

export const providersData = (teams?: Team[]): TableProviderProps[] => {
  return (
    teams?.map(p => ({
      _id: p._id ? p._id : '',
      providerImg: storageFileToFile(p.image),
      providerImgUrl: p.image?.url,
      providerImgId: p.image?._id,
      providerName: p.nameGr ? p.nameGr : '',
      services: p.services ? p.services : [],
      infoProvider: p.infoGr,
      infoProviderEn: p.infoEn,
      phone: p.phone,
      providerNameEn: p.nameEn,
      suspended: p.suspended,
      url: p.url,
      parentTeam: p.parentTeam,
      name: p.name,
      role: p.role,
      modDate: moment(p.updatedAt).format('DD/MM/YYYY'),
      modEmail: '',
    })) ?? []
  );
};

export const providerData = (team?: Team): TableProviderProps => {
  if (!team) return { providerName: '' };
  return {
    _id: team._id ? team._id : '',
    providerImg: storageFileToFile(team.image),
    providerImgUrl: team.image?.url,
    providerImgId: team.image?._id,
    providerName: team.nameGr ? team.nameGr : '',
    services: team.services ? team.services : [],
    infoProvider: team.infoGr,
    infoProviderEn: team.infoEn,
    phone: team.phone,
    providerNameEn: team.nameEn,
    suspended: team.suspended,
    url: team.url,
    parentTeam: team.parentTeam,
    name: team.name,
    role: team.role,
    modDate: moment(team.updatedAt).format('DD/MM/YYYY'),
    modEmail: '',
  };
};

export const teamData = (
  prov?: TableProviderProps,
): (Identifier & TeamFields) | undefined => {
  if (!prov) return undefined;
  return {
    _id: prov._id ? prov._id : '',
    image: undefined,
    imageId: prov.providerImgId,
    nameGr: prov.providerName,
    services: prov.services ? prov.services : [],
    infoGr: prov.infoProvider,
    infoEn: prov.infoProviderEn,
    phone: prov.phone,
    nameEn: prov.providerNameEn,
    suspended: prov.suspended,
    url: prov.url,
    parentTeam: prov.parentTeam,
    name: prov.name ? prov.name : '',
    role: prov.role,
  };
};

export const addProviderData = (prov?: TableProviderProps): TeamFieldsRequest => {
  return {
    parentTeam: '645b6778640b27b1d09c32bd', //rae _id
    name: prov?.providerName
      ? toGreeklish(prov?.providerName.toLowerCase().replace(/\s+/g, '_'))
      : '',
    nameGr: prov?.providerName ? prov?.providerName : '',
    nameEn: prov?.providerNameEn,
    infoGr: prov?.infoProvider,
    infoEn: prov?.infoProviderEn,
    url: prov?.url,
    phone: prov?.phone,
    services: prov?.services,
    suspended: prov?.suspended,
    image: prov?.providerImgId,
  };
};

export const editProviderData = (
  prov?: TableProviderProps,
): Identifier & TeamFieldsRequest => {
  return {
    _id: prov?._id ? prov?._id : '',
    parentTeam: prov?.parentTeam,
    name: prov?.name ? prov?.name : '',
    nameGr: prov?.providerName ? prov?.providerName : '',
    nameEn: prov?.providerNameEn,
    infoGr: prov?.infoProvider,
    infoEn: prov?.infoProviderEn,
    url: prov?.url,
    phone: prov?.phone,
    services: prov?.services,
    suspended: prov?.suspended,
    image: prov?.providerImgId,
  };
};

export enum ProviderStatusEnum {
  Active = 'active',
  Suspended = 'suspended',
}

export type ProviderStatus = `${ProviderStatusEnum}`;

export const providerStatus = (team: Team): ProviderStatus => {
  return team.suspended ? ProviderStatusEnum.Suspended : ProviderStatusEnum.Active;
};

export const providerStatusLabel = (providerStatus?: ProviderStatus): string => {
  switch (providerStatus) {
    case ProviderStatusEnum.Active:
      return 'Ενεργός';
    case ProviderStatusEnum.Suspended:
      return 'Σε διαθεσιμότητα';
    default:
      return '';
  }
};

export const providerStatusColor = (providerStatus?: ProviderStatus): string => {
  switch (providerStatus) {
    case ProviderStatusEnum.Active:
      return '#1E71CF';
    case ProviderStatusEnum.Suspended:
      return '#6E6E6E';
    default:
      return '';
  }
};
