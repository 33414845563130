import { HeadCell, OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import { TableGasProvidersProps } from '@rae/ui-library/src/components/table/TableRowGasProviders';
import { CustomTable } from '@rae/ui-library';
import { ActionsProps, GasChargesSearchProps } from '../../../pages/RegularChargesGas';
import { GasProvider, gasProviderData } from '../../../types/GasProvider';
import { PaginationResult } from '../../../types/ApiPagination';

type GasProvidersProps = {
  search: GasChargesSearchProps;
  setSearch: (newState: any) => void;
  order: OrderProps;
  setOrder: (newState: any) => void;
  orderBy: HeadCell['id'];
  setOrderBy: (newState: any) => void;
  page: number;
  setPage: (newState: any) => void;
  data?: PaginationResult<GasProvider>;
  handleActionsGasProviders: (
    obj: TableGasProvidersProps,
    action: ActionsProps['tab2']['type'],
  ) => void;
};

export const GasProvidersTable = ({
  search,
  setSearch,
  handleActionsGasProviders,
  order,
  orderBy,
  page,
  setOrder,
  setOrderBy,
  setPage,
  data,
}: GasProvidersProps) => {
  return (
    <CustomTable
      title=""
      buttonProps={{
        variant: 'contained',
        onClick: () => handleActionsGasProviders({}, 'new'),
        children: 'Προσθήκη Διαχειριστή',
      }}
      handleEdit={(obj: TableGasProvidersProps) => handleActionsGasProviders(obj, 'edit')}
      handleDelete={(obj: TableGasProvidersProps) =>
        handleActionsGasProviders(obj, 'delete')
      }
      searchInput={{
        onChange: e =>
          setSearch((values: GasChargesSearchProps) => ({
            ...values,
            gasProviders: {
              searchValue: e.target.value,
            },
          })),
        value: search.gasProviders.searchValue,
        type: 'search',
        label: 'Αναζήτηση ',
      }}
      rows={gasProviderData(data?.documents)}
      type={'gas-providers'}
      count={data?.count ?? 0}
      headCells={[
        { disable: false, id: 'providerName', label: 'Διαχειριστής Φυσικού Αερίου' },
        { disable: false, id: 'providerInfo', label: 'Πληροφορίες Διαχειριστή' },
        { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
        { disable: true, id: 'actions', label: 'Ενέργειες' },
      ]}
      order={order}
      orderBy={orderBy}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
      page={page}
      rowsPerPage={5}
      setPage={setPage}
    />
  );
};
