import api from './api';
import { ApiPagination, PaginationDataResult } from '../../types/ApiPagination';
import { Identifier } from '../../types/ApiSchema';
import {
  AvrgConsumptionElectric,
  AvrgConsumptionElectricFieldsRequest,
} from '../../types/AvrgConsumptionElectric';

const avrgConsumptionElectricApi = api
  .enhanceEndpoints({ addTagTypes: ['AvrgConsumptionElectric'] })
  .injectEndpoints({
    endpoints: builder => ({
      getAvrgConsumptionElectric: builder.query<
        PaginationDataResult<AvrgConsumptionElectric>,
        ApiPagination
      >({
        query: params => ({
          url: `/raeService/join-provider-consumption`,
          method: 'GET',
          params: { ...params, populate: 'providerId' },
        }),
        providesTags: result =>
          result?.data
            ? [
                ...result.data.map(
                  ({ _id }) => ({ type: 'AvrgConsumptionElectric', _id } as const),
                ),
                { type: 'AvrgConsumptionElectric', id: 'LIST' },
              ]
            : [{ type: 'AvrgConsumptionElectric', id: 'LIST' }],
      }),
      editAvrgConsumptionElectric: builder.mutation<
        AvrgConsumptionElectric,
        Identifier & Partial<AvrgConsumptionElectricFieldsRequest>
      >({
        query: ({ _id, ...params }) => ({
          url: `database/AvrgConsumptionElectric/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [
          { type: 'AvrgConsumptionElectric', _id },
        ],
      }),
      addAvrgConsumptionElectric: builder.mutation<
        AvrgConsumptionElectric,
        Partial<AvrgConsumptionElectricFieldsRequest>
      >({
        query: params => ({
          url: `/database/AvrgConsumptionElectric`,
          method: 'POST',
          body: params,
        }),
        invalidatesTags: [{ type: 'AvrgConsumptionElectric', id: 'LIST' }],
      }),
    }),
  });

export const {
  useGetAvrgConsumptionElectricQuery,
  useEditAvrgConsumptionElectricMutation,
  useAddAvrgConsumptionElectricMutation,
} = avrgConsumptionElectricApi;

export default avrgConsumptionElectricApi;
