import { Box, TextField, Typography, useTheme } from '@mui/material';
import { DropZone, Input } from '@rae/ui-library';
import React from 'react';
import { LinkedInvoicesTypes } from './LinkedInvoicesTypes';
import {
  disableFields,
  ReviewModeEnum,
  showReviewComments,
  viewOnlyReviewComments,
} from '../../types/ReviewMode';
import { ReviewTool } from '../ProductElectricPage/actions/ReviewTool';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ProductGasTypes } from '../ProductGasPage/ProductGasTypes';

type Obj = {
  obj: LinkedInvoicesTypes;
  setObj: (newState: any) => void;
  reviewMode: ReviewModeEnum;
  addComment: (id: string, comment: string) => void;
  minDate?: Date;
};

export const GrTab = ({ obj, setObj, addComment, reviewMode, minDate }: Obj) => {
  const theme = useTheme();

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'flex-start', md: 'space-around' },
          mt: 3,
        }}
      >
        <Box>
          <Input
            sx={{ mt: 2, width: '300px' }}
            type="default"
            label="Όνομα Προϊόντος"
            value={obj.productName}
            disabled={
              disableFields(reviewMode) || reviewMode === ReviewModeEnum.Versioning
            }
            onChange={e =>
              setObj((values: LinkedInvoicesTypes) => ({
                ...values,
                productName: e.target.value,
              }))
            }
          />
        </Box>
        <Box>
          <Input
            sx={{ mt: 2, width: '300px' }}
            type="default"
            label="Σύνδεσμος Προϊόντος"
            disabled={disableFields(reviewMode, true)}
            value={obj.productLink}
            onChange={e =>
              setObj((values: LinkedInvoicesTypes) => ({
                ...values,
                productLink: e.target.value,
              }))
            }
          />
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.productInfo?.linkGr}
              id="productInfo.linkGr"
              title="Σύνδεσμος Προϊόντος"
              addComment={addComment}
            />
          ) : null}
        </Box>

        <Box>
          <Input
            sx={{ mt: 2, width: '400px' }}
            disabled={true}
            value={obj.productImg ? obj.productImg?.name : ''}
            type="upload"
            subText="file must be in .png format, up to 2mb"
            label="Λογότυπο Προϊόντος"
          />
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.productInfo?.image}
              id="productInfo.image"
              title="Λογότυπο Προϊόντος"
              addComment={addComment}
            />
          ) : null}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end', pr: '4rem' }}>
        <Box
          sx={{
            mt: 2,
            height: { xs: '200px', md: '400px' },
            width: { xs: '200px', md: '400px' },
          }}
        >
          <DropZone
            disable={disableFields(reviewMode, true)}
            type="img"
            file={obj?.productImg}
            setFile={(file: any) =>
              setObj((values: LinkedInvoicesTypes) => ({
                ...values,
                providerImg: file,
              }))
            }
          />
          <Typography
            sx={{
              mt: 1,
              marginLeft: 1,
              fontSize: 14,
              color: theme.palette.grey[700],
              fontWeight: 400,
              textAlign: 'center',
            }}
            variant="subtitle2"
            gutterBottom
          >
            Σε περίπτωση μη συμπλήρωσης του πεδίου θα εμφανίζεται το λογότυπο του
            προμηθευτή.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'flex-start',
          mt: 4,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box sx={{ width: '400px', mt: 2 }}>
            <Box>
              <DatePicker
                disabled={disableFields(reviewMode)}
                label="Σε ισχύ από"
                inputFormat="DD/MM/YYYY"
                value={obj?.from ? obj?.from : null}
                minDate={minDate}
                maxDate={undefined}
                onChange={newValue =>
                  setObj((values: ProductGasTypes) => ({
                    ...values,
                    from: newValue,
                  }))
                }
                renderInput={params => (
                  <TextField sx={{ width: { xs: '300px', md: '450px' } }} {...params} />
                )}
              />
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.productInfo?.availabilityDate}
                  id="productInfo.availabilityDate"
                  title="Σε ισχύ από"
                  addComment={addComment}
                />
              ) : null}
            </Box>
          </Box>
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export const EnTab = ({ obj, setObj, addComment, reviewMode, minDate }: Obj) => {
  const theme = useTheme();

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'flex-start', md: 'space-around' },
          mt: 3,
        }}
      >
        <Box>
          <Input
            sx={{ mt: 2, width: '300px' }}
            type="default"
            label="Product Commercial Name"
            value={obj.productNameEn}
            disabled={disableFields(reviewMode)}
            onChange={e =>
              setObj((values: LinkedInvoicesTypes) => ({
                ...values,
                productNameEn: e.target.value,
              }))
            }
          />
        </Box>
        <Box>
          <Input
            sx={{ mt: 2, width: '300px' }}
            type="default"
            label="Product Link"
            disabled={disableFields(reviewMode, true)}
            value={obj.productLinkEn}
            onChange={e =>
              setObj((values: LinkedInvoicesTypes) => ({
                ...values,
                productLinkEn: e.target.value,
              }))
            }
          />
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.productInfo?.linkEn}
              id="productInfo.linkEn"
              title="Product link"
              addComment={addComment}
            />
          ) : null}
        </Box>
        <Box>
          <Input
            sx={{ mt: 2, width: '400px' }}
            disabled={true}
            value={obj.productImg ? obj.productImg?.name : ''}
            type="upload"
            subText="file must be in .png format, up to 2mb"
            label="Product Logo"
          />
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.productInfo?.image}
              id="productInfo.image"
              title="Product Logo"
              addComment={addComment}
            />
          ) : null}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end', pr: '4rem' }}>
        <Box
          sx={{
            mt: 2,
            height: { xs: '200px', md: '400px' },
            width: { xs: '200px', md: '400px' },
          }}
        >
          <DropZone
            type="img"
            file={obj?.productImg}
            disable={disableFields(reviewMode, true)}
            setFile={(file: any) =>
              setObj((values: LinkedInvoicesTypes) => ({
                ...values,
                providerImg: file,
              }))
            }
          />
          <Typography
            sx={{
              mt: 1,
              marginLeft: 1,
              fontSize: 14,
              color: theme.palette.grey[700],
              fontWeight: 400,
              textAlign: 'center',
            }}
            variant="subtitle2"
            gutterBottom
          >
            If the field is not filled in, its logo will be displayed supplier.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'flex-start',
          mt: 4,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box sx={{ width: '350px' }}>
            <DatePicker
              label="Activation date"
              inputFormat="DD/MM/YYYY"
              disabled={disableFields(reviewMode)}
              value={obj?.from ? obj?.from : null}
              minDate={minDate}
              maxDate={undefined}
              onChange={newValue =>
                setObj((values: ProductGasTypes) => ({
                  ...values,
                  from: newValue,
                }))
              }
              renderInput={params => (
                <TextField sx={{ width: { xs: '300px', md: '450px' } }} {...params} />
              )}
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.productInfo?.availabilityDate}
                id="productInfo.availabilityDate"
                title="Activation date"
                addComment={addComment}
              />
            ) : null}
          </Box>
        </LocalizationProvider>
      </Box>
    </Box>
  );
};
