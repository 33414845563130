import { Box, Container } from '@mui/material';
import {
  BreadCrumbsCustom,
  CustomChip,
  CustomTable,
  PopupConfirm,
  PopupForm,
  Tooltip,
} from '@rae/ui-library';
import { HeadCell, OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import React, { useCallback, useState } from 'react';
import { TableDocumentsProps } from '@rae/ui-library/src/components/table/TableRowDocuments';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DocumentDialog } from '../components/Documents/DocumentsDialog';
import {
  useAddDocumentMutation,
  useAddDocumentsMutation,
  useDeleteDocumentMutation,
  useDeleteDocumentsMutation,
  useGetDocumentsQuery,
  usePatchDocumentsMutation,
} from '../redux/apis/documentsApi';
import { pageToSkipLimit } from '../types/ApiPagination';
import { documentsData } from '../types/Documents';
import Loader from '../assets/loading.gif';
import { toBase64 } from '../utils/FileData';
import { setLog } from '../redux/slices/appSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store';
import useDebounce from '../utils/useDebounce';
import { useNavigate } from 'react-router-dom';

export const Documents = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState<OrderProps>('asc');
  const [orderBy, setOrderBy] = useState<HeadCell['id']>('updatedAt');
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deleteObj, setDeleteObj] = useState<TableDocumentsProps>();
  const [openFormDialog, setOpenFormDialog] = useState<boolean>(false);
  const [type, setType] = useState<'add' | 'view' | 'newEntry'>('add');
  const [obj, setObj] = useState<TableDocumentsProps>({});

  const debouncedSearch = useDebounce<string>(search, 300);

  const dispatch = useDispatch<AppDispatch>();

  const links = [{ name: 'Διαχείριση', href: '/' }, { name: 'Εγγραφα' }];

  const { data: documents, isLoading: isLoadingGetDocs } = useGetDocumentsQuery({
    ...pageToSkipLimit(page, 5, [`${order === 'asc' ? '-' : ''}${orderBy}`]),
    search: debouncedSearch,
  });

  const [addDocuments, { isLoading: editDocumenstLoading }] = useAddDocumentsMutation();
  const [addDocument, { isLoading: editDocumentLoading }] = useAddDocumentMutation();
  const [deleteDocuments, { isLoading: deleteDocumenstLoading }] =
    useDeleteDocumentsMutation();
  const [deleteDocument, { isLoading: deleteDocumentLoading }] =
    useDeleteDocumentMutation();
  const [patchDocuments, { isLoading: patchDocumentsLoading }] =
    usePatchDocumentsMutation();

  const openDelete = useCallback((obj: TableDocumentsProps) => {
    setDeleteObj(obj);
    setOpenDeleteDialog(true);
  }, []);

  const closeDelete = useCallback(() => {
    setOpenDeleteDialog(false);
  }, []);

  const deleteRow = useCallback(async () => {
    if (!deleteObj?._id || !deleteObj.fileGrId) {
      return;
    }
    try {
      await deleteDocuments({ _id: deleteObj._id }).unwrap();
      await deleteDocument({
        _id: deleteObj.fileGrId,
      }).unwrap();
      if (deleteObj.fileEnId) {
        await deleteDocument({
          _id: deleteObj.fileEnId,
        }).unwrap();
      }
      dispatch(
        setLog({
          severity: 'success',
          message: `Τα αρχεία διαγράφθηκαν επιτυχώς`,
        }),
      );
    } catch (e: any) {
      console.log(e);
      dispatch(
        setLog({
          severity: 'error',
          message: `${e.data.message}`,
        }),
      );
    }
    setDeleteObj({});
    setOpenDeleteDialog(false);
  }, [deleteDocument, deleteDocuments, deleteObj, dispatch]);

  const addFile = useCallback(async () => {
    switch (type) {
      case 'newEntry':
        if (!obj || !obj._id || !obj.fileEn || !obj.fileNameEn) {
          return;
        }
        try {
          const dataDocEn = await toBase64(obj.fileEn);
          const result = await addDocument({
            name: obj.fileEn.name,
            mimeType: obj.fileEn.type,
            data: dataDocEn,
          }).unwrap();
          await patchDocuments({
            _id: obj._id,
            fileNameEn: obj.fileNameEn,
            fileEn: result._id,
          }).unwrap();
          dispatch(
            setLog({
              severity: 'success',
              message: `Το αρχείο ανέβηκε επιτυχώς`,
            }),
          );
        } catch (e: any) {
          console.log(e);
          dispatch(
            setLog({
              severity: 'error',
              message: `${
                e.data.message === 'File already exists'
                  ? 'To αρχείο υπάρχει ήδη'
                  : e.data.message
              }`,
            }),
          );
        }
        break;
      case 'add':
        if (!obj || !obj.fileName || !obj.fileGr) {
          return;
        }
        try {
          const [dataDocGr, dataDocEn] = await Promise.all([
            toBase64(obj.fileGr),
            obj.fileEn ? toBase64(obj.fileEn) : Promise.resolve(undefined),
          ]);
          const [documentGr, documentEn] = await Promise.all([
            addDocument({
              name: obj.fileGr.name,
              mimeType: obj.fileGr.type,
              data: dataDocGr,
            }).unwrap(),
            obj.fileEn && dataDocEn
              ? addDocument({
                  name: obj.fileEn.name,
                  mimeType: obj.fileEn.type,
                  data: dataDocEn,
                }).unwrap()
              : Promise.resolve(undefined),
          ]);
          await addDocuments({
            fileName: obj.fileName,
            fileNameEn: obj?.fileNameEn,
            fileGr: documentGr._id,
            fileEn: documentEn?._id,
          }).unwrap();
          dispatch(
            setLog({
              severity: 'success',
              message: `Τα αρχεία ανέβηκαν επιτυχώς`,
            }),
          );
        } catch (e: any) {
          console.log(e);
          dispatch(
            setLog({
              severity: 'error',
              message: `${
                e.data.message === 'File already exists'
                  ? 'To αρχείο υπάρχει ήδη'
                  : e.data.message
              }`,
            }),
          );
        }
        break;
      default:
        break;
    }
    setObj({});
    setOpenFormDialog(false);
  }, [addDocument, addDocuments, obj, patchDocuments, type, dispatch]);

  const openAddFile = useCallback(() => {
    setType('add');
    setOpenFormDialog(true);
  }, []);

  const openViewFile = useCallback((obj: TableDocumentsProps) => {
    setType('view');
    setObj(obj);
    setOpenFormDialog(true);
  }, []);

  const closeFormDialog = useCallback(() => {
    setObj({});
    setOpenFormDialog(false);
  }, []);

  const newEntry = useCallback((obj: TableDocumentsProps) => {
    setObj(obj);
    setType('newEntry');
    setOpenFormDialog(true);
  }, []);

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const isLoading =
    isLoadingGetDocs ||
    editDocumentLoading ||
    editDocumenstLoading ||
    patchDocumentsLoading ||
    deleteDocumenstLoading ||
    deleteDocumentLoading;

  return (
    <Container maxWidth={'xl'} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mt: 4 }}>
        <BreadCrumbsCustom navigate={navigate} links={links} />
      </Box>
      {search ? (
        <>
          <Box sx={{ mt: 4 }}>
            <Tooltip
              text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
              type="info"
              btnAction={clearSearch}
              btnLabel="Καθαρισμός Φίλτρων"
              dismiss={true}
              icon={<InfoOutlinedIcon fontSize="small" />}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <CustomChip label={search} onDelete={clearSearch} />
          </Box>
        </>
      ) : null}
      <Box sx={{ mt: 8 }}>
        {!documents?.documents && isLoading ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img style={{ width: '140px' }} src={Loader} alt="loading..." />
          </Box>
        ) : (
          <CustomTable
            buttonProps={{
              variant: 'contained',
              onClick: openAddFile,
              children: 'Ανέβασμα Εγγράφου',
            }}
            handleNewEntry={(obj: TableDocumentsProps) => newEntry(obj)}
            title="Έγγραφα"
            handleView={(obj: TableDocumentsProps) => openViewFile(obj)}
            handleDelete={(obj: TableDocumentsProps) => openDelete(obj)}
            headCells={[
              { disable: false, id: 'fileName', label: 'Όνομα Εγγράφου' },
              { disable: true, id: 'fileGr', label: 'GR' },
              { disable: true, id: 'fileEn', label: 'EN' },
              { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
              { disable: true, id: 'actions', label: 'Ενέργειες' },
            ]}
            count={documents?.count ?? 0}
            type="documents"
            rows={documentsData(documents?.documents)}
            searchInput={{
              onChange: e => setSearch(e.target.value),
              value: search,
              type: 'search',
              label: 'Αναζήτηση',
            }}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            page={page}
            rowsPerPage={5}
            setPage={setPage}
          />
        )}
      </Box>

      <PopupForm
        maxWidth="sm"
        InfoTootipProps={undefined}
        title={type === 'add' ? 'Ανέβασμα Αρχείου' : 'Προβολή Αρχείου'}
        helpText="Κάθε έγγραφο που ανεβαίνει από το συγκεκριμένο κομμάτι της εφαρμογής, εμφανίζεται στο Εργαλείο Σύγκρισης για να επεξηγεί ένα συγκεκριμένο κομμάτι της εφαρμογής."
        leftBtnTitle="Ακύρωση"
        rightBtnTitle={'Προσθήκη'}
        open={openFormDialog}
        setOpen={() => setOpenFormDialog(true)}
        onClose={closeFormDialog}
        onClickLeftBtn={closeFormDialog}
        onClickRightBtn={addFile}
        rightBtnProps={{
          disabled: !obj.fileGr || !obj.fileName || type === 'view',
        }}
      >
        <DocumentDialog setData={setObj} data={obj} type={type} />
      </PopupForm>

      <PopupConfirm
        title="Διαγραφή Αρχείου"
        subTile="Είστε σίγουρος ότι θέλετε να προχωρήσετε στη διαγραφή του Αρχείου"
        leftBtnTitle="Οχι"
        rightBtnTitle="Ναι"
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        onClose={closeDelete}
        onClickLeftBtn={closeDelete}
        onClickRightBtn={deleteRow}
      />
    </Container>
  );
};
