import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { ButtonRae, Input } from '@rae/ui-library';
import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { RegisterobjProps } from '../../pages/RegisterPage';
import Loader from '../../assets/loading.gif';

export type ShowRegisterErrorProps = {
  email?: boolean;
  password?: boolean;
  confirm_pass?: boolean;
};

export type RegisterErrorMsg = {
  email?: string;
  password?: string;
  confirm_pass?: string;
};

type Props = {
  registerUser: RegisterobjProps;
  setRegisterUser: (newState: any) => void;
  showError: ShowRegisterErrorProps;
  errorMsg: RegisterErrorMsg;
  handleRegister: (event: React.FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
};

export const RegisterCard = ({
  registerUser,
  setRegisterUser,
  errorMsg,
  showError,
  handleRegister,
  isLoading,
}: Props) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  return (
    <Paper
      sx={{
        width: 492,
        height: 450,
        borderRadius: '16px',
        p: 4,
        position: 'relative',
      }}
      elevation={3}
    >
      {!isLoading ? (
        <form onSubmit={handleRegister}>
          <Box>
            <Typography
              sx={{ minWidth: '200px' }}
              color={theme.palette.primary.main}
              variant="h5"
            >
              Εγγραφή
            </Typography>
            <Divider
              sx={{
                mt: 1,
                width: '100px',
                borderBottomWidth: 4,
                borderColor: theme.palette.primary.main,
              }}
              textAlign="left"
            />
          </Box>
          <Box sx={{ mt: 4 }}>
            <Input
              sx={{ mt: 2, width: '100%' }}
              error={showError.email}
              helperText={showError.email ? errorMsg.email : ''}
              type="default"
              label="Email"
              placeholder="Εισαγετε Email"
              value={registerUser.email}
              onChange={e =>
                setRegisterUser((values: RegisterobjProps) => ({
                  ...values,
                  email: e.target.value,
                }))
              }
            />

            <Input
              sx={{ mt: 4, width: '100%' }}
              value={registerUser.password}
              onChange={e =>
                setRegisterUser((values: RegisterobjProps) => ({
                  ...values,
                  password: e.target.value,
                }))
              }
              type="default"
              error={showError.password}
              helperText={showError.password ? errorMsg.password : ''}
              label="Κωδικός Πρόσβασης"
              placeholder="Εισαγετε Κωδικό Πρόσβασης"
              inputType={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                        event.preventDefault()
                      }
                    >
                      {showPassword ? (
                        <Visibility color="primary" />
                      ) : (
                        <VisibilityOff color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Input
              sx={{ mt: 4, width: '100%' }}
              value={registerUser.confirm_pass}
              onChange={e =>
                setRegisterUser((values: RegisterobjProps) => ({
                  ...values,
                  confirm_pass: e.target.value,
                }))
              }
              type="default"
              error={showError.confirm_pass}
              helperText={showError.confirm_pass ? errorMsg.confirm_pass : ''}
              label="Επιβεβαίωση κωδικού πρόσβασης"
              placeholder="Εισαγετε Κωδικό Πρόσβασης"
              inputType={showPasswordConfirm ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                      onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                        event.preventDefault()
                      }
                    >
                      {showPasswordConfirm ? (
                        <Visibility color="primary" />
                      ) : (
                        <VisibilityOff color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ position: 'absolute', bottom: 20, width: `calc(100% - 64px)` }}>
              <ButtonRae
                color="primary"
                variant="contained"
                sx={{ width: '100%' }}
                type="submit"
              >
                Εγγραφή
              </ButtonRae>
            </Box>
          </Box>
        </form>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img style={{ width: '140px' }} src={Loader} alt="loading..." />
        </Box>
      )}
    </Paper>
  );
};
