import { ProfileData } from '../../components/settingPage/SettingCards';
import { LoginUserProps } from '../../pages/LoginPage';
import { PaginationTeamsResult } from '../../types/ApiPagination';
import {
  AuthRegisterUser,
  AuthResponse,
  AuthUser,
  ForgotPasswordRequest,
  ResetPasswordRequest,
} from '../../types/Auth';
import { Team } from '../../types/Team';
import api from './api';

const authApi = api.enhanceEndpoints({ addTagTypes: ['AuthUser'] }).injectEndpoints({
  endpoints: builder => ({
    getUser: builder.query<AuthUser, void>({
      query: () => 'authentication/user',
      providesTags: ['AuthUser'],
    }),
    getTeam: builder.query<PaginationTeamsResult<Team>, void>({
      query: params => ({
        url: '/authentication/teams',
        method: 'GET',
        params: { populate: ['image'] },
      }),
    }),
    registerUser: builder.mutation<AuthUser, AuthRegisterUser>({
      query: params => ({
        url: '/authentication/local/new',
        method: 'POST',
        body: params,
      }),
    }),
    login: builder.mutation<AuthResponse, LoginUserProps>({
      query: credentials => ({
        url: 'authentication/local',
        method: 'POST',
        body: credentials,
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: 'authentication/logout',
        method: 'POST',
      }),
    }),
    renew: builder.mutation<AuthResponse, void>({
      query: () => ({
        url: 'authentication/renew',
        method: 'POST',
      }),
    }),
    forgotPassword: builder.mutation<void, ForgotPasswordRequest>({
      query: params => ({
        url: 'authentication/forgot-password',
        method: 'POST',
        body: params,
      }),
    }),
    resetPassword: builder.mutation<void, ResetPasswordRequest>({
      query: params => ({
        url: 'authentication/reset-password',
        method: 'POST',
        body: params,
      }),
    }),
    editUserDetails: builder.mutation<void, ProfileData>({
      query: params => ({
        url: `database/function/changeUserDetails`,
        method: 'PATCH',
        body: params,
      }),
      invalidatesTags: ['AuthUser'],
    }),
    changePassword: builder.mutation<void, { oldPassword: string; newPassword: string }>({
      query: params => ({
        url: 'authentication/local/change-password',
        method: 'POST',
        body: params,
      }),
    }),
    changeEmail: builder.mutation<void, { newEmail: string }>({
      query: params => ({
        url: 'authentication/local/change-email',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['AuthUser'],
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRenewMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useEditUserDetailsMutation,
  useChangePasswordMutation,
  useChangeEmailMutation,
  useGetTeamQuery,
  useLazyGetTeamQuery,
  useRegisterUserMutation,
} = authApi;
export default authApi;
