import {
  configureStore,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import api from './apis/api';
import appSlice, { setLog } from './slices/appSlice';
import authSlice from './slices/authSlice';
import { getAccessToken, getRefreshToken } from '../utils/TokenStorage';

const preloadedState = {
  auth: {
    accessToken: getAccessToken(),
    refreshToken: getRefreshToken(),
  },
};

const rtkQueryErrorHandler: Middleware =
  ({ dispatch }: MiddlewareAPI<AppDispatch>) =>
  next =>
  action => {
    if (isRejectedWithValue(action)) {
      const errorData = action.payload?.data;
      dispatch(
        setLog({
          severity: 'error',
          code: errorData?.status,
          message: `Error ${errorData?.name}, message: ${errorData?.message}`,
        }),
      );
    }
    return next(action);
  };

export const store = configureStore({
  reducer: {
    //slices
    [authSlice.name]: authSlice.reducer,
    [appSlice.name]: appSlice.reducer,
    //apis
    [api.reducerPath]: api.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(rtkQueryErrorHandler).concat(api.middleware),
  preloadedState,
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
