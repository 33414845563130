import { Box } from '@mui/material';
import { Input, RadioButtonNew, DropdownNew } from '@rae/ui-library';
import { Choice } from '@rae/ui-library/src/components/DropdownNew';
import { TableProviderProps } from '@rae/ui-library/src/components/table/TableRowProvider';
import { TableUserProps } from '@rae/ui-library/src/components/table/TableRowUser';
import React, { useEffect, useMemo, useState } from 'react';
import { Team } from '../../types/Team';
import { RAE_ID } from '../../types/User';

export type AddUserProps = {
  user: TableUserProps;
  setUser: (newState: any) => void;
  isRae?: boolean;
  providers?: TableProviderProps[];
  type: 'edit' | 'save' | undefined;
  team?: Team;
};

export const AddUserModalContent = ({
  setUser,
  user,
  isRae,
  providers,
  type,
  team,
}: AddUserProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [openType, setOpenType] = useState<boolean>(false);

  const providerOptions = useMemo((): Choice[] => {
    return (
      providers?.map(provider => ({
        value: provider._id ? provider._id : '',
        label: provider.providerName ? provider.providerName : '',
      })) ?? []
    );
  }, [providers]);

  useEffect(() => {
    if (!isRae) {
      setUser((values: TableUserProps) => ({
        ...values,
        userType: 'provider',
        team: {
          ...values.team,
          _id: team?._id,
        },
      }));
    }
  }, [isRae, setUser, team?._id]);
  return (
    <Box component="div" sx={{ p: 2 }}>
      {isRae ? (
        <Box sx={{ mt: 2, mb: 2 }}>
          <DropdownNew
            sx={{ minWidth: { xs: '300px', md: '350px' } }}
            disabled={type === 'edit'}
            multiline={false}
            label="Χρήστης"
            labelBtn="Εφαρμογή"
            choices={[
              {
                label: 'Χρήστης ΡΑΕ',
                value: 'rae',
              },
              {
                label: 'Χρήστης Παρόχου',
                value: 'provider',
              },
            ]}
            open={openType}
            setOpen={setOpenType}
            selected={user.userType ? [user.userType] : []}
            setSelected={val =>
              setUser((values: TableUserProps) => ({
                ...values,
                userType: val[0],
                team: {
                  ...values.team,
                  _id: val[0] === 'rae' ? RAE_ID : undefined,
                  role: val[0] === 'provider' ? undefined : values.team?.role,
                },
                providerName: undefined,
              }))
            }
          />
        </Box>
      ) : null}
      <Box>
        <RadioButtonNew
          label="Προσβαση:"
          radio={[
            { label: 'Member', value: 'member' },
            { label: 'Admin', value: 'owner' },
          ]}
          value={user.team?.role || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setUser((values: TableUserProps) => ({
              ...values,
              team: {
                ...values.team,
                role: event.target.value,
              },
            }))
          }
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'flex-start', md: 'space-between' },
          mb: 6,
        }}
      >
        <Input
          sx={{ mt: 2, width: { xs: '300px', md: '350px' } }}
          disabled={type === 'edit'}
          value={user.email}
          onChange={e =>
            setUser((values: TableUserProps) => ({
              ...values,
              email: e.target.value,
            }))
          }
          type="default"
          label="Email"
        />
        {!isRae || (isRae && user.userType === 'provider') ? (
          <DropdownNew
            sx={{ mt: 2, minWidth: { xs: '300px', md: '350px' } }}
            disabled={type === 'edit' || !isRae}
            multiline={false}
            label="Πάροχος"
            labelBtn="Εφαρμογή"
            choices={providerOptions}
            open={open}
            setOpen={setOpen}
            selected={user.team?._id ? [user.team?._id] : []}
            setSelected={val =>
              setUser((values: TableUserProps) => ({
                ...values,
                team: {
                  ...values.team,
                  _id: val[0],
                },
              }))
            }
          />
        ) : null}
      </Box>
    </Box>
  );
};
