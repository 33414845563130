import { TableDiscountProps } from '@rae/ui-library/src/components/table/TableRowDiscounts';
import moment from 'moment';
import { ApiSchema, Identifier } from './ApiSchema';
import { DiscBenefType, discBenefTypeDataSingle } from './DiscBenefType';
import { Team } from './Team';

export type BenefitFields = {
  team: Team;
  discBenefType: DiscBenefType;
  name: string;
  nameEn: string;
  link?: string;
  linkEn?: string;
  clients: string;
  energy: string;
  descr?: string;
  descrEn?: string;
};

export type Benefit = ApiSchema<BenefitFields>;

export type BenefitsParams = {
  search?: string;
  typeId?: string[];
  energyType?: string; //'electric' | 'gas'
  team?: string;
};

export type BenefitFieldsRequest = Omit<BenefitFields, 'discBenefType' | 'team'> & {
  discBenefType: string;
  team: string;
};

export const benefitData = (isRae: boolean, benef?: Benefit[]): TableDiscountProps[] => {
  return (
    benef?.map(b => ({
      _id: b._id,
      type: discBenefTypeDataSingle(b.discBenefType),
      name: b.name,
      nameEn: b.name,
      link: b.link,
      linkEn: b.linkEn,
      clients: b.clients,
      energy: b.energy,
      descr: b.descr,
      descrEn: b.descrEn,
      category: 'benefit',
      provider: b?.team?.nameGr,
      linkInvoices: [],
      editable: !isRae, //add check if RAE
      connected: 0,
      status: 0,
      modDate: moment(b.updatedAt).format('DD/MM/YYYY'),
      modEmail: '',
    })) ?? []
  );
};

export const addBenefitData = (
  obj: TableDiscountProps,
  team: Team,
): BenefitFieldsRequest => {
  return {
    team: team._id,
    discBenefType: obj.type?._id ? obj.type?._id : '',
    name: obj.name ? obj.name : '',
    nameEn: obj.nameEn ? obj.nameEn : '',
    link: obj.link,
    linkEn: obj.linkEn,
    clients: obj.clients ? obj.clients : '',
    energy: obj.energy ? obj.energy : '',
    descr: obj.descr,
    descrEn: obj.descrEn,
  };
};

export const editBenefitData = (
  obj: TableDiscountProps,
  team: Team,
): Identifier & BenefitFieldsRequest => {
  return {
    _id: obj._id ? obj._id : '',
    team: team._id,
    discBenefType: obj.type?._id ? obj.type?._id : '',
    name: obj.name ? obj.name : '',
    nameEn: obj.nameEn ? obj.nameEn : '',
    link: obj.link,
    linkEn: obj.linkEn,
    clients: obj.clients ? obj.clients : '',
    energy: obj.energy ? obj.energy : '',
    descr: obj.descr,
    descrEn: obj.descrEn,
  };
};
