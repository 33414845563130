import { ApiPagination, PaginationResult } from '../../types/ApiPagination';
import { Identifier } from '../../types/ApiSchema';
import { SocialInvoiceDiscount, SocialInvoiceFields } from '../../types/SocialInvoice';
import api from './api';

const socialInvoiceApi = api
  .enhanceEndpoints({ addTagTypes: ['SocialInvoice'] })
  .injectEndpoints({
    endpoints: builder => ({
      getSocialInvoices: builder.query<
        PaginationResult<SocialInvoiceDiscount>,
        ApiPagination
      >({
        query: params => ({
          url: `database/SocialInvoiceDiscount`,
          method: 'GET',
          params: params,
        }),
        providesTags: result =>
          result?.documents
            ? [
                ...result.documents.map(
                  ({ _id }) => ({ type: 'SocialInvoice', _id } as const),
                ),
                { type: 'SocialInvoice', id: 'LIST' },
              ]
            : [{ type: 'SocialInvoice', id: 'LIST' }],
      }),
      addSocialInvoice: builder.mutation<SocialInvoiceDiscount, SocialInvoiceFields>({
        query: params => ({
          url: `database/SocialInvoiceDiscount`,
          method: 'POST',
          body: params,
        }),
        invalidatesTags: [{ type: 'SocialInvoice', id: 'LIST' }],
      }),
      editSocialInvoice: builder.mutation<
        SocialInvoiceDiscount,
        Identifier & SocialInvoiceFields
      >({
        query: ({ _id, ...params }) => ({
          url: `database/SocialInvoiceDiscount/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'SocialInvoice', _id }],
      }),
      deleteSocialInvoice: builder.mutation<void, Identifier>({
        query: ({ _id }) => ({
          url: `raeService/social-invoice-discount/${_id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'SocialInvoice', _id }],
      }),
    }),
  });

export const {
  useGetSocialInvoicesQuery,
  useAddSocialInvoiceMutation,
  useEditSocialInvoiceMutation,
  useDeleteSocialInvoiceMutation,
} = socialInvoiceApi;

export default socialInvoiceApi;
