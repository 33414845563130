import { HeadCell, OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import React, { useState } from 'react';
import { TableDistributionGasMngmentProps } from '@rae/ui-library/src/components/table/TableRowDistributionGasMngment';
import { CustomTable } from '@rae/ui-library';
import { ActionsProps, GasChargesSearchProps } from '../../../pages/RegularChargesGas';
import { TableGasProvidersProps } from '@rae/ui-library/src/components/table/TableRowGasProviders';
import { PaginationResult } from '../../../types/ApiPagination';
import {
  ManagmentGasNetworks,
  managmentGasNetworksData,
} from '../../../types/ManagmentGasNetwork';

type GasDistributionMngmentProps = {
  search: GasChargesSearchProps;
  setSearch: (newState: any) => void;
  handleActionsGasDistributionMngment: (
    obj: TableDistributionGasMngmentProps,
    action: ActionsProps['tab1']['type'],
  ) => void;
  optionsProvider?: TableGasProvidersProps[];
  order: OrderProps;
  setOrder: (newState: any) => void;
  orderBy: HeadCell['id'];
  setOrderBy: (newState: any) => void;
  page: number;
  setPage: (newState: any) => void;
  data?: PaginationResult<ManagmentGasNetworks>;
};

export const GasDistributionMngmentTable = ({
  search,
  setSearch,
  handleActionsGasDistributionMngment,
  optionsProvider,
  order,
  orderBy,
  page,
  setOrder,
  setOrderBy,
  setPage,
  data,
}: GasDistributionMngmentProps) => {
  const [openProvider, setOpenProvider] = useState<boolean>(false);
  const [openZone, setOpenZone] = useState<boolean>(false);
  const [openCity, setOpenCity] = useState<boolean>(false);

  const dropDowns = [
    {
      choices: Array.from(new Set(optionsProvider?.map(i => i.providerName || ''))),
      open: openProvider,
      selected: search.distributionGas.dfa,
      setOpen: setOpenProvider,
      setSelected: (type: any) =>
        setSearch((values: GasChargesSearchProps) => ({
          ...values,
          distributionGas: {
            ...values.distributionGas,
            dfa: type,
          },
        })),
      label: 'ΔΦΑ',
      multiple: true,
      labelBtn: 'Εφαρμογή',
    },
    {
      choices: ['Βόρεια', 'Νότια'],
      open: openZone,
      selected: search.distributionGas.zone,
      setOpen: setOpenZone,
      setSelected: (type: any) =>
        setSearch((values: GasChargesSearchProps) => ({
          ...values,
          distributionGas: {
            ...values.distributionGas,
            zone: type,
          },
        })),
      label: 'Ζώνη',
      multiple: false,
      labelBtn: 'Εφαρμογή',
    },
    {
      choices: Array.from(
        new Set(
          managmentGasNetworksData(data?.documents).map(i => (i.cityGr ? i.cityGr : '')),
        ),
      ),
      open: openCity,
      selected: search.distributionGas.city,
      setOpen: setOpenCity,
      setSelected: (type: any) =>
        setSearch((values: GasChargesSearchProps) => ({
          ...values,
          distributionGas: {
            ...values.distributionGas,
            city: type,
          },
        })),
      label: 'Πόλη / Περιοχή',
      multiple: false,
      labelBtn: 'Εφαρμογή',
    },
  ];

  return (
    <CustomTable
      title=""
      buttonProps={{
        variant: 'contained',
        onClick: () => handleActionsGasDistributionMngment({}, 'new'),
        children: 'Προσθήκη',
      }}
      handleEdit={(obj: TableDistributionGasMngmentProps) =>
        handleActionsGasDistributionMngment(obj, 'edit')
      }
      handleDelete={(obj: TableDistributionGasMngmentProps) =>
        handleActionsGasDistributionMngment(obj, 'delete')
      }
      searchInput={{
        onChange: e =>
          setSearch((values: GasChargesSearchProps) => ({
            ...values,
            distributionGas: {
              ...values.distributionGas,
              searchValue: e.target.value,
            },
          })),
        value: search.distributionGas.searchValue,
        type: 'search',
        label: 'Αναζήτηση ',
      }}
      rows={managmentGasNetworksData(data?.documents)}
      type={'distribution-gas-mngment'}
      count={data?.count ?? 0}
      headCells={[
        { disable: false, id: 'cityGr', label: 'Περιοχή / Πόλη' },
        {
          disable: false,
          id: 'zone',
          label: 'Ζώνη',
        },
        { disable: true, id: 'gasProvider', label: 'Διαχειριστής Φυσικού Αερίου' },
        { disable: true, id: 'remoteNetworks', label: 'Απομακρυσμένο Δίκτυο' },
        { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
        { disable: true, id: 'actions', label: 'Ενέργειες' },
      ]}
      dropDowns={dropDowns}
      order={order}
      orderBy={orderBy}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
      page={page}
      rowsPerPage={5}
      setPage={setPage}
    />
  );
};
