import { Box, IconButton, TextField, Typography, useTheme } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  CheckBoxes,
  CustomTabs,
  Dropdown,
  DropdownNew,
  DropZone,
  Input,
} from '@rae/ui-library';
import React, { useEffect, useMemo, useState } from 'react';
import { ProductElectricTypes } from './ProductElectricTypes';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { CheckBoxesProps } from '@rae/ui-library/src/components/CheckBox';
import { pageToSkipLimit } from '../../types/ApiPagination';
import { useGetSocialInvoicesQuery } from '../../redux/apis/socialInvoiceApi';
import { socialInvoiceTableData } from '../../types/SocialInvoice';
import { TableProviderIdProps } from '@rae/ui-library/src/components/table/TableRowProviderId';
import { Choice } from '@rae/ui-library/src/components/DropdownNew';
import { ReviewTool } from './actions/ReviewTool';
import {
  disableFields,
  ReviewModeEnum,
  showReviewComments,
  viewOnlyReviewComments,
} from '../../types/ReviewMode';
import {
  CategoryOfSupplyEnum,
  categoryOfSupplyReverseLabel,
} from '../../types/CategoryOfSupply';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

type Props = {
  obj: ProductElectricTypes;
  setObj: (newState: any) => void;
  providerIds?: TableProviderIdProps[];
  reviewMode: ReviewModeEnum;
  addComment: (id: string, comment: string) => void;
  minDate?: Date;
};

export const ProductInfoTab = ({
  obj,
  setObj,
  providerIds,
  reviewMode,
  addComment,
  minDate,
}: Props) => {
  const theme = useTheme();
  const [openCountGr, setOpenCountGr] = useState<boolean>(false);
  const [openPhaseGr, setOpenPhaseGr] = useState<boolean>(false);
  const [openDuration, setOpenDuration] = useState<boolean>(false);

  const { data: socialinvoices } = useGetSocialInvoicesQuery(
    pageToSkipLimit(0, 50, [`-updatedAt`]),
  );

  const providersIdOptions = useMemo((): Choice[] => {
    return (
      providerIds?.map(prov => ({
        value: prov._id ? prov._id : '',
        label: `${prov.measurement} - ${prov.categoryOfSupply}`,
        labelEn: `${prov.measurementEn} - ${prov.categoryOfSupplyEn}`,
      })) ?? []
    );
  }, [providerIds]);

  const providersIdOptionsEn = useMemo((): Choice[] => {
    return (
      providerIds?.map(prov => ({
        value: prov._id ? prov._id : '',
        label: `${prov.measurementEn} - ${prov.categoryOfSupplyEn}`,
      })) ?? []
    );
  }, [providerIds]);

  const disableSocialInvoice = useMemo(
    () =>
      obj.info?.providerId?._id &&
      categoryOfSupplyReverseLabel(
        providerIds?.find(providerId => providerId._id === obj.info?.providerId?._id)
          ?.categoryOfSupply ?? '',
      ) !== CategoryOfSupplyEnum.Home,
    [obj.info?.providerId?._id, providerIds],
  );

  useEffect(() => {
    if (disableSocialInvoice) {
      setObj((values: ProductElectricTypes) => ({
        ...values,
        info: {
          ...values.info,
          receivesDisc: undefined,
        },
      }));
    }
  }, [setObj, disableSocialInvoice]);

  const GrTab = useMemo(() => {
    const checkboxesSoxialInv: CheckBoxesProps['checkboxes'] = socialInvoiceTableData(
      socialinvoices?.documents,
    ).map(item => ({
      label: item.socialInvoiceName,
      value: item._id,
    })) as CheckBoxesProps['checkboxes'];

    const terms = obj?.info?.terms && obj.info.terms.length > 0 ? obj.info.terms : [''];

    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            mt: 3,
          }}
        >
          <Input
            sx={{ mt: 2, minWidth: '300px' }}
            type="lock"
            disabled
            label="Όνομα Παρόχου"
            value={obj?.info?.provider?.providerName}
            onChange={() => {}}
          />
          <Box>
            <Input
              sx={{ mt: 2, minWidth: '300px' }}
              type="default"
              disabled={
                disableFields(reviewMode) || reviewMode === ReviewModeEnum.Versioning
              }
              label="Όνομα Προϊόντος"
              placeholder="Εισαγετε το όνομα του προϊόντος"
              value={obj?.info?.productName}
              onChange={e =>
                setObj((values: ProductElectricTypes) => ({
                  ...values,
                  info: {
                    ...values.info,
                    productName: e.target.value,
                  },
                }))
              }
            />
          </Box>
          <Box>
            <Input
              sx={{ mt: 2, minWidth: '300px' }}
              type="default"
              disabled={disableFields(reviewMode, true)}
              label="Σύνδεσμος Προϊόντος"
              placeholder="Προσθέστε σύνδεσμο"
              value={obj?.info?.productLink}
              onChange={e =>
                setObj((values: ProductElectricTypes) => ({
                  ...values,
                  info: {
                    ...values.info,
                    productLink: e.target.value,
                  },
                }))
              }
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.productInfo?.linkGr}
                id="productInfo.linkGr"
                title="Σύνδεσμος Προϊόντος"
                addComment={addComment}
              />
            ) : null}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            mt: 6,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: { xs: '0rem', md: '4rem' },
            }}
          >
            <Box>
              <DropdownNew
                sx={{ width: { xs: '300px', md: '450px' }, mt: 2 }}
                multiline={false}
                disabled={disableFields(reviewMode)}
                label="Ταυτότητα Παροχής"
                labelBtn="Εφαρμογή"
                choices={providersIdOptions}
                open={openCountGr}
                setOpen={setOpenCountGr}
                selected={obj?.info?.providerId?._id ? [obj?.info?.providerId?._id] : []}
                setSelected={(type: any) =>
                  setObj((values: ProductElectricTypes) => ({
                    ...values,
                    info: {
                      ...values.info,
                      providerId: {
                        ...values.info?.providerId,
                        _id: type[0],
                      },
                    },
                  }))
                }
              />
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.productInfo?.providerId}
                  id="productInfo.providerId"
                  title="Ταυτότητα Παροχής"
                  addComment={addComment}
                />
              ) : null}
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              <Typography variant="subtitle2" sx={{ color: theme.palette.primary.main }}>
                Λαμβάνει έκπτωση απο το Κοινωνικό Οικιακό Τιμολόγιο;
              </Typography>
              <Box>
                <CheckBoxes
                  sx={{ pt: 2 }}
                  disabled={
                    disableFields(reviewMode) || disableSocialInvoice
                      ? checkboxesSoxialInv.map(item => item.value)
                      : []
                  }
                  checked={obj?.info?.receivesDisc ? obj?.info?.receivesDisc : []}
                  setChecked={event =>
                    setObj((values: ProductElectricTypes) => ({
                      ...values,
                      info: {
                        ...values.info,
                        receivesDisc: event,
                      },
                    }))
                  }
                  checkboxes={checkboxesSoxialInv}
                />
                {showReviewComments(reviewMode) ? (
                  <ReviewTool
                    view={viewOnlyReviewComments(reviewMode)}
                    msg={obj.reviewComments?.productInfo?.socialInvoiceDiscounts}
                    id="productInfo.socialInvoiceDiscounts"
                    title="Κοινωνικό Οικιακό Τιμολόγιο"
                    addComment={addComment}
                  />
                ) : null}
              </Box>
            </Box>
            <Box>
              <Dropdown
                sx={{ width: { xs: '300px', md: '450px' } }}
                disabled={disableFields(reviewMode)}
                label="Φάσεις Παροχής"
                labelBtn="Εφαρμογή"
                choices={['Μονοφασική', 'Τριφασική', 'Και τα δύο']}
                open={openPhaseGr}
                setOpen={setOpenPhaseGr}
                selected={obj?.info?.phase ? [obj?.info?.phase] : []}
                setSelected={(type: any) =>
                  setObj((values: ProductElectricTypes) => ({
                    ...values,
                    info: {
                      ...values.info,
                      phase: type[0],
                    },
                  }))
                }
              />
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.productInfo?.phase}
                  id="productInfo.phase"
                  title="Φάσεις Παροχής"
                  addComment={addComment}
                />
              ) : null}
            </Box>
            <Box>
              <DropdownNew
                sx={{ width: { xs: '300px', md: '450px' }, mt: 2 }}
                disabled={disableFields(reviewMode)}
                label="Διάρκεια Συμβολαίου"
                labelBtn="Εφαρμογή"
                choices={[
                  {
                    label: '1 μήνας',
                    value: '1',
                  },
                  {
                    label: '3 μήνες',
                    value: '3',
                  },
                  {
                    label: '6 μήνες',
                    value: '6',
                  },
                  {
                    label: '12 μήνες',
                    value: '12',
                  },
                  {
                    label: '18 μήνες',
                    value: '18',
                  },
                  {
                    label: '24 μήνες',
                    value: '24',
                  },
                  {
                    label: 'Αορίστου',
                    value: 'undefined',
                  },
                ]}
                open={openDuration}
                setOpen={setOpenDuration}
                selected={obj?.info?.duration ? [obj?.info?.duration] : []}
                setSelected={(type: any) =>
                  setObj((values: ProductElectricTypes) => ({
                    ...values,
                    info: {
                      ...values.info,
                      duration: type[0],
                    },
                  }))
                }
              />
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.productInfo?.duration}
                  id="productInfo.duration"
                  title="Διάρκεια Συμβολαίου"
                  addComment={addComment}
                />
              ) : null}
            </Box>
          </Box>
          <Box>
            <Box>
              <Input
                sx={{ mt: 3, width: { xs: '300px', md: '400px' } }}
                disabled={true}
                value={obj?.info?.productImg ? obj?.info?.productImg?.name : ''}
                type="upload"
                subText="file must be in .png format, up to 2mb"
                label="Λογότυπο Προϊόντος"
              />
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.productInfo?.image}
                  id="productInfo.image"
                  title="Λογότυπο Προϊόντος"
                  addComment={addComment}
                />
              ) : null}
            </Box>
            <Box
              sx={{
                mt: 2,
                height: { xs: '200px', md: '400px' },
                width: { xs: '200px', md: '400px' },
              }}
            >
              <DropZone
                imgURL={obj.info?.productImgUrl}
                type="img"
                disable={disableFields(reviewMode, true)}
                file={obj?.info?.productImg}
                setFile={(file: any) =>
                  setObj((values: ProductElectricTypes) => ({
                    ...values,
                    info: {
                      ...values.info,
                      productImg: file,
                    },
                  }))
                }
              />
              <Typography
                sx={{
                  mt: 1,
                  marginLeft: 1,
                  fontSize: 14,
                  color: theme.palette.grey[700],
                  fontWeight: 400,
                  textAlign: 'center',
                }}
                variant="subtitle2"
                gutterBottom
              >
                Σε περίπτωση μη συμπλήρωσης του πεδίου θα εμφανίζεται το λογότυπο του
                προμηθευτή.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'flex-start',
            mt: 4,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box sx={{ width: '400px', mt: 2 }}>
              <Box>
                <DatePicker
                  disabled={disableFields(reviewMode)}
                  label="Σε ισχύ από"
                  inputFormat="DD/MM/YYYY"
                  value={obj?.info?.from ? obj?.info?.from : null}
                  minDate={minDate}
                  maxDate={undefined}
                  onChange={newValue =>
                    setObj((values: ProductElectricTypes) => ({
                      ...values,
                      info: {
                        ...values.info,
                        from: newValue,
                      },
                    }))
                  }
                  renderInput={params => (
                    <TextField sx={{ width: { xs: '300px', md: '450px' } }} {...params} />
                  )}
                />
                {showReviewComments(reviewMode) ? (
                  <ReviewTool
                    view={viewOnlyReviewComments(reviewMode)}
                    msg={obj.reviewComments?.productInfo?.availabilityDate}
                    id="productInfo.availabilityDate"
                    title="Σε ισχύ από"
                    addComment={addComment}
                  />
                ) : null}
              </Box>
            </Box>
          </LocalizationProvider>
        </Box>
        <Box
          sx={{
            mt: 4,
          }}
        >
          {terms?.map((item, i) => (
            <Box key={i}>
              <Input
                sx={{ mt: 1, minWidth: { xs: '300px', md: '400px' } }}
                placeholder="Προσθέστε σύνδεσμο με τους Όρους Χρήσης"
                type="default"
                disabled={disableFields(reviewMode, true)}
                label="Όροι Χρήσης"
                value={item}
                onChange={e =>
                  setObj((values: ProductElectricTypes) => ({
                    ...values,
                    info: {
                      ...values.info,
                      terms: terms?.map((term, index) =>
                        index === i ? e.target.value : term,
                      ),
                    },
                  }))
                }
              />
              {i + 1 === terms.length && terms.length < 5 ? (
                <IconButton
                  sx={{ mt: 1.25, color: theme.palette.primary.main }}
                  disabled={disableFields(reviewMode, true)}
                  onClick={() =>
                    setObj((values: ProductElectricTypes) => ({
                      ...values,
                      info: {
                        ...values.info,
                        terms: [...terms, ''],
                      },
                    }))
                  }
                >
                  <AddCircleOutlineIcon fontSize="large" />
                </IconButton>
              ) : (
                <IconButton
                  sx={{ mt: 1.25, color: '#87007B' }}
                  disabled={disableFields(reviewMode, true)}
                  onClick={() =>
                    setObj((values: ProductElectricTypes) => ({
                      ...values,
                      info: {
                        ...values.info,
                        terms: terms.filter((term, index) => index !== i),
                      },
                    }))
                  }
                >
                  <RemoveCircleOutlineIcon fontSize="large" />
                </IconButton>
              )}
            </Box>
          ))}
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.productInfo?.termsLinkGr}
              id="productInfo.termLinkGr"
              title="Όροι Χρήσης"
              addComment={addComment}
            />
          ) : null}
        </Box>
        <Box
          sx={{
            mt: 3,
          }}
        >
          <Box>
            <Input
              sx={{ mt: 2, width: '100%' }}
              minRows={4}
              disabled={disableFields(reviewMode, true)}
              multiline
              type="default"
              placeholder="Εισαγετε πληροφορίες του προϊόντος"
              label="Πληροφορίες σχετικά με το Προϊόν"
              value={obj?.info?.info}
              onChange={e =>
                setObj((values: ProductElectricTypes) => ({
                  ...values,
                  info: {
                    ...values.info,
                    info: e.target.value,
                  },
                }))
              }
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.productInfo?.infoGr}
                id="productInfo.infoGr"
                title="Πληροφορίες σχετικά με το Προϊόν"
                addComment={addComment}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    );
  }, [
    disableSocialInvoice,
    minDate,
    socialinvoices?.documents,
    obj.info,
    obj.reviewComments,
    reviewMode,
    addComment,
    providersIdOptions,
    openCountGr,
    theme.palette,
    openPhaseGr,
    openDuration,
    setObj,
  ]);

  const EnTab = useMemo(() => {
    const checkboxesSoxialInv: CheckBoxesProps['checkboxes'] = socialInvoiceTableData(
      socialinvoices?.documents,
    ).map(item => ({
      label: item.socialInvoiceNameEn,
      value: item._id,
    })) as CheckBoxesProps['checkboxes'];

    const terms =
      obj?.info?.termsEn && obj.info.termsEn.length > 0 ? obj.info.termsEn : [''];

    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            mt: 3,
          }}
        >
          <Input
            sx={{ mt: 2, minWidth: '300px' }}
            type="lock"
            disabled
            label="Provider"
            value={obj?.info?.provider?.providerNameEn}
            onChange={() => {}}
          />
          <Box>
            <Input
              sx={{ mt: 2, minWidth: '300px' }}
              disabled={disableFields(reviewMode)}
              type="default"
              label="Product Commercial Name"
              placeholder="Enter product name"
              value={obj?.info?.productNameEn}
              onChange={e =>
                setObj((values: ProductElectricTypes) => ({
                  ...values,
                  info: {
                    ...values.info,
                    productNameEn: e.target.value,
                  },
                }))
              }
            />
          </Box>
          <Box>
            <Input
              sx={{ mt: 2, minWidth: '300px' }}
              type="default"
              disabled={disableFields(reviewMode, true)}
              label="Product link"
              placeholder="Add link"
              value={obj?.info?.productLinkEn}
              onChange={e =>
                setObj((values: ProductElectricTypes) => ({
                  ...values,
                  info: {
                    ...values.info,
                    productLinkEn: e.target.value,
                  },
                }))
              }
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.productInfo?.linkEn}
                id="productInfo.linkEn"
                title="Product link"
                addComment={addComment}
              />
            ) : null}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            mt: 6,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: { xs: '0rem', md: '4rem' },
            }}
          >
            <Box>
              <DropdownNew
                sx={{ width: { xs: '300px', md: '450px' }, mt: 2 }}
                disabled={disableFields(reviewMode)}
                multiline={false}
                label="Supply Identity"
                labelBtn="Εφαρμογή"
                choices={providersIdOptionsEn}
                open={openCountGr}
                setOpen={setOpenCountGr}
                selected={obj?.info?.providerId?._id ? [obj?.info?.providerId?._id] : []}
                setSelected={(type: any) =>
                  setObj((values: ProductElectricTypes) => ({
                    ...values,
                    info: {
                      ...values.info,
                      providerId: {
                        ...values.info?.providerId,
                        _id: type[0],
                      },
                    },
                  }))
                }
              />
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.productInfo?.providerId}
                  id="productInfo.providerId"
                  title="Measurement"
                  addComment={addComment}
                />
              ) : null}
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              <Typography variant="subtitle2" sx={{ color: theme.palette.primary.main }}>
                Recepient of Social Home Tariff?
              </Typography>
              <Box>
                <CheckBoxes
                  sx={{ pt: 2 }}
                  checked={obj?.info?.receivesDisc ? obj?.info?.receivesDisc : []}
                  disabled={
                    disableFields(reviewMode)
                      ? checkboxesSoxialInv.map(item => item.value)
                      : []
                  }
                  setChecked={event =>
                    setObj((values: ProductElectricTypes) => ({
                      ...values,
                      info: {
                        ...values.info,
                        receivesDisc: event,
                      },
                    }))
                  }
                  checkboxes={checkboxesSoxialInv}
                />

                {showReviewComments(reviewMode) ? (
                  <ReviewTool
                    view={viewOnlyReviewComments(reviewMode)}
                    msg={obj.reviewComments?.productInfo?.socialInvoiceDiscounts}
                    id="productInfo.socialInvoiceDiscounts"
                    title="Social Home Tariff"
                    addComment={addComment}
                  />
                ) : null}
              </Box>
            </Box>
            <Box>
              <Dropdown
                sx={{ width: { xs: '300px', md: '450px' } }}
                disabled={disableFields(reviewMode)}
                label="Supply Connection Phases"
                labelBtn="Εφαρμογή"
                choices={['Μονοφασική', 'Τριφασική', 'Και τα δύο']}
                enLabels={[
                  { en: '1-phased', gr: 'Μονοφασική' },
                  { en: '3-phased', gr: 'Τριφασική' },
                  { en: 'Both', gr: 'Και τα δύο' },
                ]}
                open={openPhaseGr}
                setOpen={setOpenPhaseGr}
                selected={obj?.info?.phase ? [obj?.info?.phase] : []}
                setSelected={(type: any) =>
                  setObj((values: ProductElectricTypes) => ({
                    ...values,
                    info: {
                      ...values.info,
                      phase: type[0],
                    },
                  }))
                }
              />
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.productInfo?.phase}
                  id="productInfo.phase"
                  title="Supply Connection Phases"
                  addComment={addComment}
                />
              ) : null}
            </Box>
            <Box>
              <DropdownNew
                sx={{ width: { xs: '300px', md: '450px' }, mt: 2 }}
                disabled={disableFields(reviewMode)}
                label="Contract Duration"
                labelBtn="Εφαρμογή"
                choices={[
                  {
                    label: '1 month',
                    value: '1',
                  },
                  {
                    label: '3 months',
                    value: '3',
                  },
                  {
                    label: '6 months',
                    value: '6',
                  },
                  {
                    label: '12 months',
                    value: '12',
                  },
                  {
                    label: '18 months',
                    value: '18',
                  },
                  {
                    label: '24 months',
                    value: '24',
                  },
                  {
                    label: 'Indefinite',
                    value: 'undefined',
                  },
                ]}
                open={openDuration}
                setOpen={setOpenDuration}
                selected={obj?.info?.duration ? [obj?.info?.duration] : []}
                setSelected={(type: any) =>
                  setObj((values: ProductElectricTypes) => ({
                    ...values,
                    info: {
                      ...values.info,
                      duration: type[0],
                    },
                  }))
                }
              />
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.productInfo?.duration}
                  id="productInfo.duration"
                  title="Contract Duration"
                  addComment={addComment}
                />
              ) : null}
            </Box>
          </Box>
          <Box>
            <Box>
              <Input
                sx={{ mt: 2, width: { xs: '300px', md: '400px' } }}
                disabled={true}
                value={obj?.info?.productImg ? obj?.info?.productImg?.name : ''}
                type="upload"
                subText="file must be in .png format, up to 2mb"
                label="Logo"
              />
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.productInfo?.image}
                  id="productInfo.image"
                  title="Logo"
                  addComment={addComment}
                />
              ) : null}
            </Box>

            <Box
              sx={{
                mt: 2,
                height: { xs: '200px', md: '400px' },
                width: { xs: '200px', md: '400px' },
              }}
            >
              <DropZone
                imgURL={obj.info?.productImgUrl}
                type="img"
                file={obj?.info?.productImg}
                disable={disableFields(reviewMode, true)}
                setFile={(file: any) =>
                  setObj((values: ProductElectricTypes) => ({
                    ...values,
                    info: {
                      ...values.info,
                      productImg: file,
                    },
                  }))
                }
              />
              <Typography
                sx={{
                  mt: 1,
                  marginLeft: 1,
                  fontSize: 14,
                  color: theme.palette.grey[700],
                  fontWeight: 400,
                  textAlign: 'center',
                }}
                variant="subtitle2"
                gutterBottom
              >
                If the field is not filled in, its logo will be displayed supplier.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'flex-start',
            mt: 4,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box sx={{ width: '350px', mt: 2 }}>
              <Box>
                <DatePicker
                  label="Activation date"
                  disabled={disableFields(reviewMode)}
                  inputFormat="DD/MM/YYYY"
                  minDate={minDate}
                  maxDate={undefined}
                  value={obj?.info?.from ? obj?.info?.from : null}
                  onChange={newValue =>
                    setObj((values: ProductElectricTypes) => ({
                      ...values,
                      info: {
                        ...values.info,
                        from: newValue,
                      },
                    }))
                  }
                  renderInput={params => (
                    <TextField sx={{ width: { xs: '300px', md: '450px' } }} {...params} />
                  )}
                />
                {showReviewComments(reviewMode) ? (
                  <ReviewTool
                    view={viewOnlyReviewComments(reviewMode)}
                    msg={obj.reviewComments?.productInfo?.availabilityDate}
                    id="productInfo.availabilityDate"
                    title="Activation date"
                    addComment={addComment}
                  />
                ) : null}
              </Box>
            </Box>
          </LocalizationProvider>
        </Box>
        <Box
          sx={{
            mt: 4,
          }}
        >
          {terms?.map((item, i) => (
            <Box key={i}>
              <Input
                sx={{ mt: 1, minWidth: { xs: '300px', md: '400px' } }}
                placeholder="Add link to Terms of Use"
                type="default"
                disabled={disableFields(reviewMode, true)}
                label="Terms"
                value={item}
                onChange={e =>
                  setObj((values: ProductElectricTypes) => ({
                    ...values,
                    info: {
                      ...values.info,
                      termsEn: terms?.map((term, index) =>
                        index === i ? e.target.value : term,
                      ),
                    },
                  }))
                }
              />
              {i + 1 === terms.length && terms.length < 5 ? (
                <IconButton
                  sx={{ mt: 1.25, color: theme.palette.primary.main }}
                  disabled={disableFields(reviewMode, true)}
                  onClick={() =>
                    setObj((values: ProductElectricTypes) => ({
                      ...values,
                      info: {
                        ...values.info,
                        termsEn: [...terms, ''],
                      },
                    }))
                  }
                >
                  <AddCircleOutlineIcon fontSize="large" />
                </IconButton>
              ) : (
                <IconButton
                  sx={{ mt: 1.25, color: '#87007B' }}
                  disabled={disableFields(reviewMode, true)}
                  onClick={() =>
                    setObj((values: ProductElectricTypes) => ({
                      ...values,
                      info: {
                        ...values.info,
                        termsEn: terms.filter((term, index) => index !== i),
                      },
                    }))
                  }
                >
                  <RemoveCircleOutlineIcon fontSize="large" />
                </IconButton>
              )}
            </Box>
          ))}
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.productInfo?.termsEn}
              id="productInfo.termEn"
              title="Terms"
              addComment={addComment}
            />
          ) : null}
        </Box>
        <Box
          sx={{
            mt: 3,
          }}
        >
          <Box>
            <Input
              sx={{ mt: 2, width: '100%' }}
              disabled={disableFields(reviewMode, true)}
              minRows={4}
              multiline
              type="default"
              placeholder="
              Enter product information"
              label="Product Info"
              value={obj?.info?.infoEn}
              onChange={e =>
                setObj((values: ProductElectricTypes) => ({
                  ...values,
                  info: {
                    ...values.info,
                    infoEn: e.target.value,
                  },
                }))
              }
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.productInfo?.infoEn}
                id="productInfo.infoEn"
                title="Product Info"
                addComment={addComment}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    );
  }, [
    minDate,
    addComment,
    obj.info,
    obj.reviewComments,
    openCountGr,
    openDuration,
    openPhaseGr,
    reviewMode,
    providersIdOptionsEn,
    setObj,
    socialinvoices?.documents,
    theme.palette,
  ]);

  const tabs = useMemo(() => {
    return [
      {
        tabName: 'GR',
        tabContent: GrTab,
        badge:
          showReviewComments(reviewMode) &&
          Boolean(
            obj.reviewComments?.productInfo?.termsLinkGr ||
              obj.reviewComments?.productInfo?.nameGr ||
              obj.reviewComments?.productInfo?.linkGr ||
              obj.reviewComments?.productInfo?.infoGr,
          ),
      },
      {
        tabName: 'EN',
        tabContent: EnTab,
        badge:
          showReviewComments(reviewMode) &&
          Boolean(
            obj.reviewComments?.productInfo?.termsLinkEn ||
              obj.reviewComments?.productInfo?.nameEn ||
              obj.reviewComments?.productInfo?.linkEn ||
              obj.reviewComments?.productInfo?.infoEn,
          ),
      },
    ];
  }, [EnTab, GrTab, obj.reviewComments?.productInfo, reviewMode]);

  return <CustomTabs tabs={tabs} newTab={0} />;
};
