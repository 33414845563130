import { Box, Divider, Paper, Typography, useTheme } from '@mui/material';
import { ButtonRae } from '@rae/ui-library';

type Props = {
  handleAction: () => void;
};

export const NotMemberCard = ({ handleAction }: Props) => {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        width: 492,
        height: 300,
        borderRadius: '16px',
        p: 4,
        position: 'relative',
      }}
      elevation={3}
    >
      <Box>
        <Typography
          sx={{ minWidth: '200px' }}
          color={theme.palette.primary.main}
          variant="h5"
        >
          Άρση Πρόσβασης
        </Typography>
        <Divider
          sx={{
            mt: 1,
            width: '150px',
            borderBottomWidth: 4,
            borderColor: theme.palette.primary.main,
          }}
          textAlign="left"
        />
      </Box>
      <Box sx={{ mt: 6 }}>
        <Typography>
          Η πρόσβαση για τον χρήστη με τα στοιχεία που εισάγατε δεν είναι σε ισχύ.
          Παρακαλώ πολύ επικοινωνήστε με τον διαχειριστή του συστήματος (admin) για
          περισσότερες πληροφορίες.
        </Typography>
      </Box>
      <Box sx={{ position: 'absolute', bottom: 20, width: `calc(100% - 64px)` }}>
        <ButtonRae
          color="primary"
          variant="contained"
          sx={{ width: '100%' }}
          onClick={handleAction}
        >
          Επιστροφή στην αρχική
        </ButtonRae>
      </Box>
    </Paper>
  );
};
