import { SpecialDiscProps } from '../pages/SpecialDiscounts';
import { ApiSchema, Identifier } from './ApiSchema';
import { TieredBilling } from './TieredBilling';
import { ChargeRows, chargeRowsAsNumber, chargeRowsAsString } from './ChargeRows';

export type SpecialDiscountGasFields = {
  homeTieredBilling?: TieredBilling;
  homeChargeScale?: ChargeRows[];
  professionalyTieredBilling?: TieredBilling;
  professionalyChargeScale?: ChargeRows[];
  centralTieredBilling?: TieredBilling;
  centralChargeScale?: ChargeRows[];
};

export type SpecialDiscountGasFieldsRequest = {
  homeTieredBilling?: TieredBilling | null;
  homeChargeScale?: ChargeRows[] | null;
  professionalyTieredBilling?: TieredBilling | null;
  professionalyChargeScale?: ChargeRows[] | null;
  centralTieredBilling?: TieredBilling | null;
  centralChargeScale?: ChargeRows[] | null;
};

export type SpecialDiscountGas = ApiSchema<SpecialDiscountGasFields>;

export const specialDiscountGasData = (
  discount?: SpecialDiscountGas,
): SpecialDiscProps['gas'] => {
  return {
    _id: discount?._id,
    home: {
      chargeRows: discount?.homeChargeScale
        ? chargeRowsAsString(discount?.homeChargeScale)
        : undefined,
      tieredBilling: discount?.homeTieredBilling,
      showDisc: !!discount?.homeChargeScale && discount?.homeChargeScale.length > 0,
    },
    professionaly: {
      chargeRows: discount?.professionalyChargeScale
        ? chargeRowsAsString(discount?.professionalyChargeScale)
        : undefined,
      tieredBilling: discount?.professionalyTieredBilling,
      showDisc:
        !!discount?.professionalyChargeScale &&
        discount?.professionalyChargeScale.length > 0,
    },
    central: {
      chargeRows: discount?.centralChargeScale
        ? chargeRowsAsString(discount?.centralChargeScale)
        : undefined,
      tieredBilling: discount?.centralTieredBilling,
      showDisc: !!discount?.centralChargeScale && discount?.centralChargeScale.length > 0,
    },
  };
};

export const submitSpecialDiscountGasData = (
  discount?: SpecialDiscProps['gas'],
): Identifier & SpecialDiscountGasFieldsRequest => {
  return {
    _id: discount?._id || '',
    homeTieredBilling:
      discount?.home?.showDisc && discount?.home?.tieredBilling
        ? (discount?.home?.tieredBilling as TieredBilling)
        : null,
    homeChargeScale:
      discount?.home?.showDisc && discount?.home?.chargeRows
        ? chargeRowsAsNumber(discount?.home?.chargeRows)
        : null,
    professionalyTieredBilling:
      discount?.professionaly?.showDisc && discount?.professionaly?.tieredBilling
        ? (discount?.professionaly?.tieredBilling as TieredBilling)
        : null,
    professionalyChargeScale:
      discount?.professionaly?.showDisc && discount?.professionaly?.chargeRows
        ? chargeRowsAsNumber(discount?.professionaly?.chargeRows)
        : null,
    centralTieredBilling:
      discount?.central?.showDisc && discount?.central?.tieredBilling
        ? (discount?.central?.tieredBilling as TieredBilling)
        : null,
    centralChargeScale:
      discount?.central?.showDisc && discount?.central?.chargeRows
        ? chargeRowsAsNumber(discount?.central?.chargeRows)
        : null,
  };
};
