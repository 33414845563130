import api from './api';
import { ApiPagination, PaginationResult } from '../../types/ApiPagination';
import { Identifier } from '../../types/ApiSchema';
import { Discount, DiscountFieldsRequest, DiscountParams } from '../../types/Discount';

const discountApi = api.enhanceEndpoints({ addTagTypes: ['Discount'] }).injectEndpoints({
  endpoints: builder => ({
    getDiscounts: builder.query<
      PaginationResult<Discount>,
      DiscountParams & ApiPagination
    >({
      query: params => ({
        url: `/database/function/getDiscount`,
        method: 'GET',
        params: { ...params, populate: ['discBenefType', 'team'] },
      }),
      providesTags: result =>
        result?.documents
          ? [
              ...result.documents.map(({ _id }) => ({ type: 'Discount', _id } as const)),
              { type: 'Discount', id: 'LIST' },
            ]
          : [{ type: 'Discount', id: 'LIST' }],
    }),
    addDiscount: builder.mutation<Discount, Partial<DiscountFieldsRequest>>({
      query: params => ({
        url: `database/Discount`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: [{ type: 'Discount', id: 'LIST' }],
    }),
    editDiscount: builder.mutation<Discount, Identifier & Partial<DiscountFieldsRequest>>(
      {
        query: ({ _id, ...params }) => ({
          url: `database/Discount/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'Discount', _id }],
      },
    ),
    deleteDiscount: builder.mutation<void, Identifier>({
      query: ({ _id }) => ({
        url: `raeService/discount/${_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { _id }) => [{ type: 'Discount', _id }],
    }),
  }),
});

export const {
  useGetDiscountsQuery,
  useAddDiscountMutation,
  useEditDiscountMutation,
  useDeleteDiscountMutation,
} = discountApi;

export default discountApi;
