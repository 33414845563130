import { Box, Typography, useTheme } from '@mui/material';
import { Input } from '@rae/ui-library';
import React, { useCallback, useMemo, useRef } from 'react';
import { ProductGasTypes } from '../../ProductGasTypes';
import { TieredBilling } from '../../../../types/TieredBilling';
import ChargeScales from '../../../ChargeScales/ChargeScales';
import {
  disableFields,
  ReviewModeEnum,
  showReviewComments,
  viewOnlyReviewComments,
} from '../../../../types/ReviewMode';
import { ReviewTool } from '../../../ProductElectricPage/actions/ReviewTool';

type Obj = {
  index?: number;
  obj: ProductGasTypes;
  setObj: (newState: any) => void;
  count: string;
  reviewMode: ReviewModeEnum;
  addComment: (id: string, comment: string) => void;
};

export const StableSection = ({
  index = 0,
  obj,
  setObj,
  count,
  reviewMode,
  addComment,
}: Obj) => {
  const theme = useTheme();

  let initialStateDay = useRef(
    structuredClone(obj.competitiveFee?.[index]?.stableSection?.day),
  );
  let initialStateNight = useRef(
    structuredClone(obj.competitiveFee?.[index]?.stableSection?.night),
  );

  const handleReset = useCallback(
    (reset: 'day' | 'night') => {
      switch (count) {
        case 'Ημερήσια':
          setObj((values: ProductGasTypes) => {
            const newValues = { ...values };
            if (newValues.competitiveFee?.[index]) {
              newValues.competitiveFee[index] = {
                ...newValues.competitiveFee[index],
                stableSection: {
                  ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                  day: initialStateDay.current,
                },
              };
            }
            return newValues;
          });
          break;
        case 'Ημερήσια + Νυχτερινή':
          switch (reset) {
            case 'day':
              setObj((values: ProductGasTypes) => {
                const newValues = { ...values };
                if (newValues.competitiveFee?.[index]) {
                  newValues.competitiveFee[index] = {
                    ...newValues.competitiveFee[index],
                    stableSection: {
                      ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                      day: initialStateDay.current,
                    },
                  };
                }
                return newValues;
              });
              break;
            case 'night':
              setObj((values: ProductGasTypes) => {
                const newValues = { ...values };
                if (newValues.competitiveFee?.[index]) {
                  newValues.competitiveFee[index] = {
                    ...newValues.competitiveFee[index],
                    stableSection: {
                      ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                      night: initialStateNight.current,
                    },
                  };
                }
                return newValues;
              });
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    },
    [count, index, setObj],
  );

  const section = useMemo(() => {
    switch (count) {
      case 'Ημερήσια':
        return (
          <>
            <Box>
              <Typography variant="h6" color={theme.palette.primary.main} sx={{ mb: 2 }}>
                Ημέρας
              </Typography>
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.competitiveFees?.day}
                  id="competitiveFees.day"
                  title="Ημέρας"
                  addComment={addComment}
                />
              ) : null}
            </Box>
            <ChargeScales
              disabled={disableFields(reviewMode)}
              chargeRows={obj.competitiveFee?.[index]?.stableSection?.day?.chargeRows}
              setChargeRows={value =>
                setObj((obj: ProductGasTypes) => {
                  const newValues = { ...obj };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      stableSection: {
                        ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                        day: {
                          ...(newValues.competitiveFee[index]?.stableSection?.day ?? {}),
                          chargeRows: value,
                        },
                      },
                    };
                  }
                  return newValues;
                })
              }
              tieredBilling={
                obj.competitiveFee?.[index]?.stableSection?.day
                  ?.tieredBilling as TieredBilling
              }
              setTieredBilling={value =>
                setObj((obj: ProductGasTypes) => {
                  const newValues = { ...obj };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      stableSection: {
                        ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                        day: {
                          ...(newValues.competitiveFee[index]?.stableSection?.day ?? {}),
                          tieredBilling: value,
                          chargeRows: [{ amount: '0', from: '', to: '' }],
                        },
                      },
                    };
                  }
                  return newValues;
                })
              }
              onReset={() => handleReset('day')}
              amountLabel={'Σταθερή Χρέωση Προμήθειας (€/kWh)'}
            />
          </>
        );
      case 'Ημερήσια + Νυχτερινή':
        return (
          <>
            <Box>
              <Typography variant="h6" color={theme.palette.primary.main} sx={{ mb: 2 }}>
                Ημέρας
              </Typography>
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.competitiveFees?.day}
                  id="competitiveFees.day"
                  title="Ημέρας"
                  addComment={addComment}
                />
              ) : null}
            </Box>
            <ChargeScales
              disabled={disableFields(reviewMode)}
              chargeRows={obj.competitiveFee?.[index]?.stableSection?.day?.chargeRows}
              setChargeRows={value =>
                setObj((obj: ProductGasTypes) => {
                  const newValues = { ...obj };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      stableSection: {
                        ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                        day: {
                          ...(newValues.competitiveFee[index]?.stableSection?.day ?? {}),
                          chargeRows: value,
                        },
                      },
                    };
                  }
                  return newValues;
                })
              }
              tieredBilling={
                obj.competitiveFee?.[index]?.stableSection?.day
                  ?.tieredBilling as TieredBilling
              }
              setTieredBilling={value =>
                setObj((obj: ProductGasTypes) => {
                  const newValues = { ...obj };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      stableSection: {
                        ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                        day: {
                          ...(newValues.competitiveFee[index]?.stableSection?.day ?? {}),
                          tieredBilling: value,
                          chargeRows: [{ amount: '0', from: '', to: '' }],
                        },
                      },
                    };
                  }
                  return newValues;
                })
              }
              onReset={() => handleReset('day')}
              amountLabel={'Σταθερή Χρέωση Προμήθειας (€/kWh)'}
            />

            <Box>
              <Typography
                variant="h6"
                color={theme.palette.primary.main}
                sx={{ mb: 2, mt: 8 }}
              >
                Νύχτας
              </Typography>
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.competitiveFees?.night}
                  id="competitiveFees.night"
                  title="Νύχτας"
                  addComment={addComment}
                />
              ) : null}
            </Box>
            <ChargeScales
              disabled={disableFields(reviewMode)}
              chargeRows={obj.competitiveFee?.[index]?.stableSection?.night?.chargeRows}
              setChargeRows={value =>
                setObj((obj: ProductGasTypes) => {
                  const newValues = { ...obj };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      stableSection: {
                        ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                        night: {
                          ...(newValues.competitiveFee[index]?.stableSection?.night ??
                            {}),
                          chargeRows: value,
                        },
                      },
                    };
                  }
                  return newValues;
                })
              }
              tieredBilling={
                obj.competitiveFee?.[index]?.stableSection?.night
                  ?.tieredBilling as TieredBilling
              }
              setTieredBilling={value =>
                setObj((obj: ProductGasTypes) => {
                  const newValues = { ...obj };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      stableSection: {
                        ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                        night: {
                          ...(newValues.competitiveFee[index]?.stableSection?.night ??
                            {}),
                          tieredBilling: value,
                          chargeRows: [{ amount: '0', from: '', to: '' }],
                        },
                      },
                    };
                  }
                  return newValues;
                })
              }
              onReset={() => handleReset('night')}
              amountLabel={'Σταθερή Χρέωση Προμήθειας (€/kWh)'}
            />
          </>
        );
      default:
        break;
    }
  }, [
    index,
    count,
    theme.palette.primary.main,
    reviewMode,
    obj,
    addComment,
    setObj,
    handleReset,
  ]);

  return (
    <>
      <Box>{section}</Box>
      <Box sx={{ mt: 4 }}>
        <Typography color={theme.palette.primary.main}>
          Χρεώσεις Χρήσης Συστήματος Μεταφοράς
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Input
            sx={{
              mt: 4,
              width: '300px',
              mr: { xs: 0, md: 4 },
            }}
            inputType="number"
            color="primary"
            type={'default'}
            label="Βόρεια Χρέωση Ενέργειας (€/kWh)"
            disabled={disableFields(reviewMode)}
            placeholder="πχ 0,12 €/kWh"
            value={obj.competitiveFee?.[index]?.stableSection?.energyChargeNorth}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setObj((val: ProductGasTypes) => {
                const newValues = { ...val };
                if (newValues.competitiveFee?.[index]) {
                  newValues.competitiveFee[index] = {
                    ...newValues.competitiveFee[index],
                    stableSection: {
                      ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                      energyChargeNorth: event.target.value,
                    },
                  };
                }
                return newValues;
              })
            }
          />
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.competitiveFees?.energyChargeNorth}
              id="competitiveFees.energyChargeNorth"
              title="Βόρεια Χρέωση Ενέργειας"
              addComment={addComment}
            />
          ) : null}
          <Input
            sx={{
              mt: 4,
              width: '300px',
              mr: { xs: 0, md: 4 },
            }}
            inputType="number"
            color="primary"
            type={'default'}
            label="Βόρεια Χρέωση Δυναμικότητας (€/kWh)"
            disabled={disableFields(reviewMode)}
            placeholder="πχ 0,12 €/kWh"
            value={obj.competitiveFee?.[index]?.stableSection?.dynamicChargeNorth}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setObj((val: ProductGasTypes) => {
                const newValues = { ...val };
                if (newValues.competitiveFee?.[index]) {
                  newValues.competitiveFee[index] = {
                    ...newValues.competitiveFee[index],
                    stableSection: {
                      ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                      dynamicChargeNorth: event.target.value,
                    },
                  };
                }
                return newValues;
              })
            }
          />
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.competitiveFees?.dynamicChargeNorth}
              id="competitiveFees.dynamicChargeNorth"
              title="Βόρεια Χρέωση Δυναμικότητας"
              addComment={addComment}
            />
          ) : null}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Input
            sx={{
              mt: 4,
              width: '300px',
              mr: { xs: 0, md: 4 },
            }}
            inputType="number"
            color="primary"
            type={'default'}
            label="Νότια Χρέωση Ενέργειας (€/kWh)"
            disabled={disableFields(reviewMode)}
            placeholder="πχ 0,12 €/kWh"
            value={obj.competitiveFee?.[index]?.stableSection?.energyChargeSouth}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setObj((val: ProductGasTypes) => {
                const newValues = { ...val };
                if (newValues.competitiveFee?.[index]) {
                  newValues.competitiveFee[index] = {
                    ...newValues.competitiveFee[index],
                    stableSection: {
                      ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                      energyChargeSouth: event.target.value,
                    },
                  };
                }
                return newValues;
              })
            }
          />
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.competitiveFees?.energyChargeSouth}
              id="competitiveFees.energyChargeSouth"
              title="Νότια Χρέωση Ενέργειας"
              addComment={addComment}
            />
          ) : null}
          <Input
            sx={{
              mt: 4,
              width: '300px',
              mr: { xs: 0, md: 4 },
            }}
            inputType="number"
            color="primary"
            type={'default'}
            label="Νότια Χρέωση Δυναμικότητας (€/kWh)"
            disabled={disableFields(reviewMode)}
            placeholder="πχ 0,12 €/kWh"
            value={obj.competitiveFee?.[index]?.stableSection?.dynamicChargeSouth}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setObj((val: ProductGasTypes) => {
                const newValues = { ...val };
                if (newValues.competitiveFee?.[index]) {
                  newValues.competitiveFee[index] = {
                    ...newValues.competitiveFee[index],
                    stableSection: {
                      ...(newValues.competitiveFee[index]?.stableSection ?? {}),
                      dynamicChargeSouth: event.target.value,
                    },
                  };
                }
                return newValues;
              })
            }
          />
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.competitiveFees?.dynamicChargeSouth}
              id="competitiveFees.dynamicChargeSouth"
              title="Νότια Χρέωση Δυναμικότητας"
              addComment={addComment}
            />
          ) : null}
        </Box>
      </Box>
    </>
  );
};
