import { StorageFile, storageFileToFile } from './StorageFile';
import { ApiSchema } from './ApiSchema';
import { TableDocumentsProps } from '@rae/ui-library/src/components/table/TableRowDocuments';
import moment from 'moment';

export type DocumentsFields = {
  fileName: string;
  fileGr: StorageFile;
  fileNameEn?: string;
  fileEn?: StorageFile;
};

export type Documents = ApiSchema<DocumentsFields>;

export type DocumentsParams = {
  search?: string;
};

export type DocumentsFieldsRequest = Omit<DocumentsFields, 'fileGr' | 'fileEn'> & {
  fileGr: string;
  fileEn?: string;
};

export const documentsData = (docs?: Documents[]): TableDocumentsProps[] => {
  return (
    docs?.map(doc => ({
      _id: doc._id,
      fileName: doc.fileName,
      fileGr: storageFileToFile(doc.fileGr),
      fileGrId: doc.fileGr._id,
      fileGrUrl: doc.fileGr.url,
      fileEn: storageFileToFile(doc.fileEn),
      fileEnId: doc.fileEn?._id,
      fileEnUrl: doc.fileEn?.url,
      fileNameEn: doc?.fileNameEn,
      modDate: moment(doc.createdAt).format('DD/MM/YYYY'),
      modEmail: '',
    })) ?? []
  );
};
