import api from './api';
import { ApiPagination, PaginationResult } from '../../types/ApiPagination';
import { ProductLinked, ProductLinkedFieldsRequest } from '../../types/ProductLinked';
import { Identifier } from '../../types/ApiSchema';

const productLinkedApi = api
  .enhanceEndpoints({ addTagTypes: ['ProductLinked'] })
  .injectEndpoints({
    endpoints: builder => ({
      getProductsLinked: builder.query<PaginationResult<ProductLinked>, ApiPagination>({
        query: params => ({
          url: `/database/ProductLinked`,
          method: 'GET',
          params: params,
        }),
        providesTags: result =>
          result?.documents
            ? [
                ...result.documents.map(
                  ({ _id }) => ({ type: 'ProductLinked', _id } as const),
                ),
                { type: 'ProductLinked', id: 'LIST' },
              ]
            : [{ type: 'ProductLinked', id: 'LIST' }],
      }),

      getProductLinked: builder.query<ProductLinked, Identifier>({
        query: ({ _id, ...params }) => ({
          url: `/database/ProductLinked/${_id}`,
          method: 'GET',
          params: {
            populate: [
              'productInfo.image',
              'productInfo.team',
              'productInfo.productGas',
              'productInfo.productElectric',
              'discountsElectric.discounts',
              'discountsElectric.discounts.discBenefType',
              'discountsGas.discounts',
              'discountsGas.discounts.discBenefType',
              'benefitsElectric.benefits',
              'benefitsElectric.benefits.discBenefType',
              'benefitsGas.benefits',
              'benefitsGas.benefits.discBenefType',
              'original',
            ],
            ...params,
          },
        }),
        providesTags: result =>
          result ? [{ type: 'ProductLinked', _id: result._id }] : [],
      }),
      addProductLinked: builder.mutation<
        ProductLinked,
        Partial<ProductLinkedFieldsRequest>
      >({
        query: params => ({
          url: `/database/ProductLinked`,
          method: 'POST',
          body: params,
        }),
        invalidatesTags: [{ type: 'ProductLinked', id: 'LIST' }],
      }),
      editProductLinked: builder.mutation<
        ProductLinked,
        Identifier & Partial<ProductLinkedFieldsRequest>
      >({
        query: ({ _id, ...params }) => ({
          url: `/database/ProductLinked/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [
          { type: 'ProductLinked', _id },
          { type: 'ProductLinked', id: 'LIST' },
        ],
      }),
      getProductLinkedVersions: builder.query<ProductLinked[], Identifier>({
        query: ({ _id }) => ({
          url: `database/function/getProductLinkedVersions/${_id}`,
          method: 'GET',
          params: { sort: '-productInfo.availabilityDate' },
        }),
      }),
    }),
  });

export const {
  useGetProductsLinkedQuery,
  useAddProductLinkedMutation,
  useGetProductLinkedQuery,
  useEditProductLinkedMutation,
  useGetProductLinkedVersionsQuery,
} = productLinkedApi;

export default productLinkedApi;
