import { Box, Divider, Grid, Paper, Typography, useTheme } from '@mui/material';
import { CheckBoxes, CustomTabs } from '@rae/ui-library';
import { CheckBoxesProps } from '@rae/ui-library/src/components/CheckBox';
import React from 'react';
import { ProductElectric } from '../../types/ProductElectric';
import { ProductGas } from '../../types/ProductGas';
import { LinkedInvoicesTypes } from './LinkedInvoicesTypes';
import { EnTab, GrTab } from './LinkingInvoiceinsideTab';
import {
  disableFields,
  ReviewModeEnum,
  showReviewComments,
  viewOnlyReviewComments,
} from '../../types/ReviewMode';
import { ReviewTool } from '../ProductElectricPage/actions/ReviewTool';
import moment from 'moment';

type Obj = {
  obj: LinkedInvoicesTypes;
  setObj: (newState: any) => void;
  productsElectric?: ProductElectric[];
  productsGas?: ProductGas[];
  reviewMode: ReviewModeEnum;
  addComment: (id: string, comment: string) => void;
  minDate?: Date;
};

export const LinkingInvoiceTab = ({
  obj,
  setObj,
  productsElectric,
  productsGas,
  reviewMode,
  addComment,
  minDate,
}: Obj) => {
  const theme = useTheme();

  const tabs = [
    {
      tabName: 'GR',
      tabContent: (
        <GrTab
          obj={obj}
          setObj={setObj}
          addComment={addComment}
          reviewMode={reviewMode}
          minDate={minDate}
        />
      ),
      badge:
        showReviewComments(reviewMode) &&
        Boolean(
          obj.reviewComments?.productInfo?.nameGr ||
            obj.reviewComments?.productInfo?.linkGr,
        ),
    },
    {
      tabName: 'EN',
      tabContent: (
        <EnTab
          obj={obj}
          setObj={setObj}
          addComment={addComment}
          reviewMode={reviewMode}
          minDate={minDate}
        />
      ),
      badge:
        showReviewComments(reviewMode) &&
        Boolean(
          obj.reviewComments?.productInfo?.nameEn ||
            obj.reviewComments?.productInfo?.linkEn,
        ),
    },
  ];

  const checkboxesProductElectric: CheckBoxesProps['checkboxes'] = (
    reviewMode === ReviewModeEnum.Creation ||
    reviewMode === ReviewModeEnum.Versioning ||
    reviewMode === ReviewModeEnum.Revising
      ? productsElectric
      : productsElectric?.filter(item => item._id === obj.electricProd?.[0])
  )?.map(item => ({
    label: `${item.productInfo.nameGr} (${moment(
      item.productInfo.availabilityDate,
    ).format('DD/MM/YYYY')})`,
    value: item._id,
  })) as CheckBoxesProps['checkboxes'];

  const checkboxesProductGas: CheckBoxesProps['checkboxes'] = (
    reviewMode === ReviewModeEnum.Creation ||
    reviewMode === ReviewModeEnum.Versioning ||
    reviewMode === ReviewModeEnum.Revising
      ? productsGas
      : productsGas?.filter(item => item._id === obj.gasProd?.[0])
  )?.map(item => ({
    label: `${item.productInfo.nameGr} (${moment(
      item.productInfo.availabilityDate,
    ).format('DD/MM/YYYY')})`,
    value: item._id,
  })) as CheckBoxesProps['checkboxes'];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ minHeight: '200px' }}>
          <Paper elevation={2} sx={{ p: 3 }}>
            <Box>
              <Typography
                sx={{ minWidth: '200px' }}
                fontSize={'14px'}
                fontWeight={600}
                color={theme.palette.primary.main}
              >
                Επιλογή Προϊόντος Ηλεκτρικής Ενέργειας
              </Typography>
              <Divider
                sx={{
                  mt: 1,
                  width: '20%',
                  borderBottomWidth: 4,
                  borderColor: theme.palette.primary.main,
                }}
                textAlign="left"
              />
            </Box>
            <CheckBoxes
              checked={obj.electricProd}
              setChecked={type =>
                setObj((values: LinkedInvoicesTypes) => ({
                  ...values,
                  electricProd: [type[type.length - 1]],
                }))
              }
              disabled={
                disableFields(reviewMode)
                  ? checkboxesProductElectric.map(item => item.value ?? '')
                  : []
              }
              checkboxes={checkboxesProductElectric}
              row={false}
              boxProps={{
                sx: { flexDirection: 'column', alignItems: 'flex-start', mt: 3, mb: 3 },
              }}
            />
          </Paper>
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.productInfo?.productElectric}
              id="productInfo.productElectric"
              title="Προϊόν Ηλεκτρικής Ενέργειας"
              addComment={addComment}
            />
          ) : null}
        </Grid>
        <Grid item xs={6} sx={{ minHeight: '200px' }}>
          <Paper elevation={2} sx={{ p: 3 }}>
            <Box>
              <Typography
                sx={{ minWidth: '200px' }}
                fontSize={'14px'}
                fontWeight={600}
                color={theme.palette.primary.main}
              >
                Επιλογή Προϊόντος Φυσικού Αερίου
              </Typography>
              <Divider
                sx={{
                  mt: 1,
                  width: '20%',
                  borderBottomWidth: 4,
                  borderColor: theme.palette.primary.main,
                }}
                textAlign="left"
              />
            </Box>
            <CheckBoxes
              checked={obj.gasProd}
              setChecked={type =>
                setObj((values: LinkedInvoicesTypes) => ({
                  ...values,
                  gasProd: [type[type.length - 1]],
                }))
              }
              disabled={
                disableFields(reviewMode)
                  ? checkboxesProductGas.map(item => item.value ?? '')
                  : []
              }
              checkboxes={checkboxesProductGas}
              row={false}
              boxProps={{
                sx: { flexDirection: 'column', alignItems: 'flex-start', mt: 3, mb: 3 },
              }}
            />
          </Paper>
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.productInfo?.productGas}
              id="productInfo.productGas"
              title="Προϊόν Φυσικού Αερίου"
              addComment={addComment}
            />
          ) : null}
        </Grid>
      </Grid>
      <Box sx={{ mt: 4 }}>
        <CustomTabs tabs={tabs} newTab={0} />
      </Box>
    </>
  );
};
