import { ApiPagination, PaginationDataResult } from '../../types/ApiPagination';
import { Identifier } from '../../types/ApiSchema';
import {
  filterParams,
  RegulatedChargesElectric,
  RegulatedChargesElectricFieldsRequest,
} from '../../types/RegulatedChargesElectric';
import api from './api';

const regulatedChargesElectricApi = api
  .enhanceEndpoints({ addTagTypes: ['RegulatedCharges'] })
  .injectEndpoints({
    endpoints: builder => ({
      getRegulatedChargesElectric: builder.query<
        PaginationDataResult<RegulatedChargesElectric>,
        filterParams & ApiPagination
      >({
        query: params => ({
          url: `/raeService/join-provider-charges`,
          method: 'GET',
          params: { ...params, populate: ['providerId'] },
        }),
        providesTags: result =>
          result?.data
            ? [
                ...result.data.map(
                  ({ _id }) => ({ type: 'RegulatedCharges', _id } as const),
                ),
                { type: 'RegulatedCharges', id: 'LIST' },
              ]
            : [{ type: 'RegulatedCharges', id: 'LIST' }],
      }),
      addRegulatedChargesElectric: builder.mutation<
        RegulatedChargesElectric,
        Partial<RegulatedChargesElectricFieldsRequest>
      >({
        query: params => ({
          url: `database/RegulatedChargesElectric`,
          method: 'POST',
          body: params,
        }),
        invalidatesTags: [{ type: 'RegulatedCharges', id: 'LIST' }],
      }),
      editRegulatedChargesElectric: builder.mutation<
        RegulatedChargesElectric,
        Identifier & Partial<RegulatedChargesElectricFieldsRequest>
      >({
        query: ({ _id, ...params }) => ({
          url: `database/RegulatedChargesElectric/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'RegulatedCharges', _id }],
      }),
      deleteRegulatedChargesElectric: builder.mutation<void, Identifier>({
        query: ({ _id }) => ({
          url: `database/RegulatedChargesElectric/${_id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'RegulatedCharges', _id }],
      }),
    }),
  });

export const {
  useGetRegulatedChargesElectricQuery,
  useAddRegulatedChargesElectricMutation,
  useEditRegulatedChargesElectricMutation,
  useDeleteRegulatedChargesElectricMutation,
} = regulatedChargesElectricApi;

export default regulatedChargesElectricApi;
