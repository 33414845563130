import { ShowLoginErrorProps } from '../components/LoginPage/LoginCard';
import { LoginUserProps } from '../pages/LoginPage';

type LoginPageValidationsProps = {
  loginUser: LoginUserProps;
  setShowError: (newState: any) => void;
  setErrorMsg: (newState: any) => void;
};

export const loginPageValidations = ({
  loginUser,
  setErrorMsg,
  setShowError,
}: LoginPageValidationsProps) => {
  const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  let errorFlag = false;

  setShowError({});
  setErrorMsg({});

  if (!loginUser.password) {
    setShowError((prev: ShowLoginErrorProps) => ({
      ...prev,
      password: true,
    }));
    setErrorMsg((prev: ShowLoginErrorProps) => ({
      ...prev,
      password: 'Password must be filled',
    }));
    errorFlag = true;
  }
  if (!loginUser.email) {
    setShowError((prev: ShowLoginErrorProps) => ({
      ...prev,
      email: true,
    }));
    setErrorMsg((prev: ShowLoginErrorProps) => ({
      ...prev,
      email: 'Email must be filled',
    }));
    errorFlag = true;
  } else {
    if (!expression.test(loginUser.email)) {
      setShowError((prev: ShowLoginErrorProps) => ({
        ...prev,
        email: true,
      }));
      setErrorMsg((prev: ShowLoginErrorProps) => ({
        ...prev,
        email: 'Not valid Email',
      }));
      errorFlag = true;
    }
  }
  return errorFlag;
};
