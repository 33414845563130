import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { SpecialDiscProps } from '../../../pages/SpecialDiscounts';
import { TieredBilling } from '../../../types/TieredBilling';
import ChargeScales from '../../ChargeScales/ChargeScales';

type HomeTabGasProps = {
  data: SpecialDiscProps;
  setData: (newState: any) => void;
  handleReset: () => void;
};

export const HomeGas = ({ data, setData, handleReset }: HomeTabGasProps) => {
  const handleChangeShowDisc = useCallback(
    (val: boolean) => {
      setData((values: SpecialDiscProps) => ({
        ...values,
        gas: {
          ...values.gas,
          home: {
            ...values?.gas?.home,
            tieredBilling: undefined,
            showDisc: val,
          },
        },
      }));
    },
    [setData],
  );

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <FormControlLabel
          control={
            <Switch
              checked={data?.gas?.home?.showDisc ? data?.gas.home?.showDisc : false}
              color="primary"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeShowDisc(event.target.checked)
              }
            />
          }
          label="Εφαρμόζεται έκπτωση"
          labelPlacement="start"
        />
      </Box>
      {data?.gas?.home?.showDisc ? (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mt: 2, mb: 2 }}>Έκπτωση ΤΕΜ</Typography>
          <ChargeScales
            chargeRows={data.gas?.home?.chargeRows}
            setChargeRows={value =>
              setData((values: SpecialDiscProps) => ({
                ...values,
                gas: {
                  ...values.gas,
                  home: {
                    ...values?.gas?.home,
                    chargeRows: value,
                  },
                },
              }))
            }
            tieredBilling={data?.gas?.home?.tieredBilling as TieredBilling}
            setTieredBilling={value =>
              setData((values: SpecialDiscProps) => ({
                ...values,
                gas: {
                  ...values.gas,
                  home: {
                    ...values?.gas?.home,
                    tieredBilling: value,
                    chargeRows: [{ amount: '0', from: '', to: '' }],
                  },
                },
              }))
            }
            onReset={handleReset}
            amountLabel={'Εκπτωση ΤΕΜ (€/kWh)'}
          />
        </Box>
      ) : null}
    </Box>
  );
};
