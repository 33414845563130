import { ShowResetErrorProps } from '../components/ResetPassPage/ResetPassCard';
import { ForgotPasswordRequest } from '../types/Auth';

type ResetPassPageValidationsProps = {
  resetEmail: ForgotPasswordRequest;
  setShowError: (newState: any) => void;
  setErrorMsg: (newState: any) => void;
};

export const ResetPassPageValidations = ({
  resetEmail,
  setErrorMsg,
  setShowError,
}: ResetPassPageValidationsProps) => {
  const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  let errorFlag = false;

  setShowError({});
  setErrorMsg({});

  if (!resetEmail.email) {
    setShowError((prev: ShowResetErrorProps) => ({
      ...prev,
      email: true,
    }));
    setErrorMsg((prev: ShowResetErrorProps) => ({
      ...prev,
      email: 'Email must be filled',
    }));
    errorFlag = true;
  } else {
    if (!expression.test(resetEmail.email)) {
      setShowError((prev: ShowResetErrorProps) => ({
        ...prev,
        email: true,
      }));
      setErrorMsg((prev: ShowResetErrorProps) => ({
        ...prev,
        email: 'Not valid Email',
      }));
      errorFlag = true;
    }
  }
  return errorFlag;
};
