export enum TieredBillingEnum {
  Single = 'single',
  Scalar = 'scalar',
  Cumulative = 'cumulative',
}

export type TieredBilling = `${TieredBillingEnum}`;

export const tieredBillingLabel = (categoryOfSupply?: string) => {
  switch (categoryOfSupply) {
    case 'single':
      return 'Ενιαία';
    case 'scalar':
      return 'Κλιμακωτή';
    case 'cumulative':
      return 'Σωρευτική';
    default:
      return '';
  }
};

export const tieredBillingRevertLabel = (categoryOfSupply?: string) => {
  switch (categoryOfSupply) {
    case 'Ενιαία':
      return 'single';
    case 'Κλιμακωτή':
      return 'scalar';
    case 'Σωρευτική':
      return 'cumulative';
    default:
      return undefined;
  }
};
