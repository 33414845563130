import { Divider, IconButton, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { CheckBoxes } from '@rae/ui-library';
import { CheckBoxesProps } from '@rae/ui-library/src/components/CheckBox';
import React, { useCallback, useMemo, useRef } from 'react';
import {
  ProductElectricTypes,
  ProductElectricTypesOtherCharges,
} from '../ProductElectricTypes';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { OthersSection } from './charges/OthersSection';
import { TableOtherChargesProps } from '@rae/ui-library/src/components/table/TableRowOtherCharges';
import {
  disableFields,
  ReviewModeEnum,
  showReviewComments,
  viewOnlyReviewComments,
} from '../../../types/ReviewMode';
import { ReviewTool } from '../actions/ReviewTool';
import { TieredBilling } from '../../../types/TieredBilling';
import ChargeScales from '../../ChargeScales/ChargeScales';
import ChargeScalesMixed from '../../ChargeScales/ChargeScalesMixed';
import { TableCompFeesProps } from '@rae/ui-library/dist/cjs/types/src/components/table/TableRowCompFees';

type Obj = {
  obj: ProductElectricTypes;
  setObj: (newState: any) => void;
  count?: string[];
  phases?: string;
  otherCharges: TableOtherChargesProps[];
  reviewMode: ReviewModeEnum;
  addComment: (id: string, comment: string) => void;
  compFees: TableCompFeesProps[];
};

export const OtherChargesTab = ({
  obj,
  setObj,
  count,
  phases,
  otherCharges,
  reviewMode,
  addComment,
  compFees,
}: Obj) => {
  const theme = useTheme();

  const setObjOtherCharge = useCallback(
    (val: ProductElectricTypesOtherCharges, index: number) =>
      setObj((values: ProductElectricTypes) => {
        const newOtherCharges = values.otherCharges ? [...values.otherCharges] : [];
        newOtherCharges[index] = {
          ...newOtherCharges[index],
          ...val,
        };
        return {
          ...values,
          otherCharges: newOtherCharges,
        };
      }),
    [setObj],
  );

  const initialState = useRef(structuredClone(obj?.otherCharges));

  const checkboxesPagiou: CheckBoxesProps['checkboxes'] =
    otherCharges?.map(charge => ({
      label: charge?.chargeName ? charge?.chargeName : '',
      value: charge?._id ? charge?._id : '',
    })) ?? [];

  const checkboxesOtherCharges: CheckBoxesProps['checkboxes'] = [
    {
      label: 'Άλλο',
      value: 'other',
    },
  ];

  const section = useCallback(
    (item: ProductElectricTypesOtherCharges, index: number) => {
      switch (count ? count[0] : '') {
        case 'Ημερήσια':
          return (
            <>
              <Typography variant="h6" color={theme.palette.primary.main} sx={{ mb: 2 }}>
                Ημέρας
              </Typography>
              {phases === 'Μονοφασική' ? (
                <Box sx={{ mb: 4 }}>
                  <Typography variant="caption" color={theme.palette.primary.main}>
                    Ποσό μηνιαίου παγίου μονοφασικής χρέωσης σε ευρώ
                  </Typography>
                  {showReviewComments(reviewMode) ? (
                    <ReviewTool
                      view={viewOnlyReviewComments(reviewMode)}
                      msg={obj.reviewComments?.otherCharges?.daySinglePhase?.[index]}
                      id={`otherCharges.daySinglePhase.${index}`}
                      title="Ημέρας Μονοφασική"
                      addComment={addComment}
                    />
                  ) : null}
                  <ChargeScales
                    disabled={disableFields(reviewMode)}
                    chargeRows={item?.daySinglePhase?.chargeRows}
                    setChargeRows={value =>
                      setObjOtherCharge(
                        {
                          ...item,
                          daySinglePhase: {
                            ...item?.daySinglePhase,
                            chargeRows: value,
                          },
                        },
                        index,
                      )
                    }
                    tieredBilling={item?.daySinglePhase?.tieredBilling as TieredBilling}
                    setTieredBilling={value =>
                      setObjOtherCharge(
                        {
                          ...item,
                          daySinglePhase: {
                            ...item?.daySinglePhase,
                            tieredBilling: value,
                            chargeRows: [{ amount: '0', from: '', to: '' }],
                          },
                        },
                        index,
                      )
                    }
                    onReset={() => {
                      setObjOtherCharge(
                        {
                          ...item,
                          daySinglePhase: {
                            ...(initialState.current?.[index].daySinglePhase ?? {}),
                          },
                        },
                        index,
                      );
                    }}
                    amountLabel={'Ποσό μηνιαίου παγίου μονοφασικής (€)'}
                  />
                </Box>
              ) : null}
              {phases === 'Τριφασική' ? (
                <Box sx={{ mt: 4 }}>
                  <Typography variant="caption" color={theme.palette.primary.main}>
                    Ποσό μηνιαίου παγίου τριφασικής χρέωσης σε ευρώ
                  </Typography>
                  {showReviewComments(reviewMode) ? (
                    <ReviewTool
                      view={viewOnlyReviewComments(reviewMode)}
                      msg={obj.reviewComments?.otherCharges?.dayThreePhase?.[index]}
                      id={`otherCharges.dayThreePhase.${index}`}
                      title="Ημέρας Τριφασική"
                      addComment={addComment}
                    />
                  ) : null}
                  <ChargeScales
                    disabled={disableFields(reviewMode)}
                    chargeRows={item?.dayMultiPhase?.chargeRows}
                    setChargeRows={value =>
                      setObjOtherCharge(
                        {
                          ...item,
                          dayMultiPhase: {
                            ...item?.dayMultiPhase,
                            chargeRows: value,
                          },
                        },
                        index,
                      )
                    }
                    tieredBilling={item?.dayMultiPhase?.tieredBilling as TieredBilling}
                    setTieredBilling={value =>
                      setObjOtherCharge(
                        {
                          ...item,
                          dayMultiPhase: {
                            ...item?.dayMultiPhase,
                            tieredBilling: value,
                            chargeRows: [{ amount: '0', from: '', to: '' }],
                          },
                        },
                        index,
                      )
                    }
                    onReset={() => {
                      setObjOtherCharge(
                        {
                          ...item,
                          dayMultiPhase: {
                            ...(initialState.current?.[index].dayMultiPhase ?? {}),
                          },
                        },
                        index,
                      );
                    }}
                    amountLabel={'Ποσό μηνιαίου παγίου τριφασικής (€)'}
                  />
                </Box>
              ) : null}
              {phases === 'Και τα δύο' ? (
                <>
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="caption" color={theme.palette.primary.main}>
                      Ποσό μηνιαίου παγίου μονοφασικής χρέωσης σε ευρώ
                    </Typography>
                    {showReviewComments(reviewMode) ? (
                      <ReviewTool
                        view={viewOnlyReviewComments(reviewMode)}
                        msg={obj.reviewComments?.otherCharges?.daySinglePhase?.[index]}
                        id={`otherCharges.daySinglePhase.${index}`}
                        title="Ημέρας Μονοφασική"
                        addComment={addComment}
                      />
                    ) : null}
                    <ChargeScales
                      disabled={disableFields(reviewMode)}
                      chargeRows={item?.daySinglePhase?.chargeRows}
                      setChargeRows={value =>
                        setObjOtherCharge(
                          {
                            ...item,
                            daySinglePhase: {
                              ...item?.daySinglePhase,
                              chargeRows: value,
                            },
                          },
                          index,
                        )
                      }
                      tieredBilling={item?.daySinglePhase?.tieredBilling as TieredBilling}
                      setTieredBilling={value =>
                        setObjOtherCharge(
                          {
                            ...item,
                            daySinglePhase: {
                              ...item?.daySinglePhase,
                              tieredBilling: value,
                              chargeRows: [{ amount: '0', from: '', to: '' }],
                            },
                          },
                          index,
                        )
                      }
                      onReset={() => {
                        setObjOtherCharge(
                          {
                            ...item,
                            daySinglePhase: {
                              ...(initialState.current?.[index].daySinglePhase ?? {}),
                            },
                          },
                          index,
                        );
                      }}
                      amountLabel={'Ποσό μηνιαίου παγίου μονοφασικής (€)'}
                    />
                  </Box>
                  <Box sx={{ mt: 4 }}>
                    <Typography variant="caption" color={theme.palette.primary.main}>
                      Ποσό μηνιαίου παγίου τριφασικής χρέωσης σε ευρώ
                    </Typography>
                    {showReviewComments(reviewMode) ? (
                      <ReviewTool
                        view={viewOnlyReviewComments(reviewMode)}
                        msg={obj.reviewComments?.otherCharges?.dayThreePhase?.[index]}
                        id={`otherCharges.dayThreePhase.${index}`}
                        title="Ημέρας Τριφασική"
                        addComment={addComment}
                      />
                    ) : null}
                    <ChargeScales
                      disabled={disableFields(reviewMode)}
                      chargeRows={item?.dayMultiPhase?.chargeRows}
                      setChargeRows={value =>
                        setObjOtherCharge(
                          {
                            ...item,
                            dayMultiPhase: {
                              ...item?.dayMultiPhase,
                              chargeRows: value,
                            },
                          },
                          index,
                        )
                      }
                      tieredBilling={item?.dayMultiPhase?.tieredBilling as TieredBilling}
                      setTieredBilling={value =>
                        setObjOtherCharge(
                          {
                            ...item,
                            dayMultiPhase: {
                              ...item?.dayMultiPhase,
                              tieredBilling: value,
                              chargeRows: [{ amount: '0', from: '', to: '' }],
                            },
                          },
                          index,
                        )
                      }
                      onReset={() => {
                        setObjOtherCharge(
                          {
                            ...item,
                            dayMultiPhase: {
                              ...(initialState.current?.[index].dayMultiPhase ?? {}),
                            },
                          },
                          index,
                        );
                      }}
                      amountLabel={'Ποσό μηνιαίου παγίου τριφασικής (€)'}
                    />
                  </Box>
                </>
              ) : null}
            </>
          );
        case 'Ημερήσια & Νυχτερινή':
          return (
            <>
              <Typography variant="h6" color={theme.palette.primary.main} sx={{ mb: 2 }}>
                Ημέρας
              </Typography>
              {phases === 'Μονοφασική' ? (
                <Box sx={{ mb: 4 }}>
                  <Typography variant="caption" color={theme.palette.primary.main}>
                    Ποσό μηνιαίου παγίου μονοφασικής χρέωσης σε ευρώ
                  </Typography>
                  {showReviewComments(reviewMode) ? (
                    <ReviewTool
                      view={viewOnlyReviewComments(reviewMode)}
                      msg={obj.reviewComments?.otherCharges?.daySinglePhase?.[index]}
                      id={`otherCharges.daySinglePhase.${index}`}
                      title="Ημέρας Μονοφασική"
                      addComment={addComment}
                    />
                  ) : null}
                  <ChargeScales
                    disabled={disableFields(reviewMode)}
                    chargeRows={item?.daySinglePhase?.chargeRows}
                    setChargeRows={value =>
                      setObjOtherCharge(
                        {
                          ...item,
                          daySinglePhase: {
                            ...item?.daySinglePhase,
                            chargeRows: value,
                          },
                        },
                        index,
                      )
                    }
                    tieredBilling={item?.daySinglePhase?.tieredBilling as TieredBilling}
                    setTieredBilling={value =>
                      setObjOtherCharge(
                        {
                          ...item,
                          daySinglePhase: {
                            ...item?.daySinglePhase,
                            tieredBilling: value,
                            chargeRows: [{ amount: '0', from: '', to: '' }],
                          },
                        },
                        index,
                      )
                    }
                    onReset={() =>
                      setObjOtherCharge(
                        {
                          ...item,
                          daySinglePhase: {
                            ...(initialState.current?.[index].daySinglePhase ?? {}),
                          },
                        },
                        index,
                      )
                    }
                    amountLabel={'Ποσό μηνιαίου παγίου μονοφασικής (€)'}
                  />
                </Box>
              ) : null}
              {phases === 'Τριφασική' ? (
                <Box sx={{ mt: 4 }}>
                  <Typography variant="caption" color={theme.palette.primary.main}>
                    Ποσό μηνιαίου παγίου τριφασικής χρέωσης σε ευρώ
                  </Typography>
                  {showReviewComments(reviewMode) ? (
                    <ReviewTool
                      view={viewOnlyReviewComments(reviewMode)}
                      msg={obj.reviewComments?.otherCharges?.dayThreePhase?.[index]}
                      id={`otherCharges.dayThreePhase.${index}`}
                      title="Ημέρας Τριφασική"
                      addComment={addComment}
                    />
                  ) : null}
                  <ChargeScales
                    disabled={disableFields(reviewMode)}
                    chargeRows={item?.dayMultiPhase?.chargeRows}
                    setChargeRows={value =>
                      setObjOtherCharge(
                        {
                          ...item,
                          dayMultiPhase: {
                            ...item?.dayMultiPhase,
                            chargeRows: value,
                          },
                        },
                        index,
                      )
                    }
                    tieredBilling={item?.dayMultiPhase?.tieredBilling as TieredBilling}
                    setTieredBilling={value =>
                      setObjOtherCharge(
                        {
                          ...item,
                          dayMultiPhase: {
                            ...item?.dayMultiPhase,
                            tieredBilling: value,
                            chargeRows: [{ amount: '0', from: '', to: '' }],
                          },
                        },
                        index,
                      )
                    }
                    onReset={() =>
                      setObjOtherCharge(
                        {
                          ...item,
                          dayMultiPhase: {
                            ...(initialState.current?.[index].dayMultiPhase ?? {}),
                          },
                        },
                        index,
                      )
                    }
                    amountLabel={'Ποσό μηνιαίου παγίου τριφασικής (€)'}
                  />
                </Box>
              ) : null}
              {phases === 'Και τα δύο' ? (
                <>
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="caption" color={theme.palette.primary.main}>
                      Ποσό μηνιαίου παγίου μονοφασικής χρέωσης σε ευρώ
                    </Typography>
                    {showReviewComments(reviewMode) ? (
                      <ReviewTool
                        view={viewOnlyReviewComments(reviewMode)}
                        msg={obj.reviewComments?.otherCharges?.daySinglePhase?.[index]}
                        id={`otherCharges.daySinglePhase.${index}`}
                        title="Ημέρας Μονοφασική"
                        addComment={addComment}
                      />
                    ) : null}
                    <ChargeScales
                      disabled={disableFields(reviewMode)}
                      chargeRows={item?.daySinglePhase?.chargeRows}
                      setChargeRows={value =>
                        setObjOtherCharge(
                          {
                            ...item,
                            daySinglePhase: {
                              ...item?.daySinglePhase,
                              chargeRows: value,
                            },
                          },
                          index,
                        )
                      }
                      tieredBilling={item?.daySinglePhase?.tieredBilling as TieredBilling}
                      setTieredBilling={value =>
                        setObjOtherCharge(
                          {
                            ...item,
                            daySinglePhase: {
                              ...item?.daySinglePhase,
                              tieredBilling: value,
                              chargeRows: [{ amount: '0', from: '', to: '' }],
                            },
                          },
                          index,
                        )
                      }
                      onReset={() =>
                        setObjOtherCharge(
                          {
                            ...item,
                            daySinglePhase: {
                              ...(initialState.current?.[index].daySinglePhase ?? {}),
                            },
                          },
                          index,
                        )
                      }
                      amountLabel={'Ποσό μηνιαίου παγίου μονοφασικής (€)'}
                    />
                  </Box>
                  <Box sx={{ mt: 4 }}>
                    <Typography variant="caption" color={theme.palette.primary.main}>
                      Ποσό μηνιαίου παγίου τριφασικής χρέωσης σε ευρώ
                    </Typography>
                    {showReviewComments(reviewMode) ? (
                      <ReviewTool
                        view={viewOnlyReviewComments(reviewMode)}
                        msg={obj.reviewComments?.otherCharges?.dayThreePhase?.[index]}
                        id={`otherCharges.dayThreePhase.${index}`}
                        title="Ημέρας Τριφασική"
                        addComment={addComment}
                      />
                    ) : null}
                    <ChargeScales
                      disabled={disableFields(reviewMode)}
                      chargeRows={item?.dayMultiPhase?.chargeRows}
                      setChargeRows={value =>
                        setObjOtherCharge(
                          {
                            ...item,
                            dayMultiPhase: {
                              ...item?.dayMultiPhase,
                              chargeRows: value,
                            },
                          },
                          index,
                        )
                      }
                      tieredBilling={item?.dayMultiPhase?.tieredBilling as TieredBilling}
                      setTieredBilling={value =>
                        setObjOtherCharge(
                          {
                            ...item,
                            dayMultiPhase: {
                              ...item?.dayMultiPhase,
                              tieredBilling: value,
                              chargeRows: [{ amount: '0', from: '', to: '' }],
                            },
                          },
                          index,
                        )
                      }
                      onReset={() =>
                        setObjOtherCharge(
                          {
                            ...item,
                            dayMultiPhase: {
                              ...(initialState.current?.[index].dayMultiPhase ?? {}),
                            },
                          },
                          index,
                        )
                      }
                      amountLabel={'Ποσό μηνιαίου παγίου τριφασικής (€)'}
                    />
                  </Box>
                </>
              ) : null}
              <Typography
                variant="h6"
                color={theme.palette.primary.main}
                sx={{ mb: 2, mt: 8 }}
              >
                Νύχτας
              </Typography>
              {phases === 'Μονοφασική' ? (
                <Box sx={{ mb: 4 }}>
                  <Typography variant="caption" color={theme.palette.primary.main}>
                    Ποσό μηνιαίου παγίου μονοφασικής χρέωσης σε ευρώ
                  </Typography>
                  {showReviewComments(reviewMode) ? (
                    <ReviewTool
                      view={viewOnlyReviewComments(reviewMode)}
                      msg={obj.reviewComments?.otherCharges?.nightSinglePhase?.[index]}
                      id={`otherCharges.nightSinglePhase.${index}`}
                      title="Νύχτας Μονοφασική"
                      addComment={addComment}
                    />
                  ) : null}
                  <ChargeScales
                    disabled={disableFields(reviewMode)}
                    chargeRows={item?.nightSinglePhase?.chargeRows}
                    setChargeRows={value =>
                      setObjOtherCharge(
                        {
                          ...item,
                          nightSinglePhase: {
                            ...item?.nightSinglePhase,
                            chargeRows: value,
                          },
                        },
                        index,
                      )
                    }
                    tieredBilling={item?.nightSinglePhase?.tieredBilling as TieredBilling}
                    setTieredBilling={value =>
                      setObjOtherCharge(
                        {
                          ...item,
                          nightSinglePhase: {
                            ...item?.nightSinglePhase,
                            tieredBilling: value,
                            chargeRows: [{ amount: '0', from: '', to: '' }],
                          },
                        },
                        index,
                      )
                    }
                    onReset={() =>
                      setObjOtherCharge(
                        {
                          ...item,
                          nightSinglePhase: {
                            ...(initialState.current?.[index].nightSinglePhase ?? {}),
                          },
                        },
                        index,
                      )
                    }
                    amountLabel={'Ποσό μηνιαίου παγίου μονοφασικής (€)'}
                  />
                </Box>
              ) : null}
              {phases === 'Τριφασική' ? (
                <Box sx={{ mt: 4 }}>
                  <Typography variant="caption" color={theme.palette.primary.main}>
                    Ποσό μηνιαίου παγίου τριφασικής χρέωσης σε ευρώ
                  </Typography>
                  {showReviewComments(reviewMode) ? (
                    <ReviewTool
                      view={viewOnlyReviewComments(reviewMode)}
                      msg={obj.reviewComments?.otherCharges?.nightThreePhase?.[index]}
                      id={`otherCharges.nightThreePhase.${index}`}
                      title="Νύχτας Τριφασική"
                      addComment={addComment}
                    />
                  ) : null}
                  <ChargeScales
                    disabled={disableFields(reviewMode)}
                    chargeRows={item?.nightMultiPhase?.chargeRows}
                    setChargeRows={value =>
                      setObjOtherCharge(
                        {
                          ...item,
                          nightMultiPhase: {
                            ...item?.nightMultiPhase,
                            chargeRows: value,
                          },
                        },
                        index,
                      )
                    }
                    tieredBilling={item?.nightMultiPhase?.tieredBilling as TieredBilling}
                    setTieredBilling={value =>
                      setObjOtherCharge(
                        {
                          ...item,
                          nightMultiPhase: {
                            ...item?.nightMultiPhase,
                            tieredBilling: value,
                            chargeRows: [{ amount: '0', from: '', to: '' }],
                          },
                        },
                        index,
                      )
                    }
                    onReset={() =>
                      setObjOtherCharge(
                        {
                          ...item,
                          nightMultiPhase: {
                            ...(initialState.current?.[index].nightMultiPhase ?? {}),
                          },
                        },
                        index,
                      )
                    }
                    amountLabel={'Ποσό μηνιαίου παγίου τριφασικής (€)'}
                  />
                </Box>
              ) : null}
              {phases === 'Και τα δύο' ? (
                <>
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="caption" color={theme.palette.primary.main}>
                      Ποσό μηνιαίου παγίου μονοφασικής χρέωσης σε ευρώ
                    </Typography>
                    {showReviewComments(reviewMode) ? (
                      <ReviewTool
                        view={viewOnlyReviewComments(reviewMode)}
                        msg={obj.reviewComments?.otherCharges?.nightSinglePhase?.[index]}
                        id={`otherCharges.nightSinglePhase.${index}`}
                        title="Νύχτας Μονοφασική"
                        addComment={addComment}
                      />
                    ) : null}
                    <ChargeScales
                      disabled={disableFields(reviewMode)}
                      chargeRows={item?.nightSinglePhase?.chargeRows}
                      setChargeRows={value =>
                        setObjOtherCharge(
                          {
                            ...item,
                            nightSinglePhase: {
                              ...item?.nightSinglePhase,
                              chargeRows: value,
                            },
                          },
                          index,
                        )
                      }
                      tieredBilling={
                        item?.nightSinglePhase?.tieredBilling as TieredBilling
                      }
                      setTieredBilling={value =>
                        setObjOtherCharge(
                          {
                            ...item,
                            nightSinglePhase: {
                              ...item?.nightSinglePhase,
                              tieredBilling: value,
                              chargeRows: [{ amount: '0', from: '', to: '' }],
                            },
                          },
                          index,
                        )
                      }
                      onReset={() =>
                        setObjOtherCharge(
                          {
                            ...item,
                            nightSinglePhase: {
                              ...(initialState.current?.[index].nightSinglePhase ?? {}),
                            },
                          },
                          index,
                        )
                      }
                      amountLabel={'Ποσό μηνιαίου παγίου μονοφασικής (€)'}
                    />
                  </Box>
                  <Box sx={{ mt: 4 }}>
                    <Typography variant="caption" color={theme.palette.primary.main}>
                      Ποσό μηνιαίου παγίου τριφασικής χρέωσης σε ευρώ
                    </Typography>
                    {showReviewComments(reviewMode) ? (
                      <ReviewTool
                        view={viewOnlyReviewComments(reviewMode)}
                        msg={obj.reviewComments?.otherCharges?.nightThreePhase?.[index]}
                        id={`otherCharges.nightThreePhase.${index}`}
                        title="Νύχτας Τριφασική"
                        addComment={addComment}
                      />
                    ) : null}
                    <ChargeScales
                      disabled={disableFields(reviewMode)}
                      chargeRows={item?.nightMultiPhase?.chargeRows}
                      setChargeRows={value =>
                        setObjOtherCharge(
                          {
                            ...item,
                            nightMultiPhase: {
                              ...item?.nightMultiPhase,
                              chargeRows: value,
                            },
                          },
                          index,
                        )
                      }
                      tieredBilling={
                        item?.nightMultiPhase?.tieredBilling as TieredBilling
                      }
                      setTieredBilling={value =>
                        setObjOtherCharge(
                          {
                            ...item,
                            nightMultiPhase: {
                              ...item?.nightMultiPhase,
                              tieredBilling: value,
                              chargeRows: [{ amount: '0', from: '', to: '' }],
                            },
                          },
                          index,
                        )
                      }
                      onReset={() =>
                        setObjOtherCharge(
                          {
                            ...item,
                            nightMultiPhase: {
                              ...(initialState.current?.[index].nightMultiPhase ?? {}),
                            },
                          },
                          index,
                        )
                      }
                      amountLabel={'Ποσό μηνιαίου παγίου τριφασικής (€)'}
                    />
                  </Box>
                </>
              ) : null}
            </>
          );
        default:
          break;
      }
    },
    [
      count,
      theme.palette.primary.main,
      phases,
      reviewMode,
      obj,
      addComment,
      setObjOtherCharge,
    ],
  );

  const renderSections = useMemo(
    () =>
      obj?.otherCharges?.map((item, i) => (
        <Box
          sx={[
            {
              display: 'flex',
              flexDirection: 'column',
            },
          ]}
          key={i}
        >
          <Box>
            <Typography
              sx={{ minWidth: '200px', color: theme.palette.primary.main }}
              fontSize={'13px'}
              fontWeight={700}
            >
              Προσθήκη Παγίου
            </Typography>
            <Divider
              sx={{
                mt: 1,
                width: '5%',
                borderBottomWidth: 4,
                borderColor: theme.palette.primary.main,
              }}
              textAlign="left"
            />
          </Box>
          <CheckBoxes
            checked={item?.pagio ? item?.pagio : []}
            disabled={
              disableFields(reviewMode) ? checkboxesPagiou?.map(item => item.value) : []
            }
            checkboxes={checkboxesPagiou}
            setChecked={type =>
              setObjOtherCharge(
                {
                  ...item,
                  pagio: type[type.length - 1] ? [type[type.length - 1]] : [],
                },
                i,
              )
            }
          />
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.otherCharges?.otherCharges?.[i]}
              id={`otherCharges.otherCharges.${i}`}
              title="Λοιπές Χρεώσεις"
              addComment={addComment}
            />
          ) : null}
          {item?.pagio && item?.pagio.length > 0 ? <Box>{section(item, i)} </Box> : null}
          <Box sx={{ mt: 4, mb: 1 }}>
            <Typography
              sx={{ minWidth: '200px', color: theme.palette.primary.main }}
              fontSize={'13px'}
              fontWeight={700}
            >
              Προσθήκη Λοιπών Χρεώσεων
            </Typography>
            <Divider
              sx={{
                mt: 1,
                width: '5%',
                borderBottomWidth: 4,
                borderColor: theme.palette.primary.main,
              }}
              textAlign="left"
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.otherCharges?.other?.[i]}
                id={`otherCharges.other.${i}`}
                title="Λοιπές Χρεώσεις"
                addComment={addComment}
              />
            ) : null}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckBoxes
                checked={item?.otherCharges ? item?.otherCharges : []}
                disabled={
                  disableFields(reviewMode)
                    ? checkboxesOtherCharges?.map(item => item.value)
                    : []
                }
                checkboxes={checkboxesOtherCharges}
                setChecked={type =>
                  setObjOtherCharge(
                    {
                      ...item,
                      otherCharges: type,
                      other:
                        item?.other && item?.other?.length > 0
                          ? item?.other
                          : [
                              {
                                otherName: '',
                                otherDescr: '',
                                otherAmount: '',
                                otherChargeStep: '',
                                otherApply: '',
                              },
                            ],
                    },
                    i,
                  )
                }
              />
              {item?.otherCharges && item?.otherCharges?.length > 0 ? (
                <IconButton
                  sx={{ color: theme.palette.primary.main }}
                  onClick={() => {
                    const rows = item.other ? [...item.other] : [];
                    rows.push({
                      otherAmount: '',
                      otherApply: '',
                      otherChargeStep: '',
                      otherDescr: '',
                      otherName: '',
                    });
                    setObjOtherCharge(
                      {
                        ...item,
                        other: rows,
                      },
                      i,
                    );
                  }}
                  disabled={disableFields(reviewMode)}
                >
                  <AddCircleOutlineIcon fontSize="large" />
                  <Typography sx={{ ml: 1 }}> Δημιουργία νέας χρέωσης</Typography>
                </IconButton>
              ) : null}
            </Box>
            {item?.other?.map((otherItem, otherIndex) => {
              return (
                <OthersSection
                  obj={obj}
                  i={otherIndex}
                  item={otherItem}
                  setItem={val => {
                    const newOther = item.other ? [...item.other] : [];
                    newOther[otherIndex] = val;
                    setObjOtherCharge(
                      {
                        ...item,
                        other: newOther,
                      },
                      i,
                    );
                  }}
                  key={otherIndex}
                  handleDeleteRow={() => {
                    const rows = item.other ? [...item.other] : [];
                    rows.splice(otherIndex, 1);
                    setObjOtherCharge(
                      {
                        ...item,
                        other: rows,
                      },
                      i,
                    );
                  }}
                  disable={disableFields(reviewMode)}
                />
              );
            })}
          </Box>
        </Box>
      )) ?? [],
    [
      obj,
      theme.palette.primary.main,
      reviewMode,
      checkboxesPagiou,
      addComment,
      section,
      checkboxesOtherCharges,
      setObjOtherCharge,
    ],
  );

  return (
    <ChargeScalesMixed
      type={obj.competitiveFeeType}
      chargeRows={obj.competitiveChargeScale}
      competitiveFees={obj.competitiveFee}
      compFees={compFees}
      components={renderSections}
      duration={obj.info?.duration}
      disabled
    />
  );
};
