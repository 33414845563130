import React, {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Box, BoxProps, IconButton, useTheme } from '@mui/material';
import { ButtonRae, DropdownNew, Input } from '@rae/ui-library';
import CachedIcon from '@mui/icons-material/Cached';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { toNumber } from 'lodash';
import { ProductElectricTypesCompetitiveFees } from '../ProductElectricPage/ProductElectricTypes';
import { transparencyTypeColor } from '../../types/TransparencyType';
import { TableCompFeesProps } from '@rae/ui-library/dist/cjs/types/src/components/table/TableRowCompFees';
import { Choice } from '@rae/ui-library/dist/cjs/types/src/components/DropdownNew';
import { CompetitiveFeeIdsElectricEnum } from '../../types/CompetitiveFees';
import { OptionsProps } from '@rae/ui-library/dist/cjs/types/src/components/Calculator';

type ChargeScalesMixedProps = Omit<BoxProps, 'onChange'> & {
  type?: string;
  chargeRows?: { from: string; to: string }[];
  setChargeRows?: (chargeRows?: { from: string; to: string }[]) => void;
  competitiveFees?: ProductElectricTypesCompetitiveFees[];
  setCompetitiveFees?: (competitiveFees?: ProductElectricTypesCompetitiveFees[]) => void;
  components?: ReactElement[];
  compFees: TableCompFeesProps[];
  duration?: string;
  onReset?: () => void;
  disabled?: boolean;
  setCalcData?: Dispatch<SetStateAction<OptionsProps[][]>>;
};

const ChargeScalesMixed: FC<ChargeScalesMixedProps> = ({
  type,
  chargeRows,
  setChargeRows,
  competitiveFees,
  setCompetitiveFees,
  components,
  compFees,
  duration,
  onReset,
  disabled,
  setCalcData,
  ...props
}) => {
  const theme = useTheme();
  const [openType, setOpenType] = useState<number>();

  const compFeesChoices = useMemo((): Choice[] => {
    return (
      compFees
        ?.filter(
          comp =>
            comp._id !== CompetitiveFeeIdsElectricEnum.MixedTime &&
            comp._id !== CompetitiveFeeIdsElectricEnum.MixedConsumption,
        )
        ?.map(comp => ({
          value: comp._id ? comp._id : '',
          label: comp.name ? comp.name : '',
          color: transparencyTypeColor(comp.transparencyType),
        })) ?? []
    );
  }, [compFees]);

  const handleSetCompetitiveFees = useCallback(
    (type: string, index: number) => {
      const newCompetitiveFees = [...(competitiveFees ?? [])];
      newCompetitiveFees[index] = { type };
      setCompetitiveFees?.(newCompetitiveFees);
      setCalcData?.(calcData => {
        const newCalcData = [...calcData];
        newCalcData[index] = [];
        return newCalcData;
      });
    },
    [competitiveFees, setCalcData, setCompetitiveFees],
  );

  const handleAddRow = useCallback(() => {
    const stateRows = chargeRows ? structuredClone(chargeRows) : [];
    stateRows.push({ from: '', to: '' });
    setChargeRows?.(stateRows);
    setCalcData?.(calcData => {
      const newCalcData = [...calcData];
      newCalcData.push([]);
      return newCalcData;
    });
  }, [chargeRows, setCalcData, setChargeRows]);

  const handleDeleteRow = useCallback(
    (i: any) => {
      const stateRows = chargeRows ? structuredClone(chargeRows) : [];
      if (stateRows[i + 1]) {
        stateRows[i + 1].from = stateRows[i]?.from;
      } else {
        stateRows[i - 1].to = '';
      }
      stateRows.splice(i, 1);
      setChargeRows?.(stateRows);
      setCalcData?.(calcData => {
        const newCalcData = [...calcData];
        newCalcData.splice(i, 1);
        return newCalcData;
      });
    },
    [chargeRows, setCalcData, setChargeRows],
  );

  const handleChangeRow = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, i: number, name: 'from' | 'to') => {
      const stateRows = chargeRows ? structuredClone(chargeRows) : [];
      switch (name) {
        case 'from':
          if (type === CompetitiveFeeIdsElectricEnum.MixedTime) {
            stateRows[i].from = event.target.value
              ? (toNumber(event.target.value) - 1).toFixed(0)
              : '';
          } else {
            stateRows[i].from = event.target.value
              ? toNumber(event.target.value).toFixed(0)
              : '';
          }
          if (stateRows?.[i - 1]) {
            if (type === CompetitiveFeeIdsElectricEnum.MixedTime) {
              stateRows[i - 1].to = event.target.value
                ? (toNumber(event.target.value) - 2).toFixed(0)
                : '';
            } else {
              stateRows[i - 1].to = event.target.value
                ? (toNumber(event.target.value) - 1).toFixed(0)
                : '';
            }
          }
          break;
        case 'to':
          if (type === CompetitiveFeeIdsElectricEnum.MixedTime) {
            stateRows[i].to = event.target.value
              ? (toNumber(event.target.value) - 1).toFixed(0)
              : '';
          } else {
            stateRows[i].to = event.target.value
              ? toNumber(event.target.value).toFixed(0)
              : '';
          }
          if (stateRows?.[i + 1]) {
            if (type === CompetitiveFeeIdsElectricEnum.MixedTime) {
              stateRows[i + 1].from = event.target.value
                ? toNumber(event.target.value).toFixed(0)
                : '';
            } else {
              stateRows[i + 1].from = event.target.value
                ? (toNumber(event.target.value) + 1).toFixed(0)
                : '';
            }
          }
          break;
        default:
          break;
      }
      setChargeRows?.(stateRows);
    },
    [chargeRows, setChargeRows, type],
  );

  const labels = useMemo(() => {
    switch (type) {
      case CompetitiveFeeIdsElectricEnum.MixedConsumption:
        return ['Από (kWh)', 'Εώς (kWh)'];
      case CompetitiveFeeIdsElectricEnum.MixedTime:
        return ['Από (μήνα)', 'Εώς (μήνα)'];
      default:
        return ['Από', 'Εώς'];
    }
  }, [type]);

  const valueFrom = useCallback(
    (val: string, first?: boolean) => {
      if (type === CompetitiveFeeIdsElectricEnum.MixedTime) {
        if (first) return '1';
        return val ? (toNumber(val) + 1).toFixed(0) : '';
      } else {
        if (first) return '0';
        return val ?? '';
      }
    },
    [type],
  );

  const valueTo = useCallback(
    (val: string, last?: boolean) => {
      if (type === CompetitiveFeeIdsElectricEnum.MixedTime) {
        if (last) return duration ?? 'Λήξη';
        return val ? (toNumber(val) + 1).toFixed(0) : '';
      } else {
        if (last) return 'INF';
        return val ?? '';
      }
    },
    [type, duration],
  );

  if (
    type !== CompetitiveFeeIdsElectricEnum.MixedConsumption &&
    type !== CompetitiveFeeIdsElectricEnum.MixedTime
  ) {
    return <Box {...props}>{components?.map(component => component)}</Box>;
  }

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box />
        {chargeRows && chargeRows?.length > 0 ? (
          <ButtonRae
            variant="contained"
            sx={{ bgcolor: '#2357AE' }}
            onClick={onReset}
            startIcon={<CachedIcon />}
            disabled={disabled}
          >
            Επαναφορά
          </ButtonRae>
        ) : null}
      </Box>
      {chargeRows?.map((row, i) => {
        return (
          <Box
            key={i}
            sx={[
              { display: 'flex', flexDirection: 'column' },
              i > 0 && {
                marginTop: 4,
                paddingTop: 4,
                borderWidth: 0,
                borderTopWidth: 4,
                borderColor: theme.palette.primary.main,
                borderStyle: 'solid',
              },
            ]}
          >
            <Box
              sx={[
                {
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  mt: 3,
                  mb: 2,
                },
              ]}
            >
              <Input
                sx={{
                  mt: 2,
                  width: '300px',
                  mr: { xs: 0, md: 4 },
                }}
                inputType={'number'}
                disabled={i === 0 || disabled}
                color="primary"
                type={i === 0 ? 'lock' : 'default'}
                label={labels[0]}
                value={valueFrom(row.from, i === 0)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeRow(event, i, 'from')
                }
              />
              <Input
                sx={{
                  mt: 2,
                  width: '300px',
                  mr: { xs: 0, md: 4 },
                }}
                inputType={i + 1 === chargeRows?.length ? 'text' : 'number'}
                disabled={i + 1 === chargeRows?.length || disabled}
                color="primary"
                type={i + 1 === chargeRows?.length ? 'lock' : 'default'}
                label={labels[1]}
                value={valueTo(row.to, i + 1 === chargeRows?.length)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeRow(event, i, 'to')
                }
              />
              <DropdownNew
                sx={{ width: { xs: '300px', md: '450px' }, mt: 2 }}
                multiline={false}
                disabled={disabled}
                label="Τύπος Ανταγωνιστικής Χρέωσης Κλίμακας"
                labelBtn="Εφαρμογή"
                choices={compFeesChoices}
                open={openType === i}
                setOpen={open => setOpenType(open ? i : undefined)}
                selected={
                  competitiveFees?.[i]?.type ? [competitiveFees[i].type ?? ''] : []
                }
                setSelected={(type: any) => handleSetCompetitiveFees?.(type[0], i)}
                color={transparencyTypeColor(
                  compFees?.find(f => f._id === competitiveFees?.[i]?.type)
                    ?.transparencyType,
                )}
              />
              {i === 0 ? (
                <IconButton
                  sx={{ mt: 2, color: theme.palette.primary.main }}
                  onClick={handleAddRow}
                  disabled={disabled}
                >
                  <AddCircleOutlineIcon fontSize="large" />
                </IconButton>
              ) : null}
              {i !== 0 ? (
                <IconButton
                  sx={{ mt: 2, color: '#87007B' }}
                  onClick={() => handleDeleteRow(i)}
                  disabled={disabled}
                >
                  <RemoveCircleOutlineIcon fontSize="large" />
                </IconButton>
              ) : null}
            </Box>
            <Box
              sx={{
                p: 2,
                border: `1px solid ${theme.palette.grey[400]}`,
                borderRadius: 2,
              }}
            >
              {components?.[i]}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ChargeScalesMixed;
