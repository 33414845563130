export enum TransparencyTypeEnum {
  Fixed = 'fixed',
  Special = 'special',
  Fluctuating = 'fluctuating',
  Mixed = 'mixed',
}

export type TransparencyType = `${TransparencyTypeEnum}`;

export const transparencyTypeColor = (transparency?: string) => {
  switch (transparency) {
    case 'fixed':
      return '#0064b4';
    case 'special':
      return '#0f872d';
    case 'fluctuating':
    case 'mixed':
      return '#ffd700';
    // case 'dynamic':
    //   return '#f5c343';
    default:
      return '';
  }
};

export const transparencyTypeTextColor = (transparency?: string) => {
  switch (transparency) {
    case 'fixed':
    case 'special':
      return '#fff';
    case 'fluctuating':
    case 'mixed':
      // case 'dynamic':
      return '#000';
    default:
      return '';
  }
};

export const transparencyTypeLabel = (transparency?: string) => {
  switch (transparency) {
    case 'fixed':
      return { gr: 'Σταθερό Τιμολόγιο', en: 'Fixed Invoice' };
    case 'special':
      return { gr: 'Ειδικό Τιμολόγιο', en: 'Special Invoice' };
    case 'fluctuating':
      return { gr: 'Κυμαινόμενο Τιμολόγιο', en: 'Fluctuating Invoice' };
    case 'mixed':
      return { gr: 'Μεικτό Τιμολόγιο', en: 'Mixed Invoice' };
    default:
      return { gr: '', en: '' };
  }
};

export const energyTypeReverseLabel = (
  energyType: string,
): TransparencyType | undefined => {
  switch (energyType) {
    case transparencyTypeLabel(TransparencyTypeEnum.Fixed).gr:
    case transparencyTypeLabel(TransparencyTypeEnum.Fixed).en:
      return TransparencyTypeEnum.Fixed;
    case transparencyTypeLabel(TransparencyTypeEnum.Special).gr:
    case transparencyTypeLabel(TransparencyTypeEnum.Special).en:
      return TransparencyTypeEnum.Special;
    case transparencyTypeLabel(TransparencyTypeEnum.Fluctuating).gr:
    case transparencyTypeLabel(TransparencyTypeEnum.Fluctuating).en:
      return TransparencyTypeEnum.Fluctuating;
    case transparencyTypeLabel(TransparencyTypeEnum.Mixed).gr:
    case transparencyTypeLabel(TransparencyTypeEnum.Mixed).en:
      return TransparencyTypeEnum.Mixed;
    default:
      return undefined;
  }
};
