import jwtDecode from 'jwt-decode';
import { DecodedToken } from '../types/DecodedToken';

export const tokenDecode = (token?: string): DecodedToken | undefined => {
  if (!token) return undefined;
  return jwtDecode<DecodedToken>(token);
};

export const tokenIsSudo = (token?: string): boolean => {
  const decodedToken = tokenDecode(token);
  return decodedToken?.sudo ?? false;
};
