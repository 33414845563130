import { TableUserProps } from '@rae/ui-library/src/components/table/TableRowUser';
import moment from 'moment';
import { currentTeam } from '../utils/PickTeam';
import { ApiSchema, Identifier } from './ApiSchema';
import { providerData, Team, teamData, TeamFields } from './Team';

export type UserFields = {
  email: string;
  firstName?: string;
  lastName?: string;
  contactPhone?: string;
  teams?: Team[];
};

export type Users = ApiSchema<UserFields>;

export type UserFieldsRequest = Omit<UserFields, 'teams'> & {
  teams?: { _id: string; role: string };
};

export type UserDeleteFieldsRequest = Omit<UserFields, 'teams' | 'email'> & {
  teams?: string;
  email?: string;
};

export type UserEditFieldsRequest = Omit<UserFields, 'email' | 'teams'> & {
  email?: string;
  teams?: Identifier & TeamFields;
};

export type UsersParams = {
  search?: string;
  teamId?: string;
  subTeam?: boolean;
  owner?: boolean;
};

export const RAE_ID = '645b6778640b27b1d09c32bd';

export const usersData = (user?: Users[]): TableUserProps[] => {
  return (
    user?.map(u => ({
      _id: u._id,
      email: u.email,
      firstName: u?.firstName,
      lastName: u?.lastName,
      phone: u?.contactPhone,
      team: u?.teams ? providerData(currentTeam(u.teams)) : undefined,
      userType: providerData(currentTeam(u.teams))._id === RAE_ID ? 'rae' : 'provider',
      modDate: moment(u.updatedAt).format('DD/MM/YYYY'),
      modEmail: '',
    })) ?? []
  );
};

export const addUserData = (user?: TableUserProps): UserFieldsRequest => {
  return {
    email: user?.email ? user?.email : '',
    contactPhone: user?.phone,
    firstName: user?.firstName,
    lastName: user?.lastName,
    teams: {
      _id: user?.team?._id ? user?.team?._id : '',
      role: user?.team?.role ? user?.team?.role : '',
    },
  };
};

export const deleteMemberFields = (
  user?: TableUserProps,
): Identifier & UserDeleteFieldsRequest => {
  return {
    _id: user?._id ? user?._id : '',
    teams: user?.team?._id,
  };
};

export const editMemberFields = (
  user?: TableUserProps,
): Identifier & UserEditFieldsRequest => {
  return {
    _id: user?._id ? user?._id : '',
    teams: teamData(user?.team),
  };
};
