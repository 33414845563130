import { ApiSchema, Identifier } from './ApiSchema';
import moment from 'moment';
import { TableAvrgConsProps } from '@rae/ui-library/src/components/table/TableRowAvrgCons';
import {
  ProviderIdElectric,
  providerIdElectricTableSingleData,
} from './ProviderIdElectric';

export type AvrgConsumptionElectricFields = {
  providerId: ProviderIdElectric;
  singlePhaseDay: number;
  threePhaseDay: number;
  singlePhaseNight?: number;
  threePhaseNight?: number;
};

export type AvrgConsumptionElectric = ApiSchema<AvrgConsumptionElectricFields>;

export type AvrgConsumptionElectricFieldsRequest = Omit<
  AvrgConsumptionElectricFields,
  'providerId'
> & {
  providerId: string;
};

export const avrgConsumptionElectricData = (
  cons?: AvrgConsumptionElectric[],
): TableAvrgConsProps[] => {
  return (
    cons?.map(c => ({
      _id: c._id,
      providerId: providerIdElectricTableSingleData(c.providerId),
      singlePhaseDay: c.singlePhaseDay,
      threePhaseDay: c.threePhaseDay,
      singlePhaseNight: c.singlePhaseNight,
      threePhaseNight: c.threePhaseNight,
      modDate: moment(c.createdAt).format('DD/MM/YYYY'),
      modEmail: '',
    })) ?? []
  );
};

export const addAvrgConsumptionElectricData = (
  obj: TableAvrgConsProps,
): AvrgConsumptionElectricFieldsRequest => {
  return {
    providerId: obj.providerId._id ? obj.providerId._id : '',
    singlePhaseDay: obj.singlePhaseDay,
    threePhaseDay: obj.threePhaseDay,
    singlePhaseNight: obj.singlePhaseNight,
    threePhaseNight: obj.threePhaseNight,
  };
};

export const editAvrgConsumptionElectricData = (
  obj: TableAvrgConsProps,
): Identifier & AvrgConsumptionElectricFieldsRequest => {
  return {
    _id: obj._id ? obj._id : '',
    providerId: obj.providerId._id ? obj.providerId._id : '',
    singlePhaseDay: obj.singlePhaseDay,
    threePhaseDay: obj.threePhaseDay,
    singlePhaseNight: obj.singlePhaseNight,
    threePhaseNight: obj.threePhaseNight,
  };
};
