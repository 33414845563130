import { Box } from '@mui/material';
import React, { useCallback } from 'react';
import BackGround from '../assets/loginBG.png';
import { NotMemberCard } from '../components/NotMemberPage/NotMemberCard';
import { useLogoutMutation } from '../redux/apis/authApi';
import { useNavigate } from 'react-router-dom';

export const NotMemberPage = () => {
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();

  const handleLogout = useCallback(async () => {
    try {
      await logout().unwrap();
      navigate('/login');
    } catch (e) {
      console.log(e);
    }
  }, [logout, navigate]);

  return (
    <Box
      sx={{
        height: '100vh',
        backgroundImage: `url(${BackGround})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        filter: `drop-shadow(-26px 0px 10px rgba(190, 190, 190, 0.06))`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <NotMemberCard handleAction={handleLogout} />
    </Box>
  );
};
