import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import {
  BreadCrumbsCustom,
  CustomChip,
  CustomTable,
  PopupConfirm,
  PopupForm,
  Tooltip,
} from '@rae/ui-library';
import { CoefficientClausesTabs } from '../components/ClausesPage/TabsDialog';
import { useCallback, useMemo, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TableClauseCoefficientProps } from '@rae/ui-library/src/components/table/TableRowClauseCoefficient';
import { HeadCell, OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import {
  useAddClauseCoefficientMutation,
  useDeleteClauseCoefficientMutation,
  useEditClauseCoefficientMutation,
  useGetClauseCoefficientQuery,
} from '../redux/apis/clauseCoefficientApi';
import { pageToSkipLimit } from '../types/ApiPagination';
import {
  addClauseFields,
  ClauseCoefficientMethodologyEnum,
  clauseCoefficientMethodologyLabel,
  editClauseFields,
  gasClausesData,
} from '../types/ClauseCoefficient';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { setLog } from '../redux/slices/appSlice';
import Loader from '../assets/loading.gif';
import useDebounce from '../utils/useDebounce';
import { useNavigate } from 'react-router-dom';

export const ClausesPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const dispatch = useDispatch<AppDispatch>();

  const [selectedCoef, setSelectedCoef] = useState<string[]>([]);
  const [searchCoefficient, setSearchCoefficient] = useState<string>('');
  const [openTypeCoef, setOpenTypeCoef] = useState<boolean>(false);
  const [selectTypeCoef, setSelectTypeCoef] = useState<string[]>([]);
  const [openMeth, setOpenMeth] = useState<boolean>(false);
  const [selectMeth, setSelectMeth] = useState<string[]>([]);
  const [openDialogCoefficient, setOpenDialogCoefficient] = useState<boolean>(false);
  const [openCoefficientDialog, setOpenCoefficientDialog] = useState<boolean>(false);

  const [order, setOrder] = useState<OrderProps>('asc');
  const [orderBy, setOrderBy] = useState<HeadCell['id']>('updatedAt');
  const [page, setPage] = useState<number>(0);

  const [typeCoefficient, setTypeCoefficient] = useState<'add' | 'edit'>('add');

  const [coefficientTab, setCoefficientTab] = useState<TableClauseCoefficientProps>({});

  const debouncedSearch = useDebounce<string>(searchCoefficient, 300);

  const { data: clauses, isLoading: getClausesIsLoading } = useGetClauseCoefficientQuery({
    ...pageToSkipLimit(page, 5, [`${order === 'asc' ? '-' : ''}${orderBy}`]),
    search: debouncedSearch ? debouncedSearch : undefined,
    energyType: selectTypeCoef[0] ? selectTypeCoef[0] : undefined,
    methodologyFilter: selectMeth.length > 0 ? selectMeth : undefined,
  });

  const [addClause, { isLoading: addClauseIsLoading }] =
    useAddClauseCoefficientMutation();

  const [editClause, { isLoading: editClauseIsLoading }] =
    useEditClauseCoefficientMutation();

  const [deleteClause, { isLoading: deleteClauseIsLoading }] =
    useDeleteClauseCoefficientMutation();

  const isLoading =
    getClausesIsLoading ||
    addClauseIsLoading ||
    editClauseIsLoading ||
    deleteClauseIsLoading;

  const dropDowns = [
    {
      choices: [
        { label: 'Ηλεκτρική Ενέργεια', value: 'electric' },
        { label: 'Φυσικό Αέριο', value: 'gas' },
      ],
      open: openTypeCoef,
      selected: selectTypeCoef,
      setOpen: setOpenTypeCoef,
      setSelected: setSelectTypeCoef,
      label: 'Τύπος',
      multiple: false,
      labelBtn: 'Εφαρμογή',
    },
    {
      choices: [
        {
          label: clauseCoefficientMethodologyLabel(
            ClauseCoefficientMethodologyEnum.Alternative,
          ).gr,
          value: ClauseCoefficientMethodologyEnum.Alternative,
        },
        {
          label: clauseCoefficientMethodologyLabel(ClauseCoefficientMethodologyEnum.M409)
            .gr,
          value: ClauseCoefficientMethodologyEnum.M409,
        },
      ],
      open: openMeth,
      selected: selectMeth,
      setOpen: setOpenMeth,
      setSelected: setSelectMeth,
      label: 'Μεθοδολογία',
      multiple: true,
      labelBtn: 'Εφαρμογή',
    },
  ];

  const handleDeleteCoefficient = useCallback((obj: TableClauseCoefficientProps) => {
    setOpenDialogCoefficient(true);
    setCoefficientTab(obj);
  }, []);

  const handleEditRowCoefficient = useCallback((obj: TableClauseCoefficientProps) => {
    setTypeCoefficient('edit');
    setOpenCoefficientDialog(true);
    setCoefficientTab(obj);
  }, []);

  const handleAddRowCoefficient = useCallback(() => {
    setTypeCoefficient('add');
    setOpenCoefficientDialog(true);
    setCoefficientTab({
      clauseCoefficientName: '',
      clauseInformation: '',
      modDate: '',
      modEmail: '',
    });
  }, []);

  const filtersCoef = useMemo(
    () =>
      selectedCoef.concat(
        selectMeth,
        selectTypeCoef,
        searchCoefficient !== '' ? searchCoefficient : [],
      ),
    [searchCoefficient, selectMeth, selectTypeCoef, selectedCoef],
  );

  const deleteChipCoef = useCallback(
    (chipLabel: string) => {
      setSelectedCoef(selectedCoef.filter(label => label !== chipLabel));
      setSelectTypeCoef(selectTypeCoef.filter(label => label !== chipLabel));
      setSelectMeth(selectMeth.filter(label => label !== chipLabel));
      if (searchCoefficient === chipLabel) {
        setSearchCoefficient('');
      }
    },
    [searchCoefficient, selectMeth, selectTypeCoef, selectedCoef],
  );

  const clearFiltersCoefficient = useCallback(() => {
    setSearchCoefficient('');
    setSelectMeth([]);
    setSelectTypeCoef([]);
  }, []);

  const handleSaveCoefficient = useCallback(async () => {
    switch (typeCoefficient) {
      case 'add':
        if (
          !coefficientTab ||
          !coefficientTab.price ||
          !coefficientTab.energyType ||
          !coefficientTab.clauseCoefficientName
        )
          return;
        const addFields = addClauseFields(coefficientTab);
        try {
          await addClause(addFields).unwrap();
          dispatch(
            setLog({
              severity: 'success',
              message: `Η ρήτρα αναπροσαρμογής προστέθηκε επιτυχώς`,
            }),
          );
        } catch (e: any) {
          dispatch(
            setLog({
              severity: 'error',
              message: `${e.data.message}`,
            }),
          );
        }
        setOpenCoefficientDialog(false);
        setCoefficientTab({});
        break;
      case 'edit':
        if (
          !coefficientTab ||
          !coefficientTab._id ||
          !coefficientTab.price ||
          !coefficientTab.energyType ||
          !coefficientTab.clauseCoefficientName
        )
          return;
        const editFields = editClauseFields(coefficientTab);
        try {
          await editClause(editFields).unwrap();
          dispatch(
            setLog({
              severity: 'success',
              message: `Η ρήτρα αναπροσαρμογής επεξεργάστηκε επιτυχώς`,
            }),
          );
        } catch (e: any) {
          dispatch(
            setLog({
              severity: 'error',
              message: `${e.data.message}`,
            }),
          );
        }
        setOpenCoefficientDialog(false);
        setCoefficientTab({});
        break;
      default:
        break;
    }
  }, [addClause, coefficientTab, dispatch, editClause, typeCoefficient]);

  const handleDelete = useCallback(() => {
    if (!coefficientTab || !coefficientTab._id) return;
    try {
      deleteClause({ _id: coefficientTab._id }).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: `Η ρήτρα αναπροσαρμογής διαγράφθηκε επιτυχώς`,
        }),
      );
    } catch (e: any) {
      dispatch(
        setLog({
          severity: 'error',
          message: `${e.data.message}`,
        }),
      );
    }
    setOpenDialogCoefficient(false);
    setCoefficientTab({});
  }, [coefficientTab, deleteClause, dispatch]);

  const links = [
    { name: 'Διαχείριση', href: '/' },
    { name: 'Διαχείριση Ρητρών Αναπροσαρμογής' },
  ];

  const filterLabel = useCallback((label: string) => {
    switch (label) {
      case 'electric':
        return 'Ηλεκτρική Ενέργεια';
      case 'gas':
        return 'Φυσικό Αέριο';
      case 'alternative':
        return 'Εναλλακτική';
      default:
        return label;
    }
  }, []);

  const disableSave = useMemo(() => {
    return (
      !coefficientTab.clauseCoefficientName ||
      !coefficientTab.clauseCoefficientNameEn ||
      !coefficientTab.energyType ||
      !coefficientTab.price ||
      (coefficientTab.energyType === 'electric' &&
        coefficientTab.methodology?.length === 0)
    );
  }, [
    coefficientTab.clauseCoefficientName,
    coefficientTab.clauseCoefficientNameEn,
    coefficientTab.energyType,
    coefficientTab.methodology,
    coefficientTab.price,
  ]);

  return (
    <Container maxWidth={'xl'} sx={{ p: 2 }}>
      <Box sx={{ mt: 4 }}>
        <BreadCrumbsCustom maxItems={matches ? 3 : 1} navigate={navigate} links={links} />
      </Box>

      <Box sx={{ mt: 4 }}>
        {filtersCoef.length > 0 ? (
          <Tooltip
            text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
            type="info"
            btnAction={clearFiltersCoefficient}
            btnLabel="Καθαρισμός Φίλτρων"
            dismiss={true}
            icon={<InfoOutlinedIcon fontSize="small" />}
          />
        ) : null}
      </Box>
      <Box sx={{ mt: 2 }}>
        {filtersCoef.map((chiplabel, i) => {
          return (
            <CustomChip
              key={i}
              label={filterLabel(chiplabel)}
              onDelete={() => deleteChipCoef(chiplabel)}
            />
          );
        })}
      </Box>

      <Box sx={{ mt: 4 }}>
        {!clauses?.documents && isLoading ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img style={{ width: '140px' }} src={Loader} alt="loading..." />
          </Box>
        ) : (
          <CustomTable
            title="Συντελεστής Ρήτρας"
            handleView={() => {}}
            handleEdit={(obj: TableClauseCoefficientProps) =>
              handleEditRowCoefficient(obj)
            }
            handleDelete={(obj: TableClauseCoefficientProps) =>
              handleDeleteCoefficient(obj)
            }
            buttonProps={{
              variant: 'contained',
              onClick: handleAddRowCoefficient,
              children: 'Προσθήκη Συντελεστή Ρήτρας',
            }}
            searchInput={{
              onChange: (e: any) => setSearchCoefficient(e.target.value),
              value: searchCoefficient,
              type: 'search',
              label: 'Αναζήτηση ',
            }}
            rows={gasClausesData(clauses?.documents)}
            type={'clause-coefficient'}
            count={clauses?.count ?? 0}
            headCells={[
              {
                disable: false,
                id: 'clauseCoefficientName',
                label: 'Συντελεστής Ρήτρας',
              },
              { disable: false, id: 'price', label: 'Τιμή' },
              { disable: false, id: 'methodology', label: 'Μεθοδολογία' },
              { disable: false, id: 'energyType', label: 'Τύπος' },
              { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
              { disable: true, id: 'actions', label: 'Ενέργειες' },
            ]}
            dropDownsNew={dropDowns}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            page={page}
            rowsPerPage={5}
            setPage={setPage}
          />
        )}
      </Box>

      <PopupForm
        maxWidth="md"
        InfoTootipProps={undefined}
        title={
          typeCoefficient === 'add'
            ? 'Προσθήκη Συντελεστή Ρήτρας'
            : 'Επεξεργασία Συντελεστή Ρήτρας'
        }
        helpText="Διαχείρηση των συντελεστών της ρήτρας αναπροσαρμογής/ καταχώρηση νέων συντελεστών."
        leftBtnTitle="Ακύρωση"
        rightBtnTitle={typeCoefficient === 'add' ? 'Προσθήκη' : 'Αποθήκευση'}
        open={openCoefficientDialog}
        setOpen={() => setOpenCoefficientDialog(true)}
        onClose={() => setOpenCoefficientDialog(false)}
        onClickLeftBtn={() => setOpenCoefficientDialog(false)}
        onClickRightBtn={handleSaveCoefficient}
        rightBtnProps={{ disabled: disableSave }}
      >
        <CoefficientClausesTabs
          setTab={setCoefficientTab}
          tab={coefficientTab}
          type={typeCoefficient}
        />
      </PopupForm>

      <PopupConfirm
        title="Διαγραφή Συντελεστή Ρήτρας"
        subTile="Είστε σίγουρος ότι θέλετε να προχωρήσετε με τη διαγραφή του Συντελεστή Ρήτρας; Τιμολόγια που κάνουν χρήση του συγκεκριμένου συντελεστή ρήτρας θα διαγραφούν και αυτά και ΔΕΝ υπάρχει δυνατότητα επαναφοράς."
        leftBtnTitle="Οχι"
        rightBtnTitle="Ναι"
        open={openDialogCoefficient}
        setOpen={() => setOpenDialogCoefficient(true)}
        onClose={() => setOpenDialogCoefficient(false)}
        onClickLeftBtn={() => setOpenDialogCoefficient(false)}
        onClickRightBtn={handleDelete}
      />
    </Container>
  );
};
