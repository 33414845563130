import { ApiSchema, Identifier } from './ApiSchema';
import { ProductGas } from './ProductGas';
import { ProductLinked } from './ProductLinked';
import { ProductElectric } from './ProductElectric';
import {
  ReviewStatus,
  reviewStatusColor,
  ReviewStatusEnum,
  reviewStatusLabel,
} from './ReviewStatus';
import { TableMainRaeProps } from '@rae/ui-library/src/components/table/TableRowMainRae';
import { TableMainProviderProps } from '@rae/ui-library/src/components/table/TableRowMainProvider';
import { storageFileToFile } from './StorageFile';
import { providerStatus, providerStatusColor, providerStatusLabel } from './Team';
import moment from 'moment';

export type ProductType = 'electric' | 'gas' | 'linked';
export type ProductSchemaType = 'ProductElectric' | 'ProductGas' | 'ProductLinked';

export type ProductFields = (ProductElectric | ProductGas | ProductLinked) & {
  type: ProductSchemaType;
  versions: (ProductElectric | ProductGas | ProductLinked)[];
};

export type Product = ApiSchema<ProductFields>;

export type ProductParams = {
  team?: string;
  reviewStatus?: ReviewStatus;
  teamSuspended?: boolean;
  type?: ProductType[];
  search?: string;
};
export type ProductDeleteParams = {
  type: ProductType;
};
export type ProductIdParams = Identifier & {
  type: ProductType;
};
export type ProductCompetitiveFeesHistoricityParams = {
  type: ProductType;
  competitiveFees?: string;
  categoryOfSupply?: string;
  team?: string;
};
export type ProductOtherChargesHistoricityParams = {
  type: ProductType;
  otherCharges?: string;
  categoryOfSupply?: string;
  team?: string;
};

export const productInComparisonTool = (
  product: Product,
): 'success' | 'error' | 'warning' => {
  if (product.productInfo.team.suspended) return 'error';
  if (product.reviewStatus === ReviewStatusEnum.Accepted) return 'success';
  if (
    product.versions.some(version => version.reviewStatus === ReviewStatusEnum.Accepted)
  )
    return 'warning';

  return 'error';
};

export const getProductRaeData = (products?: Product[]): TableMainRaeProps[] =>
  products?.map(product => {
    const data: TableMainRaeProps = {
      _id: product._id,
      invoiceName: product.productInfo?.nameGr,
      invoiceDates: moment(product.productInfo.availabilityDate).format('DD/MM/YYYY'),
      providerName: product.productInfo?.team?.nameGr,
      availInvoices:
        product.versions.length > 0 ? product.versions.length + 1 : undefined,
      providerImg: storageFileToFile(product.productInfo.team.image),
      providerImgUrl: product.productInfo.team.image?.url,
      providerImgId: product.productInfo.team.image?._id,
      providerStatus: providerStatusLabel(providerStatus(product.productInfo.team)),
      providerStatusColor: providerStatusColor(providerStatus(product.productInfo.team)),
      comparisonTool: productInComparisonTool(product),
      invoiceStatus: reviewStatusLabel(product.reviewStatus),
      invoiceStatusColor: reviewStatusColor(product.reviewStatus),
      modDate: moment(product.updatedAt).format('DD/MM/YYYY'),
      // modEmail?: string; //TODO :: add mod email in schemas
      type: product.type,
    };
    if (product.type === 'ProductElectric') {
      const productElectric = product as ProductElectric;
      data.transparencyType = productElectric.productCompetitiveFees.transparencyType;
    }
    return data;
  }) ?? [];

export const getProductProviderData = (products?: Product[]): TableMainProviderProps[] =>
  products?.map(product => {
    const data: TableMainProviderProps = {
      _id: product._id,
      invoiceName: product.productInfo?.nameGr,
      invoiceDates: moment(product.productInfo.availabilityDate).format('DD/MM/YYYY'),
      availInvoices:
        product.versions.length > 0 ? product.versions.length + 1 : undefined,
      comparisonTool: productInComparisonTool(product),
      invoiceStatus: reviewStatusLabel(product.reviewStatus),
      invoiceStatusColor: reviewStatusColor(product.reviewStatus),
      modDate: moment(product.updatedAt).format('DD/MM/YYYY'),
      // modEmail?: string; //TODO :: add mod email in schemas
      type: product.type,
    };
    if (product.type === 'ProductElectric') {
      const productElectric = product as ProductElectric;
      data.transparencyType = productElectric.productCompetitiveFees.transparencyType;
    }
    return data;
  }) ?? [];

export const productTypeLabel = (type?: ProductType): string => {
  switch (type) {
    case 'electric':
      return 'Ηλεκτρική Ενέργεια';
    case 'gas':
      return 'Φυσικό Αέριο';
    case 'linked':
      return 'Συνδυαστικά';
    default:
      return '';
  }
};
