import { TableProviderIdProps } from '@rae/ui-library/src/components/table/TableRowProviderId';
import { ApiSchema } from './ApiSchema';
import { CategoryOfSupply, categoryOfSupplyGasLabel } from './CategoryOfSupply';
import { Measurement, measurementGasLabel } from './Measurement';

export type ProviderIdGasFields = {
  measurement: Measurement;
  categoryOfSupply: CategoryOfSupply;
  archived?: boolean;
};

export type ProviderIdGas = ApiSchema<ProviderIdGasFields>;

export const providerIdGasTableData = (
  providers?: ProviderIdGas[],
): TableProviderIdProps[] => {
  return (
    providers?.map(provider => ({
      _id: provider._id,
      measurement: measurementGasLabel(provider.measurement).gr,
      measurementEn: measurementGasLabel(provider.measurement).en,
      categoryOfSupply: categoryOfSupplyGasLabel(provider.categoryOfSupply).gr,
      categoryOfSupplyEn: categoryOfSupplyGasLabel(provider.categoryOfSupply).en,
      modDate: new Date(provider.updatedAt).toLocaleDateString(),
      modEmail: undefined,
    })) ?? []
  );
};

export const providerIdGasTableSingleData = (
  provider?: ProviderIdGas,
): TableProviderIdProps => {
  return {
    _id: provider?._id,
    measurement: provider ? measurementGasLabel(provider.measurement).gr : '',
    measurementEn: provider ? measurementGasLabel(provider.measurement).en : '',
    categoryOfSupply: provider
      ? categoryOfSupplyGasLabel(provider.categoryOfSupply).gr
      : '',
    categoryOfSupplyEn: provider
      ? categoryOfSupplyGasLabel(provider.categoryOfSupply).en
      : '',
    modDate: provider ? new Date(provider.updatedAt).toLocaleDateString() : '',
    modEmail: undefined,
  };
};
