import { Team } from '../types/Team';
import { RAE_ID } from '../types/User';

export const currentTeam = (teams?: Team[]): Team | undefined => {
  return teams?.find(team => team._id === RAE_ID) ?? teams?.[0];
};

export const isRaeTeam = (team?: Team): boolean => {
  return team?.name?.toLowerCase() === 'rae';
};
