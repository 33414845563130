import { Box, Container, Divider } from '@mui/material';
import { BreadCrumbsCustom, ButtonRae, CustomTabs } from '@rae/ui-library';
import React, { useCallback, useEffect, useState } from 'react';
import { HomeTabEl } from '../components/SpecialDiscountPage/electricTabs/homeTabEl';
import { KotTab } from '../components/SpecialDiscountPage/electricTabs/KotTab';
import { ProfessionallyTab } from '../components/SpecialDiscountPage/electricTabs/ProfessionallyTab';
import { CentralTab } from '../components/SpecialDiscountPage/gasTabs/CentralTab';
import { HomeGas } from '../components/SpecialDiscountPage/gasTabs/HomeGas';
import { ProfessionallyTabGas } from '../components/SpecialDiscountPage/gasTabs/ProfessionallyGasTab';
import {
  useEditSpecialDiscountElectricMutation,
  useEditSpecialDiscountGasMutation,
  useGetSpecialDiscountElectricQuery,
  useGetSpecialDiscountGasQuery,
} from '../redux/apis/specialDiscountApi';
import { pageToSkipLimit } from '../types/ApiPagination';
import {
  specialDiscountElectricData,
  submitSpecialDiscountElectricData,
} from '../types/SpecialDiscountElectric';
import {
  specialDiscountGasData,
  submitSpecialDiscountGasData,
} from '../types/SpecialDiscountGas';
import Loader from '../assets/loading.gif';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { setLog } from '../redux/slices/appSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';

export type SpecialDiscProps = {
  electric?: {
    _id?: string;
    home?: {
      showDisc?: boolean;
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    professionaly?: {
      showDisc?: boolean;
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    kot?: {
      showDisc?: boolean;
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
  };
  gas?: {
    _id?: string;
    home?: {
      showDisc?: boolean;
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    professionaly?: {
      showDisc?: boolean;
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    central?: {
      showDisc?: boolean;
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
  };
};

export const SpecialDiscounts = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: specialDiscountElectric, isLoading: electricLoading } =
    useGetSpecialDiscountElectricQuery(pageToSkipLimit(0, 1, [`-updatedAt`]));

  const { data: specialDiscountGas, isLoading: gasLoading } =
    useGetSpecialDiscountGasQuery(pageToSkipLimit(0, 1, [`-updatedAt`]));

  const [editSpecialDiscountElectric, { isLoading: editElectricLoading }] =
    useEditSpecialDiscountElectricMutation();
  const [editSpecialDiscountGas, { isLoading: editGasLoading }] =
    useEditSpecialDiscountGasMutation();

  const [data, setData] = useState<SpecialDiscProps>({});

  const isLoading =
    electricLoading || gasLoading || editElectricLoading || editGasLoading;

  useEffect(() => {
    setData((val: SpecialDiscProps) => ({
      ...val,
      electric: specialDiscountElectricData(specialDiscountElectric?.documents?.[0]),
    }));
  }, [specialDiscountElectric]);

  useEffect(() => {
    setData((val: SpecialDiscProps) => ({
      ...val,
      gas: specialDiscountGasData(specialDiscountGas?.documents?.[0]),
    }));
  }, [specialDiscountGas]);

  const links = [{ name: 'Διαχείριση', href: '/' }, { name: 'Έκτακτες Εκπτώσεις' }];

  const handleResetElectric = useCallback(
    () =>
      setData(data => ({
        ...data,
        electric: specialDiscountElectricData(specialDiscountElectric?.documents?.[0]),
      })),
    [specialDiscountElectric?.documents],
  );

  const handleResetGas = useCallback(
    () =>
      setData(data => ({
        ...data,
        gas: specialDiscountGasData(specialDiscountGas?.documents?.[0]),
      })),
    [specialDiscountGas?.documents],
  );

  const tabsEL = [
    {
      tabName: 'Οικιακή',
      tabContent: (
        <HomeTabEl handleReset={handleResetElectric} data={data} setData={setData} />
      ),
    },
    {
      tabName: 'Επαγγελματική / Κοινόχρηστη',
      tabContent: (
        <ProfessionallyTab
          handleReset={handleResetElectric}
          data={data}
          setData={setData}
        />
      ),
    },
    {
      tabName: 'Δικαιούχοι ΚΟΤ',
      tabContent: (
        <KotTab handleReset={handleResetElectric} data={data} setData={setData} />
      ),
    },
  ];

  const tabsGas = [
    {
      tabName: 'Κεντρική',
      tabContent: (
        <CentralTab handleReset={handleResetGas} data={data} setData={setData} />
      ),
    },
    {
      tabName: 'Οικιακή (Αυτόνομη)',
      tabContent: <HomeGas handleReset={handleResetGas} data={data} setData={setData} />,
    },
    {
      tabName: 'Επαγγελματική (Αυτόνομη)',
      tabContent: (
        <ProfessionallyTabGas
          handleReset={handleResetGas}
          data={data}
          setData={setData}
        />
      ),
    },
  ];

  const tabs = [
    {
      tabName: 'Έκτακτες Εκπτώσεις Ηλεκτρικού Ρεύματος',
      tabContent: <CustomTabs tabs={tabsEL} chipStyle={true} newTab={0} />,
    },
    {
      tabName: 'Έκτακτες Εκπτώσεις Φυσικού Αέριου',
      tabContent: <CustomTabs tabs={tabsGas} chipStyle={true} newTab={0} />,
    },
  ];

  const onSave = useCallback(async () => {
    if (!data) return;
    const editFieldsElectric = submitSpecialDiscountElectricData(data.electric);
    const editFieldsGas = submitSpecialDiscountGasData(data.gas);

    try {
      await Promise.all([
        data.electric?._id
          ? editSpecialDiscountElectric(editFieldsElectric).unwrap()
          : null,
        data.gas?._id ? editSpecialDiscountGas(editFieldsGas).unwrap() : null,
      ]);
      dispatch(
        setLog({
          severity: 'success',
          message: `Επιτυχής αποθήκευση`,
        }),
      );
    } catch (error) {
      console.log(error);
    }
  }, [data, dispatch, editSpecialDiscountElectric, editSpecialDiscountGas]);

  const onReset = useCallback(() => {
    setData({
      electric: specialDiscountElectricData(specialDiscountElectric?.documents?.[0]),
      gas: specialDiscountGasData(specialDiscountGas?.documents?.[0]),
    });
  }, [specialDiscountElectric?.documents, specialDiscountGas?.documents]);

  return (
    <Container maxWidth={'xl'} sx={{ p: 2 }}>
      <Box sx={{ mt: 4 }}>
        <BreadCrumbsCustom navigate={navigate} links={links} />
      </Box>
      {isLoading &&
      !specialDiscountElectric?.documents &&
      !specialDiscountElectric?.documents ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img style={{ width: '140px' }} src={Loader} alt="loading..." />
        </Box>
      ) : (
        <>
          <Box sx={{ mt: 4, minHeight: { xs: 100, md: 600 } }}>
            <CustomTabs
              tabs={tabs}
              newTab={Number(searchParams.get('t') ?? 0)}
              setActive={i => setSearchParams({ ...searchParams, t: i.toString() })}
            />
          </Box>
          <Divider />
          <Box sx={{ textAlign: 'right', p: 2 }}>
            <ButtonRae onClick={onReset} variant="contained">
              Επαναφορά
            </ButtonRae>
            <ButtonRae onClick={onSave} sx={{ ml: 2 }} variant="contained">
              Αποθήκευση
            </ButtonRae>
          </Box>
        </>
      )}
    </Container>
  );
};
