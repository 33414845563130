import { Box, Grid } from '@mui/material';
import React from 'react';
import { CustomTabs, CheckBoxes, Input, DropZone, RadioButtonNew } from '@rae/ui-library';
import { CheckBoxesProps } from '@rae/ui-library/src/components/CheckBox';
import { TableProviderProps } from '@rae/ui-library/src/components/table/TableRowProvider';

export type DialogProps = {
  setProvider: (newState: any) => void;
  provider: TableProviderProps;
  file?: File;
  setFile: (newState?: File) => void;
};

export const ProviderModal = ({ provider, setProvider, file, setFile }: DialogProps) => {
  const checkBoxes: CheckBoxesProps['checkboxes'] = [
    {
      label: 'Ηλεκτρική ενέργεια',
      icon: 'electric',
      value: 'electric',
    },
    {
      label: 'Φυσικο Αέριο',
      icon: 'gas',
      value: 'gas',
    },
  ];

  const checkBoxesEn: CheckBoxesProps['checkboxes'] = [
    {
      label: 'Electricity',
      icon: 'electric',
      value: 'electric',
    },
    {
      label: 'Natural Gas',
      icon: 'gas',
      value: 'gas',
    },
  ];

  const tabEl: React.ReactNode = (
    <Grid
      container
      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
    >
      <Grid item xs={12} md={7} className="left_container">
        <Box sx={{ mt: 1 }}>
          <CheckBoxes
            label="Τύπος:"
            checked={provider?.services ? provider?.services : []}
            setChecked={(services: any) =>
              setProvider((values: TableProviderProps) => ({
                ...values,
                services: services,
              }))
            }
            checkboxes={checkBoxes}
          />
        </Box>
        <Box sx={{ mt: 1 }}>
          <RadioButtonNew
            label="Προσβαση:"
            radio={[
              { label: 'Ενεργοποιημένος', value: false },
              { label: 'Σε διαθεσιμότητα', value: true },
            ]}
            value={provider.suspended}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setProvider((values: TableProviderProps) => ({
                ...values,
                suspended: (event.target as HTMLInputElement).value,
              }))
            }
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            mt: 3,
          }}
        >
          <Input
            sx={{ mt: 2 }}
            value={provider.providerName}
            onChange={e =>
              setProvider((values: TableProviderProps) => ({
                ...values,
                providerName: e.target.value,
              }))
            }
            type="default"
            label="Πάροχος"
          />

          <Input
            sx={{ mt: 2 }}
            value={provider.url}
            onChange={e =>
              setProvider((values: TableProviderProps) => ({
                ...values,
                url: e.target.value,
              }))
            }
            type="default"
            label="Σύνδεσμος URL"
          />

          <Input
            sx={{ mt: 2 }}
            value={provider.phone}
            onChange={e =>
              setProvider((values: TableProviderProps) => ({
                ...values,
                phone: e.target.value,
              }))
            }
            inputType="number"
            type="default"
            label="Τηλέφωνο Επικοινωνίας"
          />
        </Box>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'start' }}>
          <Input
            value={provider.infoProvider}
            onChange={e =>
              setProvider((values: TableProviderProps) => ({
                ...values,
                infoProvider: e.target.value,
              }))
            }
            type="default"
            multiline={true}
            label="Πληροφορίες σχετικά με τον Πάροχο"
            rows={4}
            sx={{ width: '100%' }}
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: { xs: 2, md: 0 },
        }}
        className="right_container"
      >
        <Input
          disabled={true}
          value={file ? provider.providerImg?.name : ''}
          type="upload"
          subText="file must be in .png format, up to 2mb"
          label="Λογότυπο"
        />
        <Box
          sx={{
            mt: 2,
            height: { xs: '200px', md: '300px' },
            width: { xs: '200px', md: '300px' },
          }}
        >
          <DropZone
            file={file}
            imgURL={provider.providerImgUrl}
            setFile={setFile}
            type="img"
          />
        </Box>
      </Grid>
    </Grid>
  );

  const tabEN: React.ReactNode = (
    <Grid
      container
      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
    >
      <Grid item xs={12} md={7} className="left_container">
        <Box sx={{ mt: 1 }}>
          <CheckBoxes
            label="Energy Source Type:"
            checked={provider?.services ? provider?.services : []}
            setChecked={(services: any) =>
              setProvider((values: TableProviderProps) => ({
                ...values,
                services: services,
              }))
            }
            checkboxes={checkBoxesEn}
          />
        </Box>
        <Box sx={{ mt: 1 }}>
          <RadioButtonNew
            label="Προσβαση:"
            radio={[
              { label: 'Active', value: false },
              { label: 'Suspended', value: true },
            ]}
            value={provider.suspended}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setProvider((values: TableProviderProps) => ({
                ...values,
                suspended: (event.target as HTMLInputElement).value,
              }))
            }
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            mt: 3,
          }}
        >
          <Input
            sx={{ mt: 2 }}
            value={provider.providerNameEn}
            onChange={e =>
              setProvider((values: TableProviderProps) => ({
                ...values,
                providerNameEn: e.target.value,
              }))
            }
            type="default"
            label="Provider"
          />

          <Input
            sx={{ mt: 2 }}
            value={provider.url}
            onChange={e =>
              setProvider((values: TableProviderProps) => ({
                ...values,
                url: e.target.value,
              }))
            }
            type="default"
            label="URL Link"
          />

          <Input
            sx={{ mt: 2 }}
            value={provider.phone}
            onChange={e =>
              setProvider((values: TableProviderProps) => ({
                ...values,
                phone: e.target.value,
              }))
            }
            inputType="number"
            type="default"
            label="Contact number"
          />
        </Box>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'start' }}>
          <Input
            value={provider.infoProviderEn}
            onChange={e =>
              setProvider((values: TableProviderProps) => ({
                ...values,
                infoProviderEn: e.target.value,
              }))
            }
            type="default"
            multiline={true}
            label="Provider Information"
            rows={4}
            sx={{ width: '100%' }}
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: { xs: 2, md: 0 },
        }}
        className="right_container"
      >
        <Input
          disabled={true}
          value={file ? provider.providerImg?.name : ''}
          type="upload"
          subText="file must be in .png format, up to 2mb"
          label="Logo"
        />
        <Box
          sx={{
            mt: 2,
            height: { xs: '200px', md: '300px' },
            width: { xs: '200px', md: '300px' },
          }}
        >
          <DropZone
            file={file}
            imgURL={provider.providerImgUrl}
            setFile={setFile}
            type="img"
          />
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Box>
      <CustomTabs
        newTab={0}
        tabs={[
          { tabName: 'GR', tabContent: tabEl },
          { tabName: 'EN', tabContent: tabEN },
        ]}
      />
    </Box>
  );
};
