import { Box } from '@mui/material';
import { CustomTabs, DropdownNew, Input } from '@rae/ui-library';
import React, { useMemo, useState } from 'react';
import { TableCompFeesProps } from '@rae/ui-library/src/components/table/TableRowCompFees';
import {
  transparencyTypeColor,
  transparencyTypeLabel,
} from '../../types/TransparencyType';

type ObjProps = {
  obj: TableCompFeesProps;
  setObj: (newState: any) => void;
};

export const CompFeesDialog = ({ obj, setObj }: ObjProps) => {
  const [openType, setOpenType] = useState<boolean>(false);

  const GrTab = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'flex-start', md: 'space-around' },
          mt: 3,
        }}
      >
        <Input
          sx={{ mt: 3, width: { xs: '300px', md: '450px' } }}
          disabled
          type="lock"
          label="Ανταγωνιστική Χρέωση"
          value={obj.name}
          onChange={e =>
            setObj((values: TableCompFeesProps) => ({
              ...values,
              name: e.target.value,
            }))
          }
        />
        <DropdownNew
          sx={{ width: { xs: '300px', md: '450px' }, mt: 3 }}
          multiline={false}
          disabled
          label="Σήμανση Διαφάνειας"
          labelBtn="Εφαρμογή"
          choices={[
            {
              label: transparencyTypeLabel('fixed').gr,
              value: 'fixed',
              color: transparencyTypeColor('fixed'),
            },
            {
              label: transparencyTypeLabel('special').gr,
              value: 'special',
              color: transparencyTypeColor('special'),
            },
            {
              label: transparencyTypeLabel('fluctuating').gr,
              value: 'fluctuating',
              color: transparencyTypeColor('fluctuating'),
            },
            {
              label: transparencyTypeLabel('mixed').gr,
              value: 'mixed',
              color: transparencyTypeColor('mixed'),
            },
          ]}
          color={transparencyTypeColor(obj.transparencyType)}
          open={openType}
          setOpen={setOpenType}
          selected={obj.transparencyType ? [obj.transparencyType] : []}
          setSelected={(type: any) => {}}
        />
        <DropdownNew
          sx={{ width: { xs: '300px', md: '450px' }, mt: 3 }}
          multiline={false}
          disabled
          label="Τύπος"
          labelBtn="Εφαρμογή"
          choices={[
            {
              label: 'Ηλεκτρική Ενέργεια',
              value: 'electric',
            },
            {
              label: 'Φυσικό Αέριο',
              value: 'gas',
            },
          ]}
          open={openType}
          setOpen={setOpenType}
          selected={obj.energyType ? [obj.energyType] : []}
          setSelected={(type: any) => {}}
        />
        <Input
          sx={{ mt: 4 }}
          type="default"
          multiline
          minRows={4}
          label="Πληροφορίες Ανταγωνιστικής Χρεώσης"
          value={obj.info}
          onChange={e =>
            setObj((values: TableCompFeesProps) => ({
              ...values,
              info: e.target.value,
            }))
          }
        />
      </Box>
    );
  }, [obj.name, obj.transparencyType, obj.energyType, obj.info, openType, setObj]);

  const EnTab = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'flex-start', md: 'space-around' },
          mt: 3,
        }}
      >
        <Input
          sx={{ mt: 3, width: { xs: '300px', md: '450px' } }}
          disabled
          type="lock"
          label="Competitive Fee"
          value={obj.nameEn}
          onChange={e =>
            setObj((values: TableCompFeesProps) => ({
              ...values,
              nameEn: e.target.value,
            }))
          }
        />
        <DropdownNew
          sx={{ width: { xs: '300px', md: '450px' }, mt: 3 }}
          multiline={false}
          disabled
          label="Σήμανση Διαφάνειας"
          labelBtn="Εφαρμογή"
          choices={[
            {
              label: transparencyTypeLabel('fixed').en,
              value: 'fixed',
              color: transparencyTypeColor('fixed'),
            },
            {
              label: transparencyTypeLabel('special').en,
              value: 'special',
              color: transparencyTypeColor('special'),
            },
            {
              label: transparencyTypeLabel('fluctuating').en,
              value: 'fluctuating',
              color: transparencyTypeColor('fluctuating'),
            },
            {
              label: transparencyTypeLabel('mixed').en,
              value: 'mixed',
              color: transparencyTypeColor('mixed'),
            },
          ]}
          color={transparencyTypeColor(obj.transparencyType)}
          open={openType}
          setOpen={setOpenType}
          selected={obj.transparencyType ? [obj.transparencyType] : []}
          setSelected={(type: any) => {}}
        />
        <DropdownNew
          sx={{ width: { xs: '300px', md: '450px' }, mt: 3 }}
          multiline={false}
          disabled
          label="Type"
          labelBtn="Εφαρμογή"
          choices={[
            {
              label: 'Electric Energy',
              value: 'electric',
            },
            {
              label: 'Gas',
              value: 'gas',
            },
          ]}
          open={openType}
          setOpen={setOpenType}
          selected={obj.energyType ? [obj.energyType] : []}
          setSelected={(type: any) => {}}
        />
        <Input
          sx={{ mt: 4 }}
          type="default"
          multiline
          minRows={4}
          label="Competitive Billing Information"
          value={obj.infoEn}
          onChange={e =>
            setObj((values: TableCompFeesProps) => ({
              ...values,
              infoEn: e.target.value,
            }))
          }
        />
      </Box>
    );
  }, [obj.energyType, obj.infoEn, obj.nameEn, obj.transparencyType, openType, setObj]);

  const tabs = [
    {
      tabName: 'GR',
      tabContent: GrTab,
    },
    {
      tabName: 'EN',
      tabContent: EnTab,
    },
  ];
  return <CustomTabs tabs={tabs} newTab={0} />;
};
