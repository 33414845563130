import { TableDistributionGasMngmentProps } from '@rae/ui-library/src/components/table/TableRowDistributionGasMngment';
import moment from 'moment';
import { ApiSchema, Identifier } from './ApiSchema';
import { AuthUser } from './Auth';
import { GasProvider } from './GasProvider';

export type ManagmentGasNetworksFields = {
  cityGr: string;
  cityEn?: string;
  zone: 'north' | 'south';
  gasProvider?: GasProvider;
  remoteNetwork?: boolean;
  modUser?: AuthUser;
};

export type ManagmentGasNetworksFieldsRequest = Omit<
  ManagmentGasNetworksFields,
  'gasProvider'
> & {
  gasProvider: string;
};

export type ManagmentGasNetworksParams = {
  search?: string;
};

export type ManagmentGasNetworks = ApiSchema<ManagmentGasNetworksFields>;

export const managmentGasNetworksData = (
  network?: ManagmentGasNetworks[],
): TableDistributionGasMngmentProps[] => {
  return (
    network?.map(n => ({
      _id: n._id,
      cityGr: n.cityGr,
      cityEn: n.cityEn,
      gasProvider: {
        _id: n.gasProvider?._id,
        providerName: n.gasProvider?.providerName,
        providerInfo: n.gasProvider?.providerInfo,
        modDate: n.gasProvider?.updatedAt,
        modEmail: '',
      },
      remoteNetwork: n.remoteNetwork,
      zone: n.zone,
      modDate: moment(n.createdAt).format('DD/MM/YYYY'),
      modEmail: n.modUser?.email,
    })) ?? []
  );
};

export const addNetworkGasManagmentFields = (
  obj: TableDistributionGasMngmentProps,
): ManagmentGasNetworksFieldsRequest => {
  return {
    cityGr: obj.cityGr ? obj.cityGr : '',
    cityEn: obj?.cityEn,
    gasProvider: obj.gasProvider?._id ? obj.gasProvider?._id : '',
    zone: obj.zone ? obj.zone : 'north',
    remoteNetwork: obj.remoteNetwork,
  };
};

export const editNetworkGasManagmentFields = (
  obj: TableDistributionGasMngmentProps,
): Identifier & ManagmentGasNetworksFieldsRequest => {
  return {
    _id: obj._id ? obj._id : '',
    cityGr: obj.cityGr ? obj.cityGr : '',
    cityEn: obj?.cityEn,
    gasProvider: obj.gasProvider?._id ? obj.gasProvider?._id : '',
    zone: obj.zone ? obj.zone : 'north',
    remoteNetwork: obj.remoteNetwork,
  };
};
