import { Box, Divider } from '@mui/material';
import { RadioButtonNew } from '@rae/ui-library';
import { TableDiscountProps } from '@rae/ui-library/src/components/table/TableRowDiscounts';
import React, { useMemo } from 'react';
import ChargeScales from '../ChargeScales/ChargeScales';
import { TieredBilling } from '../../types/TieredBilling';

type DiscountBenefitsProps = {
  data?: TableDiscountProps;
  setData?: (type: string, availDisc: string, name: string, value: any) => void;
  handleReset: () => void;
  type?: 'discount' | 'benefit';
  objType: string;
  discName: string;
  disable?: boolean;
};

export const DiscountBenefitsSectionInner = ({
  handleReset,
  setData,
  data,
  type,
  discName,
  objType,
  disable,
}: DiscountBenefitsProps) => {
  const types = useMemo(() => {
    switch (type) {
      case 'discount':
        return [
          { label: 'Ποσό Έκπτωσης', value: 'disc_amount' },
          { label: 'Ποσοστό έκπτωσης', value: 'disc_rate' },
        ];
      // case 'benefit':
      //   return [
      //     { label: 'Ποσό Πρόσθετης Παροχής', value: 'benef_amount' },
      //     { label: 'Ποσοστό Πρόσθετης Παροχής', value: 'benef_ratio' },
      //     { label: 'Άλλη πρόσθετη Παροχή', value: 'benef_other' },
      //   ];
      default:
        return [];
    }
  }, [type]);

  const amountlabel = useMemo(() => {
    switch (data?.radioDisc) {
      // case 'benef_ratio':
      //   return 'Ποσοστό Πρόσθετης Παροχής σε %';
      // case 'benef_amount':
      //   return 'Ποσό Πρόσθετης Παροχής σε €';
      case 'disc_amount':
        return 'Ποσό Έκπτωσης σε €';
      case 'disc_rate':
        return 'Ποσοστό έκπτωσης σε %';
      default:
        return ' σε €';
    }
  }, [data?.radioDisc]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          mt: 3,
          p: 2,
          bgcolor: '#F8F8F8',
          borderRadius: '12px',
        }}
      >
        {type === 'discount' ? (
          <>
            <RadioButtonNew
              label="Τύπος"
              radio={types ? types : []}
              value={data ? data.radioDisc : ''}
              disableRadio={data?.status === 0 || disable}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setData?.(
                  objType,
                  discName,
                  'radioDisc',
                  (event.target as HTMLInputElement).value,
                )
              }
            />
            <Divider orientation="vertical" variant="middle" flexItem sx={{ mr: 2 }} />
          </>
        ) : null}
        <RadioButtonNew
          label="Αφορά πελάτες"
          radio={[
            { label: 'Όλους', value: 'all' },
            { label: 'Υφιστάμενους', value: 'exists' },
            { label: 'Νέους', value: 'new' },
          ]}
          value={data ? data.clients : ''}
          disableRadio={data?.status === 0 || disable}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setData?.(
              objType,
              discName,
              'clients',
              (event.target as HTMLInputElement).value,
            )
          }
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'flex-start', md: 'space-between' },
          mt: 6,
        }}
      >
        {data && data.radioDisc && data.clients ? (
          type === 'benefit' ? null : (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <ChargeScales
                tieredBilling={data.tieredBilling as TieredBilling}
                setTieredBilling={tieredBilling =>
                  setData?.(objType, discName, 'tieredBilling', tieredBilling)
                }
                chargeRows={data.chargeRows}
                setChargeRows={chargeRows =>
                  setData?.(objType, discName, 'chargeRows', chargeRows)
                }
                onReset={handleReset}
                disabled={data?.status === 0 || disable}
                amountLabel={amountlabel}
              />
            </Box>
          )
        ) : null}
      </Box>
    </Box>
  );
};
