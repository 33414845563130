import {
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { ButtonRae, Input } from '@rae/ui-library';
import React, { useState } from 'react';
import { LoginUserProps } from '../../pages/LoginPage';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Loader from '../../assets/loading.gif';

export type ShowLoginErrorProps = {
  email?: boolean;
  password?: boolean;
};

export type LoginErrorMsg = {
  email?: string;
  password?: string;
};

type Props = {
  loginUser: LoginUserProps;
  setLoginUset: (newState: any) => void;
  handleLogin: (event: React.FormEvent<HTMLFormElement>) => void;
  showError: ShowLoginErrorProps;
  errorMsg: LoginErrorMsg;
  isLoading: boolean;
};

export const LoginCard = ({
  loginUser,
  setLoginUset,
  handleLogin,
  errorMsg,
  showError,
  isLoading,
}: Props) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Paper
      sx={{
        width: 492,
        height: 400,
        borderRadius: '16px',
        p: 4,
        position: 'relative',
      }}
      elevation={3}
    >
      {!isLoading ? (
        <form onSubmit={handleLogin}>
          <Box>
            <Box>
              <Typography
                sx={{ minWidth: '200px' }}
                color={theme.palette.primary.main}
                variant="h5"
              >
                Είσοδος
              </Typography>
              <Divider
                sx={{
                  mt: 1,
                  width: '80px',
                  borderBottomWidth: 4,
                  borderColor: theme.palette.primary.main,
                }}
                textAlign="left"
              />
            </Box>
            <Box sx={{ mt: 4 }}>
              <Input
                sx={{ mt: 2, width: '100%' }}
                error={showError.email}
                helperText={showError.email ? errorMsg.email : ''}
                type="default"
                label="Email"
                placeholder="Εισαγετε Email"
                value={loginUser.email}
                onChange={e =>
                  setLoginUset((values: LoginUserProps) => ({
                    ...values,
                    email: e.target.value,
                  }))
                }
              />

              <Input
                sx={{ mt: 4, width: '100%' }}
                value={loginUser.password}
                onChange={e =>
                  setLoginUset((values: LoginUserProps) => ({
                    ...values,
                    password: e.target.value,
                  }))
                }
                type="default"
                error={showError.password}
                helperText={showError.password ? errorMsg.password : ''}
                label="Κωδικός Πρόσβασης"
                placeholder="Εισαγετε Κωδικό Πρόσβασης"
                inputType={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                          event.preventDefault()
                        }
                      >
                        {showPassword ? (
                          <Visibility color="primary" />
                        ) : (
                          <VisibilityOff color="primary" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box sx={{ mt: 2, textAlign: 'right' }}>
              <Link href="/reset/password" sx={{ textDecoration: 'none' }}>
                Ξέχασες τον κωδικό σου;
              </Link>
            </Box>
            <Box sx={{ position: 'absolute', bottom: 20, width: `calc(100% - 64px)` }}>
              <ButtonRae
                color="primary"
                variant="contained"
                sx={{ width: '100%' }}
                type="submit"
              >
                Είσοδος
              </ButtonRae>
            </Box>
          </Box>
        </form>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img style={{ width: '140px' }} src={Loader} alt="loading..." />
        </Box>
      )}
    </Paper>
  );
};
