import { CustomTable } from '@rae/ui-library';
import { OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import { TableProviderIdProps } from '@rae/ui-library/src/components/table/TableRowProviderId';

export type ElectricTableprops = {
  setOpenAddDialog: () => void;
  setEditRow: (obj: TableProviderIdProps) => void;
  dataProviderId: TableProviderIdProps[];
  setDeleteObjElectric: (obj: TableProviderIdProps) => void;
  setOrder: (oreder: OrderProps) => void;
  order: OrderProps;
  setOrderBy: (orederBy: string) => void;
  orderBy: string;
  setPage: (page: number) => void;
  page: number;
  countEl: number;
};

export const ElectricProviderIdTable = ({
  setOpenAddDialog,
  setEditRow,
  dataProviderId,
  setDeleteObjElectric,
  order,
  orderBy,
  page,
  setOrder,
  setOrderBy,
  setPage,
  countEl,
}: ElectricTableprops) => {
  return (
    <CustomTable
      handleView={() => {}}
      headCells={[
        { disable: false, id: 'measurement', label: 'Μέτρηση' },
        { disable: false, id: 'categoryOfSupply', label: 'Κατηγορία παροχής' },
        { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
        { disable: true, id: 'Ενέργειες', label: 'Ενέργειες' },
      ]}
      count={countEl}
      handleEdit={(obj: TableProviderIdProps) => setEditRow(obj)}
      handleDelete={(obj: TableProviderIdProps) => setDeleteObjElectric(obj)}
      type="provider-id"
      rows={dataProviderId}
      title=""
      buttonProps={{
        variant: 'contained',
        onClick: setOpenAddDialog,
        children: 'Προσθήκη ',
      }}
      order={order}
      orderBy={orderBy}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
      page={page}
      rowsPerPage={5}
      setPage={setPage}
    />
  );
};

export type GasTableprops = {
  setOpenAddDialog: () => void;
  setEditRow: (obj: TableProviderIdProps) => void;
  dataProviderId: TableProviderIdProps[];
  setDeleteObjGas: (obj: TableProviderIdProps) => void;
  setOrder: (oreder: OrderProps) => void;
  order: OrderProps;
  setOrderBy: (orederBy: string) => void;
  orderBy: string;
  setPage: (page: number) => void;
  page: number;
  countGas: number;
};

export const GasProviderIdTable = ({
  setOpenAddDialog,
  setEditRow,
  dataProviderId,
  setDeleteObjGas,
  countGas,
  order,
  orderBy,
  page,
  setOrder,
  setOrderBy,
  setPage,
}: GasTableprops) => {
  return (
    <CustomTable
      handleView={() => {}}
      headCells={[
        { disable: false, id: 'measurement', label: 'Μέτρηση' },
        { disable: false, id: 'categoryOfSupply', label: 'Κατηγορία παροχής' },
        { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
        { disable: true, id: 'Ενέργειες', label: 'Ενέργειες' },
      ]}
      count={countGas}
      handleEdit={(obj: TableProviderIdProps) => setEditRow(obj)}
      handleDelete={(obj: TableProviderIdProps) => setDeleteObjGas(obj)}
      type="provider-id-gas"
      rows={dataProviderId}
      title=""
      buttonProps={{
        variant: 'contained',
        onClick: setOpenAddDialog,
        children: 'Προσθήκη ',
      }}
      order={order}
      orderBy={orderBy}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
      page={page}
      rowsPerPage={5}
      setPage={setPage}
    />
  );
};
