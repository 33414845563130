import { SpecialDiscProps } from '../pages/SpecialDiscounts';
import { ApiSchema, Identifier } from './ApiSchema';
import { TieredBilling } from './TieredBilling';
import { ChargeRows, chargeRowsAsNumber, chargeRowsAsString } from './ChargeRows';

export type SpecialDiscountElectricFields = {
  homeTieredBilling?: TieredBilling;
  homeChargeScale?: ChargeRows[];
  professionalyTieredBilling?: TieredBilling;
  professionalyChargeScale?: ChargeRows[];
  kotTieredBilling?: TieredBilling;
  kotChargeScale?: ChargeRows[];
};

export type SpecialDiscountElectricFieldsRequest = {
  homeTieredBilling?: TieredBilling | undefined | null;
  homeChargeScale?: ChargeRows[] | undefined | null;
  professionalyTieredBilling?: TieredBilling | undefined | null;
  professionalyChargeScale?: ChargeRows[] | undefined | null;
  kotTieredBilling?: TieredBilling | undefined | null;
  kotChargeScale?: ChargeRows[] | undefined | null;
};

export type SpecialDiscountElectric = ApiSchema<SpecialDiscountElectricFields>;

export const specialDiscountElectricData = (
  discount?: SpecialDiscountElectric,
): SpecialDiscProps['electric'] => {
  return {
    _id: discount?._id,
    home: {
      chargeRows: discount?.homeChargeScale
        ? chargeRowsAsString(discount?.homeChargeScale)
        : undefined,
      tieredBilling: discount?.homeTieredBilling,
      showDisc: !!discount?.homeChargeScale && discount?.homeChargeScale.length > 0,
    },
    professionaly: {
      chargeRows: discount?.professionalyChargeScale
        ? chargeRowsAsString(discount?.professionalyChargeScale)
        : undefined,
      tieredBilling: discount?.professionalyTieredBilling,
      showDisc:
        !!discount?.professionalyChargeScale &&
        discount?.professionalyChargeScale.length > 0,
    },
    kot: {
      chargeRows: discount?.kotChargeScale
        ? chargeRowsAsString(discount?.kotChargeScale)
        : undefined,
      tieredBilling: discount?.kotTieredBilling,
      showDisc: !!discount?.kotChargeScale && discount?.kotChargeScale.length > 0,
    },
  };
};

export const submitSpecialDiscountElectricData = (
  discount?: SpecialDiscProps['electric'],
): Identifier & SpecialDiscountElectricFieldsRequest => {
  return {
    _id: discount?._id ? discount?._id : '',
    homeTieredBilling:
      discount?.home?.showDisc && discount?.home?.tieredBilling
        ? (discount?.home?.tieredBilling as TieredBilling)
        : null,
    homeChargeScale:
      discount?.home?.showDisc && discount?.home?.chargeRows
        ? chargeRowsAsNumber(discount?.home?.chargeRows)
        : null,
    professionalyTieredBilling:
      discount?.professionaly?.showDisc && discount?.professionaly?.tieredBilling
        ? (discount?.professionaly?.tieredBilling as TieredBilling)
        : null,
    professionalyChargeScale:
      discount?.professionaly?.showDisc && discount?.professionaly?.chargeRows
        ? chargeRowsAsNumber(discount?.professionaly?.chargeRows)
        : null,
    kotTieredBilling:
      discount?.kot?.showDisc && discount?.kot?.tieredBilling
        ? (discount?.kot?.tieredBilling as TieredBilling)
        : null,
    kotChargeScale:
      discount?.kot?.showDisc && discount?.kot?.chargeRows
        ? chargeRowsAsNumber(discount?.kot?.chargeRows)
        : null,
  };
};
