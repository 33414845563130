import { Box } from '@mui/material';
import { Input } from '@rae/ui-library';
import { TableGasProvidersProps } from '@rae/ui-library/src/components/table/TableRowGasProviders';
import React from 'react';

type DialogProps = {
  obj: TableGasProvidersProps;
  setObj: (newState: any) => void;
};

export const GasProvidersDialog = ({ obj, setObj }: DialogProps) => {
  return (
    <Box>
      <Input
        sx={{ mt: 4, width: { xs: '200px', md: '300px' } }}
        type="default"
        label="Διαχειριστής Φυσικού Αερίου"
        value={obj.providerName}
        onChange={e =>
          setObj((values: TableGasProvidersProps) => ({
            ...values,
            providerName: e.target.value,
          }))
        }
      />

      <Input
        sx={{ mt: 4, width: '100%' }}
        type="default"
        label="Πληροφορίες Διαχειριστή"
        multiline
        minRows={4}
        value={obj.providerInfo}
        onChange={e =>
          setObj((values: TableGasProvidersProps) => ({
            ...values,
            providerInfo: e.target.value,
          }))
        }
      />
    </Box>
  );
};
