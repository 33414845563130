import { CustomTable } from '@rae/ui-library';
import { DropDownNewProps } from '@rae/ui-library/src/components/DropdownNew';
import { HeadCell, OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import { TableMainRaeProps } from '@rae/ui-library/src/components/table/TableRowMainRae';
import React, { useState } from 'react';
import {
  ReviewStatus,
  ReviewStatusEnum,
  reviewStatusLabel,
} from '../../types/ReviewStatus';
import {
  ProviderStatus,
  ProviderStatusEnum,
  providerStatusLabel,
  Team,
} from '../../types/Team';
import { ProductType, productTypeLabel } from '../../types/Product';

type Props = {
  data: TableMainRaeProps[];
  teams?: Team[];
  openInvoicesVersions: (id: string) => void;
  handleView: (id?: string, type?: string) => void;
  rowsPerPage: number;
  page: number;
  setPage: (newPage: number) => void;
  order: OrderProps;
  setOrder: (newOrder: OrderProps) => void;
  orderBy: HeadCell['id'];
  setOrderBy: (newOrderBy: HeadCell['id']) => void;
  count?: number;
  type: ProductType[];
  setType: (newType: ProductType[]) => void;
  selectedTeam?: Team;
  setSelectedTeam: (newTeam?: Team) => void;
  reviewStatus?: ReviewStatus;
  setReviewStatus: (newReviewStatus?: ReviewStatus) => void;
  providerStatus?: ProviderStatus;
  setProviderStatus: (newProviderStatus?: ProviderStatus) => void;
};

export const RaeTable = ({
  data,
  teams,
  openInvoicesVersions,
  handleView,
  rowsPerPage,
  page,
  setPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  count = 0,
  type,
  setType,
  selectedTeam,
  setSelectedTeam,
  reviewStatus,
  setReviewStatus,
  providerStatus,
  setProviderStatus,
}: Props) => {
  const [openProviderStatus, setOpenProviderStatus] = useState<boolean>(false);
  const [openProvider, setOpenProvider] = useState<boolean>(false);
  const [openType, setOpenType] = useState<boolean>(false);
  const [openStatus, setOpenStatus] = useState<boolean>(false);

  const dropDowns: DropDownNewProps[] = [
    {
      choices: [
        { label: productTypeLabel('electric'), value: 'electric' },
        { label: productTypeLabel('gas'), value: 'gas' },
        { label: productTypeLabel('linked'), value: 'linked' },
      ],
      open: openType,
      selected: type,
      setOpen: setOpenType,
      setSelected: type => setType(type as ('gas' | 'electric' | 'linked')[]),
      label: 'Τύπος',
      multiple: true,
      labelBtn: 'Εφαρμογή',
    },
    {
      choices: teams?.map(i => ({ label: i.nameGr ?? '', value: i._id ?? '' })) ?? [],
      open: openProvider,
      selected: selectedTeam ? [selectedTeam._id] : [],
      setOpen: setOpenProvider,
      setSelected: team =>
        setSelectedTeam(teams?.find(i => i._id === team?.[0]) ?? undefined),
      label: 'Πάροχος',
      multiple: false,
      labelBtn: 'Εφαρμογή',
    },
    {
      choices: [
        {
          label: reviewStatusLabel(ReviewStatusEnum.Pending),
          value: ReviewStatusEnum.Pending,
        },
        {
          label: reviewStatusLabel(ReviewStatusEnum.Revision),
          value: ReviewStatusEnum.Revision,
        },
        {
          label: reviewStatusLabel(ReviewStatusEnum.Accepted),
          value: ReviewStatusEnum.Accepted,
        },
        {
          label: reviewStatusLabel(ReviewStatusEnum.Rejected),
          value: ReviewStatusEnum.Rejected,
        },
      ],
      open: openStatus,
      selected: reviewStatus ? [reviewStatus] : [],
      setOpen: setOpenStatus,
      setSelected: reviewStatus => setReviewStatus(reviewStatus?.[0] as ReviewStatus),
      label: 'Κατάσταση τιμολ',
      multiple: false,
      labelBtn: 'Εφαρμογή',
    },
    {
      choices: [
        {
          label: providerStatusLabel(ProviderStatusEnum.Active),
          value: ProviderStatusEnum.Active,
        },
        {
          label: providerStatusLabel(ProviderStatusEnum.Suspended),
          value: ProviderStatusEnum.Suspended,
        },
      ],
      open: openProviderStatus,
      selected: providerStatus ? [providerStatus] : [],
      setOpen: setOpenProviderStatus,
      setSelected: (status: any) => setProviderStatus(status?.[0] as ProviderStatus),
      label: 'Κατάσταση παρόχου',
      multiple: false,
      labelBtn: 'Εφαρμογή',
    },
  ];

  return (
    <CustomTable
      headCells={[
        { disable: false, id: 'productInfo.nameGr', label: 'Όνομα Τιμολογίου' },
        { disable: false, id: 'productInfo.team.nameGr', label: 'Πάροχος' },
        { disable: true, id: 'provider_status', label: 'Κατάσταση Παρόχου' },
        { disable: true, id: 'versions', label: 'Διαθέσιμα versions' },
        { disable: true, id: 'invoice_status', label: 'Κατάσταση Τιμολογίου' },
        { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
        { disable: true, id: 'actions', label: 'Ενέργειες' },
      ]}
      type="main-rae"
      handleNewEntry={(obj: TableMainRaeProps) =>
        openInvoicesVersions(obj._id ? obj._id : '')
      }
      handleView={(obj: TableMainRaeProps) => handleView(obj._id, obj.type)}
      count={count}
      rows={data}
      dropDownsNew={dropDowns}
      order={order}
      orderBy={orderBy}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
      page={page}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
    />
  );
};
