import { ApiSchema, Identifier } from './ApiSchema';
import moment from 'moment';
import { TableAvrgConsGasProps } from '@rae/ui-library/src/components/table/TableRowAvgConsGas';

export type AvrgConsumptionGasFields = {
  categoryOfSupply: 'home' | 'business' | 'shared';
  monthlyCons: number;
};

export type AvrgConsumptionGas = ApiSchema<AvrgConsumptionGasFields>;

export const avrgConsumptionGasData = (
  cons?: AvrgConsumptionGas[],
): TableAvrgConsGasProps[] => {
  return (
    cons?.map(c => ({
      _id: c._id,
      categoryOfSupply: c.categoryOfSupply,
      monthlyCons: c.monthlyCons,
      modDate: moment(c.createdAt).format('DD/MM/YYYY'),
      modEmail: '',
    })) ?? []
  );
};

export const editAvrgConsumptionGasData = (
  obj: TableAvrgConsGasProps,
): Identifier & AvrgConsumptionGasFields => {
  return {
    _id: obj._id ? obj._id : '',
    monthlyCons: obj.monthlyCons ? obj.monthlyCons : 0,
    categoryOfSupply: obj.categoryOfSupply ? obj.categoryOfSupply : 'home',
  };
};
