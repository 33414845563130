export type ApiPagination = {
  skip: number;
  limit: number;
  sort?: string[];
};

export type PaginationResult<T> = {
  documents: T[];
  count: number;
};

export type PaginationDataResult<T> = {
  data: T[];
  count: number;
};

export type PaginationTeamsResult<T> = {
  teams: T[];
  count: number;
};

export type PaginationNotificationResult<T> = {
  notifications: T[];
  count: number;
  unreadCount: number;
};

export type PaginationProductsResult<T> = {
  products: T[];
  count: number;
};

export type PaginationDiscBenefTypeResult<T> = {
  discBenefType: T[];
  count: number;
};

export const pageToSkipLimit = (
  page: number,
  rowsPerPage: number,
  sort?: string[],
): ApiPagination => ({
  skip: page * rowsPerPage,
  limit: rowsPerPage,
  sort: sort,
});
