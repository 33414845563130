import { TableGasProvidersProps } from '@rae/ui-library/src/components/table/TableRowGasProviders';
import moment from 'moment';
import { ApiSchema, Identifier } from './ApiSchema';

export type GasProviderFields = {
  providerName: string;
  providerInfo: string;
};

export type GasProvider = ApiSchema<GasProviderFields>;

export const gasProviderData = (provider?: GasProvider[]): TableGasProvidersProps[] => {
  return (
    provider?.map(p => ({
      _id: p._id,
      providerName: p.providerName,
      providerInfo: p.providerInfo,
      modDate: moment(p.createdAt).format('DD/MM/YYYY'),
      modEmail: '',
    })) ?? []
  );
};

export const addGasProviderFields = (obj: TableGasProvidersProps): GasProviderFields => {
  return {
    providerName: obj.providerName ? obj.providerName : '',
    providerInfo: obj.providerInfo ? obj.providerInfo : '',
  };
};

export const editGasProviderFields = (
  obj: TableGasProvidersProps,
): Identifier & GasProviderFields => {
  return {
    _id: obj._id ? obj._id : '',
    providerName: obj.providerName ? obj.providerName : '',
    providerInfo: obj.providerInfo ? obj.providerInfo : '',
  };
};
