import { Box } from '@mui/material';
import { CheckBoxes, CustomTabs, Input } from '@rae/ui-library';
import { TableSocialInvoiceProps } from '@rae/ui-library/src/components/table/TableRowSocialInvoice';
import React, { useMemo } from 'react';

type ObjProps = {
  obj: TableSocialInvoiceProps;
  setObj: (newState: any) => void;
};

export const DialogAdd = ({ obj, setObj }: ObjProps) => {
  const ElTab = useMemo(() => {
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-around' },
            mt: 3,
          }}
        >
          <Input
            sx={{ mt: 2, width: { xs: '300px', md: '350px' } }}
            type="default"
            label="Όνομα Τιμολογίου"
            value={obj.socialInvoiceName}
            onChange={e =>
              setObj((values: TableSocialInvoiceProps) => ({
                ...values,
                socialInvoiceName: e.target.value,
              }))
            }
          />
          <Input
            sx={{ mt: 2, width: { xs: '300px', md: '350px' } }}
            type="default"
            label="Έκπτωση Τιμολογίου"
            value={obj.kwh}
            inputType={'number'}
            onChange={e =>
              setObj((values: TableSocialInvoiceProps) => ({
                ...values,
                kwh: e.target.value,
              }))
            }
          />
        </Box>
        <CheckBoxes
          label={'Ρυθμιζόμενες Χρεώσεις'}
          sx={{ mt: 4, pl: 4 }}
          checked={obj.regulatedCharges ?? []}
          setChecked={disc => {
            setObj((values: TableSocialInvoiceProps) => ({
              ...values,
              regulatedCharges: disc,
            }));
          }}
          checkboxes={[
            { label: 'ΕΤΜΕΑΡ', value: 'etmeap' },
            { label: 'Σύστημα Μεταφοράς', value: 'transferSystem' },
            { label: 'Δίκτυο Διανομής', value: 'distributionNetwork' },
            { label: 'ΥΚΩ', value: 'yko' },
          ]}
        />
      </Box>
    );
  }, [obj.kwh, obj.regulatedCharges, obj.socialInvoiceName, setObj]);

  const EnTab = useMemo(() => {
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-around' },
            mt: 3,
          }}
        >
          <Input
            sx={{ mt: 2, width: { xs: '300px', md: '350px' } }}
            type="default"
            label="Tariff's Commercial Name"
            value={obj.socialInvoiceNameEn}
            onChange={e =>
              setObj((values: TableSocialInvoiceProps) => ({
                ...values,
                socialInvoiceNameEn: e.target.value,
              }))
            }
          />
          <Input
            sx={{ mt: 2, width: { xs: '300px', md: '350px' } }}
            type="default"
            label="Tariff's Discount"
            value={obj.kwh}
            inputType={'number'}
            onChange={e =>
              setObj((values: TableSocialInvoiceProps) => ({
                ...values,
                kwh: e.target.value,
              }))
            }
          />
        </Box>
        <CheckBoxes
          label={'Regulated Charges'}
          sx={{ mt: 4, pl: 4 }}
          checked={obj.regulatedCharges ?? []}
          setChecked={disc => {
            setObj((values: TableSocialInvoiceProps) => ({
              ...values,
              regulatedCharges: disc,
            }));
          }}
          checkboxes={[
            { label: 'SLPE', value: 'etmeap' },
            { label: 'Transfer System', value: 'transferSystem' },
            { label: 'Distribution Network', value: 'distributionNetwork' },
            { label: 'SGI', value: 'yko' },
          ]}
        />
      </Box>
    );
  }, [obj.kwh, obj.regulatedCharges, obj.socialInvoiceNameEn, setObj]);

  const tabs = useMemo(
    () => [
      { tabName: 'GR', tabContent: ElTab },
      { tabName: 'EN', tabContent: EnTab },
    ],
    [ElTab, EnTab],
  );

  return <CustomTabs tabs={tabs} newTab={0} />;
};
