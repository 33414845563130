import { CategoryOfSupply, categoryOfSupplyLabel } from './CategoryOfSupply';
import { Measurement, measurementLabel } from './Measurement';
import { TableProviderIdProps } from '@rae/ui-library/src/components/table/TableRowProviderId';
import { ApiSchema } from './ApiSchema';

export type ProviderIdElectricFields = {
  measurement: Measurement;
  categoryOfSupply: CategoryOfSupply;
  archived?: boolean;
};

export type ProviderIdElectric = ApiSchema<ProviderIdElectricFields>;

export const providerIdElectricTableData = (
  providers?: ProviderIdElectric[],
): TableProviderIdProps[] => {
  return (
    providers?.map(provider => ({
      _id: provider._id,
      measurement: measurementLabel(provider.measurement).gr,
      measurementEn: measurementLabel(provider.measurement).en,
      categoryOfSupply: categoryOfSupplyLabel(provider.categoryOfSupply).gr,
      categoryOfSupplyEn: categoryOfSupplyLabel(provider.categoryOfSupply).en,
      modDate: new Date(provider.updatedAt).toLocaleDateString(),
      modEmail: undefined,
    })) ?? []
  );
};

export const providerIdElectricTableSingleData = (
  provider?: ProviderIdElectric,
): TableProviderIdProps => {
  return {
    _id: provider?._id,
    measurement: provider ? measurementLabel(provider.measurement).gr : '',
    measurementEn: provider ? measurementLabel(provider.measurement).en : '',
    categoryOfSupply: provider ? categoryOfSupplyLabel(provider.categoryOfSupply).gr : '',
    categoryOfSupplyEn: provider
      ? categoryOfSupplyLabel(provider.categoryOfSupply).en
      : '',
    modDate: provider ? new Date(provider.updatedAt).toLocaleDateString() : '',
    modEmail: undefined,
  };
};
