import { Cardprops } from '@rae/ui-library/src/components/NotificationCard';
import moment from 'moment';
import { ApiSchema } from './ApiSchema';
import { AuthUser } from './Auth';

export type NotificationFields = {
  user: AuthUser;
  read: boolean;
  title?: string;
  body?: string;
  data?: {
    productId?: string;
    productType?: 'electric' | 'gas' | 'linked';
    status?: 'create' | 'version' | 'comment' | 'accept' | 'reject' | 'revision';
  };
};

export type NotificationParams = {
  read?: boolean;
  platform?: string;
};

export type Notification = ApiSchema<NotificationFields>;

export const notificationData = (notification?: Notification[]): Cardprops[] => {
  return (
    notification?.map(not => ({
      _id: not._id,
      body: not.body,
      date: moment(not.createdAt).format('DD/MM/YYYY H:mm'),
      indicator: not.read,
      title: not.title,
      url:
        not.data?.productType && not.data?.productId
          ? `/product-${not.data?.productType}/${not.data?.productId}`
          : undefined,
    })) ?? []
  );
};
