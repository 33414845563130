export enum CategoryOfSupplyEnum {
  Home = 'home',
  Business = 'business',
  Shared = 'shared',
}

export type CategoryOfSupply = `${CategoryOfSupplyEnum}`;

export const categoryOfSupplyLabel = (categoryOfSupply: string) => {
  switch (categoryOfSupply) {
    case 'home':
      return { gr: 'Οικιακή', en: 'Home' };
    case 'business':
      return { gr: 'Επαγγελματική', en: 'Business' };
    case 'shared':
      return { gr: 'Κοινόχρηστη', en: 'Shared' };
    default:
      return { gr: '', en: '' };
  }
};
export const categoryOfSupplyReverseLabel = (categoryOfSupply: string) => {
  switch (categoryOfSupply) {
    case 'Οικιακή':
    case 'Home':
      return CategoryOfSupplyEnum.Home;
    case 'Επαγγελματική':
    case 'Business':
      return CategoryOfSupplyEnum.Business;
    case 'Κοινόχρηστη':
    case 'Shared':
      return CategoryOfSupplyEnum.Shared;
    default:
      return undefined;
  }
};
export const categoryOfSupplyGasLabel = (categoryOfSupplyGas: string) => {
  switch (categoryOfSupplyGas) {
    case 'home':
      return { gr: 'Οικιακή', en: 'Home' };
    case 'business':
      return { gr: 'Επαγγελματική', en: 'Business' };
    case 'shared':
      return { gr: 'Κεντρική', en: 'Central' };
    default:
      return { gr: '', en: '' };
  }
};

export const categoryOfSupplyGasReverseLabel = (categoryOfSupplyGas: string) => {
  switch (categoryOfSupplyGas) {
    case 'Οικιακή':
    case 'Home':
      return CategoryOfSupplyEnum.Home;
    case 'Επαγγελματική':
    case 'Business':
      return CategoryOfSupplyEnum.Business;
    case 'Κεντρική':
    case 'Central':
      return CategoryOfSupplyEnum.Shared;
    default:
      return undefined;
  }
};
