import { StorageFile, storageFileToFile } from './StorageFile';
import { ApiSchema } from './ApiSchema';
import { providerData, Team } from './Team';
import {
  ProviderIdElectric,
  providerIdElectricTableSingleData,
} from './ProviderIdElectric';
import { Phase } from './Phase';
import { SocialInvoiceDiscount } from './SocialInvoice';
import { CompetitiveFees } from './CompetitiveFees';
import { TieredBilling } from './TieredBilling';
import { ClauseCoefficientMethodology } from './ClauseCoefficient';
import { OtherCharges } from './OtherCharges';
import { Discount } from './Discount';
import { Benefit } from './Benefit';
import {
  ChargeRows,
  ChargeRowsNoAmount,
  chargeRowsNoAmountAsNumber,
  chargeRowsNoAmountAsString,
} from './ChargeRows';
import {
  benefitCast,
  clauseMethodology,
  compFeesData,
  dayChargeScale,
  dayNextChargeScale,
  dayNextTieredBillling,
  daySinglePhaseChargeScale,
  daySinglePhaseTieredBillling,
  dayThreePhaseChargeScale,
  dayThreePhaseTieredBillling,
  dayTieredBillling,
  discountCast,
  durationCast,
  durationCastRevert,
  exitClauseAmount,
  exitClauses,
  factorA,
  factorB,
  factorX,
  nightChargeScale,
  nightNextChargeScale,
  nightNextTieredBillling,
  nightSinglePhaseChargeScale,
  nightSinglePhaseTieredBillling,
  nightThreePhaseChargeScale,
  nightThreePhaseTieredBillling,
  nightTieredBillling,
  otherCharges,
  otherChargesData,
  phaseCast,
  phaseCastRevert,
  ProductElectricTypes,
  protectionLower,
  protectionUpper,
} from '../components/ProductElectricPage/ProductElectricTypes';
import { FactorX } from './FactorX';
import { OptionsProps } from '@rae/ui-library/src/components/Calculator';
import { ReviewStatus } from './ReviewStatus';
import { ReviewComments } from './ReviewComments';
import moment from 'moment';

type ProductElectricProductInfoFields = {
  team: Team;
  nameGr: string;
  nameEn: string;
  linkGr?: string;
  linkEn?: string;
  image?: StorageFile;
  providerId: ProviderIdElectric;
  socialInvoiceDiscounts?: SocialInvoiceDiscount[];
  phase: Phase[];
  duration?: number;
  availabilityDate: Date;
  termsLinkGr?: string[];
  termsLinkEn?: string[];
  infoGr?: string;
  infoEn?: string;
};
export type ProductElectricProductInfoFieldsRequest = Omit<
  ProductElectricProductInfoFields,
  'team' | 'providerId' | 'socialInvoiceDiscounts' | 'image'
> & {
  team: string;
  providerId: string;
  socialInvoiceDiscounts?: string[];
  image?: string;
};

type ProductElectricCompetitiveFeesFields = {
  competitiveFees?: CompetitiveFees;
  dayTieredBilling?: TieredBilling;
  dayChargeScale?: ChargeRows[];
  nightTieredBilling?: TieredBilling;
  nightChargeScale?: ChargeRows[];
  dayNextTieredBilling?: TieredBilling;
  dayNextChargeScale?: ChargeRows[];
  nightNextTieredBilling?: TieredBilling;
  nightNextChargeScale?: ChargeRows[];
  clauseMethodology?: ClauseCoefficientMethodology;
  factorA?: number;
  factorB?: number;
  factorX?: FactorX;
  protectionLower?: number;
  protectionUpper?: number;
  maxReduction?: number;
  maxRaise?: number;
};
export type ProductElectricCompetitiveFeesFieldsRequest = Omit<
  ProductElectricCompetitiveFeesFields,
  'competitiveFees'
> & {
  competitiveFees?: string;
};

export type ProductElectricOtherChargesFields = {
  otherCharges?: OtherCharges;
  daySinglePhaseTieredBilling?: TieredBilling;
  daySinglePhaseChargeScale?: ChargeRows[];
  dayThreePhaseTieredBilling?: TieredBilling;
  dayThreePhaseChargeScale?: ChargeRows[];
  nightSinglePhaseTieredBilling?: TieredBilling;
  nightSinglePhaseChargeScale?: ChargeRows[];
  nightThreePhaseTieredBilling?: TieredBilling;
  nightThreePhaseChargeScale?: ChargeRows[];
  other?: {
    chargeName: string;
    chargeInfo?: string;
    amount: number;
    step: number;
    phase: Phase[];
  }[];
};
export type ProductElectricOtherChargesFieldsRequest = Omit<
  ProductElectricOtherChargesFields,
  'otherCharges'
> & {
  otherCharges?: string;
};

export type ProductElectricDiscountsFields = {
  discounts?: Discount[];
};
export type ProductElectricDiscountsFieldsRequest = Omit<
  ProductElectricDiscountsFields,
  'discounts'
> & {
  discounts?: string[];
};

export type ProductElectricBenefitsFields = {
  benefits?: Benefit[];
};
export type ProductElectricBenefitsFieldsRequest = Omit<
  ProductElectricBenefitsFields,
  'benefits'
> & {
  benefits?: string[];
};

export type ProductElectricExitClauseFields = {
  amount?: number[];
  info?: string;
};
export type ProductElectricExitClauseFieldsRequest = ProductElectricExitClauseFields;

export type ProductElectricFields = {
  original?: ProductElectric;
  versions?: ProductElectric[];
  reviewStatus: ReviewStatus;
  reviewComments: ReviewComments;
  productInfo: ProductElectricProductInfoFields;
  productCompetitiveFees: CompetitiveFees;
  competitiveChargeScaleConsumption?: 'day' | 'night' | 'both' | 'highest';
  competitiveChargeScale?: ChargeRowsNoAmount[];
  competitiveFees: ProductElectricCompetitiveFeesFields[];
  otherCharges: ProductElectricOtherChargesFields[];
  discounts: ProductElectricDiscountsFields[];
  benefits: ProductElectricBenefitsFields[];
  exitClause: ProductElectricExitClauseFields[];
  archived?: boolean;
};

export type ProductElectric = ApiSchema<ProductElectricFields>;

export type ProductElectricFieldsRequest = {
  original?: string;
  reviewStatus?: ReviewStatus;
  reviewComments?: ReviewComments;
  productInfo: ProductElectricProductInfoFieldsRequest;
  productCompetitiveFees: string;
  competitiveChargeScaleConsumption?: 'day' | 'night' | 'both' | 'highest';
  competitiveChargeScale?: ChargeRowsNoAmount[];
  competitiveFees: ProductElectricCompetitiveFeesFieldsRequest[];
  otherCharges: ProductElectricOtherChargesFieldsRequest[];
  discounts: ProductElectricDiscountsFieldsRequest[];
  benefits: ProductElectricBenefitsFieldsRequest[];
  exitClause: ProductElectricExitClauseFieldsRequest[];
};

export const addProductElectric = (
  product?: ProductElectricTypes,
  calculatorData?: OptionsProps[][],
): ProductElectricFieldsRequest => {
  return {
    productInfo: {
      nameGr: product?.info?.productName ? product?.info?.productName : '',
      nameEn: product?.info?.productNameEn ? product?.info?.productNameEn : '',
      phase: phaseCast(product?.info?.phase), //???? Array
      providerId: product?.info?.providerId?._id ? product?.info?.providerId?._id : '', //how this works on screen
      team: product?.info?.provider?._id ? product?.info?.provider?._id : '',
      infoGr: product?.info?.info,
      infoEn: product?.info?.infoEn,
      linkGr: product?.info?.productLink,
      linkEn: product?.info?.productLinkEn,
      termsLinkGr: product?.info?.terms,
      termsLinkEn: product?.info?.termsEn,
      duration: durationCast(product?.info?.duration),
      availabilityDate: product?.info?.from ?? new Date(),
      image: product?.info?.productImgId,
      socialInvoiceDiscounts: product?.info?.receivesDisc,
    },
    reviewComments: product?.reviewComments,
    productCompetitiveFees: product?.competitiveFeeType ?? '',
    competitiveChargeScale: chargeRowsNoAmountAsNumber(
      product?.competitiveChargeScale ?? [],
    ),
    competitiveChargeScaleConsumption: product?.competitiveChargeScaleConsumption,
    competitiveFees:
      product?.competitiveFee?.map((item, i, index) => ({
        competitiveFees: item?.type,
        dayChargeScale: dayChargeScale(item),
        dayTieredBilling: dayTieredBillling(item),
        dayNextChargeScale: dayNextChargeScale(item),
        dayNextTieredBilling: dayNextTieredBillling(item),
        nightChargeScale: nightChargeScale(item),
        nightTieredBilling: nightTieredBillling(item),
        nightNextChargeScale: nightNextChargeScale(item),
        nightNextTieredBilling: nightNextTieredBillling(item),
        clauseMethodology: clauseMethodology(item),
        factorA: factorA(item),
        factorB: factorB(item),
        maxRaise: item?.floatingChargeWithLimit?.maxRaise
          ? parseFloat(item?.floatingChargeWithLimit?.maxRaise)
          : undefined,
        maxReduction: item?.floatingChargeWithLimit?.maxReduction
          ? parseFloat(item?.floatingChargeWithLimit?.maxReduction)
          : undefined,
        protectionLower: protectionLower(item),
        protectionUpper: protectionUpper(item),
        factorX: calculatorData
          ? { expression: factorX(item, calculatorData?.[i]) }
          : undefined,
      })) ?? [],
    exitClause:
      product?.clauses?.map(item => ({
        amount: exitClauseAmount(item),
        info: item?.descrClause,
      })) ?? [],
    otherCharges:
      product?.otherCharges?.map(item => ({
        otherCharges: item?.pagio && item?.pagio?.[0] ? item?.pagio[0] : undefined,
        daySinglePhaseChargeScale: daySinglePhaseChargeScale(item),
        daySinglePhaseTieredBilling: daySinglePhaseTieredBillling(item),
        dayThreePhaseChargeScale: dayThreePhaseChargeScale(item),
        dayThreePhaseTieredBilling: dayThreePhaseTieredBillling(item),
        nightSinglePhaseChargeScale: nightSinglePhaseChargeScale(item),
        nightSinglePhaseTieredBilling: nightSinglePhaseTieredBillling(item),
        nightThreePhaseChargeScale: nightThreePhaseChargeScale(item),
        nightThreePhaseTieredBilling: nightThreePhaseTieredBillling(item),
        other: otherCharges(item),
      })) ?? [],
    discounts:
      product?.discounts?.map(item => ({
        discounts: item
          ? Object.values(item).flatMap(availDiscounts =>
              Object.values(availDiscounts)
                .map(discount => discount?._id)
                .filter((val): val is string => val !== undefined),
            )
          : undefined,
      })) ?? [],
    benefits:
      product?.benefits?.map(item => ({
        benefits: item
          ? Object.values(item).flatMap(availBenefits =>
              Object.values(availBenefits)
                .map(benefit => benefit?._id)
                .filter((val): val is string => val !== undefined),
            )
          : undefined,
      })) ?? [],
  };
};

export const getProductElectricData = (
  product?: ProductElectricFields,
): ProductElectricTypes => {
  return {
    reviewStatus: product?.reviewStatus,
    reviewComments: product?.reviewComments ? product?.reviewComments : {},
    info: {
      productName: product?.productInfo.nameGr,
      productNameEn: product?.productInfo.nameEn,
      phase: phaseCastRevert(product?.productInfo.phase),
      providerId: providerIdElectricTableSingleData(product?.productInfo.providerId),
      provider: providerData(product?.productInfo.team),
      info: product?.productInfo.infoGr,
      infoEn: product?.productInfo.infoEn,
      productLink: product?.productInfo.linkGr,
      productLinkEn: product?.productInfo.linkEn,
      terms: product?.productInfo.termsLinkGr,
      termsEn: product?.productInfo.termsLinkEn,
      duration: durationCastRevert(product?.productInfo.duration),
      from: moment(product?.productInfo.availabilityDate).toDate(),
      productImg: storageFileToFile(product?.productInfo.image),
      productImgId: product?.productInfo.image?._id,
      productImgUrl: product?.productInfo.image?.url,
      receivesDisc: product?.productInfo.socialInvoiceDiscounts?.map(item => item._id),
    },
    competitiveFeeType: product?.productCompetitiveFees?._id,
    competitiveChargeScale: chargeRowsNoAmountAsString(
      product?.competitiveChargeScale ?? [],
    ),
    competitiveChargeScaleConsumption: product?.competitiveChargeScaleConsumption,
    competitiveFee: compFeesData(product),
    otherCharges: otherChargesData(product),
    clauses: exitClauses(product),
    discounts: discountCast(product),
    benefits: benefitCast(product),
  };
};
