import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { ButtonRae, Input } from '@rae/ui-library';
import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Loader from '../../assets/loading.gif';

export type ShowNewPassErrorProps = {
  password?: boolean;
  confirmation_password?: boolean;
};

export type NewPassErrorMsg = {
  password?: string;
  confirmation_password?: string;
};

export type NewPasswordProps = {
  password?: string;
  confirmation_password?: string;
};

type Props = {
  pass: NewPasswordProps;
  setPass: (newState: any) => void;
  handleNewPass: () => void;
  showError: ShowNewPassErrorProps;
  errorMsg: NewPassErrorMsg;
  isLoading?: boolean;
};

export const NewPassCard = ({
  errorMsg,
  handleNewPass,
  pass,
  setPass,
  showError,
  isLoading,
}: Props) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <Paper
      sx={{
        width: 492,
        height: 400,
        borderRadius: '16px',
        p: 4,
        position: 'relative',
      }}
      elevation={3}
    >
      {!isLoading ? (
        <Box>
          <Box>
            <Typography
              sx={{ minWidth: '200px' }}
              color={theme.palette.primary.main}
              variant="h5"
            >
              Δημιουργία νέου κωδικού πρόσβασης
            </Typography>
            <Divider
              sx={{
                mt: 1,
                width: '90%',
                borderBottomWidth: 4,
                borderColor: theme.palette.primary.main,
              }}
              textAlign="left"
            />
          </Box>
          <Box sx={{ mt: 6 }}>
            <Input
              sx={{ mt: 4, width: '100%' }}
              error={showError.password}
              helperText={showError.password ? errorMsg.password : ''}
              value={pass.password}
              onChange={e =>
                setPass((val: NewPasswordProps) => ({
                  ...val,
                  password: e.target.value,
                }))
              }
              type="default"
              label="Νέος Κωδικός"
              placeholder="Εισαγετε Νέο Κωδικό"
              inputType={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                        event.preventDefault()
                      }
                    >
                      {showPassword ? (
                        <Visibility color="primary" />
                      ) : (
                        <VisibilityOff color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Input
              sx={{ mt: 6, width: '100%' }}
              error={showError.confirmation_password}
              helperText={
                showError.confirmation_password ? errorMsg.confirmation_password : ''
              }
              value={pass.confirmation_password}
              onChange={e =>
                setPass((val: NewPasswordProps) => ({
                  ...val,
                  confirmation_password: e.target.value,
                }))
              }
              type="default"
              label="Επιβεβαίωση κωδικού"
              placeholder="Επιβεβαίωση Νέου Κωδικού"
              inputType={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                        event.preventDefault()
                      }
                    >
                      {showConfirmPassword ? (
                        <Visibility color="primary" />
                      ) : (
                        <VisibilityOff color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ position: 'absolute', bottom: 20, width: `calc(100% - 64px)` }}>
            <ButtonRae
              color="primary"
              variant="contained"
              sx={{ width: '100%' }}
              onClick={handleNewPass}
            >
              Είσοδος
            </ButtonRae>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img style={{ width: '140px' }} src={Loader} alt="loading..." />
        </Box>
      )}
    </Paper>
  );
};
