import { Box, Container, Divider, Typography, useTheme } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { RaeTable } from '../components/LandingPage/RaeTable';
import { TableMainRaeProps } from '@rae/ui-library/src/components/table/TableRowMainRae';
import { TableMainProviderProps } from '@rae/ui-library/src/components/table/TableRowMainProvider';
import { CustomChip, Tooltip, InvoicesCard, Input } from '@rae/ui-library';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ProviderTable } from '../components/LandingPage/ProviderTable';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { isRaeTeam } from '../utils/PickTeam';
import { pageToSkipLimit } from '../types/ApiPagination';
import { useGetProductsQuery } from '../redux/apis/productApi';
import {
  getProductProviderData,
  getProductRaeData,
  ProductType,
  productTypeLabel,
} from '../types/Product';
import { OrderProps, HeadCell } from '@rae/ui-library/src/components/table/TableHeader';
import Loader from '../assets/loading.gif';
import useDebounce from '../utils/useDebounce';
import {
  ProviderStatus,
  ProviderStatusEnum,
  providerStatusLabel,
  Team,
} from '../types/Team';
import { useGetTeamsQuery } from '../redux/apis/teamApi';
import {
  ReviewStatus,
  reviewStatusColor,
  reviewStatusLabel,
} from '../types/ReviewStatus';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

export type LandingPageSearchProps = {
  rae: {
    search: string;
    providerStatus: string[];
    provider: string[];
    type: string[];
    invoiceStatus: string[];
  };
  provider: {
    search: string;
    type: string[];
    invoiceStatus: string[];
  };
};

const ROWS_PER_PAGE = 10;

export const LandingPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { team } = useSelector((state: RootState) => state.auth);

  const [order, setOrder] = useState<OrderProps>('asc');
  const [orderBy, setOrderBy] = useState<HeadCell['id']>('updatedAt');
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [type, setType] = useState<ProductType[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [reviewStatus, setReviewStatus] = useState<ReviewStatus>();
  const [providerStatus, setProviderStatus] = useState<ProviderStatus>();

  const [openInvoices, setOpenInvoices] = useState<boolean>(false);
  const [renderInvoice, setRenderInvoice] = useState<any>({});

  const debouncedSearch = useDebounce<string>(search, 300);

  const { data: products, isLoading: productsIsLoading } = useGetProductsQuery({
    ...pageToSkipLimit(page, ROWS_PER_PAGE, [`${order === 'asc' ? '-' : ''}${orderBy}`]),
    search: debouncedSearch ? debouncedSearch : undefined,
    team: isRaeTeam(team) ? selectedTeam?._id ?? undefined : team?._id,
    type: type.length > 0 ? type : undefined,
    reviewStatus: reviewStatus,
    teamSuspended: providerStatus
      ? providerStatus === ProviderStatusEnum.Suspended
      : undefined,
  });

  const { data: providers, isLoading: getProvidersisLoading } = useGetTeamsQuery(
    {
      ...pageToSkipLimit(0, 100, [`nameGr`]),
    },
    { skip: !team || !isRaeTeam(team) },
  );

  const dataMainRae: TableMainRaeProps[] = useMemo(
    () => getProductRaeData(products?.products),
    [products?.products],
  );

  const dataMainProvider: TableMainProviderProps[] = useMemo(
    () => getProductProviderData(products?.products),
    [products?.products],
  );

  const openInvoicesVersions = useCallback(
    (id?: string) => {
      const product = products?.products.find(obj => obj._id === id);
      if (!product) return;
      setRenderInvoice({
        name: product.productInfo.nameGr,
        type: product.type,
        invoices: [
          {
            _id: product._id,
            date: moment(product.productInfo.availabilityDate).format('DD/MM/YYYY'),
            name: product.productInfo.nameGr,
            status: reviewStatusLabel(product.reviewStatus),
            statusColor: reviewStatusColor(product.reviewStatus),
            version: product.versions.length + 1,
          },
          ...product.versions.map((version, index) => ({
            _id: version._id,
            date: moment(version.productInfo.availabilityDate).format('DD/MM/YYYY'),
            name: product.productInfo.nameGr,
            status: reviewStatusLabel(version.reviewStatus),
            statusColor: reviewStatusColor(version.reviewStatus),
            version: product.versions.length - index,
          })),
        ],
      });
      setOpenInvoices(true);
    },
    [products?.products],
  );

  const handleView = useCallback(
    (id?: string, type?: string) => {
      if (!id || !type) return;
      switch (type) {
        case 'ProductElectric':
          navigate(`/product-electric/${id}`);
          break;
        case 'ProductGas':
          navigate(`/product-gas/${id}`);
          break;
        case 'ProductLinked':
          navigate(`/product-linked/${id}`);
          break;
        default:
          break;
      }
    },
    [navigate],
  );

  const isLoading = useMemo(
    () => productsIsLoading || getProvidersisLoading,
    [productsIsLoading, getProvidersisLoading],
  );

  const table = useMemo(() => {
    if (!team || ((!products || products.products.length === 0) && isLoading)) {
      return (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img style={{ width: '140px' }} src={Loader} alt="loading..." />
        </Box>
      );
    }
    if (isRaeTeam(team)) {
      return (
        <RaeTable
          handleView={handleView}
          openInvoicesVersions={openInvoicesVersions}
          data={dataMainRae}
          rowsPerPage={ROWS_PER_PAGE}
          page={page}
          setPage={setPage}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          count={products?.count ?? 0}
          teams={providers?.documents ?? undefined}
          type={type}
          setType={setType}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          reviewStatus={reviewStatus}
          setReviewStatus={setReviewStatus}
          providerStatus={providerStatus}
          setProviderStatus={setProviderStatus}
        />
      );
    } else {
      return (
        <ProviderTable
          handleView={handleView}
          openInvoicesVersions={openInvoicesVersions}
          data={dataMainProvider}
          rowsPerPage={ROWS_PER_PAGE}
          page={page}
          setPage={setPage}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          count={products?.count ?? 0}
          type={type}
          setType={setType}
          reviewStatus={reviewStatus}
          setReviewStatus={setReviewStatus}
        />
      );
    }
  }, [
    team,
    dataMainRae,
    dataMainProvider,
    isLoading,
    handleView,
    openInvoicesVersions,
    page,
    order,
    orderBy,
    products,
    type,
    reviewStatus,
    providerStatus,
    providers,
    selectedTeam,
  ]);

  const filter = useMemo(() => {
    const filters: { type: string; label: string; key?: string }[] = [];
    if (search !== '') {
      filters.push({ type: 'search', label: `Αναζήτηση: ${search}` });
    }
    if (type.length > 0) {
      type.forEach(type =>
        filters.push({
          type: `type`,
          label: `Τύπος: ${productTypeLabel(type)}`,
          key: type,
        }),
      );
    }
    if (selectedTeam) {
      filters.push({
        type: 'selectedTeam',
        label: `Πάροχος: ${selectedTeam.nameGr}`,
        key: selectedTeam._id,
      });
    }
    if (reviewStatus) {
      filters.push({
        type: 'reviewStatus',
        label: `Κατάσταση τιμολ: ${reviewStatusLabel(reviewStatus)}`,
      });
    }
    if (providerStatus) {
      filters.push({
        type: 'providerStatus',
        label: `Κατάσταση παρόχου: ${providerStatusLabel(providerStatus)}`,
      });
    }
    return filters;
  }, [search, selectedTeam, type, reviewStatus, providerStatus]);

  const clearFilters = useCallback(() => {
    setSearch('');
    setSelectedTeam(undefined);
    setType([]);
    setReviewStatus(undefined);
    setProviderStatus(undefined);
  }, []);

  const renderTooltip = useMemo(() => {
    return filter.length > 0 ? (
      <Tooltip
        text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
        type="info"
        btnAction={clearFilters}
        btnLabel="Καθαρισμός Φίλτρων"
        dismiss={true}
        icon={<InfoOutlinedIcon fontSize="small" />}
      />
    ) : null;
  }, [clearFilters, filter.length]);

  const removeChipFees = useMemo(
    () => (type: string, key?: string) => {
      switch (type) {
        case 'search':
          setSearch('');
          break;
        case 'selectedTeam':
          setSelectedTeam(undefined);
          break;
        case 'type':
          setType(prevType => prevType.filter(item => item !== key));
          break;
        case 'reviewStatus':
          setReviewStatus(undefined);
          break;
        case 'providerStatus':
          setProviderStatus(undefined);
          break;
        default:
          break;
      }
    },
    [],
  );

  const renderChips = useMemo(() => {
    return filter
      ? filter.map((item, i) => {
          return (
            <CustomChip
              key={i}
              label={item.label}
              onDelete={() => removeChipFees(item.type, item.key)}
            />
          );
        })
      : null;
  }, [filter, removeChipFees]);

  return (
    <Container maxWidth={'xl'} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ width: { sm: '-webkit-fill-available', xl: 'auto' } }}>
        <Box sx={{ mt: 4 }}>{renderTooltip}</Box>
        <Box sx={{ mt: 2 }}>{renderChips}</Box>
        <Box sx={{ mt: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Typography
                sx={{ minWidth: '200px' }}
                fontSize={theme.typography.body2.fontSize}
                fontWeight={700}
              >
                Αρχική Σελίδα
              </Typography>
              <Divider
                sx={{
                  mt: 1,
                  width: '100px',
                  borderBottomWidth: 4,
                  borderColor: theme.palette.primary.main,
                }}
                textAlign="left"
              />
            </Box>
            <Box>
              <Input
                sx={{
                  width: '300px',
                }}
                color="primary"
                type="search"
                label="Αναζήτηση"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>{table}</Box>
      </Box>
      <InvoicesCard
        handleView={handleView}
        data={renderInvoice}
        open={openInvoices}
        setOpen={setOpenInvoices}
        isProvider={!isRaeTeam(team)}
      />
    </Container>
  );
};
