// @ts-ignore
export type ReviewComments = Record<string, string | ReviewComments>;

export const addReviewComment = (
  reviewComments: ReviewComments,
  id: string,
  comment: string,
): ReviewComments => {
  const [currentKey, ...remainingKeys] = id.split('.');

  if (remainingKeys.length === 0) {
    // Base case: reached the end of the id, add the comment to the current key
    return {
      ...reviewComments,
      [currentKey]: comment,
    };
  }

  // Recursive case: traverse the object and add the comment to the nested key
  const nestedObject = reviewComments[currentKey] || {};
  const updatedNestedObject = addReviewComment(
    nestedObject,
    remainingKeys.join('.'),
    comment,
  );

  return removeEmptyObjects({
    ...reviewComments,
    [currentKey]: updatedNestedObject,
  });
};

const removeEmptyObjects = (obj: ReviewComments): ReviewComments => {
  const newObj: ReviewComments = {};
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      const subObj = removeEmptyObjects(obj[key] as ReviewComments);
      if (Object.keys(subObj).length !== 0) {
        newObj[key] = subObj;
      }
    } else if (obj[key] !== '') {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

export const countReviewComments = (reviewComments: ReviewComments): number => {
  let count = 0;
  for (const key in reviewComments) {
    if (typeof reviewComments[key] === 'string') {
      count++;
    } else if (typeof reviewComments[key] === 'object') {
      count += countReviewComments(reviewComments[key] as ReviewComments);
    }
  }
  return count;
};
