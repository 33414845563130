import api from './api';
import { ApiPagination, PaginationResult } from '../../types/ApiPagination';
import {
  AvrgConsumptionGas,
  AvrgConsumptionGasFields,
} from '../../types/AvrgConsumptionGas';
import { Identifier } from '../../types/ApiSchema';

const avrgConsumptionGasApi = api
  .enhanceEndpoints({ addTagTypes: ['AvrgConsumptionGas'] })
  .injectEndpoints({
    endpoints: builder => ({
      getAvrgConsumptionGas: builder.query<
        PaginationResult<AvrgConsumptionGas>,
        ApiPagination
      >({
        query: params => ({
          url: `/database/AvrgConsumptionGas`,
          method: 'GET',
          params: { ...params },
        }),
        providesTags: result =>
          result?.documents
            ? [
                ...result.documents.map(
                  ({ _id }) => ({ type: 'AvrgConsumptionGas', _id } as const),
                ),
                { type: 'AvrgConsumptionGas', id: 'LIST' },
              ]
            : [{ type: 'AvrgConsumptionGas', id: 'LIST' }],
      }),
      editAvrgConsumptionGas: builder.mutation<
        AvrgConsumptionGas,
        Identifier & Partial<AvrgConsumptionGasFields>
      >({
        query: ({ _id, ...params }) => ({
          url: `database/AvrgConsumptionGas/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [
          { type: 'AvrgConsumptionGas', _id },
        ],
      }),
    }),
  });

export const { useGetAvrgConsumptionGasQuery, useEditAvrgConsumptionGasMutation } =
  avrgConsumptionGasApi;

export default avrgConsumptionGasApi;
