import { Box } from '@mui/material';
import { CustomTabs, Dropdown, DropdownNew, Input } from '@rae/ui-library';
import { Choice } from '@rae/ui-library/src/components/DropdownNew';
import { TableDistributionGasMngmentProps } from '@rae/ui-library/src/components/table/TableRowDistributionGasMngment';
import { TableRegulatedChargesGasProps } from '@rae/ui-library/src/components/table/TableRowRegulatedChargesGas';
import React, { useEffect, useMemo, useState } from 'react';
import {
  CategoryOfSupplyEnum,
  categoryOfSupplyGasLabel,
} from '../../../types/CategoryOfSupply';

type DialogProps = {
  obj: TableRegulatedChargesGasProps;
  setObj: (newState: any) => void;
  activeTab?: number;
  action?: 'edit' | 'view' | 'new' | 'delete';
  networkOptions?: TableDistributionGasMngmentProps[];
};

export const RegulatedChargesDialog = ({
  obj,
  setObj,
  activeTab,
  action,
  networkOptions,
}: DialogProps) => {
  const [openCategory, setOpenCategoty] = useState<boolean>(false);
  const [openCity, setOpenCity] = useState<boolean>(false);

  const cityOptions = useMemo((): Choice[] => {
    return (
      networkOptions?.map(net => ({
        value: net.cityGr ? net.cityGr : '',
        label: net?.cityGr ? net.cityGr : '',
      })) ?? []
    );
  }, [networkOptions]);

  useEffect(() => {
    setObj((values: TableRegulatedChargesGasProps) => ({
      ...values,
      penetrationSurcharge:
        (Number(values.surchargedEnergyCharge) || 0) +
        (Number(values.additionalEnergyCapacityCharge) || 0),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obj.additionalEnergyCapacityCharge, obj.surchargedEnergyCharge]);

  useEffect(() => {
    setObj((values: TableRegulatedChargesGasProps) => ({
      ...values,
      network: networkOptions?.filter(i => i.cityGr === obj.network?.cityGr)[0],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obj.network?.cityGr]);

  const tabs = [
    {
      tabName: 'Πληροφορίες',
      tabContent: (
        <>
          <DropdownNew
            sx={{ width: { xs: '200px', md: '250px' }, mt: 2 }}
            disabled={action ? action === 'view' : false}
            multiline={false}
            label="Κατηγορία Παροχής"
            labelBtn="Εφαρμογή"
            choices={[
              {
                value: CategoryOfSupplyEnum.Home,
                label: categoryOfSupplyGasLabel(CategoryOfSupplyEnum.Home).gr,
              },
              {
                value: CategoryOfSupplyEnum.Business,
                label: categoryOfSupplyGasLabel(CategoryOfSupplyEnum.Business).gr,
              },
              {
                value: CategoryOfSupplyEnum.Shared,
                label: categoryOfSupplyGasLabel(CategoryOfSupplyEnum.Shared).gr,
              },
            ]}
            open={openCategory}
            setOpen={setOpenCategoty}
            selected={obj.category ? [obj.category] : []}
            setSelected={(type: any) =>
              setObj((values: TableRegulatedChargesGasProps) => ({
                ...values,
                category: type[0],
              }))
            }
          />
        </>
      ),
      disabled: false,
    },
    {
      tabName: 'Δίκτυα Διανομής',
      tabContent: (
        <>
          <DropdownNew
            sx={{ width: { xs: '200px', md: '300px' }, mt: 2, ml: 2 }}
            disabled={action ? action === 'view' : false}
            multiline={false}
            label="Πόλη / Περιοχή"
            labelBtn="Εφαρμογή"
            choices={cityOptions}
            open={openCity}
            setOpen={setOpenCity}
            selected={obj.network?.cityGr ? [obj.network.cityGr] : []}
            setSelected={(type: any) =>
              setObj((values: TableRegulatedChargesGasProps) => ({
                ...values,
                network: {
                  ...values.network,
                  cityGr: type[0],
                },
              }))
            }
          />
          {obj.network?.cityGr && obj.network.cityGr !== '' ? (
            <>
              <DropdownNew
                sx={{ width: { xs: '200px', md: '300px' }, mt: 2, ml: 2 }}
                disabled
                multiline={false}
                label="Ζώνη"
                labelBtn="Εφαρμογή"
                choices={[
                  {
                    label: 'Βόρεια',
                    value: 'north',
                  },
                  {
                    label: 'Νότια',
                    value: 'south',
                  },
                ]}
                open={false}
                setOpen={() => {}}
                selected={obj?.network.zone ? [obj.network.zone] : []}
                setSelected={(type: any) => console.log(type)}
              />
              <Dropdown
                sx={{ width: { xs: '200px', md: '300px' }, mt: 2, ml: 2 }}
                disabled
                multiline={false}
                label="Απομακρυσμένα Δίκτυα"
                labelBtn="Εφαρμογή"
                choices={[]}
                open={false}
                setOpen={() => {}}
                selected={
                  obj?.network?.remoteNetwork !== undefined
                    ? obj?.network?.remoteNetwork
                      ? ['Ναι']
                      : ['Όχι']
                    : []
                }
                setSelected={(type: any) => console.log(type)}
              />
              <Dropdown
                sx={{ width: { xs: '200px', md: '300px' }, mt: 2, ml: 2 }}
                disabled
                multiline={false}
                label="Διαχειριστής Φυσικού Αερίου"
                labelBtn="Εφαρμογή"
                choices={[
                  'gasAdministrator-1',
                  'gasAdministrator-2',
                  'gasAdministrator-3',
                ]}
                open={false}
                setOpen={() => {}}
                selected={
                  obj.network.gasProvider?.providerName
                    ? [obj.network.gasProvider.providerName]
                    : []
                }
                setSelected={(type: any) => console.log(type)}
              />
            </>
          ) : null}
        </>
      ),
      disabled: false,
    },
    {
      tabName: 'Χρέωση Χρήσης Δικτύου Διανομής',
      tabContent: (
        <Box>
          <Box>
            <Input
              sx={{ m: 2, width: { xs: '200px', md: '300px' } }}
              disabled={action ? action === 'view' : false}
              type="default"
              inputType={'number'}
              label="Χρέωση Ενέργειας"
              value={obj.energyCharge}
              onChange={e =>
                setObj((values: TableRegulatedChargesGasProps) => ({
                  ...values,
                  energyCharge: e.target.value,
                }))
              }
            />
            <Input
              sx={{ m: 2, width: { xs: '200px', md: '300px' } }}
              disabled={action ? action === 'view' : false}
              type="default"
              inputType={'number'}
              label="Χρέωση Δυναμικότητας"
              value={obj.capacityCharge}
              onChange={e =>
                setObj((values: TableRegulatedChargesGasProps) => ({
                  ...values,
                  capacityCharge: e.target.value,
                }))
              }
            />
            <Input
              sx={{ m: 2, width: { xs: '200px', md: '300px' } }}
              disabled={action ? action === 'view' : false}
              type="default"
              inputType={'number'}
              label="Προσαυξημένη Χρέωση Ενέργειας"
              value={obj.surchargedEnergyCharge}
              onChange={e =>
                setObj((values: TableRegulatedChargesGasProps) => ({
                  ...values,
                  surchargedEnergyCharge: e.target.value,
                }))
              }
            />
          </Box>
          <Box>
            <Input
              sx={{ m: 2, width: { xs: '200px', md: '300px' } }}
              disabled={action ? action === 'view' : false}
              type="default"
              inputType={'number'}
              label="Προσαυξημένη Χρέωση Δυναμικότητας"
              value={obj.additionalEnergyCapacityCharge}
              onChange={e =>
                setObj((values: TableRegulatedChargesGasProps) => ({
                  ...values,
                  additionalEnergyCapacityCharge: e.target.value,
                }))
              }
            />
            <Input
              sx={{ m: 2, width: { xs: '200px', md: '300px' } }}
              disabled
              type="default"
              inputType={'number'}
              label="Προσαύξηση Διείσδυσης"
              value={obj.penetrationSurcharge}
              onChange={e => {}}
            />
            <Input
              sx={{ m: 2, width: { xs: '200px', md: '300px' } }}
              disabled={action ? action === 'view' : false}
              type="default"
              inputType={'number'}
              label="Απομακρυσμένα Δίκτυα"
              value={obj.remoteNetworksCharge}
              onChange={e =>
                setObj((values: TableRegulatedChargesGasProps) => ({
                  ...values,
                  remoteNetworksCharge: e.target.value,
                }))
              }
            />
          </Box>
        </Box>
      ),
      disabled: false,
    },
  ];

  return (
    <Box>
      <CustomTabs tabs={tabs} newTab={activeTab ? activeTab : 0} />
    </Box>
  );
};
