import { StorageFile, storageFileToFile } from './StorageFile';
import { ApiSchema } from './ApiSchema';
import { Discount } from './Discount';
import { Benefit } from './Benefit';
import { ProductGas } from './ProductGas';
import { ProductElectric } from './ProductElectric';
import { providerData, Team } from './Team';
import {
  benefitElectricCast,
  benefitGasCast,
  discountElectricCast,
  discountGasCast,
  LinkedInvoicesTypes,
} from '../components/LinkedInvoicesPage/LinkedInvoicesTypes';
import { ReviewStatus } from './ReviewStatus';
import { ReviewComments } from './ReviewComments';
import moment from 'moment/moment';

type ProductLinkedProductInfoFields = {
  team: Team;
  productElectric: ProductElectric;
  productGas: ProductGas;
  nameGr: string;
  nameEn: string;
  linkGr?: string;
  linkEn?: string;
  image?: StorageFile;
  availabilityDate: Date;
};
export type ProductLinkedProductInfoFieldsRequest = Omit<
  ProductLinkedProductInfoFields,
  'team' | 'productElectric' | 'productGas' | 'image'
> & {
  team: string;
  productElectric: string;
  productGas: string;
  image?: string;
};

export type ProductLinkedDiscountsElectricFields = {
  discounts?: Discount[];
};
export type ProductLinkedDiscountsElectricFieldsRequest = Omit<
  ProductLinkedDiscountsElectricFields,
  'discounts'
> & {
  discounts?: string[];
};

export type ProductLinkedDiscountsGasFields = {
  discounts?: Discount[];
};
export type ProductLinkedDiscountsGasFieldsRequest = Omit<
  ProductLinkedDiscountsGasFields,
  'discounts'
> & {
  discounts?: string[];
};

export type ProductLinkedBenefitsElectricFields = {
  benefits?: Benefit[];
};
export type ProductLinkedBenefitsElectricFieldsRequest = Omit<
  ProductLinkedBenefitsElectricFields,
  'benefits'
> & {
  benefits?: string[];
};

export type ProductLinkedBenefitsGasFields = {
  benefits?: Benefit[];
};
export type ProductLinkedBenefitsGasFieldsRequest = Omit<
  ProductLinkedBenefitsGasFields,
  'benefits'
> & {
  benefits?: string[];
};

export type ProductLinkedFields = {
  original?: ProductLinked;
  versions?: ProductLinked[];
  reviewStatus: ReviewStatus;
  reviewComments: ReviewComments;
  productInfo: ProductLinkedProductInfoFields;
  discountsElectric: ProductLinkedDiscountsElectricFields;
  benefitsElectric: ProductLinkedBenefitsElectricFields;
  discountsGas: ProductLinkedDiscountsGasFields;
  benefitsGas: ProductLinkedBenefitsGasFields;
  archived?: boolean;
};

export type ProductLinked = ApiSchema<ProductLinkedFields>;

export type ProductLinkedFieldsRequest = {
  original?: string;
  reviewStatus?: ReviewStatus;
  reviewComments?: ReviewComments;
  productInfo: ProductLinkedProductInfoFieldsRequest;
  discountsElectric: ProductLinkedDiscountsElectricFieldsRequest;
  benefitsElectric: ProductLinkedBenefitsElectricFieldsRequest;
  discountsGas: ProductLinkedDiscountsGasFieldsRequest;
  benefitsGas: ProductLinkedBenefitsGasFieldsRequest;
};

export const addLinkedProduct = (
  productInfo?: LinkedInvoicesTypes,
): ProductLinkedFieldsRequest => {
  return {
    productInfo: {
      nameGr: productInfo?.productName ? productInfo?.productName : '',
      nameEn: productInfo?.productNameEn ? productInfo?.productNameEn : '',
      productElectric: productInfo?.electricProd ? productInfo?.electricProd[0] : '',
      productGas: productInfo?.gasProd ? productInfo?.gasProd[0] : '',
      team: productInfo?.provider?._id ? productInfo?.provider?._id : '',
      image: productInfo?.productImgId,
      linkEn: productInfo?.productLinkEn,
      linkGr: productInfo?.productLink,
      availabilityDate: productInfo?.from ?? new Date(),
    },
    discountsElectric: {
      discounts: productInfo?.discountsElectric
        ? Object.values(productInfo?.discountsElectric).flatMap(availDiscounts =>
            Object.values(availDiscounts)
              .map(discount => discount?._id)
              .filter((val): val is string => val !== undefined),
          )
        : undefined,
    },
    benefitsElectric: {
      benefits: productInfo?.benefitsElectric
        ? Object.values(productInfo?.benefitsElectric).flatMap(availBenefits =>
            Object.values(availBenefits)
              .map(benefit => benefit?._id)
              .filter((val): val is string => val !== undefined),
          )
        : undefined,
    },
    discountsGas: {
      discounts: productInfo?.discountsGas
        ? Object.values(productInfo?.discountsGas).flatMap(availDiscounts =>
            Object.values(availDiscounts)
              .map(discount => discount?._id)
              .filter((val): val is string => val !== undefined),
          )
        : undefined,
    },
    benefitsGas: {
      benefits: productInfo?.benefitsGas
        ? Object.values(productInfo?.benefitsGas).flatMap(availBenefits =>
            Object.values(availBenefits)
              .map(benefit => benefit?._id)
              .filter((val): val is string => val !== undefined),
          )
        : undefined,
    },
  };
};

export const getProductLinkedData = (
  product?: ProductLinkedFields,
): LinkedInvoicesTypes => {
  return {
    reviewStatus: product?.reviewStatus,
    reviewComments: product?.reviewComments ? product?.reviewComments : {},

    productName: product?.productInfo.nameGr,
    productNameEn: product?.productInfo.nameEn,
    provider: providerData(product?.productInfo.team),
    productLink: product?.productInfo.linkGr,
    productLinkEn: product?.productInfo.linkEn,
    from: moment(product?.productInfo.availabilityDate).toDate(),
    productImg: storageFileToFile(product?.productInfo.image),
    productImgId: product?.productInfo.image?._id,
    productImgUrl: product?.productInfo.image?.url,
    discountsElectric: discountElectricCast(product),
    discountsGas: discountGasCast(product),
    benefitsElectric: benefitElectricCast(product),
    benefitsGas: benefitGasCast(product),
    electricProd: product?.productInfo.productElectric?._id
      ? [product?.productInfo.productElectric?._id]
      : [],
    gasProd: product?.productInfo.productGas?._id
      ? [product?.productInfo.productGas?._id]
      : [],
  };
};
