import { Box } from '@mui/material';
import { CustomTabs, DropdownNew } from '@rae/ui-library';
import React, { useMemo, useState } from 'react';
import {
  MeasurementEnum,
  measurementGasLabel,
  measurementLabel,
} from '../../types/Measurement';
import {
  CategoryOfSupplyEnum,
  categoryOfSupplyGasLabel,
  categoryOfSupplyLabel,
} from '../../types/CategoryOfSupply';
import { ProviderIdElectricFields } from '../../types/ProviderIdElectric';
import { Identifier } from '../../types/ApiSchema';
import { ProviderIdGasFields } from '../../types/ProviderIdGas';

type ElectricTab = {
  tab?: Identifier & ProviderIdElectricFields;
  setTab: (newState: any) => void;
};
type GasTab = {
  tab?: Identifier & ProviderIdGasFields;
  setTab: (newState: any) => void;
};

export const ElectricDialog = ({ setTab, tab }: ElectricTab) => {
  const [openCount, setOpenCount] = useState<boolean>(false);
  const [openCat, setOpenCat] = useState<boolean>(false);
  const [openCountEn, setOpenCountEn] = useState<boolean>(false);
  const [openCatEn, setOpenCatEn] = useState<boolean>(false);

  const GrTab = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'flex-start', md: 'space-around' },
          mt: 3,
        }}
      >
        <DropdownNew
          sx={{ width: '300px', mt: 2 }}
          multiline={false}
          label="Μέτρηση"
          labelBtn="Εφαρμογή"
          choices={[
            {
              value: MeasurementEnum.Daily,
              label: measurementLabel(MeasurementEnum.Daily).gr,
            },
            {
              value: MeasurementEnum.DailyNightly,
              label: measurementLabel(MeasurementEnum.DailyNightly).gr,
            },
          ]}
          open={openCount}
          setOpen={setOpenCount}
          selected={tab?.measurement ? [tab.measurement] : []}
          setSelected={type =>
            setTab((values: ProviderIdElectricFields) => ({
              ...values,
              measurement: type?.[0],
            }))
          }
        />

        <DropdownNew
          sx={{ width: '300px', mt: 2 }}
          multiline={false}
          label="Κατηγορία Παροχής"
          labelBtn="Εφαρμογή"
          choices={[
            {
              value: CategoryOfSupplyEnum.Home,
              label: categoryOfSupplyLabel(CategoryOfSupplyEnum.Home).gr,
            },
            {
              value: CategoryOfSupplyEnum.Business,
              label: categoryOfSupplyLabel(CategoryOfSupplyEnum.Business).gr,
            },
            {
              value: CategoryOfSupplyEnum.Shared,
              label: categoryOfSupplyLabel(CategoryOfSupplyEnum.Shared).gr,
            },
          ]}
          open={openCat}
          setOpen={setOpenCat}
          selected={tab?.categoryOfSupply ? [tab.categoryOfSupply] : []}
          setSelected={type =>
            setTab((values: ProviderIdElectricFields) => ({
              ...values,
              categoryOfSupply: type?.[0],
            }))
          }
        />
      </Box>
    );
  }, [openCat, openCount, setTab, tab]);

  const EnTab = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'flex-start', md: 'space-around' },
          mt: 3,
        }}
      >
        <DropdownNew
          sx={{ width: '300px', mt: 2 }}
          multiline={false}
          label="Meter indication"
          labelBtn="Εφαρμογή"
          choices={[
            {
              value: MeasurementEnum.Daily,
              label: measurementLabel(MeasurementEnum.Daily).en,
            },
            {
              value: MeasurementEnum.DailyNightly,
              label: measurementLabel(MeasurementEnum.DailyNightly).en,
            },
          ]}
          open={openCountEn}
          setOpen={setOpenCountEn}
          selected={tab?.measurement ? [tab.measurement] : []}
          setSelected={type =>
            setTab((values: ProviderIdElectricFields) => ({
              ...values,
              measurement: type?.[0],
            }))
          }
        />

        <DropdownNew
          sx={{ width: '300px', mt: 2 }}
          multiline={false}
          label="Supply Connection Type"
          labelBtn="Εφαρμογή"
          choices={[
            {
              value: CategoryOfSupplyEnum.Home,
              label: categoryOfSupplyLabel(CategoryOfSupplyEnum.Home).en,
            },
            {
              value: CategoryOfSupplyEnum.Business,
              label: categoryOfSupplyLabel(CategoryOfSupplyEnum.Business).en,
            },
            {
              value: CategoryOfSupplyEnum.Shared,
              label: categoryOfSupplyLabel(CategoryOfSupplyEnum.Shared).en,
            },
          ]}
          open={openCatEn}
          setOpen={setOpenCatEn}
          selected={tab?.categoryOfSupply ? [tab.categoryOfSupply] : []}
          setSelected={type =>
            setTab((values: ProviderIdElectricFields) => ({
              ...values,
              categoryOfSupply: type?.[0],
            }))
          }
        />
      </Box>
    );
  }, [openCatEn, openCountEn, setTab, tab]);

  const tabs = useMemo(
    () => [
      { tabName: 'GR', tabContent: GrTab },
      { tabName: 'EN', tabContent: EnTab },
    ],
    [EnTab, GrTab],
  );

  return <CustomTabs tabs={tabs} newTab={0} />;
};

export const GasDialog = ({ setTab, tab }: GasTab) => {
  const [openCount, setOpenCount] = useState<boolean>(false);
  const [openCountEn, setOpenCountEn] = useState<boolean>(false);
  const [openCat, setOpenCat] = useState<boolean>(false);
  const [openCatEn, setOpenCatEn] = useState<boolean>(false);

  const GrTab = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'flex-start', md: 'space-around' },
          mt: 3,
        }}
      >
        <DropdownNew
          sx={{ width: '300px', mt: 2 }}
          multiline={false}
          label="Μέτρηση"
          labelBtn="Εφαρμογή"
          choices={[
            {
              value: MeasurementEnum.Daily,
              label: measurementGasLabel(MeasurementEnum.Daily).gr,
            },
          ]}
          open={openCount}
          setOpen={setOpenCount}
          selected={tab?.measurement ? [tab.measurement] : []}
          setSelected={type =>
            setTab((values: ProviderIdGasFields) => ({
              ...values,
              measurement: type?.[0],
            }))
          }
        />

        <DropdownNew
          sx={{ width: '300px', mt: 2 }}
          multiline={false}
          label="Κατηγορία Παροχής"
          labelBtn="Εφαρμογή"
          choices={[
            {
              value: CategoryOfSupplyEnum.Home,
              label: categoryOfSupplyGasLabel(CategoryOfSupplyEnum.Home).gr,
            },
            {
              value: CategoryOfSupplyEnum.Business,
              label: categoryOfSupplyGasLabel(CategoryOfSupplyEnum.Business).gr,
            },
            {
              value: CategoryOfSupplyEnum.Shared,
              label: categoryOfSupplyGasLabel(CategoryOfSupplyEnum.Shared).gr,
            },
          ]}
          open={openCat}
          setOpen={setOpenCat}
          selected={tab?.categoryOfSupply ? [tab.categoryOfSupply] : []}
          setSelected={type =>
            setTab((values: ProviderIdGasFields) => ({
              ...values,
              categoryOfSupply: type?.[0],
            }))
          }
        />
      </Box>
    );
  }, [openCount, openCat, setTab, tab?.categoryOfSupply, tab?.measurement]);

  const EnTab = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'flex-start', md: 'space-around' },
          mt: 3,
        }}
      >
        <DropdownNew
          sx={{ width: '300px', mt: 2 }}
          multiline={false}
          label="Meter indication"
          labelBtn="Εφαρμογή"
          choices={[
            {
              value: MeasurementEnum.Daily,
              label: measurementGasLabel(MeasurementEnum.Daily).en,
            },
          ]}
          open={openCountEn}
          setOpen={setOpenCountEn}
          selected={tab?.measurement ? [tab.measurement] : []}
          setSelected={type =>
            setTab((values: ProviderIdGasFields) => ({
              ...values,
              measurement: type?.[0],
            }))
          }
        />
        <DropdownNew
          sx={{ width: '300px', mt: 2 }}
          multiline={false}
          label="Supply Connection Type"
          labelBtn="Εφαρμογή"
          choices={[
            {
              value: CategoryOfSupplyEnum.Home,
              label: categoryOfSupplyGasLabel(CategoryOfSupplyEnum.Home).en,
            },
            {
              value: CategoryOfSupplyEnum.Business,
              label: categoryOfSupplyGasLabel(CategoryOfSupplyEnum.Business).en,
            },
            {
              value: CategoryOfSupplyEnum.Shared,
              label: categoryOfSupplyGasLabel(CategoryOfSupplyEnum.Shared).en,
            },
          ]}
          open={openCatEn}
          setOpen={setOpenCatEn}
          selected={tab?.categoryOfSupply ? [tab.categoryOfSupply] : []}
          setSelected={type =>
            setTab((values: ProviderIdGasFields) => ({
              ...values,
              categoryOfSupply: type?.[0],
            }))
          }
        />
      </Box>
    );
  }, [openCatEn, openCountEn, setTab, tab?.categoryOfSupply, tab?.measurement]);

  const tabs = useMemo(
    () => [
      { tabName: 'GR', tabContent: GrTab },
      { tabName: 'EN', tabContent: EnTab },
    ],
    [GrTab, EnTab],
  );

  return <CustomTabs tabs={tabs} newTab={0} />;
};
