import { Box } from '@mui/system';
import { CheckBoxes, CustomTabs, DropdownNew, Input } from '@rae/ui-library';
import { CheckBoxesProps } from '@rae/ui-library/src/components/CheckBox';
import { TableClauseCoefficientProps } from '@rae/ui-library/src/components/table/TableRowClauseCoefficient';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  ClauseCoefficientMethodologyEnum,
  clauseCoefficientMethodologyLabel,
} from '../../types/ClauseCoefficient';

type CoefficientTab = {
  tab: TableClauseCoefficientProps;
  setTab: (newState: any) => void;
  type: 'add' | 'edit';
};

export const CoefficientClausesTabs = ({ tab, setTab, type }: CoefficientTab) => {
  const [openType, setOpentType] = useState<boolean>(false);
  const prevMeth = useRef(tab.methodology);

  useEffect(() => {
    if (tab.energyType && tab.energyType[0] !== 'electric' && type === 'add') {
      setTab((values: TableClauseCoefficientProps) => ({
        ...values,
        methodology: [],
      }));
    }
  }, [setTab, tab.energyType, type]);

  const GrTab = useMemo(() => {
    const checkboxes: CheckBoxesProps['checkboxes'] = [
      {
        label: clauseCoefficientMethodologyLabel(
          ClauseCoefficientMethodologyEnum.Alternative,
        ).gr,
        value: ClauseCoefficientMethodologyEnum.Alternative,
      },
      {
        label: clauseCoefficientMethodologyLabel(ClauseCoefficientMethodologyEnum.M409)
          .gr,
        value: ClauseCoefficientMethodologyEnum.M409,
      },
    ];
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            mt: 3,
          }}
        >
          <DropdownNew
            sx={{ width: { xs: '300px', md: '350px' }, mt: 2 }}
            multiline={false}
            label="Τύπος"
            labelBtn="Εφαρμογή"
            choices={[
              {
                label: 'Ηλεκτρική Ενέργεια',
                value: 'electric',
              },
              {
                label: 'Φυσικό Αέριο',
                value: 'gas',
              },
            ]}
            open={openType}
            setOpen={setOpentType}
            disabled={type === 'edit'}
            selected={tab.energyType ? [tab.energyType] : []}
            setSelected={(type: any) =>
              setTab((values: TableClauseCoefficientProps) => ({
                ...values,
                energyType: type[0],
                methodology: type[0] === 'electric' ? values.methodology : [],
              }))
            }
          />
          <Input
            sx={{ mt: 2, width: { xs: '300px', md: '350px' } }}
            value={tab.clauseCoefficientName}
            onChange={e =>
              setTab((values: TableClauseCoefficientProps) => ({
                ...values,
                clauseCoefficientName: e.target.value,
              }))
            }
            type="default"
            placeholder="Προσθέστε Όνομα "
            label="Όνομα Συντελεστή Ρήτρας"
          />
        </Box>
        <Box>
          <Input
            sx={{ mt: 2, width: { xs: '300px', md: '350px' } }}
            value={tab.price}
            onChange={e =>
              setTab((values: TableClauseCoefficientProps) => ({
                ...values,
                price: parseFloat(e.target.value),
              }))
            }
            inputType="number"
            type="default"
            placeholder="Προσθέστε Τιμή"
            label="Τιμή"
          />
        </Box>
        <Box>
          <Input
            sx={{ mt: 4, width: '100%' }}
            multiline
            minRows={3}
            value={tab.clauseInformation}
            onChange={e =>
              setTab((values: TableClauseCoefficientProps) => ({
                ...values,
                clauseInformation: e.target.value,
              }))
            }
            type="default"
            placeholder="Προσθέστε πληροφορίες"
            label="Πληροφορίες Συντελεστή Ρήτρας"
          />
        </Box>
        {tab.energyType &&
        (tab.energyType[0] === 'electric' || tab.energyType === 'electric') ? (
          <Box sx={{ mt: 2, p: 2 }}>
            <CheckBoxes
              sx={{ mt: 1 }}
              label="Μεθοδολογία:"
              disabled={type === 'edit' ? prevMeth.current?.map((i: any) => i) : []}
              checked={tab.methodology ? tab.methodology : []}
              setChecked={e =>
                setTab((values: TableClauseCoefficientProps) => ({
                  ...values,
                  methodology: e,
                }))
              }
              checkboxes={checkboxes}
            />
          </Box>
        ) : null}
      </>
    );
  }, [
    openType,
    tab.energyType,
    tab.price,
    tab.clauseCoefficientName,
    tab.clauseInformation,
    tab.methodology,
    type,
    setTab,
  ]);

  const EnTab = useMemo(() => {
    const checkboxes: CheckBoxesProps['checkboxes'] = [
      {
        label: clauseCoefficientMethodologyLabel(
          ClauseCoefficientMethodologyEnum.Alternative,
        ).en,
        value: ClauseCoefficientMethodologyEnum.Alternative,
      },
      {
        label: clauseCoefficientMethodologyLabel(ClauseCoefficientMethodologyEnum.M409)
          .en,
        value: ClauseCoefficientMethodologyEnum.M409,
      },
    ];

    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            mt: 3,
          }}
        >
          <DropdownNew
            sx={{ width: { xs: '300px', md: '350px' }, mt: 2 }}
            multiline={false}
            disabled={type === 'edit'}
            label="Τύπος"
            labelBtn="Εφαρμογή"
            choices={[
              {
                label: 'Electric Energy',
                value: 'electric',
              },
              {
                label: 'Gas',
                value: 'gas',
              },
            ]}
            open={openType}
            setOpen={setOpentType}
            selected={tab.energyType ? [tab.energyType] : []}
            setSelected={(type: any) =>
              setTab((values: TableClauseCoefficientProps) => ({
                ...values,
                energyType: type[0],
              }))
            }
          />
          <Input
            sx={{ mt: 2, width: { xs: '300px', md: '350px' } }}
            value={tab.clauseCoefficientNameEn}
            onChange={e =>
              setTab((values: TableClauseCoefficientProps) => ({
                ...values,
                clauseCoefficientNameEn: e.target.value,
              }))
            }
            type="default"
            label="Clause Name"
            placeholder="Add Name"
          />
        </Box>
        <Box>
          <Input
            sx={{ mt: 2, width: { xs: '300px', md: '350px' } }}
            value={tab.price}
            onChange={e =>
              setTab((values: TableClauseCoefficientProps) => ({
                ...values,
                price: parseFloat(e.target.value),
              }))
            }
            inputType="number"
            type="default"
            placeholder="Add price"
            label="Price"
          />
        </Box>
        <Box>
          <Input
            sx={{ mt: 4, width: '100%' }}
            multiline
            minRows={3}
            value={tab.clauseInformation}
            onChange={e =>
              setTab((values: TableClauseCoefficientProps) => ({
                ...values,
                clauseInformation: e.target.value,
              }))
            }
            type="default"
            placeholder="Add info"
            label="Clause's Info"
          />
        </Box>
        {tab.energyType &&
        (tab.energyType[0] === 'electric' || tab.energyType === 'electric') ? (
          <Box sx={{ mt: 2, p: 2 }}>
            <CheckBoxes
              sx={{ mt: 1 }}
              label="Clause Methodology:"
              checked={tab.methodology ? tab.methodology : []}
              disabled={type === 'edit' ? prevMeth.current?.map((i: any) => i) : []}
              setChecked={e =>
                setTab((values: TableClauseCoefficientProps) => ({
                  ...values,
                  methodology: e,
                }))
              }
              checkboxes={checkboxes}
            />
          </Box>
        ) : null}
      </>
    );
  }, [
    openType,
    tab.energyType,
    tab.clauseCoefficientNameEn,
    tab.price,
    tab.clauseInformation,
    tab.methodology,
    type,
    setTab,
  ]);

  const tabs = useMemo(
    () => [
      { tabName: 'GR', tabContent: GrTab },
      { tabName: 'EN', tabContent: EnTab },
    ],
    [EnTab, GrTab],
  );

  return <CustomTabs tabs={tabs} newTab={0} />;
};
