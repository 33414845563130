import { Box } from '@mui/material';
import { CustomTabs, DropdownNew, Input } from '@rae/ui-library';
import { TableAvrgConsProps } from '@rae/ui-library/src/components/table/TableRowAvrgCons';
import React, { useMemo, useState } from 'react';
import { TableAvrgConsGasProps } from '@rae/ui-library/src/components/table/TableRowAvgConsGas';

type Obj = {
  obj: TableAvrgConsProps;
  setObj: (newState: any) => void;
};

export const EditDialogEl = ({ obj, setObj }: Obj) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: { xs: 'flex-start', md: 'space-around' },
        mt: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'flex-start', md: 'space-around' },
          mb: 6,
        }}
      >
        <Input
          sx={{ mt: 2, width: '300px' }}
          value={obj.providerId.measurement}
          type="default"
          label="Μέτρηση"
          disabled
        />
        <Input
          sx={{ mt: 2, width: '300px' }}
          value={obj.providerId.categoryOfSupply}
          type="default"
          label="Κατηγορία Παροχής"
          disabled
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'flex-start', md: 'space-around' },
        }}
      >
        <Input
          sx={{ mt: 2, width: '300px' }}
          value={obj.singlePhaseDay}
          inputType="number"
          inputProps={{ min: 0 }}
          onChange={e =>
            setObj((values: TableAvrgConsProps) => ({
              ...values,
              singlePhaseDay: parseFloat(e.target.value),
            }))
          }
          type="default"
          label="Μονοφασική"
        />
        <Input
          sx={{ mt: 2, width: '300px' }}
          type="default"
          inputProps={{ min: 0 }}
          label="Τριφασική"
          inputType="number"
          value={obj.threePhaseDay}
          onChange={e =>
            setObj((values: TableAvrgConsProps) => ({
              ...values,
              threePhaseDay: parseFloat(e.target.value),
            }))
          }
        />
      </Box>
    </Box>
  );
};

export const EditTabs = ({ obj, setObj }: Obj) => {
  const EditDialogElDay = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'flex-start', md: 'space-around' },
          mt: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-around' },
            mb: 6,
          }}
        >
          <Input
            sx={{ mt: 2, width: '300px' }}
            value={obj.providerId.measurement}
            type="default"
            label="Μέτρηση"
            disabled
          />
          <Input
            sx={{ mt: 2, width: '300px' }}
            value={obj.providerId.categoryOfSupply + ' (Ημερήσια)'}
            type="default"
            label="Κατηγορία Παροχής"
            disabled
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-around' },
            mb: 6,
          }}
        >
          <Input
            sx={{ mt: 2, width: '300px' }}
            inputType="number"
            inputProps={{ min: 0 }}
            value={obj.singlePhaseDay}
            onChange={e =>
              setObj((values: TableAvrgConsProps) => ({
                ...values,
                singlePhaseDay: parseFloat(e.target.value),
              }))
            }
            type="default"
            label="Μονοφασική"
          />
          <Input
            sx={{ mt: 2, width: '300px' }}
            type="default"
            inputType="number"
            inputProps={{ min: 0 }}
            label="Τριφασική"
            value={obj.threePhaseDay}
            onChange={e =>
              setObj((values: TableAvrgConsProps) => ({
                ...values,
                threePhaseDay: parseFloat(e.target.value),
              }))
            }
          />
        </Box>
      </Box>
    );
  }, [
    obj.providerId.categoryOfSupply,
    obj.providerId.measurement,
    obj.singlePhaseDay,
    obj.threePhaseDay,
    setObj,
  ]);

  const EditDialogElNight = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'flex-start', md: 'space-around' },
          mt: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-around' },
            mb: 6,
          }}
        >
          <Input
            sx={{ mt: 2, width: '300px' }}
            value={obj.providerId.measurement}
            type="default"
            label="Μέτρηση"
            disabled
          />
          <Input
            sx={{ mt: 2, width: '300px' }}
            value={obj.providerId.categoryOfSupply + ' (Νυχτερινή)'}
            type="default"
            label="Κατηγορία Παροχής"
            disabled
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-around' },
            mb: 6,
          }}
        >
          <Input
            sx={{ mt: 2, width: '300px' }}
            inputType="number"
            inputProps={{ min: 0 }}
            value={obj.singlePhaseNight}
            onChange={e =>
              setObj((values: TableAvrgConsProps) => ({
                ...values,
                singlePhaseNight: parseFloat(e.target.value),
              }))
            }
            type="default"
            label="Μονοφασική"
          />
          <Input
            sx={{ mt: 2, width: '300px' }}
            inputType="number"
            type="default"
            inputProps={{ min: 0 }}
            label="Τριφασική"
            value={obj.threePhaseNight}
            onChange={e =>
              setObj((values: TableAvrgConsProps) => ({
                ...values,
                threePhaseNight: parseFloat(e.target.value),
              }))
            }
          />
        </Box>
      </Box>
    );
  }, [
    obj.providerId.categoryOfSupply,
    obj.providerId.measurement,
    obj.singlePhaseNight,
    obj.threePhaseNight,
    setObj,
  ]);

  const tabs = [
    { tabName: 'Ημερήσια', tabContent: EditDialogElDay },
    { tabName: 'Νυχτερινή', tabContent: EditDialogElNight },
  ];
  return <CustomTabs tabs={tabs} newTab={0} />;
};

type ObjGas = {
  obj: TableAvrgConsGasProps;
  setObj: (newState: any) => void;
};

export const EditDialogGas = ({ obj, setObj }: ObjGas) => {
  const [openCategory, setOpenCategory] = useState<boolean>(false);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: { xs: 'flex-start', md: 'space-around' },
        mt: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'flex-start', md: 'space-around' },
          mb: 6,
        }}
      >
        <DropdownNew
          sx={{ width: '300px', mt: 2 }}
          disabled
          label="Κατηγορία Παροχής"
          labelBtn="Εφαρμογή"
          choices={[
            {
              label: 'Οικιακή(Αυτόνομη)',
              value: 'home',
            },
            {
              label: 'Επαγγελματική(Αυτόνομη)',
              value: 'business',
            },
            {
              label: 'Κεντρική',
              value: 'shared',
            },
          ]}
          open={openCategory}
          setOpen={setOpenCategory}
          selected={obj?.categoryOfSupply ? [obj?.categoryOfSupply] : []}
          setSelected={() => {}}
        />
        <Input
          sx={{ mt: 2, width: '300px' }}
          value={obj.monthlyCons}
          type="default"
          inputType={'number'}
          inputProps={{ min: 0 }}
          label="Μηνιαία Κατανάλωση"
          onChange={event =>
            setObj((values: TableAvrgConsGasProps) => ({
              ...values,
              monthlyCons: parseFloat(event.target.value),
            }))
          }
        />
      </Box>
    </Box>
  );
};
