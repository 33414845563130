import { ReviewStatus } from './ReviewStatus';

export enum ReviewModeEnum {
  Creation = 'creation',
  Viewing = 'viewing',
  Editing = 'editing',
  Reviewing = 'reviewing',
  Revising = 'revising',
  Versioning = 'versioning',
}

export const disableFields = (reviewMode: ReviewModeEnum, editable: boolean = false) => {
  // editable true when field can change without new version
  if (editable) {
    return (
      reviewMode !== ReviewModeEnum.Editing &&
      reviewMode !== ReviewModeEnum.Creation &&
      reviewMode !== ReviewModeEnum.Versioning &&
      reviewMode !== ReviewModeEnum.Revising
    );
  } else {
    return (
      reviewMode !== ReviewModeEnum.Creation &&
      reviewMode !== ReviewModeEnum.Versioning &&
      reviewMode !== ReviewModeEnum.Revising
    );
  }
};

export const disableTabs = (reviewMode: ReviewModeEnum) =>
  reviewMode === ReviewModeEnum.Creation || reviewMode === ReviewModeEnum.Versioning;
export const showReviewComments = (reviewMode: ReviewModeEnum) =>
  reviewMode === ReviewModeEnum.Reviewing || reviewMode === ReviewModeEnum.Revising;

export const viewOnlyReviewComments = (reviewMode: ReviewModeEnum) =>
  reviewMode !== ReviewModeEnum.Reviewing;

export enum ReviewActionsEnum {
  Edit = 'edit',
  Review = 'review',
  Reject = 'reject',
  Revise = 'revise',
  Version = 'version',
  Delete = 'delete',
  QR = 'qr',
  LatestVersion = 'latestVersion',
}

export const availableReviewActions = (
  reviewStatus?: ReviewStatus,
  isRae: boolean = false,
  isNotLatestVersion: boolean = false,
): ReviewActionsEnum[] => {
  switch (reviewStatus) {
    case 'accepted':
      return isRae
        ? [
            ...(isNotLatestVersion
              ? [ReviewActionsEnum.LatestVersion]
              : [ReviewActionsEnum.Review, ReviewActionsEnum.Reject]),
          ]
        : [
            ...(isNotLatestVersion
              ? [ReviewActionsEnum.QR, ReviewActionsEnum.LatestVersion]
              : [
                  ReviewActionsEnum.QR,
                  ReviewActionsEnum.Edit,
                  ReviewActionsEnum.Version,
                  ReviewActionsEnum.Delete,
                ]),
          ];
    case 'rejected':
      return isRae
        ? [
            ...(isNotLatestVersion
              ? [ReviewActionsEnum.LatestVersion]
              : [ReviewActionsEnum.Review]),
          ]
        : [
            ...(isNotLatestVersion
              ? [ReviewActionsEnum.LatestVersion]
              : [ReviewActionsEnum.Delete]),
          ];
    case 'pending':
      return isRae
        ? [
            ...(isNotLatestVersion
              ? [ReviewActionsEnum.LatestVersion]
              : [ReviewActionsEnum.Review, ReviewActionsEnum.Reject]),
          ]
        : [
            ...(isNotLatestVersion
              ? [ReviewActionsEnum.LatestVersion]
              : [ReviewActionsEnum.Edit, ReviewActionsEnum.Delete]),
          ];
    case 'revision':
      return isRae
        ? [
            ...(isNotLatestVersion
              ? [ReviewActionsEnum.LatestVersion]
              : [ReviewActionsEnum.Review, ReviewActionsEnum.Reject]),
          ]
        : [
            ...(isNotLatestVersion
              ? [ReviewActionsEnum.LatestVersion]
              : [ReviewActionsEnum.Revise, ReviewActionsEnum.Delete]),
          ];
    default:
      return [];
  }
};
