import { ApiPagination, PaginationNotificationResult } from '../../types/ApiPagination';
import { Notification, NotificationParams } from '../../types/Notification';
import api from './api';

const notificationApi = api
  .enhanceEndpoints({ addTagTypes: ['Notifications'] })
  .injectEndpoints({
    endpoints: builder => ({
      getNotifications: builder.query<
        PaginationNotificationResult<Notification>,
        NotificationParams & ApiPagination
      >({
        query: params => ({
          url: `pushNotifications/notifications`,
          method: 'GET',
          params: { ...params },
        }),
        providesTags: result =>
          result?.notifications
            ? [
                ...result.notifications.map(
                  ({ _id }) => ({ type: 'Notifications', _id } as const),
                ),
                { type: 'Notifications', id: 'LIST' },
              ]
            : [{ type: 'Notifications', id: 'LIST' }],
      }),
      readNotification: builder.mutation<void, { _id: string }>({
        query: ({ _id }) => ({
          url: `pushNotifications/notifications`,
          method: 'PATCH',
          body: { id: _id },
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'Notifications', _id }],
      }),
      sendNotification: builder.mutation<
        void,
        {
          productId: string;
          productType: 'electric' | 'gas' | 'linked';
          status: 'create' | 'version' | 'comment' | 'accept' | 'reject' | 'revision';
        }
      >({
        query: ({ productId, productType, status }) => ({
          url: `raeService/notifications`,
          method: 'POST',
          body: { productId, productType, status },
        }),
      }),
    }),
  });

export const {
  useGetNotificationsQuery,
  useReadNotificationMutation,
  useSendNotificationMutation,
} = notificationApi;

export default notificationApi;
