import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { Mutex } from 'async-mutex';
import { clear, setTokens } from '../slices/authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: '/api',
  prepareHeaders: (headers, { getState, endpoint, forced }) => {
    const token =
      endpoint === '/authentication/renew'
        ? (getState() as RootState).auth.refreshToken
        : (getState() as RootState).auth.accessToken;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    if (forced) {
      headers.set('Cache-Control', 'no-cache');
    }
    return headers;
  },
});

const mutex = new Mutex();
const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            method: 'POST',
            url: '/authentication/renew',
          },
          { ...api, endpoint: '/authentication/renew' },
          extraOptions,
        );
        const refreshRes = refreshResult as any;
        if (refreshRes.data) {
          // update the tokens in redux store
          const { accessToken, refreshToken } = refreshRes.data;
          api.dispatch(setTokens({ accessToken, refreshToken }));

          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(clear());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({}),
});

export default api;
