import { Box, Typography, Link } from '@mui/material';
import { CustomTable, Tooltip } from '@rae/ui-library';
import React, { useMemo } from 'react';
import { TableAvrgConsGasProps } from '@rae/ui-library/src/components/table/TableRowAvgConsGas';
import { OrderProps } from '@rae/ui-library/src/components/table/TableHeader';

export type AvrgConsRowGasProps = {
  setOpenEditGas: (obj: TableAvrgConsGasProps) => void;
  data: TableAvrgConsGasProps[];
  setOrder: (oreder: OrderProps) => void;
  order: OrderProps;
  setOrderBy: (orederBy: string) => void;
  orderBy: string;
  setPage: (page: number) => void;
  page: number;
};

export const TabsTableGas = ({
  setOpenEditGas,
  data,
  order,
  orderBy,
  page,
  setOrder,
  setOrderBy,
  setPage,
}: AvrgConsRowGasProps) => {
  const toolTipText = useMemo(() => {
    return (
      <Typography variant="subtitle2" sx={{ color: '#fff', ml: 2 }}>
        Παρακαλώ πολύ{' '}
        <Link
          href="/providerid?t=1"
          color="inherit"
          sx={{ cursor: 'pointer', fontWeight: '700' }}
        >
          πατηστε εδώ
        </Link>{' '}
        για να συμπληρώσετε τα στοιχεία στην διαχείριση Ταυτότητας Παροχής
      </Typography>
    );
  }, []);

  return (
    <Box>
      {data?.length === 0 ? <Tooltip type="link" text={toolTipText} /> : null}
      <CustomTable
        handleEdit={obj => setOpenEditGas(obj)}
        type="average-cons-gas"
        headCells={[
          { disable: true, id: 'category', label: 'Κατηγορία παροχής' },
          { disable: false, id: 'monthlyCons', label: 'Μηνιαία Κατανάλωση (kWh)' },
          { disable: true, id: 'Τροποποίηση', label: 'Τροποποίηση' },
          { disable: true, id: 'Ενέργειες', label: 'Ενέργειες' },
        ]}
        count={data.length}
        rows={data}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        page={page}
        rowsPerPage={5}
        setPage={setPage}
      />
    </Box>
  );
};
