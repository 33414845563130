import { Box } from '@mui/material';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BackGround from '../assets/loginBG.png';
import {
  RegisterCard,
  RegisterErrorMsg,
  ShowRegisterErrorProps,
} from '../components/RegisterPage/RegisterCard';
import { useRegisterUserMutation } from '../redux/apis/authApi';
import { setLog } from '../redux/slices/appSlice';
import { AppDispatch } from '../redux/store';
import { registerUserData } from '../types/Register';
import { registerPageValidations } from '../validations/RegisterPageValidations';

export type RegisterobjProps = {
  email: string;
  password: string;
  confirm_pass: string;
  invitationToken: string;
};

export const RegisterPage = () => {
  let [searchParams] = useSearchParams();

  let navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [register, { isLoading: registerIsLoading }] = useRegisterUserMutation();

  const [showError, setShowError] = useState<ShowRegisterErrorProps>({});
  const [errorMsg, setErrorMsg] = useState<RegisterErrorMsg>({});

  const [registerUser, setRegisterUser] = useState<RegisterobjProps>({
    email: '',
    password: '',
    invitationToken: searchParams.get('invitationToken') || '',
    confirm_pass: '',
  });

  const handleRegistration = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!registerPageValidations({ registerUser, setErrorMsg, setShowError })) {
        try {
          if (
            !registerUser.email ||
            !registerUser.password ||
            !registerUser.invitationToken
          )
            return;
          const addFields = registerUserData(registerUser);
          const user = await register(addFields).unwrap();
          dispatch(
            setLog({
              severity: 'success',
              message: `Η εγγραφή σας ολοκληρώθηκε με επιτυχία`,
            }),
          );
          if (user) {
            navigate(`/login`);
          }
        } catch (e: any) {
          dispatch(
            setLog({
              severity: 'error',
              message: `${e.data.message}`,
            }),
          );
        }
      }
    },
    [dispatch, navigate, register, registerUser],
  );

  const isLoading = registerIsLoading;

  return (
    <Box
      sx={{
        height: '100vh',
        backgroundImage: `url(${BackGround})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        filter: `drop-shadow(-26px 0px 10px rgba(190, 190, 190, 0.06))`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <RegisterCard
        registerUser={registerUser}
        setRegisterUser={setRegisterUser}
        errorMsg={errorMsg}
        showError={showError}
        handleRegister={handleRegistration}
        isLoading={isLoading}
      />
    </Box>
  );
};
