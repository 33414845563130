import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import {
  BreadCrumbsCustom,
  Tooltip,
  CustomChip,
  PopupForm,
  PopupConfirm,
  CustomTable,
} from '@rae/ui-library';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { OrderProps, HeadCell } from '@rae/ui-library/src/components/table/TableHeader';
import { TableUserProps } from '@rae/ui-library/src/components/table/TableRowUser';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AddUserModalContent } from '../components/usersPage/UsersPageComponents';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store';
import {
  useDeleteMemberMutation,
  useEditMemberMutation,
  useGetUsersQuery,
  useInviteUserMutation,
} from '../redux/apis/userApi';
import { pageToSkipLimit } from '../types/ApiPagination';
import useDebounce from '../utils/useDebounce';
import {
  addUserData,
  deleteMemberFields,
  editMemberFields,
  usersData,
} from '../types/User';
import Loader from '../assets/loading.gif';
import { useGetTeamsQuery } from '../redux/apis/teamApi';
import { providersData } from '../types/Team';
import { DropDownNewProps } from '@rae/ui-library/src/components/DropdownNew';
import { setLog } from '../redux/slices/appSlice';
import { isRaeTeam } from '../utils/PickTeam';
import { useNavigate } from 'react-router-dom';

export const Users = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const dispatch = useDispatch<AppDispatch>();

  const [openType, setOpenType] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<string[]>([]);
  const [openProvider, setOpenProvider] = useState<boolean>(false);
  const [selectedProvider, setSelectedProvider] = useState<string[]>([]);
  const [openAcccess, setOpenAcccess] = useState<boolean>(false);
  const [selectedAcccess, setSelectedAcccess] = useState<string[]>([]);
  const [order, setOrder] = useState<OrderProps>('asc');
  const [orderBy, setOrderBy] = useState<HeadCell['id']>('updatedAt');
  const [page, setPage] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [type, setType] = useState<'edit' | 'save' | undefined>();
  const [valueDeleteUser, setValueDeleteUser] = useState<TableUserProps>();

  const { team } = useSelector((state: RootState) => state.auth);
  const [valueUser, setValueUser] = useState<TableUserProps>({});
  const debouncedSearch = useDebounce<string>(searchValue, 300);

  const { data: providers, isLoading: getProvidersisLoading } = useGetTeamsQuery({
    ...pageToSkipLimit(0, 1000, [`-updatedAt`]),
  });

  const { data: users, isLoading: isLoadingGetUsers } = useGetUsersQuery(
    {
      ...pageToSkipLimit(page, 5, [`${order === 'asc' ? '-' : ''}${orderBy}`]),
      search: debouncedSearch !== '' ? debouncedSearch : undefined,
      teamId: selectedProvider[0] ? selectedProvider[0] : team?._id,
      subTeam: selectedType[0] ? selectedType[0] === 'Χρήστης Παρόχου' : undefined,
      owner: selectedAcccess[0] ? selectedAcccess[0] === 'Admin' : undefined,
    },
    { skip: !team },
  );
  const [inviteUser, { isLoading: inviteUserLoading }] = useInviteUserMutation();
  const [deleteMember, { isLoading: deleteMemberLoading }] = useDeleteMemberMutation();
  const [editMember, { isLoading: editMemberLoading }] = useEditMemberMutation();

  const links = [{ name: 'Προϊόντα', href: '/' }, { name: 'Διαχείριση Χρηστών' }];

  const dropDowns: DropDownNewProps[] = [
    {
      choices: [
        { label: 'Admin', value: 'Admin' },
        { label: 'Member', value: 'Member' },
      ],
      open: openAcccess,
      selected: selectedAcccess,
      setOpen: setOpenAcccess,
      setSelected: setSelectedAcccess,
      label: 'Πρόσβαση',
      multiple: false,
      labelBtn: 'Εφαρμογή',
    },
  ];

  const raeDropDowns: DropDownNewProps[] = [
    {
      choices: [
        { label: 'Χρήστης ΡΑΕ', value: 'Χρήστης ΡΑΕ' },
        { label: 'Χρήστης Παρόχου', value: 'Χρήστης Παρόχου' },
      ],
      open: openType,
      selected: selectedType,
      setOpen: setOpenType,
      setSelected: setSelectedType,
      label: 'Τύπος χρήστη',
      multiple: false,
      labelBtn: 'Εφαρμογή',
    },
    {
      choices: providersData(providers?.documents).map(p => {
        return { label: p.providerName, value: p._id ? p._id : '' };
      }),
      disabled: providers?.count === 0,
      open: openProvider,
      selected: selectedProvider,
      setOpen: setOpenProvider,
      setSelected: setSelectedProvider,
      label: 'Πάροχος',
      multiple: false,
      labelBtn: 'Εφαρμογή',
    },
    {
      choices: [
        { label: 'Admin', value: 'Admin' },
        { label: 'Member', value: 'Member' },
      ],
      open: openAcccess,
      selected: selectedAcccess,
      setOpen: setOpenAcccess,
      setSelected: setSelectedAcccess,
      label: 'Πρόσβαση',
      multiple: false,
      labelBtn: 'Εφαρμογή',
    },
  ];

  const headCells = [
    { disable: false, id: 'lastName', label: 'Ονοματεπώνυμο' },
    { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
    { disable: false, id: 'contactPhone', label: 'Τηλέφωνο' },
    { disable: true, id: 'provider', label: 'Πάροχος' },
    { disable: true, id: 'access', label: 'Πρόσβαση' },
    { disable: true, id: 'actions', label: 'Ενέργειες' },
  ];

  const clearFilters = useMemo(
    () => () => {
      setSelectedAcccess([]);
      setSelectedProvider([]);
      setSelectedType([]);
      setSearchValue('');
    },
    [],
  );

  const removeChip = useMemo(
    () => (chipLabel: string) => {
      setSelectedAcccess(selectedAcccess.filter(label => label !== chipLabel));
      setSelectedProvider(selectedProvider.filter(label => label !== chipLabel));
      setSelectedType(selectedType.filter(label => label !== chipLabel));
      if (searchValue === chipLabel) {
        setSearchValue('');
      }
    },
    [searchValue, selectedAcccess, selectedProvider, selectedType],
  );

  const filters = useMemo(
    () =>
      selectedAcccess.concat(
        selectedProvider,
        selectedType,
        searchValue !== '' ? searchValue : [],
      ),
    [selectedProvider, selectedAcccess, selectedType, searchValue],
  );

  const handleSave = useCallback(async () => {
    setOpenModal(false);
    switch (type) {
      case 'save':
        if (!valueUser.email || !valueUser.team) return;
        try {
          const inviteFields = addUserData(valueUser);
          await inviteUser(inviteFields).unwrap();
          dispatch(
            setLog({
              severity: 'success',
              message: `Η πρόσκληση στάλθηκε επιτυχώς`,
            }),
          );
        } catch (e: any) {
          dispatch(
            setLog({
              severity: 'error',
              message: `${e.data.message}`,
            }),
          );
        }
        setValueUser({});
        break;
      case 'edit':
        if (!valueUser.email || !valueUser.team || !valueUser._id) return;
        try {
          const editFields = editMemberFields(valueUser);
          await editMember(editFields).unwrap();
          dispatch(
            setLog({
              severity: 'success',
              message: `Η επεξεργασία έγινε επιτυχώς`,
            }),
          );
        } catch (e: any) {
          dispatch(
            setLog({
              severity: 'error',
              message: `${e.data.message}`,
            }),
          );
        }
        setValueUser({});
        break;
      default:
        break;
    }
  }, [dispatch, editMember, inviteUser, type, valueUser]);

  const handleDelete = useCallback(async () => {
    if (!valueDeleteUser?._id || !valueDeleteUser.team) return;
    try {
      const deleteFields = deleteMemberFields(valueDeleteUser);
      await deleteMember(deleteFields).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: `Ο χρηστης διαγράφτηκε επιτυχώς`,
        }),
      );
    } catch (e: any) {
      dispatch(
        setLog({
          severity: 'error',
          message: `${e.data.message}`,
        }),
      );
    }
    setValueUser({});
    setOpenModalDelete(false);
  }, [deleteMember, dispatch, valueDeleteUser]);

  const handleEdit = useCallback((obj: TableUserProps) => {
    setType('edit');
    setOpenModal(true);
    setValueUser(obj);
  }, []);

  const handleOpenDelete = useCallback((obj: TableUserProps) => {
    setOpenModalDelete(true);
    setValueDeleteUser(obj);
  }, []);

  const addUserModal = useCallback(() => {
    setType('save');
    setValueUser({});
    setOpenModal(true);
  }, []);

  const disableSave =
    !valueUser.userType ||
    !valueUser.email ||
    !valueUser.team?.role ||
    !valueUser.team?._id;

  const isLoading =
    isLoadingGetUsers ||
    getProvidersisLoading ||
    inviteUserLoading ||
    deleteMemberLoading ||
    editMemberLoading;

  return (
    <Container maxWidth={'xl'} sx={{ p: 2 }}>
      <Box sx={{ mt: 4 }}>
        <BreadCrumbsCustom maxItems={matches ? 3 : 1} navigate={navigate} links={links} />
      </Box>
      {filters.length > 0 ? (
        <Box sx={{ mt: 4 }}>
          <Tooltip
            type="info"
            text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
            btnAction={clearFilters}
            btnLabel="Καθαρισμός Φίλτρων"
            dismiss={true}
            icon={<InfoOutlinedIcon fontSize="small" />}
          />
        </Box>
      ) : null}
      <Box sx={{ mt: 2 }}>
        {filters.map((chiplabel, i) => {
          const provider = providers?.documents
            ? providersData(providers?.documents).find(p => p._id === chiplabel)
            : null;
          const label = provider ? provider.providerName : chiplabel;

          return (
            <CustomChip key={i} label={label} onDelete={() => removeChip(chiplabel)} />
          );
        })}
      </Box>
      <Box sx={{ mt: 4 }}>
        {!users?.data && isLoading ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img style={{ width: '140px' }} src={Loader} alt="loading..." />
          </Box>
        ) : (
          <CustomTable
            handleView={() => alert('')}
            dropDownsNew={isRaeTeam(team) ? raeDropDowns : dropDowns}
            items={[]}
            buttonProps={{
              variant: 'contained',
              onClick: addUserModal,
              children: 'Προσθήκη',
            }}
            searchInput={{
              type: 'search',
              label: 'Αναζήτηση ',
              value: searchValue,
              onChange: (e: ChangeEvent<HTMLInputElement>) =>
                setSearchValue(e.target.value),
            }}
            type="user"
            handleEdit={(obj: TableUserProps) => handleEdit(obj)}
            handleDelete={(obj: TableUserProps) => handleOpenDelete(obj)}
            headCells={headCells}
            count={users?.count ?? 0}
            title="Διαχείριση Χρηστών"
            rows={usersData(users?.data)}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            page={page}
            rowsPerPage={5}
            setPage={setPage}
          />
        )}
      </Box>
      <PopupForm
        title={type === 'edit' ? 'Προσθήκη Χρήστη' : 'Προσθήκη Χρήστη'}
        helpText="Τα παραπάνω στοιχεία αφορούν στα βασικά στοιχεία των χρηστών του Εργαλείου Διαχείρισης."
        leftBtnTitle="Ακύρωση"
        rightBtnTitle={type === 'save' ? 'Πρόσθήκη' : 'Αποθήκευση'}
        open={openModal}
        setOpen={setOpenModal}
        onClose={() => setOpenModal(false)}
        onClickLeftBtn={() => setOpenModal(false)}
        onClickRightBtn={handleSave}
        maxWidth="md"
        rightBtnProps={{ disabled: disableSave }}
      >
        <AddUserModalContent
          isRae={isRaeTeam(team)}
          setUser={setValueUser}
          user={valueUser}
          providers={providersData(providers?.documents)}
          type={type}
          team={team}
        />
      </PopupForm>

      <PopupConfirm
        title="Διαγραφή Χρήστη"
        subTile="Είστε σίγουρος ότι θέλετε να διαγράψετε το συγκεκριμένο χρήστη;"
        leftBtnTitle="Οχι"
        rightBtnTitle="Ναι"
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        onClose={() => setOpenModalDelete(false)}
        onClickLeftBtn={() => setOpenModalDelete(false)}
        onClickRightBtn={handleDelete}
      />
    </Container>
  );
};
