import { Box, Container, Divider, Typography, useTheme } from '@mui/material';
import {
  BreadCrumbsCustom,
  ButtonRae,
  CustomTabs,
  HelpComponent,
  PopupConfirm,
} from '@rae/ui-library';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BenefitsElTab } from '../components/LinkedInvoicesPage/BenefitsElTab';
import { BenefitsGasTab } from '../components/LinkedInvoicesPage/BenefitsGasTab';
import { LinkedInvoicesTypes } from '../components/LinkedInvoicesPage/LinkedInvoicesTypes';
import { LinkingInvoiceTab } from '../components/LinkedInvoicesPage/LinkingInvoiceTab';
import { ProductDiscGasTab } from '../components/LinkedInvoicesPage/ProductDiscGasTab';
import { ProductDiscTab } from '../components/LinkedInvoicesPage/ProductDiscTab';
import { pageToSkipLimit } from '../types/ApiPagination';
import Loader from '../assets/loading.gif';
import { discBenefTypeData } from '../types/DiscBenefType';
import { discountData } from '../types/Discount';
import { useGetDiscBenefTypeQuery } from '../redux/apis/DiscBenefTypeApi';
import { useGetDiscountsQuery } from '../redux/apis/discountApi';
import { benefitData } from '../types/Benefit';
import { useGetBenefitsQuery } from '../redux/apis/benefitApi';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store';
import { providerData } from '../types/Team';
import { addLinkedProduct, getProductLinkedData } from '../types/ProductLinked';
import { toBase64 } from '../utils/FileData';
import { setLog } from '../redux/slices/appSlice';
import {
  useAddProductLinkedMutation,
  useEditProductLinkedMutation,
  useGetProductLinkedQuery,
  useGetProductLinkedVersionsQuery,
} from '../redux/apis/productLinkedApi';
import {
  useAddProductLogoMutation,
  useArchiveProductMutation,
  useEditProductLogoMutation,
} from '../redux/apis/productApi';
import { useNavigate, useParams } from 'react-router-dom';
import { disableTabs, ReviewModeEnum, showReviewComments } from '../types/ReviewMode';
import { addReviewComment, countReviewComments } from '../types/ReviewComments';
import { ReviewStatusEnum } from '../types/ReviewStatus';
import ActionsComponent from '../components/ProductElectricPage/actions/ActionsComponent';
import moment from 'moment/moment';
import { useGetAcceptedProductsElectricQuery } from '../redux/apis/productElectricApi';
import { useGetAcceptedProductsGasQuery } from '../redux/apis/productGasApi';
import { DownloadQRDialog } from '../components/QR/DownloadQRDialog';
import { isRaeTeam } from '../utils/PickTeam';
import { useSendNotificationMutation } from '../redux/apis/notificationApi';

export const LinkedInvoices = () => {
  const theme = useTheme();

  const { team } = useSelector((state: RootState) => state.auth);
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [reviewMode, setReviewMode] = useState<ReviewModeEnum>(
    id ? ReviewModeEnum.Viewing : ReviewModeEnum.Creation,
  );

  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [openDownloadQR, setOpenDownloadQR] = useState<boolean>(false);

  const [obj, setObj] = useState<LinkedInvoicesTypes>({
    electricProd: [],
    gasProd: [],
    productImg: undefined,
    productLink: '',
    productName: '',
    reviewComments: {},
  });

  useEffect(() => {
    if (!id) {
      setObj({
        electricProd: [],
        gasProd: [],
        productImg: undefined,
        productLink: '',
        productName: '',
        reviewComments: {},
      });
    }
  }, [id]);

  const [addLogo, { isLoading: addLogoIsLoading }] = useAddProductLogoMutation();
  const [editLogo, { isLoading: editLogoIsLoading }] = useEditProductLogoMutation();

  const [addProductLinkedRequest, { isLoading: addProductLinkedIsLoading }] =
    useAddProductLinkedMutation();
  const [editProductLinkedRequest, { isLoading: editProductLinkedIsLoading }] =
    useEditProductLinkedMutation();
  const [archiveProductLinkedRequest, { isLoading: archiveProductLinkedIsLoading }] =
    useArchiveProductMutation();
  const [sendNotification] = useSendNotificationMutation();

  const {
    data: productLinked,
    isLoading: getProductLinkedLoading,
    error: getProductLinkedError,
  } = useGetProductLinkedQuery(
    {
      _id: id ?? '',
    },
    { skip: !id },
  );

  const { data: versions, isLoading: versionsIsLoading } =
    useGetProductLinkedVersionsQuery(
      {
        _id: productLinked?.original?._id ?? productLinked?._id ?? '',
      },
      { skip: !id || !productLinked },
    );

  const { data: electricProducts, isLoading: productsElectricLoading } =
    useGetAcceptedProductsElectricQuery({
      team: isRaeTeam(team) ? undefined : team?._id,
    });

  const { data: gasProducts, isLoading: productsGasLoading } =
    useGetAcceptedProductsGasQuery({
      team: isRaeTeam(team) ? undefined : team?._id,
    });

  const { data: discountTypes, isLoading: discountTypesIsLoading } =
    useGetDiscBenefTypeQuery({
      ...pageToSkipLimit(0, 50, [`-updatedAt`]),
      type: 'discount',
    });

  const { data: discounts, isLoading: discountsIsLoading } = useGetDiscountsQuery({
    ...pageToSkipLimit(0, 50, [`-updatedAt`]),
    typeId:
      obj?.discountsElectric && Object.keys(obj.discountsElectric).length > 0
        ? Object.keys(obj.discountsElectric).map(key => key)
        : undefined,
    team: isRaeTeam(team) ? undefined : team?._id,
  });

  const { data: discountsGas, isLoading: discountsGasIsLoading } = useGetDiscountsQuery({
    ...pageToSkipLimit(0, 50, [`-updatedAt`]),
    typeId:
      obj?.discountsGas && Object.keys(obj.discountsGas).length > 0
        ? Object.keys(obj.discountsGas).map(key => key)
        : undefined,
    team: isRaeTeam(team) ? undefined : team?._id,
  });

  const { data: benefitsElTypes, isLoading: benefitsElTypesIsLoading } =
    useGetDiscBenefTypeQuery({
      ...pageToSkipLimit(0, 50, [`-updatedAt`]),
      type: 'benefit',
    });

  const { data: benefits, isLoading: benefitsIsLoading } = useGetBenefitsQuery({
    ...pageToSkipLimit(0, 50, [`-updatedAt`]),
    typeId:
      obj?.benefitsElectric && Object.keys(obj.benefitsElectric).length > 0
        ? Object.keys(obj.benefitsElectric).map(key => key)
        : undefined,
    team: isRaeTeam(team) ? undefined : team?._id,
  });

  const { data: benefitsGas, isLoading: benefitsGasIsLoading } = useGetBenefitsQuery({
    ...pageToSkipLimit(0, 50, [`-updatedAt`]),
    typeId:
      obj?.benefitsGas && Object.keys(obj.benefitsGas).length > 0
        ? Object.keys(obj.benefitsGas).map(key => key)
        : undefined,
    team: isRaeTeam(team) ? undefined : team?._id,
  });

  const setInitialData = useCallback(() => {
    if (reviewMode === ReviewModeEnum.Creation && team) {
      setObj((values: LinkedInvoicesTypes) => ({
        ...values,
        provider: providerData(team),
      }));
    } else if (reviewMode !== ReviewModeEnum.Creation && productLinked && id) {
      setObj(getProductLinkedData(productLinked));
    }
  }, [id, productLinked, reviewMode, team]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  useEffect(() => {
    if (
      getProductLinkedError &&
      'status' in getProductLinkedError &&
      getProductLinkedError.status === 404
    ) {
      navigate('/');
    }
  }, [getProductLinkedError, navigate]);

  const links = useMemo(() => {
    return [
      { name: 'Προϊόντα', href: '/' },
      { name: 'Δημιουργία Προιόντος Ηλεκτρικής Ενέργειας' },
    ];
  }, []);

  const addComment = useCallback(
    (id: string, comment: string) => {
      setObj(prevObj => {
        const newObj = { ...prevObj };
        newObj.reviewComments = addReviewComment(newObj.reviewComments, id, comment);
        return newObj;
      });
    },
    [setObj],
  );

  const minDate = useMemo(() => {
    return productLinked?.productInfo?.availabilityDate
      ? reviewMode === ReviewModeEnum.Versioning
        ? moment
            .max(
              moment(productLinked?.productInfo?.availabilityDate).add(1, 'days'),
              moment(),
            )
            .startOf('day')
            .toDate()
        : moment
            .min(moment(productLinked?.productInfo?.availabilityDate), moment())
            .startOf('day')
            .toDate()
      : moment().startOf('day').toDate();
  }, [productLinked?.productInfo?.availabilityDate, reviewMode]);

  const tabs = useMemo(() => {
    return [
      {
        tabName: 'Σύνδεση τιμολογίων',
        tabContent: (
          <LinkingInvoiceTab
            obj={obj}
            setObj={setObj}
            productsElectric={electricProducts?.products}
            productsGas={gasProducts?.products}
            reviewMode={reviewMode}
            addComment={addComment}
            minDate={minDate}
          />
        ),
        disabled: activeTab <= 0 && disableTabs(reviewMode),
        badge: showReviewComments(reviewMode)
          ? countReviewComments(obj.reviewComments?.productInfo)
          : undefined,
      },
      {
        tabName: 'Εκπτώσεις Προϊόντος Η.Ε.',
        tabContent: (
          <ProductDiscTab
            data={obj}
            setData={setObj}
            team={team}
            productEl={obj.electricProd}
            productGas={obj.gasProd}
            discountsTypes={discBenefTypeData(discountTypes?.discBenefType)}
            discounts={discountData(false, discounts?.documents)}
            productsElectric={electricProducts?.products}
            productsGas={gasProducts?.products}
            reviewMode={reviewMode}
            addComment={addComment}
          />
        ),
        disabled: activeTab <= 1 && disableTabs(reviewMode),
        badge: showReviewComments(reviewMode)
          ? countReviewComments(obj.reviewComments?.discountsElectric)
          : undefined,
      },
      {
        tabName: 'Προσθετές Παροχές Η.Ε.',
        tabContent: (
          <BenefitsElTab
            data={obj}
            setData={setObj}
            team={team}
            productEl={obj.electricProd}
            productGas={obj.gasProd}
            benefitsTypes={discBenefTypeData(benefitsElTypes?.discBenefType)}
            benefitsData={benefitData(false, benefits?.documents)}
            productsElectric={electricProducts?.products}
            productsGas={gasProducts?.products}
            reviewMode={reviewMode}
            addComment={addComment}
          />
        ),
        disabled: activeTab <= 2 && disableTabs(reviewMode),
        badge: showReviewComments(reviewMode)
          ? countReviewComments(obj.reviewComments?.benefitsElectric)
          : undefined,
      },
      {
        tabName: 'Εκπτώσεις Προϊόντος Φ.Α.',
        tabContent: (
          <ProductDiscGasTab
            data={obj}
            setData={setObj}
            team={team}
            productEl={obj.electricProd}
            productGas={obj.gasProd}
            discountsTypes={discBenefTypeData(discountTypes?.discBenefType)}
            discounts={discountData(false, discountsGas?.documents)}
            productsElectric={electricProducts?.products}
            productsGas={gasProducts?.products}
            reviewMode={reviewMode}
            addComment={addComment}
          />
        ),
        disabled: activeTab <= 3 && disableTabs(reviewMode),
        badge: showReviewComments(reviewMode)
          ? countReviewComments(obj.reviewComments?.discountsGas)
          : undefined,
      },
      {
        tabName: 'Προσθετές Παροχές Φ.Α.',
        tabContent: (
          <BenefitsGasTab
            data={obj}
            setData={setObj}
            team={team}
            productEl={obj.electricProd}
            productGas={obj.gasProd}
            benefitsTypes={discBenefTypeData(benefitsElTypes?.discBenefType)}
            benefitsData={benefitData(false, benefitsGas?.documents)}
            productsElectric={electricProducts?.products}
            productsGas={gasProducts?.products}
            reviewMode={reviewMode}
            addComment={addComment}
          />
        ),
        disabled: activeTab <= 4 && disableTabs(reviewMode),
        badge: showReviewComments(reviewMode)
          ? countReviewComments(obj.reviewComments?.benefitsGas)
          : undefined,
      },
    ];
  }, [
    minDate,
    activeTab,
    addComment,
    benefits?.documents,
    benefitsElTypes?.discBenefType,
    benefitsGas?.documents,
    discountTypes?.discBenefType,
    discounts?.documents,
    discountsGas?.documents,
    electricProducts?.products,
    gasProducts?.products,
    obj,
    reviewMode,
    team,
  ]);

  const disableNext = useMemo(() => {
    switch (activeTab) {
      case 0:
        return (
          !obj.productName ||
          !obj.productNameEn ||
          !obj.electricProd ||
          !obj.gasProd ||
          !obj.from ||
          moment(obj.from).isBefore(minDate)
        );

      default:
        return false;
    }
  }, [
    minDate,
    activeTab,
    obj.productName,
    obj.productNameEn,
    obj.electricProd,
    obj.gasProd,
    obj.from,
  ]);

  const nextBtn = useCallback(() => {
    setActiveTab(tab => (tab === tabs.length - 1 ? tab : tab + 1));
  }, [tabs.length]);

  const cancel = useCallback(() => {
    setInitialData();
    setActiveTab(0);
  }, [setInitialData]);

  const helpText = useMemo(() => {
    switch (activeTab) {
      case 0:
        return 'Καθορίζεται ο συνδυασμός που πρέπει να γίνει μεταξύ των προσφερόμενων προϊόντων για να χορηγηθεί έκπτωση με συγκεκριμένα χαρακτηριστικά.';
      case 1:
        return 'Καταχώρηση των εκπτώσεων που εφαρμόζονται στο προσφερόμενο συνδυαστικό προϊόν.';
      case 2:
        return 'Αφορά πρόσθετες παροχές που μπορεί να προσφέρονται για το συγκεκριμένο συνδυαστικό προϊόν, όπως  δωροκάρτες, εκπτώσεις σε συνεργαζόμενες εταιρίες κ.λπ.';
      case 3:
        return 'Καταχώρηση των εκπτώσεων που εφαρμόζονται στο προσφερόμενο συνδυαστικό προϊόν.';
      case 4:
        return 'Αφορά πρόσθετες παροχές που μπορεί να προσφέρονται για το συγκεκριμένο συνδυαστικό προϊόν, όπως  δωροκάρτες, εκπτώσεις σε συνεργαζόμενες εταιρίες κ.λ.π.';
      default:
        break;
    }
  }, [activeTab]);

  const handleDelete = useCallback(async () => {
    if (!productLinked) return;
    try {
      await archiveProductLinkedRequest({
        _id: productLinked.original?._id ?? productLinked._id,
        type: 'linked',
      }).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: 'Η Συνδυαστική Προσφορά διαγράφηκε με επιτυχία',
        }),
      );
    } catch (error: any) {
      console.log(error);
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setActiveTab(0);
  }, [archiveProductLinkedRequest, dispatch, productLinked]);

  const handleReview = useCallback(async () => {
    if (!productLinked) return;
    try {
      await editProductLinkedRequest({
        _id: productLinked._id,
        reviewComments: obj.reviewComments,
        reviewStatus:
          countReviewComments(obj.reviewComments) > 0
            ? ReviewStatusEnum.Revision
            : ReviewStatusEnum.Accepted,
      }).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: 'Η Διαδικασία Έγκρισης Συνδυαστικής Προσφοράς ολοκληρώθηκε',
        }),
      );
      sendNotification({
        productId: productLinked._id,
        productType: 'linked',
        status: countReviewComments(obj.reviewComments) > 0 ? 'comment' : 'accept',
      });
    } catch (error: any) {
      console.log(error);
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setActiveTab(0);
  }, [
    dispatch,
    editProductLinkedRequest,
    obj.reviewComments,
    productLinked,
    sendNotification,
  ]);

  const handleReject = useCallback(async () => {
    if (!productLinked) return;
    try {
      await editProductLinkedRequest({
        _id: productLinked._id,
        reviewStatus: ReviewStatusEnum.Rejected,
      }).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: 'Η Διαδικασία Απόρριψης Συνδυαστικής Προσφοράς ολοκληρώθηκε',
        }),
      );
      sendNotification({
        productId: productLinked._id,
        productType: 'linked',
        status: 'reject',
      });
    } catch (error: any) {
      console.log(error);
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setActiveTab(0);
  }, [dispatch, editProductLinkedRequest, productLinked, sendNotification]);

  const handleRevise = useCallback(async () => {
    if (!productLinked) return;
    let updatedProduct = { ...obj };
    if (obj?.productImg) {
      const logo = await toBase64(obj?.productImg);
      const { _id: logoId } = productLinked?.productInfo?.image
        ? await editLogo({
            _id: productLinked.productInfo?.image?._id,
            name: obj?.productImg.name,
            mimeType: obj?.productImg.type,
            data: logo,
          }).unwrap()
        : await addLogo({
            name: obj?.productImg.name,
            mimeType: obj?.productImg.type,
            data: logo,
          }).unwrap();
      updatedProduct = {
        ...updatedProduct,
        productImgId: logoId,
      };
    }
    const editFields = addLinkedProduct(updatedProduct);
    try {
      await editProductLinkedRequest({
        ...editFields,
        _id: productLinked?._id,
        reviewStatus: ReviewStatusEnum.Pending,
      }).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: 'Η Επεξεργασία Συνδυαστικής Προσφοράς ολοκληρώθηκε',
        }),
      );
      sendNotification({
        productId: productLinked._id,
        productType: 'linked',
        status: 'revision',
      });
    } catch (error: any) {
      console.log(error);
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setActiveTab(0);
  }, [
    productLinked,
    obj,
    editLogo,
    addLogo,
    editProductLinkedRequest,
    dispatch,
    sendNotification,
  ]);

  const handleEdit = useCallback(async () => {
    if (!productLinked) return;
    let updatedProduct = { ...obj };
    if (obj?.productImg) {
      const logo = await toBase64(obj?.productImg);
      const { _id: logoId } = productLinked?.productInfo?.image
        ? await editLogo({
            _id: productLinked.productInfo?.image?._id,
            name: obj?.productImg.name,
            mimeType: obj?.productImg.type,
            data: logo,
          }).unwrap()
        : await addLogo({
            name: obj?.productImg.name,
            mimeType: obj?.productImg.type,
            data: logo,
          }).unwrap();
      updatedProduct = {
        ...updatedProduct,
        productImgId: logoId,
      };
    }
    const editFields = addLinkedProduct(updatedProduct);
    try {
      await editProductLinkedRequest({
        ...editFields,
        _id: productLinked?._id,
      }).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: 'Η Επεξεργασία Συνδυαστικής Προσφοράς ολοκληρώθηκε',
        }),
      );
    } catch (error: any) {
      console.log(error);
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setActiveTab(0);
  }, [productLinked, obj, editLogo, addLogo, editProductLinkedRequest, dispatch]);

  const handleCreate = useCallback(async () => {
    let updatedProduct = { ...obj };
    if (obj?.productImg) {
      const logo = await toBase64(obj?.productImg);
      const [{ _id: logoId }] = await Promise.all([
        addLogo({
          name: obj?.productImg.name,
          mimeType: obj?.productImg.type,
          data: logo,
        }).unwrap(),
      ]);
      updatedProduct = {
        ...updatedProduct,
        productImgId: logoId,
      };
    }
    const addFields = addLinkedProduct(updatedProduct);
    try {
      const { _id: productId } = await addProductLinkedRequest(addFields).unwrap();
      navigate(`/product-linked/${productId}`);
      dispatch(
        setLog({
          severity: 'success',
          message: 'Η Δημιουργία Συνδυαστικής Προσφοράς ολοκληρώθηκε',
        }),
      );
      sendNotification({
        productId: productId,
        productType: 'linked',
        status: 'create',
      });
    } catch (error: any) {
      console.log(error);
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setActiveTab(0);
  }, [obj, addLogo, addProductLinkedRequest, navigate, dispatch, sendNotification]);

  const handleCreateVersion = useCallback(async () => {
    if (!productLinked) return;
    let updatedProduct = { ...obj };
    if (obj?.productImg) {
      const logo = await toBase64(obj?.productImg);
      const [{ _id: logoId }] = await Promise.all([
        addLogo({
          name: obj?.productImg.name,
          mimeType: obj?.productImg.type,
          data: logo,
        }).unwrap(),
      ]);
      updatedProduct = {
        ...updatedProduct,
        productImgId: logoId,
      };
    }
    const addFields = addLinkedProduct(updatedProduct);
    try {
      const { _id: productId } = await addProductLinkedRequest({
        original: productLinked.original?._id ?? productLinked._id,
        ...addFields,
      }).unwrap();
      navigate(`/product-linked/${productId}`);
      dispatch(
        setLog({
          severity: 'success',
          message: 'Η Δημιουργία Συνδυαστικής Προσφοράς ολοκληρώθηκε',
        }),
      );
      sendNotification({
        productId: productId,
        productType: 'linked',
        status: 'version',
      });
    } catch (error: any) {
      console.log(error);
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setActiveTab(0);
  }, [
    productLinked,
    obj,
    addLogo,
    addProductLinkedRequest,
    navigate,
    dispatch,
    sendNotification,
  ]);

  const handleSave = useCallback(() => {
    switch (reviewMode) {
      case ReviewModeEnum.Creation:
        handleCreate();
        break;
      case ReviewModeEnum.Reviewing:
        handleReview();
        break;
      case ReviewModeEnum.Editing:
        handleEdit();
        break;
      case ReviewModeEnum.Revising:
        handleRevise();
        break;
      case ReviewModeEnum.Versioning:
        handleCreateVersion();
        break;
      default:
        break;
    }
    setReviewMode(ReviewModeEnum.Viewing);
  }, [
    handleCreate,
    handleCreateVersion,
    handleEdit,
    handleReview,
    handleRevise,
    reviewMode,
  ]);

  const isLoading =
    productsElectricLoading ||
    productsGasLoading ||
    discountTypesIsLoading ||
    discountsIsLoading ||
    benefitsElTypesIsLoading ||
    benefitsIsLoading ||
    discountsGasIsLoading ||
    benefitsGasIsLoading ||
    addLogoIsLoading ||
    editLogoIsLoading ||
    addProductLinkedIsLoading ||
    editProductLinkedIsLoading ||
    archiveProductLinkedIsLoading ||
    getProductLinkedLoading ||
    versionsIsLoading;

  const disableSave = useMemo(() => {
    if (reviewMode === ReviewModeEnum.Reviewing) return false;
    return !(
      obj.electricProd &&
      obj.gasProd &&
      obj.productName &&
      obj.productNameEn &&
      obj.from
    );
  }, [
    obj.electricProd,
    obj.from,
    obj.gasProd,
    obj.productName,
    obj.productNameEn,
    reviewMode,
  ]);

  const handleDeleteProduct = () => {
    handleDelete();
    navigate('/');
  };

  const handleRejectProduct = () => {
    handleReject();
  };

  const handleQRProduct = () => {
    setOpenDownloadQR(true);
  };

  const pageTitle = useMemo(() => {
    switch (reviewMode) {
      case ReviewModeEnum.Creation:
        return 'Δημιουργία Συνδυαστικής Προσφοράς';
      case ReviewModeEnum.Editing:
        return 'Επεξεργασία Συνδυαστικής Προσφοράς';
      case ReviewModeEnum.Reviewing:
        return 'Έγκριση Συνδυαστικής Προσφοράς';
      case ReviewModeEnum.Revising:
        return 'Διόρθωση Συνδυαστικής Προσφοράςς';
      case ReviewModeEnum.Versioning:
        return 'Δημιουργία Νέας Έκδοσης Συνδυαστικής Προσφοράς';
      case ReviewModeEnum.Viewing:
        return 'Προβολή Συνδυαστικής Προσφοράς';
    }
  }, [reviewMode]);

  const handleSetReviewMode = (reviewMode: ReviewModeEnum) => {
    setReviewMode(reviewMode);
    setActiveTab(0);
  };

  return (
    <Container maxWidth={'xl'} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mt: 4 }}>
        <BreadCrumbsCustom navigate={navigate} links={links} />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography sx={{ minWidth: '200px' }} fontSize={'18px'} fontWeight={700}>
          {pageTitle}
        </Typography>
        <Divider
          sx={{
            mt: 1,
            width: '10%',
            borderBottomWidth: 4,
            borderColor: theme.palette.primary.main,
          }}
          textAlign="left"
        />
      </Box>
      {id && (
        <ActionsComponent
          team={team}
          product={productLinked}
          versions={versions}
          handleNavigate={id => navigate(`/product-linked/${id}`)}
          reviewMode={reviewMode}
          setReviewMode={handleSetReviewMode}
          handleDelete={() => setOpenDelete(true)}
          handleReject={handleRejectProduct}
          handleQR={handleQRProduct}
          comments={countReviewComments(obj.reviewComments)}
        />
      )}
      <Box sx={{ mt: 4, mb: 4 }}>
        {isLoading ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img style={{ width: '140px' }} src={Loader} alt="loading..." />
          </Box>
        ) : (
          <CustomTabs
            tabs={tabs}
            chipStyle={true}
            newTab={activeTab}
            setActive={setActiveTab}
          />
        )}
        <HelpComponent icon="lamp-on" helpText={helpText} />
      </Box>
      <Divider variant="middle" />
      <Box sx={{ mt: 4, mb: 4, display: 'flex', justifyContent: 'end' }}>
        {activeTab === 0 ? null : (
          <ButtonRae
            color="secondary"
            variant="contained"
            sx={{ mr: 2, width: 200 }}
            onClick={cancel}
          >
            Ακύρωση
          </ButtonRae>
        )}
        <ButtonRae
          color="primary"
          variant="contained"
          sx={{ width: 200 }}
          disabled={activeTab === 4 ? disableSave : disableNext}
          onClick={activeTab === 4 ? handleSave : nextBtn}
        >
          {activeTab === 4 ? 'Αποθήκευση' : 'Επόμενο'}
        </ButtonRae>
      </Box>
      <PopupConfirm
        title="Διαγραφή Συνδυαστικής Προσφοράς"
        subTile="Προσοχή! Η διαγραφή αφορά στο συγκεκριμένο τιμολόγιο καθώς και σε ενδεχόμενα προηγούμενα versions του τιμολογίου. Είστε σίγουρος ότι θέλετε να προχωρήσετε στη διαγραφή της συνδυαστικής προσφοράς;"
        leftBtnTitle="Όχι"
        rightBtnTitle="Ναι"
        open={openDelete}
        setOpen={setOpenDelete}
        onClose={() => setOpenDelete(false)}
        onClickLeftBtn={() => setOpenDelete(false)}
        onClickRightBtn={handleDeleteProduct}
      />
      <DownloadQRDialog
        qrText={`${process.env.REACT_APP_COMPARISON_BASE_URL}?mode=${'mixed'}&provider=${
          productLinked?.productInfo?.team?._id
        }&product=${productLinked?._id}`}
        fileName={`RAE_QR_${productLinked?.productInfo?.nameEn}`}
        title={'Κατέβασμα QR'}
        helpText={'Κατεβάστε το QR του προϊόντος'}
        open={openDownloadQR}
        setOpen={setOpenDownloadQR}
      />
    </Container>
  );
};
