import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { BreadCrumbsCustom, CustomTabs, PopupConfirm, PopupForm } from '@rae/ui-library';
import React, { useCallback, useState } from 'react';
import {
  ElectricProviderIdTable,
  GasProviderIdTable,
} from '../components/ProviderIdPage/TabsTablesContent';
import { ElectricDialog, GasDialog } from '../components/ProviderIdPage/TabsDialog';
import { TableProviderIdProps } from '@rae/ui-library/src/components/table/TableRowProviderId';
import { TableProviderIdGasProps } from '@rae/ui-library/src/components/table/TableRowProviderIdGas';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  ProviderIdElectric,
  ProviderIdElectricFields,
  providerIdElectricTableData,
} from '../types/ProviderIdElectric';
import {
  useAddProviderIdElectricMutation,
  useAddProviderIdGasMutation,
  useDeleteProviderIdElectricMutation,
  useDeleteProviderIdGasMutation,
  useEditProviderIdElectricMutation,
  useEditProviderIdGasMutation,
  useGetProviderIdsElectricQuery,
  useGetProviderIdsGasQuery,
} from '../redux/apis/providerIdApi';
import { Identifier } from '../types/ApiSchema';
import { HeadCell, OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import { pageToSkipLimit } from '../types/ApiPagination';
import {
  ProviderIdGas,
  ProviderIdGasFields,
  providerIdGasTableData,
} from '../types/ProviderIdGas';

export const ProviderId = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [searchParams, setSearchParams] = useSearchParams();
  const [openDialogDeleteElctr, setOpenDialogDeleteElctr] = useState<boolean>(false);
  const [openDialogDeleteGas, setOpenDialogDeleteGas] = useState<boolean>(false);
  const [openDialogAddElectric, setOpenDialogAddElectric] = useState<boolean>(false);
  const [typeEl, setTypeEl] = useState<'edit' | 'add'>('add');
  const [typeGas, setTypeGas] = useState<'edit' | 'add'>('add');
  const [electricTab, setElectricTab] = useState<ProviderIdElectric>();
  const [openDialogAddGas, setOpenDialogAddGas] = useState<boolean>(false);
  const [gasTab, setGasTab] = useState<ProviderIdGas>();

  const [orderEl, setOrderEl] = useState<OrderProps>('asc');
  const [orderByEl, setOrderByEl] = useState<HeadCell['id']>('updatedAt');
  const [pageEl, setPageEl] = useState<number>(0);

  const [orderGas, setOrderGas] = useState<OrderProps>('asc');
  const [orderByGas, setOrderByGas] = useState<HeadCell['id']>('updatedAt');
  const [pageGas, setPageGas] = useState<number>(0);

  const { data: providerIdsElectric } = useGetProviderIdsElectricQuery(
    pageToSkipLimit(pageEl, 5, [`${orderEl === 'asc' ? '-' : ''}${orderByEl}`]),
  );

  const { data: providerIdsGas } = useGetProviderIdsGasQuery(
    pageToSkipLimit(pageGas, 5, [`${orderGas === 'asc' ? '-' : ''}${orderByGas}`]),
  );

  const [addProviderIdElectric] = useAddProviderIdElectricMutation();
  const [editProviderIdElectric] = useEditProviderIdElectricMutation();
  const [deleteProviderIdElectric] = useDeleteProviderIdElectricMutation();

  const [addProviderIdGas] = useAddProviderIdGasMutation();
  const [editProviderIdGas] = useEditProviderIdGasMutation();
  const [deleteProviderIdGas] = useDeleteProviderIdGasMutation();

  const handleEditRowElectric = useCallback(
    (obj: TableProviderIdProps) => {
      const provider = providerIdsElectric?.documents?.find(p => p._id === obj._id);
      setTypeEl('edit');
      setOpenDialogAddElectric(true);
      setElectricTab(provider);
    },
    [providerIdsElectric?.documents],
  );

  const setDeleteObjElectric = useCallback(
    (obj: TableProviderIdProps) => {
      const provider = providerIdsElectric?.documents?.find(p => p._id === obj._id);
      setOpenDialogDeleteElctr(true);
      setElectricTab(provider);
    },
    [providerIdsElectric?.documents],
  );

  const handleDeleteRowGas = useCallback(() => {
    if (!gasTab || !gasTab?._id) return;
    const delete_id: Identifier = {
      _id: gasTab._id,
    };
    deleteProviderIdGas(delete_id);
    setOpenDialogDeleteGas(false);
  }, [deleteProviderIdGas, gasTab]);

  const setDeleteObjGas = useCallback(
    (obj: TableProviderIdGasProps) => {
      const provider = providerIdsGas?.documents?.find(p => p._id === obj._id);
      setOpenDialogDeleteGas(true);
      setGasTab(provider);
    },
    [providerIdsGas?.documents],
  );

  const handleDeleteRowElectric = useCallback(() => {
    if (!electricTab || !electricTab?._id) return;
    const delete_id: Identifier = {
      _id: electricTab._id,
    };
    deleteProviderIdElectric(delete_id);
    setOpenDialogDeleteElctr(false);
  }, [deleteProviderIdElectric, electricTab]);

  const handleEditRowGas = useCallback(
    (obj: TableProviderIdGasProps) => {
      const provider = providerIdsGas?.documents?.find(p => p._id === obj._id);
      setTypeGas('edit');
      setOpenDialogAddGas(true);
      setGasTab(provider);
    },
    [providerIdsGas?.documents],
  );

  const handleAddRowElectric = useCallback(() => {
    setTypeEl('add');
    setOpenDialogAddElectric(true);
    setElectricTab(undefined);
  }, []);

  const handleAddRowGas = useCallback(() => {
    setTypeGas('add');
    setOpenDialogAddGas(true);
    setGasTab(undefined);
  }, []);

  const addRowElectric = useCallback(() => {
    switch (typeEl) {
      case 'add':
        if (!electricTab) return;
        const addFields: ProviderIdElectricFields = {
          measurement: electricTab.measurement as ProviderIdElectricFields['measurement'],
          categoryOfSupply:
            electricTab.categoryOfSupply as ProviderIdElectricFields['categoryOfSupply'],
        };
        addProviderIdElectric(addFields);
        setOpenDialogAddElectric(false);
        break;
      case 'edit':
        if (!electricTab || !electricTab?._id) return;
        const editFields: Identifier & ProviderIdElectricFields = {
          _id: electricTab._id,
          measurement: electricTab.measurement as ProviderIdElectricFields['measurement'],
          categoryOfSupply:
            electricTab.categoryOfSupply as ProviderIdElectricFields['categoryOfSupply'],
        };
        editProviderIdElectric(editFields);
        setOpenDialogAddElectric(false);
        break;

      default:
        break;
    }
  }, [electricTab, typeEl, addProviderIdElectric, editProviderIdElectric]);

  const addRowGas = useCallback(() => {
    switch (typeGas) {
      case 'add':
        if (!gasTab) return;
        const addFields: ProviderIdGasFields = {
          measurement: gasTab.measurement as ProviderIdGasFields['measurement'],
          categoryOfSupply:
            gasTab.categoryOfSupply as ProviderIdGasFields['categoryOfSupply'],
        };
        addProviderIdGas(addFields);
        setOpenDialogAddGas(false);
        break;
      case 'edit':
        if (!gasTab || !gasTab?._id) return;
        const editFields: Identifier & ProviderIdGasFields = {
          _id: gasTab._id,
          measurement: gasTab.measurement as ProviderIdGasFields['measurement'],
          categoryOfSupply:
            gasTab.categoryOfSupply as ProviderIdGasFields['categoryOfSupply'],
        };
        editProviderIdGas(editFields);
        setOpenDialogAddGas(false);
        break;

      default:
        break;
    }
  }, [addProviderIdGas, editProviderIdGas, gasTab, typeGas]);

  const links = [{ name: 'Διαχείριση', href: '/' }, { name: 'Ταυτότητα Παροχής' }];

  const tabs = [
    {
      tabName: 'Ταυτότητα Παροχής Ηλεκτρικής Ενέργειας',
      tabContent: (
        <ElectricProviderIdTable
          setOpenAddDialog={handleAddRowElectric}
          setEditRow={handleEditRowElectric}
          dataProviderId={providerIdElectricTableData(providerIdsElectric?.documents)}
          setDeleteObjElectric={setDeleteObjElectric}
          order={orderEl}
          orderBy={orderByEl}
          page={pageEl}
          setOrder={setOrderEl}
          setOrderBy={setOrderByEl}
          setPage={setPageEl}
          countEl={providerIdsElectric?.count ?? 0}
        />
      ),
    },
    {
      tabName: 'Ταυτότητα Παροχής Φυσικού Αερίου',
      tabContent: (
        <GasProviderIdTable
          setOpenAddDialog={handleAddRowGas}
          setEditRow={handleEditRowGas}
          dataProviderId={providerIdGasTableData(providerIdsGas?.documents)}
          setDeleteObjGas={setDeleteObjGas}
          countGas={providerIdsGas?.count ?? 0}
          order={orderGas}
          orderBy={orderByGas}
          page={pageGas}
          setOrder={setOrderGas}
          setOrderBy={setOrderByGas}
          setPage={setPageGas}
        />
      ),
    },
  ];

  return (
    <Container maxWidth={'xl'} sx={{ p: 2 }}>
      <Box sx={{ mt: 4 }}>
        <BreadCrumbsCustom maxItems={matches ? 3 : 1} navigate={navigate} links={links} />
      </Box>

      <Box sx={{ mt: 4 }}>
        <CustomTabs
          tabs={tabs}
          newTab={Number(searchParams.get('t') ?? 0)}
          setActive={i => setSearchParams({ ...searchParams, t: i.toString() })}
        />
      </Box>

      <PopupForm
        maxWidth="md"
        InfoTootipProps={undefined}
        title={
          typeEl === 'edit'
            ? 'Επεξεργασία Ταυτότητας Παροχής'
            : 'Προσθήκη Ταυτότητας Παροχής'
        }
        helpText="Η ταυτότητα παροχής ορίζει τα βασικά στοιχεία του τιμολογίου όπως ορίζονται στα πρότυπα λογαριασμού."
        leftBtnTitle="Ακύρωση"
        rightBtnTitle={typeEl === 'edit' ? 'Αποθήκευση' : 'Προσθήκη'}
        open={openDialogAddElectric}
        setOpen={() => setOpenDialogAddElectric(true)}
        onClose={() => setOpenDialogAddElectric(false)}
        onClickLeftBtn={() => setOpenDialogAddElectric(false)}
        onClickRightBtn={addRowElectric}
      >
        <ElectricDialog setTab={setElectricTab} tab={electricTab} />
      </PopupForm>

      <PopupForm
        maxWidth="lg"
        InfoTootipProps={undefined}
        title={
          typeGas === 'edit'
            ? 'Επεξεργασία Ταυτότητας Παροχής'
            : 'Προσθήκη Ταυτότητας Παροχής'
        }
        helpText="Η ταυτότητα παροχής ορίζει τα βασικά στοιχεία του τιμολογίου όπως ορίζονται στα πρότυπα λογαριασμού."
        leftBtnTitle="Ακύρωση"
        rightBtnTitle={typeGas === 'edit' ? 'Αποθήκευση' : 'Προσθήκη'}
        open={openDialogAddGas}
        setOpen={() => setOpenDialogAddGas(true)}
        onClose={() => setOpenDialogAddGas(false)}
        onClickLeftBtn={() => setOpenDialogAddGas(false)}
        onClickRightBtn={addRowGas}
      >
        <GasDialog setTab={setGasTab} tab={gasTab} />
      </PopupForm>

      <PopupConfirm
        title="Διαγραφή Ταυτότητας Παροχής"
        subTile="Είστε σίγουρος ότι θέλετε να προχωρήσετε στη διαγραφή της συγκεκριμένης Ταυτότητας Παροχής"
        leftBtnTitle="Οχι"
        rightBtnTitle="Ναι"
        open={openDialogDeleteElctr}
        setOpen={setOpenDialogDeleteElctr}
        onClose={() => setOpenDialogDeleteElctr(false)}
        onClickLeftBtn={() => setOpenDialogDeleteElctr(false)}
        onClickRightBtn={handleDeleteRowElectric}
      />

      <PopupConfirm
        title="Διαγραφή Ταυτότητας Παροχής"
        subTile="Είστε σίγουρος ότι θέλετε να προχωρήσετε με τη διαγραφή της Ταυτότητας Παροχής; Τιμολόγια που κάνουν χρήση της συγκεκριμένης ταυτότητας παροχής θα διαγραφούν και αυτά και ΔΕΝ υπάρχει δυνατότητα επαναφοράς."
        leftBtnTitle="Οχι"
        rightBtnTitle="Ναι"
        open={openDialogDeleteGas}
        setOpen={setOpenDialogDeleteGas}
        onClose={() => setOpenDialogDeleteGas(false)}
        onClickLeftBtn={() => setOpenDialogDeleteGas(false)}
        onClickRightBtn={handleDeleteRowGas}
      />
    </Container>
  );
};
