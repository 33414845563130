import React, { FC, useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ModalProps,
  useTheme,
} from '@mui/material';
import { ButtonRae, HelpComponent } from '@rae/ui-library';

type Props = Omit<ModalProps, 'children'> & {
  title?: string;
  qrText?: string;
  fileName?: string;
  helpText?: string;
  open: boolean;
  setOpen: (newState: boolean) => void;
};

export const DownloadQRDialog: FC<Props> = ({
  title,
  qrText,
  fileName,
  helpText,
  open,
  setOpen,
  ...props
}) => {
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const handleDownload = () => {
    const canvas = canvasContainerRef.current?.children?.[0] as HTMLCanvasElement;
    const pngFile = canvas.toDataURL('image/png');

    const downloadLink = document.createElement('a');
    downloadLink.download = `${fileName}.png`;
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={() => setOpen(false)}
      open={open}
      {...props}
    >
      <Box
        sx={{
          height: 76,
          backgroundColor: theme.palette.primary.main,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <DialogTitle
          variant={'h6'}
          sx={{ textAlign: 'center', width: '-webkit-fill-available' }}
          color={theme.palette.grey[100]}
        >
          {title}
        </DialogTitle>
      </Box>
      <DialogContent sx={{ textAlign: 'center' }}>
        <div ref={canvasContainerRef}>
          {qrText ? <QRCodeCanvas value={qrText} includeMargin size={256} /> : null}
        </div>
        {(title || qrText) && helpText ? (
          <Divider sx={{ mt: 4 }} variant="middle" />
        ) : null}
        {helpText ? <HelpComponent helpText={helpText} icon="lamp-on" /> : null}
      </DialogContent>
      <Box sx={{ mt: 4, mb: 4, display: 'flex', justifyContent: 'center' }}>
        <DialogActions>
          <ButtonRae
            onClick={() => setOpen(false)}
            size="large"
            sx={{ mr: 1, width: 148 }}
            color="secondary"
            variant="contained"
          >
            Κλείσιμο
          </ButtonRae>
          <ButtonRae
            disabled={!qrText}
            onClick={handleDownload}
            size="large"
            sx={{ width: 148 }}
            color="primary"
            variant="contained"
          >
            Κατέβασμα
          </ButtonRae>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
