import {
  ChangeEmailData,
  PasswordData,
  ProfileData,
  ProfileErrorMsg,
  ShowProfileErrorProps,
} from '../components/settingPage/SettingCards';

type SettingPageProfileValidationsProps = {
  profile: ProfileData;
  setShowError: (newState: any) => void;
  setErrorMsg: (newState: any) => void;
};

type SettingPageEmailValidationsProps = {
  email: ChangeEmailData;
  setShowError: (newState: any) => void;
  setErrorMsg: (newState: any) => void;
};

type SettingPagePassValidationsProps = {
  pass: PasswordData;
  setShowError: (newState: any) => void;
  setErrorMsg: (newState: any) => void;
};

export const profileValidations = ({
  profile,
  setErrorMsg,
  setShowError,
}: SettingPageProfileValidationsProps) => {
  const numberOnlyRegEx: RegExp = /^[0-9]{1,10}$/;

  let errorFlag = false;

  setShowError({});
  setErrorMsg({});

  if (!profile.firstName) {
    setShowError((prev: ShowProfileErrorProps) => ({
      ...prev,
      firstName: true,
    }));
    setErrorMsg((prev: ProfileErrorMsg) => ({
      ...prev,
      firstName: 'Πρέπει να συμπληρωθεί το όνομα',
    }));
    errorFlag = true;
  }
  if (!profile.lastName) {
    setShowError((prev: ShowProfileErrorProps) => ({
      ...prev,
      lastName: true,
    }));
    setErrorMsg((prev: ProfileErrorMsg) => ({
      ...prev,
      lastName: 'Πρέπει να συμπληρωθεί το επώνυμο',
    }));
    errorFlag = true;
  }
  if (!profile.contactPhone) {
    setShowError((prev: ShowProfileErrorProps) => ({
      ...prev,
      contactPhone: true,
    }));
    setErrorMsg((prev: ProfileErrorMsg) => ({
      ...prev,
      contactPhone: 'Πρέπει να συμπληρωθεί ο αριθμός τηλεφώνου',
    }));
    errorFlag = true;
  } else {
    if (!numberOnlyRegEx.test(profile.contactPhone)) {
      setShowError((prev: ShowProfileErrorProps) => ({
        ...prev,
        contactPhone: true,
      }));
      setErrorMsg((prev: ProfileErrorMsg) => ({
        ...prev,
        contactPhone: 'Μην έγκυρος αριθμός τηλεφώνου',
      }));
      errorFlag = true;
    }
  }
  return errorFlag;
};

export const emailValidations = ({
  email,
  setErrorMsg,
  setShowError,
}: SettingPageEmailValidationsProps) => {
  const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  let errorFlag = false;

  setShowError({});
  setErrorMsg({});

  if (!email.email) {
    setShowError((prev: ShowProfileErrorProps) => ({
      ...prev,
      email: true,
    }));
    setErrorMsg((prev: ProfileErrorMsg) => ({
      ...prev,
      email: 'Πρέπει να συμπληρωθεί το email',
    }));
    errorFlag = true;
  } else {
    if (!expression.test(email.email)) {
      setShowError((prev: ShowProfileErrorProps) => ({
        ...prev,
        email: true,
      }));
      setErrorMsg((prev: ProfileErrorMsg) => ({
        ...prev,
        email: 'Μη έγκυρο email',
      }));
      errorFlag = true;
    }
  }
  return errorFlag;
};

export const changePassValidations = ({
  pass,
  setErrorMsg,
  setShowError,
}: SettingPagePassValidationsProps) => {
  let errorFlag = false;

  setShowError({});
  setErrorMsg({});
  if (pass.confirmPass !== pass.newPass) {
    setShowError((prev: ShowProfileErrorProps) => ({
      ...prev,
      newPass: true,
      confirmPass: true,
    }));
    setErrorMsg((prev: ProfileErrorMsg) => ({
      ...prev,
      confirmPass: 'Ο κωδικός επιβεβαίωσης δεν ταιριάζει με τον νέο κωδικό πρόσβασης',
    }));
    errorFlag = true;
  }
  if (pass.currentPass === pass.newPass) {
    setShowError((prev: ShowProfileErrorProps) => ({
      ...prev,
      newPass: true,
      currentPass: true,
    }));
    setErrorMsg((prev: ProfileErrorMsg) => ({
      ...prev,
      newPass:
        'Ο νέος κωδικός πρόσβασης δεν μπορεί να είναι ίδιος με τον τρέχων κωδικό πρόσβασης',
    }));
    errorFlag = true;
  }
  return errorFlag;
};
