import { CustomTable } from '@rae/ui-library';
import { HeadCell, OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import { TableGasProvidersProps } from '@rae/ui-library/src/components/table/TableRowGasProviders';
import { TableRegulatedChargesGasProps } from '@rae/ui-library/src/components/table/TableRowRegulatedChargesGas';
import React, { useState } from 'react';
import { ActionsProps, GasChargesSearchProps } from '../../../pages/RegularChargesGas';

type DistributionGasChargesProps = {
  search: GasChargesSearchProps;
  setSearch: (newState: any) => void;
  handleActionsGasDistributionCharges: (
    obj: TableRegulatedChargesGasProps,
    action: ActionsProps['tab4']['type'],
  ) => void;
  data: TableRegulatedChargesGasProps[];
  optionsProvider?: TableGasProvidersProps[];
};

export const DistributionGasChargesTable = ({
  search,
  setSearch,
  handleActionsGasDistributionCharges,
  data,
  optionsProvider,
}: DistributionGasChargesProps) => {
  const [order, setOrder] = useState<OrderProps>('asc');
  const [orderBy, setOrderBy] = useState<HeadCell['id']>('test');
  const [page, setPage] = useState<number>(0);
  const [openProvider, setOpenProvider] = useState<boolean>(false);
  const [openZone, setOpenZone] = useState<boolean>(false);
  const [openCtegory, setOpenCategory] = useState<boolean>(false);

  const dropDowns = [
    {
      choices: Array.from(new Set(optionsProvider?.map(i => i.providerName || ''))),
      open: openProvider,
      selected: search.distributionGasCharges.dfa,
      setOpen: setOpenProvider,
      setSelected: (type: any) =>
        setSearch((values: GasChargesSearchProps) => ({
          ...values,
          distributionGasCharges: {
            ...values.distributionGasCharges,
            dfa: type,
          },
        })),
      label: 'ΔΦΑ',
      multiple: true,
      labelBtn: 'Εφαρμογή',
    },
    {
      choices: ['Βόρεια', 'Νότια'],
      open: openZone,
      selected: search.distributionGasCharges.zone,
      setOpen: setOpenZone,
      setSelected: (type: any) =>
        setSearch((values: GasChargesSearchProps) => ({
          ...values,
          distributionGasCharges: {
            ...values.distributionGasCharges,
            zone: type,
          },
        })),
      label: 'Ζώνη',
      multiple: true,
      labelBtn: 'Εφαρμογή',
    },
    {
      choices: Array.from(
        new Set(
          data.map(i =>
            i.category
              ? i.category === 'home'
                ? 'Οικιακή'
                : i.category === 'business'
                ? 'Επαγγελματική'
                : 'Κεντρική'
              : '',
          ),
        ),
      ),
      open: openCtegory,
      selected: search.distributionGasCharges.category,
      setOpen: setOpenCategory,
      setSelected: (type: any) =>
        setSearch((values: GasChargesSearchProps) => ({
          ...values,
          distributionGasCharges: {
            ...values.distributionGasCharges,
            category: type,
          },
        })),
      label: 'Κατηγορία Παροχής',
      multiple: false,
      labelBtn: 'Εφαρμογή',
    },
  ];

  return (
    <CustomTable
      title=""
      handleView={(obj: TableRegulatedChargesGasProps) =>
        handleActionsGasDistributionCharges(obj, 'view')
      }
      searchInput={{
        onChange: e =>
          setSearch((values: GasChargesSearchProps) => ({
            ...values,
            distributionGasCharges: {
              ...values.distributionGasCharges,
              searchValue: e.target.value,
            },
          })),
        value: search.distributionGasCharges.searchValue,
        type: 'search',
        label: 'Αναζήτηση ',
      }}
      rows={data}
      type={'distribution-gas-charges'}
      count={data.length}
      headCells={[
        { disable: false, id: 'city', label: 'Περιοχή / Πόλη' },
        { disable: false, id: 'category', label: 'Κατηγορία Παροχής' },

        {
          disable: true,
          id: 'energyCharges',
          label: 'Χρεώση Ενέργειας',
        },

        { disable: true, id: 'capacityCharge', label: 'Χρεώση Δυναμικότητας' },
        { disable: true, id: 'penetrationSurcharge', label: 'Προσαύξηση Διείσδυσης' },
        {
          disable: true,
          id: 'remoteNetworksCharge',
          label: 'Συντελεστής Απομακρ. Δικτύων',
        },
        { disable: true, id: 'mod', label: 'Τροποποίηση' },
        { disable: true, id: 'actions', label: 'Ενέργειες' },
      ]}
      dropDowns={dropDowns}
      order={order}
      orderBy={orderBy}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
      page={page}
      rowsPerPage={5}
      setPage={setPage}
    />
  );
};
