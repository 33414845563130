// Access Token
export const getAccessToken = (): string | undefined => {
  return localStorage.getItem('accessToken') ?? undefined;
};

export const setAccessToken = (accessToken: string) => {
  return localStorage.setItem('accessToken', accessToken);
};

export const removeAccessToken = () => {
  return localStorage.removeItem('accessToken');
};

// Refresh Token
export const getRefreshToken = (): string | undefined => {
  return localStorage.getItem('refreshToken') ?? undefined;
};

export const setRefreshToken = (refreshToken: string) => {
  return localStorage.setItem('refreshToken', refreshToken);
};

export const removeRefreshToken = () => {
  return localStorage.removeItem('refreshToken');
};
