import api from './api';
import {
  ProviderIdElectric,
  ProviderIdElectricFields,
} from '../../types/ProviderIdElectric';
import { ApiPagination, PaginationResult } from '../../types/ApiPagination';
import { Identifier } from '../../types/ApiSchema';
import { ProviderIdGas, ProviderIdGasFields } from '../../types/ProviderIdGas';

const providerIdApi = api
  .enhanceEndpoints({ addTagTypes: ['ProviderIdElectric', 'ProviderIdsGas'] })
  .injectEndpoints({
    endpoints: builder => ({
      getProviderIdsElectric: builder.query<
        PaginationResult<ProviderIdElectric>,
        ApiPagination
      >({
        query: params => ({
          url: `raeService/providers/electric`,
          method: 'GET',
          params: { ...params },
        }),
        providesTags: result =>
          result?.documents
            ? [
                ...result.documents.map(
                  ({ _id }) => ({ type: 'ProviderIdElectric', _id } as const),
                ),
                { type: 'ProviderIdElectric', id: 'LIST' },
              ]
            : [{ type: 'ProviderIdElectric', id: 'LIST' }],
      }),
      addProviderIdElectric: builder.mutation<
        ProviderIdElectric,
        ProviderIdElectricFields
      >({
        query: params => ({
          url: `database/ProviderIdElectric`,
          method: 'POST',
          body: params,
        }),
        invalidatesTags: [{ type: 'ProviderIdElectric', id: 'LIST' }],
      }),
      editProviderIdElectric: builder.mutation<
        ProviderIdElectric,
        Identifier & ProviderIdElectricFields
      >({
        query: ({ _id, ...params }) => ({
          url: `database/ProviderIdElectric/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [
          { type: 'ProviderIdElectric', _id },
        ],
      }),
      deleteProviderIdElectric: builder.mutation<void, Identifier>({
        query: ({ _id }) => ({
          url: `raeService/providers/electric/${_id}/archive`,
          method: 'PATCH',
        }),
        invalidatesTags: (result, error, { _id }) => [
          { type: 'ProviderIdElectric', _id },
        ],
      }),
      getProviderIdsGas: builder.query<PaginationResult<ProviderIdGas>, ApiPagination>({
        query: params => ({
          url: `raeService/providers/gas`,
          method: 'GET',
          params: params,
        }),
        providesTags: result =>
          result?.documents
            ? [
                ...result.documents.map(
                  ({ _id }) => ({ type: 'ProviderIdsGas', _id } as const),
                ),
                { type: 'ProviderIdsGas', id: 'LIST' },
              ]
            : [{ type: 'ProviderIdsGas', id: 'LIST' }],
      }),
      addProviderIdGas: builder.mutation<ProviderIdGas, ProviderIdGasFields>({
        query: params => ({
          url: `database/ProviderIdGas`,
          method: 'POST',
          body: params,
        }),
        invalidatesTags: [{ type: 'ProviderIdsGas', id: 'LIST' }],
      }),
      editProviderIdGas: builder.mutation<
        ProviderIdGas,
        Identifier & ProviderIdGasFields
      >({
        query: ({ _id, ...params }) => ({
          url: `database/ProviderIdGas/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'ProviderIdsGas', _id }],
      }),
      deleteProviderIdGas: builder.mutation<void, Identifier>({
        query: ({ _id }) => ({
          url: `raeService/providers/gas/${_id}/archive`,
          method: 'PATCH',
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'ProviderIdsGas', _id }],
      }),
    }),
  });

export const {
  useGetProviderIdsElectricQuery,
  useAddProviderIdElectricMutation,
  useEditProviderIdElectricMutation,
  useDeleteProviderIdElectricMutation,
  useGetProviderIdsGasQuery,
  useAddProviderIdGasMutation,
  useEditProviderIdGasMutation,
  useDeleteProviderIdGasMutation,
} = providerIdApi;

export default providerIdApi;
