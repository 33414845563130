import { Box } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackGround from '../assets/loginBG.png';
import {
  LoginCard,
  LoginErrorMsg,
  ShowLoginErrorProps,
} from '../components/LoginPage/LoginCard';
import {
  useLazyGetTeamQuery,
  useLazyGetUserQuery,
  useLoginMutation,
} from '../redux/apis/authApi';
import { loginPageValidations } from '../validations/LoginPageValidations';

export type LoginUserProps = {
  email?: string;
  password?: string;
};

export const LoginPage = () => {
  const [login, { isLoading: loginIsLoading }] = useLoginMutation();
  const [getUser, { isLoading: getUserIsLoading }] = useLazyGetUserQuery();
  const [getTeam, { isLoading: getTeamIsLoading }] = useLazyGetTeamQuery();

  const isLoading = loginIsLoading || getUserIsLoading || getTeamIsLoading;
  let navigate = useNavigate();

  const [loginUser, setLoginUser] = useState<LoginUserProps>({});
  const [showError, setShowError] = useState<ShowLoginErrorProps>({});
  const [errorMsg, setErrorMsg] = useState<LoginErrorMsg>({});

  const handleLogin = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!loginPageValidations({ loginUser, setErrorMsg, setShowError })) {
        try {
          await login(loginUser).unwrap();
          const user = await getUser().unwrap();
          if (user) {
            getTeam();
            navigate(`/`);
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    [getTeam, getUser, login, loginUser, navigate],
  );

  return (
    <Box
      sx={{
        height: '100vh',
        backgroundImage: `url(${BackGround})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        filter: `drop-shadow(-26px 0px 10px rgba(190, 190, 190, 0.06))`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LoginCard
        isLoading={isLoading}
        errorMsg={errorMsg}
        showError={showError}
        handleLogin={handleLogin}
        loginUser={loginUser}
        setLoginUset={setLoginUser}
      />
    </Box>
  );
};
