import { Box, Container, Drawer } from '@mui/material';
import {
  BreadCrumbsCustom,
  CustomChip,
  CustomChipNew,
  CustomTable,
  CustomTabs,
  PopupConfirm,
  PopupForm,
  Tooltip,
} from '@rae/ui-library';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { OtherChargesDialog } from '../components/AssetsMngment/OthesChargesDialog';
import { TableOtherChargesProps } from '@rae/ui-library/src/components/table/TableRowOtherCharges';
import { TableHistChargesEL } from '@rae/ui-library/src/components/table/TableRowHistChargesEl';
import { TableHistChargesGasProps } from '@rae/ui-library/src/components/table/TableRowHistChargesGas';
import { DrawerElectric } from '../components/AssetsMngment/DrawerElectric';
import { DrawerGas } from '../components/AssetsMngment/DrawerGas';
import { pageToSkipLimit } from '../types/ApiPagination';
import { HeadCell, OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import {
  useAddOtherChargesMutation,
  useDeleteOtherChargesMutation,
  useEditOtherChargesMutation,
  useGetOtherChargesQuery,
} from '../redux/apis/OtherChargesApi';
import {
  addOtherChargesFields,
  editOtherChargesFields,
  gasOtherCharges,
  otherChargesHistoryElectricDetails,
  otherChargesHistoryGasDetails,
} from '../types/OtherCharges';
import Loader from '../assets/loading.gif';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { setLog } from '../redux/slices/appSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useDebounce from '../utils/useDebounce';
import { useGetTeamsQuery } from '../redux/apis/teamApi';
import { useGetOtherChargesHistoricityQuery } from '../redux/apis/productApi';
import {
  categoryOfSupplyGasLabel,
  categoryOfSupplyLabel,
} from '../types/CategoryOfSupply';

export type otherChargesFilterProps = { search: string; type: string[] };
export type histElFilterProps = {
  search: string;
  type: string[];
  provider: string[];
  category: string[];
};
export type histGasFilterProps = {
  search: string;
  type: string[];
  provider: string[];
  category: string[];
};
export type ActionProps = 'add' | 'edit';
export const Assetsmngment = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [orderOtherCharges, setOrderOtherCharges] = useState<OrderProps>('asc');
  const [orderByOtherCharges, setOrderByOtherCharges] =
    useState<HeadCell['id']>('updatedAt');
  const [pageOtherCharges, setPageOtherCharges] = useState<number>(0);
  const [pageOtherChargesHist, setPageOtherChargesHist] = useState<number>(0);
  const [pageOtherChargesHistGas, setPageOtherChargesHistGas] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [openOtherChargesDialog, setOpenOthesChargesDialog] = useState<boolean>(false);
  const [otherChargesSearch, setOtherChargesSearch] = useState<string>('');
  const [otherChargesType, setOtherChargesType] = useState<string[]>([]);
  const [otherChargesData, setOtherChargesData] = useState<TableOtherChargesProps>({});
  const [otherChargesAction, setOtherChargesAction] = useState<ActionProps>('add');
  const [openDeleteDialogOtherCharges, setOpenDeleteDialogOtherCharges] =
    useState<boolean>(false);
  const [openTypeOtherCharges, setOpenTypeOtherCharges] = useState<boolean>(false);

  const debouncedSearch = useDebounce<string>(otherChargesSearch, 300);

  const [histElFilters, setHistElFilters] = useState<histElFilterProps>({
    search: '',
    category: [],
    provider: [],
    type: [],
  });
  const [histElId, setHistElId] = useState<string>();
  const [histElRecord, setHistElRecord] = useState<string>();

  const [histGasFilters, setHistGasFilters] = useState<histGasFilterProps>({
    search: '',
    category: [],
    provider: [],
    type: [],
  });
  const [histGasId, setHistGasId] = useState<string>();
  const [histGasRecord, setHistGasRecord] = useState<string>();

  const { data: otherCharges, isLoading: getOtherChargesIsLoading } =
    useGetOtherChargesQuery({
      ...pageToSkipLimit(pageOtherCharges, 5, [
        `${orderOtherCharges === 'asc' ? '-' : ''}${orderByOtherCharges}`,
      ]),
      search: debouncedSearch,
      energyType: otherChargesType?.[0] ?? undefined,
    });
  const { data: otherChargesAll } = useGetOtherChargesQuery({
    ...pageToSkipLimit(0, 1000),
  });
  const { data: providers } = useGetTeamsQuery({
    ...pageToSkipLimit(0, 1000),
  });
  const { data: otherChargesHist, isLoading: otherChargesHistIsLoading } =
    useGetOtherChargesHistoricityQuery({
      ...pageToSkipLimit(pageOtherChargesHist, 5),
      type: 'electric',
      otherCharges: histElFilters.type?.[0],
      categoryOfSupply: histElFilters.category?.[0],
      team: histElFilters.provider?.[0],
    });
  const { data: otherChargesHistGas, isLoading: otherChargesHistGasIsLoading } =
    useGetOtherChargesHistoricityQuery({
      ...pageToSkipLimit(pageOtherChargesHistGas, 5),
      type: 'gas',
      otherCharges: histGasFilters.type?.[0],
      categoryOfSupply: histGasFilters.category?.[0],
      team: histGasFilters.provider?.[0],
    });
  const [addOtherCharges, { isLoading: addOtherChargesLoading }] =
    useAddOtherChargesMutation();
  const [editOtherCharges, { isLoading: editOtherChargesLoading }] =
    useEditOtherChargesMutation();
  const [deleteOtherCharges, { isLoading: deleteOtherChargesLoading }] =
    useDeleteOtherChargesMutation();

  const links = [{ name: 'Διαχείριση', href: '/' }, { name: 'Άλλων Χρεώσεων' }];

  useEffect(() => {
    setActiveTab(Number(searchParams.get('t') ?? 0));
  }, [searchParams]);

  const handleOtherChargesDialogEdit = useCallback((obj: TableOtherChargesProps) => {
    setOtherChargesData(obj);
    setOpenOthesChargesDialog(true);
    setOtherChargesAction('edit');
  }, []);

  const handleOtherChargesDialogAdd = useCallback(() => {
    setOtherChargesData({});
    setOpenOthesChargesDialog(true);
    setOtherChargesAction('add');
  }, []);

  const handleCloseOtherChargesDialog = useCallback(() => {
    setOtherChargesData({});
    setOpenOthesChargesDialog(false);
  }, []);

  const handleDeleteOtherCharge = useCallback((obj: TableOtherChargesProps) => {
    setOtherChargesData(obj);
    setOpenDeleteDialogOtherCharges(true);
  }, []);

  const deleteOtherCharge = useCallback(async () => {
    setOpenDeleteDialogOtherCharges(false);
    if (!otherChargesData?._id) return;
    try {
      await deleteOtherCharges({ _id: otherChargesData?._id }).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: `Η χρέωση διαγράφθηκε επιτυχώς`,
        }),
      );
    } catch (error: any) {
      console.log(error);
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setOtherChargesData({});
  }, [deleteOtherCharges, dispatch, otherChargesData?._id]);

  const handleActionOtherCharges = useCallback(async () => {
    setOpenOthesChargesDialog(false);
    switch (otherChargesAction) {
      case 'add':
        if (!otherChargesData?.chargeName || !otherChargesData.energyType) return;
        const addFields = addOtherChargesFields(otherChargesData);
        try {
          await addOtherCharges(addFields).unwrap();
          dispatch(
            setLog({
              severity: 'success',
              message: `Η χρέωση αποθηκεύτηκε επιτυχώς`,
            }),
          );
        } catch (error: any) {
          console.log(error);
          dispatch(
            setLog({
              severity: 'error',
              message: error.data.message,
            }),
          );
        }
        setOtherChargesData({});
        break;
      case 'edit':
        if (
          !otherChargesData?._id ||
          !otherChargesData?.chargeName ||
          !otherChargesData.energyType
        )
          return;
        const editFields = editOtherChargesFields(otherChargesData);
        try {
          await editOtherCharges(editFields).unwrap();
          dispatch(
            setLog({
              severity: 'success',
              message: `Η χρέωση αποθηκεύτηκε επιτυχώς`,
            }),
          );
        } catch (error: any) {
          console.log(error);
          dispatch(
            setLog({
              severity: 'error',
              message: error.data.message,
            }),
          );
        }
        setOtherChargesData({});
        break;
      default:
        break;
    }
  }, [addOtherCharges, dispatch, editOtherCharges, otherChargesAction, otherChargesData]);

  const openHistoryElectricDrawer = useCallback(
    (obj: TableHistChargesEL, options: string) => {
      setHistElId(obj.id);
      setHistElRecord(options);
    },
    [],
  );

  const openHistoryGasDrawer = useCallback(
    (obj: TableHistChargesGasProps, options: string) => {
      setHistGasId(obj.id);
      setHistGasRecord(options);
    },
    [],
  );

  const histElObj = useMemo(() => {
    if (!histElId) return undefined;
    return otherChargesHistoryElectricDetails(
      otherChargesHist?.products?.find(el => el._id === histElId),
    );
  }, [histElId, otherChargesHist]);

  const histGasObj = useMemo(() => {
    if (!histGasId) return undefined;
    return otherChargesHistoryGasDetails(
      otherChargesHistGas?.products?.find(el => el._id === histGasId),
    );
  }, [histGasId, otherChargesHistGas]);

  const tabs = [
    {
      tabName: 'Άλλες Χρεώσεις ',
      tabContent: (
        <CustomTable
          dropDownsNew={[
            {
              choices: [
                { label: 'Ηλεκτρική Ενέργεια', value: 'electric' },
                { label: 'Φυσικό Αέριο', value: 'gas' },
              ],
              open: openTypeOtherCharges,
              selected: otherChargesType,
              setOpen: setOpenTypeOtherCharges,
              setSelected: setOtherChargesType,
              label: 'Τύπος',
              multiple: false,
              labelBtn: 'Εφαρμογή',
            },
          ]}
          headCells={[
            { disable: false, id: 'chargeName', label: 'Όνομα Χρέωσης' },
            { disable: false, id: 'chargeInfo', label: 'Πληροφορίες Χρεώσης' },
            { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
            { disable: true, id: 'actions', label: 'Ενέργειες' },
          ]}
          count={otherCharges?.count ?? 0}
          handleEdit={(obj: TableOtherChargesProps) => handleOtherChargesDialogEdit(obj)}
          handleDelete={(obj: TableOtherChargesProps) => handleDeleteOtherCharge(obj)}
          type="other-charges"
          rows={gasOtherCharges(otherCharges?.documents)}
          title=""
          searchInput={{
            onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
              setOtherChargesSearch((event.target as HTMLInputElement).value),
            value: otherChargesSearch,
            type: 'search',
            label: 'Αναζήτηση ',
          }}
          buttonProps={{
            variant: 'contained',
            onClick: handleOtherChargesDialogAdd,
            children: 'Προσθήκη Άλλης Χρέωσης',
          }}
          order={orderOtherCharges}
          orderBy={orderByOtherCharges}
          page={pageOtherCharges}
          setOrder={setOrderOtherCharges}
          setOrderBy={setOrderByOtherCharges}
          setPage={setPageOtherCharges}
          rowsPerPage={5}
        />
      ),
    },
    // {
    //   tabName: 'Ιστορικότητα Άλλων Χρεώσεων ΗΕ',
    //   tabContent: (
    //     <HistOtherChargesElTable
    //       data={otherChargesHistoryElectric(otherChargesHist?.products)}
    //       page={pageOtherChargesHist}
    //       setPage={setPageOtherChargesHist}
    //       filter={histElFilters}
    //       setFilter={setHistElFilters}
    //       openDrawer={openHistoryElectricDrawer}
    //       count={otherChargesHist?.count ?? 0}
    //       teams={providers?.documents}
    //       otherCharges={otherChargesAll?.documents}
    //     />
    //   ),
    // },
    // {
    //   tabName: 'Ιστορικότητα Άλλων Χρεώσεων ΦΑ',
    //   tabContent: (
    //     <HistOtherChargesGasTable
    //       data={otherChargesHistoryGas(otherChargesHistGas?.products)}
    //       page={pageOtherChargesHistGas}
    //       setPage={setPageOtherChargesHistGas}
    //       filter={histGasFilters}
    //       setFilter={setHistGasFilters}
    //       openDrawer={openHistoryGasDrawer}
    //       count={otherChargesHistGas?.count ?? 0}
    //       teams={providers?.documents}
    //       otherCharges={otherChargesAll?.documents}
    //     />
    //   ),
    // },
  ];

  const filterOtherCharges = useMemo(
    () => otherChargesType.concat(otherChargesSearch !== '' ? [otherChargesSearch] : []),
    [otherChargesSearch, otherChargesType],
  );

  const filterOtherChargesHist = useMemo(
    () =>
      histElFilters.category
        .map(c => ({
          label: categoryOfSupplyLabel(c).gr,
          value: c,
        }))
        .concat(
          histElFilters.provider.map(p => ({
            label: providers?.documents?.find(pr => pr._id === p)?.nameGr ?? '',
            value: p,
          })),
          histElFilters.type.map(c => ({
            label: otherChargesAll?.documents?.find(cf => cf._id === c)?.chargeName ?? '',
            value: c,
          })),
        ),
    [
      otherChargesAll?.documents,
      providers?.documents,
      histElFilters.category,
      histElFilters.provider,
      histElFilters.type,
    ],
  );

  const filterOtherChargesGas = useMemo(
    () =>
      histGasFilters.category
        .map(c => ({
          label: categoryOfSupplyGasLabel(c).gr,
          value: c,
        }))
        .concat(
          histGasFilters.provider.map(p => ({
            label: providers?.documents?.find(pr => pr._id === p)?.nameGr ?? '',
            value: p,
          })),
          histGasFilters.type.map(c => ({
            label: otherChargesAll?.documents?.find(cf => cf._id === c)?.chargeName ?? '',
            value: c,
          })),
        ),
    [
      otherChargesAll?.documents,
      providers?.documents,
      histGasFilters.category,
      histGasFilters.provider,
      histGasFilters.type,
    ],
  );

  const clearFiltersOtherCharges = useCallback(() => {
    setOtherChargesSearch('');
    setOtherChargesType([]);
  }, []);

  const clearFiltersOtherChargesHist = useCallback(() => {
    setHistElFilters((values: histElFilterProps) => ({
      ...values,
      search: '',
      category: [],
      provider: [],
      type: [],
    }));
  }, []);

  const clearFiltersOtherChargesHistGas = useCallback(() => {
    setHistGasFilters((values: histGasFilterProps) => ({
      ...values,
      search: '',
      category: [],
      provider: [],
      type: [],
    }));
  }, []);

  const removeChipOtherCharges = useCallback((chipLabel: string) => {
    switch (chipLabel) {
      case 'search':
        setOtherChargesSearch('');
        break;
      case 'type':
        setOtherChargesType([]);
        break;
      default:
        break;
    }
  }, []);

  const removeChipEl = useCallback((chipLabel: string) => {
    setHistElFilters(values => ({
      ...values,
      search: values.search === chipLabel ? '' : values.search,
      category: values.category.filter(label => label !== chipLabel),
      provider: values.provider.filter(label => label !== chipLabel),
      type: values.type.filter(label => label !== chipLabel),
    }));
  }, []);

  const removeChipGas = useCallback((chipLabel: string) => {
    setHistGasFilters(values => ({
      ...values,
      search: values.search === chipLabel ? '' : values.search,
      category: values.category.filter(label => label !== chipLabel),
      provider: values.provider.filter(label => label !== chipLabel),
      type: values.type.filter(label => label !== chipLabel),
    }));
  }, []);

  const renderTooltip = useMemo(() => {
    switch (activeTab) {
      case 0:
        return filterOtherCharges.length > 0 ? (
          <Tooltip
            text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
            type="info"
            btnAction={clearFiltersOtherCharges}
            btnLabel="Καθαρισμός Φίλτρων"
            dismiss={true}
            icon={<InfoOutlinedIcon fontSize="small" />}
          />
        ) : null;
      case 1:
        return filterOtherChargesHist.length > 0 ? (
          <Tooltip
            text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
            type="info"
            btnAction={clearFiltersOtherChargesHist}
            btnLabel="Καθαρισμός Φίλτρων"
            dismiss={true}
            icon={<InfoOutlinedIcon fontSize="small" />}
          />
        ) : null;
      case 2:
        return filterOtherChargesGas.length > 0 ? (
          <Tooltip
            text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
            type="info"
            btnAction={clearFiltersOtherChargesHistGas}
            btnLabel="Καθαρισμός Φίλτρων"
            dismiss={true}
            icon={<InfoOutlinedIcon fontSize="small" />}
          />
        ) : null;
      default:
        break;
    }
  }, [
    activeTab,
    clearFiltersOtherCharges,
    clearFiltersOtherChargesHist,
    clearFiltersOtherChargesHistGas,
    filterOtherCharges.length,
    filterOtherChargesGas.length,
    filterOtherChargesHist.length,
  ]);

  const renderChips = useMemo(() => {
    switch (activeTab) {
      case 0:
        return filterOtherCharges
          ? filterOtherCharges.map((item, i) => {
              return (
                <CustomChip
                  key={i}
                  label={item}
                  onDelete={() => removeChipOtherCharges(item)}
                />
              );
            })
          : null;
      case 1:
        return filterOtherChargesHist
          ? filterOtherChargesHist.map((item, i) => {
              return (
                <CustomChipNew
                  key={i}
                  label={item.label}
                  value={item.value}
                  onDelete={() => removeChipEl(item.value)}
                />
              );
            })
          : null;
      case 2:
        return filterOtherChargesGas
          ? filterOtherChargesGas.map((item, i) => {
              return (
                <CustomChipNew
                  key={i}
                  label={item.label}
                  value={item.value}
                  onDelete={() => removeChipGas(item.value)}
                />
              );
            })
          : null;
      default:
        break;
    }
  }, [
    activeTab,
    filterOtherCharges,
    filterOtherChargesGas,
    filterOtherChargesHist,
    removeChipEl,
    removeChipGas,
    removeChipOtherCharges,
  ]);

  const isLoading =
    getOtherChargesIsLoading ||
    addOtherChargesLoading ||
    editOtherChargesLoading ||
    deleteOtherChargesLoading;

  return (
    <Container maxWidth={'xl'} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mt: 4 }}>
        <BreadCrumbsCustom navigate={navigate} links={links} />
      </Box>
      <Box sx={{ mt: 4 }}>{renderTooltip}</Box>
      <Box sx={{ mt: 2 }}>{renderChips}</Box>
      <Box sx={{ mt: 4 }}>
        {(!otherCharges?.documents && isLoading) ||
        (!otherChargesHist?.products && otherChargesHistIsLoading) ||
        (!otherChargesHistGas?.products && otherChargesHistGasIsLoading) ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img style={{ width: '140px' }} src={Loader} alt="loading..." />
          </Box>
        ) : (
          <CustomTabs
            tabs={tabs}
            newTab={Number(searchParams.get('t') ?? 0)}
            setActive={i => setSearchParams({ ...searchParams, t: i.toString() })}
          />
        )}
      </Box>

      <PopupForm
        title={otherChargesAction === 'add' ? 'Προσθήκη Χρέωσης' : 'Επεξεργασία Χρέωσης'}
        helpText="Διαχείρηση/Προσθήκη νέου είδους Άλλης Χρέωσης."
        leftBtnTitle="Ακύρωση"
        rightBtnTitle={otherChargesAction === 'edit' ? 'Αποθήκευση' : 'Προσθήκη'}
        open={openOtherChargesDialog}
        setOpen={setOpenOthesChargesDialog}
        onClose={handleCloseOtherChargesDialog}
        onClickLeftBtn={handleCloseOtherChargesDialog}
        onClickRightBtn={handleActionOtherCharges}
        rightBtnProps={{
          disabled: !otherChargesData.chargeName || !otherChargesData.energyType,
        }}
        maxWidth="md"
      >
        <OtherChargesDialog
          data={otherChargesData}
          setData={setOtherChargesData}
          action={otherChargesAction}
        />
      </PopupForm>

      <PopupConfirm
        title="Διαγραφή Χρέωσης"
        subTile="Είστε σίγουρος ότι θέλετε να προχωρήσετε στη διαγραφή της συγκεκριμένης Χρέωσης"
        leftBtnTitle="Οχι"
        rightBtnTitle="Ναι"
        open={openDeleteDialogOtherCharges}
        setOpen={setOpenDeleteDialogOtherCharges}
        onClose={() => setOpenDeleteDialogOtherCharges(false)}
        onClickLeftBtn={() => setOpenDeleteDialogOtherCharges(false)}
        onClickRightBtn={deleteOtherCharge}
      />

      <Drawer
        anchor="right"
        open={Boolean(histElId)}
        onClose={() => setHistElId(undefined)}
      >
        <Box
          sx={{
            p: 4,
            width: 800,
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <DrawerElectric
            closeDrawer={() => setHistElId(undefined)}
            data={histElObj}
            active={histElRecord}
          />
        </Box>
      </Drawer>

      <Drawer
        anchor="right"
        open={Boolean(histGasId)}
        onClose={() => setHistGasId(undefined)}
      >
        <Box
          sx={{
            p: 4,
            width: 700,
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <DrawerGas
            closeDrawer={() => setHistGasId(undefined)}
            data={histGasObj}
            active={histGasRecord}
          />
        </Box>
      </Drawer>
    </Container>
  );
};
