import { ApiSchema } from './ApiSchema';

export type StorageFileFields = {
  name: string;
  size: number;
  isPublic: boolean;
  url: string;
  mimeType: string;
  data: string;
  folder?: string;
  container?: string;
};

export type StorageFileProps = {
  name: string;
  mimeType: string;
  data: string;
  folder?: string;
  container?: string;
};

export type StorageFile = ApiSchema<StorageFileFields>;

export const storageFileToFile = (storageFile?: StorageFile): File | undefined => {
  if (!storageFile) return undefined;
  const { name, mimeType, data } = storageFile;
  const blob = new Blob([data], { type: mimeType });
  return new File([blob], name, { type: mimeType });
};
