import { Box, InputAdornment } from '@mui/material';
import { Dropdown, DropdownNew, Input } from '@rae/ui-library';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ProductGasTypeClauses, ProductGasTypes } from './ProductGasTypes';
import {
  disableFields,
  ReviewModeEnum,
  showReviewComments,
  viewOnlyReviewComments,
} from '../../types/ReviewMode';
import { ReviewTool } from '../ProductElectricPage/actions/ReviewTool';
import { TableCompFeesProps } from '@rae/ui-library/dist/cjs/types/src/components/table/TableRowCompFees';
import ChargeScalesMixed from '../ChargeScales/ChargeScalesMixed';

type Obj = {
  data: ProductGasTypes;
  setData: (newState: any) => void;
  addComment: (id: string, comment: string) => void;
  reviewMode: ReviewModeEnum;
  compFees: TableCompFeesProps[];
};

export const ClauseTab = ({ data, setData, reviewMode, addComment, compFees }: Obj) => {
  const [openImplCause, setOpenImplClause] = useState<number>();
  const [openKind, setOpenKind] = useState<number>();
  const [openDuration, setOpenDuration] = useState<number>();

  useEffect(() => {
    const newData = [...(data.clauses ?? [])].map((obj, i) => {
      const newObj = { ...obj };
      if (newObj?.implClause && newObj?.implClause === 'Ναι') {
        if (data.info?.duration) {
          newObj.durationClause =
            data.info?.duration === 'undefined' ? 'undefined' : data.info?.duration;
          if (data.info?.duration === 'undefined') {
            newObj.months = [];
          } else {
            if (
              !newObj.months ||
              newObj.months?.length === 0 ||
              newObj.months?.length !== parseInt(data.info?.duration)
            ) {
              newObj.months = [];
              const newMonths: number[] = Array(parseInt(data.info?.duration))
                .fill(0)
                .map((_, index) => index + 1);
              newMonths.forEach(val =>
                newObj.months?.push({ id: val, value: undefined }),
              );
            }
            newObj.descrClause = undefined;
          }
          return newObj;
        }
      } else {
        newObj.months = [];
        return newObj;
      }
    });
    setData((values: ProductGasTypes) => ({
      ...values,
      clauses: newData,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.info?.duration, data.clauses]);

  const setObjClauses = useCallback(
    (val: ProductGasTypeClauses, index: number) =>
      setData((values: ProductGasTypes) => {
        const newDiscounts = values.clauses ? [...values.clauses] : [];
        newDiscounts[index] = {
          ...newDiscounts[index],
          ...val,
        };
        return {
          ...values,
          clauses: newDiscounts,
        };
      }),
    [setData],
  );

  const handleChangeMonth = useCallback(
    (id: number, value: any, i: number) => {
      const newData = { ...(data.clauses?.[i] ?? {}) };
      if (newData.months) {
        newData.months[id] = {
          id: id + 1,
          value: value !== '' ? parseFloat(value) : undefined,
        };
        setObjClauses(newData, i);
      }
    },
    [data.clauses, setObjClauses],
  );

  // useEffect(() => {
  //   if (data.competitiveFee?.type === CompetitiveFeeIdsElectricEnum.Stable) {
  //     //stable
  //     handleImplClause(["Ναι"]);
  //   } else {
  //     handleImplClause(["Όχι"]);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data.competitiveFee?.type]);

  const renderSections = useMemo(
    () =>
      data?.clauses?.map((item, i) => (
        <Box key={i} sx={[{ display: 'flex', flexDirection: 'column' }]}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row', justifyContent: 'space-between' },
            }}
          >
            <Dropdown
              sx={{ width: { xs: '300px', md: '350px' }, mt: 2 }}
              label="Εφαρμόζεται Ρήτρα;"
              labelBtn="Εφαρμογή"
              choices={['Ναι', 'Όχι']}
              disabled={disableFields(reviewMode)}
              open={openImplCause}
              setOpen={setOpenImplClause}
              selected={item?.implClause ? [item?.implClause] : []}
              setSelected={(type: any) => {
                const newData = { ...item };
                newData.implClause = type[0];
                if (type[0] === 'Όχι') {
                  setObjClauses(newData, i);
                } else {
                  newData.durationClause =
                    data.info?.duration && data.info?.duration === 'undefined'
                      ? 'undefined'
                      : data.info?.duration;
                  newData.kindClause = 'Πρόωρης Αποχώρησης';
                  setObjClauses(newData, i);
                }
              }}
            />
            {item?.implClause && item?.implClause === 'Ναι' ? (
              <>
                <Dropdown
                  sx={{ width: { xs: '300px', md: '350px' }, mt: 2 }}
                  label="Είδος Ρητρας"
                  labelBtn="Εφαρμογή"
                  choices={['Πρόωρης Αποχώρησης']}
                  open={openKind === i}
                  disabled
                  setOpen={open => setOpenKind(open ? i : undefined)}
                  selected={item?.kindClause ? [item?.kindClause] : []}
                  setSelected={(type: any) =>
                    setData((values: ProductGasTypes) => ({
                      ...values,
                      clauses: {
                        ...values.clauses,
                        kindClause: type,
                      },
                    }))
                  }
                />
                <DropdownNew
                  sx={{ width: { xs: '300px', md: '350px' }, mt: 2 }}
                  disabled
                  label="Διάρκεια Συμβολαίου"
                  labelBtn="Εφαρμογή"
                  choices={[
                    {
                      label: '1 μήνας',
                      value: '1',
                    },
                    {
                      label: '3 μήνες',
                      value: '3',
                    },
                    {
                      label: '6 μήνες',
                      value: '6',
                    },
                    {
                      label: '12 μήνες',
                      value: '12',
                    },
                    {
                      label: '18 μήνες',
                      value: '18',
                    },
                    {
                      label: '24 μήνες',
                      value: '24',
                    },
                    {
                      label: 'Αορίστου',
                      value: 'undefined',
                    },
                  ]}
                  selected={item?.durationClause ? [item?.durationClause] : []}
                  setSelected={() => {}}
                  open={openDuration === i}
                  setOpen={open => setOpenDuration(open ? i : undefined)}
                />
              </>
            ) : null}
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={data.reviewComments?.exitClause?.exitClause}
                id="exitClause.exitClause"
                title="Ρήτρα Π. Αποχώρησης"
                addComment={addComment}
              />
            ) : null}
          </Box>
          {item?.durationClause && item?.implClause ? (
            item?.durationClause === 'undefined' && item?.implClause === 'Ναι' ? (
              <Box sx={{ mt: 2 }}>
                <Input
                  sx={{ mt: 4, mr: 3, width: { xs: '100%', md: '80%' } }}
                  multiline
                  minRows={4}
                  disabled={disableFields(reviewMode)}
                  type="default"
                  label="Επεξήγηση Ρήτρας Πρόωρης Αποχώρησης"
                  value={item?.descrClause ? item?.descrClause : ''}
                  onChange={event =>
                    setObjClauses({ ...item, descrClause: event.target.value }, i)
                  }
                />
              </Box>
            ) : null
          ) : null}
          {item?.months && item?.implClause && item?.implClause === 'Ναι' ? (
            <Box
              sx={{
                display: 'flex',
                flexFlow: { xs: 'column', md: 'column wrap' },
                mt: 4,
              }}
            >
              {item?.months.map((obj, ind) => {
                return (
                  <Input
                    key={ind}
                    sx={{ mt: 2, mb: 2 }}
                    disabled={disableFields(reviewMode)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                    type="label"
                    sideLabel={`${ind + 1}ος μήνας  `}
                    placeholder="Εισάγετε ποσό"
                    inputType={'number'}
                    value={obj.value}
                    onChange={event => handleChangeMonth(ind, event.target.value, i)}
                  />
                );
              })}
            </Box>
          ) : null}
        </Box>
      )),
    [
      addComment,
      data?.clauses,
      data.info?.duration,
      data.reviewComments?.exitClause?.exitClause,
      handleChangeMonth,
      openDuration,
      openImplCause,
      openKind,
      reviewMode,
      setData,
      setObjClauses,
    ],
  );

  return (
    <ChargeScalesMixed
      type={data.competitiveFeeType}
      competitiveFees={data.competitiveFee}
      compFees={compFees}
      components={renderSections}
      duration={data.info?.duration}
      disabled
    />
  );
};
