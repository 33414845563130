export enum ReviewStatusEnum {
  Accepted = 'accepted',
  Rejected = 'rejected',
  Pending = 'pending',
  Revision = 'revision',
  Deleted = 'deleted',
}

export type ReviewStatus = `${ReviewStatusEnum}`;

export const reviewStatusLabel = (reviewStatus?: ReviewStatus): string => {
  switch (reviewStatus) {
    case ReviewStatusEnum.Accepted:
      return 'Εγκρίθηκε';
    case ReviewStatusEnum.Rejected:
      return 'Απορρίφθηκε';
    case ReviewStatusEnum.Pending:
      return 'Σε εκκρεμότητα';
    case ReviewStatusEnum.Revision:
      return 'Χρήζει Αναθεώρησης';
    case ReviewStatusEnum.Deleted:
      return 'Διαγράφηκε';
    default:
      return '';
  }
};

export const reviewStatusColor = (reviewStatus?: ReviewStatus): string => {
  switch (reviewStatus) {
    case ReviewStatusEnum.Accepted:
      return '#038F09';
    case ReviewStatusEnum.Rejected:
      return '#D22121';
    case ReviewStatusEnum.Pending:
      return '#87007B';
    case ReviewStatusEnum.Revision:
      return '#1E71CF';
    case ReviewStatusEnum.Deleted:
      return '#9E9E9E';
    default:
      return '';
  }
};
