export enum MeasurementEnum {
  Daily = 'daily',
  DailyNightly = 'daily_nightly',
}

export type Measurement = `${MeasurementEnum}`;

export const measurementLabel = (measurement: Measurement) => {
  switch (measurement) {
    case 'daily':
      return { gr: 'Ημερήσια', en: 'Daily' };
    case 'daily_nightly':
      return { gr: 'Ημερήσια & Νυχτερινή', en: 'Daily & Nightly' };
    default:
      return { gr: '', en: '' };
  }
};

export const measurementGasLabel = (measurement: Measurement) => {
  switch (measurement) {
    case 'daily':
      return { gr: 'Βασική', en: 'Basic' };
    // case 'daily_nightly':
    //   return { gr: 'Ημερήσια & Νυχτερινή', en: 'Daily & Nightly' };
    default:
      return { gr: '', en: '' };
  }
};
