import { Box } from '@mui/material';
import { CustomTabs, DropZone, Input } from '@rae/ui-library';
import { TableDocumentsProps } from '@rae/ui-library/src/components/table/TableRowDocuments';
import React, { useMemo } from 'react';

type Obj = {
  data?: TableDocumentsProps;
  setData: (newState?: any) => void;
  type?: 'add' | 'view' | 'newEntry';
};

export const DocumentDialog = ({ setData, data, type }: Obj) => {
  const GrTab = useMemo(() => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Input
          sx={{ width: { xs: '300px', md: '450px' }, mb: 4, mt: 4 }}
          value={data?.fileName}
          disabled={type === 'view'}
          type="default"
          placeholder="Πρόσθεσε Όνομα"
          label="Όνομα Εγγράφου"
          onChange={e =>
            setData((values: TableDocumentsProps) => ({
              ...values,
              fileName: e.target.value,
            }))
          }
        />
        <Input
          sx={{ width: { xs: '300px', md: '450px' } }}
          disabled={true}
          value={data?.fileGr ? data.fileGr?.name : ''}
          type="upload"
          subText="file must be in .pdf format, up to 2mb"
          label="Όνομα Αρχείου"
        />
        <Box
          sx={{
            mt: 2,
            mb: 4,
            height: { xs: '200px', md: '350px' },
            width: { xs: '200px', md: '350px' },
          }}
        >
          <DropZone
            disable={type === 'view'}
            type="pdf"
            file={data?.fileGr}
            url={data?.fileGrUrl}
            setFile={(file: any) =>
              setData((values: TableDocumentsProps) => ({
                ...values,
                fileGr: file,
              }))
            }
          />
        </Box>
      </Box>
    );
  }, [data, setData, type]);

  const EnTab = useMemo(() => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Input
          sx={{ width: { xs: '300px', md: '450px' }, mb: 4, mt: 4 }}
          value={data?.fileNameEn}
          disabled={type === 'view'}
          type="default"
          placeholder="Add name"
          label="Document Name"
          onChange={e =>
            setData((values: TableDocumentsProps) => ({
              ...values,
              fileNameEn: e.target.value,
            }))
          }
        />
        <Input
          sx={{ width: { xs: '300px', md: '450px' } }}
          disabled={true}
          value={data?.fileEn ? data.fileEn?.name : ''}
          type="upload"
          subText="file must be in .pdf format, up to 2mb"
          label="File Name"
        />
        <Box
          sx={{
            mt: 2,
            mb: 4,
            height: { xs: '200px', md: '350px' },
            width: { xs: '200px', md: '350px' },
          }}
        >
          <DropZone
            disable={type === 'view'}
            type="pdf"
            file={data?.fileEn}
            url={data?.fileGrUrl}
            setFile={(file: any) =>
              setData((values: TableDocumentsProps) => ({
                ...values,
                fileEn: file,
              }))
            }
          />
        </Box>
      </Box>
    );
  }, [data, setData, type]);

  return (
    <CustomTabs
      newTab={type === 'newEntry' ? (data?.fileGr ? 1 : 0) : 0}
      tabs={[
        {
          tabName: 'GR',
          tabContent: GrTab,
          disabled: data?.fileGr && type === 'newEntry' ? true : false,
        },
        {
          tabName: 'EN',
          tabContent: EnTab,
          disabled: data?.fileEn && type === 'newEntry' ? true : false,
        },
      ]}
    />
  );
};
