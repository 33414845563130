import { Box, Typography, useTheme } from '@mui/material';
import { Calculator, Input } from '@rae/ui-library';
import { OptionsProps } from '@rae/ui-library/src/components/Calculator';
import React from 'react';
import { ProductGasTypes } from '../../ProductGasTypes';
import {
  disableFields,
  ReviewModeEnum,
  showReviewComments,
  viewOnlyReviewComments,
} from '../../../../types/ReviewMode';
import { ReviewTool } from '../../../ProductElectricPage/actions/ReviewTool';

type Obj = {
  index?: number;
  obj: ProductGasTypes;
  setObj: (newState: any) => void;
  compFeesOptions: OptionsProps[];
  noClauseData: OptionsProps[][];
  setNoClauseData: (newState: any) => void;
  reviewMode: ReviewModeEnum;
  addComment: (id: string, comment: string) => void;
};

export const FloatingChargeNoClause = ({
  index = 0,
  obj,
  setObj,
  compFeesOptions,
  noClauseData,
  setNoClauseData,
  reviewMode,
  addComment,
}: Obj) => {
  const theme = useTheme();

  return (
    <>
      <Box>
        <Input
          sx={{ mt: 3, width: '300px' }}
          disabled={disableFields(reviewMode)}
          type="default"
          inputType={'number'}
          label="Συντελεστής Α"
          value={obj?.competitiveFee?.[index]?.floatingChargeNoClause?.factorA}
          onChange={e =>
            setObj((values: ProductGasTypes) => {
              const newValues = { ...values };
              if (newValues.competitiveFee?.[index]) {
                newValues.competitiveFee[index] = {
                  ...newValues.competitiveFee[index],
                  floatingChargeNoClause: {
                    ...(newValues.competitiveFee[index]?.floatingChargeNoClause ?? {}),
                    factorA: e.target.value,
                  },
                };
              }
              return newValues;
            })
          }
        />
        {showReviewComments(reviewMode) ? (
          <ReviewTool
            view={viewOnlyReviewComments(reviewMode)}
            msg={obj.reviewComments?.competitiveFees?.factorA}
            id="competitiveFees.factorA"
            title="Συντελεστής Α"
            addComment={addComment}
          />
        ) : null}
      </Box>

      <Box>
        <Input
          sx={{ mt: 3, width: '300px' }}
          disabled={disableFields(reviewMode)}
          type="default"
          inputType={'number'}
          label="Συντελεστής Β"
          value={obj?.competitiveFee?.[index]?.floatingChargeNoClause?.factorB}
          onChange={e =>
            setObj((values: ProductGasTypes) => {
              const newValues = { ...values };
              if (newValues.competitiveFee?.[index]) {
                newValues.competitiveFee[index] = {
                  ...newValues.competitiveFee[index],
                  floatingChargeNoClause: {
                    ...(newValues.competitiveFee[index]?.floatingChargeNoClause ?? {}),
                    factorB: e.target.value,
                  },
                };
              }
              return newValues;
            })
          }
        />
        {showReviewComments(reviewMode) ? (
          <ReviewTool
            view={viewOnlyReviewComments(reviewMode)}
            msg={obj.reviewComments?.competitiveFees?.factorB}
            id="competitiveFees.factorB"
            title="Συντελεστής B"
            addComment={addComment}
          />
        ) : null}
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography color={theme.palette.primary.main}>
          Χρεώσεις Χρήσης Συστήματος Μεταφοράς
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Box>
            <Input
              sx={{
                mt: 4,
                width: '300px',
                mr: { xs: 0, md: 4 },
              }}
              inputType="number"
              color="primary"
              type={'default'}
              label="Βόρεια Χρέωση Ενέργειας (€/kWh)"
              placeholder="πχ 0,12 €/kWh"
              disabled={disableFields(reviewMode)}
              value={
                obj.competitiveFee?.[index]?.floatingChargeNoClause?.energyChargeNorth
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setObj((val: ProductGasTypes) => ({
                  ...val,
                  competitiveFee: {
                    ...val.competitiveFee,
                    [index]: {
                      ...(val.competitiveFee?.[index] ?? {}),
                      floatingChargeNoClause: {
                        ...(val.competitiveFee?.[index]?.floatingChargeNoClause ?? {}),
                        energyChargeNorth: event.target.value,
                      },
                    },
                  },
                }))
              }
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.competitiveFees?.energyChargeNorth}
                id="competitiveFees.energyChargeNorth"
                title="Βόρεια Χρέωση Ενέργειας"
                addComment={addComment}
              />
            ) : null}
          </Box>
          <Box>
            <Input
              sx={{
                mt: 4,
                width: '300px',
                mr: { xs: 0, md: 4 },
              }}
              inputType="number"
              color="primary"
              type={'default'}
              label="Βόρεια Χρέωση Δυναμικότητας (€/kWh)"
              placeholder="πχ 0,12 €/kWh"
              disabled={disableFields(reviewMode)}
              value={
                obj.competitiveFee?.[index]?.floatingChargeNoClause?.dynamicChargeNorth
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setObj((val: ProductGasTypes) => ({
                  ...val,
                  competitiveFee: {
                    ...val.competitiveFee,
                    [index]: {
                      ...(val.competitiveFee?.[index] ?? {}),
                      floatingChargeNoClause: {
                        ...(val.competitiveFee?.[index]?.floatingChargeNoClause ?? {}),
                        dynamicChargeNorth: event.target.value,
                      },
                    },
                  },
                }))
              }
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.competitiveFees?.dynamicChargeNorth}
                id="competitiveFees.dynamicChargeNorth"
                title="Βόρεια Χρέωση Δυναμικότητας"
                addComment={addComment}
              />
            ) : null}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Box>
            <Input
              sx={{
                mt: 4,
                width: '300px',
                mr: { xs: 0, md: 4 },
              }}
              inputType="number"
              color="primary"
              type={'default'}
              label="Νότια Χρέωση Ενέργειας (€/kWh)"
              placeholder="πχ 0,12 €/kWh"
              disabled={disableFields(reviewMode)}
              value={
                obj.competitiveFee?.[index]?.floatingChargeNoClause?.energyChargeSouth
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setObj((val: ProductGasTypes) => ({
                  ...val,
                  competitiveFee: {
                    ...val.competitiveFee,
                    [index]: {
                      ...(val.competitiveFee?.[index] ?? {}),
                      floatingChargeNoClause: {
                        ...(val.competitiveFee?.[index]?.floatingChargeNoClause ?? {}),
                        energyChargeSouth: event.target.value,
                      },
                    },
                  },
                }))
              }
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.competitiveFees?.energyChargeSouth}
                id="competitiveFees.energyChargeSouth"
                title="Νότια Χρέωση Ενέργειας"
                addComment={addComment}
              />
            ) : null}
          </Box>
          <Box>
            <Input
              sx={{
                mt: 4,
                width: '300px',
                mr: { xs: 0, md: 4 },
              }}
              inputType="number"
              color="primary"
              type={'default'}
              label="Νότια Χρέωση Δυναμικότητας (€/kWh)"
              placeholder="πχ 0,12 €/kWh"
              disabled={disableFields(reviewMode)}
              value={
                obj.competitiveFee?.[index]?.floatingChargeNoClause?.dynamicChargeSouth
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setObj((val: ProductGasTypes) => ({
                  ...val,
                  competitiveFee: {
                    ...val.competitiveFee,
                    [index]: {
                      ...(val.competitiveFee?.[index] ?? {}),
                      floatingChargeNoClause: {
                        ...(val.competitiveFee?.[index]?.floatingChargeNoClause ?? {}),
                        dynamicChargeSouth: event.target.value,
                      },
                    },
                  },
                }))
              }
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.competitiveFees?.dynamicChargeSouth}
                id="competitiveFees.dynamicChargeSouth"
                title="Νότια Χρέωση Δυναμικότητας"
                addComment={addComment}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography sx={{ mt: 4 }} variant="body1" color={theme.palette.primary.main}>
          Τιμή Ρήτρας = A x + B
        </Typography>
        {showReviewComments(reviewMode) ? (
          <ReviewTool
            view={viewOnlyReviewComments(reviewMode)}
            msg={obj.reviewComments?.competitiveFees?.factorX}
            id="competitiveFees.factorX"
            title="Τιμή Χρέωσης"
            addComment={addComment}
          />
        ) : null}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            mt: 4,
            width: 600,
            border: '1px solid #012C6D',
            p: 2,
            borderRadius: '16px',
          }}
        >
          <Calculator
            disabled={disableFields(reviewMode)}
            data={noClauseData?.[index]}
            setData={(newData: any) => {
              setNoClauseData((values: any) => {
                const newValues = [...values];
                newValues[index] = newData;
                return newValues;
              });
            }}
            multi={false}
            options={compFeesOptions}
          />
        </Box>
      </Box>
    </>
  );
};
