import api from './api';
import {
  ProductElectric,
  ProductElectricFieldsRequest,
} from '../../types/ProductElectric';
import { Identifier } from '../../types/ApiSchema';

const productElectricApi = api
  .enhanceEndpoints({ addTagTypes: ['ProductElectric'] })
  .injectEndpoints({
    endpoints: builder => ({
      getAcceptedProductsElectric: builder.query<
        { products: ProductElectric[] },
        { team?: string }
      >({
        query: ({ team }) => ({
          url: `raeService/product/accepted`,
          method: 'GET',
          params: { type: 'electric', sort: '-productInfo.availabilityDate', team },
        }),
        providesTags: result =>
          result?.products
            ? [
                ...result.products.map(
                  ({ _id }) => ({ type: 'ProductElectric', _id } as const),
                ),
                { type: 'ProductElectric', id: 'LIST' },
              ]
            : [{ type: 'ProductElectric', id: 'LIST' }],
      }),
      getProductElectric: builder.query<ProductElectric, Identifier>({
        query: ({ _id, ...params }) => ({
          url: `/database/ProductElectric/${_id}`,
          method: 'GET',
          params: {
            populate: [
              'productInfo.image',
              'productInfo.team',
              'productInfo.providerId',
              'productInfo.socialInvoiceDiscounts',
              'competitiveFees.competitiveFees',
              'discounts.discounts',
              'discounts.discounts.discBenefType',
              'benefits.benefits',
              'benefits.benefits.discBenefType',
              'otherCharges.otherCharges',
              'productCompetitiveFees',
              'original',
            ],
            ...params,
          },
        }),
        providesTags: result =>
          result ? [{ type: 'ProductElectric', _id: result._id }] : [],
      }),
      addProductElectric: builder.mutation<
        ProductElectric,
        Partial<ProductElectricFieldsRequest>
      >({
        query: params => ({
          url: `/database/ProductElectric`,
          method: 'POST',
          body: params,
        }),
        invalidatesTags: [{ type: 'ProductElectric', id: 'LIST' }],
      }),
      editProductElectric: builder.mutation<
        ProductElectric,
        Identifier & Partial<ProductElectricFieldsRequest>
      >({
        query: ({ _id, ...params }) => ({
          url: `/database/ProductElectric/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [
          { type: 'ProductElectric', _id },
          { type: 'ProductElectric', id: 'LIST' },
        ],
      }),
      getProductElectricVersions: builder.query<ProductElectric[], Identifier>({
        query: ({ _id }) => ({
          url: `database/function/getProductElectricVersions/${_id}`,
          method: 'GET',
          params: { sort: '-productInfo.availabilityDate' },
        }),
      }),
    }),
  });

export const {
  useAddProductElectricMutation,
  useGetAcceptedProductsElectricQuery,
  useGetProductElectricQuery,
  useEditProductElectricMutation,
  useGetProductElectricVersionsQuery,
} = productElectricApi;

export default productElectricApi;
