import { Box, IconButton, InputAdornment } from '@mui/material';
import { Input } from '@rae/ui-library';
import React, { useState } from 'react';
import { LoginUserProps } from '../../pages/LoginPage';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type SudoDialogProps = {
  user: LoginUserProps;
  setUser: (newState: any) => void;
};

export const SudoDialog = ({ user, setUser }: SudoDialogProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Input
        sx={{ mt: 2, minWidth: '300px' }}
        type="default"
        label="Email"
        placeholder="Εισαγετε Email"
        value={user.email}
        onChange={e =>
          setUser((values: LoginUserProps) => ({
            ...values,
            email: e.target.value,
          }))
        }
      />

      <Input
        sx={{ mt: 2, minWidth: '300px' }}
        type="default"
        label="Κωδικός Πρόσβασης"
        placeholder="Εισαγετε Κωδικό Πρόσβασης"
        value={user.password}
        onChange={e =>
          setUser((values: LoginUserProps) => ({
            ...values,
            password: e.target.value,
          }))
        }
        inputType={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                  event.preventDefault()
                }
              >
                {showPassword ? (
                  <Visibility color="primary" />
                ) : (
                  <VisibilityOff color="primary" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
