import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import {
  BreadCrumbsCustom,
  CustomChip,
  CustomTable,
  PopupConfirm,
  PopupForm,
  Tooltip,
} from '@rae/ui-library';
import { HeadCell, OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import { TableRegulatedChargesProps } from '@rae/ui-library/src/components/table/TableRowRegulatedCharges';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useCallback, useMemo, useState } from 'react';
import { NewEntryTabsEl } from '../components/RegularCharges/NewEntryTabsEl';
import {
  useAddRegulatedChargesElectricMutation,
  useDeleteRegulatedChargesElectricMutation,
  useEditRegulatedChargesElectricMutation,
  useGetRegulatedChargesElectricQuery,
} from '../redux/apis/regulatedChargesElectricApi';
import { pageToSkipLimit } from '../types/ApiPagination';
import { useGetProviderIdsElectricQuery } from '../redux/apis/providerIdApi';
import {
  editRegulatedChargesElectricData,
  addRegulatedChargesElectricData,
  regulatedChargesElectricTableData,
} from '../types/RegulatedChargesElectric';
import { setLog } from '../redux/slices/appSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store';
import Loader from '../assets/loading.gif';
import { useNavigate } from 'react-router-dom';

export const RegularChargesEl = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [order, setOrder] = useState<OrderProps>('asc');
  const [orderBy, setOrderBy] = useState<HeadCell['id']>('updatedAt');
  const [page, setPage] = useState<number>(0);
  const [openDeleteDialog, setopentDeleteDialog] = useState<boolean>(false);
  const [openNewEntryDialog, setOpenNewEntryDialog] = useState<boolean>(false);
  const [view, setView] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [valueTab, setValueTab] = useState<TableRegulatedChargesProps>({});
  const [valueTabInitial, setValueTabInitial] = useState<TableRegulatedChargesProps>({});

  const dispatch = useDispatch<AppDispatch>();

  const [addRegulatedCharge] = useAddRegulatedChargesElectricMutation();
  const [editRegulatedCharge] = useEditRegulatedChargesElectricMutation();
  const [deleteRegulatedCharge] = useDeleteRegulatedChargesElectricMutation();

  const { data: providerIdsElectric } = useGetProviderIdsElectricQuery(
    pageToSkipLimit(page, 5, [`${order === 'asc' ? '-' : ''}${orderBy}`]),
  );

  const { data: regulatedElectricData, isLoading: isLoadingRegulatedElectric } =
    useGetRegulatedChargesElectricQuery({
      ...pageToSkipLimit(page, 5, [`${order === 'asc' ? '-' : ''}${orderBy}`]),
      registeredOnly: selected[0] ? selected[0] === 'Ολοκληρωμένη' : undefined,
    });

  const handleNewEntry = useCallback((obj: TableRegulatedChargesProps) => {
    setView(false);
    setEdit(true);
    setOpenNewEntryDialog(true);
    setValueTab({ ...obj });
    setValueTabInitial({ ...obj });
  }, []);

  const links = [
    { name: 'Διαχείριση', href: '/' },
    { name: 'Ρυθμιζόμενες Χρεώσεις Η.Ε' },
  ];

  const dropDowns = [
    {
      choices: ['Ολοκληρωμένη', 'Νέα καταχώρηση'],
      open: open,
      selected: selected,
      setOpen: setOpen,
      setSelected: setSelected,
      label: 'Κατάσταση',
      multiple: false,
      labelBtn: 'Εφαρμογή',
    },
  ];

  const filters = useMemo(() => selected, [selected]);

  const clearFilters = useMemo(
    () => () => {
      setSelected([]);
    },
    [],
  );

  const removeChip = useMemo(
    () => (chipLabel: string) => {
      setSelected(selected.filter(label => label !== chipLabel));
    },
    [selected],
  );

  const openDelete = useCallback((obj: TableRegulatedChargesProps) => {
    setValueTab(obj);
    setopentDeleteDialog(true);
  }, []);

  const handleDelete = useCallback(async () => {
    setopentDeleteDialog(false);
    if (!valueTab._id || !valueTab?.providerId?._id) return;
    try {
      await deleteRegulatedCharge({ _id: valueTab._id }).unwrap();
      setValueTab({});
    } catch (e) {
      console.log(e);
      dispatch(
        setLog({
          severity: 'error',
          message: `ERROR ${e}`,
        }),
      );
    }
  }, [deleteRegulatedCharge, valueTab, dispatch]);

  const handleSave = useCallback(async () => {
    setOpenNewEntryDialog(false);
    if (!valueTab?.providerId?._id) return;
    try {
      const addFields = addRegulatedChargesElectricData(valueTab);
      await addRegulatedCharge(addFields).unwrap();
      setValueTab({});
    } catch (e) {
      console.log(e);
      dispatch(
        setLog({
          severity: 'error',
          message: `ERROR ${e}`,
        }),
      );
    }
  }, [addRegulatedCharge, valueTab, dispatch]);

  const handleView = useCallback((obj: TableRegulatedChargesProps) => {
    setView(true);
    setEdit(false);
    setOpenNewEntryDialog(true);
    setValueTab({ ...obj });
    setValueTabInitial({ ...obj });
  }, []);

  const handleEdit = useCallback(async () => {
    setOpenNewEntryDialog(false);
    if (!valueTab?._id) return;
    try {
      const editFields = editRegulatedChargesElectricData(valueTab);
      await editRegulatedCharge(editFields).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: `Η χρέωση αποθηκεύτηκε επιτυχώς`,
        }),
      );
      setValueTab({});
    } catch (e) {
      console.log(e);
      dispatch(
        setLog({
          severity: 'error',
          message: `ERROR ${e}`,
        }),
      );
    }
  }, [editRegulatedCharge, valueTab, dispatch]);

  const disableSubmit = useMemo(() => {
    return !(
      valueTab.etmeap !== undefined &&
      valueTab.priceEnergyDistr !== undefined &&
      valueTab.priceEnergyMove !== undefined &&
      valueTab.pricePowerDistr !== undefined &&
      valueTab.pricePowerMove !== undefined &&
      valueTab.tieredBilling !== undefined &&
      edit
    );
  }, [
    valueTab.etmeap,
    valueTab.priceEnergyDistr,
    valueTab.priceEnergyMove,
    valueTab.pricePowerDistr,
    valueTab.pricePowerMove,
    valueTab.tieredBilling,
    edit,
  ]);

  const isLoading = isLoadingRegulatedElectric;

  console.log(valueTab);

  return (
    <Container maxWidth={'xl'} sx={{ p: 2 }}>
      <Box sx={{ mt: 4 }}>
        <BreadCrumbsCustom maxItems={matches ? 3 : 1} navigate={navigate} links={links} />
      </Box>

      {filters.length > 0 ? (
        <Box sx={{ mt: 4 }}>
          <Tooltip
            type="info"
            text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
            btnAction={clearFilters}
            btnLabel="Καθαρισμός Φίλτρων"
            dismiss={true}
            icon={<InfoOutlinedIcon fontSize="small" />}
          />
        </Box>
      ) : null}

      <Box sx={{ mt: 2 }}>
        {filters.map((chiplabel, i) => {
          return (
            <CustomChip
              key={i}
              label={chiplabel}
              onDelete={() => removeChip(chiplabel)}
            />
          );
        })}
      </Box>

      {!regulatedElectricData?.data && isLoading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img style={{ width: '140px' }} src={Loader} alt="loading..." />
        </Box>
      ) : (
        <Box sx={{ mt: 4 }}>
          <CustomTable
            handleNewEntry={(obj: TableRegulatedChargesProps) => handleNewEntry(obj)}
            type="regulated-charges"
            headCells={[
              { disable: false, id: 'measurement', label: 'Μέτρηση' },
              { disable: false, id: 'categorySupply', label: 'Κατηγορία παροχής' },
              { disable: true, id: 'Καταχώρηση', label: 'Καταχώρηση' },
              { disable: true, id: 'updatedAt', label: 'Τροποποίηση' },
              { disable: true, id: 'Ενέργειες', label: 'Ενέργειες' },
            ]}
            handleDelete={(obj: TableRegulatedChargesProps) => openDelete(obj)}
            handleView={(obj: TableRegulatedChargesProps) => handleView(obj)}
            count={providerIdsElectric?.count ?? 0}
            rows={regulatedChargesElectricTableData(regulatedElectricData?.data)}
            title="Ρυθμιζόμενες Χρεώσεις Ηλεκτρικού Ρεύματος"
            dropDowns={dropDowns}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            page={page}
            rowsPerPage={5}
            setPage={setPage}
          />
        </Box>
      )}

      <PopupForm
        title="Νέα Καταχώρηση"
        helpText="Επικαιροποίηση Ρυθμιζόμενων Χρεώσεων/ Προσθήκη νέας ρυθμιζόμενης χρέωσης."
        leftBtnTitle={view ? 'Πίσω στο Πίνακα' : 'Ακύρωση'}
        rightBtnTitle={view ? 'Αποθήκευση' : 'Καταχώρηση'}
        open={openNewEntryDialog}
        setOpen={setOpenNewEntryDialog}
        onClose={() => setOpenNewEntryDialog(false)}
        onClickLeftBtn={() => setOpenNewEntryDialog(false)}
        onClickRightBtn={view ? handleEdit : handleSave}
        rightBtnProps={{ disabled: disableSubmit }}
      >
        <Box sx={{ minHeight: '435px' }}>
          <NewEntryTabsEl
            edit={edit}
            setEdit={setEdit}
            view={view}
            setTabs={setValueTab}
            tabsContent={valueTab}
            handleReset={() => setValueTab(valueTabInitial)}
          />
        </Box>
      </PopupForm>

      <PopupConfirm
        title="Διαγραφή Ρυθμιζόμενης Χρέωσης"
        subTile="Είστε σίγουρος ότι θέλετε να προχωρήσετε στη διαγραφή της συγκεκριμένης  Ρυθμιζόμενης Χρέωσης;"
        leftBtnTitle="Οχι"
        rightBtnTitle="Ναι"
        open={openDeleteDialog}
        setOpen={setopentDeleteDialog}
        onClose={() => setopentDeleteDialog(false)}
        onClickLeftBtn={() => setopentDeleteDialog(false)}
        onClickRightBtn={handleDelete}
      />
    </Container>
  );
};
