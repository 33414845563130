import { createTheme, Theme } from '@mui/material';
import { purple } from '@mui/material/colors';

export const theme: Theme = createTheme({
  typography: {
    button: {
      textTransform: 'capitalize',
    },
  },
  palette: {
    primary: {
      main: '#012C6D',
    },
    secondary: {
      main: '#6E6E6E',
    },
    info: {
      main: '#1E71CF2E',
    },
    warning: {
      main: '#FFC107',
    },
    customPurple: { main: purple[700], contrastText: '#fff' },
    toolTipError: { main: '#FF000038', contrastText: '#fff' },
  },
});
