import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LogsProps } from '../../types/AppProps';

export type AppState = {
  loading: boolean;
  showSnackBar: boolean;
  log?: LogsProps;
};

const initialState: AppState = {
  loading: false,
  showSnackBar: false,
  log: {},
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppLoading: (state, { payload }) => {
      state.loading = payload;
    },
    closeShowSnackBar: state => {
      state.showSnackBar = false;
    },
    setLog: (state, { payload }: PayloadAction<LogsProps>) => {
      state.log = payload;
      state.showSnackBar = true;
    },
  },
});
export const { setAppLoading, closeShowSnackBar, setLog } = appSlice.actions;

export default appSlice;
