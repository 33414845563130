import { Box, Divider, IconButton, Typography, useTheme } from '@mui/material';
import { ButtonRae, CheckBoxes, DropdownNew, PopupForm } from '@rae/ui-library';
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { ProductsComponent } from './ProductsComponent';
import { TableDiscountProps } from '@rae/ui-library/src/components/table/TableRowDiscounts';
import { Choice } from '@rae/ui-library/src/components/DropdownNew';
import { ProductElectric } from '../../types/ProductElectric';
import { ProductGas } from '../../types/ProductGas';
import { DiscBenefTypeProps } from '@rae/ui-library/src/components/table/TableRowDiscBenefType';
import { addBenefitData } from '../../types/Benefit';
import { useAddBenefitMutation } from '../../redux/apis/benefitApi';
import {
  disableFields,
  ReviewModeEnum,
  showReviewComments,
  viewOnlyReviewComments,
} from '../../types/ReviewMode';
import { ReviewTool } from '../ProductElectricPage/actions/ReviewTool';
import { LinkedInvoicesTypes } from './LinkedInvoicesTypes';
import { DiscountBenefitsSection } from '../ProductElectricPage/DiscountBenefitsSection';
import { Team } from '../../types/Team';

type Obj = {
  data: LinkedInvoicesTypes;
  setData: Dispatch<SetStateAction<LinkedInvoicesTypes>>;
  team?: Team;
  productEl: string[];
  productGas: string[];
  benefitsTypes: DiscBenefTypeProps[];
  benefitsData: TableDiscountProps[];
  productsElectric?: ProductElectric[];
  productsGas?: ProductGas[];
  reviewMode: ReviewModeEnum;
  addComment: (id: string, comment: string) => void;
};

export const BenefitsGasTab = ({
  productEl,
  productGas,
  data,
  setData,
  team,
  productsGas,
  productsElectric,
  benefitsData,
  benefitsTypes,
  reviewMode,
  addComment,
}: Obj) => {
  const theme = useTheme();

  const [openKind, setOpenKind] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [activeType, setActiveType] = useState<string>('');
  const [newDisc, setNewDisc] = useState<TableDiscountProps>({
    category: 'benefit',
  });
  const [prevName, setPrevName] = useState<string>();

  const [addBenefitRequest] = useAddBenefitMutation();

  const addBenefitService = useCallback(async () => {
    if (
      !team ||
      !newDisc?.type?._id ||
      !newDisc.name ||
      !newDisc.nameEn ||
      !newDisc.energy ||
      !newDisc.clients
    )
      return;
    const addFields = addBenefitData(newDisc, team);
    try {
      return await addBenefitRequest(addFields).unwrap();
    } catch (error: any) {
      console.log(error);
    }
  }, [addBenefitRequest, newDisc, team]);

  const handleTypeSelect = useCallback(
    (types: string[]) => {
      const currentTypes = Object.keys(data.benefitsGas ? data.benefitsGas : []);

      const added = types.filter(x => !currentTypes.includes(x));
      const removed = currentTypes.filter(x => !types.includes(x));
      const newData = { ...data.benefitsGas };
      removed.forEach(value => delete newData[value]);
      added.forEach(value => (newData[value] = {}));
      setData(values => ({
        ...values,
        benefitsGas: newData,
      }));
    },
    [data, setData],
  );

  const handleInvoiceCheckbox = useCallback(
    (type: string, discount: string[]) => {
      const currentInvoices = Object.keys(data.benefitsGas ? data.benefitsGas[type] : []);
      const added = discount.filter(x => !currentInvoices.includes(x));
      const removed = currentInvoices.filter(x => !discount.includes(x));
      const newData = { ...data.benefitsGas };
      removed.forEach(value => delete newData[type][value]);
      added.forEach(
        value =>
          (newData[type][value] = benefitsData
            ?.filter(item => item && item._id === value && item.type?._id === type)
            .map(item => item)[0] ?? { ...newDisc, status: 0, _id: value }),
      );

      setData(values => ({
        ...values,
        benefitsGas: newData,
      }));
    },
    [benefitsData, data.benefitsGas, newDisc, setData],
  );

  const onFocusName = useCallback((prevName: string) => {
    setPrevName(prevName);
  }, []);

  const onLoseFocusName = useCallback(
    (type: string, availDisc: string, name: string) => {
      const newData = { ...data.benefitsGas };
      const currentInvoices = Object.keys(data.benefitsGas ? data.benefitsGas[type] : []);
      let discName = newData[type][availDisc].name;
      currentInvoices.push(discName ? discName : '');
      const filteredArray = currentInvoices.filter(obj => obj !== prevName);
      handleInvoiceCheckbox(type, filteredArray);
    },
    [data, handleInvoiceCheckbox, prevName],
  );

  const editNewData = useCallback(
    (type: string, availDisc: string, name: string, value: any) => {
      switch (name) {
        case 'type':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'gas_comb',
            type: value,
          }));
          break;
        case 'name':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'gas_comb',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            name: value,
          }));
          break;
        case 'nameEn':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'gas_comb',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            nameEn: value,
          }));
          break;
        case 'link':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'gas_comb',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            link: value,
          }));
          break;
        case 'linkEn':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'gas_comb',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            linkEn: value,
          }));
          break;
        case 'descr':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'gas_comb',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            descr: value,
          }));
          break;
        case 'descrEn':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'gas_comb',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            descrEn: value,
          }));
          break;
        case 'reset':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'gas_comb',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            chargeRows: value,
            tieredBilling: undefined,
          }));
          break;
        case 'clients':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'gas_comb',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            clients: value,
          }));
          break;
        case 'tieredBilling':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'gas_comb',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            tieredBilling: value,
            chargeRows: [{ amount: '0', from: '', to: '' }],
          }));
          break;
        case 'chargeRows':
          setNewDisc(newDisc => ({
            ...newDisc,
            energy: 'gas_comb',
            ...(newDisc.type
              ? {
                  type: {
                    _id: type,
                    ...newDisc.type,
                  },
                }
              : {}),
            chargeRows: value,
          }));
          break;
        default:
          break;
      }
    },
    [],
  );

  const addDiscount = useCallback(async () => {
    const benef = await addBenefitService();
    if (!benef) return;
    const currentInvoices = Object.keys(
      data.benefitsGas ? data.benefitsGas[newDisc.type?._id ? newDisc.type._id : ''] : [],
    );
    currentInvoices.push(benef._id ? benef._id : '');
    handleInvoiceCheckbox(newDisc.type?._id ? newDisc.type._id : '', currentInvoices);

    setOpenDialog(false);
    setNewDisc({ category: 'benefit' });
  }, [addBenefitService, data.benefitsGas, handleInvoiceCheckbox, newDisc]);

  const cancelAddDiscount = useCallback(() => {
    setOpenDialog(false);
    setNewDisc({ category: 'benefit' });
  }, []);

  const handleOpenDialog = useCallback((type: DiscBenefTypeProps) => {
    setNewDisc((values: any) => ({
      ...values,
      type: type,
    }));
    setOpenDialog(true);
    setActiveType(type?._id ? type._id : '');
  }, []);

  const disableAddBenefObj = useMemo(() => {
    return (
      !newDisc?.type?._id ||
      !newDisc.name ||
      !newDisc.nameEn ||
      !newDisc.energy ||
      !newDisc.clients ||
      !newDisc.descr ||
      !newDisc.descrEn
    );
  }, [newDisc]);

  const removeDisc = useCallback(
    (type: string, availDisc: string) => {
      const newData = { ...data.benefitsGas };
      delete newData[type][availDisc];
      setData(values => ({
        ...values,
        benefitsGas: newData,
      }));
    },
    [data, setData],
  );

  const typesOptions = useMemo((): Choice[] => {
    return (
      benefitsTypes?.map(type => ({
        value: type._id ? type._id : '',
        label: type.typeName ? type.typeName : '',
      })) ?? []
    );
  }, [benefitsTypes]);

  return (
    <>
      <ProductsComponent
        productEl={productEl}
        productGas={productGas}
        productsGas={productsGas}
        productsElectric={productsElectric}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: { xs: '0rem', md: '5rem' },
        }}
      >
        <Box>
          <DropdownNew
            disabled={disableFields(reviewMode)}
            sx={{ width: { xs: '300px', md: '470px' }, mt: 2 }}
            label="Επιλέξτε ένα ή περισσοτερα είδη Πρόσθετης Παροχής"
            multiple
            labelBtn="Εφαρμογή"
            choices={typesOptions}
            open={openKind}
            setOpen={setOpenKind}
            selected={Object.keys(data.benefitsGas ? data.benefitsGas : [])}
            setSelected={handleTypeSelect}
          />
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={data.reviewComments?.benefitsGas?.benefits}
              id="benefitsGas.benefits"
              title="Προσθετές Παροχές Φ.Α."
              addComment={addComment}
            />
          ) : null}
        </Box>
        {Object.entries(data.benefitsGas ? data.benefitsGas : []).map(
          ([type, availDiscounts]) => (
            <Box sx={{ mt: 2 }} key={type}>
              <Typography
                sx={{ minWidth: '200px' }}
                fontSize={'14px'}
                fontWeight={600}
                color={theme.palette.primary.main}
              >
                {`Διαθέσιμες Πρόσθετες Παροχές ${
                  benefitsTypes?.find(disc => disc._id === type)?.typeName
                }`}
                <Divider
                  sx={{
                    mt: 1,
                    width: '10%',
                    borderBottomWidth: 4,
                    borderColor: theme.palette.primary.main,
                  }}
                  textAlign="left"
                />
              </Typography>
              <Box sx={{ mt: 2, display: 'flex' }}>
                {benefitsData?.find(
                  benef => benef.type?._id === type && benef.energy === 'gas_comb',
                ) ? (
                  <CheckBoxes
                    sx={{ mt: 1 }}
                    checked={Object.keys(availDiscounts)}
                    setChecked={disc => handleInvoiceCheckbox(type, disc)}
                    disabled={benefitsData?.map(item => item._id ?? '') ?? []}
                    checkboxes={
                      benefitsData
                        ?.filter(
                          item =>
                            item && item.energy === 'gas_comb' && item.type?._id === type,
                        )
                        .map(item => ({
                          label: item.name ? item.name : '',
                          value: item._id ? item._id : '',
                        })) ?? []
                    }
                  />
                ) : null}
                <IconButton
                  disabled={disableFields(reviewMode)}
                  onClick={() =>
                    handleOpenDialog(
                      benefitsTypes.find(item => item?._id === type) || {
                        category: 'benefit',
                        typeName: '',
                      },
                    )
                  }
                  aria-label="add"
                  sx={{
                    '&:hover': { backgroundColor: 'transparent' },
                    '&:active': { backgroundColor: 'transparent' },
                    textAlign: 'center',
                  }}
                >
                  <AddCircleOutlineOutlinedIcon sx={{ fontSize: 40 }} color="primary" />
                  <Typography>
                    Προσθήκη νέας Πρόσθετης Παροχής{' '}
                    {benefitsTypes?.find(disc => disc._id === type)?.typeName}
                  </Typography>
                </IconButton>
              </Box>
              {Object.entries(availDiscounts).map(([availDisc, discountData], i) => {
                return (
                  <Box sx={{ mt: 2 }} key={availDisc}>
                    <Divider sx={{ mb: 2 }} />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="body2">
                        {benefitsData?.find(disc => disc._id === availDisc)?.name}
                      </Typography>
                      <ButtonRae
                        disabled={disableFields(reviewMode)}
                        size="small"
                        sx={{ color: '#87007B' }}
                        onClick={() => removeDisc(type, availDisc)}
                      >
                        Αφαίρεση Πρόσθετης Παροχής
                      </ButtonRae>
                    </Box>
                    <DiscountBenefitsSection
                      disable={disableFields(reviewMode)}
                      obj={discountData}
                      typeDisc={'edit'}
                      type={type}
                      discName={availDisc}
                      section="benefit"
                      onFocusName={onFocusName}
                      onLoseFocusName={onLoseFocusName}
                      key={i}
                    />
                  </Box>
                );
              })}
              {activeType === type ? (
                <PopupForm
                  InfoTootipProps={undefined}
                  title={'Προσθήκη Πρόσθετης Παροχής'}
                  helpText="Insert helping text"
                  leftBtnTitle="Ακύρωση"
                  rightBtnTitle={'Προσθήκη'}
                  open={openDialog}
                  setOpen={() => setOpenDialog(true)}
                  onClose={cancelAddDiscount}
                  onClickLeftBtn={cancelAddDiscount}
                  onClickRightBtn={addDiscount}
                  rightBtnProps={{ disabled: disableAddBenefObj }}
                >
                  <DiscountBenefitsSection
                    disable={disableFields(reviewMode)}
                    obj={newDisc}
                    setObj={editNewData}
                    typeDisc={'new'}
                    type={type}
                    discName={''}
                    onFocusName={onFocusName}
                    onLoseFocusName={onLoseFocusName}
                    section="benefit"
                  />
                </PopupForm>
              ) : null}
            </Box>
          ),
        )}
      </Box>
    </>
  );
};
