import {
  RegisterErrorMsg,
  ShowRegisterErrorProps,
} from '../components/RegisterPage/RegisterCard';
import { RegisterobjProps } from '../pages/RegisterPage';

type LoginPageValidationsProps = {
  registerUser: RegisterobjProps;
  setShowError: (newState: any) => void;
  setErrorMsg: (newState: any) => void;
};

export const registerPageValidations = ({
  registerUser,
  setErrorMsg,
  setShowError,
}: LoginPageValidationsProps) => {
  const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  let errorFlag = false;

  setShowError({});
  setErrorMsg({});

  if (!registerUser.password) {
    setShowError((prev: ShowRegisterErrorProps) => ({
      ...prev,
      password: true,
    }));
    setErrorMsg((prev: RegisterErrorMsg) => ({
      ...prev,
      password: 'Password must be filled',
    }));
    errorFlag = true;
  }
  if (!registerUser.confirm_pass) {
    setShowError((prev: ShowRegisterErrorProps) => ({
      ...prev,
      password: true,
      confirm_pass: true,
    }));
    setErrorMsg((prev: RegisterErrorMsg) => ({
      ...prev,
      confirm_pass: 'Confirmation password must be filled',
    }));
    errorFlag = true;
  }
  if (registerUser.confirm_pass !== registerUser.password) {
    setShowError((prev: ShowRegisterErrorProps) => ({
      ...prev,
      password: true,
      confirm_pass: true,
    }));
    setErrorMsg((prev: RegisterErrorMsg) => ({
      ...prev,
      confirm_pass: 'Password does not match',
    }));
    errorFlag = true;
  }
  if (!registerUser.email) {
    setShowError((prev: ShowRegisterErrorProps) => ({
      ...prev,
      email: true,
    }));
    setErrorMsg((prev: RegisterErrorMsg) => ({
      ...prev,
      email: 'Email must be filled',
    }));
    errorFlag = true;
  } else {
    if (!expression.test(registerUser.email)) {
      setShowError((prev: ShowRegisterErrorProps) => ({
        ...prev,
        email: true,
      }));
      setErrorMsg((prev: RegisterErrorMsg) => ({
        ...prev,
        email: 'Not valid Email',
      }));
      errorFlag = true;
    }
  }
  return errorFlag;
};
