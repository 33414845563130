import { Box } from '@mui/material';
import { CustomTabs, DropdownNew, Input } from '@rae/ui-library';
import { TableDiscountProps } from '@rae/ui-library/src/components/table/TableRowDiscounts';
import React, { useCallback, useMemo, useState } from 'react';
import { DiscountBenefitsSectionInner } from './DiscountBenefitsSectionInner';

type ObjProps = {
  obj?: TableDiscountProps;
  setObj?: (type: string, availDisc: string, name: string, value: any) => void;
  typeDisc: 'new' | 'edit' | 'viewLinkInv';
  type: string;
  discName: string;
  section: 'disc' | 'benefit';
  onLoseFocusName: (type: string, availDisc: string, name: string) => void;
  onFocusName: (prevName: string) => void;
  disable?: boolean;
};

export const DiscountBenefitsSection = ({
  obj,
  setObj,
  typeDisc,
  discName,
  type,
  section,
  onLoseFocusName,
  onFocusName,
  disable,
}: ObjProps) => {
  const [openKindDisc, setOpenKindDisc] = useState<boolean>(false);

  const handleReset = useCallback(() => {
    setObj?.(type, discName, 'reset', [{ to: undefined, from: undefined, amount: 0 }]);
  }, [discName, setObj, type]);

  const infoTabGr = useMemo(() => {
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            mt: 3,
          }}
        >
          <DropdownNew
            sx={{ width: '300px', mt: 2 }}
            label={section === 'disc' ? 'Είδος Έκπτωσης' : 'Είδος Πρόσθετης Παροχής'}
            multiline={false}
            labelBtn="Εφαρμογή"
            choices={[
              {
                label: obj?.type?.typeName ? obj?.type?.typeName : '',
                value: obj?.type?._id ? obj?.type?._id : '',
              },
            ]}
            open={openKindDisc}
            setOpen={setOpenKindDisc}
            selected={obj?.type?._id ? [obj?.type?._id] : []}
            setSelected={(type: any) => setObj?.(type, discName, 'type', type)}
            disabled={true}
          />

          <Input
            sx={{ mt: 2, width: '300px' }}
            type="default"
            label={section === 'disc' ? 'Όνομα Έκπτωσης' : 'Όνομα Πρόσθετης Παροχής'}
            placeholder="Εισάγετε όνομα"
            value={obj?.name}
            onChange={e => setObj?.(type, discName, 'name', e.target.value)}
            onFocus={() => onFocusName(obj?.type?._id ? obj?.type?._id : '')}
            onBlur={
              typeDisc === 'new'
                ? () => {}
                : e =>
                    onLoseFocusName(type, discName, obj?.type?._id ? obj?.type?._id : '')
            }
            disabled={obj?.status === 0 || typeDisc === 'edit' || disable}
          />
          <Input
            sx={{ mt: 2, width: '300px' }}
            type="default"
            label={section === 'disc' ? 'Link Έκπτωσης' : 'Link Πρόσθετης Παροχής'}
            placeholder="Eισάγετε link"
            value={obj?.link}
            onChange={e => setObj?.(type, discName, 'link', e.target.value)}
            disabled={obj?.status === 0 || disable}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            mt: 3,
          }}
        >
          <Input
            sx={{ mt: 2, width: '100%' }}
            multiline
            minRows={4}
            type="default"
            label={
              section === 'disc' ? 'Επεξήγηση έκπτωσης' : 'Επεξήγηση Πρόσθετης Παροχής'
            }
            placeholder="Εισάγετε επεξήγηση"
            value={obj?.descr}
            onChange={e => setObj?.(type, discName, 'descr', e.target.value)}
            disabled={obj?.status === 0 || disable}
          />
        </Box>
      </Box>
    );
  }, [
    disable,
    discName,
    obj?.descr,
    obj?.link,
    obj?.name,
    obj?.status,
    obj?.type?._id,
    obj?.type?.typeName,
    onFocusName,
    onLoseFocusName,
    openKindDisc,
    section,
    setObj,
    type,
    typeDisc,
  ]);

  const infoTabEn = useMemo(() => {
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            mt: 3,
          }}
        >
          <DropdownNew
            sx={{ width: '300px', mt: 2 }}
            label={section === 'disc' ? 'Discount Type' : 'Additional Offer Type'}
            multiline={false}
            labelBtn="Εφαρμογή"
            choices={[
              {
                label: obj?.type?.typeNameEn ? obj?.type?.typeNameEn : '',
                value: obj?.type?._id ? obj?.type?._id : '',
              },
            ]}
            open={openKindDisc}
            setOpen={setOpenKindDisc}
            selected={obj?.type?._id ? [obj?.type?._id] : []}
            setSelected={(type: any) => setObj?.(type, discName, 'type', type)}
            disabled={true}
          />
          <Input
            sx={{ mt: 2, width: '300px' }}
            type="default"
            label={section === 'disc' ? 'Discount Name' : 'Additional Offer Name'}
            placeholder="Enter name"
            value={obj?.nameEn}
            onChange={e => setObj?.(type, discName, 'nameEn', e.target.value)}
            onFocus={() => onFocusName(obj?.nameEn ? obj?.nameEn : '')}
            onBlur={
              typeDisc === 'new'
                ? () => {}
                : e => onLoseFocusName(type, discName, obj?.nameEn ? obj?.nameEn : '')
            }
            disabled={obj?.status === 0 || disable}
          />
          <Input
            sx={{ mt: 2, width: '300px' }}
            type="default"
            label={section === 'disc' ? 'Discount Link' : 'Additional Offer Link'}
            placeholder="Enter Link"
            value={obj?.linkEn}
            onChange={e => setObj?.(type, discName, 'linkEn', e.target.value)}
            disabled={obj?.status === 0 || disable}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            mt: 3,
          }}
        >
          <Input
            sx={{ mt: 2, width: '100%' }}
            multiline
            minRows={4}
            type="default"
            label={
              section === 'disc' ? 'Discount Explanation' : 'Additional Offer Explanation'
            }
            placeholder="Enter an explanation"
            value={obj?.descrEn}
            onChange={e => setObj?.(type, discName, 'descrEn', e.target.value)}
            disabled={obj?.status === 0 || disable}
          />
        </Box>
      </Box>
    );
  }, [
    disable,
    discName,
    obj?.descrEn,
    obj?.linkEn,
    obj?.nameEn,
    obj?.status,
    obj?.type?._id,
    obj?.type?.typeNameEn,
    onFocusName,
    onLoseFocusName,
    openKindDisc,
    section,
    setObj,
    type,
    typeDisc,
  ]);

  const infoTab = useMemo(() => {
    const tabsInner = [
      { tabName: 'GR', tabContent: infoTabGr },
      { tabName: 'EN', tabContent: infoTabEn },
    ];
    return (
      <Box sx={{ width: '100%' }}>
        <CustomTabs tabs={tabsInner} newTab={0} />
      </Box>
    );
  }, [infoTabEn, infoTabGr]);

  const DiscTab = useMemo(() => {
    return (
      <DiscountBenefitsSectionInner
        data={obj}
        handleReset={handleReset}
        setData={setObj}
        type={section === 'disc' ? 'discount' : 'benefit'}
        discName={discName}
        objType={type}
        disable={disable}
      />
    );
  }, [disable, discName, handleReset, obj, section, setObj, type]);

  const tabs = useMemo(() => {
    return [
      {
        tabName: 'Πληροφορίες',
        tabContent: infoTab,
        disabled: typeDisc === 'viewLinkInv',
      },
      {
        tabName: section === 'disc' ? 'Έκπτωση' : 'Πρόσθετη Παροχή',
        tabContent: DiscTab,
        disabled: typeDisc === 'viewLinkInv',
      },
    ];
  }, [DiscTab, infoTab, section, typeDisc]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <CustomTabs tabs={tabs} newTab={typeDisc === 'viewLinkInv' ? 2 : 0} />
      </Box>
    </Box>
  );
};
