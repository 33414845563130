import { Box, Typography, useTheme } from '@mui/material';
import { Calculator, Input, RadioButtonNew } from '@rae/ui-library';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ProductElectricTypes } from '../ProductElectricTypes';
import { OptionsProps } from '@rae/ui-library/src/components/Calculator';
import { ReviewTool } from '../actions/ReviewTool';
import {
  disableFields,
  ReviewModeEnum,
  showReviewComments,
  viewOnlyReviewComments,
} from '../../../types/ReviewMode';
import { TieredBilling } from '../../../types/TieredBilling';
import ChargeScales from '../../ChargeScales/ChargeScales';

type Obj = {
  index?: number;
  obj: ProductElectricTypes;
  setObj: (newState: any) => void;
  count?: string[];
  compFeesOptions: OptionsProps[];
  limitData: OptionsProps[][];
  setLimitData: (newState: any) => void;
  reviewMode: ReviewModeEnum;
  addComment: (id: string, comment: string) => void;
};

export const FloatingChargeLimit = ({
  index = 0,
  obj,
  setObj,
  count,
  compFeesOptions,
  limitData,
  setLimitData,
  addComment,
  reviewMode,
}: Obj) => {
  const theme = useTheme();
  const [calcError, setCalcError] = useState<boolean>(false);

  let initialStateDay = useRef(
    structuredClone(obj?.competitiveFee?.[index]?.floatingChargeWithLimit?.day),
  );
  let initialStateNight = useRef(
    structuredClone(obj?.competitiveFee?.[index]?.floatingChargeWithLimit?.night),
  );

  useEffect(() => {
    let math: string[] = limitData?.[index].length > 0 ? ['('] : [];
    const operators = ['+', '-', '*', '/'];
    // eslint-disable-next-line array-callback-return
    limitData?.[index]?.map(item => {
      if (item.value === 0) {
        math.push('0');
      } else {
        math.push(item.value ? item.value.toString() : '');
      }
    });

    if (
      limitData?.[index].length > 1 &&
      limitData?.[index][limitData.length - 1].type === 'text' &&
      limitData?.[index][limitData.length - 2].type === 'text'
    ) {
      setCalcError(true);
    } else if (
      limitData?.[index].length > 1 &&
      operators
        .map(i => limitData?.[index][limitData.length - 1].value?.toString() === i)
        .includes(true) &&
      operators
        .map(i => limitData?.[index][limitData.length - 2].value?.toString() === i)
        .includes(true)
    ) {
      setCalcError(true);
    } else {
      setCalcError(false);
    }
    setObj((values: ProductElectricTypes) => {
      const newValues = { ...values };
      if (newValues.competitiveFee?.[index]) {
        newValues.competitiveFee[index] = {
          ...newValues.competitiveFee[index],
          floatingChargeWithLimit: {
            ...(newValues.competitiveFee[index]?.floatingChargeWithLimit ?? {}),
            clausePrice: `${
              obj?.competitiveFee?.[index]?.floatingChargeWithLimit?.factorA
            }*${math.join('')}+${
              obj?.competitiveFee?.[index]?.floatingChargeWithLimit?.factorB
            }`,
          },
        };
      }
      return newValues;
    });
  }, [limitData, obj?.competitiveFee, setObj, index]);

  const handleReset = useCallback(
    (reset: 'day' | 'night') => {
      switch (count ? count[0] : '') {
        case 'Ημερήσια':
          setObj((values: ProductElectricTypes) => {
            const newValues = { ...values };
            if (newValues.competitiveFee?.[index]) {
              newValues.competitiveFee[index] = {
                ...newValues.competitiveFee[index],
                floatingChargeWithLimit: {
                  ...(newValues.competitiveFee[index]?.floatingChargeWithLimit ?? {}),
                  day: initialStateDay.current,
                },
              };
            }
            return newValues;
          });
          break;
        case 'Ημερήσια & Νυχτερινή':
          switch (reset) {
            case 'day':
              setObj((values: ProductElectricTypes) => {
                const newValues = { ...values };
                if (newValues.competitiveFee?.[index]) {
                  newValues.competitiveFee[index] = {
                    ...newValues.competitiveFee[index],
                    floatingChargeWithLimit: {
                      ...(newValues.competitiveFee[index]?.floatingChargeWithLimit ?? {}),
                      day: initialStateDay.current,
                    },
                  };
                }
                return newValues;
              });
              break;
            case 'night':
              setObj((values: ProductElectricTypes) => {
                const newValues = { ...values };
                if (newValues.competitiveFee?.[index]) {
                  newValues.competitiveFee[index] = {
                    ...newValues.competitiveFee[index],
                    floatingChargeWithLimit: {
                      ...(newValues.competitiveFee[index]?.floatingChargeWithLimit ?? {}),
                      night: initialStateNight.current,
                    },
                  };
                }
                return newValues;
              });
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    },
    [count, setObj, index],
  );

  const section = useMemo(() => {
    switch (count ? count[0] : '') {
      case 'Ημερήσια':
        return (
          <>
            <Box>
              <Typography variant="h6" color={theme.palette.primary.main} sx={{ mb: 2 }}>
                Ημέρας
              </Typography>
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.competitiveFees?.day?.[index]}
                  id={`competitiveFees.day.${index}`}
                  title="Ημέρας"
                  addComment={addComment}
                />
              ) : null}
            </Box>
            <ChargeScales
              disabled={disableFields(reviewMode)}
              chargeRows={
                obj.competitiveFee?.[index]?.floatingChargeWithLimit?.day?.chargeRows
              }
              setChargeRows={value =>
                setObj((obj: ProductElectricTypes) => {
                  const newValues = { ...obj };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      floatingChargeWithLimit: {
                        ...(newValues.competitiveFee[index]?.floatingChargeWithLimit ??
                          {}),
                        day: {
                          ...newValues.competitiveFee[index]?.floatingChargeWithLimit
                            ?.day,
                          chargeRows: value,
                        },
                      },
                    };
                  }
                  return newValues;
                })
              }
              tieredBilling={
                obj.competitiveFee?.[index]?.floatingChargeWithLimit?.day
                  ?.tieredBilling as TieredBilling
              }
              setTieredBilling={value =>
                setObj((obj: ProductElectricTypes) => {
                  const newValues = { ...obj };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      floatingChargeWithLimit: {
                        ...(newValues.competitiveFee[index]?.floatingChargeWithLimit ??
                          {}),
                        day: {
                          ...newValues.competitiveFee[index]?.floatingChargeWithLimit
                            ?.day,
                          tieredBilling: value,
                          chargeRows: [{ amount: '0', from: '', to: '' }],
                        },
                      },
                    };
                  }
                  return newValues;
                })
              }
              onReset={() => handleReset('day')}
              amountLabel={'Σταθερή χρέωση προμήθειας'}
            />
          </>
        );
      case 'Ημερήσια & Νυχτερινή':
        return (
          <>
            <Box>
              <Typography variant="h6" color={theme.palette.primary.main} sx={{ mb: 2 }}>
                Ημέρας
              </Typography>
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.competitiveFees?.day?.[index]}
                  id={`competitiveFees.day.${index}`}
                  title="Ημέρας"
                  addComment={addComment}
                />
              ) : null}
            </Box>
            <ChargeScales
              disabled={disableFields(reviewMode)}
              chargeRows={
                obj.competitiveFee?.[index]?.floatingChargeWithLimit?.day?.chargeRows
              }
              setChargeRows={value =>
                setObj((obj: ProductElectricTypes) => {
                  const newValues = { ...obj };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      floatingChargeWithLimit: {
                        ...(newValues.competitiveFee[index]?.floatingChargeWithLimit ??
                          {}),
                        day: {
                          ...newValues.competitiveFee[index]?.floatingChargeWithLimit
                            ?.day,
                          chargeRows: value,
                        },
                      },
                    };
                  }
                  return newValues;
                })
              }
              tieredBilling={
                obj.competitiveFee?.[index]?.floatingChargeWithLimit?.day
                  ?.tieredBilling as TieredBilling
              }
              setTieredBilling={value =>
                setObj((obj: ProductElectricTypes) => {
                  const newValues = { ...obj };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      floatingChargeWithLimit: {
                        ...(newValues.competitiveFee[index]?.floatingChargeWithLimit ??
                          {}),
                        day: {
                          ...newValues.competitiveFee[index]?.floatingChargeWithLimit
                            ?.day,
                          tieredBilling: value,
                          chargeRows: [{ amount: '0', from: '', to: '' }],
                        },
                      },
                    };
                  }
                  return newValues;
                })
              }
              onReset={() => handleReset('day')}
              amountLabel={'Σταθερή χρέωση προμήθειας'}
            />
            <Box>
              <Typography
                variant="h6"
                color={theme.palette.primary.main}
                sx={{ mb: 2, mt: 8 }}
              >
                Νύχτας
              </Typography>
              {showReviewComments(reviewMode) ? (
                <ReviewTool
                  view={viewOnlyReviewComments(reviewMode)}
                  msg={obj.reviewComments?.competitiveFees?.night?.[index]}
                  id={`competitiveFees.night.${index}`}
                  title="Νύχτας"
                  addComment={addComment}
                />
              ) : null}
            </Box>
            <ChargeScales
              disabled={disableFields(reviewMode)}
              chargeRows={
                obj.competitiveFee?.[index]?.floatingChargeWithLimit?.night?.chargeRows
              }
              setChargeRows={value =>
                setObj((obj: ProductElectricTypes) => {
                  const newValues = { ...obj };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      floatingChargeWithLimit: {
                        ...(newValues.competitiveFee[index]?.floatingChargeWithLimit ??
                          {}),
                        night: {
                          ...newValues.competitiveFee[index]?.floatingChargeWithLimit
                            ?.night,
                          chargeRows: value,
                        },
                      },
                    };
                  }
                  return newValues;
                })
              }
              tieredBilling={
                obj.competitiveFee?.[index]?.floatingChargeWithLimit?.night
                  ?.tieredBilling as TieredBilling
              }
              setTieredBilling={value =>
                setObj((obj: ProductElectricTypes) => {
                  const newValues = { ...obj };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      floatingChargeWithLimit: {
                        ...(newValues.competitiveFee[index]?.floatingChargeWithLimit ??
                          {}),
                        night: {
                          ...newValues.competitiveFee[index]?.floatingChargeWithLimit
                            ?.night,
                          tieredBilling: value,
                          chargeRows: [{ amount: '0', from: '', to: '' }],
                        },
                      },
                    };
                  }
                  return newValues;
                })
              }
              onReset={() => handleReset('night')}
              amountLabel={'Σταθερή χρέωση προμήθειας'}
            />
          </>
        );
      default:
        break;
    }
  }, [addComment, count, handleReset, obj, reviewMode, setObj, theme, index]);

  return (
    <Box>
      {section}
      <Box sx={{ mt: 8 }}>
        <Typography variant="h6" color={theme.palette.primary.main}>
          Μεθολογία Ρήτρας
        </Typography>
        <Box>
          <RadioButtonNew
            sx={{ mt: 2 }}
            disableRadio={disableFields(reviewMode)}
            radio={[
              { label: 'Εναλλακτική Μεθοδολογία', value: 'alternative' },
              { label: 'Μεθοδολογία 409', value: '409' },
            ]}
            value={
              obj?.competitiveFee?.[index]?.floatingChargeWithLimit?.clauseMethodology
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setObj((values: ProductElectricTypes) => {
                const newValues = { ...values };
                if (newValues.competitiveFee?.[index]) {
                  newValues.competitiveFee[index] = {
                    ...newValues.competitiveFee[index],
                    floatingChargeWithLimit: {
                      ...(newValues.competitiveFee[index]?.floatingChargeWithLimit ?? {}),
                      clauseMethodology: event.target.value,
                    },
                  };
                }
                return newValues;
              })
            }
          />
          {showReviewComments(reviewMode) ? (
            <ReviewTool
              view={viewOnlyReviewComments(reviewMode)}
              msg={obj.reviewComments?.competitiveFees?.clauseMethodology?.[index]}
              id={`competitiveFees.clauseMethodology.${index}`}
              title="Μεθολογία Ρήτρας"
              addComment={addComment}
            />
          ) : null}
        </Box>

        <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column' }}>
          <Box>
            <Input
              sx={{ width: { xs: '300px', md: '350px' } }}
              disabled={disableFields(reviewMode)}
              value={obj?.competitiveFee?.[index]?.floatingChargeWithLimit?.factorA}
              type="default"
              label="Συντελεστής Α"
              inputType={'number'}
              placeholder="πχ. 2€"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setObj((values: ProductElectricTypes) => {
                  const newValues = { ...values };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      floatingChargeWithLimit: {
                        ...(newValues.competitiveFee[index]?.floatingChargeWithLimit ??
                          {}),
                        factorA: event.target.value,
                      },
                    };
                  }
                  return newValues;
                })
              }
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.competitiveFees?.factorA?.[index]}
                id={`competitiveFees.factorA.${index}`}
                title="Συντελεστής Α"
                addComment={addComment}
              />
            ) : null}
          </Box>

          <Box>
            <Input
              sx={{ mt: 4, width: { xs: '300px', md: '350px' } }}
              disabled={disableFields(reviewMode)}
              value={obj?.competitiveFee?.[index]?.floatingChargeWithLimit?.factorB}
              type="default"
              label="Συντελεστής B"
              inputType={'number'}
              placeholder="πχ. 0,12 €/kWh"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setObj((values: ProductElectricTypes) => {
                  const newValues = { ...values };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      floatingChargeWithLimit: {
                        ...(newValues.competitiveFee[index]?.floatingChargeWithLimit ??
                          {}),
                        factorB: event.target.value,
                      },
                    };
                  }
                  return newValues;
                })
              }
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.competitiveFees?.factorB?.[index]}
                id={`competitiveFees.factorB.${index}`}
                title="Συντελεστής B"
                addComment={addComment}
              />
            ) : null}
          </Box>
        </Box>
        <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column' }}>
          <Box>
            <Typography variant="body1" color={theme.palette.primary.main}>
              Τιμή Ρήτρας = A x + B
            </Typography>
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.competitiveFees?.factorX?.[index]}
                id={`competitiveFees.factorX.${index}`}
                title="Τιμή Ρήτρας"
                addComment={addComment}
              />
            ) : null}
          </Box>

          <Box
            sx={{
              mt: 4,
              width: 1000,
              border: '1px solid #012C6D',
              p: 2,
              borderRadius: '16px',
            }}
          >
            <Calculator
              disabled={disableFields(reviewMode)}
              data={limitData?.[index]}
              setData={(newData: any) => {
                setLimitData((values: any) => {
                  const newValues = [...values];
                  newValues[index] = newData;
                  return newValues;
                });
              }}
              multi={
                obj?.competitiveFee?.[index]?.floatingChargeWithLimit
                  ?.clauseMethodology === 'alternative'
              }
              options={compFeesOptions}
              error={calcError}
            />
          </Box>
        </Box>
        <Typography sx={{ mt: 8 }} variant="h6" color={theme.palette.primary.main}>
          Περιοχή Προστασίας
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: { sm: 'column', md: 'row' } }}>
          <Box>
            <Input
              sx={{ mt: 2, width: { xs: '300px', md: '350px' } }}
              disabled={disableFields(reviewMode)}
              value={
                obj?.competitiveFee?.[index]?.floatingChargeWithLimit?.underProtection
              }
              type="default"
              label="Κάτω Προστασία"
              inputType={'number'}
              placeholder="πχ. 0,12 €/kWh"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setObj((values: ProductElectricTypes) => {
                  const newValues = { ...values };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      floatingChargeWithLimit: {
                        ...(newValues.competitiveFee[index]?.floatingChargeWithLimit ??
                          {}),
                        underProtection: event.target.value,
                      },
                    };
                  }
                  return newValues;
                })
              }
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.competitiveFees?.protectionLower?.[index]}
                id={`competitiveFees.protectionLower.${index}`}
                title="Κάτω Προστασία"
                addComment={addComment}
              />
            ) : null}
          </Box>

          <Box>
            <Input
              sx={{ ml: { sm: 0, md: 2 }, mt: 2, width: { xs: '300px', md: '350px' } }}
              disabled={disableFields(reviewMode)}
              value={obj?.competitiveFee?.[index]?.floatingChargeWithLimit?.topProtection}
              type="default"
              label="Άνω Προστασία"
              inputType={'number'}
              placeholder="πχ. 0,19 €/kWh"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setObj((values: ProductElectricTypes) => {
                  const newValues = { ...values };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      floatingChargeWithLimit: {
                        ...(newValues.competitiveFee[index]?.floatingChargeWithLimit ??
                          {}),
                        topProtection: event.target.value,
                      },
                    };
                  }
                  return newValues;
                })
              }
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.competitiveFees?.protectionUpper?.[index]}
                id={`competitiveFees.protectionUpper.${index}`}
                title="Άνω Προστασία"
                addComment={addComment}
              />
            ) : null}
          </Box>
        </Box>
        <Typography sx={{ mt: 8 }} variant="h6" color={theme.palette.primary.main}>
          Όρια στη Χρέωση της Ρήτρας
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: { sm: 'column', md: 'row' } }}>
          <Box>
            <Input
              sx={{ mt: 2, width: { xs: '300px', md: '350px' } }}
              disabled={disableFields(reviewMode)}
              value={obj?.competitiveFee?.[index]?.floatingChargeWithLimit?.maxReduction}
              type="default"
              label="Μέγιστη Μείωση"
              inputType={'number'}
              placeholder="πχ. -10%"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setObj((values: ProductElectricTypes) => {
                  const newValues = { ...values };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      floatingChargeWithLimit: {
                        ...(newValues.competitiveFee[index]?.floatingChargeWithLimit ??
                          {}),
                        maxReduction: event.target.value,
                      },
                    };
                  }
                  return newValues;
                })
              }
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.competitiveFees?.maxReduction?.[index]}
                id={`competitiveFees.maxReduction.${index}`}
                title="Μέγιστη Μείωση"
                addComment={addComment}
              />
            ) : null}
          </Box>

          <Box>
            <Input
              sx={{ ml: { sm: 0, md: 2 }, mt: 2, width: { xs: '300px', md: '350px' } }}
              disabled={disableFields(reviewMode)}
              value={obj?.competitiveFee?.[index]?.floatingChargeWithLimit?.maxRaise}
              type="default"
              label="Μέγιστη Αύξηση"
              inputType={'number'}
              placeholder="πχ. +10%"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setObj((values: ProductElectricTypes) => {
                  const newValues = { ...values };
                  if (newValues.competitiveFee?.[index]) {
                    newValues.competitiveFee[index] = {
                      ...newValues.competitiveFee[index],
                      floatingChargeWithLimit: {
                        ...(newValues.competitiveFee[index]?.floatingChargeWithLimit ??
                          {}),
                        maxRaise: event.target.value,
                      },
                    };
                  }
                  return newValues;
                })
              }
            />
            {showReviewComments(reviewMode) ? (
              <ReviewTool
                view={viewOnlyReviewComments(reviewMode)}
                msg={obj.reviewComments?.competitiveFees?.maxRaise?.[index]}
                id={`competitiveFees.maxRaise.${index}`}
                title="Μέγιστη Αύξηση"
                addComment={addComment}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
