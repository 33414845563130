import { Box, Container, Divider, Typography, useTheme } from '@mui/material';
import {
  BreadCrumbsCustom,
  ButtonRae,
  CustomTabs,
  HelpComponent,
  PopupConfirm,
} from '@rae/ui-library';
import { OptionsProps } from '@rae/ui-library/src/components/Calculator';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BenefitsTab } from '../components/ProductGasPage/BenefitsTab/BenefitsTab';
import { ClauseTab } from '../components/ProductGasPage/ClauseTab';
import { CompetitiveFeesTab } from '../components/ProductGasPage/CompetitiveFeesTab/CompetitiveFeesTab';
import { DiscountsTab } from '../components/ProductGasPage/DiscountTab/DiscountsTab';
import { OtherChargesTab } from '../components/ProductGasPage/OtherChargesTab/OtherChargesTab';
import {
  disableBenefits,
  disableCompFees,
  disableDiscounts,
  disableExitClause,
  disableOtherCharges,
  ProductGasTypes,
} from '../components/ProductGasPage/ProductGasTypes';
import { ProductInfoTab } from '../components/ProductGasPage/ProductInfoTab';
import { useGetProviderIdsGasQuery } from '../redux/apis/providerIdApi';
import { AppDispatch, RootState } from '../redux/store';
import { pageToSkipLimit } from '../types/ApiPagination';
import { providerIdGasTableData } from '../types/ProviderIdGas';
import { providerData } from '../types/Team';
import Loader from '../assets/loading.gif';
import { toBase64 } from '../utils/FileData';
import { addProductGas, getProductGasData } from '../types/ProductGas';
import { useGetCompetitiveFeesQuery } from '../redux/apis/CompetitiveFeesApi';
import { gasCompetitiveFeesData } from '../types/CompetitiveFees';
import { useGetOtherChargesQuery } from '../redux/apis/OtherChargesApi';
import { gasOtherCharges } from '../types/OtherCharges';
import { useGetDiscBenefTypeQuery } from '../redux/apis/DiscBenefTypeApi';
import { discBenefTypeData } from '../types/DiscBenefType';
import { useGetDiscountsQuery } from '../redux/apis/discountApi';
import { discountData } from '../types/Discount';
import { useGetBenefitsQuery } from '../redux/apis/benefitApi';
import { benefitData } from '../types/Benefit';
import {
  useAddProductGasMutation,
  useEditProductGasMutation,
  useGetProductGasQuery,
  useGetProductGasVersionsQuery,
} from '../redux/apis/productGasApi';
import { setLog } from '../redux/slices/appSlice';
import { useGetClauseCoefficientQuery } from '../redux/apis/clauseCoefficientApi';
import { gasClausesData } from '../types/ClauseCoefficient';
import {
  useAddProductLogoMutation,
  useArchiveProductMutation,
  useEditProductLogoMutation,
} from '../redux/apis/productApi';
import { useNavigate, useParams } from 'react-router-dom';
import { disableTabs, ReviewModeEnum, showReviewComments } from '../types/ReviewMode';
import { factorXExpressionToOptionsProps } from '../types/FactorX';
import { addReviewComment, countReviewComments } from '../types/ReviewComments';
import { ReviewStatusEnum } from '../types/ReviewStatus';
import ActionsComponent from '../components/ProductElectricPage/actions/ActionsComponent';
import moment from 'moment';
import { DownloadQRDialog } from '../components/QR/DownloadQRDialog';
import { isRaeTeam } from '../utils/PickTeam';
import { useSendNotificationMutation } from '../redux/apis/notificationApi';

export const ProductGas = () => {
  const theme = useTheme();
  const { team } = useSelector((state: RootState) => state.auth);
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [reviewMode, setReviewMode] = useState<ReviewModeEnum>(
    id ? ReviewModeEnum.Viewing : ReviewModeEnum.Creation,
  );
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openDownloadQR, setOpenDownloadQR] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState<number>(0);
  const [calculatorData, setCalculatorData] = useState<OptionsProps[][]>([[]]);

  const [obj, setObj] = useState<ProductGasTypes>({});

  useEffect(() => {
    if (!id) {
      setObj({});
      setCalculatorData([[]]);
    }
  }, [id]);

  const [addLogo, { isLoading: addLogoIsLoading }] = useAddProductLogoMutation();
  const [editLogo, { isLoading: editLogoIsLoading }] = useEditProductLogoMutation();

  const { data: providerIdsGas, isLoading: providersIdsLoading } =
    useGetProviderIdsGasQuery(pageToSkipLimit(0, 100, [`-updatedAt`]));

  const { data: competitiveFees, isLoading: competitiveFeesIsLoading } =
    useGetCompetitiveFeesQuery({
      ...pageToSkipLimit(0, 100, [`-updatedAt`]),
      energyType: 'gas',
    });

  const { data: otherCharges, isLoading: getOtherChargesIsLoading } =
    useGetOtherChargesQuery({
      ...pageToSkipLimit(0, 100, [`-updatedAt`]),
      energyType: 'gas',
    });

  const { data: discountTypes, isLoading: discountTypesIsLoading } =
    useGetDiscBenefTypeQuery({
      ...pageToSkipLimit(0, 100, [`-updatedAt`]),
      type: 'discount',
    });

  const { data: benefitsTypes, isLoading: benefitsTypesIsLoading } =
    useGetDiscBenefTypeQuery({
      ...pageToSkipLimit(0, 100, [`-updatedAt`]),
      type: 'benefit',
    });

  const typeIdDiscounts = useMemo(() => {
    const typeId = obj?.discounts?.flatMap(disc =>
      Object.keys(disc).length > 0 ? Object.keys(disc) : [],
    );
    if (!typeId || typeId.length === 0) return undefined;
  }, [obj?.discounts]);

  const typeIdBenefits = useMemo(() => {
    const typeId = obj?.benefits?.flatMap(benef =>
      Object.keys(benef).length > 0 ? Object.keys(benef) : [],
    );
    if (!typeId || typeId.length === 0) return undefined;
  }, [obj?.benefits]);

  const { data: discounts, isLoading: discountsIsLoading } = useGetDiscountsQuery({
    ...pageToSkipLimit(0, 100, [`-updatedAt`]),
    typeId: typeIdDiscounts,
    team: isRaeTeam(team) ? undefined : team?._id,
  });

  const { data: benefits, isLoading: benefitsIsLoading } = useGetBenefitsQuery({
    ...pageToSkipLimit(0, 100, [`-updatedAt`]),
    typeId: typeIdBenefits,
    team: isRaeTeam(team) ? undefined : team?._id,
  });

  const [addProductGasRequest, { isLoading: addProductGasIsLoading }] =
    useAddProductGasMutation();
  const [editProductGasRequest, { isLoading: editProductGasIsLoading }] =
    useEditProductGasMutation();
  const [archiveProductGasRequest, { isLoading: archiveProductGasIsLoading }] =
    useArchiveProductMutation();
  const [sendNotification] = useSendNotificationMutation();

  const {
    data: productGas,
    isLoading: getProductGasLoading,
    error: getProductGasError,
  } = useGetProductGasQuery(
    {
      _id: id ?? '',
    },
    { skip: !id },
  );

  const { data: versions, isLoading: versionsIsLoading } = useGetProductGasVersionsQuery(
    {
      _id: productGas?.original?._id ?? productGas?._id ?? '',
    },
    { skip: !id || !productGas },
  );

  const { data: clauses, isLoading: getClausesIsLoading } = useGetClauseCoefficientQuery({
    ...pageToSkipLimit(0, 100, [`-updatedAt`]),
    energyType: 'gas',
  });

  const compFeesOptions: OptionsProps[] = useMemo(() => {
    return gasClausesData(clauses?.documents).map(item => ({
      label: item.clauseCoefficientName,
      type: 'text',
      value: item._id,
    }));
  }, [clauses?.documents]);

  const links = useMemo(() => {
    return [{ name: 'Προϊόντα', href: '/' }, { name: 'Φυσικό Αερίο' }];
  }, []);

  const addComment = useCallback(
    (id: string, comment: string) => {
      setObj(prevObj => {
        const newObj = { ...prevObj };
        newObj.reviewComments = addReviewComment(newObj.reviewComments, id, comment);
        return newObj;
      });
    },
    [setObj],
  );

  const minDate = useMemo(() => {
    return productGas?.productInfo?.availabilityDate
      ? reviewMode === ReviewModeEnum.Versioning
        ? moment
            .max(
              moment(productGas?.productInfo?.availabilityDate).add(1, 'days'),
              moment(),
            )
            .startOf('day')
            .toDate()
        : moment
            .min(moment(productGas?.productInfo?.availabilityDate), moment())
            .startOf('day')
            .toDate()
      : moment().startOf('day').toDate();
  }, [productGas?.productInfo?.availabilityDate, reviewMode]);

  const tabs = useMemo(() => {
    return [
      {
        tabName: 'Πληροφορίες Προϊόντος',
        tabContent: (
          <ProductInfoTab
            obj={obj}
            setObj={setObj}
            providerIds={providerIdGasTableData(providerIdsGas?.documents)}
            reviewMode={reviewMode}
            addComment={addComment}
            minDate={minDate}
          />
        ),
        disabled: activeTab <= 0 && disableTabs(reviewMode),
        badge: showReviewComments(reviewMode)
          ? countReviewComments(obj.reviewComments?.productInfo)
          : undefined,
      },
      {
        tabName: 'Ανταγωνιστικές Χρεώσεις',
        tabContent: (
          <CompetitiveFeesTab
            obj={obj}
            setObj={setObj}
            calculatorData={calculatorData}
            setCalculatorData={setCalculatorData}
            compFees={gasCompetitiveFeesData(competitiveFees?.documents)}
            compFeesOptions={compFeesOptions}
            reviewMode={reviewMode}
            addComment={addComment}
          />
        ),
        disabled: activeTab <= 1 && disableTabs(reviewMode),
        badge: showReviewComments(reviewMode)
          ? countReviewComments(obj.reviewComments?.competitiveFees)
          : undefined,
      },
      {
        tabName: 'Λοιπές Χρεώσεις',
        tabContent: (
          <OtherChargesTab
            obj={obj}
            setObj={setObj}
            count={['Ημερήσια']}
            otherCharges={gasOtherCharges(otherCharges?.documents)}
            compFees={gasCompetitiveFeesData(competitiveFees?.documents)}
            reviewMode={reviewMode}
            addComment={addComment}
          />
        ),
        disabled: activeTab <= 2 && disableTabs(reviewMode),
        badge: showReviewComments(reviewMode)
          ? countReviewComments(obj.reviewComments?.otherCharges)
          : undefined,
      },
      {
        tabName: 'Εκπτώσεις',
        tabContent: (
          <DiscountsTab
            data={obj}
            setData={setObj}
            team={team}
            discountsTypes={discBenefTypeData(discountTypes?.discBenefType)}
            discounts={discountData(false, discounts?.documents)}
            compFees={gasCompetitiveFeesData(competitiveFees?.documents)}
            reviewMode={reviewMode}
            addComment={addComment}
          />
        ),
        disabled: activeTab <= 3 && disableTabs(reviewMode),
        badge: showReviewComments(reviewMode)
          ? countReviewComments(obj.reviewComments?.discounts)
          : undefined,
      },
      {
        tabName: 'Πρόσθετες Παροχές',
        tabContent: (
          <BenefitsTab
            data={obj}
            setData={setObj}
            team={team}
            benefitsTypes={discBenefTypeData(benefitsTypes?.discBenefType)}
            benefitsData={benefitData(false, benefits?.documents)}
            compFees={gasCompetitiveFeesData(competitiveFees?.documents)}
            reviewMode={reviewMode}
            addComment={addComment}
          />
        ),
        disabled: activeTab <= 4 && disableTabs(reviewMode),
        badge: showReviewComments(reviewMode)
          ? countReviewComments(obj.reviewComments?.benefits)
          : undefined,
      },
      {
        tabName: 'Ρήτρα Π. Αποχώρησης',
        tabContent: (
          <ClauseTab
            data={obj}
            setData={setObj}
            compFees={gasCompetitiveFeesData(competitiveFees?.documents)}
            reviewMode={reviewMode}
            addComment={addComment}
          />
        ),
        disabled: activeTab <= 5 && disableTabs(reviewMode),
        badge: showReviewComments(reviewMode)
          ? countReviewComments(obj.reviewComments?.exitClause)
          : undefined,
      },
    ];
  }, [
    team,
    minDate,
    addComment,
    activeTab,
    benefits?.documents,
    benefitsTypes?.discBenefType,
    calculatorData,
    compFeesOptions,
    competitiveFees?.documents,
    discountTypes?.discBenefType,
    discounts?.documents,
    obj,
    otherCharges?.documents,
    providerIdsGas?.documents,
    reviewMode,
  ]);

  const helpText = useMemo(() => {
    switch (activeTab) {
      case 0:
        return 'Μια επεξηγητική περιγραφή του προσφερόμενου προϊόντος.';
      case 1:
        return 'Καταχώρηση των Χρεώσεων Προμήθειας (Αναλογικών Χρεώσεων που προκύπτουν ανάλογα με το ύψος κατανάλωσης  του ρεύματος, και τις Πάγιες Χρεώσεις)';
      case 2:
        return 'Καταχώρηση επιπλέον χρεώσεων του προϊόντος που δεν έχουν υπολογισθεί σε προηγούμενα  πεδία. ';
      case 3:
        return 'Καταχώρηση των εκπτώσεων που εφαρμόζονται στο προσφερόμενο προϊόν.';
      case 4:
        return 'Αφορά πρόσθετες παροχές που μπορεί να προσφέρονται για το συγκεκριμένο προϊόν, όπως  δωροκάρτες, εκπτώσεις σε συνεργαζόμενες εταιρίες κ.λ.π.';
      case 5:
        return 'Όροι και συνέπειες αποχώρησης πελάτη πριν την λήξη συμβολαίου του παρόντος προϊόντος.';
      default:
        break;
    }
  }, [activeTab]);

  const setInitialData = useCallback(() => {
    if (reviewMode === ReviewModeEnum.Creation && team) {
      setObj((values: ProductGasTypes) => ({
        ...values,
        info: {
          ...values.info,
          provider: providerData(team),
        },
      }));
    } else if (reviewMode !== ReviewModeEnum.Creation && productGas && id) {
      setObj(getProductGasData(productGas));
      const calcData = productGas?.competitiveFees?.map(item => {
        if (item?.factorX?.expression) {
          return factorXExpressionToOptionsProps(
            item.factorX.expression,
            clauses?.documents,
          );
        } else {
          return [];
        }
      });
      setCalculatorData(calcData);
    }
  }, [reviewMode, team, productGas, id, clauses?.documents]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  useEffect(() => {
    if (
      getProductGasError &&
      'status' in getProductGasError &&
      getProductGasError.status === 404
    ) {
      navigate('/');
    }
  }, [getProductGasError, navigate]);

  const disableNext = useMemo(() => {
    switch (activeTab) {
      case 0:
        return (
          !obj?.info?.providerId ||
          !obj?.info?.duration ||
          !obj?.info?.productName ||
          !obj?.info?.productNameEn ||
          !obj.info.from ||
          moment(obj.info.from).isBefore(minDate)
        );
      case 1:
        return disableCompFees(obj);
      case 2:
        return disableOtherCharges(obj);
      case 3:
        return disableDiscounts(obj);
      case 4:
        return disableBenefits(obj);
      default:
        return false;
    }
  }, [activeTab, obj, minDate]);

  // useEffect(() => {
  //   if (obj.otherCharges?.otherCharges && obj.otherCharges?.otherCharges?.length > 0) {
  //     setObj((values: ProductGasTypes) => ({
  //       ...values,
  //       otherCharges: {
  //         ...values.otherCharges,
  //         other: values.otherCharges?.other ?? [],
  //       },
  //     }));
  //   } else {
  //     setObj((values: ProductGasTypes) => ({
  //       ...values,
  //       otherCharges: {
  //         ...values.otherCharges,
  //         other: [],
  //       },
  //     }));
  //   }
  // }, [obj.otherCharges?.otherCharges, setObj]);

  const cancelBtn = useCallback(() => {
    setInitialData();
    setActiveTab(0);
  }, [setInitialData]);

  const nextBtn = useCallback(() => {
    setActiveTab(tab => (tab === tabs.length - 1 ? tab : tab + 1));
  }, [tabs.length]);

  const handleDelete = useCallback(async () => {
    if (!productGas) return;
    try {
      await archiveProductGasRequest({
        _id: productGas.original?._id ?? productGas._id,
        type: 'gas',
      }).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: 'Το προϊόν Φυσικού Αερίου διαγράφηκε με επιτυχία',
        }),
      );
    } catch (error: any) {
      console.log(error);
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setActiveTab(0);
  }, [archiveProductGasRequest, dispatch, productGas]);

  const handleReview = useCallback(async () => {
    if (!productGas) return;
    try {
      await editProductGasRequest({
        _id: productGas._id,
        reviewComments: obj.reviewComments,
        reviewStatus:
          countReviewComments(obj.reviewComments) > 0
            ? ReviewStatusEnum.Revision
            : ReviewStatusEnum.Accepted,
      }).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: 'Η Διαδικασία Έγκρισης προϊόντος Φυσικού Αερίου ολοκληρώθηκε',
        }),
      );
      sendNotification({
        productId: productGas._id,
        productType: 'gas',
        status: countReviewComments(obj.reviewComments) > 0 ? 'comment' : 'accept',
      });
    } catch (error: any) {
      console.log(error);
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setActiveTab(0);
  }, [dispatch, editProductGasRequest, obj.reviewComments, productGas, sendNotification]);

  const handleReject = useCallback(async () => {
    if (!productGas) return;
    try {
      await editProductGasRequest({
        _id: productGas._id,
        reviewStatus: ReviewStatusEnum.Rejected,
      }).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: 'Η Διαδικασία Απόρριψης προϊόντος Φυσικού Αερίου ολοκληρώθηκε',
        }),
      );
      sendNotification({
        productId: productGas._id,
        productType: 'gas',
        status: 'reject',
      });
    } catch (error: any) {
      console.log(error);
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setActiveTab(0);
  }, [dispatch, editProductGasRequest, productGas, sendNotification]);

  const handleRevise = useCallback(async () => {
    if (!productGas) return;
    let updatedProduct = { ...obj };
    if (obj.info?.productImg) {
      const logo = await toBase64(obj.info?.productImg);
      const { _id: logoId } = productGas?.productInfo?.image
        ? await editLogo({
            _id: productGas.productInfo?.image?._id,
            name: obj.info?.productImg.name,
            mimeType: obj.info?.productImg.type,
            data: logo,
          }).unwrap()
        : await addLogo({
            name: obj.info?.productImg.name,
            mimeType: obj.info?.productImg.type,
            data: logo,
          }).unwrap();
      updatedProduct = {
        ...updatedProduct,
        info: {
          ...updatedProduct.info,
          productImgId: logoId,
        },
      };
    }
    const editFields = addProductGas(updatedProduct, calculatorData);
    try {
      await editProductGasRequest({
        ...editFields,
        _id: productGas?._id,
        reviewStatus: ReviewStatusEnum.Pending,
      }).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: 'Η Επεξεργασία προϊόντος Φυσικού Αερίου ολοκληρώθηκε',
        }),
      );
      sendNotification({
        productId: productGas._id,
        productType: 'gas',
        status: 'revision',
      });
    } catch (error: any) {
      console.log(error);
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setActiveTab(0);
  }, [
    productGas,
    obj,
    calculatorData,
    editLogo,
    addLogo,
    editProductGasRequest,
    dispatch,
    sendNotification,
  ]);

  const handleEdit = useCallback(async () => {
    if (!productGas) return;
    let updatedProduct = { ...obj };
    if (obj.info?.productImg) {
      const logo = await toBase64(obj.info?.productImg);
      const { _id: logoId } = productGas?.productInfo?.image
        ? await editLogo({
            _id: productGas.productInfo?.image?._id,
            name: obj.info?.productImg.name,
            mimeType: obj.info?.productImg.type,
            data: logo,
          }).unwrap()
        : await addLogo({
            name: obj.info?.productImg.name,
            mimeType: obj.info?.productImg.type,
            data: logo,
          }).unwrap();
      updatedProduct = {
        ...updatedProduct,
        info: {
          ...updatedProduct.info,
          productImgId: logoId,
        },
      };
    }
    const editFields = addProductGas(updatedProduct, calculatorData);
    try {
      await editProductGasRequest({
        ...editFields,
        _id: productGas?._id,
      }).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: 'Η Επεξεργασία προϊόντος Φυσικού Αερίου ολοκληρώθηκε',
        }),
      );
    } catch (error: any) {
      console.log(error);
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setActiveTab(0);
  }, [
    productGas,
    obj,
    calculatorData,
    editLogo,
    addLogo,
    editProductGasRequest,
    dispatch,
  ]);

  const handleCreate = useCallback(async () => {
    let updatedProduct = { ...obj };
    if (obj.info?.productImg) {
      const logo = await toBase64(obj.info?.productImg);
      const [{ _id: logoId }] = await Promise.all([
        addLogo({
          name: obj.info?.productImg.name,
          mimeType: obj.info?.productImg.type,
          data: logo,
        }).unwrap(),
      ]);
      updatedProduct = {
        ...updatedProduct,
        info: {
          ...updatedProduct.info,
          productImgId: logoId,
        },
      };
    }
    const addFields = addProductGas(updatedProduct, calculatorData);
    try {
      const { _id: productId } = await addProductGasRequest(addFields).unwrap();
      navigate(`/product-gas/${productId}`);
      dispatch(
        setLog({
          severity: 'success',
          message: 'Η Δημιουργία προϊόντος Φυσικού Αερίου ολοκληρώθηκε',
        }),
      );
      sendNotification({
        productId: productId,
        productType: 'gas',
        status: 'create',
      });
    } catch (error: any) {
      console.log(error);
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setActiveTab(0);
  }, [
    obj,
    calculatorData,
    addLogo,
    addProductGasRequest,
    navigate,
    dispatch,
    sendNotification,
  ]);

  const handleCreateVersion = useCallback(async () => {
    if (!productGas) return;
    let updatedProduct = { ...obj };
    if (obj.info?.productImg) {
      const logo = await toBase64(obj.info?.productImg);
      const [{ _id: logoId }] = await Promise.all([
        addLogo({
          name: obj.info?.productImg.name,
          mimeType: obj.info?.productImg.type,
          data: logo,
        }).unwrap(),
      ]);
      updatedProduct = {
        ...updatedProduct,
        info: {
          ...updatedProduct.info,
          productImgId: logoId,
        },
      };
    }
    const addFields = addProductGas(updatedProduct, calculatorData);
    try {
      const { _id: productId } = await addProductGasRequest({
        original: productGas.original?._id ?? productGas._id,
        ...addFields,
      }).unwrap();
      navigate(`/product-gas/${productId}`);
      dispatch(
        setLog({
          severity: 'success',
          message: 'Η Δημιουργία προϊόντος Φυσικού Αερίου ολοκληρώθηκε',
        }),
      );
      sendNotification({
        productId: productId,
        productType: 'gas',
        status: 'version',
      });
    } catch (error: any) {
      console.log(error);
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setActiveTab(0);
  }, [
    productGas,
    obj,
    calculatorData,
    addLogo,
    addProductGasRequest,
    navigate,
    dispatch,
    sendNotification,
  ]);

  const handleSave = useCallback(() => {
    switch (reviewMode) {
      case ReviewModeEnum.Creation:
        handleCreate();
        break;
      case ReviewModeEnum.Reviewing:
        handleReview();
        break;
      case ReviewModeEnum.Editing:
        handleEdit();
        break;
      case ReviewModeEnum.Revising:
        handleRevise();
        break;
      case ReviewModeEnum.Versioning:
        handleCreateVersion();
        break;
      default:
        break;
    }
    setReviewMode(ReviewModeEnum.Viewing);
  }, [
    handleCreate,
    handleCreateVersion,
    handleEdit,
    handleReview,
    handleRevise,
    reviewMode,
  ]);

  const isLoading =
    addLogoIsLoading ||
    editLogoIsLoading ||
    competitiveFeesIsLoading ||
    discountTypesIsLoading ||
    benefitsTypesIsLoading ||
    discountsIsLoading ||
    benefitsIsLoading ||
    getOtherChargesIsLoading ||
    addProductGasIsLoading ||
    editProductGasIsLoading ||
    archiveProductGasIsLoading ||
    getClausesIsLoading ||
    getProductGasLoading ||
    versionsIsLoading ||
    providersIdsLoading;

  const disableSave = useMemo(() => {
    if (reviewMode === ReviewModeEnum.Reviewing) return false;
    return disableExitClause(obj);
  }, [obj, reviewMode]);

  const handleDeleteProduct = () => {
    handleDelete();
    navigate('/');
  };

  const handleRejectProduct = () => {
    handleReject();
  };

  const handleQRProduct = () => {
    setOpenDownloadQR(true);
  };

  const pageTitle = useMemo(() => {
    switch (reviewMode) {
      case ReviewModeEnum.Creation:
        return 'Δημιουργία Προϊόντος Φυσικού Αερίου';
      case ReviewModeEnum.Editing:
        return 'Επεξεργασία Προϊόντος Φυσικού Αερίου';
      case ReviewModeEnum.Reviewing:
        return 'Έγκριση Προϊόντος Φυσικού Αερίου';
      case ReviewModeEnum.Revising:
        return 'Διόρθωση Προϊόντος Φυσικού Αερίου';
      case ReviewModeEnum.Versioning:
        return 'Δημιουργία Νέας Έκδοσης Προϊόντος Φυσικού Αερίου';
      case ReviewModeEnum.Viewing:
        return 'Προβολή Προϊόντος Φυσικού Αερίου';
    }
  }, [reviewMode]);

  const handleSetReviewMode = (reviewMode: ReviewModeEnum) => {
    setReviewMode(reviewMode);
    setActiveTab(0);
  };

  return (
    <Container maxWidth={'xl'} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mt: 4 }}>
        <BreadCrumbsCustom navigate={navigate} links={links} />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography sx={{ minWidth: '200px' }} fontSize={'18px'} fontWeight={700}>
          {pageTitle}
        </Typography>
        <Divider
          sx={{
            mt: 1,
            width: '10%',
            borderBottomWidth: 4,
            borderColor: theme.palette.primary.main,
          }}
          textAlign="left"
        />
      </Box>
      {id && (
        <ActionsComponent
          team={team}
          product={productGas}
          versions={versions}
          handleNavigate={id => navigate(`/product-gas/${id}`)}
          reviewMode={reviewMode}
          setReviewMode={handleSetReviewMode}
          handleDelete={() => setOpenDelete(true)}
          handleReject={handleRejectProduct}
          handleQR={handleQRProduct}
          comments={countReviewComments(obj.reviewComments)}
        />
      )}
      <Box sx={{ mt: 4, mb: 4 }}>
        {isLoading ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img style={{ width: '140px' }} src={Loader} alt="loading..." />
          </Box>
        ) : (
          <CustomTabs
            tabs={tabs}
            chipStyle={true}
            newTab={activeTab}
            setActive={setActiveTab}
          />
        )}
        <HelpComponent icon="lamp-on" helpText={helpText} />
      </Box>

      <Box sx={{ mt: 4, mb: 4, display: 'flex', justifyContent: 'end' }}>
        {activeTab === 0 ? null : (
          <ButtonRae
            color="secondary"
            variant="contained"
            sx={{ mr: 2, width: 200 }}
            onClick={cancelBtn}
          >
            Ακύρωση
          </ButtonRae>
        )}
        <ButtonRae
          color="primary"
          variant="contained"
          sx={{ width: 200 }}
          disabled={activeTab === 5 ? disableSave : disableNext}
          onClick={activeTab === 5 ? handleSave : nextBtn}
        >
          {activeTab === 5 ? 'Αποθήκευση' : 'Επόμενο'}
        </ButtonRae>
      </Box>
      <PopupConfirm
        title="Διαγραφή Προϊόντος Φυσικού Αερίου"
        subTile="Προσοχή! Η διαγραφή αφορά στο συγκεκριμένο τιμολόγιο καθώς και σε ενδεχόμενα προηγούμενα versions του τιμολογίου. Είστε σίγουρος ότι θέλετε να προχωρήσετε στη διαγραφή του προϊόντος φυσικού αερίου;"
        leftBtnTitle="Όχι"
        rightBtnTitle="Ναι"
        open={openDelete}
        setOpen={setOpenDelete}
        onClose={() => setOpenDelete(false)}
        onClickLeftBtn={() => setOpenDelete(false)}
        onClickRightBtn={handleDeleteProduct}
      />
      <DownloadQRDialog
        qrText={`${process.env.REACT_APP_COMPARISON_BASE_URL}?mode=${'gas'}&provider=${
          productGas?.productInfo?.team?._id
        }&product=${productGas?._id}`}
        fileName={`RAE_QR_${productGas?.productInfo?.nameEn}`}
        title={'Κατέβασμα QR'}
        helpText={'Κατεβάστε το QR του προϊόντος'}
        open={openDownloadQR}
        setOpen={setOpenDownloadQR}
      />
    </Container>
  );
};
