import { Box, Container } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import {
  BreadCrumbsCustom,
  CustomTable,
  Tooltip,
  CustomChip,
  PopupConfirm,
  PopupForm,
} from '@rae/ui-library';
import { HeadCell, OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import { TableProviderProps } from '@rae/ui-library/src/components/table/TableRowProvider';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ProviderModal } from '../components/providerPage/modalContent';
import {
  useAddLogoMutation,
  useAddTeamMutation,
  useDeleteTeamMutation,
  useEditLogoMutation,
  useEditTeamMutation,
  useGetTeamsQuery,
} from '../redux/apis/teamApi';
import { addProviderData, editProviderData, providersData } from '../types/Team';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { setLog } from '../redux/slices/appSlice';
import Loader from '../assets/loading.gif';
import { toBase64 } from '../utils/FileData';
import { pageToSkipLimit } from '../types/ApiPagination';
import { DropDownNewProps } from '@rae/ui-library/src/components/DropdownNew';
import useDebounce from '../utils/useDebounce';
import { useNavigate } from 'react-router-dom';

export const ProvidersPage = () => {
  const navigate = useNavigate();
  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [order, setOrder] = useState<OrderProps>('asc');
  const [orderBy, setOrderBy] = useState<HeadCell['id']>('updatedAt');
  const [page, setPage] = useState<number>(0);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [type, setType] = useState<'edit' | 'save' | undefined>();
  const [search, setSearch] = useState<string>('');
  const [provider, setProvider] = useState<TableProviderProps>({
    infoProvider: '',
    modDate: '',
    modEmail: '',
    phone: '',
    providerImg: undefined,
    providerName: '',
    suspended: false,
  });
  const [providerInit, setProviderInit] = useState<TableProviderProps>({
    providerName: '',
  });

  const links = [{ name: 'Προϊόντα', href: '/' }, { name: 'Πάροχοι' }];

  const dispatch = useDispatch<AppDispatch>();
  const debouncedSearch = useDebounce<string>(search, 300);

  const { data: providers, isLoading: getProvidersisLoading } = useGetTeamsQuery({
    ...pageToSkipLimit(page, 5, [`${order === 'asc' ? '-' : ''}${orderBy}`]),
    search: debouncedSearch === '' ? undefined : debouncedSearch,
    isSuspended: selectedStatus[0]
      ? selectedStatus[0] === 'Σε διαθεσιμότητα'
        ? true
        : selectedStatus[0] === 'Ενεργοποιημένος'
        ? false
        : undefined
      : undefined,
  });

  const [addProvider, { isLoading: addProviderIsLoading }] = useAddTeamMutation();
  const [editProvider, { isLoading: editProviderIsLoading }] = useEditTeamMutation();
  const [deleteProvider, { isLoading: deleteProviderIsLoading }] =
    useDeleteTeamMutation();
  const [addLogo, { isLoading: addLogoIsLoading }] = useAddLogoMutation();
  const [editLogo, { isLoading: editLogoIsLoading }] = useEditLogoMutation();

  const dropDownsNew: DropDownNewProps[] = [
    {
      choices: [
        { label: 'Ενεργοποιημένος', value: 'Ενεργοποιημένος' },
        { label: 'Σε διαθεσιμότητα', value: 'Σε διαθεσιμότητα' },
      ],
      open: openStatus,
      selected: selectedStatus,
      setOpen: setOpenStatus,
      setSelected: setSelectedStatus,
      label: 'Κατασταση',
      multiple: false,
      labelBtn: 'Εφαρμογή',
    },
  ];

  const clearFilters = useCallback(() => {
    setSelectedStatus([]);
    setSearch('');
  }, []);

  const deleteChip = useCallback(
    (chipLabel: string) => {
      setSelectedStatus(selectedStatus.filter(label => label !== chipLabel));
      if (search === chipLabel) {
        setSearch('');
      }
    },
    [search, selectedStatus],
  );

  const filters = useMemo(
    () => selectedStatus.concat(search !== '' ? search : []),
    [search, selectedStatus],
  );

  const handleDelete = useCallback(async () => {
    setOpenModalDelete(false);
    if (!provider || !provider._id) return;
    try {
      await deleteProvider({ _id: provider._id }).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: `Ο διαχειριστής διαγράφθηκε επιτυχώς`,
        }),
      );
    } catch (e: any) {
      console.log(e);
      dispatch(
        setLog({
          severity: 'error',
          message: `${e.data.message}`,
        }),
      );
    }
    setProvider({
      infoProvider: '',
      modDate: '',
      modEmail: '',
      phone: '',
      providerImg: undefined,
      providerName: '',
      suspended: false,
    });
  }, [deleteProvider, dispatch, provider]);

  const deleteRow = useCallback((obj: TableProviderProps) => {
    setProvider(obj);
    setOpenModalDelete(true);
  }, []);

  const handleSave = useCallback(async () => {
    setOpenModal(false);
    switch (type) {
      case 'save':
        if (!provider || !provider.providerName || !provider.providerImg) return;
        try {
          const newTeam = addProviderData(provider);
          const logo = await toBase64(provider.providerImg);
          const [{ _id: logoId }, { _id }] = await Promise.all([
            addLogo({
              name: provider.providerImg.name,
              mimeType: provider.providerImg.type,
              data: logo,
            }).unwrap(),
            addProvider(newTeam).unwrap(),
          ]);
          const updatedProvider = {
            ...provider,
            _id: _id,
            providerImgId: logoId,
          };
          const addFields = editProviderData(updatedProvider);
          await editProvider(addFields).unwrap();
          dispatch(
            setLog({
              severity: 'success',
              message: `Ο διαχειριστής αποθηκεύτηκε επιτυχώς`,
            }),
          );
        } catch (e: any) {
          dispatch(
            setLog({
              severity: 'error',
              message: `${e.data.message}`,
            }),
          );
        }
        break;
      case 'edit':
        if (!provider || !provider.providerName || !provider._id || !provider.providerImg)
          return;
        try {
          if (provider.providerImg) {
            if (!providerInit.providerImgId) return;
            const logo = await toBase64(provider.providerImg);
            await editLogo({
              _id: providerInit.providerImgId,
              name: provider.providerImg.name,
              mimeType: provider.providerImg.type,
              data: logo,
            }).unwrap();
          }
          const editFields = editProviderData(provider);
          await editProvider(editFields).unwrap();
          dispatch(
            setLog({
              severity: 'success',
              message: `Ο διαχειριστής επεξεργάστηκε επιτυχώς`,
            }),
          );
        } catch (e: any) {
          dispatch(
            setLog({
              severity: 'error',
              message: `${e.data.message}`,
            }),
          );
        }
        break;
      default:
        break;
    }
    setProviderInit({ providerName: '' });
    setProvider({
      infoProvider: '',
      modDate: '',
      modEmail: '',
      phone: '',
      providerImg: undefined,
      providerName: '',
      suspended: false,
    });
  }, [
    addLogo,
    editLogo,
    addProvider,
    dispatch,
    editProvider,
    provider,
    providerInit.providerImgId,
    type,
  ]);

  const addProviderModal = () => {
    setType('save');
    setProvider({
      infoProvider: '',
      modDate: '',
      modEmail: '',
      phone: '',
      providerImg: undefined,
      providerName: '',
      suspended: false,
    });
    setOpenModal(true);
  };

  const editProviderModal = useCallback((obj: TableProviderProps) => {
    setType('edit');
    setProvider(obj);
    setProviderInit(obj);
    setOpenModal(true);
  }, []);

  const isLoading =
    addProviderIsLoading ||
    editProviderIsLoading ||
    addLogoIsLoading ||
    deleteProviderIsLoading ||
    editLogoIsLoading ||
    getProvidersisLoading;

  const disableSave =
    !provider.providerName ||
    typeof provider.suspended === 'undefined' ||
    !provider.services ||
    provider.services.length === 0 ||
    !provider.phone ||
    !provider.providerImg;

  return (
    <Container maxWidth={'xl'} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mt: 4 }}>
        <BreadCrumbsCustom navigate={navigate} links={links} />
      </Box>

      <Box sx={{ mt: 4 }}>
        {filters.length > 0 ? (
          <Tooltip
            text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
            type="info"
            btnAction={clearFilters}
            btnLabel="Καθαρισμός Φίλτρων"
            dismiss={true}
            icon={<InfoOutlinedIcon fontSize="small" />}
          />
        ) : null}
      </Box>

      <Box sx={{ mt: 2 }}>
        {filters.map((chiplabel, i) => {
          return (
            <CustomChip
              key={i}
              label={chiplabel}
              onDelete={() => deleteChip(chiplabel)}
            />
          );
        })}
      </Box>
      <Box sx={{ mt: 4, transition: 'all 0.3s ease' }}>
        {!providers?.documents && isLoading ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img style={{ width: '140px' }} src={Loader} alt="loading..." />
          </Box>
        ) : (
          <CustomTable
            buttonProps={{
              variant: 'contained',
              onClick: addProviderModal,
              children: 'Προσθήκη',
            }}
            headCells={[
              { disable: false, id: 'nameGr', label: 'Πάροχος' },
              { disable: true, id: 'type', label: 'Τύπος' },
              { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
              { disable: false, id: 'suspended', label: 'Κατάσταση' },
              { disable: true, id: 'actions', label: 'Ενέργειες' },
            ]}
            searchInput={{
              onChange: e => setSearch(e.target.value),
              value: search,
              type: 'search',
              label: 'Αναζήτηση',
            }}
            type="provider"
            title="Διαχείριση Παρόχων"
            handleView={() => {}}
            handleDelete={(obj: TableProviderProps) => deleteRow(obj)}
            handleEdit={(obj: TableProviderProps) => editProviderModal(obj)}
            count={providers?.count ?? 0}
            items={[
              { label: 'Ηλεκτρική Ενέργεια', iconType: 'electric' },
              { label: 'Φυσικο Αέριο', iconType: 'gas' },
            ]}
            rows={providersData(providers?.documents)}
            //dropDowns={dropDowns}
            dropDownsNew={dropDownsNew}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            page={page}
            rowsPerPage={5}
            setPage={setPage}
          />
        )}
      </Box>

      <PopupForm
        InfoTootipProps={undefined}
        title={type === 'save' ? 'Προσθήκη Παρόχου' : 'Επεξεργασία Παρόχου'}
        helpText="Τα παραπάνω στοιχεία αφορούν στα βασικά στοιχεία των χρηστών του Εργαλείου Διαχείρισης."
        leftBtnTitle="Ακύρωση"
        rightBtnTitle={'Αποθήκευση'}
        open={openModal}
        setOpen={setOpenModal}
        onClose={() => setOpenModal(false)}
        onClickLeftBtn={() => setOpenModal(false)}
        onClickRightBtn={handleSave}
        rightBtnProps={{ disabled: disableSave }}
      >
        <ProviderModal
          provider={provider}
          setProvider={setProvider}
          file={provider?.providerImg}
          setFile={(file: any) =>
            setProvider((values: TableProviderProps) => ({
              ...values,
              providerImg: file,
            }))
          }
        />
      </PopupForm>

      <PopupConfirm
        title="Διαγραφή Παρόχου"
        subTile="Είστε σίγουρος ότι θέλετε να διαγράψετε το συγκεκριμένο Πάροχο;"
        helpText="Αυτή η ενέργεια έχει ώς αποτέλεσμα την απενεργοποίηση όλων των τιμολογίων που σχετίζοναι με τον πάροχο."
        leftBtnTitle="Οχι"
        rightBtnTitle="Ναι"
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        onClose={() => setOpenModalDelete(false)}
        onClickLeftBtn={() => setOpenModalDelete(false)}
        onClickRightBtn={handleDelete}
      />
    </Container>
  );
};
