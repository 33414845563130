import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, Location } from 'react-router-dom';
import { useGetTeamQuery, useGetUserQuery } from '../redux/apis/authApi';
import { RootState } from '../redux/store';
import { currentTeam, isRaeTeam } from '../utils/PickTeam';

const ProtectedRoutes = () => {
  const { accessToken } = useSelector((state: RootState) => state.auth);
  const { data: userData } = useGetUserQuery(undefined, { skip: !accessToken });
  const { data: teamData, isLoading: teamIsLoading } = useGetTeamQuery(undefined, {
    skip: !accessToken,
  });
  const location = useLocation();

  if (!accessToken) {
    return <Navigate to="/login" replace />;
  }

  if (teamData?.count === 0) {
    return <Navigate to="/not_member" replace />;
  }

  if (
    !teamIsLoading &&
    !isRaeTeam(currentTeam(teamData?.teams)) &&
    isRaeProtectedLocation(location)
  ) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

const RAEProtectedPaths = [
  '/providers',
  '/clauses',
  '/regular-electric-charges',
  '/providerid',
  '/social-invoice',
  '/avrg-consumption',
  '/competitive-charges',
  '/documents',
  '/charges',
  '/special-discounts',
  '/regular-gas-charges',
];
const isRaeProtectedLocation = (location: Location): boolean => {
  const pathnameRoot = location.pathname?.split(/(?=\/)/g)?.[0];
  return RAEProtectedPaths.includes(pathnameRoot);
};

export default ProtectedRoutes;
