import api from './api';
import { Identifier } from '../../types/ApiSchema';
import { StorageFile, StorageFileProps } from '../../types/StorageFile';
import { toFileName } from '../../utils/FileData';
import { ApiPagination, PaginationProductsResult } from '../../types/ApiPagination';
import {
  Product,
  ProductCompetitiveFeesHistoricityParams,
  ProductDeleteParams,
  ProductIdParams,
  ProductOtherChargesHistoricityParams,
  ProductParams,
} from '../../types/Product';

const productApi = api
  .enhanceEndpoints({ addTagTypes: ['ProductElectric', 'ProductGas', 'ProductLinked'] })
  .injectEndpoints({
    endpoints: builder => ({
      getProducts: builder.query<
        PaginationProductsResult<Product>,
        ProductParams & ApiPagination
      >({
        query: params => ({
          url: `/raeService/products`,
          method: 'GET',
          params: params,
        }),
        providesTags: result =>
          result?.products
            ? [
                ...result.products.map(({ _id, type }) => ({ type, _id } as const)),
                { type: 'ProductElectric', id: 'LIST' },
                { type: 'ProductGas', id: 'LIST' },
                { type: 'ProductLinked', id: 'LIST' },
              ]
            : [
                { type: 'ProductElectric', id: 'LIST' },
                { type: 'ProductGas', id: 'LIST' },
                { type: 'ProductLinked', id: 'LIST' },
              ],
      }),
      deleteProduct: builder.mutation<void, ProductDeleteParams & Identifier>({
        query: ({ _id, type }) => ({
          url: `/raeService/products/${_id}`,
          method: 'DELETE',
          params: { type },
        }),
        invalidatesTags: (result, error, { _id }) => [
          { type: 'ProductElectric', _id },
          { type: 'ProductGas', _id },
          { type: 'ProductLinked', _id },
          { type: 'ProductElectric', id: 'LIST' },
          { type: 'ProductGas', id: 'LIST' },
          { type: 'ProductLinked', id: 'LIST' },
        ],
      }),
      archiveProduct: builder.mutation<void, ProductDeleteParams & Identifier>({
        query: ({ _id, type }) => ({
          url: `/raeService/products/archive/${_id}`,
          method: 'PATCH',
          body: { type },
        }),
        invalidatesTags: (result, error, { _id }) => [
          { type: 'ProductElectric', _id },
          { type: 'ProductGas', _id },
          { type: 'ProductLinked', _id },
          { type: 'ProductElectric', id: 'LIST' },
          { type: 'ProductGas', id: 'LIST' },
          { type: 'ProductLinked', id: 'LIST' },
        ],
      }),
      addProductLogo: builder.mutation<StorageFile, StorageFileProps>({
        query: ({ name, ...params }) => ({
          url: `storage/file`,
          method: 'POST',
          body: {
            ...params,
            name: toFileName(name, true),
            container: 'rae-public',
            folder: 'product-images/',
            isPublic: true,
          },
        }),
      }),
      editProductLogo: builder.mutation<StorageFile, Identifier & StorageFileProps>({
        query: ({ _id, name, ...params }) => ({
          url: `storage/file/${_id}`,
          method: 'PATCH',
          body: {
            ...params,
            name: toFileName(name, true),
            container: 'rae-public',
            folder: 'product-images/',
            isPublic: true,
          },
        }),
      }),
      getProduct: builder.query<Product, ProductIdParams>({
        query: ({ _id, ...params }) => ({
          url: `/raeService/products/${_id}`,
          method: 'GET',
          params: params,
        }),
      }),
      getCompetitiveFeesHistoricity: builder.query<
        PaginationProductsResult<Product>,
        ProductCompetitiveFeesHistoricityParams & ApiPagination
      >({
        query: params => ({
          url: `/raeService/products/historicity/competitive-fees`,
          method: 'GET',
          params: params,
        }),
      }),
      getOtherChargesHistoricity: builder.query<
        PaginationProductsResult<Product>,
        ProductOtherChargesHistoricityParams & ApiPagination
      >({
        query: params => ({
          url: `/raeService/products/historicity/other-charges`,
          method: 'GET',
          params: params,
        }),
      }),
    }),
  });

export const {
  useGetProductsQuery,
  useAddProductLogoMutation,
  useEditProductLogoMutation,
  useDeleteProductMutation,
  useArchiveProductMutation,
  useGetCompetitiveFeesHistoricityQuery,
  useGetOtherChargesHistoricityQuery,
  useGetProductQuery,
} = productApi;

export default productApi;
