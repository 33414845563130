import { Box, Container } from '@mui/material';
import { BreadCrumbsCustom, CustomTable, PopupConfirm, PopupForm } from '@rae/ui-library';
import { HeadCell, OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import { TableSocialInvoiceProps } from '@rae/ui-library/src/components/table/TableRowSocialInvoice';
import React, { useCallback, useState } from 'react';
import { DialogAdd } from '../components/SocialInvoice/DialogContent';
import {
  useAddSocialInvoiceMutation,
  useDeleteSocialInvoiceMutation,
  useEditSocialInvoiceMutation,
  useGetSocialInvoicesQuery,
} from '../redux/apis/socialInvoiceApi';
import { pageToSkipLimit } from '../types/ApiPagination';
import { Identifier } from '../types/ApiSchema';
import { SocialInvoiceFields, socialInvoiceTableData } from '../types/SocialInvoice';
import { useNavigate } from 'react-router-dom';

type TypeProps = 'add' | 'edit';

export const SocialInvoice = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState<OrderProps>('asc');
  const [orderBy, setOrderBy] = useState<HeadCell['id']>('updatedAt');
  const [page, setPage] = useState<number>(0);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [type, setType] = useState<TypeProps>('add');
  const [openDialogAdd, setOpenDialogAdd] = useState<boolean>(false);
  const [obj, setObj] = useState<TableSocialInvoiceProps>({});

  const links = [
    { name: 'Διαχείριση', href: '/' },
    { name: 'Έκπτωση Κοινωνικών Τιμολογίων' },
  ];

  const [addSocialInvoiceDiscount] = useAddSocialInvoiceMutation();
  const [editSocialInvoice] = useEditSocialInvoiceMutation();
  const [deleteSocialInvoice] = useDeleteSocialInvoiceMutation();

  const { data: socialinvoices } = useGetSocialInvoicesQuery(
    pageToSkipLimit(page, 5, [`${order === 'asc' ? '-' : ''}${orderBy}`]),
  );

  const handleAdd = useCallback(() => {
    setType('add');
    setObj(obj);
    setOpenDialogAdd(true);
  }, [obj]);

  const handleEdit = useCallback((obj: TableSocialInvoiceProps) => {
    setType('edit');
    setObj(obj);
    setOpenDialogAdd(true);
  }, []);

  const handleSave = useCallback(() => {
    switch (type) {
      case 'add':
        if (obj) {
          const addFields: SocialInvoiceFields = {
            kwh: obj.kwh ? obj.kwh : 0,
            socialInvoiceName: obj.socialInvoiceName ? obj.socialInvoiceName : '',
            socialInvoiceNameEn: obj.socialInvoiceNameEn ? obj.socialInvoiceNameEn : '',
            regulatedCharges: obj.regulatedCharges,
          };
          addSocialInvoiceDiscount(addFields);
        }
        setOpenDialogAdd(false);
        setObj({});
        break;
      case 'edit':
        if (!obj || !obj?._id) return;
        const editFields: Identifier & SocialInvoiceFields = {
          _id: obj._id,
          kwh: obj.kwh ? obj.kwh : 0,
          socialInvoiceName: obj.socialInvoiceName ? obj.socialInvoiceName : '',
          socialInvoiceNameEn: obj.socialInvoiceNameEn ? obj.socialInvoiceNameEn : '',
          regulatedCharges: obj.regulatedCharges,
        };
        editSocialInvoice(editFields);
        setOpenDialogAdd(false);
        setObj({});
        break;
      default:
        break;
    }
  }, [addSocialInvoiceDiscount, editSocialInvoice, obj, type]);

  const handleDelete = useCallback((param: TableSocialInvoiceProps) => {
    setObj(param);
    setOpenDelete(true);
  }, []);

  const handleFinalDelete = useCallback(() => {
    if (obj && obj._id) {
      const delete_id: Identifier = {
        _id: obj._id,
      };
      deleteSocialInvoice(delete_id);
    }
    setOpenDelete(false);
  }, [deleteSocialInvoice, obj]);

  const handleCloseFormDialog = useCallback(() => {
    setOpenDialogAdd(false);
    setObj({});
  }, []);

  return (
    <Container maxWidth={'xl'} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mt: 4 }}>
        <BreadCrumbsCustom navigate={navigate} links={links} />
      </Box>
      <Box sx={{ mt: 4 }}>
        <CustomTable
          buttonProps={{
            variant: 'contained',
            onClick: handleAdd,
            children: 'Προσθήκη',
          }}
          title="Έκπτωση Κοινωνικών Τιμολογίων"
          rows={socialInvoiceTableData(socialinvoices?.documents)}
          headCells={[
            { disable: false, id: 'socialInvoiceName', label: 'Κοινωνικό Τιμολόγιο' },
            { disable: false, id: 'kwh', label: '€/KWh' },
            { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
            { disable: true, id: 'Ενέργειες', label: 'Ενέργειες' },
          ]}
          handleView={() => {}}
          handleEdit={(obj: TableSocialInvoiceProps) => handleEdit(obj)}
          handleDelete={(obj: TableSocialInvoiceProps) => handleDelete(obj)}
          type="social-invoice"
          count={socialinvoices?.count ?? 0}
          dropDowns={[]}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          page={page}
          rowsPerPage={5}
          setPage={setPage}
        />
      </Box>

      <PopupForm
        maxWidth="md"
        title={type === 'add' ? 'Προσθήκη ΚΟΤ' : 'Επεξεργασία ΚΟΤ'}
        helpText="Εισαγωγή εκπτώσεων ΚΟΤ για κάθε κατηγορία δικαιούχου."
        leftBtnTitle="Ακύρωση"
        rightBtnTitle={type === 'add' ? 'Προσθήκη' : 'Αποθήκευση'}
        open={openDialogAdd}
        setOpen={setOpenDialogAdd}
        onClose={() => setOpenDialogAdd(false)}
        onClickLeftBtn={handleCloseFormDialog}
        onClickRightBtn={handleSave}
        rightBtnProps={{
          disabled: !obj.socialInvoiceName || !obj.socialInvoiceNameEn || !obj.kwh,
        }}
      >
        <DialogAdd obj={obj} setObj={setObj} />
      </PopupForm>

      <PopupConfirm
        title="Διαγραφή Κοινωνικού Τιμολογίου"
        subTile="Είστε σίγουρος ότι θέλετε να προχωρήσετε στη διαγραφή του συγκεκριμένου τιμολογίου ;"
        leftBtnTitle="Οχι"
        rightBtnTitle="Ναι"
        open={openDelete}
        setOpen={setOpenDelete}
        onClose={() => setOpenDelete(false)}
        onClickLeftBtn={() => setOpenDelete(false)}
        onClickRightBtn={handleFinalDelete}
      />
    </Container>
  );
};
