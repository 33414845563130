import api from './api';
import { ApiPagination, PaginationResult } from '../../types/ApiPagination';
import { Identifier } from '../../types/ApiSchema';
import {
  CompetitiveFees,
  CompetitiveFeesFields,
  CompFeesParams,
} from '../../types/CompetitiveFees';

const competitiveFeesApi = api
  .enhanceEndpoints({ addTagTypes: ['CompetitiveFees'] })
  .injectEndpoints({
    endpoints: builder => ({
      getCompetitiveFees: builder.query<
        PaginationResult<CompetitiveFees>,
        CompFeesParams & ApiPagination
      >({
        query: params => ({
          url: `/database/function/getCompetitiveFees`,
          method: 'GET',
          params: { ...params, populate: ['modUser'] },
        }),
        providesTags: result =>
          result?.documents
            ? [
                ...result.documents.map(
                  ({ _id }) => ({ type: 'CompetitiveFees', _id } as const),
                ),
                { type: 'CompetitiveFees', id: 'LIST' },
              ]
            : [{ type: 'CompetitiveFees', id: 'LIST' }],
      }),
      editCompetitiveFees: builder.mutation<
        CompetitiveFees,
        Identifier & CompetitiveFeesFields
      >({
        query: ({ _id, ...params }) => ({
          url: `/database/function/updateCompetitiveFees/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'CompetitiveFees', _id }],
      }),
    }),
  });

export const { useGetCompetitiveFeesQuery, useEditCompetitiveFeesMutation } =
  competitiveFeesApi;

export default competitiveFeesApi;
