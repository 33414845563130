import { Box, Button, Container, Divider, Typography, useTheme } from '@mui/material';
import { BreadCrumbsCustom, PriceEvolutionCard } from '@rae/ui-library';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  competitiveFeeHistoryElectricDetails,
  competitiveFeeHistoryGasDetails,
} from '../types/CompetitiveFees';
import { useGetProductQuery } from '../redux/apis/productApi';
import Loader from '../assets/loading.gif';
import { useGetClauseCoefficientQuery } from '../redux/apis/clauseCoefficientApi';
import { pageToSkipLimit } from '../types/ApiPagination';

export const CompetitiveFeeEntry = () => {
  let { id, type } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [active, setActive] = useState<string>('xp');
  const activeTab = useRef(type === 'gas' ? 2 : 1);

  const { data: productElectric, isLoading: getProductElectricLoading } =
    useGetProductQuery(
      {
        _id: id ?? '',
        type: 'electric',
      },
      { skip: type !== 'electric' || !id },
    );

  const { data: productGas, isLoading: getProductGasLoading } = useGetProductQuery(
    {
      _id: id ?? '',
      type: 'gas',
    },
    { skip: type !== 'gas' || !id },
  );

  const { data: clausesElectric } = useGetClauseCoefficientQuery({
    ...pageToSkipLimit(0, 100, [`-updatedAt`]),
    energyType: 'electric',
  });

  const { data: clausesGas } = useGetClauseCoefficientQuery({
    ...pageToSkipLimit(0, 100, [`-updatedAt`]),
    energyType: 'gas',
  });

  const backAction = useCallback(() => {
    navigate(`/competitive-charges?t=${activeTab.current}`);
  }, [navigate]);

  const links = [
    { name: 'Διαχείριση', href: '/' },
    {
      name: 'Διαχείριση Ανταγωνιστικών Χρεώσεων',
      href: `/competitive-charges?t=${activeTab.current}`,
    },
    { name: 'Εξέλιξη Τιμής' },
  ];

  const dataCompFeeHistoryElectric = useMemo(() => {
    if (!productElectric || !clausesElectric?.documents) return undefined;
    return competitiveFeeHistoryElectricDetails(
      productElectric,
      clausesElectric.documents,
    );
  }, [clausesElectric?.documents, productElectric]);

  const dataCompFeeHistoryGas = useMemo(() => {
    if (!productGas || !clausesGas?.documents) return undefined;
    return competitiveFeeHistoryGasDetails(productGas, clausesGas.documents);
  }, [clausesGas?.documents, productGas]);

  return (
    <Container maxWidth={'xl'} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mt: 4 }}>
        <BreadCrumbsCustom navigate={navigate} links={links} />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Button
          onClick={backAction}
          variant="text"
          sx={{ fontWeight: 600 }}
          startIcon={<KeyboardBackspaceIcon />}
        >
          Πίσω στον πίνακα
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Εξέλιξη Τιμής
        </Typography>
        <Divider
          color={theme.palette.primary.main}
          sx={{ width: '12%', height: '4px', borderRadius: '16px' }}
        />
      </Box>
      {(!productElectric && getProductElectricLoading) ||
      (!productGas && getProductGasLoading) ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img style={{ width: '140px' }} src={Loader} alt="loading..." />
        </Box>
      ) : (
        <>
          <Box sx={{ mt: 1, display: 'flex', flexDirection: 'row', p: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', mr: 2 }}>
              <Typography sx={{ fontWeight: 600 }}>Πρόγραμμα:</Typography>
              <Typography sx={{ ml: 1 }}>
                {activeTab.current === 1
                  ? dataCompFeeHistoryElectric?.[0]?.program
                  : dataCompFeeHistoryGas?.[0]?.program}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography sx={{ fontWeight: 600 }}>Κατηγορία:</Typography>
              <Typography sx={{ ml: 1 }}>
                {activeTab.current === 1
                  ? dataCompFeeHistoryElectric?.[0]?.category
                  : dataCompFeeHistoryGas?.[0]?.category}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ maxHeight: '1000px', overflow: 'auto' }}>
            {activeTab.current === 1 ? (
              <>
                {dataCompFeeHistoryElectric?.map((item, i) => {
                  return (
                    <Box key={i} sx={{ mb: 4 }}>
                      <PriceEvolutionCard
                        type={'electric'}
                        dataEl={item}
                        active={active}
                        setActive={setActive}
                      />
                    </Box>
                  );
                })}
              </>
            ) : (
              <>
                {dataCompFeeHistoryGas?.map((item, i) => {
                  return (
                    <Box key={i} sx={{ mb: 4 }}>
                      <PriceEvolutionCard
                        type={'gas'}
                        dataGas={item}
                        active={active}
                        setActive={setActive}
                      />
                    </Box>
                  );
                })}
              </>
            )}
          </Box>
        </>
      )}
    </Container>
  );
};
