import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material';
import { ButtonRae, Input } from '@rae/ui-library';
import React, { useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { ReactComponent as PdfIcon } from '../../assets/pdf.svg';
import Loader from '../../assets/loading.gif';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { UserManual } from '../../types/UserManual';

export type ProfileData = {
  firstName?: string;
  lastName?: string;
  contactPhone?: string;
};

export type ShowProfileErrorProps = {
  firstName?: boolean;
  lastName?: boolean;
  contactPhone?: boolean;
  email?: boolean;
  newPass?: boolean;
  confirmPass?: boolean;
  currentPass?: boolean;
};

export type ProfileErrorMsg = {
  firstName?: string;
  lastName?: string;
  contactPhone?: string;
  email?: string;
  newPass?: string;
  confirmPass?: string;
  currentPass?: string;
};

export type ChangeEmailData = {
  email?: string;
};

export type PasswordData = {
  currentPass?: string;
  newPass?: string;
  confirmPass?: string;
};

export type ProfileCardProps = {
  profile: ProfileData;
  setProfile: (newSate: any) => void;
  email: ChangeEmailData;
  setEmail: (newState: any) => void;
  showError: ShowProfileErrorProps;
  errorMsg: ProfileErrorMsg;
  isLoading: boolean;
  handleEmailChange: () => void;
  handleChangeDetails: () => void;
};

export type PasswordCardProps = {
  pass: PasswordData;
  setPass: (newSate: any) => void;
  showError: ShowProfileErrorProps;
  errorMsg: ProfileErrorMsg;
  handleResetPass: () => void;
  isLoading: boolean;
};

export const ProfileCard = ({
  profile,
  setProfile,
  email,
  setEmail,
  errorMsg,
  showError,
  isLoading,
  handleEmailChange,
  handleChangeDetails,
}: ProfileCardProps) => {
  return (
    <>
      {!isLoading ? (
        <Box>
          <Typography
            variant="h4"
            fontWeight={700}
            sx={{ mb: 2, textAlign: { xs: 'center', md: 'start' } }}
          >
            Το προφίλ μου
          </Typography>
          <Input
            sx={{ mt: 4, width: '100%' }}
            error={showError.email}
            helperText={showError.email ? errorMsg.email : ''}
            value={email.email}
            onChange={e =>
              setEmail((val: ChangeEmailData) => ({
                ...val,
                email: e.target.value,
              }))
            }
            type="default"
            label="Διεύθυνση Email"
            placeholder=""
          />
          <ButtonRae
            disabled={!email.email}
            onClick={handleEmailChange}
            sx={{ mt: 2, width: 250 }}
            variant="contained"
          >
            Αλλαγή Διεύθυνσης Email
          </ButtonRae>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Input
            sx={{ mt: 4, width: '100%' }}
            error={showError.firstName}
            helperText={showError.firstName ? errorMsg.firstName : ''}
            value={profile.firstName}
            onChange={e =>
              setProfile((val: ProfileData) => ({
                ...val,
                firstName: e.target.value,
              }))
            }
            type="default"
            label="Όνομα"
            placeholder=""
          />
          <Input
            sx={{ mt: 4, width: '100%' }}
            error={showError.lastName}
            helperText={showError.lastName ? errorMsg.lastName : ''}
            value={profile.lastName}
            onChange={e =>
              setProfile((val: ProfileData) => ({
                ...val,
                lastName: e.target.value,
              }))
            }
            type="default"
            label="Επώνυμο"
            placeholder=""
          />
          <Input
            sx={{ mt: 4, width: '100%' }}
            error={showError.contactPhone}
            helperText={showError.contactPhone ? errorMsg.contactPhone : ''}
            value={profile.contactPhone}
            onChange={e =>
              setProfile((val: ProfileData) => ({
                ...val,
                contactPhone: e.target.value,
              }))
            }
            type="default"
            label="Τηλέφωνο"
            placeholder=""
          />
          <ButtonRae
            onClick={handleChangeDetails}
            sx={{ mt: 2, width: 250 }}
            variant="contained"
          >
            Αλλαγή Στοιχειων
          </ButtonRae>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img style={{ width: '140px' }} src={Loader} alt="loading..." />
        </Box>
      )}
    </>
  );
};

export const PasswordCard = ({
  pass,
  setPass,
  errorMsg,
  showError,
  handleResetPass,
  isLoading,
}: PasswordCardProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  return (
    <>
      {!isLoading ? (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant="h4"
              fontWeight={700}
              sx={{ mb: 2, textAlign: { xs: 'center', md: 'start' } }}
            >
              Αλλαγή Kωδικού Πρόσβασης
            </Typography>
            <Input
              sx={{ mt: 2, width: '100%' }}
              error={showError.currentPass}
              helperText={showError.currentPass ? errorMsg.currentPass : ''}
              value={pass.currentPass}
              onChange={e =>
                setPass((val: PasswordData) => ({
                  ...val,
                  currentPass: e.target.value,
                }))
              }
              type="default"
              label="Τρέχον Κωδικός Πρόσβασης"
              placeholder="Εισαγετε Τρέχον Κωδικός"
              inputType={showOldPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={() => setShowOldPassword(!showOldPassword)}
                      onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                        event.preventDefault()
                      }
                    >
                      {showOldPassword ? (
                        <Visibility color="primary" />
                      ) : (
                        <VisibilityOff color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Input
              sx={{ mt: 2, width: '100%' }}
              error={showError.newPass}
              helperText={showError.newPass ? errorMsg.newPass : ''}
              value={pass.newPass}
              onChange={e =>
                setPass((val: PasswordData) => ({
                  ...val,
                  newPass: e.target.value,
                }))
              }
              type="default"
              label="Νέος Κωδικός Πρόσβασης"
              placeholder="Εισαγετε Νέου Κωδικού"
              inputType={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                        event.preventDefault()
                      }
                    >
                      {showPassword ? (
                        <Visibility color="primary" />
                      ) : (
                        <VisibilityOff color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Input
              sx={{ mt: 2, width: '100%' }}
              error={showError.confirmPass}
              helperText={showError.confirmPass ? errorMsg.confirmPass : ''}
              value={pass.confirmPass}
              onChange={e =>
                setPass((val: PasswordData) => ({
                  ...val,
                  confirmPass: e.target.value,
                }))
              }
              type="default"
              label="Επιβεβαίωση Νέου Κωδικού Πρόσβασης"
              placeholder="Επιβεβαίωση Νέου Κωδικού Πρόσβασης"
              inputType={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                        event.preventDefault()
                      }
                    >
                      {showConfirmPassword ? (
                        <Visibility color="primary" />
                      ) : (
                        <VisibilityOff color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <ButtonRae
              disabled={!pass.confirmPass || !pass.currentPass || !pass.newPass}
              onClick={handleResetPass}
              sx={{ mt: 2, width: 200 }}
              variant="contained"
            >
              Αλλαγή Kωδικού
            </ButtonRae>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img style={{ width: '140px' }} src={Loader} alt="loading..." />
        </Box>
      )}
    </>
  );
};

type DocumentsProps = {
  userManuals?: UserManual[];
  handleFileSelect: (event: React.ChangeEvent<HTMLInputElement>, id?: string) => void;
  isLoading: boolean;
  isRae?: boolean;
};

export const DocumentsCards = ({
  userManuals,
  isLoading,
  handleFileSelect,
  isRae,
}: DocumentsProps) => {
  const theme = useTheme();

  return (
    <>
      {!isLoading ? (
        <Box>
          <Typography
            variant="h4"
            fontWeight={700}
            sx={{ mb: 2, textAlign: { xs: 'center', md: 'start' } }}
          >
            Χρήσιμα Έγγραφα
          </Typography>
          <Typography variant="subtitle1">
            Εδώ θα εμφανίζονται χρήσιμα αρχεία όπως για παράδειγμα πως μπορεί να
            δημιουργήσει ο χρήστης ένα νέο προϊόν στο σύστημα.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: { xs: 'center', md: 'flex-start' },
              mt: 2,
              flexWrap: 'wrap',
            }}
          >
            {userManuals?.map((item, i) => {
              return (
                <Box
                  key={i}
                  sx={{ display: 'flex', flexDirection: 'column', p: 2, width: 300 }}
                >
                  <Box
                    sx={{
                      border: `1px solid ${theme.palette.grey[400]}`,
                      p: 3,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      mb: 1,
                      borderRadius: '16px',
                    }}
                  >
                    <IconButton
                      onClick={() => window.open(`${item.file?.url}`, '_blank')}
                      aria-label="download PDF"
                    >
                      <SvgIcon
                        sx={{
                          fontSize: 60,
                          transition: 'all 1s ease-out',
                          '&:hover': {
                            cursor: 'pointer',
                            animation: 'scale .5s forwards',
                            '@keyframes scale': {
                              '0%': {
                                transform: 'scale(1)',
                              },
                              '100%': {
                                transform: 'scale(1.2)',
                              },
                            },
                          },
                        }}
                        component={PdfIcon}
                        inheritViewBox
                      />
                    </IconButton>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, mb: 1, color: theme.palette.grey[500] }}
                    >
                      {item.file?.name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    {isRae ? (
                      <>
                        <input
                          id={`file${item._id}`}
                          type="file"
                          accept=".pdf"
                          style={{ display: 'none' }}
                          onChange={e => handleFileSelect(e, item.file?._id)}
                          ref={input => {
                            if (input) {
                              input.style.display = 'none';
                            }
                          }}
                        />
                        <ButtonRae
                          id="fileInput"
                          color="customPurple"
                          sx={{ width: '80%', height: '30px', mb: 1 }}
                          size="small"
                          variant="contained"
                          endIcon={<CloudUploadIcon />}
                          onClick={() => {
                            const input = document.getElementById(
                              `file${item._id}`,
                            ) as HTMLInputElement;
                            if (input) {
                              input.click();
                            }
                          }}
                        >
                          Αντικατάσταση
                        </ButtonRae>
                      </>
                    ) : null}
                    <a
                      style={{ width: '80%', height: '30px', textDecoration: 'none' }}
                      href={`${item.file?.url}`}
                      target="blank"
                    >
                      <ButtonRae
                        sx={{ width: '100%', height: '100%' }}
                        size="small"
                        variant="contained"
                        endIcon={<FileDownloadOutlinedIcon />}
                      >
                        Κατέβασμα άρχείου
                      </ButtonRae>
                    </a>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img style={{ width: '140px' }} src={Loader} alt="loading..." />
        </Box>
      )}
    </>
  );
};
