import { Box } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BackGround from '../assets/loginBG.png';
import {
  NewPassCard,
  NewPassErrorMsg,
  NewPasswordProps,
  ShowNewPassErrorProps,
} from '../components/ResetPassPage/NewPassCard';

import {
  ResetErrorMsg,
  ResetPassCard,
  ShowResetErrorProps,
} from '../components/ResetPassPage/ResetPassCard';
import {
  useForgotPasswordMutation,
  useResetPasswordMutation,
} from '../redux/apis/authApi';
import { ForgotPasswordRequest, ResetPasswordRequest } from '../types/Auth';
import { newPassValidations } from '../validations/NewPassValidations';
import { ResetPassPageValidations } from '../validations/ResetPassValidations';

export const ResetPass = () => {
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const [forgotPass, { isLoading: forgotPassIsLoading }] = useForgotPasswordMutation();
  const [resetPass, { isLoading: resetPassIsLoading }] = useResetPasswordMutation();

  const isLoading = forgotPassIsLoading || resetPassIsLoading;

  const [resetEmail, setResetEmail] = useState<ForgotPasswordRequest>({ email: '' });
  const [showError, setShowError] = useState<ShowResetErrorProps>({});
  const [errorMsg, setErrorMsg] = useState<ResetErrorMsg>({});
  const [showErrorNewPass, setShowErrorNewPass] = useState<ShowNewPassErrorProps>({});
  const [errorMsgNewPass, setErrorMsgNewPass] = useState<NewPassErrorMsg>({});
  const [newPass, setNewPass] = useState<NewPasswordProps>({});

  const handleReset = useCallback(async () => {
    if (!ResetPassPageValidations({ resetEmail, setErrorMsg, setShowError })) {
      try {
        await forgotPass(resetEmail).unwrap();
        setShowError((val: ShowResetErrorProps) => ({
          ...val,
          submit: false,
        }));
      } catch (e) {
        setShowError((val: ShowResetErrorProps) => ({
          ...val,
          submit: true,
        }));
        console.log(e);
      }
    }
  }, [forgotPass, resetEmail]);

  const handleNewPass = useCallback(async () => {
    if (!newPassValidations({ newPass, setErrorMsgNewPass, setShowErrorNewPass })) {
      const resetPassData: ResetPasswordRequest = {
        password: newPass.password ? newPass.password : '',
        passwordResetToken: String(searchParams.get('reset_token') || ''),
      };
      try {
        await resetPass(resetPassData).unwrap();
        return navigate('/login');
      } catch (e) {
        console.log(e);
      }
    }
  }, [newPass, searchParams, resetPass, navigate]);

  return (
    <Box
      sx={{
        height: '100vh',
        backgroundImage: `url(${BackGround})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        filter: `drop-shadow(-26px 0px 10px rgba(190, 190, 190, 0.06))`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {searchParams.has('reset_token') ? (
        <NewPassCard
          errorMsg={errorMsgNewPass}
          handleNewPass={handleNewPass}
          pass={newPass}
          setPass={setNewPass}
          showError={showErrorNewPass}
          isLoading={isLoading}
        />
      ) : (
        <ResetPassCard
          email={resetEmail}
          errorMsg={errorMsg}
          handleReset={handleReset}
          setEmail={setResetEmail}
          showError={showError}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
};
