import api from './api';
import { ApiPagination, PaginationResult } from '../../types/ApiPagination';
import {
  RegulatedChargesGas,
  RegulatedChargesGasFieldsRequest,
} from '../../types/RegulatedChargesGas';
import { Identifier } from '../../types/ApiSchema';

const regulatedChargesGasApi = api
  .enhanceEndpoints({ addTagTypes: ['RegulatedCharges'] })
  .injectEndpoints({
    endpoints: builder => ({
      getRegulatedCharges: builder.query<
        PaginationResult<RegulatedChargesGas>,
        ApiPagination
      >({
        query: params => ({
          url: `/database/RegulatedChargesGas`,
          method: 'GET',
          params: { ...params, populate: ['network', 'modUser'] },
        }),
        providesTags: result =>
          result?.documents
            ? [
                ...result.documents.map(
                  ({ _id }) => ({ type: 'RegulatedCharges', _id } as const),
                ),
                { type: 'RegulatedCharges', id: 'LIST' },
              ]
            : [{ type: 'RegulatedCharges', id: 'LIST' }],
      }),
      addRegulatedCharges: builder.mutation<
        RegulatedChargesGas,
        Partial<RegulatedChargesGasFieldsRequest>
      >({
        query: params => ({
          url: `/database/function/createRegulatedChargesGas`,
          method: 'POST',
          body: params,
        }),
        invalidatesTags: [{ type: 'RegulatedCharges', id: 'LIST' }],
      }),
      editRegulatedCharges: builder.mutation<
        RegulatedChargesGas,
        Identifier & Partial<RegulatedChargesGasFieldsRequest>
      >({
        query: ({ _id, ...params }) => ({
          url: `/database/function/updateRegulatedChargesGas/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'RegulatedCharges', _id }],
      }),
      deleteRegulatedCharges: builder.mutation<void, Identifier>({
        query: ({ _id }) => ({
          url: `database/RegulatedChargesGas/${_id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'RegulatedCharges', _id }],
      }),
    }),
  });

export const {
  useGetRegulatedChargesQuery,
  useAddRegulatedChargesMutation,
  useEditRegulatedChargesMutation,
  useDeleteRegulatedChargesMutation,
} = regulatedChargesGasApi;

export default regulatedChargesGasApi;
