import { Box } from '@mui/material';
import { CustomTabs, Input, RadioButtonNew } from '@rae/ui-library';
import { DiscBenefTypeProps } from '@rae/ui-library/src/components/table/TableRowDiscBenefType';
import React, { useMemo } from 'react';

type TypeDialogProps = {
  obj: DiscBenefTypeProps;
  setObj: (newState: any) => void;
  type: 'new' | 'edit' | 'viewLinkInv';
};

export const AddTypeDialog = ({ obj, setObj, type }: TypeDialogProps) => {
  const GrTab = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'flex-start', md: 'space-around' },
          mt: 3,
        }}
      >
        <Box>
          <RadioButtonNew
            disableRadio={type === 'edit'}
            label="Κατηγορία:"
            radio={[
              { label: 'Έκπτωση', value: 'discount' },
              { label: 'Πρόσθετη Παροχή', value: 'benefit' },
            ]}
            value={obj.category}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setObj((values: DiscBenefTypeProps) => ({
                ...values,
                category: (event.target as HTMLInputElement).value,
                applicationField: undefined,
              }))
            }
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            mt: 4,
          }}
        >
          <Input
            sx={{ mt: 2, width: '620px' }}
            type="default"
            label="Είδος Έκπτωσης / Παροχής "
            value={obj.typeName}
            onChange={e =>
              setObj((values: DiscBenefTypeProps) => ({
                ...values,
                typeName: e.target.value,
              }))
            }
          />
        </Box>
        {obj.category === 'discount' ? (
          <Box sx={{ mt: 2 }}>
            <RadioButtonNew
              label="Πεδίο Εφαρμογής:"
              radio={[
                { label: 'Χρέωση ενέργειας Ημέρας', value: 'energyChargeDay' },
                { label: 'Χρέωση ενέργειας Νύχτας', value: 'energyChargeNight' },
                { label: 'Συντελεστής Β', value: 'factorB' },
                { label: 'Πάγιο Ημέρας', value: 'fixedChargeDay' },
                { label: 'Πάγιο Νύχτας', value: 'fixedChargeNight' },
              ]}
              value={obj.applicationField}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setObj((values: DiscBenefTypeProps) => ({
                  ...values,
                  applicationField: (event.target as HTMLInputElement).value,
                }))
              }
            />
          </Box>
        ) : null}
      </Box>
    );
  }, [obj.applicationField, obj.category, obj.typeName, setObj, type]);

  const EnTab = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'flex-start', md: 'space-around' },
          mt: 3,
        }}
      >
        <Box>
          <RadioButtonNew
            disableRadio={type === 'edit'}
            label="Κατηγορία:"
            radio={[
              { label: 'Discount', value: 'discount' },
              { label: 'Offer', value: 'benefit' },
            ]}
            value={obj.category}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setObj((values: DiscBenefTypeProps) => ({
                ...values,
                category: (event.target as HTMLInputElement).value,
                applicationField: undefined,
              }))
            }
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            mt: 4,
          }}
        >
          <Input
            sx={{ mt: 2, width: '620px' }}
            type="default"
            label="Discount / Offer Type"
            value={obj.typeNameEn}
            onChange={e =>
              setObj((values: DiscBenefTypeProps) => ({
                ...values,
                typeNameEn: e.target.value,
              }))
            }
          />
        </Box>
        {obj.category === 'discount' ? (
          <Box sx={{ mt: 2 }}>
            <RadioButtonNew
              label="Application Field:"
              radio={[
                { label: 'Energy charge Day', value: 'energyChargeDay' },
                { label: 'Energy charge Night', value: 'energyChargeNight' },
                { label: 'Factor B', value: 'factorB' },
                { label: 'Fixed charge Day', value: 'fixedChargeDay' },
                { label: 'Fixed charge Night', value: 'fixedChargeNight' },
              ]}
              value={obj.applicationField}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setObj((values: DiscBenefTypeProps) => ({
                  ...values,
                  applicationField: (event.target as HTMLInputElement).value,
                }))
              }
            />
          </Box>
        ) : null}
      </Box>
    );
  }, [obj.applicationField, obj.category, obj.typeNameEn, setObj, type]);

  const tabs = useMemo(
    () => [
      { tabName: 'GR', tabContent: GrTab },
      { tabName: 'EN', tabContent: EnTab },
    ],
    [EnTab, GrTab],
  );

  return <CustomTabs tabs={tabs} newTab={0} />;
};
