import { Box } from '@mui/material';
import { DropdownNew } from '@rae/ui-library';
import { OptionsProps } from '@rae/ui-library/src/components/Calculator';
import { Choice } from '@rae/ui-library/src/components/DropdownNew';
import { TableCompFeesProps } from '@rae/ui-library/src/components/table/TableRowCompFees';
import React, { useCallback, useMemo, useState } from 'react';
import { ProductGasTypes } from '../ProductGasTypes';
import { FloatingChargeNoClause } from './Sections/FloatingChargeNoClause';
import { FloatingChargeNoLimit } from './Sections/FloatingChargeNoLimit';
import { StableSection } from './Sections/StableSection';
import {
  disableFields,
  ReviewModeEnum,
  showReviewComments,
  viewOnlyReviewComments,
} from '../../../types/ReviewMode';
import { ReviewTool } from '../../ProductElectricPage/actions/ReviewTool';
import { CompetitiveFeeIdsGasEnum } from '../../../types/CompetitiveFees';

type Obj = {
  obj: ProductGasTypes;
  setObj: (newState: any) => void;
  calculatorData: OptionsProps[][];
  setCalculatorData: (newState: any) => void;
  compFees: TableCompFeesProps[];
  compFeesOptions: OptionsProps[];
  reviewMode: ReviewModeEnum;
  addComment: (id: string, comment: string) => void;
};

export const CompetitiveFeesTab = ({
  obj,
  setObj,
  calculatorData,
  setCalculatorData,
  compFees,
  compFeesOptions,
  reviewMode,
  addComment,
}: Obj) => {
  const [openType, setopenType] = useState<boolean>(false);

  const compFeesChoices = useMemo((): Choice[] => {
    return (
      compFees?.map(comp => ({
        value: comp._id ? comp._id : '',
        label: comp.name ? comp.name : '',
      })) ?? []
    );
  }, [compFees]);

  const renderSection = useMemo(() => {
    switch (obj.competitiveFeeType) {
      case CompetitiveFeeIdsGasEnum.Stable:
        return (
          <StableSection
            obj={obj}
            setObj={setObj}
            count={'Ημερήσια'}
            reviewMode={reviewMode}
            addComment={addComment}
          />
        );
      case CompetitiveFeeIdsGasEnum.NoLimit:
        return (
          <FloatingChargeNoLimit
            noLimitData={calculatorData}
            setNoLimitData={setCalculatorData}
            obj={obj}
            setObj={setObj}
            compFeesOptions={compFeesOptions}
            count={'Ημερήσια'}
            addComment={addComment}
            reviewMode={reviewMode}
          />
        );
      case CompetitiveFeeIdsGasEnum.NoClause:
        return (
          <FloatingChargeNoClause
            obj={obj}
            setObj={setObj}
            compFeesOptions={compFeesOptions}
            noClauseData={calculatorData}
            setNoClauseData={setCalculatorData}
            addComment={addComment}
            reviewMode={reviewMode}
          />
        );
      default:
        return <></>;
    }
  }, [
    obj,
    setObj,
    calculatorData,
    setCalculatorData,
    compFeesOptions,
    addComment,
    reviewMode,
  ]);

  const handleSetSelected = useCallback(
    (type: any) => {
      setObj((values: ProductGasTypes) => ({
        ...values,
        competitiveFeeType: type[0],
        competitiveFee: [{ type: type[0] }],
        otherCharges: [{}],
        discounts: [{}],
        benefits: [{}],
        clauses: [{}],
      }));
      setCalculatorData([[]]);
    },
    [setCalculatorData, setObj],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        mt: 3,
      }}
    >
      <Box>
        <DropdownNew
          sx={{ width: { xs: '300px', md: '450px' }, mt: 2 }}
          multiline={false}
          disabled={disableFields(reviewMode)}
          label="Τύπος Ανταγωνιστικής Χρέωσης"
          labelBtn="Εφαρμογή"
          choices={compFeesChoices}
          open={openType}
          setOpen={setopenType}
          selected={obj?.competitiveFeeType ? [obj?.competitiveFeeType] : []}
          setSelected={handleSetSelected}
        />
        {showReviewComments(reviewMode) ? (
          <ReviewTool
            view={viewOnlyReviewComments(reviewMode)}
            msg={obj.reviewComments?.competitiveFees?.competitiveFees}
            id="competitiveFees.competitiveFees"
            title="Ανταγωνιστική Χρέωση"
            addComment={addComment}
          />
        ) : null}
      </Box>
      <Box sx={{ mt: 4, p: 1 }}>{renderSection}</Box>
    </Box>
  );
};
