import { TableSocialInvoiceProps } from '@rae/ui-library/src/components/table/TableRowSocialInvoice';
import { ApiSchema } from './ApiSchema';

export type SocialInvoiceFields = {
  socialInvoiceName: string;
  socialInvoiceNameEn: string;
  kwh: number;
  regulatedCharges?: string[];
};

export type SocialInvoiceDiscount = ApiSchema<SocialInvoiceFields>;

export const socialInvoiceTableData = (
  socialInvoice?: SocialInvoiceDiscount[],
): TableSocialInvoiceProps[] => {
  return (
    socialInvoice?.map(invoice => ({
      _id: invoice._id,
      socialInvoiceName: invoice.socialInvoiceName,
      socialInvoiceNameEn: invoice.socialInvoiceNameEn,
      kwh: invoice.kwh,
      regulatedCharges: invoice.regulatedCharges,
      modDate: new Date(invoice.updatedAt).toLocaleDateString(),
      modEmail: undefined,
    })) ?? []
  );
};
