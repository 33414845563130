import React from 'react';
import './App.css';
import { Settings } from './pages/Settings';
import { ProvidersPage } from './pages/Providers';
import { Users } from './pages/Users';
import { ClausesPage } from './pages/Clauses';
import { RegularChargesEl } from './pages/RegularChargesEl';
import { ProviderId } from './pages/ProviderId';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { MenuLayout } from './components/MenuLayout';
import { AvrgCosnymptionPage } from './pages/AvrgConsumption';
import { SocialInvoice } from './pages/SocialInvoice';
import { DiscountAndBnfits } from './pages/DiscountsAndBenefits';
import { LinkedInvoices } from './pages/LinkedInvoices';
import { ProductElectric } from './pages/ProductElectric';
import { CompFeeMngment } from './pages/CompetitiveFeeMngment';
import { Documents } from './pages/Documents';
import { Assetsmngment } from './pages/AssetsMngment';
import { SpecialDiscounts } from './pages/SpecialDiscounts';
import { RegularCHargesGas } from './pages/RegularChargesGas';
import { CompetitiveFeeEntry } from './pages/CompetitiveFeeEntry';
import { NotificationsPage } from './pages/Notifications';
import { ProductGas } from './pages/ProductGas';
import { LoginPage } from './pages/LoginPage';
import { ResetPass } from './pages/ResetPass';
import { LandingPage } from './pages/LandingPage';
import { Alert, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './redux/store';
import { closeShowSnackBar } from './redux/slices/appSlice';
import ProtectedRoutes from './components/ProtectedRoutes';
import { RegisterPage } from './pages/RegisterPage';
import { NotMemberPage } from './pages/NotMember';

function App() {
  const dispatch = useDispatch<AppDispatch>();

  const { showSnackBar, log } = useSelector((state: RootState) => state.app);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/reset/password" element={<ResetPass />} />
          <Route path="/invite" element={<RegisterPage />} />
          <Route path="/not_member" element={<NotMemberPage />} />

          <Route element={<ProtectedRoutes />}>
            <Route element={<MenuLayout />}>
              <Route path="/" element={<LandingPage />} />
              <Route path="/settings/*" element={<Settings />} />
              <Route path="/providers" element={<ProvidersPage />} />
              <Route path="/users" element={<Users />} />
              <Route path="/clauses" element={<ClausesPage />} />
              <Route path="/regular-electric-charges" element={<RegularChargesEl />} />
              <Route path="/providerid" element={<ProviderId />} />
              <Route path="/social-invoice" element={<SocialInvoice />} />
              <Route path="/discounts-benefits" element={<DiscountAndBnfits />} />
              <Route path="/avrg-consumption" element={<AvrgCosnymptionPage />} />
              <Route path="/competitive-charges" element={<CompFeeMngment />} />
              <Route
                path="/competitive-charges/:type/:id"
                element={<CompetitiveFeeEntry />}
              />
              <Route path="/documents" element={<Documents />} />
              <Route path="/charges" element={<Assetsmngment />} />
              <Route path="/special-discounts" element={<SpecialDiscounts />} />
              <Route path="/regular-gas-charges" element={<RegularCHargesGas />} />
              <Route path="/notifications" element={<NotificationsPage />} />
              <Route path="/product-electric" element={<ProductElectric />} />
              <Route path="/product-electric/:id" element={<ProductElectric />} />
              <Route path="/product-gas" element={<ProductGas />} />
              <Route path="/product-gas/:id" element={<ProductGas />} />
              <Route path="/product-linked" element={<LinkedInvoices />} />
              <Route path="/product-linked/:id" element={<LinkedInvoices />} />
            </Route>
          </Route>
        </Routes>
      </Router>

      <Snackbar
        open={showSnackBar}
        autoHideDuration={6000}
        onClose={() => dispatch(closeShowSnackBar())}
      >
        <Alert
          onClose={() => dispatch(closeShowSnackBar())}
          severity={log?.severity}
          sx={{ width: '100%' }}
          elevation={6}
        >
          {log?.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default App;
