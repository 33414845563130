import { Box, Container, Typography, useTheme } from '@mui/material';
import { BreadCrumbsCustom, NotificationCard } from '@rae/ui-library';
import { Cardprops } from '@rae/ui-library/src/components/NotificationCard';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useGetNotificationsQuery,
  useReadNotificationMutation,
} from '../redux/apis/notificationApi';
import { pageToSkipLimit } from '../types/ApiPagination';
import { notificationData } from '../types/Notification';
import Loader from '../assets/loading.gif';

export const NotificationsPage = () => {
  const theme = useTheme();
  let navigate = useNavigate();

  const links = [{ name: 'Ειδοποιήσεις', href: '/notifications' }];

  const { data: notifications, isLoading: getNotificationsloading } =
    useGetNotificationsQuery({
      ...pageToSkipLimit(0, 100, [`-createdAt`]),
    });

  const [readNotification, { isLoading: readnotificationsloading }] =
    useReadNotificationMutation();

  const isLoading = getNotificationsloading || readnotificationsloading;

  const redirect = useCallback(
    async (obj: Cardprops) => {
      if (!obj._id) {
        return;
      }
      if (obj.indicator) {
        navigate(obj.url ? obj.url : '#');
        return;
      }
      try {
        await readNotification({ _id: obj._id }).unwrap();
        navigate(obj.url ? obj.url : '#');
      } catch (e) {
        console.log(e);
      }
    },
    [navigate, readNotification],
  );

  return (
    <Container maxWidth={'xl'} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mt: 4 }}>
        <BreadCrumbsCustom navigate={navigate} links={links} />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
        <Box sx={{ mt: 4, p: 1, width: '70%' }}>
          <Typography
            variant="h5"
            sx={{ fontWeight: 600, mb: 4 }}
            color={theme.palette.primary.main}
          >
            Ειδοποιήσεις
          </Typography>
          <Box sx={{ p: 2, border: '1px solid #E3E3E3', borderRadius: '16px' }}>
            {notifications?.notifications ? (
              notificationData(notifications?.notifications).map((not, i) => (
                <NotificationCard
                  handleRedirect={() => redirect(not)}
                  key={i}
                  body={not.body}
                  indicator={not.indicator}
                  date={not.date}
                  url={not.url}
                  title={not.title}
                />
              ))
            ) : isLoading ? (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img style={{ width: '140px' }} src={Loader} alt="loading..." />
              </Box>
            ) : (
              <Typography>Δεν υπάρχουν διαθέσιμες ειδοποιήσεις</Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
