import React, { useState } from 'react';
import { TableRegulatedChargesGasProps } from '@rae/ui-library/src/components/table/TableRowRegulatedChargesGas';
import { CustomTable } from '@rae/ui-library';
import { HeadCell, OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import { ActionsProps, GasChargesSearchProps } from '../../../pages/RegularChargesGas';

type RegulatedChargesProps = {
  search: GasChargesSearchProps;
  setSearch: (newState: any) => void;
  data: TableRegulatedChargesGasProps[];
  handleActionsRegulatedCharges: (
    obj: TableRegulatedChargesGasProps,
    action: ActionsProps['tab1']['type'],
  ) => void;
  order: OrderProps;
  setOrder: (newState: any) => void;
  orderBy: HeadCell['id'];
  setOrderBy: (newState: any) => void;
  page: number;
  setPage: (newState: any) => void;
};

export const RegulatedChargesGasTable = ({
  search,
  setSearch,
  handleActionsRegulatedCharges,
  data,
  order,
  orderBy,
  page,
  setOrder,
  setOrderBy,
  setPage,
}: RegulatedChargesProps) => {
  const [openProvider, setOpenProvider] = useState<boolean>(false);
  const [openZone, setOpenZone] = useState<boolean>(false);
  const [openCtegory, setOpenCategory] = useState<boolean>(false);

  const dropDowns = [
    {
      choices: Array.from(
        new Set(
          data.map(i =>
            i.network?.gasProvider?.providerName
              ? i.network?.gasProvider?.providerName
              : '',
          ),
        ),
      ),
      open: openProvider,
      selected: search.regulatedCharges.dfa,
      setOpen: setOpenProvider,
      setSelected: (type: any) =>
        setSearch((values: GasChargesSearchProps) => ({
          ...values,
          regulatedCharges: {
            ...values.regulatedCharges,
            dfa: type,
          },
        })),
      label: 'ΔΦΑ',
      multiple: true,
      labelBtn: 'Εφαρμογή',
    },
    {
      choices: Array.from(
        new Set(data.map(i => (i.network?.zone ? i.network?.zone : ''))),
      ),
      open: openZone,
      selected: search.regulatedCharges.zone,
      setOpen: setOpenZone,
      setSelected: (type: any) =>
        setSearch((values: GasChargesSearchProps) => ({
          ...values,
          regulatedCharges: {
            ...values.regulatedCharges,
            zone: type,
          },
        })),
      label: 'Ζώνη',
      multiple: true,
      labelBtn: 'Εφαρμογή',
    },
    {
      choices: Array.from(new Set(data.map(i => (i.category ? i.category : '')))),
      open: openCtegory,
      selected: search.regulatedCharges.category,
      setOpen: setOpenCategory,
      setSelected: (type: any) =>
        setSearch((values: GasChargesSearchProps) => ({
          ...values,
          regulatedCharges: {
            ...values.regulatedCharges,
            category: type,
          },
        })),
      label: 'Κατηγορία Παροχής',
      multiple: false,
      labelBtn: 'Εφαρμογή',
    },
  ];

  return (
    <CustomTable
      title=""
      buttonProps={{
        variant: 'contained',
        onClick: () => handleActionsRegulatedCharges({}, 'new'),
        children: 'Προσθήκη',
      }}
      handleEdit={(obj: TableRegulatedChargesGasProps) =>
        handleActionsRegulatedCharges(obj, 'edit')
      }
      handleDelete={(obj: TableRegulatedChargesGasProps) =>
        handleActionsRegulatedCharges(obj, 'delete')
      }
      handleView={(obj: TableRegulatedChargesGasProps) =>
        handleActionsRegulatedCharges(obj, 'view')
      }
      searchInput={{
        onChange: e =>
          setSearch((values: GasChargesSearchProps) => ({
            ...values,
            regulatedCharges: {
              ...values.regulatedCharges,
              searchValue: e.target.value,
            },
          })),
        value: search.regulatedCharges.searchValue,
        type: 'search',
        label: 'Αναζήτηση ',
      }}
      rows={data}
      type={'regulated-charges-gas'}
      count={data.length}
      headCells={[
        { disable: false, id: 'category', label: 'Κατηγορία Παροχής' },
        { disable: true, id: 'city', label: 'Περιοχή / Πόλη' },
        {
          disable: true,
          id: 'zone',
          label: 'Ζώνη',
        },
        { disable: true, id: 'view', label: 'Χ.Χ. Δικτύου Διανομής' },
        { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
        { disable: true, id: 'actions', label: 'Ενέργειες' },
      ]}
      dropDowns={dropDowns}
      order={order}
      orderBy={orderBy}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
      page={page}
      rowsPerPage={5}
      setPage={setPage}
    />
  );
};
