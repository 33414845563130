import api from './api';
import { ApiPagination, PaginationResult } from '../../types/ApiPagination';
import {
  ManagmentGasNetworks,
  ManagmentGasNetworksFieldsRequest,
  ManagmentGasNetworksParams,
} from '../../types/ManagmentGasNetwork';
import { Identifier } from '../../types/ApiSchema';

const managmentGasNetworkApi = api
  .enhanceEndpoints({ addTagTypes: ['Networks'] })
  .injectEndpoints({
    endpoints: builder => ({
      getManagmentGasNetworks: builder.query<
        PaginationResult<ManagmentGasNetworks>,
        ManagmentGasNetworksParams & ApiPagination
      >({
        query: params => ({
          url: `/database/function/getManagmentGasNetworks`,
          method: 'GET',
          params: { ...params, populate: ['gasProvider', 'modUser'] },
        }),
        providesTags: result =>
          result?.documents
            ? [
                ...result.documents.map(
                  ({ _id }) => ({ type: 'Networks', _id } as const),
                ),
                { type: 'Networks', id: 'LIST' },
              ]
            : [{ type: 'Networks', id: 'LIST' }],
      }),
      addManagmentGasNetwork: builder.mutation<
        ManagmentGasNetworks,
        Partial<ManagmentGasNetworksFieldsRequest>
      >({
        query: params => ({
          url: `/database/function/createManagmentGasNetworks`,
          method: 'POST',
          body: params,
        }),
        invalidatesTags: [{ type: 'Networks', id: 'LIST' }],
      }),
      editManagmentGasNetwork: builder.mutation<
        ManagmentGasNetworks,
        Identifier & Partial<ManagmentGasNetworksFieldsRequest>
      >({
        query: ({ _id, ...params }) => ({
          url: `/database/function/updateManagmentGasNetworks/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'Networks', _id }],
      }),
      deleteManagmentGasNetwork: builder.mutation<void, Identifier>({
        query: ({ _id }) => ({
          url: `raeService/gas-network/${_id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'Networks', _id }],
      }),
    }),
  });

export const {
  useGetManagmentGasNetworksQuery,
  useAddManagmentGasNetworkMutation,
  useEditManagmentGasNetworkMutation,
  useDeleteManagmentGasNetworkMutation,
} = managmentGasNetworkApi;

export default managmentGasNetworkApi;
