import { Box, Link, Typography } from '@mui/material';
import { CustomTable, Tooltip } from '@rae/ui-library';
import { OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import { TableAvrgConsProps } from '@rae/ui-library/src/components/table/TableRowAvrgCons';
import React, { useMemo } from 'react';

export type AvrgConsRowProps = {
  setOpenEdit: (obj: TableAvrgConsProps) => void;
  data: TableAvrgConsProps[];
  setOrder: (oreder: OrderProps) => void;
  order: OrderProps;
  setOrderBy: (orederBy: string) => void;
  orderBy: string;
  setPage: (page: number) => void;
  page: number;
};

export const ElTableContent = ({
  setOpenEdit,
  data,
  order,
  orderBy,
  page,
  setOrder,
  setOrderBy,
  setPage,
}: AvrgConsRowProps) => {
  const toolTipText = useMemo(() => {
    return (
      <Typography variant="subtitle2" sx={{ color: '#fff', ml: 2 }}>
        Παρακαλώ πολύ{' '}
        <Link
          href="/providerid"
          color="inherit"
          sx={{ cursor: 'pointer', fontWeight: '700' }}
        >
          πατηστε εδώ
        </Link>{' '}
        για να συμπληρώσετε τα στοιχεία στην διαχείριση Ταυτότητας Παροχής
      </Typography>
    );
  }, []);

  return (
    <Box>
      {data.length === 0 ? <Tooltip type="link" text={toolTipText} /> : null}
      <CustomTable
        handleEdit={obj => setOpenEdit(obj)}
        type="average-cons"
        headCells={[
          { disable: true, id: 'Μέτρηση', label: 'Μέτρηση' },
          { disable: true, id: 'Κατηγορία παροχής', label: 'Κατηγορία παροχής' },
          {
            disable: true,
            id: 'Μηνιαία Κατανάλωση (kWh)',
            label: 'Μηνιαία Κατανάλωση (kWh)',
            colSpan: 2,
          },
          { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
          { disable: true, id: 'Ενέργειες', label: 'Ενέργειες' },
        ]}
        count={data.length}
        rows={data}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        page={page}
        rowsPerPage={5}
        setPage={setPage}
      />
    </Box>
  );
};
