import { RegisterobjProps } from '../pages/RegisterPage';
import { AuthRegisterUser } from './Auth';

export const registerUserData = (user?: RegisterobjProps): AuthRegisterUser => {
  return {
    email: user?.email ? user?.email : '',
    invitationToken: user?.invitationToken ? user?.invitationToken : '',
    password: user?.password ? user?.password : '',
  };
};
