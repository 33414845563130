import { Box, Container } from '@mui/material';
import {
  BreadCrumbsCustom,
  CustomChip,
  CustomChipNew,
  CustomTabs,
  PopupForm,
  Tooltip,
} from '@rae/ui-library';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CompetitiveFeesTab } from '../components/CompetitiveFeeMngmentTabs/CompetitiveFeesTab';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TableCompFeesProps } from '@rae/ui-library/src/components/table/TableRowCompFees';
import { CompFeesDialog } from '../components/CompetitiveFeeMngmentTabs/CompFeesDialog';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { HeadCell, OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import {
  useEditCompetitiveFeesMutation,
  useGetCompetitiveFeesQuery,
} from '../redux/apis/CompetitiveFeesApi';
import { pageToSkipLimit } from '../types/ApiPagination';
import Loader from '../assets/loading.gif';
import {
  editCompetitiveFeesFields,
  gasCompetitiveFeesData,
} from '../types/CompetitiveFees';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { setLog } from '../redux/slices/appSlice';
import useDebounce from '../utils/useDebounce';
import { energyTypeReverseLabel } from '../types/EnergyType';
import { useGetCompetitiveFeesHistoricityQuery } from '../redux/apis/productApi';
import { useGetTeamsQuery } from '../redux/apis/teamApi';
import {
  categoryOfSupplyGasLabel,
  categoryOfSupplyLabel,
} from '../types/CategoryOfSupply';

export type SearchProps = {
  compFees: {
    search: string;
    type: string[];
  };
  compFeesHist: {
    search: string;
    type: string[];
    category: string[];
    provider: string[];
  };
  compFeesHistGas: {
    search: string;
    type: string[];
    category: string[];
    provider: string[];
  };
};

export const CompFeeMngment = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch<AppDispatch>();

  const [orderFees, setOrderFees] = useState<OrderProps>('asc');
  const [orderByFees, setOrderByFees] = useState<HeadCell['id']>('updatedAt');
  const [pageFees, setPageFees] = useState<number>(0);

  const [pageFeesHist, setPageFeesHist] = useState<number>(0);
  const [pageFeesHistGas, setPageFeesHistGas] = useState<number>(0);

  const [tabValue, setValueTab] = useState<number>(0);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [type, setType] = useState<'edit' | 'new'>('new');
  const [compFeeObj, setCompFeeObj] = useState<TableCompFeesProps>({
    info: '',
    infoEn: '',
    name: '',
    nameEn: '',
    modDate: '',
    modEmail: '',
  });
  const [search, setSearch] = useState<SearchProps>({
    compFees: { search: '', type: [] },
    compFeesHist: { search: '', category: [], provider: [], type: [] },
    compFeesHistGas: { search: '', category: [], provider: [], type: [] },
  });

  const debouncedSearch = useDebounce<string>(search.compFees.search, 300);

  const { data: competitiveFees, isLoading: competitiveFeesIsLoading } =
    useGetCompetitiveFeesQuery({
      ...pageToSkipLimit(pageFees, 5, [
        `${orderFees === 'asc' ? '-' : ''}${orderByFees}`,
      ]),
      search: debouncedSearch !== '' ? debouncedSearch : undefined,
      energyType: energyTypeReverseLabel(search.compFees.type?.[0]),
    });
  const { data: competitiveFeesAll } = useGetCompetitiveFeesQuery({
    ...pageToSkipLimit(0, 1000),
  });
  const { data: providers } = useGetTeamsQuery({
    ...pageToSkipLimit(0, 1000),
  });
  const { data: competitiveFeesHist, isLoading: competitiveFeesHistIsLoading } =
    useGetCompetitiveFeesHistoricityQuery({
      ...pageToSkipLimit(pageFeesHist, 5),
      type: 'electric',
      competitiveFees: search.compFeesHist.type?.[0],
      categoryOfSupply: search.compFeesHist.category?.[0],
      team: search.compFeesHist.provider?.[0],
    });

  const { data: competitiveFeesHistGas, isLoading: competitiveFeesHistGasIsLoading } =
    useGetCompetitiveFeesHistoricityQuery({
      ...pageToSkipLimit(pageFeesHistGas, 5),
      type: 'gas',
      competitiveFees: search.compFeesHistGas.type?.[0],
      categoryOfSupply: search.compFeesHistGas.category?.[0],
      team: search.compFeesHistGas.provider?.[0],
    });

  const [editCompetitiveFees, { isLoading: editCompetitiveFeesLoading }] =
    useEditCompetitiveFeesMutation();

  const links = [
    { name: 'Διαχείριση', href: '/' },
    { name: 'Διαχείριση Ανταγωνιστικών Χρεώσεων' },
  ];

  useEffect(() => {
    setValueTab(Number(searchParams.get('t') ?? 0));
  }, [searchParams]);

  const viewEntry = useCallback(
    (id: string, type: string) => {
      navigate(`/competitive-charges/${type}/${id}`);
    },
    [navigate],
  );

  const editRow = useCallback((obj: TableCompFeesProps) => {
    setOpenDialog(true);
    setType('edit');
    setCompFeeObj(obj);
  }, []);

  const saveEditRow = useCallback(async () => {
    switch (type) {
      case 'new':
        alert('save new');
        console.log(compFeeObj);
        break;
      case 'edit':
        if (!compFeeObj?._id || !compFeeObj.info || !compFeeObj.infoEn) return;
        const editFields = editCompetitiveFeesFields(compFeeObj);
        try {
          await editCompetitiveFees(editFields).unwrap();
          dispatch(
            setLog({
              severity: 'success',
              message: `Η Ανταγωνιστική Χρέωση αποθηκεύτηκε επιτυχώς`,
            }),
          );
        } catch (error: any) {
          console.log(error);
          dispatch(
            setLog({
              severity: 'error',
              message: error.data.message,
            }),
          );
        }
        break;
      default:
        break;
    }
    setOpenDialog(false);
    setCompFeeObj({ info: '', infoEn: '' });
  }, [compFeeObj, dispatch, editCompetitiveFees, type]);

  const cancelEditRow = useCallback(() => {
    setOpenDialog(false);
    setCompFeeObj({
      info: '',
      infoEn: '',
      name: '',
      nameEn: '',
      modDate: '',
      modEmail: '',
    });
  }, []);

  const tabs = [
    {
      tabName: 'Ανταγωνιστικές Χρεώσεις',
      tabContent: (
        <CompetitiveFeesTab
          edit={editRow}
          search={search}
          setSearch={setSearch}
          data={gasCompetitiveFeesData(competitiveFees?.documents)}
          order={orderFees}
          orderBy={orderByFees}
          page={pageFees}
          setOrder={setOrderFees}
          setOrderBy={setOrderByFees}
          setPage={setPageFees}
          count={competitiveFees?.count ?? 0}
        />
      ),
    },
    // {
    //   tabName: 'Ιστορικότητα Ανταγωνιστικών Χρεώσεων ΗΕ',
    //   tabContent: (
    //     <CompFeesHistoryTab
    //       search={search}
    //       setSearch={setSearch}
    //       viewEntry={viewEntry}
    //       page={pageFeesHist}
    //       setPage={setPageFeesHist}
    //       count={competitiveFeesHist?.count ?? 0}
    //       data={competitiveFeeHistoryElectric(competitiveFeesHist?.products)}
    //       teams={providers?.documents}
    //       competitiveFees={competitiveFeesAll?.documents}
    //     />
    //   ),
    // },
    // {
    //   tabName: 'Ιστορικότητα Ανταγωνιστικών Χρεώσεων ΦΑ',
    //   tabContent: (
    //     <CompFeesHistoryGasTab
    //       viewEntry={viewEntry}
    //       search={search}
    //       setSearch={setSearch}
    //       page={pageFeesHistGas}
    //       setPage={setPageFeesHistGas}
    //       count={competitiveFeesHistGas?.count ?? 0}
    //       data={competitiveFeeHistoryGas(competitiveFeesHistGas?.products)}
    //       teams={providers?.documents}
    //       competitiveFees={competitiveFeesAll?.documents}
    //     />
    //   ),
    // },
  ];

  const filterCompFees = useMemo(
    () =>
      search.compFees.type.concat(
        search.compFees.search !== '' ? search.compFees.search : [],
      ),
    [search.compFees.search, search.compFees.type],
  );

  const filterCompFeesHist = useMemo(
    () =>
      search.compFeesHist.category
        .map(c => ({
          label: categoryOfSupplyLabel(c).gr,
          value: c,
        }))
        .concat(
          search.compFeesHist.provider.map(p => ({
            label: providers?.documents?.find(pr => pr._id === p)?.nameGr ?? '',
            value: p,
          })),
          search.compFeesHist.type.map(c => ({
            label: competitiveFeesAll?.documents?.find(cf => cf._id === c)?.name ?? '',
            value: c,
          })),
        ),
    [
      competitiveFeesAll?.documents,
      providers?.documents,
      search.compFeesHist.category,
      search.compFeesHist.provider,
      search.compFeesHist.type,
    ],
  );

  const filterCompFeesHistGas = useMemo(
    () =>
      search.compFeesHistGas.category
        .map(c => ({
          label: categoryOfSupplyGasLabel(c).gr,
          value: c,
        }))
        .concat(
          search.compFeesHistGas.provider.map(p => ({
            label: providers?.documents?.find(pr => pr._id === p)?.nameGr ?? '',
            value: p,
          })),
          search.compFeesHistGas.type.map(c => ({
            label: competitiveFeesAll?.documents?.find(cf => cf._id === c)?.name ?? '',
            value: c,
          })),
        ),
    [
      competitiveFeesAll?.documents,
      providers?.documents,
      search.compFeesHistGas.category,
      search.compFeesHistGas.provider,
      search.compFeesHistGas.type,
    ],
  );

  const clearFiltersCompFees = useCallback(() => {
    setSearch((values: SearchProps) => ({
      ...values,
      compFees: { search: '', type: [] },
    }));
  }, []);

  const clearFiltersCompFeesHist = useCallback(() => {
    setSearch((values: SearchProps) => ({
      ...values,
      compFeesHist: { search: '', category: [], provider: [], type: [] },
    }));
  }, []);

  const clearFiltersCompFeesHistGas = useCallback(() => {
    setSearch((values: SearchProps) => ({
      ...values,
      compFeesHistGas: { search: '', category: [], provider: [], type: [] },
    }));
  }, []);

  const removeChipFees = useCallback((chipLabel: string) => {
    setSearch((values: SearchProps) => ({
      ...values,
      compFees: {
        search: values.compFees.search === chipLabel ? '' : values.compFees.search,
        type: values.compFees.type.filter(label => label !== chipLabel),
      },
    }));
  }, []);

  const removeChipGas = useCallback((chipLabel: string) => {
    setSearch((values: SearchProps) => ({
      ...values,
      compFeesHistGas: {
        search:
          values.compFeesHistGas.search === chipLabel
            ? ''
            : values.compFeesHistGas.search,
        category: values.compFeesHistGas.category.filter(label => label !== chipLabel),
        provider: values.compFeesHistGas.provider.filter(label => label !== chipLabel),
        type: values.compFeesHistGas.type.filter(label => label !== chipLabel),
      },
    }));
  }, []);

  const removeChipEl = useCallback((chipLabel: string) => {
    setSearch((values: SearchProps) => ({
      ...values,
      compFeesHist: {
        search:
          values.compFeesHist.search === chipLabel ? '' : values.compFeesHist.search,
        category: values.compFeesHist.category.filter(label => label !== chipLabel),
        provider: values.compFeesHist.provider.filter(label => label !== chipLabel),
        type: values.compFeesHist.type.filter(label => label !== chipLabel),
      },
    }));
  }, []);

  const renderTooltip = useMemo(() => {
    switch (tabValue) {
      case 0:
        return filterCompFees.length > 0 ? (
          <Tooltip
            text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
            type="info"
            btnAction={clearFiltersCompFees}
            btnLabel="Καθαρισμός Φίλτρων"
            dismiss={true}
            icon={<InfoOutlinedIcon fontSize="small" />}
          />
        ) : null;
      case 1:
        return filterCompFeesHist.length > 0 ? (
          <Tooltip
            text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
            type="info"
            btnAction={clearFiltersCompFeesHist}
            btnLabel="Καθαρισμός Φίλτρων"
            dismiss={true}
            icon={<InfoOutlinedIcon fontSize="small" />}
          />
        ) : null;
      case 2:
        return filterCompFeesHistGas.length > 0 ? (
          <Tooltip
            text="Βλέπεις αποτελέσματα με βάση τα φίλτρα της αναζήτησής σου"
            type="info"
            btnAction={clearFiltersCompFeesHistGas}
            btnLabel="Καθαρισμός Φίλτρων"
            dismiss={true}
            icon={<InfoOutlinedIcon fontSize="small" />}
          />
        ) : null;
      default:
        break;
    }
  }, [
    clearFiltersCompFees,
    clearFiltersCompFeesHist,
    clearFiltersCompFeesHistGas,
    filterCompFees,
    filterCompFeesHist,
    filterCompFeesHistGas,
    tabValue,
  ]);

  const renderChips = useMemo(() => {
    switch (tabValue) {
      case 0:
        return filterCompFees
          ? filterCompFees.map((item, i) => {
              return (
                <CustomChip key={i} label={item} onDelete={() => removeChipFees(item)} />
              );
            })
          : null;
      case 1:
        return filterCompFeesHist
          ? filterCompFeesHist.map((item, i) => {
              return (
                <CustomChipNew
                  key={i}
                  label={item.label}
                  value={item.value}
                  onDelete={() => removeChipEl(item.value)}
                />
              );
            })
          : null;
      case 2:
        return filterCompFeesHistGas
          ? filterCompFeesHistGas.map((item, i) => {
              return (
                <CustomChipNew
                  key={i}
                  label={item.label}
                  value={item.value}
                  onDelete={() => removeChipGas(item.value)}
                />
              );
            })
          : null;
      default:
        break;
    }
  }, [
    filterCompFees,
    filterCompFeesHist,
    filterCompFeesHistGas,
    removeChipEl,
    removeChipFees,
    removeChipGas,
    tabValue,
  ]);

  const isLoading = competitiveFeesIsLoading || editCompetitiveFeesLoading;

  return (
    <Container maxWidth={'xl'} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mt: 4 }}>
        <BreadCrumbsCustom navigate={navigate} links={links} />
      </Box>
      <Box sx={{ mt: 4 }}>{renderTooltip}</Box>
      <Box sx={{ mt: 2 }}>{renderChips}</Box>
      <Box sx={{ mt: 4 }}>
        {(!competitiveFees?.documents && isLoading) ||
        (!competitiveFeesHist?.products && competitiveFeesHistIsLoading) ||
        (!competitiveFeesHistGas?.products && competitiveFeesHistGasIsLoading) ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img style={{ width: '140px' }} src={Loader} alt="loading..." />
          </Box>
        ) : (
          <CustomTabs
            tabs={tabs}
            newTab={Number(searchParams.get('t') ?? 0)}
            setActive={i => setSearchParams({ ...searchParams, t: i.toString() })}
          />
        )}
      </Box>

      <PopupForm
        InfoTootipProps={undefined}
        title={
          type === 'edit' ? 'Επεξεργασία Ανταγωνιστικής Χρέωσης' : 'Προσθήκη Χρέωσης'
        }
        helpText={`${
          type === 'edit' ? 'Διαχείριση' : 'Προσθήκη'
        } Τύπου Ανταγωνιστικής Χρέωσης Ηλεκτρικής Ενέργειας.`}
        leftBtnTitle="Ακύρωση"
        rightBtnTitle={type === 'new' ? 'Προσθήκη' : 'Αποθήκευση'}
        open={openDialog}
        setOpen={setOpenDialog}
        onClose={() => setOpenDialog(false)}
        onClickLeftBtn={cancelEditRow}
        onClickRightBtn={saveEditRow}
        maxWidth="md"
        rightBtnProps={{ disabled: !compFeeObj.info || !compFeeObj.infoEn }}
      >
        <CompFeesDialog obj={compFeeObj} setObj={setCompFeeObj} />
      </PopupForm>
    </Container>
  );
};
