import api from './api';
import { ApiPagination, PaginationResult } from '../../types/ApiPagination';
import {
  SpecialDiscountElectric,
  SpecialDiscountElectricFieldsRequest,
} from '../../types/SpecialDiscountElectric';
import { Identifier } from '../../types/ApiSchema';
import {
  SpecialDiscountGas,
  SpecialDiscountGasFieldsRequest,
} from '../../types/SpecialDiscountGas';

const providerIdApi = api
  .enhanceEndpoints({ addTagTypes: ['SpecialDiscountElectric', 'SpecialDiscountGas'] })
  .injectEndpoints({
    endpoints: builder => ({
      getSpecialDiscountElectric: builder.query<
        PaginationResult<SpecialDiscountElectric>,
        ApiPagination
      >({
        query: params => ({
          url: `database/SpecialDiscountElectric`,
          method: 'GET',
          params: params,
        }),
        providesTags: result =>
          result?.documents
            ? [
                ...result.documents.map(
                  ({ _id }) => ({ type: 'SpecialDiscountElectric', _id } as const),
                ),
                { type: 'SpecialDiscountElectric', id: 'LIST' },
              ]
            : [{ type: 'SpecialDiscountElectric', id: 'LIST' }],
      }),
      getSpecialDiscountGas: builder.query<
        PaginationResult<SpecialDiscountElectric>,
        ApiPagination
      >({
        query: params => ({
          url: `database/SpecialDiscountGas`,
          method: 'GET',
          params: params,
        }),
        providesTags: result =>
          result?.documents
            ? [
                ...result.documents.map(
                  ({ _id }) => ({ type: 'SpecialDiscountGas', _id } as const),
                ),
                { type: 'SpecialDiscountGas', id: 'LIST' },
              ]
            : [{ type: 'SpecialDiscountGas', id: 'LIST' }],
      }),
      editSpecialDiscountElectric: builder.mutation<
        SpecialDiscountElectric,
        Identifier & SpecialDiscountElectricFieldsRequest
      >({
        query: ({ _id, ...params }) => ({
          url: `database/SpecialDiscountElectric/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [
          { type: 'SpecialDiscountElectric', _id },
          { type: 'SpecialDiscountElectric', id: 'LIST' },
        ],
      }),
      editSpecialDiscountGas: builder.mutation<
        SpecialDiscountGas,
        Identifier & SpecialDiscountGasFieldsRequest
      >({
        query: ({ _id, ...params }) => ({
          url: `database/SpecialDiscountGas/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [
          { type: 'SpecialDiscountGas', _id },
          { type: 'SpecialDiscountGas', id: 'LIST' },
        ],
      }),
    }),
  });

export const {
  useGetSpecialDiscountElectricQuery,
  useGetSpecialDiscountGasQuery,
  useEditSpecialDiscountElectricMutation,
  useEditSpecialDiscountGasMutation,
} = providerIdApi;

export default providerIdApi;
