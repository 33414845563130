import { StorageFile, storageFileToFile } from './StorageFile';
import { ApiSchema } from './ApiSchema';
import { providerData, Team } from './Team';
import { ProviderIdGas, providerIdGasTableSingleData } from './ProviderIdGas';
import { CompetitiveFees } from './CompetitiveFees';
import { TieredBilling } from './TieredBilling';
import { OtherCharges } from './OtherCharges';
import { Discount } from './Discount';
import { Benefit } from './Benefit';
import { ChargeRows } from './ChargeRows';
import {
  benefitCast,
  compFeesData,
  dayChargeScale,
  dayTieredBillling,
  discountCast,
  durationCastRevert,
  dynamicChargeNorth,
  dynamicChargeSouth,
  energyChargeNorth,
  energyChargeSouth,
  exitClauseAmount,
  exitClauses,
  factorA,
  factorB,
  factorX,
  otherCharges,
  otherChargesData,
  otherDayChargeScale,
  otherDayTieredBilling,
  ProductGasTypes,
  protectionLower,
  protectionUpper,
} from '../components/ProductGasPage/ProductGasTypes';
import { FactorX } from './FactorX';
import { OptionsProps } from '@rae/ui-library/src/components/Calculator';
import { ReviewStatus } from './ReviewStatus';
import { ReviewComments } from './ReviewComments';
import moment from 'moment/moment';
import { durationCast } from '../components/ProductElectricPage/ProductElectricTypes';

type ProductGasProductInfoFields = {
  team: Team;
  nameGr: string;
  nameEn: string;
  linkGr?: string;
  linkEn?: string;
  image?: StorageFile;
  providerId: ProviderIdGas;
  duration?: number;
  availabilityDate: Date;
  termsLinkGr?: string[];
  termsLinkEn?: string[];
  infoGr?: string;
  infoEn?: string;
};
export type ProductGasProductInfoFieldsRequest = Omit<
  ProductGasProductInfoFields,
  'team' | 'providerId' | 'socialInvoiceDiscounts' | 'image'
> & {
  team: string;
  providerId: string;
  image?: string;
};

type ProductGasCompetitiveFeesFields = {
  competitiveFees?: CompetitiveFees;
  dayTieredBilling?: TieredBilling;
  dayChargeScale?: ChargeRows[];
  factorA?: number;
  factorB?: number;
  factorX?: FactorX;
  protectionLower?: number;
  protectionUpper?: number;
  energyChargeNorth?: number;
  energyChargeSouth?: number;
  dynamicChargeNorth?: number;
  dynamicChargeSouth?: number;
};
export type ProductGasCompetitiveFeesFieldsRequest = Omit<
  ProductGasCompetitiveFeesFields,
  'competitiveFees'
> & {
  competitiveFees?: string;
};

export type ProductGasOtherChargesFields = {
  otherCharges?: OtherCharges;
  otherDayTieredBilling?: TieredBilling;
  otherDayChargeScale?: ChargeRows[];
  other?: {
    chargeName: string;
    chargeInfo?: string;
    amount: number;
    step: number;
  }[];
};
export type ProductGasOtherChargesFieldsRequest = Omit<
  ProductGasOtherChargesFields,
  'otherCharges'
> & {
  otherCharges?: string;
};

export type ProductGasDiscountsFields = {
  discounts?: Discount[];
};
export type ProductGasDiscountsFieldsRequest = Omit<
  ProductGasDiscountsFields,
  'discounts'
> & {
  discounts?: string[];
};

export type ProductGasBenefitsFields = {
  benefits?: Benefit[];
};
export type ProductGasBenefitsFieldsRequest = Omit<
  ProductGasBenefitsFields,
  'benefits'
> & {
  benefits?: string[];
};

export type ProductGasExitClauseFields = {
  amount?: number[];
  info?: string;
};
export type ProductGasExitClauseFieldsRequest = ProductGasExitClauseFields;

export type ProductGasFields = {
  original?: ProductGas;
  versions?: ProductGas[];
  reviewStatus: ReviewStatus;
  reviewComments: ReviewComments;
  productInfo: ProductGasProductInfoFields;
  productCompetitiveFees: CompetitiveFees;
  competitiveFees: ProductGasCompetitiveFeesFields[];
  otherCharges: ProductGasOtherChargesFields[];
  discounts: ProductGasDiscountsFields[];
  benefits: ProductGasBenefitsFields[];
  exitClause: ProductGasExitClauseFields[];
  archived?: boolean;
};

export type ProductGas = ApiSchema<ProductGasFields>;

export type ProductGasFieldsRequest = {
  original?: string;
  reviewStatus?: ReviewStatus;
  reviewComments?: ReviewComments;
  productInfo: ProductGasProductInfoFieldsRequest;
  productCompetitiveFees: string;
  competitiveFees: ProductGasCompetitiveFeesFieldsRequest[];
  otherCharges: ProductGasOtherChargesFieldsRequest[];
  discounts: ProductGasDiscountsFieldsRequest[];
  benefits: ProductGasBenefitsFieldsRequest[];
  exitClause: ProductGasExitClauseFieldsRequest[];
};

export const addProductGas = (
  product?: ProductGasTypes,
  calculatorData?: OptionsProps[][],
): ProductGasFieldsRequest => {
  return {
    productInfo: {
      nameGr: product?.info?.productName ? product?.info?.productName : '',
      nameEn: product?.info?.productNameEn ? product?.info?.productNameEn : '',
      providerId: product?.info?.providerId?._id ? product?.info?.providerId?._id : '',
      team: product?.info?.provider?._id ? product?.info?.provider?._id : '',
      infoGr: product?.info?.info,
      infoEn: product?.info?.infoEn,
      linkGr: product?.info?.productLink,
      linkEn: product?.info?.productLinkEn,
      termsLinkGr: product?.info?.terms,
      termsLinkEn: product?.info?.termsEn,
      duration: durationCast(product?.info?.duration),
      availabilityDate: product?.info?.from ?? new Date(),
      image: product?.info?.productImgId,
    },
    productCompetitiveFees: product?.competitiveFeeType ?? '',
    competitiveFees:
      product?.competitiveFee?.map((item, i, index) => ({
        dayTieredBilling: dayTieredBillling(item),
        dayChargeScale: dayChargeScale(item),
        competitiveFees: item?.type,
        dynamicChargeNorth: dynamicChargeNorth(item),
        dynamicChargeSouth: dynamicChargeSouth(item),
        energyChargeNorth: energyChargeNorth(item),
        energyChargeSouth: energyChargeSouth(item),
        factorA: factorA(item),
        factorB: factorB(item),
        protectionLower: protectionLower(item),
        protectionUpper: protectionUpper(item),
        factorX: calculatorData
          ? { expression: factorX(item, calculatorData?.[i]) }
          : undefined,
      })) ?? [],
    otherCharges:
      product?.otherCharges?.map(item => ({
        otherCharges: item?.pagio && item?.pagio?.[0] ? item.pagio[0] : undefined,
        otherDayTieredBilling: otherDayTieredBilling(item),
        otherDayChargeScale: otherDayChargeScale(item),
        other: otherCharges(item),
      })) ?? [],
    exitClause:
      product?.clauses?.map(item => ({
        amount: exitClauseAmount(item),
        info: item?.descrClause,
      })) ?? [],
    benefits:
      product?.benefits?.map(item => ({
        benefits: item
          ? Object.values(item).flatMap(availBenefits =>
              Object.values(availBenefits)
                .map(benefit => benefit?._id)
                .filter((val): val is string => val !== undefined),
            )
          : undefined,
      })) ?? [],
    discounts:
      product?.discounts?.map(item => ({
        discounts: item
          ? Object.values(item).flatMap(availDiscounts =>
              Object.values(availDiscounts)
                .map(discount => discount?._id)
                .filter((val): val is string => val !== undefined),
            )
          : undefined,
      })) ?? [],
  };
};

export const getProductGasData = (product?: ProductGasFields): ProductGasTypes => {
  return {
    reviewStatus: product?.reviewStatus,
    reviewComments: product?.reviewComments ? product?.reviewComments : {},
    info: {
      productName: product?.productInfo.nameGr,
      productNameEn: product?.productInfo.nameEn,
      providerId: providerIdGasTableSingleData(product?.productInfo.providerId),
      provider: providerData(product?.productInfo.team),
      info: product?.productInfo.infoGr,
      infoEn: product?.productInfo.infoEn,
      productLink: product?.productInfo.linkGr,
      productLinkEn: product?.productInfo.linkEn,
      terms: product?.productInfo.termsLinkGr,
      termsEn: product?.productInfo.termsLinkEn,
      duration: durationCastRevert(product?.productInfo.duration),
      from: moment(product?.productInfo.availabilityDate).toDate(),
      productImg: storageFileToFile(product?.productInfo.image),
      productImgId: product?.productInfo.image?._id,
      productImgUrl: product?.productInfo.image?.url,
    },
    competitiveFeeType: product?.productCompetitiveFees?._id,
    competitiveFee: compFeesData(product),
    otherCharges: otherChargesData(product),
    clauses: exitClauses(product),
    discounts: discountCast(product),
    benefits: benefitCast(product),
  };
};
