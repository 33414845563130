import { OptionsProps } from '@rae/ui-library/src/components/Calculator';
import { TableDiscountProps } from '@rae/ui-library/src/components/table/TableRowDiscounts';
import {
  ChargeRows,
  chargeRowsAsNumber,
  chargeRowsAsString,
} from '../../types/ChargeRows';
import { FactorXExpression, optionsPropsToFactorXExpression } from '../../types/FactorX';
import {
  ProductGasCompetitiveFeesFieldsRequest,
  ProductGasFields,
  ProductGasOtherChargesFields,
} from '../../types/ProductGas';
import { TieredBilling } from '../../types/TieredBilling';
import { ReviewStatus } from '../../types/ReviewStatus';
import { ReviewComments } from '../../types/ReviewComments';
import { TableProviderIdGasProps } from '@rae/ui-library/src/components/table/TableRowProviderIdGas';
import { TableProviderProps } from '@rae/ui-library/src/components/table/TableRowProvider';
import { CompetitiveFeeIdsGasEnum } from '../../types/CompetitiveFees';
import { toNumber } from 'lodash';

export type ProductGasTypes = {
  reviewStatus?: ReviewStatus;
  reviewComments?: ReviewComments;
  info?: {
    provider?: TableProviderProps;
    productName?: string;
    productNameEn?: string;
    productLink?: string;
    productLinkEn?: string;
    providerId?: TableProviderIdGasProps;
    info?: string;
    infoEn?: string;
    duration?: string;
    from?: Date | null;
    productImg?: File | undefined;
    productImgId?: string;
    productImgUrl?: string;
    checkBox?: string[];
    terms?: string[];
    termsEn?: string[];
  };
  competitiveFeeType?: string;
  competitiveFee?: ProductGasTypesCompetitiveFees[];
  otherCharges?: ProductGasOtherCharges[];
  discounts?: DiscountsTypeDiscounts[];
  benefits?: DiscountsTypeBenefits[];
  clauses?: ProductGasTypeClauses[];
};

export type ProductGasTypesCompetitiveFees = {
  type?: string;
  transparencyType?: string;
  stableSection?: {
    day?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    night?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    energyChargeNorth?: string;
    energyChargeSouth?: string;
    dynamicChargeNorth?: string;
    dynamicChargeSouth?: string;
  };
  floatingChargeNoLimitSection?: {
    day?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    night?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    factorA?: string;
    factorB?: string;
    underProtection?: string;
    topProtection?: string;
    clausePrice?: string;
    clausePriceSelection?: string;
    energyChargeNorth?: string;
    energyChargeSouth?: string;
    dynamicChargeNorth?: string;
    dynamicChargeSouth?: string;
  };
  floatingChargeWithLimitSection?: {
    day?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    night?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    clauseMethodology?: string;
    factorA?: string;
    factorB?: string;
    underProtection?: string;
    topProtection?: string;
    maxReduction?: string;
    maxRaise?: string;
    clausePrice?: string;
    clausePriceSelection?: string;
  };
  floatingChargeNoClause?: {
    factorA?: string;
    factorB?: string;
    energyChargeNorth?: string;
    energyChargeSouth?: string;
    dynamicChargeNorth?: string;
    dynamicChargeSouth?: string;
    priceSelection?: string;
  };
  monthlyVariable?: {
    dayA?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    dayB?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    nightA?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
    nightB?: {
      tieredBilling?: string;
      chargeRows?: { amount: string; from: string; to: string }[];
    };
  };
};

export type ProductGasOtherCharges = {
  pagio?: string[];
  otherCharges?: string[];
  daySinglePhase?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  dayMultiPhase?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  nightSinglePhase?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  nightMultiPhase?: {
    tieredBilling?: string;
    chargeRows?: { amount: string; from: string; to: string }[];
  };
  other?: {
    otherName?: string;
    otherDescr?: string;
    otherAmount?: string;
    otherChargeStep?: string;
    otherApply?: string;
  }[];
};

export type ProductGasTypeClauses = {
  implClause?: string;
  kindClause?: string;
  durationClause?: string;
  descrClause?: string;
  months?: { id: number; value: number | undefined }[];
};

export type AvailibleDiscounts = Record<string, TableDiscountProps>;

export type DiscountsTypeDiscounts = Record<string, AvailibleDiscounts>;

export type AvailibleBenefits = Record<string, TableDiscountProps>;

export type DiscountsTypeBenefits = Record<string, AvailibleBenefits>;

export const dayTieredBillling = (
  obj?: ProductGasTypesCompetitiveFees,
): TieredBilling | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsGasEnum.Stable: //stable
      return obj?.stableSection?.day
        ?.tieredBilling as ProductGasCompetitiveFeesFieldsRequest['dayTieredBilling'];
    case CompetitiveFeeIdsGasEnum.NoLimit: //no-limit
      return obj?.floatingChargeNoLimitSection?.day
        ?.tieredBilling as ProductGasCompetitiveFeesFieldsRequest['dayTieredBilling'];
    case CompetitiveFeeIdsGasEnum.NoClause: //no-clause
      return undefined;
    default:
      return undefined;
  }
};

export const dayChargeScale = (
  obj?: ProductGasTypesCompetitiveFees,
): ChargeRows[] | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsGasEnum.Stable: //stable
      return obj?.stableSection?.day?.chargeRows
        ? chargeRowsAsNumber(obj.stableSection.day.chargeRows)
        : undefined;
    case CompetitiveFeeIdsGasEnum.NoLimit: //no-limit
      return obj?.floatingChargeNoLimitSection?.day?.chargeRows
        ? chargeRowsAsNumber(obj.floatingChargeNoLimitSection.day.chargeRows)
        : undefined;
    case CompetitiveFeeIdsGasEnum.NoClause: //no-clause
      return undefined;
    default:
      return undefined;
  }
};

export const dynamicChargeNorth = (
  obj?: ProductGasTypesCompetitiveFees,
): number | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsGasEnum.Stable: //stable
      return obj?.stableSection?.dynamicChargeNorth
        ? toNumber(obj.stableSection.dynamicChargeNorth)
        : undefined;
    case CompetitiveFeeIdsGasEnum.NoLimit: //no-limit
      return obj?.floatingChargeNoLimitSection?.dynamicChargeNorth
        ? toNumber(obj.floatingChargeNoLimitSection.dynamicChargeNorth)
        : undefined;
    case CompetitiveFeeIdsGasEnum.NoClause: //no-clause
      return obj?.floatingChargeNoClause?.dynamicChargeNorth
        ? toNumber(obj.floatingChargeNoClause.dynamicChargeNorth)
        : undefined;
    default:
      return undefined;
  }
};

export const dynamicChargeSouth = (
  obj?: ProductGasTypesCompetitiveFees,
): number | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsGasEnum.Stable: //stable
      return obj.stableSection?.dynamicChargeSouth
        ? toNumber(obj.stableSection.dynamicChargeSouth)
        : undefined;
    case CompetitiveFeeIdsGasEnum.NoLimit: //no-limit
      return obj?.floatingChargeNoLimitSection?.dynamicChargeSouth
        ? toNumber(obj.floatingChargeNoLimitSection.dynamicChargeSouth)
        : undefined;
    case CompetitiveFeeIdsGasEnum.NoClause: //no-clause
      return obj?.floatingChargeNoClause?.dynamicChargeSouth
        ? toNumber(obj.floatingChargeNoClause.dynamicChargeSouth)
        : undefined;
    default:
      return undefined;
  }
};

export const energyChargeNorth = (
  obj?: ProductGasTypesCompetitiveFees,
): number | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsGasEnum.Stable: //stable
      return obj?.stableSection?.energyChargeNorth
        ? toNumber(obj?.stableSection.energyChargeNorth)
        : undefined;
    case CompetitiveFeeIdsGasEnum.NoLimit: //no-limit
      return obj?.floatingChargeNoLimitSection?.energyChargeNorth
        ? toNumber(obj?.floatingChargeNoLimitSection.energyChargeNorth)
        : undefined;
    case CompetitiveFeeIdsGasEnum.NoClause: //no-clause
      return obj?.floatingChargeNoClause?.energyChargeNorth
        ? toNumber(obj?.floatingChargeNoClause.energyChargeNorth)
        : undefined;
    default:
      return undefined;
  }
};

export const energyChargeSouth = (
  obj?: ProductGasTypesCompetitiveFees,
): number | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsGasEnum.Stable: //stable
      return obj?.stableSection?.energyChargeSouth
        ? toNumber(obj.stableSection.energyChargeSouth)
        : undefined;
    case CompetitiveFeeIdsGasEnum.NoLimit: //no-limit
      return obj?.floatingChargeNoLimitSection?.energyChargeSouth
        ? toNumber(obj.floatingChargeNoLimitSection.energyChargeSouth)
        : undefined;
    case CompetitiveFeeIdsGasEnum.NoClause: //no-clause
      return obj?.floatingChargeNoClause?.energyChargeSouth
        ? toNumber(obj.floatingChargeNoClause.energyChargeSouth)
        : undefined;
    default:
      return undefined;
  }
};

export const factorA = (obj?: ProductGasTypesCompetitiveFees): number | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsGasEnum.Stable: //stable
      return undefined;
    case CompetitiveFeeIdsGasEnum.NoLimit: //no-limit
      return obj?.floatingChargeNoLimitSection?.factorA
        ? toNumber(obj.floatingChargeNoLimitSection.factorA)
        : undefined;
    case CompetitiveFeeIdsGasEnum.NoClause: //no-clause
      return obj?.floatingChargeNoClause?.factorA
        ? toNumber(obj.floatingChargeNoClause.factorA)
        : undefined;
    default:
      return undefined;
  }
};

export const factorB = (obj?: ProductGasTypesCompetitiveFees): number | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsGasEnum.Stable: //stable
      return undefined;
    case CompetitiveFeeIdsGasEnum.NoLimit: //no-limit
      return obj?.floatingChargeNoLimitSection?.factorB
        ? toNumber(obj.floatingChargeNoLimitSection.factorB)
        : undefined;
    case CompetitiveFeeIdsGasEnum.NoClause: //no-clause
      return obj?.floatingChargeNoClause?.factorB
        ? toNumber(obj.floatingChargeNoClause.factorB)
        : undefined;
    default:
      return undefined;
  }
};

export const factorX = (
  obj?: ProductGasTypesCompetitiveFees,
  calculatorData?: OptionsProps[],
): FactorXExpression => {
  switch (obj?.type) {
    case CompetitiveFeeIdsGasEnum.Stable: //stable
      return optionsPropsToFactorXExpression([]);
    case CompetitiveFeeIdsGasEnum.NoLimit: //no-limit
      return calculatorData
        ? optionsPropsToFactorXExpression(calculatorData)
        : optionsPropsToFactorXExpression([]);
    case CompetitiveFeeIdsGasEnum.NoClause: //no-clause
      return calculatorData
        ? optionsPropsToFactorXExpression(calculatorData)
        : optionsPropsToFactorXExpression([]);
    default:
      return optionsPropsToFactorXExpression([]);
  }
};

export const protectionLower = (
  obj?: ProductGasTypesCompetitiveFees,
): number | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsGasEnum.Stable: //stable
      return undefined;
    case CompetitiveFeeIdsGasEnum.NoLimit: //no-limit
      return obj?.floatingChargeNoLimitSection?.underProtection
        ? toNumber(obj.floatingChargeNoLimitSection.underProtection)
        : undefined;
    case CompetitiveFeeIdsGasEnum.NoClause: //no-clause
      return undefined;
    default:
      return undefined;
  }
};

export const protectionUpper = (
  obj?: ProductGasTypesCompetitiveFees,
): number | undefined => {
  switch (obj?.type) {
    case CompetitiveFeeIdsGasEnum.Stable: //stable
      return undefined;
    case CompetitiveFeeIdsGasEnum.NoLimit: //no-limit
      return obj?.floatingChargeNoLimitSection?.topProtection
        ? toNumber(obj.floatingChargeNoLimitSection.topProtection)
        : undefined;
    case CompetitiveFeeIdsGasEnum.NoClause: //no-clause
      return undefined;
    default:
      return undefined;
  }
};

export const otherDayTieredBilling = (
  obj?: ProductGasOtherCharges,
): TieredBilling | undefined => {
  if (!obj?.daySinglePhase?.tieredBilling) return undefined;
  return obj.daySinglePhase
    .tieredBilling as ProductGasOtherChargesFields['otherDayTieredBilling'];
};

export const otherDayChargeScale = (
  obj?: ProductGasOtherCharges,
): ChargeRows[] | undefined => {
  if (!obj?.daySinglePhase?.chargeRows) return undefined;
  return obj?.daySinglePhase?.chargeRows
    ? chargeRowsAsNumber(obj.daySinglePhase.chargeRows)
    : undefined;
};

export const otherCharges = (
  obj?: ProductGasOtherCharges,
): ProductGasOtherChargesFields['other'] | undefined => {
  if (!obj?.other) return undefined;
  if (obj?.other?.length === 0) return undefined;

  const result: ProductGasOtherChargesFields['other'] = [];

  for (const charge of obj.other) {
    if (charge.otherAmount && charge.otherName && charge.otherChargeStep) {
      result.push({
        amount: toNumber(charge.otherAmount),
        chargeName: charge.otherName,
        step: toNumber(charge.otherChargeStep),
        chargeInfo: charge.otherDescr,
      });
    }
  }

  return result.length > 0 ? result : undefined;
};
export const exitClauseAmount = (obj?: ProductGasTypeClauses): number[] | undefined => {
  if (obj?.months?.length === 0) return;
  return obj?.months ? obj?.months?.map(month => (month?.value ? month?.value : 0)) : [];
};

export const disableCompFees = (obj?: ProductGasTypes): boolean => {
  switch (obj?.competitiveFeeType) {
    case CompetitiveFeeIdsGasEnum.Stable: //stable
      return (
        !obj.competitiveFee?.[0]?.stableSection?.day?.tieredBilling ||
        !obj.competitiveFee?.[0]?.stableSection.dynamicChargeNorth ||
        !obj.competitiveFee?.[0]?.stableSection.dynamicChargeSouth ||
        !obj.competitiveFee?.[0]?.stableSection.energyChargeNorth ||
        !obj.competitiveFee?.[0]?.stableSection.energyChargeSouth
      );
    case CompetitiveFeeIdsGasEnum.NoLimit: //no-limit
      return (
        !obj.competitiveFee?.[0]?.floatingChargeNoLimitSection?.day?.tieredBilling ||
        !obj.competitiveFee?.[0]?.floatingChargeNoLimitSection?.dynamicChargeNorth ||
        !obj.competitiveFee?.[0]?.floatingChargeNoLimitSection?.dynamicChargeSouth ||
        !obj.competitiveFee?.[0]?.floatingChargeNoLimitSection?.energyChargeNorth ||
        !obj.competitiveFee?.[0]?.floatingChargeNoLimitSection?.energyChargeSouth ||
        !obj.competitiveFee?.[0]?.floatingChargeNoLimitSection?.factorA ||
        !obj.competitiveFee?.[0]?.floatingChargeNoLimitSection?.factorB ||
        !obj.competitiveFee?.[0]?.floatingChargeNoLimitSection?.topProtection ||
        !obj.competitiveFee?.[0]?.floatingChargeNoLimitSection?.underProtection
      );
    case CompetitiveFeeIdsGasEnum.NoClause: //no-clause
      return (
        !obj.competitiveFee?.[0]?.floatingChargeNoClause?.factorA ||
        !obj.competitiveFee?.[0]?.floatingChargeNoClause?.factorB ||
        !obj.competitiveFee?.[0]?.floatingChargeNoClause?.dynamicChargeNorth ||
        !obj.competitiveFee?.[0]?.floatingChargeNoClause?.dynamicChargeSouth ||
        !obj.competitiveFee?.[0]?.floatingChargeNoClause?.energyChargeNorth ||
        !obj.competitiveFee?.[0]?.floatingChargeNoClause?.energyChargeSouth
      );
    default:
      return true;
  }
};

export const disableOtherCharges = (obj?: ProductGasTypes): boolean => {
  return false;
  // return (
  //   !obj?.otherCharges?.pagio ||
  //   (!obj.otherCharges.dayMultiPhase?.tieredBilling &&
  //     !obj.otherCharges.daySinglePhase?.tieredBilling &&
  //     !obj.otherCharges.nightMultiPhase?.tieredBilling &&
  //     !obj.otherCharges.nightSinglePhase?.tieredBilling)
  // );
};

export const disableDiscounts = (obj?: ProductGasTypes): boolean => {
  return false;
  // return obj?.discounts
  //   ? Object.entries(obj?.discounts)
  //       .map(([type, availDiscounts]) => Object.keys(availDiscounts)[0])
  //       .filter(val => val !== undefined).length === 0
  //   : true;
};

export const disableBenefits = (obj?: ProductGasTypes): boolean => {
  return false;
  // return obj?.benefits
  //   ? Object.entries(obj?.benefits)
  //       .map(([type, availDiscounts]) => Object.keys(availDiscounts)[0])
  //       .filter(val => val !== undefined).length === 0
  //   : true;
};

export const disableExitClause = (obj?: ProductGasTypes): boolean => {
  return (
    !obj?.clauses ||
    obj.clauses.length === 0 ||
    obj.clauses.reduce((acc, clause) => {
      if (!clause.implClause) return true;
      return (
        acc ||
        (clause.implClause === 'Ναι'
          ? obj?.info?.duration && obj?.info?.duration === 'undefined'
            ? !clause?.descrClause
            : clause?.months?.some(month => month.value === undefined) ?? false
          : clause?.implClause !== 'Όχι')
      );
    }, false)
  );
};

export const compFeesData = (obj?: ProductGasFields): ProductGasTypes['competitiveFee'] =>
  obj?.competitiveFees?.map(fee => {
    if (!fee?.competitiveFees?._id) {
      return {};
    }
    switch (fee.competitiveFees._id) {
      case CompetitiveFeeIdsGasEnum.Stable: //stable
        return {
          type: fee.competitiveFees._id,
          stableSection: {
            day: {
              chargeRows: fee.dayChargeScale
                ? chargeRowsAsString(fee.dayChargeScale)
                : undefined,
              tieredBilling: fee.dayTieredBilling,
            },
            night: {
              chargeRows: fee.dayChargeScale
                ? chargeRowsAsString(fee.dayChargeScale)
                : undefined,
              tieredBilling: fee.dayTieredBilling,
            },
            dynamicChargeNorth: fee.dynamicChargeNorth?.valueOf().toString(),
            dynamicChargeSouth: fee.dynamicChargeSouth?.valueOf().toString(),
            energyChargeNorth: fee.energyChargeNorth?.valueOf().toString(),
            energyChargeSouth: fee.energyChargeSouth?.valueOf().toString(),
          },
        };
      case CompetitiveFeeIdsGasEnum.NoLimit: //no-limit
        return {
          type: fee.competitiveFees._id,
          floatingChargeNoLimitSection: {
            day: {
              chargeRows: fee.dayChargeScale
                ? chargeRowsAsString(fee.dayChargeScale)
                : undefined,
              tieredBilling: fee.dayTieredBilling,
            },
            night: {
              chargeRows: fee.dayChargeScale
                ? chargeRowsAsString(fee.dayChargeScale)
                : undefined,
              tieredBilling: fee.dayTieredBilling,
            },
            factorA: fee.factorA?.toString(),
            factorB: fee.factorB?.toString(),
            topProtection: fee.protectionUpper?.toString(),
            underProtection: fee.protectionLower?.toString(),
            dynamicChargeNorth: fee.dynamicChargeNorth?.valueOf().toString(),
            dynamicChargeSouth: fee.dynamicChargeSouth?.valueOf().toString(),
            energyChargeNorth: fee.energyChargeNorth?.valueOf().toString(),
            energyChargeSouth: fee.energyChargeSouth?.valueOf().toString(),
          },
        };
      case CompetitiveFeeIdsGasEnum.NoClause: //no-clause
        return {
          type: fee.competitiveFees._id,
          floatingChargeNoClause: {
            factorA: fee.factorA?.toString(),
            factorB: fee.factorB?.toString(),
            dynamicChargeNorth: fee.dynamicChargeNorth?.valueOf().toString(),
            dynamicChargeSouth: fee.dynamicChargeSouth?.valueOf().toString(),
            energyChargeNorth: fee.energyChargeNorth?.valueOf().toString(),
            energyChargeSouth: fee.energyChargeSouth?.valueOf().toString(),
          },
        };
      default:
        return {};
    }
  });

export const durationCastRevert = (duration?: number): string => {
  if (!duration) return 'undefined';
  return `${duration}`;
};

export const exitClauses = (obj?: ProductGasFields): ProductGasTypes['clauses'] => {
  return obj?.exitClause?.map(clause => ({
    descrClause: clause.info,
    months: clause.amount?.map((item, i) => ({ id: i + 1, value: item })),
    implClause:
      clause.info || (clause.amount && clause.amount.length > 0) ? 'Ναι' : 'Όχι',
    kindClause: clause.info || clause.amount ? 'Πρόωρης Αποχώρησης' : undefined,
  }));
};

export const otherChargesRevert = (other?: ProductGasOtherChargesFields) => {
  if (!other?.other || other?.other.length === 0) return undefined;

  return other.other.map(
    charge =>
      ({
        otherAmount: charge.amount?.toString(),
        otherName: charge.chargeName,
        otherChargeStep: charge.step?.toString(),
        otherDescr: charge.chargeInfo,
      } ?? []),
  );
};

export const otherChargesData = (
  obj?: ProductGasFields,
): ProductGasTypes['otherCharges'] => {
  if (!obj?.otherCharges || obj.otherCharges.length === 0) return [];

  return obj.otherCharges.map(charge => {
    return {
      daySinglePhase: {
        chargeRows: charge.otherDayChargeScale
          ? chargeRowsAsString(charge.otherDayChargeScale)
          : undefined,
        tieredBilling: charge.otherDayTieredBilling,
      },
      dayMultiPhase: {
        chargeRows: charge.otherDayChargeScale
          ? chargeRowsAsString(charge.otherDayChargeScale)
          : undefined,
        tieredBilling: charge.otherDayTieredBilling,
      },
      nightSinglePhase: {
        chargeRows: charge.otherDayChargeScale
          ? chargeRowsAsString(charge.otherDayChargeScale)
          : undefined,
        tieredBilling: charge.otherDayTieredBilling,
      },
      nightMultiPhase: {
        chargeRows: charge.otherDayChargeScale
          ? chargeRowsAsString(charge.otherDayChargeScale)
          : undefined,
        tieredBilling: charge.otherDayTieredBilling,
      },
      other: otherChargesRevert(charge),
      otherCharges: charge.other && charge.other?.length > 0 ? ['other'] : [''],
      pagio: charge.otherCharges?._id ? [charge.otherCharges?._id] : [],
    };
  });
};

export const discountCast = (obj?: ProductGasFields): ProductGasTypes['discounts'] =>
  obj?.discounts && obj.discounts.length > 0
    ? obj?.discounts?.map(disc => {
        if (!disc?.discounts) {
          return {};
        }

        const discounts: DiscountsTypeDiscounts = {};
        disc?.discounts.forEach(discount => {
          const tableDiscount: TableDiscountProps = {
            _id: discount._id,
            type: discount.discBenefType,
            name: discount.name,
            nameEn: discount.nameEn,
            provider: '',
            connected: 0,
            modDate: '',
            modEmail: '',
            editable: true, // if RAE ? false : true
            link: discount.link,
            linkEn: discount.linkEn,
            radioDisc: discount.radioDisc,
            descr: discount.descr,
            descrEn: discount.descrEn,
            clients: discount.clients,
            linkInvoices: [],
            category: '',
            energy: discount.energy,
            tieredBilling: discount.tieredBilling,
            chargeRows: discount?.chargeScale
              ? chargeRowsAsString(discount?.chargeScale)
              : undefined,
            status: 0,
          };

          if (!discounts[discount.discBenefType._id]) {
            discounts[discount.discBenefType._id] = {};
          }

          discounts[discount.discBenefType._id][discount._id] = tableDiscount;
        });

        return discounts;
      })
    : [{}];

export const benefitCast = (
  obj?: ProductGasFields,
): ProductGasTypes['benefits'] | undefined =>
  obj?.benefits && obj.benefits.length > 0
    ? obj?.benefits?.map(benef => {
        if (!benef?.benefits) {
          return {};
        }

        const benefits: DiscountsTypeDiscounts = {};
        benef.benefits.forEach(benef => {
          const tableBenefits: TableDiscountProps = {
            _id: benef._id,
            type: benef.discBenefType,
            name: benef.name,
            nameEn: benef.nameEn,
            provider: '',
            connected: 0,
            modDate: '',
            modEmail: '',
            editable: true, // if RAE ? false : true
            link: benef.link,
            linkEn: benef.linkEn,
            descr: benef.descr,
            descrEn: benef.descrEn,
            clients: benef.clients,
            linkInvoices: [],
            category: '',
            energy: benef.energy,
            status: 0,
          };

          if (!benefits[benef.discBenefType._id]) {
            benefits[benef.discBenefType._id] = {};
          }

          benefits[benef.discBenefType._id][benef._id] = tableBenefits;
        });

        return benefits;
      })
    : [{}];
