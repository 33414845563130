import api from './api';
import { ProductGas, ProductGasFieldsRequest } from '../../types/ProductGas';
import { Identifier } from '../../types/ApiSchema';

const productGasApi = api
  .enhanceEndpoints({ addTagTypes: ['ProductGas'] })
  .injectEndpoints({
    endpoints: builder => ({
      getAcceptedProductsGas: builder.query<
        { products: ProductGas[] },
        { team?: string }
      >({
        query: ({ team }) => ({
          url: `raeService/product/accepted`,
          method: 'GET',
          params: { type: 'gas', sort: '-productInfo.availabilityDate', team },
        }),
        providesTags: result =>
          result?.products
            ? [
                ...result?.products.map(
                  ({ _id }) => ({ type: 'ProductGas', _id } as const),
                ),
                { type: 'ProductGas', id: 'LIST' },
              ]
            : [{ type: 'ProductGas', id: 'LIST' }],
      }),
      getProductGas: builder.query<ProductGas, Identifier>({
        query: ({ _id, ...params }) => ({
          url: `/database/ProductGas/${_id}`,
          method: 'GET',
          params: {
            populate: [
              'productInfo.image',
              'productInfo.team',
              'productInfo.providerId',
              'competitiveFees.competitiveFees',
              'discounts.discounts',
              'discounts.discounts.discBenefType',
              'benefits.benefits',
              'benefits.benefits.discBenefType',
              'otherCharges.otherCharges',
              'productCompetitiveFees',
              'original',
            ],
            ...params,
          },
        }),
        providesTags: result => (result ? [{ type: 'ProductGas', _id: result._id }] : []),
      }),
      addProductGas: builder.mutation<ProductGas, Partial<ProductGasFieldsRequest>>({
        query: params => ({
          url: `/database/ProductGas`,
          method: 'POST',
          body: params,
        }),
        invalidatesTags: [{ type: 'ProductGas', id: 'LIST' }],
      }),
      editProductGas: builder.mutation<
        ProductGas,
        Identifier & Partial<ProductGasFieldsRequest>
      >({
        query: ({ _id, ...params }) => ({
          url: `/database/ProductGas/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [
          { type: 'ProductGas', _id },
          { type: 'ProductGas', id: 'LIST' },
        ],
      }),
      getProductGasVersions: builder.query<ProductGas[], Identifier>({
        query: ({ _id }) => ({
          url: `database/function/getProductGasVersions/${_id}`,
          method: 'GET',
          params: { sort: '-productInfo.availabilityDate' },
        }),
      }),
    }),
  });

export const {
  useAddProductGasMutation,
  useGetAcceptedProductsGasQuery,
  useGetProductGasQuery,
  useEditProductGasMutation,
  useGetProductGasVersionsQuery,
} = productGasApi;
export default productGasApi;
