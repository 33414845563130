import { TableOtherChargesProps } from '@rae/ui-library/src/components/table/TableRowOtherCharges';
import { TableHistChargesEL } from '@rae/ui-library/src/components/table/TableRowHistChargesEl';
import { TableHistChargesGasProps } from '@rae/ui-library/src/components/table/TableRowHistChargesGas';
import moment from 'moment';
import { ApiSchema, Identifier } from './ApiSchema';
import { EnergyType, EnergyTypeEnum } from './EnergyType';
import { Product } from './Product';
import { ProductElectric } from './ProductElectric';
import { categoryOfSupplyLabel } from './CategoryOfSupply';
import { tieredBillingLabel } from './TieredBilling';
import { ProductGas } from './ProductGas';

export type OtherChargesFields = {
  chargeName?: string;
  chargeNameEn?: string;
  chargeInfo?: string;
  energyType: EnergyType;
};

export type OtherCharges = ApiSchema<OtherChargesFields>;

export type OtherChargesParams = {
  search?: string;
  energyType?: string; //'electric' | 'gas'
};

export const gasOtherCharges = (charge?: OtherCharges[]): TableOtherChargesProps[] => {
  return (
    charge?.map(c => ({
      _id: c._id,
      chargeName: c.chargeName,
      chargeNameEn: c.chargeNameEn,
      chargeInfo: c.chargeInfo,
      energyType: c.energyType,
      modDate: moment(c.createdAt).format('DD/MM/YYYY'),
      modEmail: '',
    })) ?? []
  );
};

export const addOtherChargesFields = (
  obj: TableOtherChargesProps,
): OtherChargesFields => {
  return {
    chargeName: obj.chargeName,
    chargeNameEn: obj.chargeNameEn,
    chargeInfo: obj.chargeInfo,
    energyType: obj.energyType ? obj.energyType : EnergyTypeEnum.Electric,
  };
};

export const editOtherChargesFields = (
  obj: TableOtherChargesProps,
): Identifier & OtherChargesFields => {
  return {
    _id: obj._id ? obj._id : '',
    chargeName: obj.chargeName,
    chargeNameEn: obj.chargeNameEn,
    chargeInfo: obj.chargeInfo,
    energyType: obj.energyType ? obj.energyType : EnergyTypeEnum.Electric,
  };
};

export const otherChargesHistoryElectric = (
  products?: Product[],
): TableHistChargesEL[] => {
  const productsArray = products as ProductElectric[];
  return (
    productsArray?.map(p => ({
      id: p._id,
      type: p.otherCharges.otherCharges?.chargeName ?? '',
      program: p.productInfo.nameGr ?? '',
      category: categoryOfSupplyLabel(p.productInfo.providerId.categoryOfSupply).gr,
      daySinglePhaseChargeScale:
        p.otherCharges.daySinglePhaseChargeScale &&
        p.otherCharges.daySinglePhaseChargeScale.length > 0
          ? p.otherCharges.daySinglePhaseChargeScale.map(
              d => `${d.from ?? '0'}-${d.to ?? 'INF'}: ${d.amount}€`,
            )
          : ['-'],
      dayThreePhaseChargeScale:
        p.otherCharges.dayThreePhaseChargeScale &&
        p.otherCharges.dayThreePhaseChargeScale.length > 0
          ? p.otherCharges.dayThreePhaseChargeScale.map(
              d => `${d.from ?? '0'}-${d.to ?? 'INF'}: ${d.amount}€`,
            )
          : ['-'],
      nightSinglePhaseChargeScale:
        p.otherCharges.nightSinglePhaseChargeScale &&
        p.otherCharges.nightSinglePhaseChargeScale.length > 0
          ? p.otherCharges.nightSinglePhaseChargeScale.map(
              d => `${d.from ?? '0'}-${d.to ?? 'INF'}: ${d.amount}€`,
            )
          : ['-'],
      nightThreePhaseChargeScale:
        p.otherCharges.nightThreePhaseChargeScale &&
        p.otherCharges.nightThreePhaseChargeScale.length > 0
          ? p.otherCharges.nightThreePhaseChargeScale.map(
              d => `${d.from ?? '0'}-${d.to ?? 'INF'}: ${d.amount}€`,
            )
          : ['-'],
      provider: p.productInfo.team.nameGr,
    })) ?? []
  );
};

export const otherChargesHistoryElectricDetails = (
  product?: Product,
): TableHistChargesEL[] => {
  const productsArray = [product, ...(product?.versions ?? [])] as ProductElectric[];
  return (
    productsArray?.map(p => ({
      id: p._id,
      type: p.otherCharges?.otherCharges?.chargeName ?? '',
      program: p.productInfo.nameGr ?? '',
      category: categoryOfSupplyLabel(p.productInfo.providerId.categoryOfSupply).gr,
      daySinglePhaseTieredBilling: tieredBillingLabel(
        p.otherCharges.daySinglePhaseTieredBilling,
      ),
      daySinglePhaseChargeScale:
        p.otherCharges.daySinglePhaseChargeScale &&
        p.otherCharges.daySinglePhaseChargeScale.length > 0
          ? p.otherCharges.daySinglePhaseChargeScale.map(
              d => `${d.from ?? '0'}-${d.to ?? 'INF'}: ${d.amount}€`,
            )
          : ['-'],
      dayThreePhaseTieredBilling: tieredBillingLabel(
        p.otherCharges.dayThreePhaseTieredBilling,
      ),
      dayThreePhaseChargeScale:
        p.otherCharges.dayThreePhaseChargeScale &&
        p.otherCharges.dayThreePhaseChargeScale.length > 0
          ? p.otherCharges.dayThreePhaseChargeScale.map(
              d => `${d.from ?? '0'}-${d.to ?? 'INF'}: ${d.amount}€`,
            )
          : ['-'],
      nightSinglePhaseTieredBilling: tieredBillingLabel(
        p.otherCharges.nightSinglePhaseTieredBilling,
      ),
      nightSinglePhaseChargeScale:
        p.otherCharges.nightSinglePhaseChargeScale &&
        p.otherCharges.nightSinglePhaseChargeScale.length > 0
          ? p.otherCharges.nightSinglePhaseChargeScale.map(
              d => `${d.from ?? '0'}-${d.to ?? 'INF'}: ${d.amount}€`,
            )
          : ['-'],
      nightThreePhaseTieredBilling: tieredBillingLabel(
        p.otherCharges.nightThreePhaseTieredBilling,
      ),
      nightThreePhaseChargeScale:
        p.otherCharges.nightThreePhaseChargeScale &&
        p.otherCharges.nightThreePhaseChargeScale.length > 0
          ? p.otherCharges.nightThreePhaseChargeScale.map(
              d => `${d.from ?? '0'}-${d.to ?? 'INF'}: ${d.amount}€`,
            )
          : ['-'],
      provider: p.productInfo.team.nameGr,
      version: moment(p.productInfo.availabilityDate).format('DD/MM/YYYY'),
    })) ?? []
  );
};

export const otherChargesHistoryGas = (
  products?: Product[],
): TableHistChargesGasProps[] => {
  const productsArray = products as ProductGas[];
  return (
    productsArray?.map(p => ({
      id: p._id,
      type: p.otherCharges.otherCharges?.chargeName ?? '',
      program: p.productInfo.nameGr ?? '',
      category: categoryOfSupplyLabel(p.productInfo.providerId.categoryOfSupply).gr,
      chargeScale:
        p.otherCharges.otherDayChargeScale &&
        p.otherCharges.otherDayChargeScale.length > 0
          ? p.otherCharges.otherDayChargeScale.map(
              d => `${d.from ?? '0'}-${d.to ?? 'INF'}: ${d.amount}€`,
            )
          : ['-'],
      provider: p.productInfo.team.nameGr,
    })) ?? []
  );
};

export const otherChargesHistoryGasDetails = (
  product?: Product,
): TableHistChargesGasProps[] => {
  const productsArray = [product, ...(product?.versions ?? [])] as ProductGas[];
  return (
    productsArray?.map(p => ({
      id: p._id,
      type: p.otherCharges?.otherCharges?.chargeName ?? '',
      program: p.productInfo.nameGr ?? '',
      category: categoryOfSupplyLabel(p.productInfo.providerId.categoryOfSupply).gr,
      daySinglePhaseTieredBilling: tieredBillingLabel(
        p.otherCharges.otherDayTieredBilling,
      ),
      chargeScale:
        p.otherCharges.otherDayChargeScale &&
        p.otherCharges.otherDayChargeScale.length > 0
          ? p.otherCharges.otherDayChargeScale.map(
              d => `${d.from ?? '0'}-${d.to ?? 'INF'}: ${d.amount}€`,
            )
          : ['-'],
      provider: p.productInfo.team.nameGr,
      version: moment(p.productInfo.availabilityDate).format('DD/MM/YYYY'),
    })) ?? []
  );
};
