import { Box } from '@mui/material';
import { Dropdown, DropdownNew, Input } from '@rae/ui-library';
import { TableDistributionGasMngmentProps } from '@rae/ui-library/src/components/table/TableRowDistributionGasMngment';
import { TableGasProvidersProps } from '@rae/ui-library/src/components/table/TableRowGasProviders';
import React, { useMemo, useState } from 'react';
import { Choice } from '@rae/ui-library/src/components/DropdownNew';

type DialogProps = {
  obj: TableDistributionGasMngmentProps;
  setObj: (newState: any) => void;
  optionsProvider?: TableGasProvidersProps[];
};

export const DistributionGasMngmentDialod = ({
  obj,
  setObj,
  optionsProvider,
}: DialogProps) => {
  const [openZone, setOpenZone] = useState<boolean>(false);
  const [openRemoteNetworks, setOpenRemoteNetworks] = useState<boolean>(false);
  const [openAdmin, setOpenAdmin] = useState<boolean>(false);

  const providerOptions = useMemo((): Choice[] => {
    return (
      optionsProvider?.map(provider => ({
        value: provider._id ? provider._id : '',
        label: provider.providerName ? provider.providerName : '',
      })) ?? []
    );
  }, [optionsProvider]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexGrow: 1, mt: 2, justifyContent: 'space-between' }}>
        <Input
          sx={{ flexGrow: 1, m: 1 }}
          type="default"
          label="Πόλη / Περιοχή (GR)"
          placeholder="Eισάγετε πόλη"
          value={obj.cityGr}
          onChange={e =>
            setObj((values: TableDistributionGasMngmentProps) => ({
              ...values,
              cityGr: e.target.value,
            }))
          }
        />
        <Input
          sx={{ flexGrow: 1, m: 1 }}
          type="default"
          label="Πόλη / Περιοχή (EN)"
          placeholder="Insert city"
          value={obj.cityEn}
          onChange={e =>
            setObj((values: TableDistributionGasMngmentProps) => ({
              ...values,
              cityEn: e.target.value,
            }))
          }
        />
      </Box>

      <Box sx={{ display: 'flex', flexGrow: 1, mt: 2, justifyContent: 'space-between' }}>
        <DropdownNew
          sx={{ flexGrow: 1, m: 1 }}
          multiline={false}
          label="Ζώνη"
          labelBtn="Εφαρμογή"
          choices={[
            {
              label: 'Βόρεια',
              value: 'north',
            },
            {
              label: 'Νότια',
              value: 'south',
            },
          ]}
          open={openZone}
          setOpen={setOpenZone}
          selected={obj.zone ? [obj.zone] : []}
          setSelected={(type: any) =>
            setObj((values: TableDistributionGasMngmentProps) => ({
              ...values,
              zone: type[0],
            }))
          }
        />
        <Dropdown
          sx={{ flexGrow: 1, m: 1 }}
          multiline={false}
          label="Απομακρυσμένα Δίκτυα"
          labelBtn="Εφαρμογή"
          choices={['Ναι', 'Όχι']}
          open={openRemoteNetworks}
          setOpen={setOpenRemoteNetworks}
          selected={
            obj?.remoteNetwork !== undefined
              ? obj.remoteNetwork
                ? ['Ναι']
                : ['Όχι']
              : []
          }
          setSelected={(type: any) =>
            setObj((values: TableDistributionGasMngmentProps) => ({
              ...values,
              remoteNetwork: type[0] === 'Ναι',
            }))
          }
        />

        <DropdownNew
          sx={{ flexGrow: 1, m: 1 }}
          multiline={false}
          label="Διαχειριστής Φυσικού Αερίου"
          labelBtn="Εφαρμογή"
          choices={providerOptions}
          open={openAdmin}
          setOpen={setOpenAdmin}
          selected={obj?.gasProvider?._id ? [obj.gasProvider._id] : []}
          setSelected={(type: any) =>
            setObj((values: TableDistributionGasMngmentProps) => ({
              ...values,
              gasProvider: {
                ...values.gasProvider,
                _id: type[0],
              },
            }))
          }
        />
      </Box>
    </Box>
  );
};
