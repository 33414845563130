import api from './api';
import { ApiPagination, PaginationDiscBenefTypeResult } from '../../types/ApiPagination';
import {
  DiscBenefType,
  DiscBenefTypeFields,
  searcParams,
} from '../../types/DiscBenefType';
import { Identifier } from '../../types/ApiSchema';

const discBenefTypeApi = api
  .enhanceEndpoints({ addTagTypes: ['DiscBenefType'] })
  .injectEndpoints({
    endpoints: builder => ({
      getDiscBenefType: builder.query<
        PaginationDiscBenefTypeResult<DiscBenefType>,
        searcParams & ApiPagination
      >({
        query: params => ({
          url: `raeService/discBenefType`,
          method: 'GET',
          params: params,
        }),
        providesTags: result =>
          result?.discBenefType
            ? [
                ...result.discBenefType.map(
                  ({ _id }) => ({ type: 'DiscBenefType', _id } as const),
                ),
                { type: 'DiscBenefType', id: 'LIST' },
              ]
            : [{ type: 'DiscBenefType', id: 'LIST' }],
      }),
      addDiscBenefType: builder.mutation<DiscBenefType, DiscBenefTypeFields>({
        query: params => ({
          url: `database/DiscBenefType`,
          method: 'POST',
          body: params,
        }),
        invalidatesTags: [{ type: 'DiscBenefType', id: 'LIST' }],
      }),
      editDiscBenefType: builder.mutation<
        DiscBenefType,
        Identifier & DiscBenefTypeFields
      >({
        query: ({ _id, ...params }) => ({
          url: `database/DiscBenefType/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'DiscBenefType', _id }],
      }),
      deleteDiscBenefType: builder.mutation<void, Identifier>({
        query: ({ _id }) => ({
          url: `raeService/disc-benef-type/${_id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'DiscBenefType', _id }],
      }),
    }),
  });

export const {
  useGetDiscBenefTypeQuery,
  useAddDiscBenefTypeMutation,
  useEditDiscBenefTypeMutation,
  useDeleteDiscBenefTypeMutation,
} = discBenefTypeApi;

export default discBenefTypeApi;
