import { CustomTable } from '@rae/ui-library';
import { OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import React, { useState } from 'react';
import { TableCompFeesProps } from '@rae/ui-library/src/components/table/TableRowCompFees';
import { SearchProps } from '../../pages/CompetitiveFeeMngment';
import { DropDownProps } from '@rae/ui-library/src/components/Dropdown';

type CompFeesProps = {
  search: SearchProps;
  setSearch: (newState: any) => void;
  data: TableCompFeesProps[];
  edit: (obj: TableCompFeesProps) => void;
  setOrder: (oreder: OrderProps) => void;
  order: OrderProps;
  setOrderBy: (orederBy: string) => void;
  orderBy: string;
  setPage: (page: number) => void;
  page: number;
  count: number;
};

export const CompetitiveFeesTab = ({
  search,
  setSearch,
  data,
  edit,
  order,
  orderBy,
  page,
  setOrder,
  setOrderBy,
  setPage,
  count,
}: CompFeesProps) => {
  const [openType, setOpenType] = useState<boolean>(false);

  const dropDowns: DropDownProps[] = [
    {
      choices: ['Ηλεκτρική Ενέργεια', 'Φυσικό Αέριο'],
      open: openType,
      selected: search.compFees.type ? search.compFees.type : [],
      setOpen: setOpenType,
      setSelected: (type: any) =>
        setSearch((values: SearchProps) => ({
          ...values,
          compFees: {
            ...values.compFees,
            type: type,
          },
        })),
      label: 'Τύπος Ανταγωνιστικής Χρεώσης',
      multiple: false,
      labelBtn: 'Εφαρμογή',
      large: true,
    },
  ];

  return (
    <CustomTable
      dropDowns={dropDowns}
      handleEdit={(obj: TableCompFeesProps) => edit(obj)}
      searchInput={{
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          setSearch((values: SearchProps) => ({
            ...values,
            compFees: {
              ...values.compFees,
              search: (event.target as HTMLInputElement).value,
            },
          })),
        value: search.compFees.search === null ? '' : search.compFees.search,
        type: 'search',
        label: 'Αναζήτηση',
      }}
      type="comp-fees"
      rows={data}
      count={count}
      headCells={[
        { disable: false, id: 'name', label: 'Όνομα' },
        { disable: false, id: 'transparencyType', label: 'Σήμανση Διαφάνειας' },
        { disable: false, id: 'info', label: 'Πληροφορίες' },
        { disable: false, id: 'updatedAt', label: 'Τροποποίηση' },
        { disable: true, id: 'actions', label: 'Ενέργειες' },
      ]}
      order={order}
      orderBy={orderBy}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
      page={page}
      rowsPerPage={5}
      setPage={setPage}
    />
  );
};
