import api from './api';
import { ApiPagination, PaginationResult } from '../../types/ApiPagination';
import { GasProvider, GasProviderFields } from '../../types/GasProvider';
import { Identifier } from '../../types/ApiSchema';

const gasProviderApi = api
  .enhanceEndpoints({ addTagTypes: ['GasProvider'] })
  .injectEndpoints({
    endpoints: builder => ({
      getGasProviders: builder.query<PaginationResult<GasProvider>, ApiPagination>({
        query: params => ({
          url: `/database/GasProvider`,
          method: 'GET',
          params: params,
        }),
        providesTags: result =>
          result?.documents
            ? [
                ...result.documents.map(
                  ({ _id }) => ({ type: 'GasProvider', _id } as const),
                ),
                { type: 'GasProvider', id: 'LIST' },
              ]
            : [{ type: 'GasProvider', id: 'LIST' }],
      }),
      addGasProvider: builder.mutation<GasProvider, GasProviderFields>({
        query: params => ({
          url: `database/GasProvider`,
          method: 'POST',
          body: params,
        }),
        invalidatesTags: [{ type: 'GasProvider', id: 'LIST' }],
      }),
      editGasProvider: builder.mutation<GasProvider, Identifier & GasProviderFields>({
        query: ({ _id, ...params }) => ({
          url: `database/GasProvider/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'GasProvider', _id }],
      }),
      deleteGasProcider: builder.mutation<void, Identifier>({
        query: ({ _id }) => ({
          url: `raeService/gas-provider/${_id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'GasProvider', _id }],
      }),
    }),
  });

export const {
  useGetGasProvidersQuery,
  useAddGasProviderMutation,
  useEditGasProviderMutation,
  useDeleteGasProciderMutation,
} = gasProviderApi;

export default gasProviderApi;
