import { Box } from '@mui/material';
import { Input } from '@rae/ui-library';
import { TableRegulatedChargesGasProps } from '@rae/ui-library/src/components/table/TableRowRegulatedChargesGas';
import React from 'react';

type DialogProps = {
  obj: TableRegulatedChargesGasProps;
  setObj: (newState: any) => void;
};

export const TransmissionGasChargesDialog = ({ obj, setObj }: DialogProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Input
        sx={{ m: 2, flexGrow: 1 }}
        type="default"
        inputType={'number'}
        label="Α. Χρέωση Ενέργειας"
        value={obj.transportationEnergyCharge}
        onChange={e =>
          setObj((values: TableRegulatedChargesGasProps) => ({
            ...values,
            transportationEnergyCharge: e.target.value,
          }))
        }
      />
      <Input
        sx={{ m: 2, flexGrow: 1 }}
        type="default"
        inputType={'number'}
        label="Β. Χρέωση Δυναμικότητας"
        value={obj.transportationCapacityCharge}
        onChange={e =>
          setObj((values: TableRegulatedChargesGasProps) => ({
            ...values,
            transportationCapacityCharge: e.target.value,
          }))
        }
      />
    </Box>
  );
};
