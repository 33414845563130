import { DiscBenefTypeProps } from '@rae/ui-library/src/components/table/TableRowDiscBenefType';
import moment from 'moment';
import { ApiSchema, Identifier } from './ApiSchema';

export type DiscBenefTypeFields = {
  typeName: string;
  typeNameEn?: string;
  category: 'discount' | 'benefit';
  applicationField?: string;
  modEmail?: string;
};

export type DiscBenefType = ApiSchema<DiscBenefTypeFields>;

export type searcParams = {
  team?: string;
  search?: string;
  type?: 'discount' | 'benefit';
};

export const discBenefTypeData = (type?: DiscBenefType[]): DiscBenefTypeProps[] => {
  return (
    type?.map(t => ({
      _id: t._id,
      category: t.category,
      typeName: t.typeName ? t.typeName : '',
      typeNameEn: t.typeNameEn,
      applicationField: t.applicationField,
      modDate: moment(t.updatedAt).format('DD/MM/YYYY'),
      modEmail: '',
    })) ?? []
  );
};

export const discBenefTypeDataSingle = (type?: DiscBenefType): DiscBenefTypeProps => {
  return {
    _id: type?._id,
    category: type?.category,
    typeName: type?.typeName ? type.typeName : '',
    typeNameEn: type?.typeNameEn,
    applicationField: type?.applicationField,
    modDate: moment(type?.updatedAt).format('DD/MM/YYYY'),
    modEmail: '',
  };
};

export const addDiscBenefTypeData = (obj: DiscBenefTypeProps): DiscBenefTypeFields => {
  return {
    category: obj.category ? obj.category : 'discount',
    typeName: obj.typeName,
    typeNameEn: obj.typeNameEn,
    applicationField: obj.applicationField,
  };
};

export const editDiscBenefTypeData = (
  obj: DiscBenefTypeProps,
): Identifier & DiscBenefTypeFields => {
  return {
    _id: obj._id ? obj._id : '',
    category: obj.category ? obj.category : 'discount',
    typeName: obj.typeName,
    typeNameEn: obj.typeNameEn,
    applicationField: obj.applicationField,
  };
};
