import api from './api';
import { ApiPagination, PaginationDataResult } from '../../types/ApiPagination';
import {
  UserDeleteFieldsRequest,
  UserEditFieldsRequest,
  UserFieldsRequest,
  Users,
  UsersParams,
} from '../../types/User';
import { Identifier } from '../../types/ApiSchema';

const userApi = api.enhanceEndpoints({ addTagTypes: ['Users'] }).injectEndpoints({
  endpoints: builder => ({
    getUsers: builder.query<PaginationDataResult<Users>, UsersParams & ApiPagination>({
      query: ({ teamId, ...params }) => ({
        url: `/raeService/members/${teamId}`,
        method: 'GET',
        params: { ...params },
      }),
      providesTags: result =>
        result?.data
          ? [
              ...result.data.map(({ _id }) => ({ type: 'Users', _id } as const)),
              { type: 'Users', id: 'LIST' },
            ]
          : [{ type: 'Users', id: 'LIST' }],
    }),
    editMember: builder.mutation<void, Identifier & UserEditFieldsRequest>({
      query: ({ _id, teams }) => ({
        url: `/authentication/teams/${teams?._id}/members`,
        method: 'PATCH',
        body: {
          members: [_id],
          role: teams?.role,
        },
      }),
      invalidatesTags: (result, error, { _id }) => [{ type: 'Users', _id }],
    }),
    inviteUser: builder.mutation<void, UserFieldsRequest>({
      query: ({ email, teams }) => ({
        url: `/authentication/teams/${teams?._id}/invite`,
        method: 'POST',
        body: {
          role: teams?.role,
          email: email,
        },
      }),
    }),
    deleteMember: builder.mutation<string, Identifier & UserDeleteFieldsRequest>({
      query: ({ _id, teams }) => ({
        url: `/raeService/teams/${teams}/members`,
        method: 'DELETE',
        params: { members: [_id] },
      }),
      invalidatesTags: (result, error, { _id }) => [{ type: 'Users', _id }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useInviteUserMutation,
  useDeleteMemberMutation,
  useEditMemberMutation,
} = userApi;

export default userApi;
