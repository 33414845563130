import { ApiPagination, PaginationResult } from '../../types/ApiPagination';
import { StorageFile, StorageFileProps } from '../../types/StorageFile';
import api from './api';
import { Identifier } from '../../types/ApiSchema';
import { UserManual } from '../../types/UserManual';
import {
  Documents,
  DocumentsFieldsRequest,
  DocumentsParams,
} from '../../types/Documents';
import { toFileName } from '../../utils/FileData';
import { toGreeklish } from 'greek-utils';

const documentsApi = api
  .enhanceEndpoints({ addTagTypes: ['UserManuals', 'Documents'] })
  .injectEndpoints({
    endpoints: builder => ({
      getUserManuals: builder.query<PaginationResult<UserManual>, ApiPagination>({
        query: params => ({
          url: `database/UserManuals`,
          params: { ...params, populate: 'file' },
        }),
        providesTags: result =>
          result?.documents
            ? [
                ...result.documents.map(
                  ({ _id }) => ({ type: 'UserManuals', _id } as const),
                ),
                { type: 'UserManuals', id: 'LIST' },
              ]
            : [{ type: 'UserManuals', id: 'LIST' }],
      }),
      replaceUserManual: builder.mutation<void, Identifier & StorageFileProps>({
        query: ({ _id, name, ...params }) => ({
          url: `storage/file/${_id}`,
          method: 'PATCH',
          body: {
            ...params,
            name: toFileName(name),
            container: 'rae-public',
            folder: 'user-manuals/',
          },
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'UserManuals', _id }],
      }),
      getDocuments: builder.query<
        PaginationResult<Documents>,
        DocumentsParams & ApiPagination
      >({
        query: params => ({
          url: `database/function/getDocuments`,
          params: { ...params, populate: ['fileGr', 'fileEn'] },
        }),
        providesTags: result =>
          result?.documents
            ? [
                ...result.documents.map(
                  ({ _id }) => ({ type: 'Documents', _id } as const),
                ),
                { type: 'Documents', id: 'LIST' },
              ]
            : [{ type: 'Documents', id: 'LIST' }],
      }),
      addDocument: builder.mutation<Identifier & StorageFile, StorageFileProps>({
        query: ({ name, ...params }) => ({
          url: `storage/file`,
          method: 'POST',
          body: {
            ...params,
            name: toFileName(name),
            container: 'rae-public',
            folder: 'documents/',
            isPublic: true,
          },
        }),
      }),
      patchDocuments: builder.mutation<
        Documents,
        Identifier & Partial<DocumentsFieldsRequest>
      >({
        query: ({ _id, fileGr, fileEn, fileNameEn }) => ({
          url: `database/Documents/${_id}`,
          method: 'PATCH',
          body: {
            fileNameEn: fileNameEn ? toGreeklish(fileNameEn) : '',
            fileGr: fileGr,
            fileEn: fileEn,
          },
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'Documents', _id }],
      }),
      addDocuments: builder.mutation<Documents, Partial<DocumentsFieldsRequest>>({
        query: params => ({
          url: `database/Documents`,
          method: 'POST',
          body: params,
        }),
        invalidatesTags: [{ type: 'Documents', id: 'LIST' }],
      }),
      deleteDocument: builder.mutation<string, Identifier>({
        query: ({ _id }) => ({
          url: `storage/file/${_id}`,
          method: 'DELETE',
        }),
      }),
      deleteDocuments: builder.mutation<string, Identifier>({
        query: ({ _id }) => ({
          url: `database/Documents/${_id}`,
          method: 'DELETE',
        }),
        invalidatesTags: [{ type: 'Documents', id: 'LIST' }],
      }),
    }),
  });

export const {
  useGetUserManualsQuery,
  useReplaceUserManualMutation,
  useGetDocumentsQuery,
  useAddDocumentsMutation,
  useAddDocumentMutation,
  usePatchDocumentsMutation,
  useDeleteDocumentMutation,
  useDeleteDocumentsMutation,
} = documentsApi;

export default documentsApi;
