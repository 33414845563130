import api from './api';
import { ApiPagination, PaginationResult } from '../../types/ApiPagination';
import { Identifier } from '../../types/ApiSchema';
import { TeamFieldsRequest, TeamParams, Team } from '../../types/Team';
import { StorageFile, StorageFileProps } from '../../types/StorageFile';
import { toFileName } from '../../utils/FileData';

const teamApi = api
  .enhanceEndpoints({
    addTagTypes: ['Teams', 'ProductElectric', 'ProductGas', 'ProductLinked'],
  })
  .injectEndpoints({
    endpoints: builder => ({
      getTeams: builder.query<PaginationResult<Team>, TeamParams & ApiPagination>({
        query: params => ({
          url: `/database/function/getTeams`,
          method: 'GET',
          params: { ...params, populate: ['image'] },
        }),
        providesTags: result =>
          result?.documents
            ? [
                ...result.documents.map(({ _id }) => ({ type: 'Teams', _id } as const)),
                { type: 'Teams', id: 'LIST' },
              ]
            : [{ type: 'Teams', id: 'LIST' }],
      }),
      addTeam: builder.mutation<Team, Partial<TeamFieldsRequest>>({
        query: params => ({
          url: `/authentication/teams`,
          method: 'POST',
          body: params,
        }),
      }),
      editTeam: builder.mutation<Team, Identifier & Partial<TeamFieldsRequest>>({
        query: ({ _id, ...params }) => ({
          url: `/database/function/changeTeamDetails/${_id}`,
          method: 'PATCH',
          body: params,
        }),
        invalidatesTags: (result, error, { _id }) => [{ type: 'Teams', _id }],
      }),
      deleteTeam: builder.mutation<void, Identifier>({
        query: ({ _id }) => ({
          url: `/raeService/teams/${_id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, { _id }) => [
          { type: 'Teams', _id },
          { type: 'ProductElectric', id: 'LIST' },
          { type: 'ProductGas', id: 'LIST' },
          { type: 'ProductLinked', id: 'LIST' },
        ],
      }),
      addLogo: builder.mutation<Identifier & StorageFile, StorageFileProps>({
        query: ({ name, ...params }) => ({
          url: `storage/file`,
          method: 'POST',
          body: {
            ...params,
            name: toFileName(name),
            container: 'rae-public',
            folder: 'team-image/',
            isPublic: true,
          },
        }),
      }),
      editLogo: builder.mutation<Identifier & StorageFile, Identifier & StorageFileProps>(
        {
          query: ({ _id, name, ...params }) => ({
            url: `storage/file/${_id}`,
            method: 'PATCH',
            body: {
              ...params,
              name: toFileName(name),
              container: 'rae-public',
              folder: 'team-image/',
              isPublic: true,
            },
          }),
        },
      ),
    }),
  });

export const {
  useGetTeamsQuery,
  useAddTeamMutation,
  useEditTeamMutation,
  useDeleteTeamMutation,
  useAddLogoMutation,
  useEditLogoMutation,
} = teamApi;

export default teamApi;
