import { Box, Container } from '@mui/material';
import { BreadCrumbsCustom, CustomTabs, PopupForm } from '@rae/ui-library';
import { TableAvrgConsProps } from '@rae/ui-library/src/components/table/TableRowAvrgCons';
import React, { useCallback, useState } from 'react';
import {
  EditDialogEl,
  EditDialogGas,
  EditTabs,
} from '../components/AvrgConsumptionPage/TabsDialog';
import { ElTableContent } from '../components/AvrgConsumptionPage/TabsTableContent';
import { TabsTableGas } from '../components/AvrgConsumptionPage/TabsTableGas';
import { TableAvrgConsGasProps } from '@rae/ui-library/src/components/table/TableRowAvgConsGas';
import { pageToSkipLimit } from '../types/ApiPagination';
import {
  useEditAvrgConsumptionGasMutation,
  useGetAvrgConsumptionGasQuery,
} from '../redux/apis/avrgConsumptionGasApi';
import {
  avrgConsumptionGasData,
  editAvrgConsumptionGasData,
} from '../types/AvrgConsumptionGas';
import { HeadCell, OrderProps } from '@rae/ui-library/src/components/table/TableHeader';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { setLog } from '../redux/slices/appSlice';
import Loader from '../assets/loading.gif';
import {
  useAddAvrgConsumptionElectricMutation,
  useEditAvrgConsumptionElectricMutation,
  useGetAvrgConsumptionElectricQuery,
} from '../redux/apis/avrgConsumptionElectricApi';
import {
  addAvrgConsumptionElectricData,
  avrgConsumptionElectricData,
  editAvrgConsumptionElectricData,
} from '../types/AvrgConsumptionElectric';
import { useGetProviderIdsGasQuery } from '../redux/apis/providerIdApi';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const AvrgCosnymptionPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [isSingle, setIsSingle] = useState<boolean>(false);
  const [objEdit, setObjEdit] = useState<TableAvrgConsProps>({
    providerId: {
      categoryOfSupply: '',
      measurement: '',
    },
    singlePhaseDay: 0,
    threePhaseDay: 0,
  });
  const [objGasEdit, setObjGasEdit] = useState<TableAvrgConsGasProps>({});
  const [openEditGasDialog, setOpenEditGasDialog] = useState<boolean>(false);
  const [orderElectric, setOrderElectric] = useState<OrderProps>('asc');
  const [orderByElectric, setOrderByElectric] = useState<HeadCell['id']>('updatedAt');
  const [pageElectric, setPageElectric] = useState<number>(0);
  const [orderGas, setOrderGas] = useState<OrderProps>('asc');
  const [orderByGas, setOrderByGas] = useState<HeadCell['id']>('updatedAt');
  const [pageGas, setPageGas] = useState<number>(0);

  const { data: avrgConsumptionElectric, isLoading: avrgConsumptionElectricLoading } =
    useGetAvrgConsumptionElectricQuery(
      pageToSkipLimit(pageElectric, 5, [
        `${orderElectric === 'asc' ? '-' : ''}${orderByElectric}`,
      ]),
    );

  const [addAvrgConsumptionElectric, { isLoading: addAvrgConsumptionElectricLoading }] =
    useAddAvrgConsumptionElectricMutation();

  const [editAvrgConsumptionElectric, { isLoading: editAvrgConsumptionElectricLoading }] =
    useEditAvrgConsumptionElectricMutation();

  const { data: providerIdsGas } = useGetProviderIdsGasQuery(
    pageToSkipLimit(0, 100, [`-updatedAt`]),
  );

  const { data: avrgConsumptionGas, isLoading: avrgConsumptionGasLoading } =
    useGetAvrgConsumptionGasQuery(
      pageToSkipLimit(pageGas, 5, [`${orderGas === 'asc' ? '-' : ''}${orderByGas}`]),
    );

  const [editAvrgConsumptionGas, { isLoading: editAvrgConsumptionGasLoading }] =
    useEditAvrgConsumptionGasMutation();

  const links = [{ name: 'Προϊόντα', href: '/' }, { name: 'Δείκτες Μέσης Κατανάλωσης' }];

  const handleOpenEditDialog = useCallback((obj: TableAvrgConsProps) => {
    if (obj.providerId.measurement === 'Ημερήσια & Νυχτερινή') {
      setIsSingle(false);
    } else {
      setIsSingle(true);
    }
    setOpenEditDialog(true);
    setObjEdit(obj);
  }, []);

  const handleOpenGasEditDialog = useCallback((obj: TableAvrgConsGasProps) => {
    setOpenEditGasDialog(true);
    setObjGasEdit(obj);
  }, []);

  const handleSaveEdit = useCallback(async () => {
    if (
      !objEdit?._id ||
      !objEdit?.singlePhaseDay ||
      !objEdit?.threePhaseDay ||
      !objEdit.providerId?._id
    )
      return;
    const editFields = editAvrgConsumptionElectricData(objEdit);
    try {
      await editAvrgConsumptionElectric(editFields).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: `Η μέση κατανάλωση Hλεκτρικής Ενέργειας αποθηκεύτηκε επιτυχώς`,
        }),
      );
    } catch (error: any) {
      console.log(error);
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setObjEdit({
      providerId: {
        categoryOfSupply: '',
        measurement: '',
      },
      singlePhaseDay: 0,
      threePhaseDay: 0,
    });
    setOpenEditDialog(false);
  }, [dispatch, editAvrgConsumptionElectric, objEdit]);

  const handleAddElectric = useCallback(async () => {
    if (!objEdit?.singlePhaseDay || !objEdit?.threePhaseDay || !objEdit.providerId?._id)
      return;
    const addFields = addAvrgConsumptionElectricData(objEdit);
    try {
      await addAvrgConsumptionElectric(addFields).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: `Η μέση κατανάλωση Hλεκτρικής Ενέργειας αποθηκεύτηκε επιτυχώς`,
        }),
      );
    } catch (error: any) {
      console.log(error);
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setObjEdit({
      providerId: {
        categoryOfSupply: '',
        measurement: '',
      },
      singlePhaseDay: 0,
      threePhaseDay: 0,
    });
    setOpenEditDialog(false);
  }, [addAvrgConsumptionElectric, dispatch, objEdit]);

  const handleSaveGasEdit = useCallback(async () => {
    if (!objGasEdit?._id || !objGasEdit?.monthlyCons) return;
    const editFields = editAvrgConsumptionGasData(objGasEdit);
    try {
      await editAvrgConsumptionGas(editFields).unwrap();
      dispatch(
        setLog({
          severity: 'success',
          message: `Η μέση κατανάλωση Φυσικού Αερίου αποθηκεύτηκε επιτυχώς`,
        }),
      );
    } catch (error: any) {
      console.log(error);
      dispatch(
        setLog({
          severity: 'error',
          message: error.data.message,
        }),
      );
    }
    setOpenEditGasDialog(false);
    setObjGasEdit({});
  }, [dispatch, editAvrgConsumptionGas, objGasEdit]);

  const handleCloseDialogGas = useCallback(() => {
    setOpenEditGasDialog(false);
    setObjGasEdit({});
  }, []);

  const handleCloseDialogElectric = useCallback(() => {
    setOpenEditDialog(false);
    setObjEdit({
      providerId: {
        categoryOfSupply: '',
        measurement: '',
      },
      singlePhaseDay: 0,
      threePhaseDay: 0,
    });
  }, []);

  const tabs = [
    {
      tabName: 'Μέση Κατανάλωση Ηλεκτρική Ενέργειας',
      tabContent: (
        <ElTableContent
          order={orderElectric}
          orderBy={orderByElectric}
          page={pageElectric}
          setOrder={setOrderElectric}
          setOrderBy={setOrderByElectric}
          setPage={setPageElectric}
          setOpenEdit={handleOpenEditDialog}
          data={avrgConsumptionElectricData(avrgConsumptionElectric?.data)}
        />
      ),
    },
    {
      tabName: 'Μέση Κατανάλωση Φυσικού Αερίου',
      tabContent: (
        <TabsTableGas
          order={orderGas}
          orderBy={orderByGas}
          page={pageGas}
          setOrder={setOrderGas}
          setOrderBy={setOrderByGas}
          setPage={setPageGas}
          data={avrgConsumptionGasData(
            avrgConsumptionGas?.documents.filter(avr =>
              providerIdsGas?.documents.some(
                prov => prov.categoryOfSupply === avr.categoryOfSupply,
              ),
            ),
          )}
          setOpenEditGas={handleOpenGasEditDialog}
        />
      ),
    },
  ];

  const isLoading =
    avrgConsumptionGasLoading ||
    editAvrgConsumptionGasLoading ||
    avrgConsumptionElectricLoading ||
    editAvrgConsumptionElectricLoading ||
    addAvrgConsumptionElectricLoading;

  return (
    <Container maxWidth={'xl'} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mt: 4 }}>
        <BreadCrumbsCustom navigate={navigate} links={links} />
      </Box>

      <Box sx={{ mt: 4 }}>
        {!avrgConsumptionGas?.documents && isLoading ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img style={{ width: '140px' }} src={Loader} alt="loading..." />
          </Box>
        ) : (
          <CustomTabs
            tabs={tabs}
            newTab={Number(searchParams.get('t') ?? 0)}
            setActive={i => setSearchParams({ ...searchParams, t: i.toString() })}
          />
        )}
      </Box>

      <PopupForm
        maxWidth="md"
        InfoTootipProps={undefined}
        title="Επεξεργασία Δείκτη Μέσης Κατανάλωσης"
        helpText="Οι δείκτες μέσης κατανάλωσης χρησιμοποιούνται στο Εργαλείο Σύγκρισης για όσους καταναλωτές δε γνωρίζουν την ακριβή κατανάλωσή τους και επιθυμούν να αξιοποιήσουν το εθνικό μέσο όρο."
        leftBtnTitle="Ακύρωση"
        rightBtnTitle="Απόθηκευση"
        open={openEditDialog}
        setOpen={setOpenEditDialog}
        onClose={handleCloseDialogElectric}
        onClickLeftBtn={handleCloseDialogElectric}
        onClickRightBtn={objEdit._id ? handleSaveEdit : handleAddElectric}
        rightBtnProps={{
          disabled:
            !objEdit.singlePhaseDay ||
            objEdit.singlePhaseDay === 0 ||
            !objEdit.threePhaseDay ||
            objEdit.threePhaseDay === 0 ||
            (objEdit.providerId.measurement === 'Ημερήσια & Νυχτερινή' &&
              (!objEdit.threePhaseNight || !objEdit.singlePhaseNight)),
        }}
      >
        {isSingle ? (
          <EditDialogEl obj={objEdit} setObj={setObjEdit} />
        ) : (
          <EditTabs obj={objEdit} setObj={setObjEdit} />
        )}
      </PopupForm>
      <PopupForm
        maxWidth="md"
        InfoTootipProps={undefined}
        title="Επεξεργασία Δείκτη Μέσης Κατανάλωσης"
        helpText="Οι δείκτες μέσης κατανάλωσης χρησιμοποιούνται στο Εργαλείο Σύγκρισης για όσους καταναλωτές δε γνωρίζουν την ακριβή κατανάλωσή τους και επιθυμούν να αξιοποιήσουν το εθνικό μέσο όρο."
        leftBtnTitle="Ακύρωση"
        rightBtnTitle="Απόθηκευση"
        open={openEditGasDialog}
        setOpen={setOpenEditGasDialog}
        onClose={handleCloseDialogGas}
        onClickLeftBtn={handleCloseDialogGas}
        onClickRightBtn={handleSaveGasEdit}
        rightBtnProps={{
          disabled: !objGasEdit.monthlyCons || objGasEdit.monthlyCons === 0,
        }}
      >
        <EditDialogGas obj={objGasEdit} setObj={setObjGasEdit} />
      </PopupForm>
    </Container>
  );
};
