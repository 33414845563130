import api from './api';
import { ApiPagination, PaginationResult } from '../../types/ApiPagination';
import { Identifier } from '../../types/ApiSchema';
import { Benefit, BenefitFieldsRequest, BenefitsParams } from '../../types/Benefit';

const benefitApi = api.enhanceEndpoints({ addTagTypes: ['Benefit'] }).injectEndpoints({
  endpoints: builder => ({
    getBenefits: builder.query<PaginationResult<Benefit>, BenefitsParams & ApiPagination>(
      {
        query: params => ({
          url: `/database/function/getBenefits`,
          method: 'GET',
          params: { ...params, populate: ['discBenefType', 'team'] },
        }),
        providesTags: result =>
          result?.documents
            ? [
                ...result.documents.map(({ _id }) => ({ type: 'Benefit', _id } as const)),
                { type: 'Benefit', id: 'LIST' },
              ]
            : [{ type: 'Benefit', id: 'LIST' }],
      },
    ),
    addBenefit: builder.mutation<Benefit, Partial<BenefitFieldsRequest>>({
      query: params => ({
        url: `database/Benefits`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: [{ type: 'Benefit', id: 'LIST' }],
    }),
    editBenefit: builder.mutation<Benefit, Identifier & Partial<BenefitFieldsRequest>>({
      query: ({ _id, ...params }) => ({
        url: `database/Benefits/${_id}`,
        method: 'PATCH',
        body: params,
      }),
      invalidatesTags: (result, error, { _id }) => [{ type: 'Benefit', _id }],
    }),
    deleteBenefit: builder.mutation<void, Identifier>({
      query: ({ _id }) => ({
        url: `raeService/benefit/${_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { _id }) => [{ type: 'Benefit', _id }],
    }),
  }),
});

export const {
  useGetBenefitsQuery,
  useAddBenefitMutation,
  useEditBenefitMutation,
  useDeleteBenefitMutation,
} = benefitApi;

export default benefitApi;
